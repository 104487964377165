import {AxiosRequestConfig} from 'axios';
import {PlatformOSType} from 'react-native';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {useToast} from 'react-native-fast-toast';
import {API, ApiEndpointsEnum} from '../api';
import {downloadFile} from '../api/api-blob.native';
import {CodingCodes} from '../data/Codes.data';
import {
  ConsultationRequestResBody,
  ConsultationStatusEnum,
} from '../types/consultation.types';
import {
  DiagnosticReportReqBody,
  MedicationRequestReqBody,
  MedicationRequestArrayUI,
  MedicationRequestStatusEnum,
  LaboratoryRequestReqBody,
  DiagnosticReportStatusEnum,
  DiagnosticReportResBody,
  MedicationRequestResBody,
  MedicationRequest,
  GetAllMedicalAttachmentsParam,
  MedicalAttachmentsResBody,
  GetPrescriptionParam,
  GetMedicalAttachmentParams,
  UpdateLaboratoryRequestReqBody,
  UpdateMedicalCertificateReqBody,
  UpdatePrescriptionReqBody,
} from '../types/medical-attachment.types';
import {convertJsonToParams} from '../utils/query.util';

const addMedicalCertificate = async (reqBody: DiagnosticReportReqBody) => {
  return API.post(`${ApiEndpointsEnum.POST_MEDICAL_CERTIFICATE}`, reqBody);
};

const useAddMedicalCertificate = (
  basedOnId: string,
  status: DiagnosticReportStatusEnum,
  conclusion: string,
) => {
  const queryClient = useQueryClient();

  const moldedReqBody: DiagnosticReportReqBody = {
    basedOnId: basedOnId,
    conclusion: conclusion,
    status: status,
  };

  return useMutation(
    ['add-medical-certificate'],
    () => addMedicalCertificate(moldedReqBody),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['add-medical-certificate']);
        queryClient.fetchQuery(['get-medical-attachments', {id: basedOnId}]);
      },
    },
  );
};

const addPrescription = async (reqBody: MedicationRequestReqBody) => {
  return API.post(`${ApiEndpointsEnum.POST_PRESCRIPTION}`, reqBody);
};

const useAddPrescription = (
  basedOnId: string,
  status: MedicationRequestStatusEnum,
  data: MedicationRequestArrayUI,
) => {
  const queryClient = useQueryClient();

  const moldedReqBody: MedicationRequest[] = [];

  data.prescriptions.map(prescription => {
    const inscription: MedicationRequest = {
      basedOnId: basedOnId,
      status: status,
      dosageInstruction: {
        doseAndRate: {
          doseText: prescription.dose,
          rateText: prescription.quantity,
        },
        timing: {
          text: prescription.frequency,
        },
      },
      medicationCodeableConcept: {
        code: [
          {
            system: 'generic-name',
            display: prescription.generic,
          },
          {
            system: 'brand-name',
            display: prescription.brand,
          },
        ],
      },
      note: prescription.generalInstruction,
    };

    moldedReqBody.push(inscription);
  });

  return useMutation(
    ['add-prescription'],
    () => addPrescription({medicationRequests: moldedReqBody}),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['add-prescription']);
        queryClient.fetchQuery(['get-medical-attachments', {id: basedOnId}]);
      },
    },
  );
};

const addLaboratoryRequest = async (
  reqBody: LaboratoryRequestReqBody,
  subject: string,
) => {
  let config: AxiosRequestConfig = {};

  if (subject) {
    const headerAuthorization: any = {
      'X-Subject': subject,
    };

    config = {
      headers: {
        ...headerAuthorization,
      },
    };
  }

  return API.post<ConsultationRequestResBody>(
    `${ApiEndpointsEnum.POST_CONSULTATION_REQUEST}`,
    reqBody,
    config,
  );
};

const useAddLaboratoryRequest = (
  basedOnId: string,
  status: ConsultationStatusEnum,
  note: string,
  subject: string,
  performer: string,
) => {
  const queryClient = useQueryClient();

  const moldedReqBody: LaboratoryRequestReqBody = {
    code: {
      code: [
        {
          code: CodingCodes.laboratoryRequest,
        },
      ],
    },
    basedOnId: basedOnId,
    note: note,
    status: status,
    performer: performer,
  };

  return useMutation(
    ['add-laboratory-request'],
    () => addLaboratoryRequest(moldedReqBody, subject),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['add-laboratory-request']);
        queryClient.fetchQuery(['get-medical-attachments', {id: basedOnId}]);
      },
    },
  );
};

const getAllMedicalAttachments = (params: GetAllMedicalAttachmentsParam) => {
  const queryParams = convertJsonToParams(params as any);

  const url = ApiEndpointsEnum.GET_ALL_MEDICAL_ATTACHMENTS + '?' + queryParams;
  return API.get<MedicalAttachmentsResBody[]>(url);
};

const useGetAllMedicalAttachments = (params: GetAllMedicalAttachmentsParam) => {
  return useQuery(
    ['get-medical-attachments', {id: params.serviceRequestId}],
    () => getAllMedicalAttachments(params),
    {
      enabled: !!params.serviceRequestId,
    },
  );
};

const getMedicalCertificate = (diagnosticReportId: string, subject: string) => {
  const url = ApiEndpointsEnum.GET_MEDICAL_CERTIFICATE.replace(
    '{diagnosticReportId}',
    diagnosticReportId,
  );

  let config: AxiosRequestConfig = {};

  if (subject) {
    const headerAuthorization: any = {
      'X-Subject': subject,
    };

    config = {
      headers: {
        ...headerAuthorization,
      },
    };
  }

  return API.get<DiagnosticReportResBody>(url, config);
};

const useGetMedicalCertificate = (
  diagnosticReportId: string,
  platform: PlatformOSType,
  subject: string,
) => {
  const queryClient = useQueryClient();

  return useQuery(
    ['get-medical-certificate', {id: diagnosticReportId}],
    () => getMedicalCertificate(diagnosticReportId, subject),
    {
      enabled: !!diagnosticReportId,
      onSuccess: () => {
        queryClient.invalidateQueries([
          `download-medical-certificate-${
            platform === 'web' ? 'web' : 'mobile'
          }`,
          {id: diagnosticReportId},
        ]);

        queryClient.removeQueries([
          `download-medical-certificate-${
            platform === 'web' ? 'web' : 'mobile'
          }`,
          {id: diagnosticReportId},
        ]);
      },
    },
  );
};

const getPrescription = (params: GetPrescriptionParam, subject: string) => {
  const queryParams = convertJsonToParams(params as any);

  let config: AxiosRequestConfig = {};

  if (subject) {
    const headerAuthorization: any = {
      'X-Subject': subject,
    };

    config = {
      headers: {
        ...headerAuthorization,
      },
    };
  }

  const url = ApiEndpointsEnum.GET_PRESCRIPTION + '?' + queryParams;
  return API.get<MedicationRequestResBody[]>(url, config);
};

const useGetPrescription = (
  params: GetPrescriptionParam,
  platform: PlatformOSType,
  subject: string,
) => {
  const queryClient = useQueryClient();

  return useQuery(
    ['get-prescription', {id: params.groupId}],
    () => getPrescription(params, subject),
    {
      enabled: !!params.groupId,
      onSuccess: () => {
        queryClient.invalidateQueries([
          `download-prescription-${platform === 'web' ? 'web' : 'mobile'}`,
          {id: params.groupId},
        ]);

        queryClient.removeQueries([
          `download-prescription-${platform === 'web' ? 'web' : 'mobile'}`,
          {id: params.groupId},
        ]);
      },
    },
  );
};

const getLaboratoryRequest = (serviceRequestId: string) => {
  const url = ApiEndpointsEnum.GET_LABORATORY_REQUEST.replace(
    '{serviceRequestId}',
    serviceRequestId,
  );

  return API.get<ConsultationRequestResBody>(url);
};

const useGetLaboratoryRequest = (
  serviceRequestId: string,
  platform: PlatformOSType,
) => {
  const queryClient = useQueryClient();

  return useQuery(
    ['get-laboratory-request', {id: serviceRequestId}],
    () => getLaboratoryRequest(serviceRequestId),
    {
      enabled: !!serviceRequestId,
      onSuccess: () => {
        queryClient.invalidateQueries([
          `download-laboratory-request-${
            platform === 'web' ? 'web' : 'mobile'
          }`,
          {id: serviceRequestId},
        ]);

        queryClient.removeQueries([
          `download-laboratory-request-${
            platform === 'web' ? 'web' : 'mobile'
          }`,
          {id: serviceRequestId},
        ]);
      },
    },
  );
};

const getMedicalCertificates = (params: GetMedicalAttachmentParams) => {
  const queryParams = convertJsonToParams(params as any);

  const url = ApiEndpointsEnum.GET_MEDICAL_CERTIFICATES + '?' + queryParams;
  return API.get<DiagnosticReportResBody[]>(url);
};

const useGetMedicalCertificates = (params: GetMedicalAttachmentParams) => {
  return useQuery(
    ['get-medical-certificates', {id: params.basedOnId}],
    () => getMedicalCertificates(params),
    {
      enabled: !!params.basedOnId,
    },
  );
};

const getPrescriptions = (params: GetMedicalAttachmentParams) => {
  const queryParams = convertJsonToParams(params as any);

  const url = ApiEndpointsEnum.GET_PRESCRIPTIONS + '?' + queryParams;
  return API.get<MedicationRequestResBody[]>(url);
};

const useGetPrescriptions = (params: GetMedicalAttachmentParams) => {
  return useQuery(
    ['get-prescriptions', {id: params.basedOnId}],
    () => getPrescriptions(params),
    {
      enabled: !!params.basedOnId,
    },
  );
};

const getLaboratoryRequests = (subject: string) => {
  const queryParams = convertJsonToParams({
    status: ConsultationStatusEnum.PENDING,
    code: CodingCodes.laboratoryRequest,
  });
  const url = ApiEndpointsEnum.GET_LABORATORY_REQUESTS + '?' + queryParams;

  let config: AxiosRequestConfig = {};

  if (subject) {
    const headerAuthorization: any = {
      'X-Subject': subject,
    };

    config = {
      headers: {
        ...headerAuthorization,
      },
    };
  }

  return API.get<ConsultationRequestResBody[]>(url, config);
};

const useGetLaboratoryRequests = (subject: string) => {
  return useQuery(
    ['get-laboratory-requests', {id: subject}],
    () => getLaboratoryRequests(subject),
    {
      enabled: !!subject,
    },
  );
};

// const downloadLaboratoryRequestBlob = (
//   serviceRequestId: string,
//   platform: PlatformOSType,
// ) => {
//   const url = ApiEndpointsEnum.GET_LABORATORY_REQUEST.replace(
//     '{serviceRequestId}',
//     serviceRequestId,
//   );

//   let config: AxiosRequestConfig = {};

//   const headerAuthorization: any = {
//     'Content-Type': 'application/pdf',
//   };

//   config = {
//     headers: {
//       ...headerAuthorization,
//     },
//     responseType: 'blob',
//   };

//   return downloadFile({
//     endpoint: url,
//     headers: config.headers,
//     fileName: 'Laboratory Request',
//     platform: platform,
//   });

//   // return API.get<ConsultationRequestResBody>(url, config);
// };

// const useDownloadLaboratoryRequestBlob = (
//   serviceRequestId: string,
//   platform: PlatformOSType,
// ) => {
//   const queryClient = useQueryClient();

//   return useQuery(
//     ['download-laboratory-request-mobile', {id: serviceRequestId}],
//     () => downloadLaboratoryRequestBlob(serviceRequestId, platform),
//     {
//       enabled: false,
//       refetchOnWindowFocus: false,
//       staleTime: Infinity,
//       onSuccess: () => {
//         queryClient.invalidateQueries(['download-laboratory-request-mobile']);
//       },
//     },
//   );
// };

const downloadLaboratoryRequest = (
  serviceRequestId: string,
  platform: PlatformOSType,
) => {
  const url = ApiEndpointsEnum.GET_LABORATORY_REQUEST.replace(
    '{serviceRequestId}',
    serviceRequestId,
  );

  let config: AxiosRequestConfig = {};

  const headerAuthorization: any = {
    'Content-Type': 'application/pdf',
  };

  config = {
    headers: {
      ...headerAuthorization,
    },
    responseType: 'blob',
  };

  if (platform === 'web') {
    return API.get<ConsultationRequestResBody>(url, config);
  } else {
    return downloadFile({
      endpoint: url,
      headers: config.headers,
      fileName: 'Laboratory Request',
      platform: platform,
    });
  }
};

const useDownloadLaboratoryRequest = (
  serviceRequestId: string,
  platform: PlatformOSType,
) => {
  const queryClient = useQueryClient();
  const toast = useToast();

  toast?.hide(serviceRequestId);
  return useQuery(
    [
      `download-laboratory-request-${platform === 'web' ? 'web' : 'mobile'}`,
      {id: serviceRequestId},
    ],
    () => {
      toast?.show('Downloading...', {
        type: 'normal',
        id: serviceRequestId,
      });
      return downloadLaboratoryRequest(serviceRequestId, platform);
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: Infinity,
      onSuccess: () => {
        toast?.hide(serviceRequestId);
        toast?.show('Download complete.', {
          type: 'success',
          id: serviceRequestId,
        });

        queryClient.invalidateQueries([
          `download-laboratory-request-${
            platform === 'web' ? 'web' : 'mobile'
          }`,
          {id: serviceRequestId},
        ]);

        setTimeout(() => {
          queryClient.removeQueries([
            `download-laboratory-request-${
              platform === 'web' ? 'web' : 'mobile'
            }`,
            {id: serviceRequestId},
          ]);
        }, 100);
      },
    },
  );
};

// const downloadPrescription = (
//   params: GetPrescriptionParam,
//   subject: string,
// ) => {
//   const queryParams = convertJsonToParams(params as any);

//   let config: AxiosRequestConfig = {};

//   if (subject) {
//     const headerAuthorization: any = {
//       'X-Subject': subject,
//       'Content-Type': 'application/pdf',
//     };

//     config = {
//       headers: {
//         ...headerAuthorization,
//       },
//       responseType: 'blob',
//     };
//   }

//   const url = ApiEndpointsEnum.GET_PRESCRIPTION + '?' + queryParams;
//   return API.download<MedicationRequestResBody[]>(url, config, 'Prescription');
// };

// const useDownloadPrescription = (
//   params: GetPrescriptionParam,
//   subject: string,
// ) => {
//   return useQuery(
//     ['download-prescription', {id: params.groupId}],
//     () => downloadPrescription(params, subject),
//     {
//       enabled: false,
//       refetchOnWindowFocus: false,
//     },
//   );
// };

const downloadPrescription = (
  params: GetPrescriptionParam,
  platform: PlatformOSType,
  subject?: string,
) => {
  const queryParams = convertJsonToParams(params as any);

  let config: AxiosRequestConfig = {};
  let headerAuthorization: any = {
    'Content-Type': 'application/pdf',
  };

  if (subject) {
    headerAuthorization['X-Subject'] = subject;
  }

  config = {
    headers: {
      ...headerAuthorization,
    },
    responseType: 'blob',
  };

  const url = ApiEndpointsEnum.GET_PRESCRIPTION + '?' + queryParams;

  if (platform === 'web') {
    return API.get<MedicationRequestResBody[]>(url, config);
  } else {
    return downloadFile({
      endpoint: url,
      headers: config.headers,
      fileName: 'Prescription',
      platform: platform,
    });
  }
};

const useDownloadPrescription = (
  params: GetPrescriptionParam,
  platform: PlatformOSType,
  subject?: string,
) => {
  const queryClient = useQueryClient();
  const toast = useToast();

  toast?.hide(params.groupId);
  return useQuery(
    [
      `download-prescription-${platform === 'web' ? 'web' : 'mobile'}`,
      {id: params.groupId},
    ],
    () => {
      toast?.show('Downloading...', {
        type: 'normal',
        id: params.groupId,
      });
      return downloadPrescription(params, platform, subject);
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: Infinity,
      onSuccess: () => {
        toast?.hide(params.groupId);
        toast?.show('Download complete.', {
          type: 'success',
          id: params.groupId,
        });

        queryClient.invalidateQueries([
          `download-prescription-${platform === 'web' ? 'web' : 'mobile'}`,
          {id: params.groupId},
        ]);

        setTimeout(() => {
          queryClient.removeQueries([
            `download-prescription-${platform === 'web' ? 'web' : 'mobile'}`,
            {id: params.groupId},
          ]);
        }, 100);
      },
    },
  );
};

// const downloadMedicalCertificatex = (
//   diagnosticReportId: string,
//   subject: string,
// ) => {
//   const url = ApiEndpointsEnum.GET_MEDICAL_CERTIFICATE.replace(
//     '{diagnosticReportId}',
//     diagnosticReportId,
//   );

//   let config: AxiosRequestConfig = {};

//   if (subject) {
//     const headerAuthorization: any = {
//       'X-Subject': subject,
//       'Content-Type': 'application/pdf',
//     };

//     config = {
//       headers: {
//         ...headerAuthorization,
//       },
//       responseType: 'blob',
//     };
//   }

//   return API.download<DiagnosticReportResBody>(
//     url,
//     config,
//     'Medical Certificate',
//   );
// };

// const useDownloadMedicalCertificatex = (
//   diagnosticReportId: string,
//   subject: string,
// ) => {
//   return useQuery(
//     ['download-medical-certificate', {id: diagnosticReportId}],
//     () => downloadMedicalCertificate(diagnosticReportId, subject),
//     {
//       enabled: false,
//       refetchOnWindowFocus: false,
//     },
//   );
// };

const downloadMedicalCertificate = (
  diagnosticReportId: string,
  platform: PlatformOSType,
  subject?: string,
) => {
  const url = ApiEndpointsEnum.GET_MEDICAL_CERTIFICATE.replace(
    '{diagnosticReportId}',
    diagnosticReportId,
  );

  let config: AxiosRequestConfig = {};
  let headerAuthorization: any = {
    'Content-Type': 'application/pdf',
  };

  if (subject) {
    headerAuthorization['X-Subject'] = subject;
  }

  config = {
    headers: {
      ...headerAuthorization,
    },
    responseType: 'blob',
  };

  if (platform === 'web') {
    return API.get<DiagnosticReportResBody>(url, config);
  } else {
    return downloadFile({
      endpoint: url,
      headers: config.headers,
      fileName: 'Medical Certificate',
      platform: platform,
    });
  }
};

const useDownloadMedicalCertificate = (
  diagnosticReportId: string,
  platform: PlatformOSType,
  subject?: string,
) => {
  const queryClient = useQueryClient();
  const toast = useToast();

  toast?.hide(diagnosticReportId);
  return useQuery(
    [
      `download-medical-certificate-${platform === 'web' ? 'web' : 'mobile'}`,
      {id: diagnosticReportId},
    ],
    () => {
      toast?.show('Downloading...', {
        type: 'normal',
        id: diagnosticReportId,
      });
      return downloadMedicalCertificate(diagnosticReportId, platform, subject);
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: Infinity,
      onSuccess: () => {
        toast?.hide(diagnosticReportId);
        toast?.show('Download complete.', {
          type: 'success',
          id: diagnosticReportId,
        });

        queryClient.invalidateQueries([
          `download-medical-certificate-${
            platform === 'web' ? 'web' : 'mobile'
          }`,
          {id: diagnosticReportId},
        ]);

        setTimeout(() => {
          queryClient.removeQueries([
            `download-medical-certificate-${
              platform === 'web' ? 'web' : 'mobile'
            }`,
            {id: diagnosticReportId},
          ]);
        }, 100);
      },
    },
  );
};

const updateLaboratoryRequest = (
  serviceRequestId: string,
  reqBody: UpdateLaboratoryRequestReqBody,
) => {
  const url = ApiEndpointsEnum.PUT_LABORATORY_REQUEST.replace(
    '{serviceRequestId}',
    serviceRequestId,
  );

  return API.put<ConsultationRequestResBody>(url, reqBody);
};

const useUpdateLaboratoryRequest = (
  serviceRequestId: string,
  reqBody: UpdateLaboratoryRequestReqBody,
  procedureId: string,
) => {
  const queryClient = useQueryClient();

  return useMutation(
    ['update-laboratory-request'],
    () => updateLaboratoryRequest(serviceRequestId, reqBody),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['update-laboratory-request']);
        queryClient.fetchQuery(['get-medical-attachments', {id: procedureId}]);
      },
    },
  );
};

const updateMedicalCertificate = (
  diagnosticReportId: string,
  reqBody: UpdateMedicalCertificateReqBody,
) => {
  const url = ApiEndpointsEnum.PUT_MEDICAL_CERTIFICATE.replace(
    '{diagnosticReportId}',
    diagnosticReportId,
  );

  return API.put<DiagnosticReportResBody>(url, reqBody);
};

const useUpdateMedicalCertificate = (
  diagnosticReportId: string,
  reqBody: UpdateMedicalCertificateReqBody,
  procedureId: string,
) => {
  const queryClient = useQueryClient();

  return useMutation(
    ['update-medical-certificate'],
    () => updateMedicalCertificate(diagnosticReportId, reqBody),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['update-medical-certificate']);
        queryClient.fetchQuery(['get-medical-attachments', {id: procedureId}]);
      },
    },
  );
};

const updatePrescription = (reqBody: UpdatePrescriptionReqBody) => {
  const url = ApiEndpointsEnum.PUT_PRESCRIPTION;

  return API.put<DiagnosticReportResBody>(url, reqBody);
};

const useUpdatePrescription = (
  groupId: string,
  basedOnId: string,
  status: MedicationRequestStatusEnum,
  data: MedicationRequestArrayUI,
  procedureId: string,
) => {
  const queryClient = useQueryClient();

  const moldedPrescriptions: MedicationRequest[] = [];

  data.prescriptions.map(prescription => {
    const inscription: MedicationRequest = {
      basedOnId: basedOnId,
      status: status,
      dosageInstruction: {
        doseAndRate: {
          doseText: prescription.dose,
          rateText: prescription.quantity,
        },
        timing: {
          text: prescription.frequency,
        },
      },
      medicationCodeableConcept: {
        code: [
          {
            system: 'generic-name',
            display: prescription.generic,
          },
          {
            system: 'brand-name',
            display: prescription.brand,
          },
        ],
      },
      note: prescription.generalInstruction,
    };

    moldedPrescriptions.push(inscription);
  });

  const moldedReqBody: UpdatePrescriptionReqBody = {
    groupId: groupId,
    medicationRequests: moldedPrescriptions,
  };

  return useMutation(
    ['update-prescription'],
    () => updatePrescription(moldedReqBody),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['update-prescription']);
        queryClient.invalidateQueries(['get-prescriptions']);
        queryClient.fetchQuery(['get-medical-attachments', {id: procedureId}]);
      },
    },
  );
};

export {
  useAddMedicalCertificate,
  useGetMedicalCertificate,
  useAddPrescription,
  useGetPrescription,
  useAddLaboratoryRequest,
  useGetLaboratoryRequest,
  useGetAllMedicalAttachments,
  useGetMedicalCertificates,
  useGetPrescriptions,
  useGetLaboratoryRequests,
  useDownloadLaboratoryRequest,
  useDownloadPrescription,
  useDownloadMedicalCertificate,
  useUpdateLaboratoryRequest,
  useUpdateMedicalCertificate,
  useUpdatePrescription,
};
