import React from 'react';
import {Platform} from 'react-native';
import {useTheme, CheckBoxProps} from 'rne-netzon';
import {CheckBox} from 'rne-netzon/dist/checkbox/CheckBox';

interface RNECheckBoxProps extends CheckBoxProps {}

const textStyle = {
  fontSize: Platform.OS === 'web' ? 14 : 12,
  fontFamily: 'Quicksand-Regular',
  fontWeight: '400',
};

const containerStyle = {
  backgroundColor: 'transparent',
  borderColor: 'transparent',
  justifyContent: 'center',
};

const activeOpacity = 0.8;

export const RNECheckBox = (props: RNECheckBoxProps) => {
  const {...others} = props;

  return (
    <CheckBox
      {...others}
      textStyle={Object.assign(others.textStyle ?? {}, textStyle)}
      containerStyle={Object.assign(
        others.containerStyle ?? {},
        containerStyle,
      )}
      activeOpacity={activeOpacity}
    />
  );
};
