import React, {useLayoutEffect} from 'react';
import {
  View,
  Platform,
  ScrollView,
  TouchableWithoutFeedback,
} from 'react-native';

import HTML from 'react-native-render-html';

import {useNavigation} from '@react-navigation/native';

import {makeStyles} from 'rne-netzon';
import {colorPalette} from '../../../core/config/color.config';

import {RNEText, SVGIcon} from '../../components';
import HeaderBackLeft from '../../views/navigation/HeaderBackLeft';

import {HTMLPrivacyPolicy} from './../../data/policy/privacy-policy';
import {
  BUILD_NUMBER,
  MAJOR_VERSION,
  MINOR_VERSION,
} from '../../server/api/api.config';

const PrivacyPolicyScreen = () => {
  const webStyles = useWebStyles();
  const mobileStyles = useMobileStyles();

  const navigation = useNavigation();

  useLayoutEffect(() => {
    navigation.setOptions(
      Platform.OS === 'web'
        ? {
            headerShown: false,
          }
        : {
            headerShown: true,
            title: '',
            headerStyle: {
              elevation: 0,
              shadowOpacity: 0,
            },
            headerTintColor: colorPalette.blackLight,
            headerTitleStyle: {
              fontFamily: 'Quicksand-Regular',
              fontSize: 14,
              paddingLeft: 28,
            },
            headerLeft: () => <HeaderBackLeft />,
          },
    );
  }, [navigation]);

  return (
    <>
      {Platform.OS === 'web' ? (
        <View style={webStyles.mainContainer}>
          <View style={webStyles.titleContainer}>
            <TouchableWithoutFeedback onPress={() => navigation.goBack()}>
              <View style={webStyles.backContainer} accessibilityRole="button">
                <SVGIcon name="left-black" size={24} />
              </View>
            </TouchableWithoutFeedback>
            <RNEText
              label="Privacy Policy"
              variant="header"
              weight="bold"
              color="accent"
            />
          </View>
          <ScrollView style={webStyles.mainWrapper}>
            <HTML
              source={{
                html: HTMLPrivacyPolicy,
              }}
            />
          </ScrollView>
          <View style={webStyles.versionContainer}>
            <RNEText
              label={`House Call version ${MAJOR_VERSION}.${MINOR_VERSION}.${BUILD_NUMBER}`}
              variant="title"
              weight="bold"
              color="accent"
              align="right"
              // eslint-disable-next-line react-native/no-inline-styles
              style={{fontSize: 16}}
            />
          </View>
        </View>
      ) : (
        <>
          <ScrollView>
            <View style={mobileStyles.mainContainer}>
              <RNEText
                label="Privacy Policy"
                variant="header"
                weight="bold"
                color="accent"
              />

              <HTML
                source={{
                  html: HTMLPrivacyPolicy,
                }}
              />
            </View>
          </ScrollView>

          <View style={mobileStyles.versionContainer}>
            <RNEText
              label={`House Call version ${MAJOR_VERSION}.${MINOR_VERSION}.${BUILD_NUMBER}`}
              variant="title"
              weight="bold"
              color="accent"
              align="right"
              // eslint-disable-next-line react-native/no-inline-styles
              style={{fontSize: 16}}
            />
          </View>
        </>
      )}
    </>
  );
};

export default PrivacyPolicyScreen;

const useWebStyles = makeStyles({
  mainContainer: {
    paddingTop: 140,
    paddingBottom: 32,
    paddingHorizontal: 80,
    width: '100%',
    flex: 1,
  },
  titleContainer: {
    marginBottom: 40,
    flexDirection: 'row',
  },
  mainWrapper: {
    paddingRight: 48,
  },
  versionContainer: {
    marginTop: 20,
  },
  backContainer: {
    marginRight: 16,
    justifyContent: 'center',
  },
});

const useMobileStyles = makeStyles({
  mainContainer: {
    paddingVertical: 20,
    paddingHorizontal: 48,
  },
  versionContainer: {
    marginRight: 20,
    marginBottom: 20,
  },
});
