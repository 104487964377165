import React from 'react';
import {View, Platform, ScrollView} from 'react-native';
import {SafeAreaView} from 'react-native-safe-area-context';
import {makeStyles} from 'rne-netzon';

import {RNEText} from '../../components';

const PatientConsultationsScreen = () => {
  const sharedStyles = useSharedStyles();
  const webStyles = useWebStyles();
  const mobileStyles = useMobileStyles();

  return (
    <SafeAreaView style={sharedStyles.root}>
      {Platform.OS === 'web' ? (
        <ScrollView>
          <View style={webStyles.mainContainer}>
            <RNEText
              label="Consultation Screen"
              color="accent"
              weight="bold"
              variant="title"
            />
          </View>
        </ScrollView>
      ) : (
        <ScrollView>
          <View style={mobileStyles.mainContainer}>
            <RNEText
              label="Consultation Screen"
              color="accent"
              weight="bold"
              variant="title"
            />
          </View>
        </ScrollView>
      )}
    </SafeAreaView>
  );
};

export default PatientConsultationsScreen;

const useWebStyles = makeStyles({
  mainContainer: {
    paddingTop: 120,
    paddingBottom: 32,
    paddingHorizontal: 120,
    width: '100%',
  },
});

const useMobileStyles = makeStyles({
  mainContainer: {
    paddingVertical: 20,
    paddingHorizontal: 48,
  },
});

const useSharedStyles = makeStyles({
  root: {
    height: '100%',
    flexDirection: 'row',
    width: '100%',
  },
});
