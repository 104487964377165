import {useNavigation} from '@react-navigation/core';
import React, {memo} from 'react';
import {Platform, View} from 'react-native';

import {makeStyles} from 'rne-netzon';

import {RNEText, RNEButton} from '../../components';
import {useRequestVerification} from '../../server/react-query/usePractitioner';

function RequestVerificationModal() {
  const rneStyles = useStyles();
  const navigation = useNavigation();

  const verificationMutation = useRequestVerification();
  const requestVerification = async () => {
    try {
      await verificationMutation.mutateAsync();
      navigation.goBack();
    } catch (err) {
      console.log('err', err);
    }
  };

  return (
    <View style={rneStyles.root}>
      <RNEText
        label="Request Verification"
        variant="title"
        align="center"
        weight="600"
        color="accent"
      />

      <View style={rneStyles.subtitleContainer}>
        <RNEText
          label="To fully enjoy the benefits of House Call, please request for your account to be verified."
          variant="body"
          align="center"
        />
      </View>

      <View style={rneStyles.actionContainer}>
        <RNEButton
          title="Request Verification"
          color="accent"
          onPress={requestVerification}
          loading={verificationMutation.isLoading}
          style={rneStyles.buttonStyle}
        />
      </View>
    </View>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: Platform.OS === 'web' ? 420 : '80%',
    padding: Platform.OS === 'web' ? 32 : 16,
  },
  subtitleContainer: {
    marginTop: 20,
  },
  actionContainer: {
    marginTop: 36,
    alignItems: 'center',
  },
  buttonStyle: {
    width: 200,
  },
}));

export default memo(RequestVerificationModal);
