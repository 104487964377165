import React, {useEffect, useState, useLayoutEffect} from 'react';
import {useNavigation, useRoute} from '@react-navigation/native';
import {View, Platform, ScrollView, Image} from 'react-native';
import {makeStyles} from 'rne-netzon';

import {RNEButton, RNEIconButton, RNEText, SVGIcon} from '../../components';
import {LogoLight} from './../../images/Image';
import {TouchableWithoutFeedback} from 'react-native-gesture-handler';
import {
  useDownloadLaboratoryRequest,
  useGetLaboratoryRequest,
} from '../../server/react-query/useMedicalAttachment';
import {colorPalette} from '../../../core/config/color.config';
import {formatDateString} from '../../utils/date.utils';
import HeaderIconRight from '../navigation/HeaderIconRight';
import {useDownloader} from '../../hooks/downloader/useDownloader';

export interface ViewPrescriptionViewProps {
  onClose: () => void;
  id: string;
}

const ViewLaboratoryRequestView = (props: ViewPrescriptionViewProps) => {
  const webStyles = useWebStyles();
  const mobileStyles = useMobileStyles();
  const route = useRoute();
  const navigation = useNavigation();

  const [id, setId] = useState();

  const {data, isFetching, refetch} = useGetLaboratoryRequest(
    Platform.OS === 'web' ? props.id : id!,
    Platform.OS,
  );

  const {data: webBlob, refetch: download} = useDownloadLaboratoryRequest(
    Platform.OS === 'web' ? props.id : id!,
    Platform.OS,
  );

  const laboratoryRequestConfig = {
    web: {
      fileName: 'Laboratory Request',
      data: webBlob,
      download: download,
    },
    mobile: {
      download: download,
    },
  };

  const {downloadAttachment} = useDownloader(laboratoryRequestConfig);
  const [age, setAge] = useState<number | null>(null);

  const onDownloadClick = () => {
    downloadAttachment();
  };

  useEffect(() => {
    const params = route.params as any;

    if (params && params.id) {
      setId(params.id);
    }
  }, [route.params]);

  useEffect(() => {
    if (id) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useLayoutEffect(() => {
    if (Platform.OS !== 'web') {
      navigation.setOptions({
        headerRight: () => (
          <HeaderIconRight iconName="download-down" onClick={onDownloadClick} />
        ),
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigation]);

  useEffect(() => {
    if (data?.subject.birthdate) {
      const birthdate = new Date(data.subject.birthdate);
      const ageDifMs = Date.now() - birthdate.getTime();
      const ageDate = new Date(ageDifMs);
      const calculatedAge = Math.abs(ageDate.getUTCFullYear() - 1970);
      setAge(calculatedAge);
    }
  }, [data]);

  return (
    <>
      {Platform.OS === 'web' ? (
        <View style={webStyles.mainContainer}>
          <View style={webStyles.headerContainer}>
            <Image style={webStyles.logo} source={LogoLight} />
            <View style={webStyles.rightIcons}>
              <TouchableWithoutFeedback>
                <RNEIconButton
                  icon={<SVGIcon name="download-down" size={28} />}
                  type="clear"
                  size={28}
                  onPress={onDownloadClick}
                  containerStyle={{borderRadius: 0}}
                />
              </TouchableWithoutFeedback>
              <TouchableWithoutFeedback>
                <RNEIconButton
                  icon={<SVGIcon name="close-gray-71" size={36} />}
                  type="clear"
                  size={36}
                  onPress={props.onClose}
                />
              </TouchableWithoutFeedback>
            </View>
          </View>
          <ScrollView>
            <View style={webStyles.bodyContainer}>
              {data && (
                <>
                  <View style={webStyles.infoContainer}>
                    <RNEText
                      label={`Patient: ${data.subject.humanName.given[0]} ${data.subject.humanName.family}`}
                      variant="body"
                      weight="bold"
                      style={textWebStyle}
                    />
                    <RNEText
                      label={`Address: ${data.subject.location ?? '--'}`}
                      variant="body"
                      weight="bold"
                      style={textWebStyle}
                    />
                    <RNEText
                      label={`Age: ${age ?? '--'}`}
                      variant="body"
                      weight="bold"
                      style={textWebStyle}
                    />
                    <RNEText
                      label={`Sex: ${
                        data.subject.sex
                          ? data.subject.sex.charAt(0).toUpperCase() +
                            data.subject.sex.slice(1)
                          : '--'
                      }`}
                      variant="body"
                      weight="bold"
                      style={textWebStyle}
                    />
                  </View>
                </>
              )}
              <View style={webStyles.titleContainer}>
                <RNEText
                  label="Laboratory Request"
                  variant="title"
                  weight="bold"
                  color="accent"
                />
                <View style={webStyles.spinnerContainer}>
                  <RNEButton
                    height={20}
                    loadingProps={loadingPropsStyle}
                    loading={isFetching}
                  />
                </View>
              </View>
              {data && (
                <>
                  <View style={webStyles.infoContainer}>
                    <RNEText
                      label={`Date Issued: ${formatDateString(
                        data.modifiedOn,
                      )}`}
                      variant="body"
                      style={textWebStyle}
                    />
                    <RNEText
                      label={`Laboratory Request ID: #${data.serviceRequestId
                        .substring(0, 7)
                        .toUpperCase()}`}
                      variant="body"
                      style={textWebStyle}
                    />
                  </View>
                  <View style={webStyles.certificateContainer}>
                    <RNEText
                      label={data.note[0].text || ''}
                      variant="body"
                      style={textWebStyle}
                    />
                  </View>
                  <View style={webStyles.doctorContainer}>
                    <RNEText
                      label={`Dr. ${data.performer.humanName.given[0]} ${data.performer.humanName.family}`}
                      weight="bold"
                      style={doctorNameStyle}
                    />
                    <RNEText
                      label={
                        data.performer.organization[0].address?.city
                          ? `${data.performer.practitionerRole[0].specialty.text}, ${data.performer.organization[0].address?.city}`
                          : `${data.performer.practitionerRole[0].specialty.text}`
                      }
                      variant="body"
                    />
                    <RNEText
                      label={`License No: ${data.performer.qualification.identifier}`}
                      variant="body"
                    />
                  </View>
                </>
              )}
            </View>
          </ScrollView>
        </View>
      ) : (
        <ScrollView>
          <View style={mobileStyles.mainContainer}>
            {data && (
              <>
                <View style={mobileStyles.infoContainer}>
                  <RNEText
                    label={`Patient: ${data.subject.humanName.given[0]} ${data.subject.humanName.family}`}
                    variant="body"
                    weight="bold"
                    style={textMobileStyle}
                  />
                  <RNEText
                    label={`Address: ${data.subject.location ?? '--'}`}
                    variant="body"
                    weight="bold"
                    style={textMobileStyle}
                  />
                  <RNEText
                    label={`Age: ${age ?? '--'}`}
                    variant="body"
                    weight="bold"
                    style={textMobileStyle}
                  />
                  <RNEText
                    label={`Sex: ${
                      data.subject.sex
                        ? data.subject.sex.charAt(0).toUpperCase() +
                          data.subject.sex.slice(1)
                        : '--'
                    }`}
                    variant="body"
                    weight="bold"
                    style={textMobileStyle}
                  />
                </View>
              </>
            )}
            <View style={mobileStyles.titleContainer}>
              <RNEText
                label="Laboratory Request"
                variant="title"
                weight="bold"
                color="accent"
              />
              <View style={mobileStyles.spinnerContainer}>
                <RNEButton
                  height={24}
                  loadingProps={loadingPropsStyle}
                  loading={isFetching}
                />
              </View>
            </View>
            {data && (
              <>
                <View style={mobileStyles.infoContainer}>
                  <RNEText
                    label={`Date Issue: ${formatDateString(data.modifiedOn)}`}
                    variant="body"
                    style={textMobileStyle}
                  />
                  <RNEText
                    label={`Laboratory Request ID: #${data.serviceRequestId
                      .substring(0, 7)
                      .toUpperCase()}`}
                    variant="body"
                    style={textMobileStyle}
                  />
                </View>
                <View style={mobileStyles.certificateContainer}>
                  <RNEText
                    label={data.note[0].text || ''}
                    variant="body"
                    style={textMobileStyle}
                  />
                </View>
                <View style={mobileStyles.doctorContainer}>
                  <RNEText
                    label={`Dr. ${data.performer.humanName.given[0]} ${data.performer.humanName.family}`}
                    weight="bold"
                    variant="title"
                    style={doctorNameStyle}
                  />
                  <RNEText
                    label={
                      data.performer.organization[0].address?.city
                        ? `${data.performer.practitionerRole[0].specialty.text}, ${data.performer.organization[0].address?.city}`
                        : `${data.performer.practitionerRole[0].specialty.text}`
                    }
                    variant="body"
                  />
                  <RNEText
                    label={`License No: ${data.performer.qualification.identifier}`}
                    variant="body"
                  />
                </View>
              </>
            )}
          </View>
        </ScrollView>
      )}
    </>
  );
};

export default ViewLaboratoryRequestView;

const doctorNameStyle = {
  fontSize: 24,
};

const textWebStyle = {
  lineHeight: 24,
};

const textMobileStyle = {
  lineHeight: 20,
};

const loadingPropsStyle = {
  color: colorPalette.primary,
};

const useWebStyles = makeStyles({
  mainContainer: {
    marginHorizontal: 40,
    marginVertical: 20,
    flex: 1,
    width: '40vw',
    maxWidth: 700,
    minWidth: 440,
    maxHeight: '70vh',
  },
  headerContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottomColor: 'rgba(229, 229, 229, 0.7)',
    borderBottomWidth: 1,
    paddingTop: 12,
    paddingBottom: 28,
  },
  logo: {
    width: 200,
    height: 44,
  },
  rightIcons: {
    flexDirection: 'row',
    alignSelf: 'center',
    justifyContent: 'space-between',
    width: 76,
  },
  closeIcon: {
    marginLeft: 28,
  },
  bodyContainer: {
    marginVertical: 40,
    marginHorizontal: 60,
  },
  infoContainer: {
    marginVertical: 24,
  },
  certificateContainer: {
    marginVertical: 28,
  },
  doctorContainer: {
    marginVertical: 24,
  },
  titleContainer: {
    flexDirection: 'row',
  },
  spinnerContainer: {
    alignSelf: 'flex-end',
    marginBottom: 8,
  },
});

const useMobileStyles = makeStyles({
  mainContainer: {
    width: '100%',
    flex: 1,
    paddingHorizontal: 52,
    paddingVertical: 40,
  },
  infoContainer: {
    marginBottom: 24,
  },
  certificateContainer: {
    marginVertical: 28,
  },
  spaceContainer: {
    marginVertical: 12,
  },
  doctorContainer: {
    marginVertical: 24,
  },
  titleContainer: {
    flexDirection: 'row',
  },
  spinnerContainer: {
    marginTop: 8,
    marginLeft: -12,
  },
});
