import React, {useEffect, useState} from 'react';
import {StyleSheet, View, TouchableOpacity} from 'react-native';

import {observer} from 'mobx-react';
import {useNavigation} from '@react-navigation/native';

import {SVGIcon, RNEText} from '../../components';

import {colorPalette} from '../../../core/config/color.config';

import {PractitionerProfile} from '../../server/types/practitioner-profile.types';
import {ConsultationStatusEnum} from '../../server/types/consultation.types';
import {ConsultationRequestResBody} from '../../server/types/consultation.types';

import ConsultationPhoto from '../consultation/ConsultationPhoto';

import {PopoverItemKey} from '../popover/data/popoverItems.data';
import {ProcedureStatusEnum} from '../../server/types/procedure.types';

interface HeaderConsultationPatientProps {
  doctorData?: PractitionerProfile;
  serviceRequest?: ConsultationRequestResBody;
  onMenuItemClick?: (itemKey: PopoverItemKey) => void;
  onBackClickFromList?: () => void;
}

const HeaderConsultationPatient = (props: HeaderConsultationPatientProps) => {
  const navigation = useNavigation();

  const [displayName, setDisplayName] = useState('');
  const [location, setLocation] = useState('');

  const onBackClick = () => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    }
  };

  useEffect(() => {
    if (props.serviceRequest?.subject) {
      switch (props.serviceRequest.status) {
        case ConsultationStatusEnum.PENDING:
          setDisplayName(
            `Patient ${props.serviceRequest.subject.patientId
              .substring(0, 6)
              .toUpperCase()}`,
          );
          setLocation('');
          break;

        case ConsultationStatusEnum.CANCELLED:
          setDisplayName(
            `Patient ${props.serviceRequest.subject.patientId
              .substring(0, 6)
              .toUpperCase()}`,
          );
          setLocation('');
          break;

        case ConsultationStatusEnum.DECLINED:
          setDisplayName(
            `Patient ${props.serviceRequest.subject.patientId
              .substring(0, 6)
              .toUpperCase()}`,
          );
          setLocation('');
          break;

        case ConsultationStatusEnum.ONGOING:
          setDisplayName(
            `${props.serviceRequest?.subject?.humanName.given[0]} ${props.serviceRequest?.subject?.humanName.family}`,
          );
          setLocation(props.serviceRequest?.subject.location || '--');
          break;

        case ConsultationStatusEnum.COMPLETED:
          setDisplayName(
            `${props.serviceRequest?.subject?.humanName.given[0]} ${props.serviceRequest?.subject?.humanName.family}`,
          );
          setLocation(props.serviceRequest?.subject.location || '--');
          break;

        default:
          break;
      }

      if (props.serviceRequest?.procedure) {
        if (
          props.serviceRequest?.status === ConsultationStatusEnum.PENDING &&
          props.serviceRequest?.procedure?.status === ProcedureStatusEnum.TO_PAY
        ) {
          setDisplayName(
            `${props.serviceRequest?.subject?.humanName.given[0]} ${props.serviceRequest?.subject?.humanName.family}`,
          );
          setLocation(props.serviceRequest?.subject.location || '--');
        }
      }
    }
  }, [
    props.serviceRequest?.status,
    props.serviceRequest?.subject,
    props.serviceRequest?.procedure,
  ]);

  return (
    <View accessibilityRole="button" style={styles.mainContainer}>
      <TouchableOpacity
        activeOpacity={0.75}
        onPress={
          props.onBackClickFromList ? props.onBackClickFromList : onBackClick
        }>
        <View style={styles.backContainer}>
          <SVGIcon name="left-gray" size={20} />
        </View>
      </TouchableOpacity>

      <View style={styles.profileContainer}>
        <View style={styles.profilePhoto}>
          <ConsultationPhoto
            photo={props.serviceRequest?.subject?.photo}
            size={36}
          />
        </View>

        <View style={styles.nameProfessionContainer}>
          <RNEText
            label={
              props.serviceRequest?.subject ? displayName : 'Deleted Profile'
            }
            variant="button"
            weight="bold"
            numberOfLines={1}
          />
          {!!location && (
            <RNEText
              label={location}
              variant="button"
              weight="300"
              color="blackLight"
              numberOfLines={1}
            />
          )}
        </View>
      </View>
    </View>
  );
};

export default observer(HeaderConsultationPatient);

const styles = StyleSheet.create({
  mainContainer: {
    paddingHorizontal: 20,
    flexDirection: 'row',
    alignItems: 'center',
  },
  backContainer: {
    marginRight: 16,
  },
  profileContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  profilePhoto: {
    borderRadius: 50,
    borderWidth: 1,
    borderColor: colorPalette.primary,
    width: 36,
    height: 36,
    alignItems: 'center',
    justifyContent: 'center',
  },
  imageStyle: {
    height: 36,
    width: 36,
    borderRadius: 100,
    borderWidth: 1,
    borderColor: colorPalette.primary,
  },
  nameProfessionContainer: {
    marginLeft: 16,
    width: '65%',
  },
});
