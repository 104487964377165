import React, {memo, useRef} from 'react';
import {View, TouchableOpacity} from 'react-native';

import {makeStyles} from 'rne-netzon';
import {useHover} from 'react-native-web-hooks';

import {RNEText} from '../../components';

interface TableContentCellProps {
  label: string;
  active?: boolean;
  ascending?: boolean;
  hideSort?: boolean;
  onPress?: () => void;
}

const TableContentCell = (props: TableContentCellProps) => {
  const styles = useStyles();
  const ref = useRef(null);
  const isHovered = useHover(ref);

  return (
    <TouchableOpacity activeOpacity={0.8} onPress={props.onPress}>
      <View ref={ref} style={styles.root}>
        <RNEText
          label={props.label}
          variant="button"
          weight="600"
          style={isHovered && hoverStyle}
        />

        {!props.hideSort && (
          <View
            style={[
              styles.triangle,
              props.active && styles.active,
              isHovered && !props.active && styles.active,
              !props.ascending && styles.triangleDown,
            ]}
          />
        )}
      </View>
    </TouchableOpacity>
  );
};

const hoverStyle = {
  opacity: 0.7,
};

const useStyles = makeStyles(theme => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    height: '100%',
  },
  triangle: {
    marginLeft: 12,
    width: 0,
    height: 0,
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderLeftWidth: 5,
    borderRightWidth: 5,
    borderBottomWidth: 10,
    borderLeftColor: 'transparent',
    borderRightColor: 'transparent',
    borderBottomColor: '#ACBABD',
    opacity: 0,
  },
  triangleDown: {
    transform: [{rotate: '180deg'}],
  },
  active: {
    opacity: 1,
  },
}));

export default memo(TableContentCell);
