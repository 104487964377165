import React from 'react';
import {Platform, View} from 'react-native';
import {makeStyles} from 'rne-netzon';

import {ConsultationRequestResBody} from '../../server/types/consultation.types';

import ConsultationEnd from '../consultation/ConsultationEnd';

interface ConsultationEndModalProps {
  serviceRequest: ConsultationRequestResBody;
  onCancel?: () => void;
  onComplete?: () => void;
}

const ConsultationEndModal = (props: ConsultationEndModalProps) => {
  const styles = useStyles();

  return (
    <View style={styles.root}>
      <ConsultationEnd
        serviceRequest={props.serviceRequest}
        onCancel={props.onCancel}
        onComplete={props.onComplete}
      />
    </View>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    width: Platform.OS === 'web' ? 560 : '80%',
    paddingVertical: Platform.OS === 'web' ? 24 : 12,
    paddingHorizontal: Platform.OS === 'web' ? 32 : 20,
  },
}));

export default ConsultationEndModal;
