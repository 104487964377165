import React, {useState, useEffect} from 'react';
import {StyleSheet, Image, View, TouchableOpacity} from 'react-native';

import {observer} from 'mobx-react';

import useStores from '../../stores/useStores';

import {RNEText} from '../../components';

import {colorPalette} from '../../../core/config/color.config';

import ConsultationStatus from '../consultation/ConsultationStatus';
import ConsultationPhoto from '../consultation/ConsultationPhoto';

import {ConsultationStatusEnum} from '../../server/types/consultation.types';
import {ConsultationRequestResBody} from '../../server/types/consultation.types';

import {PopoverItemKey} from '../popover/data/popoverItems.data';
import ConsultationDoctorPopover from '../popover/ConsultationDoctorPopover';
import {ProcedureStatusEnum} from '../../server/types/procedure.types';

interface HeaderConsultationPatientWebProps {
  serviceRequest?: ConsultationRequestResBody;
  onMenuItemClick?: (itemKey: PopoverItemKey) => void;
}

const HeaderConsultationPatientWeb = (
  props: HeaderConsultationPatientWebProps,
) => {
  const {authStore} = useStores();

  const [displayName, setDisplayName] = useState('');
  const [location, setLocation] = useState('');

  useEffect(() => {
    if (props.serviceRequest?.subject) {
      switch (props.serviceRequest.status) {
        case ConsultationStatusEnum.PENDING:
          setDisplayName(
            `Patient ${props.serviceRequest.subject.patientId
              .substring(0, 6)
              .toUpperCase()}`,
          );
          setLocation('');
          break;

        case ConsultationStatusEnum.CANCELLED:
          setDisplayName(
            `Patient ${props.serviceRequest.subject.patientId
              .substring(0, 6)
              .toUpperCase()}`,
          );
          setLocation('');
          break;

        case ConsultationStatusEnum.DECLINED:
          setDisplayName(
            `Patient ${props.serviceRequest.subject.patientId
              .substring(0, 6)
              .toUpperCase()}`,
          );
          setLocation('');
          break;

        case ConsultationStatusEnum.ONGOING:
          setDisplayName(
            `${props.serviceRequest?.subject?.humanName.given[0]} ${props.serviceRequest?.subject?.humanName.family}`,
          );
          setLocation(props.serviceRequest?.subject.location || '--');
          break;

        case ConsultationStatusEnum.COMPLETED:
          setDisplayName(
            `${props.serviceRequest?.subject?.humanName.given[0]} ${props.serviceRequest?.subject?.humanName.family}`,
          );
          setLocation(props.serviceRequest?.subject.location || '--');
          break;

        default:
          break;
      }

      if (props.serviceRequest?.procedure) {
        if (
          props.serviceRequest?.status === ConsultationStatusEnum.PENDING &&
          props.serviceRequest?.procedure?.status === ProcedureStatusEnum.TO_PAY
        ) {
          setDisplayName(
            `${props.serviceRequest?.subject?.humanName.given[0]} ${props.serviceRequest?.subject?.humanName.family}`,
          );
          setLocation(props.serviceRequest?.subject.location || '--');
        }
      }
    }
  }, [
    props.serviceRequest?.status,
    props.serviceRequest?.subject,
    props.serviceRequest?.procedure,
  ]);

  return (
    <>
      <View style={styles.mainContainer}>
        <View style={styles.profileContainer}>
          <View style={styles.profilePhoto}>
            <ConsultationPhoto
              photo={props.serviceRequest?.subject?.photo}
              size={48}
            />
          </View>

          <View style={styles.nameProfessionContainer}>
            <RNEText
              label={
                props.serviceRequest?.subject ? displayName : 'Deleted Profile'
              }
              variant="body"
              weight="bold"
              numberOfLines={1}
            />
            {!!location && (
              <RNEText
                label={location}
                variant="button"
                weight="300"
                color="blackLight"
                numberOfLines={1}
              />
            )}
          </View>
        </View>

        {props.serviceRequest?.status === ConsultationStatusEnum.ONGOING ||
        props.serviceRequest?.status === ConsultationStatusEnum.COMPLETED ? (
          <ConsultationDoctorPopover
            status={props.serviceRequest.status}
            onMenuItemClick={props.onMenuItemClick}
          />
        ) : (
          <ConsultationStatus
            consultationStatus={props.serviceRequest?.status}
            procedureStatus={props.serviceRequest?.procedure?.status}
            isActionByPatient={
              !(
                authStore.auth?.user.userId ===
                  props.serviceRequest?.modifiedBy &&
                authStore.userRole === 'practitioner'
              )
            }
          />
        )}
      </View>
    </>
  );
};

export default observer(HeaderConsultationPatientWeb);

const styles = StyleSheet.create({
  mainContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 16,
    borderBottomWidth: 1,
    borderBottomColor: colorPalette.light,
  },
  profileContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  profilePhoto: {
    borderRadius: 50,
    borderWidth: 1,
    borderColor: colorPalette.primary,
    width: 48,
    height: 48,
    alignItems: 'center',
    justifyContent: 'center',
  },
  imageStyle: {
    height: 48,
    width: 48,
    borderRadius: 100,
    borderWidth: 1,
    borderColor: colorPalette.primary,
  },
  nameProfessionContainer: {
    marginLeft: 24,
    flex: 0.9,
  },
});
