import {AxiosRequestConfig} from 'axios';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {API, ApiEndpointsEnum} from '../api';
import {
  RoomReqBody,
  RoomResBody,
  GenerateTokenResBody,
} from '../types/video-call.types';

const generateToken = async (reqBody: RoomReqBody) => {
  let config: AxiosRequestConfig = {};

  if (reqBody.subjectId) {
    const headerAuthorization: any = {
      'X-Subject': reqBody.subjectId,
    };

    config = {
      headers: {
        ...headerAuthorization,
      },
    };
  }

  const url = ApiEndpointsEnum.POST_VIDEO_CALL_TOKEN.replace(
    '{roomId}',
    reqBody.roomId,
  );

  return API.post<GenerateTokenResBody>(url, reqBody, config);
};

const useGenerateToken = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ['generate-token'],
    (reqBody: RoomReqBody) => generateToken(reqBody),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['generate-token']);
      },
    },
  );
};

const createRoom = async (reqBody: RoomReqBody) => {
  let config: AxiosRequestConfig = {};

  if (reqBody.subjectId) {
    const headerAuthorization: any = {
      'X-Subject': reqBody.subjectId,
    };

    config = {
      headers: {
        ...headerAuthorization,
      },
    };
  }

  const url = ApiEndpointsEnum.PUT_VIDEO_CALL_ROOM.replace(
    '{roomId}',
    reqBody.roomId,
  );

  return API.put<RoomResBody>(url, reqBody, config);
};

const useCreateRoom = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ['create-room'],
    (reqBody: RoomReqBody) => createRoom(reqBody),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['create-room']);
      },
    },
  );
};

const checkRoom = async (reqBody: RoomReqBody) => {
  let config: AxiosRequestConfig = {};

  if (reqBody.subjectId) {
    const headerAuthorization: any = {
      'X-Subject': reqBody.subjectId,
    };

    config = {
      headers: {
        ...headerAuthorization,
      },
    };
  }

  const url = ApiEndpointsEnum.GET_VIDEO_CALL_ROOM.replace(
    '{roomId}',
    reqBody.roomId,
  );

  return API.get<RoomResBody>(url, config);
};

const useCheckRoom = (reqBody: RoomReqBody) => {
  return useQuery(
    ['check-room', {id: reqBody.roomId}],
    () => checkRoom(reqBody),
    {
      enabled: !!reqBody.roomId,
      cacheTime: 0,
    },
  );
};

const closeRoom = async (reqBody: RoomReqBody) => {
  let config: AxiosRequestConfig = {};

  if (reqBody.subjectId) {
    const headerAuthorization: any = {
      'X-Subject': reqBody.subjectId,
    };

    config = {
      headers: {
        ...headerAuthorization,
      },
    };
  }

  const url = ApiEndpointsEnum.PATCH_VIDEO_CALL_ROOM.replace(
    '{roomId}',
    reqBody.roomId,
  );

  return API.patch<RoomResBody>(url, reqBody, config);
};

const useCloseRoom = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ['close-room'],
    (reqBody: RoomReqBody) => closeRoom(reqBody),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['close-room']);
      },
    },
  );
};

export {useGenerateToken, useCreateRoom, useCheckRoom, useCloseRoom};
