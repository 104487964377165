import React, {memo} from 'react';
import {useEffect} from 'react';
import {useState} from 'react';
import {View} from 'react-native';

import {makeStyles} from 'rne-netzon';

import AllergiesView from '../medical-history/AllergiesView';
import {MedicalHistoryEnum} from '../medical-history/data/medicalHistory';
import FamilyConditionView from '../medical-history/FamilyConditionView';
import HospitalizationView from '../medical-history/HospitalizationView';
import MaintenanceView from '../medical-history/MaintenanceView';
import MedicalConditionView from '../medical-history/MedicalConditionView';
import PersonalAndSocialView from '../medical-history/PersonalAndSocialView';

interface MedicalHistoryModalProps {
  onCancel: () => void;
  onSuccess: () => void;
  medicalHistoryType: MedicalHistoryEnum;
}

function MedicalHistoryModal(props: MedicalHistoryModalProps) {
  const rneStyles = useStyles();

  const [view, setView] = useState(
    <MedicalConditionView
      onCancel={props.onCancel}
      onSuccess={props.onSuccess}
    />,
  );

  const switchView = (medicalHistoryType: MedicalHistoryEnum) => {
    switch (medicalHistoryType) {
      case MedicalHistoryEnum.ALLERGY_INTOLERANCE:
        setView(
          <AllergiesView
            onCancel={props.onCancel}
            onSuccess={props.onSuccess}
          />,
        );
        break;
      case MedicalHistoryEnum.CONDITION:
        setView(
          <MedicalConditionView
            onCancel={props.onCancel}
            onSuccess={props.onSuccess}
          />,
        );
        break;
      case MedicalHistoryEnum.FAMILY_MEMBER_HISTORY:
        setView(
          <FamilyConditionView
            onCancel={props.onCancel}
            onSuccess={props.onSuccess}
          />,
        );
        break;
      case MedicalHistoryEnum.MEDICATION_STATEMENT:
        setView(
          <MaintenanceView
            onCancel={props.onCancel}
            onSuccess={props.onSuccess}
          />,
        );
        break;
      case MedicalHistoryEnum.PERSONAL_AND_SOCIAL:
        setView(
          <PersonalAndSocialView
            onCancel={props.onCancel}
            onSuccess={props.onSuccess}
          />,
        );
        break;
      case MedicalHistoryEnum.PROCEDURE:
        setView(
          <HospitalizationView
            onCancel={props.onCancel}
            onSuccess={props.onSuccess}
          />,
        );
        break;
    }
  };

  useEffect(() => {
    switchView(props.medicalHistoryType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.medicalHistoryType]);

  return <View style={rneStyles.root}>{view}</View>;
}

const useStyles = makeStyles({
  root: {
    width: 700,
    margin: 60,
    height: '80%',
    justifyContent: 'center',
  },
});

export default memo(MedicalHistoryModal);
