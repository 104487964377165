import {useNavigation, useRoute} from '@react-navigation/native';
import React from 'react';
import {useState} from 'react';
import {useEffect} from 'react';
import {View, Platform, ScrollView, Image} from 'react-native';
import {useToast} from 'react-native-fast-toast';

import {makeStyles, Overlay} from 'rne-netzon';
import {colorPalette} from '../../../core/config/color.config';

import {useForm, Controller} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';

import {RNEText, RNEInput, RNEButton, SVGIcon} from '../../components';
import {NavigationKey} from '../../navigations/config';
import {useAddConsultationRequest} from '../../server/react-query/useConsultation';
import {useGetPractitionerProfile} from '../../server/react-query/usePractitioner';
import {ConsultationRequestReqBody} from '../../server/types/consultation.types';
import useStores from '../../stores/useStores';
import {formatAmount} from '../../utils/currency.utils';
import {ConsultationRequestSentModal} from '../../views';
import {useSendMobilePushNotification} from '../../server/react-query/usePushNotification';
import {REACT_APP_BASE_WEB_URL} from '../../server/api/api.config';

const consultationQuestions = [
  'When you first noticed the appearance of symptoms.',
  'What medications you took (if any). ',
  'What other symptoms accompanied the first symptom.',
];

const schema: yup.SchemaOf<ConsultationRequestReqBody> = yup.object({
  performer: yup.string().trim().required('This field is required.'),
  subject: yup.string().trim().required('This field is required.'),
  note: yup.string().trim().required('This field is required.'),
  code: yup.object().optional(),
});

const PatientConsultationRequestScreen = () => {
  const webStyles = useWebStyles();
  const route = useRoute();
  const navigation = useNavigation();
  const toast = useToast();
  const {patientProfileStore, authStore} = useStores();
  const {
    control,
    handleSubmit,
    getValues,
    reset,
    setValue,
    formState: {errors, isValid},
  } = useForm<ConsultationRequestReqBody>({
    mode: 'all',
    defaultValues: {
      performer: '',
      subject: '',
      note: '',
    },
    resolver: yupResolver(schema),
  });

  const [practitionerId, setPractitionerId] = useState('');
  const [serviceRequestId, setServiceRequestId] = useState('');
  const [showDialog, setShowDialog] = useState(false);
  const [navProps, setNavProps] = useState({
    procedureId: '',
    subjectId: '',
    receiver: '',
    serviceRequestId: '',
  });

  const {data, isFetching, refetch} = useGetPractitionerProfile({
    practitionerId: practitionerId,
  });

  const patientId = patientProfileStore.activeProfileData?.patientId!;

  const mutation = useAddConsultationRequest(getValues());

  const addConsultationRequest = () => {
    handleSubmit(onSubmit)();
  };

  const triggerPushNotification = useSendMobilePushNotification({
    title: 'New consultation request for the doctor',
    body: 'You have received a new consultation request.',
    consultation: `"${JSON.stringify(navProps)}"`,
    notificationUrl: `${REACT_APP_BASE_WEB_URL}main/consultation/detail?id=${serviceRequestId}&screen=ongoing&tab=1`,
    fromUserId: authStore.authData?.user.userId!,
    toUserId: data?.userId!,
  });

  const onSubmit = async () => {
    if (isValid) {
      try {
        const response = await mutation.mutateAsync();
        reset();
        mutation.reset();

        setServiceRequestId(response.serviceRequestId);
        setShowDialog(true);

        setNavProps({
          procedureId: response.procedure?.procedureId!,
          subjectId: response.subject.patientId,
          receiver: response.performer.practitionerId,
          serviceRequestId: response.serviceRequestId,
        });

        triggerPushNotification.mutateAsync();
      } catch (error) {
        if (error?.data?.error) {
          toast?.show(error?.data?.error, {type: 'danger'});
        }
      }
    }
  };

  const viewConsultationRequest = () => {
    setShowDialog(false);
    if (Platform.OS === 'web') {
      navigation.navigate(NavigationKey.PATIENT_CONSULTATION_STACK, {
        screen: NavigationKey.CONSULTATION_LIST,
        params: {
          tab: 1,
          id: serviceRequestId,
        },
      });
    } else {
      navigation.navigate(NavigationKey.PATIENT_CONSULTATION_STACK, {
        screen: NavigationKey.CONSULTATION_LIST,
        params: {
          screen: NavigationKey.CONSULTATION_TAB_PENDING,
        },
      });
    }
  };

  useEffect(() => {
    const params = route.params as any;

    if (params && params.practitionerId) {
      setPractitionerId(params.practitionerId);
      setValue('performer', params.practitionerId);
      setValue('subject', patientId);
    }
  }, [route.params, patientId, setValue]);

  useEffect(() => {
    if (practitionerId) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [practitionerId]);

  return (
    <>
      <Overlay
        isVisible={showDialog}
        overlayStyle={webStyles.overlayContainerWeb}>
        <ConsultationRequestSentModal onConfirm={viewConsultationRequest} />
      </Overlay>
      <ScrollView>
        <View style={webStyles.mainContainer}>
          <View style={webStyles.titleContainer}>
            <RNEText
              label="Consultation Request"
              variant="title"
              weight="bold"
              color="accent"
            />
            <View style={webStyles.spinnerContainer}>
              <RNEButton
                height={20}
                loadingProps={loadingPropsStyle}
                loading={isFetching}
              />
            </View>
          </View>
          <View style={webStyles.mainWrapper}>
            <View style={webStyles.leftWrapper}>
              <View style={webStyles.topWrapper}>
                <RNEText
                  label="You are requesting a consultation from:"
                  style={subtitleFontSize20}
                />
              </View>
              <View style={webStyles.bottomWrapper}>
                <RNEText
                  label="In your message, make sure to indicate:"
                  variant="subheader"
                  style={subtitleFontSize20}
                />

                <View style={webStyles.questionsContainer}>
                  {consultationQuestions.map((question, index) => (
                    <View key={index} style={webStyles.questionItem}>
                      <RNEText
                        label={`${(index + 1).toString()}. `}
                        variant="body"
                        style={listTextStyle}
                      />
                      <RNEText
                        key={index}
                        label={question}
                        variant="body"
                        style={listTextStyle}
                      />
                    </View>
                  ))}
                </View>
              </View>
            </View>
            <View style={webStyles.rightWrapper}>
              <View style={webStyles.topWrapper}>
                <View style={webStyles.boxContainer}>
                  <View style={webStyles.photoContainer}>
                    {data?.photo ? (
                      <Image
                        source={{uri: data.photo.url}}
                        style={webStyles.imageStyle}
                      />
                    ) : (
                      <SVGIcon name="user" size={36} />
                    )}
                  </View>
                  <View style={webStyles.textGroupContainer}>
                    <View style={webStyles.infoContainer}>
                      <RNEText
                        label={`Dr. ${data?.humanName.given[0]} ${data?.humanName.family}`}
                        variant="body"
                        weight="bold"
                        color="accent"
                      />
                      <RNEText
                        label={
                          data?.organization[0].address?.city
                            ? `${data?.practitionerRole[0].specialty.text} at ${data?.organization[0].address?.city}`
                            : `${data?.practitionerRole[0].specialty.text}`
                        }
                        variant="body"
                        style={subtitleStyle}
                      />
                    </View>
                    <View style={webStyles.feeContainer}>
                      <RNEText
                        label={`${data?.fee.amount.currency} ${formatAmount(
                          data?.fee.amount.value || 0,
                        )}`}
                        variant="body"
                        weight="bold"
                      />
                      <RNEText
                        label="Consultation Fee"
                        variant="body"
                        style={subtitleStyle}
                      />
                    </View>
                  </View>
                </View>
              </View>
              <View style={webStyles.bottomWrapper}>
                <Controller
                  control={control}
                  render={({field: {onChange, onBlur, value}}) => (
                    <RNEInput
                      height={240}
                      variant="shadowed"
                      placeholder="Your message here..."
                      value={value || ''}
                      onChangeText={text => {
                        onChange(text);
                      }}
                      errorMessage={errors.note?.message}
                      multiline={true}
                      inputStyle={inputStyle}
                      inputContainerStyle={inputContainerWebStyle}
                    />
                  )}
                  name="note"
                />
              </View>
              <View style={webStyles.footerWrapper}>
                <View style={webStyles.buttonsContainer}>
                  <View style={webStyles.buttonContainer}>
                    <RNEButton
                      title="Cancel"
                      color="secondary"
                      style={webStyles.buttonStyle}
                      onPress={navigation.goBack}
                      variant="outlined"
                    />
                  </View>
                  <RNEButton
                    title="Send"
                    color="secondary"
                    style={webStyles.buttonStyle}
                    onPress={addConsultationRequest}
                    loading={mutation.isLoading}
                  />
                </View>
              </View>
            </View>
          </View>
        </View>
      </ScrollView>
    </>
  );
};

export default PatientConsultationRequestScreen;

const listTextStyle = {
  lineHeight: Platform.OS === 'web' ? 32 : 24,
};

const subtitleStyle = {
  paddingTop: 4,
};

const inputContainerWebStyle = {
  padding: 20,
  borderRadius: 20,
};

const inputContainerMobileStyle = {
  borderRadius: 28,
  padding: 20,
};

const inputStyle = {
  lineHeight: 24,
};

const loadingPropsStyle = {
  color: colorPalette.primary,
};

const subtitleFontSize20 = {
  fontSize: 20,
};

const useWebStyles = makeStyles({
  mainContainer: {
    paddingTop: 92,
    paddingBottom: 32,
    paddingHorizontal: 120,
    width: '100%',
  },
  titleContainer: {
    marginBottom: 40,
    borderBottomColor: 'rgba(229, 229, 229, 0.7)',
    borderBottomWidth: 1,
  },
  spinnerContainer: {
    alignSelf: 'flex-end',
    marginBottom: 8,
  },
  mainWrapper: {
    flexDirection: 'row',
    flex: 2,
  },
  leftWrapper: {
    width: 300,
    paddingRight: 48,
  },
  rightWrapper: {
    flex: 1,
  },
  topWrapper: {
    height: 160,
  },
  bottomWrapper: {
    height: 300,
  },
  questionsContainer: {
    paddingTop: 40,
  },
  questionItem: {
    flexDirection: 'row',
  },
  boxContainer: {
    flexDirection: 'row',
    borderColor: colorPalette.light,
    borderWidth: 1,
    borderRadius: 20,
    padding: 20,
    flex: 1,
    marginBottom: 40,
  },
  textGroupContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignSelf: 'center',
    flex: 1,
  },
  infoContainer: {
    paddingRight: 20,
    flex: 0.7,
  },
  feeContainer: {
    flex: 0.3,
  },
  footerWrapper: {
    marginTop: 40,
  },
  buttonsContainer: {
    justifyContent: 'flex-end',
    flexDirection: 'row',
  },
  buttonContainer: {
    marginRight: 20,
  },
  buttonStyle: {
    width: 180,
  },
  overlayContainerWeb: {
    borderRadius: 16,
  },
  photoContainer: {
    marginRight: 20,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    borderWidth: 1,
    borderColor: colorPalette.light,
    borderRadius: 50,
    height: 68,
    width: 68,
  },
  imageStyle: {
    height: 68,
    width: 68,
    borderRadius: 50,
  },
});
