import AsyncStorage from '@react-native-async-storage/async-storage';
import {PersistenceStore} from 'mobx-persist-store/lib/types';
import {persistence, StorageAdapter} from 'mobx-persist-store';

const readStore = (name: string) => {
  return new Promise<any>(async resolve => {
    const itemData = await AsyncStorage.getItem(name);
    resolve(itemData);
  });
};

const writeStore = (name: string, content: any) => {
  return new Promise<void>(async resolve => {
    await AsyncStorage.setItem(name, content);
    resolve();
  });
};

export const persistStore = <T extends Record<string, any>, P extends keyof T>(
  target: T,
  properties: P[],
  persistName: string,
): T | PersistenceStore<T> => {
  return persistence({
    name: persistName,
    properties: properties as string[],
    adapter: new StorageAdapter({
      read: async name => {
        return readStore(name);
      },
      write: async (name, content) => {
        writeStore(name, content);
      },
    }),
    reactionOptions: {
      delay: 200,
    },
  })(target);
};
