import React, {memo} from 'react';
import {View} from 'react-native';

import {makeStyles} from 'rne-netzon';
import AccountDeletionRequest from '../profile-deletion/AccountDeletionRequest';

interface AccountDeletionModalProps {
  onCancel: () => void;
  onDoctorDelete?: () => void;
  patientId?: string;
  forDoctor?: boolean;
  accountOwner?: boolean;
}

function AccountDeletionModal(props: AccountDeletionModalProps) {
  const rneStyles = useStyles();

  return (
    <View style={rneStyles.root}>
      <AccountDeletionRequest
        onCancel={props.onCancel}
        onDoctorDelete={props.onDoctorDelete}
        patientId={props.patientId}
        forDoctor={props.forDoctor}
        accountOwner={props.accountOwner}
      />
    </View>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 600,
    padding: 32,
    justifyContent: 'center',
  },
  actionContainer: {
    marginTop: 80,
    justifyContent: 'center',
    flexDirection: 'row',
  },
  buttonContainer: {
    marginRight: 16,
  },
  buttonStyle: {
    width: 120,
  },
  overlayContainer: {
    borderRadius: 20,
  },
}));

export default memo(AccountDeletionModal);
