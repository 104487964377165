import {LoginReqResponse} from '../types/authentication.types';

export const LoginUserMock: LoginReqResponse = {
  token:
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzaWQiOiJiMGRmMzcwNy1iMzg4LTQ4ZDYtOGYwYi02MGYxNzJmNmRjOTUiLCJwaWQiOiI3Y2MxNDFlNC0xYzVmLTRkOTItYmYzYy0xZTg4N2ZlNTlhOGMiLCJodHRwOi8vc2NoZW1hcy5taWNyb3NvZnQuY29tL3dzLzIwMDgvMDYvaWRlbnRpdHkvY2xhaW1zL3JvbGUiOiJQYXRpZW50In0.0aUSKAJ-NHirWyI69BGCve4MEb-zOI0q6pvEelrC_9Q',
  user: {
    userId: '31bcbaa4-33a9-4d56-8fad-516623716248',
    userRole: 'patient',
    email: 'test@email.com',
    firstName: 'John',
    middleName: 'Mark',
    lastName: 'Doe',
    registrationStatus: 'fullyVerified',
  },
};
