import React, {useEffect, useLayoutEffect, useState} from 'react';
import {View, Platform, ScrollView, TextStyle} from 'react-native';
import {makeStyles, Overlay} from 'rne-netzon';
import {colorPalette} from '../../../core/config/color.config';

import {useNavigation} from '@react-navigation/native';
import {NavigationKey} from '../../navigations/config';

import {
  RNEText,
  SVGIcon,
  RNEInput,
  RNEButton,
  ImageSelector,
  RNSearchableDropdownOption,
  RNSearchableDropdown,
} from '../../components';

import {useForm, Controller} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {useToast} from 'react-native-fast-toast';
import {formatDateString, parseDateString} from '../../utils/date.utils';
import {
  EditPractitionerProfileReqUIBody,
  UploadPractitionerProfilePhotoReqBody,
} from '../../server/types/practitioner-profile.types';
import {
  useEditDoctorProfile,
  useGetProfessionalProfile,
  useUploadPractitionerProfile,
} from '../../server/react-query/usePractitioner';
import useStores from '../../stores/useStores';
import {format} from 'date-fns';
import {PractitionerStatus} from '../../server/types/practitioner-status.types';
import {HeaderBackLeft, RequestVerificationModal} from '../../views';

const schema: yup.SchemaOf<EditPractitionerProfileReqUIBody> = yup.object({
  firstName: yup.string().trim().required('This field is required.'),
  middleName: yup.string().trim().required('This field is required.'),
  lastName: yup.string().trim().required('This field is required.'),
  specialty: yup.string().trim().required('This field is required.'),
  // hospital: yup.string().trim().required('This field is required.'),
  city: yup.string().trim(),
  licenseNumber: yup.string().trim().required('This field is required.'),
  consultationFee: yup
    .number()
    .min(0, 'Cannot set negative consultation fee.')
    .typeError('Amount must be a number')
    .required('This field is required.'),
  handledCases: yup.string(),
  birthdate: yup
    .string()
    .trim()
    .matches(/^\d{4}-\d{2}-\d{2}$/, {
      message: 'Invalid date format (yyyy-mm-dd)',
      excludeEmptyString: true,
    })
    .test('test-invalid-date', 'Invalid date value', parseDateString),
  bankName: yup.string().trim().required('This field is required.'),
  bankAccount: yup.string().trim().required('This field is required.'),
});

const DoctorEditProfessionalProfileScreen = () => {
  const navigation = useNavigation();
  const webStyles = useWebStyles();
  const sharedStyles = useSharedStyles();
  const {specializationStore, hospitalStore} = useStores();

  const [fetchedPhoto, setFetchedPhoto] = useState('');
  const [photo, setPhoto] = useState<UploadPractitionerProfilePhotoReqBody>({
    image: null,
  });
  const [showDialog, setShowDialog] = useState(false);

  const [specializationOptions, setSpecializationOptions] = useState<
    RNSearchableDropdownOption[]
  >([]);
  // const [hospitalOptions, setHospitalOptions] = useState<
  //   RNSearchableDropdownOption[]
  // >([]);

  const {
    control,
    handleSubmit,
    getValues,
    reset,
    setValue,
    formState: {errors, isValid},
  } = useForm<EditPractitionerProfileReqUIBody>({
    mode: 'all',
    defaultValues: {
      firstName: '',
      middleName: '',
      lastName: '',
      birthdate: '',
      specialty: '',
      // hospital: '',
      city: '',
      licenseNumber: '',
      consultationFee: 0,
      handledCases: '',
      bankName: '',
      bankAccount: '',
    },
    resolver: yupResolver(schema),
  });

  const toast = useToast();

  const mutation = useEditDoctorProfile(getValues());
  const uploadMutation = useUploadPractitionerProfile(photo);
  const {status, data, error, isFetching} = useGetProfessionalProfile();

  useEffect(() => {
    setSpecializationOptions(
      specializationStore.specializations.map(specialization => ({
        name: specialization.value,
        value: specialization.specializationId,
      })),
    );
  }, [specializationStore.specializations]);

  // useEffect(() => {
  //   setHospitalOptions(
  //     hospitalStore.hospitals.map(hospital => ({
  //       name: hospital.value,
  //       value: hospital.hospitalId,
  //     })),
  //   );
  // }, [hospitalStore.hospitals]);

  useEffect(() => {
    reset({
      firstName: data?.humanName?.given[0]!,
      middleName: data?.humanName?.given[1]!,
      lastName: data?.humanName?.family!,
      birthdate: data?.birthdate
        ? format(new Date(data?.birthdate), 'yyyy-MM-dd')
        : '',
      specialty: valueAssignment(
        data?.practitionerRole[0]
          ? data.practitionerRole[0].specializationId
          : null,
      ),
      // hospital: valueAssignment(
      //   data?.organization[0] ? data?.organization[0]?.hospitalId : null,
      // ),
      city: data?.organization[0]?.address?.city,
      licenseNumber: valueAssignment(
        data?.qualification ? data?.qualification.identifier : '',
      ),
      consultationFee: valueAssignment(data?.fee ? data?.fee.amount.value : 0),
      handledCases: valueAssignment(
        data?.handledCases ? data.handledCases : '',
      ),
      bankName: data?.bankName,
      bankAccount: data?.bankAccount,
    });

    setFetchedPhoto(data?.photo ? data.photo.url : '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const editDoctorProfile = () => {
    handleSubmit(onSubmit)();
  };

  const onSubmit = async () => {
    if (isValid || photo.image) {
      try {
        await mutation.mutateAsync();

        if (photo.image) {
          await uploadMutation.mutateAsync();
          uploadMutation.reset();
        }

        mutation.reset();

        if (
          data?.status === PractitionerStatus.VERIFIED ||
          data?.status === PractitionerStatus.PENDING
        ) {
          goToViewProfile();
        } else {
          setShowDialog(true);
        }
      } catch (err) {
        if (err?.data?.err) {
          toast?.show(err?.data?.err, {type: 'danger'});
        }
      }
    }
  };

  const goToViewProfile = () => {
    reset();
    navigation.navigate(NavigationKey.DOCTOR_VIEW_PROFESSIONAL_PROFILE);
  };

  const valueAssignment = (value: any) => {
    return value ? value : '';
  };

  const getFile = (file: any | null) => {
    setPhoto({
      image: file,
    });
  };

  const loadingPropsStyle = {
    color: colorPalette.black,
  };

  useLayoutEffect(() => {
    if (Platform.OS !== 'web') {
      navigation.setOptions({
        title: 'Edit Professional Profile',
        headerStyle: {
          borderBottomColor: 'transparent',
        },
        headerTintColor: colorPalette.blackLight,
        headerTitleStyle: {
          fontFamily: 'Quicksand-Bold',
          fontSize: 14,
          fontWeight: 'bold',
          alignSelf: 'center',
          marginStart: -28,
        } as TextStyle,
        headerLeft: () => (
          <HeaderBackLeft removeLabel={true} iconName="left-gray" />
        ),
      });
    }
  }, [navigation]);

  return (
    <>
      <Overlay
        isVisible={showDialog}
        overlayStyle={sharedStyles.overlayContainer}>
        <RequestVerificationModal />
      </Overlay>
      <ScrollView>
        <View style={webStyles.mainContainer}>
          <View style={webStyles.topBackground}>
            <View style={webStyles.titleContainer}>
              <RNEText label="Dashboard > " variant="body" color="white" />
              <RNEText
                label="Edit Professional Profile"
                variant="body"
                color="white"
                weight="bold"
              />
            </View>
            <View style={webStyles.headerContainer}>
              <ImageSelector
                onSelectPhoto={getFile}
                providedPhoto={fetchedPhoto}
              />
              <View>
                <RNEText
                  label="Edit Professional Profile"
                  variant="header"
                  color="white"
                  weight="bold"
                />
                <View style={webStyles.dateContainer}>
                  <RNEText
                    label={`Date Updated: ${formatDateString(
                      data?.modifiedOn,
                    )}`}
                    variant="body"
                    color="white"
                  />
                </View>
              </View>
            </View>
          </View>
          <View style={webStyles.formContainer}>
            <View style={webStyles.formTitleContainer}>
              <RNEText
                label="Professional Profile"
                variant="body"
                color="black"
                weight="bold"
              />
              <RNEButton
                height={20}
                loadingProps={loadingPropsStyle}
                loading={isFetching}
              />
            </View>

            <View style={webStyles.inputGroupContainer}>
              <View style={webStyles.nameInputGroupContainer}>
                <View style={webStyles.inputFirstNameContainer}>
                  <View style={webStyles.inputLabel}>
                    <RNEText
                      label="First Name"
                      variant="button"
                      color="accent"
                      weight="bold"
                    />
                  </View>
                  <Controller
                    control={control}
                    render={({field: {onChange, onBlur, value}}) => (
                      <RNEInput
                        placeholder="First Name"
                        value={value || ''}
                        onChangeText={text => {
                          onChange(text);
                        }}
                        errorMessage={
                          isFetching ? undefined : errors.firstName?.message
                        }
                        variant="shadowed"
                      />
                    )}
                    name="firstName"
                  />
                </View>

                <View style={webStyles.inputNameContainer}>
                  <View style={webStyles.inputLabel}>
                    <RNEText
                      label="Middle Name"
                      variant="button"
                      color="accent"
                      weight="bold"
                    />
                  </View>
                  <Controller
                    control={control}
                    render={({field: {onChange, onBlur, value}}) => (
                      <RNEInput
                        placeholder="Middle Name"
                        value={value || ''}
                        onChangeText={text => onChange(text)}
                        errorMessage={
                          isFetching ? undefined : errors.middleName?.message
                        }
                        variant="shadowed"
                      />
                    )}
                    name="middleName"
                  />
                </View>

                <View style={webStyles.inputNameContainer}>
                  <View style={webStyles.inputLabel}>
                    <RNEText
                      label="Last Name"
                      variant="button"
                      color="accent"
                      weight="bold"
                    />
                  </View>
                  <Controller
                    control={control}
                    render={({field: {onChange, onBlur, value}}) => (
                      <RNEInput
                        placeholder="Last Name"
                        value={value || ''}
                        onChangeText={text => onChange(text)}
                        errorMessage={
                          isFetching ? undefined : errors.lastName?.message
                        }
                        variant="shadowed"
                      />
                    )}
                    name="lastName"
                  />
                </View>
              </View>

              <View
                style={[webStyles.nameInputGroupContainer, webStyles.dropdown]}>
                <View style={webStyles.inputFirstNameContainer}>
                  <View style={webStyles.inputLabel}>
                    <RNEText
                      label="Specialization"
                      variant="button"
                      color="accent"
                      weight="bold"
                    />
                  </View>
                  <Controller
                    control={control}
                    render={({field: {onChange, onBlur, value}}) => (
                      <RNSearchableDropdown
                        value={value}
                        placeholder="Specialization"
                        options={specializationOptions}
                        onChange={text => onChange(text)}
                      />
                    )}
                    name="specialty"
                  />
                </View>

                <View style={webStyles.inputNameContainer}>
                  <View style={webStyles.inputLabel}>
                    <RNEText
                      label="City"
                      variant="button"
                      color="accent"
                      weight="bold"
                    />
                  </View>
                  <Controller
                    control={control}
                    render={({field: {onChange, onBlur, value}}) => (
                      <RNEInput
                        placeholder="City"
                        leftIcon={<SVGIcon name="map" />}
                        value={value || ''}
                        onChangeText={text => onChange(text)}
                        errorMessage={
                          isFetching ? undefined : errors.city?.message
                        }
                        variant="shadowed"
                      />
                    )}
                    name="city"
                  />
                  {/* <Controller
                      control={control}
                      render={({field: {onChange, onBlur, value}}) => (
                        <RNSearchableDropdown
                          value={value}
                          placeholder="Hospital"
                          options={hospitalOptions}
                          onChange={text => onChange(text)}
                        />
                      )}
                      name="hospital"
                    /> */}
                </View>

                <View style={webStyles.inputNameContainer}>
                  <View style={webStyles.inputLabel}>
                    <RNEText
                      label="License Number"
                      variant="button"
                      color="accent"
                      weight="bold"
                    />
                  </View>
                  <Controller
                    control={control}
                    render={({field: {onChange, onBlur, value}}) => (
                      <RNEInput
                        placeholder="License Number"
                        leftIcon={
                          <SVGIcon name="allied-health-professionals" />
                        }
                        value={value || ''}
                        onChangeText={text => onChange(text)}
                        errorMessage={
                          isFetching ? undefined : errors.licenseNumber?.message
                        }
                        variant="shadowed"
                      />
                    )}
                    name="licenseNumber"
                  />
                </View>
              </View>

              <View style={webStyles.nameInputGroupContainer}>
                <View style={webStyles.inputFirstNameContainer}>
                  <View style={webStyles.inputLabel}>
                    <RNEText
                      label="Birthdate"
                      variant="button"
                      color="accent"
                      weight="bold"
                    />
                  </View>
                  <Controller
                    control={control}
                    render={({field: {onChange, onBlur, value}}) => (
                      <RNEInput
                        placeholder="Birthdate"
                        leftIcon={<SVGIcon name="calendar" />}
                        value={value || ''}
                        onChangeText={text => {
                          onChange(text);
                        }}
                        errorMessage={errors.birthdate?.message}
                        variant="shadowed"
                      />
                    )}
                    name="birthdate"
                  />
                </View>

                <View style={webStyles.inputNameContainer}>
                  <View style={webStyles.inputLabel}>
                    <RNEText
                      label="Consultation Fee*"
                      variant="button"
                      color="accent"
                      weight="bold"
                    />
                  </View>
                  <Controller
                    control={control}
                    render={({field: {onChange, onBlur, value}}) => (
                      <RNEInput
                        keyboardType="numeric"
                        placeholder="Consultation Fee"
                        leftIcon={<SVGIcon name="price" />}
                        value={value.toString()}
                        onChangeText={text => {
                          onChange(text);
                        }}
                        errorMessage={errors.consultationFee?.message}
                        variant="shadowed"
                      />
                    )}
                    name="consultationFee"
                  />
                </View>
                <View style={webStyles.inputNameContainer}>
                  <View style={webStyles.inputLabel}>
                    <RNEText
                      label="*Consultation Fee"
                      variant="body"
                      color="black"
                    />
                  </View>
                  <View style={webStyles.inputLabel}>
                    <RNEText
                      label="A Housecall processing fee will be added on top of your consultation fee per transaction."
                      variant="body"
                      color="black"
                    />
                  </View>
                </View>
              </View>

              <View>
                <View style={webStyles.historyTitleContainer}>
                  <RNEText
                    label="Cases I usually handle"
                    variant="body"
                    color="black"
                    weight="bold"
                  />
                </View>

                <View style={webStyles.sectionContainer}>
                  <View style={webStyles.casesContainer}>
                    <Controller
                      control={control}
                      render={({field: {onChange, onBlur, value}}) => (
                        <RNEInput
                          placeholder="Cases I usually handle"
                          value={value || ''}
                          onChangeText={text => onChange(text)}
                          errorMessage={errors.handledCases?.message}
                          variant="shadowed"
                          multiline
                          height={100}
                          inputStyle={inputStyle}
                          inputContainerStyle={inputContainerWebStyle}
                        />
                      )}
                      name="handledCases"
                    />
                  </View>
                </View>
              </View>

              <View style={webStyles.bankTitleContainer}>
                <RNEText
                  label="Bank Details"
                  variant="body"
                  color="black"
                  weight="bold"
                />
              </View>

              <View style={webStyles.nameInputGroupContainer}>
                <View style={webStyles.inputFirstNameContainer}>
                  <View style={webStyles.inputLabel}>
                    <RNEText
                      label="Bank Name"
                      variant="button"
                      color="accent"
                      weight="bold"
                    />
                  </View>
                  <Controller
                    control={control}
                    render={({field: {onChange, onBlur, value}}) => (
                      <RNEInput
                        placeholder="Bank Name"
                        leftIcon={<SVGIcon name="home" />}
                        value={value || ''}
                        onChangeText={text => {
                          onChange(text);
                        }}
                        errorMessage={
                          isFetching ? undefined : errors.firstName?.message
                        }
                        variant="shadowed"
                      />
                    )}
                    name="bankName"
                  />
                </View>

                <View style={webStyles.inputNameContainer}>
                  <View style={webStyles.inputLabel}>
                    <RNEText
                      label="Account Number"
                      variant="button"
                      color="accent"
                      weight="bold"
                    />
                  </View>
                  <Controller
                    control={control}
                    render={({field: {onChange, onBlur, value}}) => (
                      <RNEInput
                        placeholder="* * * * * * * * * * 246"
                        leftIcon={<SVGIcon name="pay" />}
                        value={value || ''}
                        onChangeText={text => {
                          onChange(text);
                        }}
                        errorMessage={
                          isFetching ? undefined : errors.firstName?.message
                        }
                        variant="shadowed"
                      />
                    )}
                    name="bankAccount"
                  />
                </View>

                <View style={webStyles.inputNameContainer} />
              </View>

              <View style={webStyles.buttonsContainer}>
                <View style={webStyles.buttonContainer}>
                  <RNEButton
                    title="Cancel"
                    color="accent"
                    style={webStyles.buttonStyle}
                    onPress={goToViewProfile}
                    variant="outlined"
                  />
                </View>
                <RNEButton
                  title="Save"
                  color="secondary"
                  style={webStyles.buttonStyle}
                  onPress={editDoctorProfile}
                  loading={mutation.isLoading || uploadMutation.isLoading}
                  disabled={!isValid}
                />
              </View>
            </View>
          </View>
        </View>
      </ScrollView>
    </>
  );
};

export default DoctorEditProfessionalProfileScreen;

const inputContainerWebStyle = {
  padding: 20,
};

const inputContainerMobileStyle = {
  padding: 8,
  borderRadius: 16,
};

const inputStyle = {
  lineHeight: 24,
};

const useWebStyles = makeStyles({
  mainContainer: {
    flex: 1,
    height: '100%',
    width: '100%',
    backgroundColor: '#F6F7FB',
  },
  topBackground: {
    backgroundColor: colorPalette.purple,
    borderBottomRightRadius: 50,
    height: 420,
  },
  titleContainer: {
    flexDirection: 'row',
    marginHorizontal: 100,
    paddingVertical: 20,
    borderBottomWidth: 1,
    borderBottomColor: colorPalette.white,
  },
  headerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 100,
    paddingVertical: 60,
  },
  userIcon: {
    backgroundColor: colorPalette.white,
    marginRight: 48,
    borderRadius: 100,
    height: 140,
    width: 140,
    justifyContent: 'center',
    alignItems: 'center',
  },
  imageWrapper: {
    width: '100%',
    height: '100%',
    borderRadius: 100,
  },
  cameraIcon: {
    backgroundColor: colorPalette.white,
    borderRadius: 50,
    height: 52,
    width: 52,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 156,
    left: 300,
  },
  formContainer: {
    backgroundColor: colorPalette.white,
    borderRadius: 20,
    marginHorizontal: 100,
    top: -100,
    paddingTop: 64,
    paddingBottom: 40,
    paddingHorizontal: 60,
  },
  formTitleContainer: {
    paddingLeft: 24,
    flexDirection: 'row',
  },
  inputGroupContainer: {
    width: '100%',
    marginVertical: 40,
  },
  nameInputGroupContainer: {
    flexDirection: 'row',
    marginBottom: 40,
    zIndex: 1,
  },
  dropdown: {
    zIndex: 2,
  },
  inputContainer: {
    marginTop: 16,
    flex: 1,
  },
  inputFirstNameContainer: {
    flex: 1,
  },
  inputNameContainer: {
    marginLeft: 24,
    flex: 1,
  },
  inputLabel: {
    paddingLeft: 24,
    marginBottom: 4,
  },
  historyTitleContainer: {
    paddingLeft: 24,
  },
  sectionContainer: {
    marginLeft: 24,
    marginVertical: 20,
  },
  sectionTitleContainer: {
    flexDirection: 'row',
  },
  sectionTitleText: {
    justifyContent: 'center',
    marginLeft: 32,
  },
  cardContainer: {
    marginLeft: -20,
    marginTop: 28,
    width: '100%',
  },
  casesContainer: {
    marginLeft: -20,
    marginTop: 0,
    width: '100%',
  },
  cardText: {
    width: '100%',
  },
  buttonsContainer: {
    marginTop: 40,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  rightButtonContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  buttonContainer: {
    marginRight: 20,
  },
  buttonStyle: {
    width: 180,
  },
  dateContainer: {
    height: 48,
    justifyContent: 'center',
  },
  overlayContainerWeb: {
    borderRadius: 16,
  },
  bankTitleContainer: {
    paddingBottom: 16,
    paddingLeft: 24,
  },
});

const useSharedStyles = makeStyles({
  overlayContainer: {
    borderRadius: 20,
  },
});
