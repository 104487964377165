import React from 'react';
import {View, Text} from 'react-native';

import {makeStyles, Divider} from 'rne-netzon';

import {colorPalette} from '../../../core/config/color.config';

interface TableBodyRowProps {
  children: React.ReactNode;
}

const TableBodyRow = (props: TableBodyRowProps) => {
  const styles = useStyle();

  return (
    <>
      <View style={styles.rootContainer}>{props.children}</View>
      <Divider style={styles.divider} />
    </>
  );
};

const useStyle = makeStyles({
  rootContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 68,
  },
  divider: {
    height: 1,
    backgroundColor: colorPalette.light,
  },
});

export default TableBodyRow;
