import React, {memo, useState, useEffect} from 'react';
import SelectSearch from 'react-select-search';

import './RNSearchableDropdown.css';

import Fuse from 'fuse.js';

const fuzzySearch = (options: any[]) => {
  const fuse = new Fuse(options, {
    keys: ['name', 'groupName', 'items.name'],
    threshold: 0.3,
  });

  return (value: any) => {
    if (!value.length) {
      return options;
    }

    return fuse.search(value);
  };
};

export type RNSearchableDropdownOption = {
  name: string;
  value: string | number;
};

interface RNSearchableDropdownProps {
  value: any;
  options: RNSearchableDropdownOption[];
  placeholder?: string;
  title?: string;
  clearShadow?: boolean;
  onChange: (value: string | number | null | undefined) => void;
}

const RNSearchableDropdown = (props: RNSearchableDropdownProps) => {
  const [value, setValue] = useState('');

  const onChange = (val: string | number | undefined) => {
    props.onChange(val);
  };

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  return (
    <SelectSearch
      value={value}
      onChange={val => onChange(val as any)}
      options={props.options}
      filterOptions={fuzzySearch}
      placeholder={props.placeholder || 'Select item'}
      emptyMessage="Not found"
      search={false}
    />
  );
};

export default memo(RNSearchableDropdown);
