const formatAmount = (originalValue: number) => {
  let formattedAmount: string = originalValue.toString();
  formattedAmount = originalValue.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return formattedAmount;
};

export {formatAmount};
