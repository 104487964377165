import React from 'react';
import {Platform, TextStyle} from 'react-native';

import {createStackNavigator} from '@react-navigation/stack';
import {colorPalette} from '../../../core/config/color.config';

import {NavigationKey} from '../config/navigation.config';
import {NavigationTitle} from '../config/navigationTitle';

import {
  AddMedicalAttachmentScreen,
  ConsultationDetailScreen,
  ConsultationImageAttachmentViewScreen,
  ConsultationPatientMedicalScreen,
  ConsultationRequestAcceptanceScreen,
  ConsultationSearchResultsScreen,
  ConsultationVideoCallScreen,
  ConsultationWebListScreen,
} from '../../screens';
import {
  HeaderBackLeft,
  MedicalAttachmentsListView,
  ViewLaboratoryRequestView,
  ViewMedicalCertificateView,
  ViewPrescriptionView,
} from '../../views';

import {ConversationProvider} from './../../hooks/conversation/useConversation';

import ConsultationTabNavigator from './ConsultationTabNavigator';
import HeaderIconRight from '../../views/navigation/HeaderIconRight';
import {VideoCallProvider} from '../../hooks/video-call/useVideoCall';

const {Navigator, Screen} = createStackNavigator();

const DoctorConsultationStackWebNavigator = () => {
  return (
    <ConversationProvider>
      <VideoCallProvider>
        <Navigator initialRouteName={NavigationKey.CONSULTATION_LIST}>
          <Screen
            name={NavigationKey.CONSULTATION_LIST}
            component={ConsultationWebListScreen}
            options={{
              headerShown: false,
              title: NavigationTitle.CONSULTATION_LIST,
            }}
          />
          <Screen
            name={NavigationKey.CONSULTATION_DETAIL}
            component={ConsultationWebListScreen}
            options={{
              headerShown: false,
              title: NavigationTitle.CONSULTATION_LIST,
            }}
          />
          <Screen
            name={NavigationKey.DOCTOR_ADD_MEDICAL_ATTACHMENT}
            component={AddMedicalAttachmentScreen}
            options={{
              headerShown: false,
              title: NavigationTitle.DOCTOR_ADD_MEDICAL_ATTACHMENT,
            }}
          />
          <Screen
            name={NavigationKey.CONSULTATION_VIDEO_CALL}
            component={ConsultationVideoCallScreen}
            options={{
              headerShown: false,
              title: NavigationTitle.CONSULTATION_VIDEO_CALL,
            }}
          />
        </Navigator>
      </VideoCallProvider>
    </ConversationProvider>
  );
};

const DoctorConsultationStackMobileNavigator = () => {
  const mobileBackHeaderNoTitleOptions = {
    title: '',
    headerStyle: {
      elevation: 0,
      shadowOpacity: 0,
    },
    headerTintColor: colorPalette.blackLight,
    headerTitleStyle: {
      fontFamily: 'Quicksand-Regular',
      fontSize: 14,
      paddingLeft: 28,
    },
    headerLeft: () => <HeaderBackLeft />,
  };

  return (
    <ConversationProvider>
      <VideoCallProvider>
        <Navigator initialRouteName={NavigationKey.CONSULTATION_LIST}>
          <Screen
            name={NavigationKey.CONSULTATION_LIST}
            component={ConsultationTabNavigator}
            options={{
              title: 'Consultations',
              headerStyle: {
                backgroundColor: colorPalette.primary,
                borderBottomColor: 'transparent',
                elevation: 0,
                zIndex: 0,
                shadowOpacity: 0,
              },
              headerTintColor: colorPalette.blackLight,
              headerTitleStyle: {
                fontFamily: 'Quicksand-Bold',
                fontSize: 14,
                fontWeight: 'bold',
                alignSelf: 'center',
                marginStart: -28,
                color: colorPalette.white,
              } as TextStyle,
              headerLeft: () => (
                <HeaderBackLeft
                  removeLabel
                  iconName="left-white"
                  navigationKey={NavigationKey.DOCTOR_LANDING_STACK}
                />
              ),
              headerRight: () => <HeaderIconRight iconName="search-white-80" />,
            }}
          />
          <Screen
            name={NavigationKey.CONSULTATION_DETAIL}
            component={ConsultationDetailScreen}
          />
          <Screen
            name={NavigationKey.DOCTOR_ADD_MEDICAL_ATTACHMENT}
            component={AddMedicalAttachmentScreen}
            options={{
              ...mobileBackHeaderNoTitleOptions,
              headerLeft: () => (
                <HeaderBackLeft removeLabel iconName="left-gray" />
              ),
            }}
          />
          <Screen
            name={NavigationKey.MEDICAL_ATTACHMENTS_LIST}
            component={MedicalAttachmentsListView}
          />
          <Screen
            name={NavigationKey.VIEW_MEDICAL_CERTIFICATE}
            component={ViewMedicalCertificateView}
            options={{
              ...mobileBackHeaderNoTitleOptions,
              headerLeft: () => <HeaderBackLeft iconName="left-black" />,
            }}
          />
          <Screen
            name={NavigationKey.VIEW_PRESCRIPTION}
            component={ViewPrescriptionView}
            options={{
              ...mobileBackHeaderNoTitleOptions,
              headerLeft: () => <HeaderBackLeft iconName="left-black" />,
            }}
          />
          <Screen
            name={NavigationKey.VIEW_LABORATORY_REQUEST}
            component={ViewLaboratoryRequestView}
            options={{
              ...mobileBackHeaderNoTitleOptions,
              headerLeft: () => <HeaderBackLeft iconName="left-black" />,
            }}
          />
          <Screen
            name={NavigationKey.VIEW_IMAGE_ATTACHMENT}
            component={ConsultationImageAttachmentViewScreen}
            options={{
              ...mobileBackHeaderNoTitleOptions,
              headerLeft: () => <HeaderBackLeft iconName="left-black" />,
              headerRight: () => <HeaderIconRight iconName="download-down" />,
            }}
          />
          <Screen
            name={NavigationKey.CONSULTATION_PATIENT_MEDICAL_HISTORY}
            component={ConsultationPatientMedicalScreen}
          />
          <Screen
            name={NavigationKey.CONSULTATION_VIDEO_CALL}
            component={ConsultationVideoCallScreen}
            options={{headerShown: false}}
          />
          <Screen
            name={NavigationKey.CONSULTATION_SEARCH}
            component={ConsultationSearchResultsScreen}
            options={{
              ...mobileBackHeaderNoTitleOptions,
            }}
          />
          <Screen
            name={NavigationKey.CONSULTATION_REQUEST_ACCEPTANCE}
            component={ConsultationRequestAcceptanceScreen}
            options={{
              ...mobileBackHeaderNoTitleOptions,
            }}
          />
        </Navigator>
      </VideoCallProvider>
    </ConversationProvider>
  );
};

export default Platform.OS === 'web'
  ? DoctorConsultationStackWebNavigator
  : DoctorConsultationStackMobileNavigator;
