import React, {useLayoutEffect, useEffect, useState} from 'react';
import {Platform} from 'react-native';

import {useNavigation, useRoute} from '@react-navigation/native';

import {colorPalette} from '../../../core/config/color.config';

import {DoctorConsultationProfile, HeaderBackLeft} from './../../views';

import {PractitionerProfile} from '../../server/types/practitioner-profile.types';

const ConsultationDoctorProfileScreen = () => {
  const route = useRoute();
  const navigation = useNavigation();

  const [practitioner, setPractitioner] = useState<PractitionerProfile>();

  const onCancel = () => {
    goBack();
  };

  const goBack = () => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    }
  };

  useLayoutEffect(() => {
    if (Platform.OS !== 'web') {
      navigation.setOptions({
        title: '',
        headerLeft: () => <HeaderBackLeft label=" " iconName="left-white" />,
        headerStyle: {
          borderBottomColor: 'transparent',
          elevation: 0,
          shadowOpacity: 0,
          backgroundColor: colorPalette.primary,
        },
      });
    }
  }, [navigation]);

  useEffect(() => {
    const params: any = route.params;

    if (params && params.practitioner) {
      setPractitioner(params.practitioner);
    } else {
      goBack();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route.params]);

  return (
    <>
      {!!practitioner && (
        <DoctorConsultationProfile
          practitioner={practitioner}
          onClose={onCancel}
        />
      )}
    </>
  );
};

export default ConsultationDoctorProfileScreen;
