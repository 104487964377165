import React, {memo} from 'react';
import {Platform, View} from 'react-native';

import {makeStyles} from 'rne-netzon';

import {SVGIcon, RNEIconButton, RNEText} from '../../components';
import useStores from '../../stores/useStores';

interface RegistrationSuccessModalProps {
  onConfirm?: () => void;
}

function RegistrationSuccessModal(props: RegistrationSuccessModalProps) {
  const rneStyles = useStyles();
  const {authStore} = useStores();

  return (
    <View style={rneStyles.root}>
      <RNEText
        label={
          authStore.authData?.user.userRole === 'patient'
            ? 'AWESOME!'
            : 'Great job, Doc!'
        }
        variant="header"
        align="center"
        weight="600"
        color="primary"
      />
      <RNEText
        label="Your account has been verified. You can now start using House Call to proceed with your medical consultations."
        variant="body"
        align="center"
      />

      <View style={rneStyles.actionContainer}>
        <RNEIconButton
          icon={<SVGIcon name="check-white" size={48} />}
          color="secondary"
          size={64}
          onPress={props.onConfirm}
          raised={Platform.OS !== 'web'}
        />
      </View>
    </View>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: Platform.OS === 'web' ? 420 : '80%',
    padding: Platform.OS === 'web' ? 32 : 16,
  },
  actionContainer: {
    marginTop: 36,
    alignItems: 'center',
  },
}));

export default memo(RegistrationSuccessModal);
