import React, {useEffect, useState} from 'react';
import {View, ScrollView, Image, Platform} from 'react-native';
import {makeStyles} from 'rne-netzon';

import {RNEButton, RNEIconButton, SVGIcon} from '../../components';
import {LogoLight} from './../../images/Image';
import {TouchableWithoutFeedback} from 'react-native-gesture-handler';
import {colorPalette} from '../../../core/config/color.config';
import {
  useGetMedia,
  useGetMediaUrl,
} from '../../server/react-query/useConversation';
import {useToast} from 'react-native-fast-toast';

export interface ViewImageAttachmentView {
  subject: string;
  mediaId: string;
  onClose: () => void;
}

const ViewImageAttachmentView = (props: ViewImageAttachmentView) => {
  const webStyles = useWebStyles();
  const toast = useToast();

  const [imageURL, setImageURL] = useState('');

  const {data: imageData} = useGetMedia({
    subject: props.subject,
    mediaId: props.mediaId,
  });

  const {data, refetch} = useGetMediaUrl({
    subject: props.subject,
    mediaId: props.mediaId,
  });

  const onDownloadClick = () => {
    toast?.show('Downloading...', {
      type: 'normal',
      id: props.mediaId,
    });
    downloadResource(
      data?.url!,
      imageData?.content.title ? imageData?.content.title : 'Image',
    );
  };

  const downloadImage = (blob: any, filename: string) => {
    var a = document.createElement('a');
    a.download = filename;
    a.href = blob;
    document.body.appendChild(a);
    a.click();
    a.remove();
    toast?.show('Download complete.', {
      type: 'success',
      id: props.mediaId,
    });
  };

  const downloadResource = (url: string, filename: string) => {
    fetch(url)
      .then(response => response.blob())
      .then(blob => {
        let blobUrl = window.URL.createObjectURL(blob);
        downloadImage(blobUrl, filename);
      })
      .catch(e => console.error(e));
  };

  useEffect(() => {
    if (data?.url) {
      const index = data?.url.indexOf('?');
      const url = data?.url.substring(0, index);
      setImageURL(url);
    }
  }, [data]);

  return (
    <View style={webStyles.mainContainer}>
      <View style={webStyles.headerContainer}>
        <Image style={webStyles.logo} source={LogoLight} />
        {Platform.OS === 'web' ? (
          <View style={webStyles.rightIcons}>
            <TouchableWithoutFeedback>
              <RNEIconButton
                icon={<SVGIcon name="download-down" size={28} />}
                type="clear"
                size={28}
                onPress={onDownloadClick}
                containerStyle={{borderRadius: 0}}
              />
            </TouchableWithoutFeedback>
            <TouchableWithoutFeedback>
              <RNEIconButton
                icon={<SVGIcon name="close-gray-71" size={36} />}
                type="clear"
                size={36}
                onPress={props.onClose}
              />
            </TouchableWithoutFeedback>
          </View>
        ) : (
          <View style={webStyles.rightIcons}>
            <TouchableWithoutFeedback onPress={onDownloadClick}>
              <View accessibilityRole="button">
                <SVGIcon name="download-down" size={28} />
              </View>
            </TouchableWithoutFeedback>
            <TouchableWithoutFeedback onPress={props.onClose}>
              <View style={webStyles.closeIcon} accessibilityRole="button">
                <SVGIcon name="close-gray-71" size={36} />
              </View>
            </TouchableWithoutFeedback>
          </View>
        )}
      </View>
      <ScrollView>
        <View style={webStyles.bodyContainer}>
          {imageURL.length > 0 ? (
            <View style={webStyles.imageContainer}>
              <Image
                source={{uri: imageURL}}
                style={webStyles.imageStyle}
                resizeMode="contain"
              />
            </View>
          ) : (
            <View style={webStyles.spinnerContainer}>
              <RNEButton
                height={20}
                loadingProps={loadingPropsStyle}
                loading={imageURL.length === 0}
              />
            </View>
          )}
        </View>
      </ScrollView>
    </View>
  );
};

export default ViewImageAttachmentView;

const loadingPropsStyle = {
  color: colorPalette.primary,
};

const useWebStyles = makeStyles({
  mainContainer: {
    marginHorizontal: 40,
    marginVertical: 20,
    flex: 1,
    width: '40vw',
    maxWidth: 700,
    minWidth: 440,
    maxHeight: '70vh',
  },
  headerContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottomColor: 'rgba(229, 229, 229, 0.7)',
    borderBottomWidth: 1,
    paddingTop: 12,
    paddingBottom: 28,
  },
  logo: {
    width: 200,
    height: 44,
  },
  rightIcons: {
    flexDirection: 'row',
    alignSelf: 'center',
    justifyContent: 'space-between',
    width: 76,
  },
  closeIcon: {
    marginLeft: 28,
  },
  bodyContainer: {
    marginVertical: 40,
    marginHorizontal: 60,
  },
  spinnerContainer: {
    alignSelf: 'center',
  },
  imageContainer: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
  },
  imageStyle: {
    justifyContent: 'center',
    height: 550,
  },
});

const useMobileStyles = makeStyles({
  mainContainer: {
    width: '100%',
    flex: 1,
    paddingHorizontal: 52,
    paddingVertical: 40,
  },
  spinnerContainer: {
    alignSelf: 'center',
  },
  imageContainer: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
  },
  imageStyle: {
    justifyContent: 'center',
    height: 550,
  },
});
