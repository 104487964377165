import {useQuery, useQueryClient} from 'react-query';
import {API, ApiEndpointsEnum} from '../api';
import {AdminDashboardReqResponse} from '../types/admin-dashboard.types';

const getAdminDashboardReport = () => {
  const url = ApiEndpointsEnum.GET_ADMIN_DASHBOARD;
  return API.get<AdminDashboardReqResponse>(url);
};

const useAdminDashboardReport = () => {
  const queryClient = useQueryClient();

  return useQuery(['admin-dashboard-report'], () => getAdminDashboardReport());
};

export {useAdminDashboardReport};
