import React from 'react';
import {Platform} from 'react-native';
import {createDrawerNavigator} from '@react-navigation/drawer';

import {NavigationKey} from '../config/navigation.config';

import DoctorLandingStackNavigator from './DoctorLandingStackNavigator';
import DoctorProfileStackNavigator from './DoctorProfileStackNavigator';
import DoctorNotificationStackNavigator from './DoctorNotificationStackNavigator';
import DoctorConsultationStackNavigator from './DoctorConsultationStackNavigator';
import DoctorTandCStackNavigator from './DoctorTandCStackNavigator';
import DoctorHelpStackNavigator from './DoctorHelpStackNavigator';
import DoctorAccountStackNavigator from './DoctorAccountStackNavigator';

import {DoctorSideMenu} from '../../views';
import {makeStyles} from 'rne-netzon';
import {PrivacyPolicyScreen} from '../../screens';
import {NavigationTitle} from '../config/navigationTitle';

const {Navigator, Screen} = createDrawerNavigator();

const DoctorDrawerNavigator = () => {
  const drawerStyles = useDrawerStyles();
  const drawerPosition = Platform.OS === 'web' ? 'left' : 'right';
  const drawerType = Platform.OS === 'web' ? 'permanent' : 'front';

  return (
    <Navigator
      drawerPosition={drawerPosition}
      drawerType={drawerType}
      drawerStyle={
        Platform.OS === 'web' ? drawerStyles.webWidth : drawerStyles.mobileWidth
      }
      drawerContent={() => <DoctorSideMenu />}
      screenOptions={{
        unmountOnBlur: Platform.OS === 'web' ? true : false,
        swipeEnabled: false,
      }}>
      <Screen
        name={NavigationKey.DOCTOR_LANDING_STACK}
        component={DoctorLandingStackNavigator}
        options={{
          headerShown: false,
        }}
      />
      <Screen
        name={NavigationKey.DOCTOR_PROFILE_STACK}
        component={DoctorProfileStackNavigator}
        options={{
          headerShown: false,
        }}
      />
      <Screen
        name={NavigationKey.DOCTOR_NOTIFICATION_STACK}
        component={DoctorNotificationStackNavigator}
        options={{
          headerShown: false,
        }}
      />
      <Screen
        name={NavigationKey.DOCTOR_CONSULTATION_STACK}
        component={DoctorConsultationStackNavigator}
        options={{
          headerShown: false,
        }}
      />
      <Screen
        name={NavigationKey.DOCTOR_TANDC_STACK}
        component={DoctorTandCStackNavigator}
        options={{
          headerShown: false,
        }}
      />
      <Screen
        name={NavigationKey.DOCTOR_HELP_STACK}
        component={DoctorHelpStackNavigator}
        options={{
          headerShown: false,
        }}
      />
      <Screen
        name={NavigationKey.DOCTOR_ACCOUNT_STACK}
        component={DoctorAccountStackNavigator}
        options={{
          headerShown: false,
        }}
      />
      <Screen
        name={NavigationKey.PRIVACY_POLICY}
        component={PrivacyPolicyScreen}
        options={{
          title: NavigationTitle.PRIVACY_POLICY,
        }}
      />
    </Navigator>
  );
};

export default DoctorDrawerNavigator;

const useDrawerStyles = makeStyles({
  webWidth: {
    width: 'auto',
    borderRightColor: 'rgba(229, 229, 229, 0.4)',
    borderRightWidth: 2,
  },
  mobileWidth: {
    width: '85%',
  },
});
