import React, {useLayoutEffect} from 'react';
import {
  View,
  Platform,
  ScrollView,
  TouchableWithoutFeedback,
  TextStyle,
  Linking,
} from 'react-native';
import {RNEText, SVGIcon, RNEButton} from '../../components';
import {SafeAreaView} from 'react-native-safe-area-context';

import {DrawerActions, useNavigation} from '@react-navigation/native';
import {NavigationKey} from '../../navigations/config';
import {NavigationTitle} from '../../navigations/config/navigationTitle';

import {makeStyles} from 'rne-netzon';
import {colorPalette} from '../../../core/config/color.config';

import {HeaderProfileLeft, HeaderProfileRight} from '../../views';

const DoctorDefaultLandingScreen = () => {
  const navigation = useNavigation();
  const sharedStyles = useSharedStyles();
  const webStyles = useWebStyles();
  const mobileStyles = useMobileStyles();

  const goToProfessionalProfile = () => {
    navigation.navigate(NavigationKey.DOCTOR_PROFILE_STACK, {
      screen: NavigationKey.DOCTOR_VIEW_PROFESSIONAL_PROFILE,
    });
  };

  const goToWebsite = (index: number) => {
    let url = '';

    switch (index) {
      case 0:
        url = 'http://housecall.com.ph/';
        break;
      case 1:
        url = 'https://www.facebook.com/House-Call-112923250497692';
        break;
      case 2:
        url = 'https://www.instagram.com/housecall.app/';
        break;
    }

    Linking.canOpenURL(url).then(supported => {
      if (supported) {
        Linking.openURL(url);
      } else {
        console.log('Cannot redirect to ' + url);
      }
    });
  };

  useLayoutEffect(() => {
    navigation.setOptions(
      Platform.OS === 'web'
        ? {
            headerShown: false,
            title: NavigationTitle.DOCTOR_SCREEN_LANDING,
          }
        : {
            headerShown: true,
            ...{
              headerStyle: {
                elevation: 0,
                shadowOpacity: 0,
              },
              headerTitleStyle: {
                fontWeight: 'bold',
                fontFamily: 'Quicksand-Bold',
                fontSize: 16,
              } as TextStyle,
            },
            headerStyle: {
              backgroundColor: colorPalette.purple,
              borderBottomColor: 'transparent',
              elevation: 0,
              shadowOpacity: 0,
            },
            headerTitleStyle: {
              paddingLeft: 28,
            },
            headerLeft: () => (
              <HeaderProfileLeft inProfileScreen={true} isLogoContrast={true} />
            ),
            headerRight: () => (
              <HeaderProfileRight
                showMessageIcon={false}
                isMenuLight={true}
                onClick={() =>
                  navigation.dispatch(DrawerActions.toggleDrawer())
                }
              />
            ),
            ...{
              title: '',
              headerTintColor: colorPalette.primary,
            },
          },
    );
  }, [navigation]);

  return (
    <SafeAreaView style={sharedStyles.root}>
      {Platform.OS === 'web' ? (
        <ScrollView>
          <View style={webStyles.mainContainer}>
            <View>
              <RNEText
                label="Hang in there, Doc!"
                variant="header"
                weight="bold"
                color="white"
              />
              <RNEText
                label="Update your professional details to verify your account."
                variant="body"
                color="white"
                style={customWebTextFont}
                weight="300"
              />
            </View>
            <View style={webStyles.buttonContainer}>
              <RNEButton
                title="Check my account"
                color="secondary"
                height={48}
                onPress={goToProfessionalProfile}
              />
            </View>
            <View>
              <RNEText
                label="You may visit our pages to get updates"
                variant="body"
                color="white"
                style={customWebTextFont}
                weight="300"
              />
            </View>
            <View style={sharedStyles.snsWrapper}>
              <TouchableWithoutFeedback onPress={() => goToWebsite(0)}>
                <View accessibilityRole="button" style={webStyles.snsContainer}>
                  <SVGIcon name="globe" size={32} />
                </View>
              </TouchableWithoutFeedback>
              <TouchableWithoutFeedback onPress={() => goToWebsite(1)}>
                <View accessibilityRole="button" style={webStyles.snsContainer}>
                  <SVGIcon name="facebook" size={32} />
                </View>
              </TouchableWithoutFeedback>
              <TouchableWithoutFeedback onPress={() => goToWebsite(2)}>
                <View accessibilityRole="button" style={webStyles.snsContainer}>
                  <SVGIcon name="instagram" size={32} />
                </View>
              </TouchableWithoutFeedback>
            </View>
          </View>
        </ScrollView>
      ) : (
        <ScrollView>
          <View style={mobileStyles.mainContainer}>
            <View>
              <RNEText
                label="Hang in there, Doc!"
                variant="header"
                weight="bold"
                color="white"
                style={titleFontSize44}
              />
              <RNEText
                label="Update your professional details to verify your account."
                variant="body"
                color="white"
                style={subtitlePadding}
              />
            </View>
            <View style={mobileStyles.buttonContainer}>
              <RNEButton
                title="Check my account"
                color="secondary"
                height={48}
                onPress={goToProfessionalProfile}
              />
            </View>
            <View>
              <RNEText
                label="You may visit our pages to get updates"
                variant="body"
                color="white"
              />
            </View>
            <View style={sharedStyles.snsWrapper}>
              <TouchableWithoutFeedback onPress={() => goToWebsite(0)}>
                <View
                  accessibilityRole="button"
                  style={mobileStyles.snsContainer}>
                  <SVGIcon name="globe" size={16} />
                </View>
              </TouchableWithoutFeedback>
              <TouchableWithoutFeedback onPress={() => goToWebsite(1)}>
                <View
                  accessibilityRole="button"
                  style={mobileStyles.snsContainer}>
                  <SVGIcon name="facebook" size={16} />
                </View>
              </TouchableWithoutFeedback>
              <TouchableWithoutFeedback onPress={() => goToWebsite(2)}>
                <View
                  accessibilityRole="button"
                  style={mobileStyles.snsContainer}>
                  <SVGIcon name="instagram" size={16} />
                </View>
              </TouchableWithoutFeedback>
            </View>
          </View>
        </ScrollView>
      )}
    </SafeAreaView>
  );
};

export default DoctorDefaultLandingScreen;

const customWebTextFont = {
  fontSize: 20,
};

const titleFontSize44 = {
  fontSize: 44,
};

const subtitlePadding = {
  paddingTop: 20,
};

const useSharedStyles = makeStyles({
  root: {
    height: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    backgroundColor: colorPalette.purple,
  },
  snsWrapper: {
    width: '100%',
    flexDirection: 'row',
    marginTop: 28,
  },
});

const useWebStyles = makeStyles({
  mainContainer: {
    padding: 100,
  },
  buttonContainer: {
    width: 180,
    marginTop: 24,
    marginBottom: 96,
  },
  snsContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 80,
    height: 80,
    marginRight: 32,
    borderRadius: 100,
    borderWidth: 2,
    borderColor: colorPalette.white,
  },
});

const useMobileStyles = makeStyles({
  mainContainer: {
    paddingVertical: 60,
    paddingHorizontal: 40,
  },
  buttonContainer: {
    width: 160,
    marginTop: 24,
    marginBottom: 117,
  },
  snsContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 50,
    height: 50,
    marginRight: 20,
    borderRadius: 50,
    borderWidth: 2,
    borderColor: colorPalette.white,
  },
});
