import React, {memo} from 'react';
import {View, Image} from 'react-native';

import {makeStyles} from 'rne-netzon';
import {colorPalette} from '../../../core/config/color.config';

import {Photo} from './../../server/types/photo.types';

import {SVGIcon} from './../../components';

interface ConsultationPhotoProps {
  size?: number;
  photo?: Photo;
}

const ConsultationPhoto = ({size = 56, photo}: ConsultationPhotoProps) => {
  const styles = useStyles();

  return (
    <View
      style={[
        styles.userIcon,
        {
          width: size,
          height: size,
          borderRadius: size,
        },
      ]}>
      {photo ? (
        <Image
          style={[
            styles.imageWrapper,
            {
              borderRadius: size,
            },
          ]}
          source={{
            uri: photo.url,
          }}
        />
      ) : (
        <SVGIcon name="user" size={size * 0.5} />
      )}
    </View>
  );
};

const useStyles = makeStyles({
  userIcon: {
    position: 'relative',
    backgroundColor: 'transparent',
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: colorPalette.primary,
  },
  imageWrapper: {
    width: '100%',
    height: '100%',
  },
});

export default memo(ConsultationPhoto);
