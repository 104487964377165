import React, {memo} from 'react';
import {View} from 'react-native';

import {makeStyles} from 'rne-netzon';

import {useGetMedicalData} from '../../server/react-query/useMedicalHistory';

import ContentScrollView from '../scroll/ContentScrollView';
import PatientMedicalItemView from './PatientMedicalItemView';

interface PatientMedicalViewProps {
  patientId: string;
}

const PatientMedicalView = (props: PatientMedicalViewProps) => {
  const styles = useStyles();

  const [
    allergy,
    familyCondition,
    hospitalization,
    maintenance,
    medicalCondition,
    telcom,
  ] = useGetMedicalData({
    patientId: props.patientId,
  });

  return (
    <View style={styles.root}>
      <ContentScrollView isWeb>
        <PatientMedicalItemView
          style={styles.detailItem}
          label="Allergies"
          value={allergy.data?.map(item => item.note.text).join(', ') || '--'}
          iconName="hc-icon-allergies"
          isLoading={allergy.isLoading}
        />
        <PatientMedicalItemView
          style={styles.detailItem}
          label="Family Conditions"
          value={
            familyCondition.data?.map(item => item.note.text).join(', ') || '--'
          }
          iconName="hc-icon-family-condition"
          isLoading={familyCondition.isLoading}
        />
        <PatientMedicalItemView
          style={styles.detailItem}
          label="Hospitalization"
          value={
            hospitalization.data?.map(item => item.note.text).join(', ') || '--'
          }
          iconName="hc-icon-hospitalization"
          isLoading={hospitalization.isLoading}
        />
        <PatientMedicalItemView
          style={styles.detailItem}
          label="Maintenance Medications"
          value={
            maintenance.data?.map(item => item.note.text).join(', ') || '--'
          }
          iconName="hc-icon-maintenance"
          isLoading={maintenance.isLoading}
        />
        <PatientMedicalItemView
          style={styles.detailItem}
          label="Medical Conditions"
          value={
            medicalCondition.data?.map(item => item.note.text).join(', ') ||
            '--'
          }
          iconName="hc-icon-medical-condition"
          isLoading={medicalCondition.isLoading}
        />
        <PatientMedicalItemView
          style={styles.detailItem}
          label="Personal and Social"
          value={telcom.data?.map(item => item.value).join(', ') || '--'}
          iconName="hc-icon-personal-and-social"
          isLoading={telcom.isLoading}
        />
      </ContentScrollView>
    </View>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    width: '100%',
    height: '60vh',
    maxHeight: '60vh',
    position: 'relative',
    paddingBottom: 40,
  },
  detailItem: {
    marginBottom: 24,
  },
}));

export default memo(PatientMedicalView);
