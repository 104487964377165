import React from 'react';
import {View, Platform, ScrollView} from 'react-native';

import {makeStyles} from 'rne-netzon';

import {RNEText} from '../../components';

const HelpScreen = () => {
  const sharedStyles = useSharedStyles();
  const webStyles = useWebStyles();
  const mobileStyles = useMobileStyles();

  return (
    <>
      {Platform.OS === 'web' ? (
        <View style={webStyles.mainContainer}>
          <View style={webStyles.titleContainer}>
            <RNEText
              label="Help"
              variant="header"
              weight="bold"
              color="accent"
            />
          </View>
          <ScrollView style={webStyles.mainWrapper}>
            <View style={webStyles.qaSetContainer}>
              <View style={webStyles.rowContainer}>
                <View style={webStyles.questionLabel}>
                  <RNEText label="Question:" variant="body" weight="bold" />
                </View>
                <RNEText
                  label="How are consultations conducted?"
                  variant="body"
                />
              </View>
              <View style={webStyles.rowContainer}>
                <View style={webStyles.answerLabel}>
                  <RNEText label="Answer:" variant="body" weight="bold" />
                </View>
                <RNEText
                  label="After you search for the doctor of your choice, send out a request for a consultation with them by sending them your first message, which should include why you’re seeking consultation, your symptoms, how the patient feels, medications that the patient has taken, and other interventions that the patient has received. Once this message is sent, wait for the doctor to accept the consultation. Once he/she has accepted the request, the consultation then starts and you can converse with your doctor more. Once your doctor has understood your condition enough, he/she will give out the necessary requests and prescriptions to you. The consultation ends at this point. You can request for another consultation with your doctor after (Please see Requesting for Another Consultation with the Same Doctor)"
                  variant="body"
                />
              </View>
            </View>
            <View style={webStyles.qaSetContainer}>
              <View style={webStyles.rowContainer}>
                <View style={webStyles.questionLabel}>
                  <RNEText label="Question:" variant="body" weight="bold" />
                </View>
                <RNEText
                  label="How much does a consultation cost?"
                  variant="body"
                />
              </View>
              <View style={webStyles.rowContainer}>
                <View style={webStyles.answerLabel}>
                  <RNEText label="Answer:" variant="body" weight="bold" />
                </View>
                <RNEText
                  label="Consultation fees vary per doctor. Make sure that before you request a consultation with a new doctor, check the amount of his/her consultation fee."
                  variant="body"
                />
              </View>
            </View>
            <View style={webStyles.qaSetContainer}>
              <View style={webStyles.rowContainer}>
                <View style={webStyles.questionLabel}>
                  <RNEText label="Question:" variant="body" weight="bold" />
                </View>
                <RNEText
                  label="Can I use one account for multiple people’s check ups?"
                  variant="body"
                />
              </View>
              <View style={webStyles.rowContainer}>
                <View style={webStyles.answerLabel}>
                  <RNEText label="Answer:" variant="body" weight="bold" />
                </View>
                <RNEText
                  label="Housecall gives you the ability to set up multiple profiles under one main account, so you can set up an account for your parents, siblings, etc. When a specific person other than you (main account holder) is the one needing a check up, make sure to choose their profile first before searching for your doctor and requesting a consultation."
                  variant="body"
                />
              </View>
            </View>
          </ScrollView>
        </View>
      ) : (
        <ScrollView>
          <View style={mobileStyles.mainContainer}>
            <RNEText
              label="Help"
              variant="header"
              weight="bold"
              color="accent"
            />
            <View style={mobileStyles.qaSetContainer}>
              <View style={mobileStyles.rowContainer}>
                <RNEText label="Question:" variant="body" weight="bold" />
                <RNEText
                  label="How are consultations conducted?"
                  variant="body"
                />
              </View>
              <View style={mobileStyles.rowContainer}>
                <RNEText label="Answer:" variant="body" weight="bold" />
                <RNEText
                  label="After you search for the doctor of your choice, send out a request for a consultation with them by sending them your first message, which should include why you’re seeking consultation, your symptoms, how the patient feels, medications that the patient has taken, and other interventions that the patient has received. Once this message is sent, wait for the doctor to accept the consultation. Once he/she has accepted the request, the consultation then starts and you can converse with your doctor more. Once your doctor has understood your condition enough, he/she will give out the necessary requests and prescriptions to you. The consultation ends at this point. You can request for another consultation with your doctor after (Please see Requesting for Another Consultation with the Same Doctor)"
                  variant="body"
                />
              </View>
            </View>
            <View style={mobileStyles.qaSetContainer}>
              <View style={mobileStyles.rowContainer}>
                <RNEText label="Question:" variant="body" weight="bold" />
                <RNEText
                  label="How much does a consultation cost?"
                  variant="body"
                />
              </View>
              <View style={mobileStyles.rowContainer}>
                <RNEText label="Answer:" variant="body" weight="bold" />
                <RNEText
                  label="Consultation fees vary per doctor. Make sure that before you request a consultation with a new doctor, check the amount of his/her consultation fee."
                  variant="body"
                />
              </View>
            </View>
            <View style={mobileStyles.qaSetContainer}>
              <View style={mobileStyles.rowContainer}>
                <RNEText label="Question:" variant="body" weight="bold" />
                <RNEText
                  label="Can I use one account for multiple people’s check ups?"
                  variant="body"
                />
              </View>
              <View style={mobileStyles.rowContainer}>
                <RNEText label="Answer:" variant="body" weight="bold" />
                <RNEText
                  label="Housecall gives you the ability to set up multiple profiles under one main account, so you can set up an account for your parents, siblings, etc. When a specific person other than you (main account holder) is the one needing a check up, make sure to choose their profile first before searching for your doctor and requesting a consultation."
                  variant="body"
                />
              </View>
            </View>
          </View>
        </ScrollView>
      )}
    </>
  );
};

export default HelpScreen;

const useWebStyles = makeStyles({
  mainContainer: {
    paddingTop: 140,
    paddingBottom: 32,
    paddingHorizontal: 80,
    width: '100%',
    flex: 1,
  },
  titleContainer: {
    marginBottom: 40,
  },
  mainWrapper: {
    paddingRight: 48,
  },
  qaSetContainer: {
    marginVertical: 24,
  },
  rowContainer: {
    flexDirection: 'row',
    // justifyContent: 'space-between',
    marginVertical: 16,
  },
  questionLabel: {
    width: 116,
  },
  answerLabel: {
    width: 116,
  },
});

const useMobileStyles = makeStyles({
  mainContainer: {
    paddingVertical: 20,
    paddingHorizontal: 48,
  },
  qaSetContainer: {
    marginVertical: 16,
  },
  rowContainer: {
    justifyContent: 'space-between',
    marginVertical: 8,
  },
});

const useSharedStyles = makeStyles({
  root: {
    height: '100%',
    flexDirection: 'row',
    width: '100%',
  },
});
