import React, {useEffect, useState} from 'react';
import {Platform, StyleSheet} from 'react-native';
import {SafeAreaView} from 'react-native-safe-area-context';
import {Pager, PagerProvider} from '@crowdlinker/react-native-pager';

import useStores from '../../stores/useStores';

import OnboardingSlideScreen1 from './OnboardingSlideScreen1';
import OnboardingSlideScreen2 from './OnboardingSlideScreen2';
import OnboardingSlideScreen3 from './OnboardingSlideScreen3';
import OnboardingActionPanel from '../../views/onboarding/OnboardingActionPanel';

const slides = [
  {
    key: 'slide1',
    component: OnboardingSlideScreen1,
  },
  {
    key: 'slide2',
    component: OnboardingSlideScreen2,
  },
  {
    key: 'slide3',
    component: OnboardingSlideScreen3,
  },
];

interface ComponentProps {
  hideActions?: boolean;
}

const OnboardingSliderScreen = (props: ComponentProps) => {
  const {authStore} = useStores();
  const [activeIndex, setActiveIndex] = useState(0);

  const goBack = () => {
    if (activeIndex > 0) {
      onPagerChange(activeIndex - 1);
    }
  };

  const goNext = () => {
    if (activeIndex < slides.length - 1) {
      onPagerChange(activeIndex + 1);
    }

    if (activeIndex === slides.length - 1) {
      authStore.completeOnboarding();
    }
  };

  const onComplete = () => {
    if (activeIndex === slides.length - 1) {
      authStore.completeOnboarding();
    }
  };

  const onPagerChange = (nextIndex: number) => {
    setActiveIndex(nextIndex);
  };

  useEffect(() => {
    if (Platform.OS === 'web') {
      onPagerChange(1);
      setTimeout(() => {
        onPagerChange(0);
      }, 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SafeAreaView
      style={[styles.root, Platform.OS === 'web' ? styles.web : styles.mobile]}>
      <PagerProvider activeIndex={activeIndex} onChange={onPagerChange}>
        <Pager>
          {slides.map(slide => (
            <slide.component key={slide.key} />
          ))}
        </Pager>
        <OnboardingActionPanel
          size={slides.length}
          activeIndex={activeIndex}
          hideActions={props.hideActions}
          goBack={goBack}
          goNext={goNext}
          onComplete={onComplete}
          onIndicatorPress={index => onPagerChange(index)}
        />
      </PagerProvider>
    </SafeAreaView>
  );
};

export default OnboardingSliderScreen;

const styles = StyleSheet.create({
  root: {
    flex: 1,
    width: '100%',
    height: '100%',
    paddingVertical: 16,
  },
  web: {
    backgroundColor: '#2BA8E81A',
  },
  mobile: {
    backgroundColor: 'white',
  },
  pagerView: {
    flex: 1,
  },
});
