import React from 'react';
import {createStackNavigator} from '@react-navigation/stack';

import {NavigationKey} from '../config/navigation.config';
import {NavigationTitle} from '../config/navigationTitle';
import {
  ForgotPasswordScreen,
  LoginScreen,
  PrivacyPolicyAuthScreen,
  ResetPasswordScreen,
  SignupScreen,
  SignupVerificationScreen,
  TermsConditionsAuthScreen,
} from '../../screens';

const {Navigator, Screen} = createStackNavigator();

const AuthStackNavigator = () => {
  return (
    <Navigator
      initialRouteName={NavigationKey.LOGIN_SCREEN}
      screenOptions={{
        headerShown: false,
      }}>
      <Screen
        options={{title: NavigationTitle.LOGIN_SCREEN}}
        name={NavigationKey.LOGIN_SCREEN}
        component={LoginScreen}
      />
      <Screen
        options={{title: NavigationTitle.SIGNUP_SCREEN}}
        name={NavigationKey.SIGNUP_SCREEN}
        component={SignupScreen}
      />
      <Screen
        options={{title: NavigationTitle.SIGNUP_VERIFY_SCREEN}}
        name={NavigationKey.SIGNUP_VERIFY_SCREEN}
        component={SignupVerificationScreen}
      />
      <Screen
        options={{title: NavigationTitle.FORGOT_PASSWORD_SCREEN}}
        name={NavigationKey.FORGOT_PASSWORD_SCREEN}
        component={ForgotPasswordScreen}
      />
      <Screen
        options={{title: NavigationTitle.RESET_PASSWORD_SCREEN}}
        name={NavigationKey.RESET_PASSWORD_SCREEN}
        component={ResetPasswordScreen}
      />
      <Screen
        options={{title: NavigationTitle.TANDC_SCREEN}}
        name={NavigationKey.TANDC_SCREEN}
        component={TermsConditionsAuthScreen}
      />
      <Screen
        name={NavigationKey.PRIVACY_POLICY}
        component={PrivacyPolicyAuthScreen}
      />
    </Navigator>
  );
};

export default AuthStackNavigator;
