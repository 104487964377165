import {PractitionerProfile} from './practitioner-profile.types';
import {PatientProfileReqResponse} from './patient-profile.types';
import {ProcedureConsultationReqResponse} from './procedure.types';
import {CodeableConcept} from './code.types';

export interface ConsultationRequestReqBody {
  performer: string;
  subject: string;
  note: string;
  code?: CodeableConcept;
}

export interface ConsultationFee {
  value: number;
  currency: string;
}

export interface ConsultationNote {
  text: string;
  time: Date;
}

export enum ConsultationStatusEnum {
  PENDING = 'draft',
  ONGOING = 'active',
  COMPLETED = 'completed',
  DECLINED = 'revoked',
  CANCELLED = 'revoked',
}

export enum ConsultationListStatus {
  PENDING = 'pending',
  ONGOING = 'ongoing',
  HISTORY = 'history',
}

export interface ConsultationRequestResBody {
  serviceRequestId: string;
  status: ConsultationStatusEnum;
  fee: ConsultationFee;
  note: ConsultationNote[];
  performer: PractitionerProfile;
  subject: PatientProfileReqResponse;
  requester: string;
  modifiedOn: Date;
  modifiedBy: string;
  procedure?: ProcedureConsultationReqResponse;
}

export interface ConsultationListReqParams {
  searchKey?: string;
  status: ConsultationStatusEnum[];
  subject?: string;
  SortBy?: string;
  Ascending?: boolean;
  code?: string;
}

export interface ConsultationDetailReqBody {
  serviceRequestId: string;
}

export interface CancelConsultationReqBody {
  serviceRequestId: string;
  status: ConsultationStatusEnum;
}

export interface DenyConsultationReqBody {
  serviceRequestId: string;
  status: ConsultationStatusEnum;
  note?: string;
}

export interface AcceptConsultationReqBody {
  basedOn: string;
}

export interface EndConsultationReqBody {
  serviceRequestId: string;
  procedureId: string;
}

export interface ProcedureDetailReqBody {
  procedureId?: string;
}

export interface ConsultationDashboard {
  pending: number;
  ongoing: number;
  completed: number;
}
