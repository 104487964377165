import React, {memo, useEffect, useState} from 'react';
import {useMemo} from 'react';
import {MedicalAttachmentsResBody} from '../../server/types/medical-attachment.types';
import {MedicalAttachmentEnum} from '../medical-attachments/data/medicalAttachment';
import MedicalAttachmentsListView from '../medical-attachments/MedicalAttachmentsListView';
import ViewLaboratoryRequestView from '../medical-attachments/ViewLaboratoryRequestView';
import ViewMedicalCertificateView from '../medical-attachments/ViewMedicalCertificateView';
import ViewPrescriptionView from '../medical-attachments/ViewPrescriptionView';

interface MedicalAttachmentsModalProps {
  basedOnId?: string;
  subject?: string;
  serviceRequestId?: string;
  attachments?: MedicalAttachmentsResBody[];
}

function MedicalAttachmentsModal(props: MedicalAttachmentsModalProps) {
  const [medicalAttachmentType, setMedicalAttachmentType] = useState('');
  const [id, setId] = useState<string>('');

  const checkSelectedAttachment = (
    medicalAttachment: MedicalAttachmentEnum,
    attachmentId: string,
  ) => {
    setId(attachmentId);
    setMedicalAttachmentType(medicalAttachment);
  };

  const defaultView = useMemo(
    () => (
      <MedicalAttachmentsListView
        onSelectAttachment={checkSelectedAttachment}
        serviceRequestId={props.serviceRequestId}
        attachments={props.attachments}
      />
    ),
    [props],
  );

  const [view, setView] = useState(defaultView);

  useEffect(() => {
    switch (medicalAttachmentType) {
      case MedicalAttachmentEnum.MEDICAL_CERTIFICATE:
        setView(
          <ViewMedicalCertificateView
            id={id}
            subject={props.subject}
            onClose={() => {
              setMedicalAttachmentType('');
              setView(defaultView);
            }}
          />,
        );
        break;
      case MedicalAttachmentEnum.PRESCRIPTION:
        setView(
          <ViewPrescriptionView
            id={id}
            subject={props.subject}
            onClose={() => {
              setMedicalAttachmentType('');
              setView(defaultView);
            }}
          />,
        );
        break;
      case MedicalAttachmentEnum.LABORATORY_REQUEST:
        setView(
          <ViewLaboratoryRequestView
            id={id}
            onClose={() => {
              setMedicalAttachmentType('');
              setView(defaultView);
            }}
          />,
        );
        break;
      default:
        setView(defaultView);
        break;
    }
  }, [id, medicalAttachmentType, props, defaultView]);

  return <>{view}</>;
}

export default memo(MedicalAttachmentsModal);
