import {TColorPalette} from '../../../../core/config/color.config';

const FontFamily = {
  primary: 'Quicksand',
  secondary: 'Lato',
} as const;

export type TextColor = Exclude<
  TColorPalette,
  'greyOutline' | 'searchBg' | 'divider' | 'dark' | 'light'
>;

export type TextWeight = '300' | '400' | 'bold' | '600';
export const textWeightMapping: {[key in TextWeight]: string} = {
  '300': 'Light',
  '400': 'Regular',
  '600': 'Bold',
  bold: 'Bold',
};

export const textWeightBoldMapping: {[key in TextVariants]: TextWeight} = {
  header: '600',
  title: '600',
  subheader: 'bold',
  body: 'bold',
  button: 'bold',
};

export type TextAlign = 'left' | 'center' | 'right';

export const fontFamilyMapping: {[key in TextVariants]: string} = {
  header: FontFamily.primary,
  title: FontFamily.primary,
  subheader: FontFamily.secondary,
  body: FontFamily.secondary,
  button: FontFamily.primary,
};

export interface TypeScale {
  size: number;
  family: string;
  weight?: TextWeight;
}

export type TextVariants = 'header' | 'body' | 'title' | 'subheader' | 'button';

const typeScaleWeb: {[key in TextVariants]: TypeScale} = {
  header: {
    size: 40,
    family: '',
  },
  title: {
    size: 30,
    family: '',
  },
  subheader: {
    size: 30,
    family: '',
  },
  body: {
    size: 16,
    family: '',
  },
  button: {
    size: 14,
    family: '',
  },
};

const typeScaleMobile: {[key in TextVariants]: TypeScale} = {
  header: {
    size: 30,
    family: '',
  },
  title: {
    size: 20,
    family: '',
  },
  subheader: {
    size: 20,
    family: '',
  },
  body: {
    size: 14,
    family: '',
  },
  button: {
    size: 12,
    family: '',
  },
};

export const getTypeScale = (
  weight: TextWeight,
  variant: TextVariants,
  isWeb: boolean,
): TypeScale => {
  const fontWeightSuffix = textWeightMapping[weight];
  const fontWeightBold =
    weight === '600' || weight === 'bold'
      ? textWeightBoldMapping[variant]
      : weight;
  // console.log('fontWeightSuffix', weight, fontWeightSuffix);
  const typeScaleGroup = isWeb ? typeScaleWeb : typeScaleMobile;
  const typeScale = typeScaleGroup[variant];
  const fontFamily = fontFamilyMapping[variant] + '-' + fontWeightSuffix;
  typeScale.family = fontFamily;
  typeScale.weight = fontWeightBold;

  return {...typeScale};
};
