import {makeAutoObservable, observable, action, computed} from 'mobx';
import {PractitionerHospital} from '../server/types/practitioner-profile.types';

import {persistStore} from '../utils/mobx.utils';

export class HospitalStore {
  hospitals: PractitionerHospital[] = [];

  constructor() {
    makeAutoObservable(this, {
      hospitals: observable,
      setHospitals: action,
    });

    persistStore(this, ['hospitals'], 'HospitalStore');
  }

  setHospitals(data: PractitionerHospital[]) {
    this.hospitals = data;
  }
}
