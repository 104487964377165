import React from 'react';
import {createStackNavigator} from '@react-navigation/stack';

import {NavigationKey} from '../config/navigation.config';
import {NavigationTitle} from '../config/navigationTitle';
import {
  ConsultationPaymentEntryScreen,
  ConsultationPaymentFailScreen,
  ConsultationPaymentSuccessScreen,
} from '../../screens';
import {PaymentProvider} from '../../hooks/payment/usePayment';

const {Navigator, Screen} = createStackNavigator();

const PaymentWebViewStackNavigator = () => {
  return (
    <PaymentProvider>
      <Navigator
        initialRouteName={NavigationKey.CONSULTATION_PAYMENT_ENTRY}
        screenOptions={{
          headerShown: false,
        }}>
        <Screen
          name={NavigationKey.CONSULTATION_PAYMENT_ENTRY}
          component={ConsultationPaymentEntryScreen}
          options={{
            headerShown: false,
            title: NavigationTitle.CONSULTATION_PAYMENT_ENTRY,
            gestureEnabled: false,
          }}
        />
        <Screen
          name={NavigationKey.CONSULTATION_PAYMENT_FAIL}
          component={ConsultationPaymentFailScreen}
          options={{
            headerShown: false,
            title: NavigationTitle.CONSULTATION_PAYMENT_FAIL,
            gestureEnabled: false,
          }}
        />
        <Screen
          name={NavigationKey.CONSULTATION_PAYMENT_SUCCESS}
          component={ConsultationPaymentSuccessScreen}
          options={{
            headerShown: false,
            title: NavigationTitle.CONSULTATION_PAYMENT_SUCCESS,
            gestureEnabled: false,
          }}
        />
      </Navigator>
    </PaymentProvider>
  );
};

export default PaymentWebViewStackNavigator;
