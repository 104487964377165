import React, {memo} from 'react';
import {Platform, View} from 'react-native';

import {makeStyles} from 'rne-netzon';

import {SVGIcon, RNEButton, RNEText} from '../../components';

interface LogoutConfirmationModalProps {
  onCancel?: () => void;
  onConfirm?: () => void;
}

function ConsultationCancelConfirmationModal(
  props: LogoutConfirmationModalProps,
) {
  const rneStyles = useStyles();

  return (
    <View style={rneStyles.root}>
      <RNEText
        label="Are you sure you want to cancel the consultation request?"
        variant="header"
        align="center"
        weight="600"
        color="accent"
      />

      <View style={rneStyles.actionContainer}>
        <View style={rneStyles.buttonContainer}>
          <RNEButton
            title="Cancel"
            color="secondary"
            onPress={props.onCancel}
            style={rneStyles.buttonStyle}
            variant="outlined"
            height={60}
          />
        </View>
        <RNEButton
          title="Cancel Request"
          color="secondary"
          onPress={props.onConfirm}
          style={rneStyles.buttonStyle}
          height={60}
        />
      </View>
    </View>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 540,
    padding: Platform.OS === 'web' ? 60 : 32,
    justifyContent: 'center',
  },
  actionContainer: {
    marginTop: 80,
    justifyContent: 'center',
    flexDirection: 'row',
  },
  buttonContainer: {
    marginRight: 16,
  },
  buttonStyle: {
    width: 140,
  },
}));

export default memo(ConsultationCancelConfirmationModal);
