import React from 'react';
import {View, Image} from 'react-native';

import {makeStyles, Rating, Divider} from 'rne-netzon';
import {colorPalette} from '../../../core/config/color.config';

import {RNEText, RNEButton, SVGIcon} from '../../components';
import {formatAmount} from '../../utils/currency.utils';
import ContentScrollView from '../scroll/ContentScrollView';

import {useGetPractitionerProfile} from './../../server/react-query/usePractitioner';

interface PractitionerRequestProfileViewModalProps {
  practitionerId: string;
  onClose?: () => void;
  onRequest?: () => void;
}

const PractitionerRequestProfileViewModal = (
  props: PractitionerRequestProfileViewModalProps,
) => {
  const styles = useStyles();

  const {data, isLoading} = useGetPractitionerProfile({
    practitionerId: props.practitionerId,
  });

  return (
    <View style={styles.root}>
      <View style={styles.headerContainer}>
        <View style={styles.mainDetailContainer}>
          <View style={styles.mainDetailItem}>
            {!isLoading ? (
              <RNEText
                label={`Dr. ${data?.humanName.given[0]} ${data?.humanName.family}`}
                variant="title"
                weight="600"
                color="blackLight"
                numberOfLines={2}
                ellipsizeMode="tail"
              />
            ) : (
              <RNEButton
                height={24}
                loading={isLoading}
                type="clear"
                loadingProps={loadingStyle}
                buttonStyle={buttonStyle}
              />
            )}
          </View>

          <View style={styles.mainDetailItem}>
            <RNEText
              label={
                data
                  ? data?.organization[0].address?.city
                    ? `${data?.practitionerRole[0].specialty.text} at ${data?.organization[0].address?.city}`
                    : `${data?.practitionerRole[0].specialty.text}`
                  : '--'
              }
              color="blackLight"
            />
          </View>

          <View style={styles.mainDetailItem}>
            <View style={styles.mainDetailItemKey}>
              <RNEText label={'Consultation Fee:'} />
            </View>
            <View style={styles.mainDetailItemValue}>
              <RNEText
                label={
                  data
                    ? `${data.fee.amount.currency} ${formatAmount(
                        data?.fee.amount.value,
                      )}`
                    : '--'
                }
                weight="600"
              />
            </View>
          </View>

          <View style={styles.mainDetailItem}>
            {/* <Rating
              readonly
              ratingCount={5}
              imageSize={24}
              fractions={0}
              startingValue={5}
              jumpValue={1}
              style={styles.ratingStyle}
            /> */}
          </View>
        </View>
        <View style={styles.profilePictureContainer}>
          {data && data.photo ? (
            <Image
              source={{
                uri: data.photo.url,
                width: 96,
                height: 96,
              }}
              style={imageStyle}
              accessibilityLabel={data.photo.title}
            />
          ) : (
            <SVGIcon name="user" size={48} />
          )}
        </View>
      </View>

      <Divider style={styles.divider} />

      <View style={styles.otherContentContainer}>
        <View style={styles.otherContentContainerHeader}>
          <RNEText
            label={'What cases I usually handle:'}
            color="accent"
            weight="600"
          />
        </View>

        <ContentScrollView isWeb>
          <RNEText label={data?.handledCases || '--'} />
        </ContentScrollView>
      </View>

      <View style={styles.actionContainer}>
        <RNEButton
          title="Back"
          color="secondary"
          variant="outlined"
          onPress={props.onClose}
          style={styles.buttonStyle}
        />
        <RNEButton
          title="Request Appointment"
          color="secondary"
          onPress={props.onRequest}
          style={[styles.buttonStyle, styles.buttonSibling]}
        />
      </View>
    </View>
  );
};

const buttonStyle = {
  paddingHorizontal: 0,
};

const loadingStyle = {
  color: colorPalette.blackLight,
};

const imageStyle = {
  width: 96,
  height: 96,
  borderRadius: 96,
};

const useStyles = makeStyles(theme => ({
  root: {
    width: 720,
    paddingHorizontal: 48,
    paddingVertical: 32,
  },
  headerContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  mainDetailContainer: {
    flex: 1,
  },
  mainDetailItem: {
    marginBottom: 4,
    flexDirection: 'row',
  },
  mainDetailItemKey: {
    marginRight: 4,
  },
  mainDetailItemValue: {},
  profilePictureContainer: {
    width: 96,
    height: 96,
    borderRadius: 96,
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 2,
    borderColor: colorPalette.light,
    marginLeft: 24,
  },
  divider: {
    marginVertical: 32,
  },
  otherContentContainer: {
    marginTop: 16,
    maxHeight: 200,
  },
  otherContentContainerHeader: {
    marginBottom: 4,
  },
  actionContainer: {
    marginTop: 48,
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  buttonStyle: {
    width: 200,
  },
  buttonSibling: {
    marginLeft: 16,
  },
  ratingStyle: {
    alignSelf: 'flex-start',
    paddingVertical: 8,
  },
}));

export default PractitionerRequestProfileViewModal;
