import React, {memo} from 'react';
import {Platform, View} from 'react-native';

import {makeStyles} from 'rne-netzon';

import {RNEText, RNEButton} from '../../components';

interface ResendVerificationSuccessModalProps {
  onConfirm?: () => void;
}

function ResendVerificationSuccessModal(
  props: ResendVerificationSuccessModalProps,
) {
  const rneStyles = useStyles();

  return (
    <View style={rneStyles.root}>
      <RNEText
        label="Verification request sent!"
        variant="subheader"
        align="center"
        weight="600"
        color="accent"
      />

      <View style={rneStyles.detailText}>
        <RNEText
          label="Your application is still on verification status. This process normally takes 24-48 hrs."
          variant="body"
          align="center"
        />
      </View>

      <View style={rneStyles.actionContainer}>
        <RNEButton
          style={rneStyles.button}
          title="Great!"
          color="secondary"
          onPress={props.onConfirm}
        />
      </View>
    </View>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: Platform.OS === 'web' ? 480 : '80%',
    paddingVertical: Platform.OS === 'web' ? 32 : 16,
    paddingHorizontal: Platform.OS === 'web' ? 48 : 16,
  },
  detailText: {
    marginTop: 24,
  },
  actionContainer: {
    marginTop: 36,
    alignItems: 'center',
  },
  button: {
    width: 150,
  },
}));

export default memo(ResendVerificationSuccessModal);
