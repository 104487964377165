import React, {memo} from 'react';
import {View} from 'react-native';

import {makeStyles} from 'rne-netzon';

import {ConsultationRequestResBody} from '../../server/types/consultation.types';

import useStores from '../../stores/useStores';

import ConsultationRequestMessage from './ConsultationRequestMessage';
import ConsultationMessageAttachment from './ConsultationMessageAttachment';
import {useGetInvoice} from '../../server/react-query/usePayment';
import {ConsultationInformationMessage} from '..';
import {formatAmount} from '../../utils/currency.utils';

interface ConsultationDetailPendingProps {
  serviceRequest: ConsultationRequestResBody;
}

const ConsultationDetailPending = (props: ConsultationDetailPendingProps) => {
  const {authStore} = useStores();
  const styles = useStyles();

  const {data} = useGetInvoice({
    serviceId: props.serviceRequest.procedure?.procedureId || '',
    patientId: props.serviceRequest.subject?.patientId,
  });

  return (
    <View style={styles.body}>
      <ConsultationRequestMessage
        message={props.serviceRequest.note?.[0]?.text}
        timestamp={new Date(props.serviceRequest.note?.[0]?.time)}
        owner={authStore.userRole === 'patient'}
      />

      {authStore.userRole === 'practitioner' && (
        <ConsultationMessageAttachment
          messageType={
            authStore.userRole === 'practitioner' ? 'incoming' : 'outgoing'
          }
          attribute={{
            Type: 'history',
          }}
          serviceRequest={props.serviceRequest}
        />
      )}

      {data && (
        <ConsultationInformationMessage
          timestamp={
            data && data[0] && data[0].date
              ? new Date(data[0].date)
              : new Date()
          }
          fee={formatAmount(data.length ? data[0].totalGross.value : 0)}
        />
      )}
    </View>
  );
};

const useStyles = makeStyles({
  body: {
    paddingVertical: 12,
  },
});

export default memo(ConsultationDetailPending);
