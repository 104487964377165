import React, {memo, useEffect, useState} from 'react';
import {View, TouchableOpacity} from 'react-native';

import {makeStyles} from 'rne-netzon';

import {colorPalette} from '../../../core/config/color.config';
import {RNEText} from '../../components';

import useStores from '../../stores/useStores';

import {Photo} from '../../server/types/user.types';
import {
  ConsultationRequestResBody,
  ConsultationStatusEnum,
} from '../../server/types/consultation.types';

import ConsultationPhoto from './ConsultationPhoto';
import ConsultationStatus from './ConsultationStatus';

import {formatToMessageTime} from '../../utils/date.utils';

interface ConsultationListItemHistoryProps {
  item: ConsultationRequestResBody;
  onPress?: () => void;
}

const ConsultationListItemHistory = (
  props: ConsultationListItemHistoryProps,
) => {
  const styles = useStyle();
  const {authStore} = useStores();

  const [displayName, setDisplayName] = useState('');
  const [displayPhoto, setDisplayPhoto] = useState<Photo>();
  const [statusByPatient, setStatusByPatient] = useState(false);

  const updateDisplayNameByStatus = () => {
    if (props.item.subject) {
      switch (props.item.status) {
        case ConsultationStatusEnum.PENDING:
          setDisplayName(
            `"Patient ${props.item.subject.patientId
              .substring(0, 6)
              .toUpperCase()}"`,
          );
          break;

        case ConsultationStatusEnum.CANCELLED:
          setDisplayName(
            `"Patient ${props.item.subject.patientId
              .substring(0, 6)
              .toUpperCase()}"`,
          );
          break;

        case ConsultationStatusEnum.DECLINED:
          setDisplayName(
            `"Patient ${props.item.subject.patientId
              .substring(0, 6)
              .toUpperCase()}"`,
          );
          break;

        case ConsultationStatusEnum.ONGOING:
          setDisplayName(
            `${props.item.subject.humanName.given[0]} ${props.item.subject.humanName.family}`,
          );
          break;

        case ConsultationStatusEnum.COMPLETED:
          setDisplayName(
            `${props.item.subject.humanName.given[0]} ${props.item.subject.humanName.family}`,
          );
          break;

        default:
          break;
      }
      setDisplayPhoto(props.item.subject.photo);
    } else {
      setDisplayName('Deleted Profile');
    }
  };

  useEffect(() => {
    switch (authStore.userRole) {
      case 'patient':
        setStatusByPatient(
          authStore.auth?.user.userId === props.item?.modifiedBy,
        );

        if (props.item.performer) {
          setDisplayName(
            `Dr. ${props.item.performer.humanName.given[0]} ${props.item.performer.humanName.family}`,
          );
          setDisplayPhoto(props.item.performer.photo);
        } else {
          setDisplayName('Deleted Profile');
        }
        break;

      case 'practitioner':
        updateDisplayNameByStatus();
        setStatusByPatient(
          !(authStore.auth?.user.userId === props.item?.modifiedBy),
        );
        break;

      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authStore.userRole, props]);

  return (
    <TouchableOpacity activeOpacity={0.75} onPress={props.onPress}>
      <View style={styles.root}>
        <View style={styles.photo}>
          <ConsultationPhoto photo={displayPhoto} />
        </View>

        <View style={styles.message}>
          <View>
            <RNEText
              label={displayName}
              color="blackLight"
              weight="600"
              numberOfLines={1}
              ellipsizeMode="tail"
            />
          </View>

          <View>
            <RNEText
              label={formatToMessageTime(new Date(props.item.modifiedOn))}
              color="blackLight"
              variant="button"
              weight="400"
              numberOfLines={2}
              ellipsizeMode="tail"
            />
          </View>
        </View>

        <View style={styles.statusContainer}>
          <ConsultationStatus
            consultationStatus={props.item.status}
            procedureStatus={props.item.procedure?.status}
            isActionByPatient={statusByPatient}
          />
        </View>
      </View>
    </TouchableOpacity>
  );
};

const useStyle = makeStyles({
  root: {
    flexDirection: 'row',
    paddingHorizontal: 24,
    paddingVertical: 24,
    borderBottomWidth: 1,
    borderBottomColor: colorPalette.light,
  },
  photo: {},
  message: {
    flex: 1,
    paddingHorizontal: 16,
    justifyContent: 'center',
  },
  statusContainer: {
    justifyContent: 'center',
  },
  status: {
    borderRadius: 100,
    backgroundColor: colorPalette.accent,
    paddingHorizontal: 12,
    paddingVertical: 6,
  },
});

export default memo(ConsultationListItemHistory);
