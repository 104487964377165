import React, {useState, useEffect} from 'react';
import {
  Platform,
  ScrollView,
  View,
  TouchableOpacity,
  TouchableWithoutFeedback,
} from 'react-native';

import {observer} from 'mobx-react';

import {makeStyles, Divider} from 'rne-netzon';
import {colorPalette} from '../../../core/config/color.config';

import useStores from '../../stores/useStores';

import {useRoute, useNavigation} from '@react-navigation/native';
import {NavigationKey} from '../../navigations/config';

import {PractitionerStatus} from '../../server/types/practitioner-status.types';
import {PractitionerProfile} from '../../server/types/practitioner-profile.types';
import {useGetPractitionerProfiles} from './../../server/react-query/usePractitioner';

import {
  RNEText,
  RNEInput,
  RNEButton,
  RNSearchableDropdown,
  RNSearchableDropdownOption,
  SVGIcon,
} from '../../components';
import {DoctorSearchList} from '../../views';

const PatientSearchResultScreen = () => {
  const route = useRoute();
  const navigation = useNavigation();
  const sharedStyles = useSharedStyles();
  const webStyles = useWebStyles();
  const mobileStyles = useMobileStyles();
  const {specializationStore} = useStores();

  const [searchKey, setSearchKey] = useState('');
  const [searchByDoctor, setSearchByDoctor] = useState(false);
  const [dropdownValue, setDropdownValue] = useState<
    number | string | null | undefined
  >();
  const [dropdownOptions, setDropdownOptions] = useState<
    RNSearchableDropdownOption[]
  >([]);
  const [isLoadedFromParams, setIsLoadedFromParams] = useState(false);
  const [searchEnabled, setSearchEnabled] = useState(false);

  const {data, isLoading, isFetching, refetch} = useGetPractitionerProfiles(
    {
      searchKey: searchByDoctor ? searchKey : '',
      profession: !searchByDoctor ? (dropdownValue as string) || '' : '',
      status: PractitionerStatus.VERIFIED,
      pageSize: 1000,
    },
    {
      enabled: searchEnabled,
      cacheTime: 0,
    },
  );

  const switchSearchBy = () => {
    if (!isFetching) {
      setSearchKey('');
      setTimeout(() => {
        setDropdownValue(undefined);
      }, 10);
      setSearchByDoctor(!searchByDoctor);
    }
  };

  const onDropdownChange = (val: string | number | null | undefined) => {
    if (dropdownValue !== val) {
      setDropdownValue(val);
    }
  };

  const searchDoctor = () => {
    if (searchEnabled) {
      refetch();
    }
  };

  useEffect(() => {
    setDropdownOptions(
      specializationStore.specializations.map(specialization => ({
        name: specialization.value,
        value: specialization.specializationId,
      })),
    );
  }, [specializationStore.specializations]);

  useEffect(() => {
    if (searchEnabled) {
      searchDoctor();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchByDoctor]);

  useEffect(() => {
    const params = route.params as any;

    if (params) {
      setSearchKey(params.searchKey || '');
      setSearchByDoctor(params.searchType === 'name');
      setDropdownValue(params.searchDropValue);
      setTimeout(() => {
        setIsLoadedFromParams(true);
      }, 10);
    }
  }, [route.params]);

  useEffect(() => {
    if (isLoadedFromParams) {
      setSearchEnabled(true);
    }
  }, [isLoadedFromParams]);

  useEffect(() => {
    if (searchEnabled) {
      searchDoctor();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownValue]);

  useEffect(() => {
    if (searchEnabled) {
      searchDoctor();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchEnabled]);

  return (
    <>
      {Platform.OS === 'web' ? (
        <ScrollView>
          <View style={webStyles.mainContainer}>
            <View style={webStyles.titleContainer}>
              <RNEText
                label={
                  searchByDoctor
                    ? "Search by Doctor's Name:"
                    : "Search Doctor's Specialization:"
                }
                variant="body"
                color="accent"
              />
            </View>
            <View style={webStyles.searchContainer}>
              <View style={webStyles.dropdownContainer}>
                {searchByDoctor ? (
                  <View style={webStyles.searchInput}>
                    <RNEInput
                      height={48}
                      value={searchKey}
                      onChangeText={setSearchKey}
                      placeholder="Input Doctor's Name here"
                      variant="shadowed"
                      inputContainerStyle={inputContainerStyle}
                      onSubmitEditing={searchDoctor}
                    />
                  </View>
                ) : (
                  <View style={webStyles.dropdown}>
                    <RNSearchableDropdown
                      value={dropdownValue}
                      placeholder="Input Doctor's Specialization here"
                      options={dropdownOptions}
                      onChange={onDropdownChange}
                    />
                  </View>
                )}

                <View style={webStyles.buttonContainer}>
                  <RNEButton
                    title="Find a Doctor"
                    color="secondary"
                    style={webStyles.buttonStyle}
                    onPress={searchDoctor}
                  />
                </View>
              </View>
              <View style={webStyles.textButton}>
                <TouchableWithoutFeedback
                  onPress={switchSearchBy}
                  accessibilityRole="button">
                  <RNEText
                    label={
                      searchByDoctor
                        ? "Input Doctor's Specialization instead"
                        : 'Find a Doctor by Name Instead'
                    }
                    variant="button"
                    weight="bold"
                    color="secondary"
                  />
                </TouchableWithoutFeedback>
              </View>
            </View>

            <Divider style={webStyles.divider} />

            {!isLoading && data && data.data.length === 0 && (
              <View style={webStyles.placeholderContainer}>
                <RNEText label="No results found" color="grey" />
              </View>
            )}

            {isLoading && (
              <RNEButton
                height={24}
                loading={isLoading}
                type="clear"
                loadingProps={loadingStyle}
                buttonStyle={buttonStyle}
              />
            )}

            {isFetching && !isLoading && (
              <View style={[]}>
                <RNEButton
                  height={24}
                  loading={isFetching}
                  type="clear"
                  loadingProps={loadingStyle}
                  buttonStyle={buttonStyle}
                />
              </View>
            )}

            <View style={webStyles.listContainer}>
              <DoctorSearchList practitioners={data?.data || []} />
            </View>
          </View>
        </ScrollView>
      ) : (
        <ScrollView>
          <View style={mobileStyles.mainContainer}>
            <View>
              <RNEText
                label={
                  searchByDoctor
                    ? "Search by Doctor's Name:"
                    : "Search Doctor's Specialization:"
                }
                variant="subheader"
                weight="bold"
                color="accent"
              />
            </View>

            {searchByDoctor ? (
              <View style={mobileStyles.searchInput}>
                <RNEInput
                  value={searchKey}
                  onChangeText={setSearchKey}
                  placeholder="Input Doctor's Name here"
                  inputContainerStyle={inputContainerStyle}
                  onSubmitEditing={searchDoctor}
                  rightIcon={
                    searchKey ? (
                      <TouchableOpacity
                        onPress={() => {
                          setSearchKey('');
                          setTimeout(() => {
                            searchDoctor();
                          }, 200);
                        }}>
                        <SVGIcon name="close-gray-71" />
                      </TouchableOpacity>
                    ) : null
                  }
                  returnKeyType="search"
                />
              </View>
            ) : (
              <View style={mobileStyles.dropdownContainer}>
                <RNSearchableDropdown
                  placeholder="Input Doctor's Specialization here"
                  value={dropdownValue}
                  options={dropdownOptions}
                  onChange={onDropdownChange}
                />
              </View>
            )}

            <TouchableWithoutFeedback onPress={switchSearchBy}>
              <View accessibilityRole="button" style={mobileStyles.textButton}>
                <RNEText
                  label={
                    searchByDoctor
                      ? "Input Doctor's Specialization instead"
                      : 'Find a Doctor by Name Instead'
                  }
                  variant="body"
                  weight="bold"
                  color="secondary"
                />
              </View>
            </TouchableWithoutFeedback>

            {!isLoading && data && data.data.length === 0 && (
              <View style={webStyles.placeholderContainer}>
                <RNEText label="No results found" color="grey" />
              </View>
            )}

            {isLoading && (
              <RNEButton
                height={24}
                loading={isLoading}
                type="clear"
                loadingProps={loadingStyle}
                buttonStyle={buttonStyle}
              />
            )}

            {isFetching && !isLoading && (
              <View style={[]}>
                <RNEButton
                  height={24}
                  loading={isFetching}
                  type="clear"
                  loadingProps={loadingStyle}
                  buttonStyle={buttonStyle}
                />
              </View>
            )}

            <View style={mobileStyles.listContainer}>
              <DoctorSearchList practitioners={data?.data || []} />
            </View>
          </View>
        </ScrollView>
      )}
    </>
  );
};

const buttonStyle = {
  paddingHorizontal: 0,
  marginTop: 0,
};

const loadingStyle = {
  color: colorPalette.primary,
};

const inputContainerStyle = {
  height: 48,
  borderRadius: 30,
  shadowOffset: {
    width: -5,
    height: 10,
  },
  shadowOpacity: 0.5,
  shadowRadius: 20,
  shadowColor: colorPalette.grey,
  backgroundColor: colorPalette.white,
  zIndex: 50,
  elevation: 50,
};

const useWebStyles = makeStyles({
  mainContainer: {
    padding: 100,
  },
  titleContainer: {
    paddingLeft: 24,
  },
  searchContainer: {
    marginTop: 32,
    zIndex: 20,
  },
  dropdownContainer: {
    flexDirection: 'row',
    zIndex: 20,
  },
  dropdown: {
    height: 48,
    flex: 1,
    justifyContent: 'center',
  },
  searchInput: {
    height: 48,
    flex: 1,
    justifyContent: 'center',
  },
  buttonContainer: {
    marginLeft: 24,
  },
  buttonStyle: {
    width: 168,
  },
  textButton: {
    marginTop: 24,
    zIndex: 10,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  divider: {
    height: 1,
    color: colorPalette.light,
    marginVertical: 32,
  },
  listContainer: {
    marginTop: 16,
  },
  placeholderContainer: {
    marginTop: 12,
    alignItems: 'center',
  },
});

const useMobileStyles = makeStyles({
  mainContainer: {
    padding: 40,
  },
  listContainer: {
    marginTop: 16,
  },
  searchInput: {
    marginTop: 24,
    marginBottom: 16,
    height: 48,
  },
  dropdownContainer: {
    marginTop: 24,
    height: 64,
    zIndex: 50,
    elevation: 50,
  },
  textButton: {
    alignItems: 'flex-end',
    height: 60,
    zIndex: 10,
    elevation: 10,
  },
});

const useSharedStyles = makeStyles({
  root: {
    height: '100%',
    flexDirection: 'row',
    width: '100%',
  },
});

export default observer(PatientSearchResultScreen);
