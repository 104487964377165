import React from 'react';
import {Platform} from 'react-native';
import {createStackNavigator} from '@react-navigation/stack';

import {colorPalette} from '../../../core/config/color.config';

import {NavigationKey} from '../config/navigation.config';
import {NavigationTitle} from '../config/navigationTitle';

import {
  AccountDeletionConfirmationScreen,
  PatientEditAccountScreen,
  PatientViewAccountScreen,
} from '../../screens';
import {HeaderBackLeft} from '../../views';

const {Navigator, Screen} = createStackNavigator();

const mobileBackHeaderNoTitleOptions = {
  title: '',
  headerStyle: {
    elevation: 0,
    shadowOpacity: 0,
  },
  headerTintColor: colorPalette.blackLight,
  headerTitleStyle: {
    fontFamily: 'Quicksand-Regular',
    fontSize: 14,
    paddingLeft: 28,
  },
  headerLeft: () => <HeaderBackLeft />,
};

const PatientAccountStackWebNavigator = () => {
  return (
    <Navigator>
      <Screen
        name={NavigationKey.PATIENT_ACCOUNT}
        component={PatientViewAccountScreen}
        options={{headerShown: false, title: NavigationTitle.PATIENT_ACCOUNT}}
      />
      <Screen
        name={NavigationKey.PATIENT_ACCOUNT_EDIT}
        component={PatientEditAccountScreen}
        options={{
          headerShown: false,
          title: NavigationTitle.PATIENT_ACCOUNT_EDIT,
        }}
      />
    </Navigator>
  );
};

const PatientAccountStackMobileNavigator = () => {
  return (
    <Navigator>
      <Screen
        name={NavigationKey.PATIENT_ACCOUNT}
        component={PatientViewAccountScreen}
        options={{
          ...mobileBackHeaderNoTitleOptions,
          headerStyle: {
            backgroundColor: colorPalette.primary,
            borderBottomWidth: 0,
            elevation: 0,
            shadowOpacity: 0,
          },
          headerLeft: () => (
            <HeaderBackLeft removeLabel={true} iconName="left-white" />
          ),
        }}
      />
      <Screen
        name={NavigationKey.PATIENT_ACCOUNT_EDIT}
        component={PatientEditAccountScreen}
        options={{
          ...mobileBackHeaderNoTitleOptions,
          headerStyle: {
            backgroundColor: colorPalette.primary,
            borderBottomWidth: 0,
            elevation: 0,
          },
          headerLeft: () => (
            <HeaderBackLeft removeLabel={true} iconName="left-white" />
          ),
        }}
      />
      <Screen
        name={NavigationKey.PATIENT_PROFILE_DELETE}
        component={AccountDeletionConfirmationScreen}
        options={{
          ...mobileBackHeaderNoTitleOptions,
        }}
      />
    </Navigator>
  );
};

export default Platform.OS === 'web'
  ? PatientAccountStackWebNavigator
  : PatientAccountStackMobileNavigator;
