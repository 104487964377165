import React from 'react';
import {View} from 'react-native';

import {makeStyles} from 'rne-netzon';

import {PatientProfileReqResponse} from '../../server/types/patient-profile.types';
import {ConsultationStatusEnum} from '../../server/types/consultation.types';

import ConsultationPatientMedicalView from '../medical-history/ConsultationPatientMedicalView';

interface ConsultationPatientMedicalModalProps {
  patient: PatientProfileReqResponse;
  status: ConsultationStatusEnum;
  onClose?: () => void;
}

const ConsultationPatientMedicalModal = (
  props: ConsultationPatientMedicalModalProps,
) => {
  const styles = useStyles();

  return (
    <View style={styles.root}>
      <ConsultationPatientMedicalView
        patient={props.patient}
        status={props.status}
        onClose={props.onClose}
      />
    </View>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '75vw',
    maxWidth: '75vw',
    height: '70vh',
    maxHeight: '70vh',
    flexDirection: 'row',
  },
}));

export default ConsultationPatientMedicalModal;
