import React, {useEffect, useState} from 'react';
import {View} from 'react-native';

import {ScrollView} from 'react-native-gesture-handler';

import {useNavigation, useRoute} from '@react-navigation/native';
import {NavigationKey} from '../../navigations/config';

import {makeStyles} from 'rne-netzon';
import {colorPalette} from '../../../core/config/color.config';

import {RNEButton, RNEText} from '../../components';

import useStores from '../../stores/useStores';

import {useGetOngoingConsultation} from '../../server/react-query/useConsultation';
import {
  ProcedureConsultationReqResponse,
  ProcedureStatusEnum,
} from '../../server/types/procedure.types';

import ConsultationListItemOngoing from '../consultation/ConsultationListItemOngoing';

interface ConsultationOngoingTabProps {
  searchKey?: string;
}

const ConsultationOngoingTab = (props: ConsultationOngoingTabProps) => {
  const styles = useStyles();

  const route = useRoute();
  const navigation = useNavigation();

  const {patientProfileStore, recentlyViewedAccountsStore} = useStores();
  const activeProfile = patientProfileStore.activeProfileData;

  const [routeParams, setRouteParams] = useState({});

  const {data, isLoading} = useGetOngoingConsultation({
    status: [ProcedureStatusEnum.ONGOING],
    subject: activeProfile?.patientId,
    SortBy: 'noteTime',
    Ascending: false,
    searchKey: props.searchKey,
  });

  const onItemPressed = (
    id: string,
    consultationData: ProcedureConsultationReqResponse,
  ) => {
    recentlyViewedAccountsStore.addRecentlyViewedAccount(id, consultationData);
    const params = {
      ...routeParams,
      id: id,
    };
    navigation.navigate(NavigationKey.CONSULTATION_DETAIL, params);
  };

  useEffect(() => {
    const params: any = route.params;

    if (params) {
      setRouteParams(params);
    }
  }, [route.params]);

  return (
    <ScrollView>
      {isLoading && (
        <View style={styles.spinnerContainer}>
          <RNEButton
            height={32}
            loadingProps={loadingPropsStyle}
            loading={isLoading}
          />
        </View>
      )}

      {data &&
        data.map((item, index) => (
          <ConsultationListItemOngoing
            key={index}
            item={item}
            onPress={() => onItemPressed(item.basedOnId, item)}
          />
        ))}

      {data && data.length === 0 && (
        <View style={styles.placeholder}>
          <RNEText
            label={props.searchKey ? '' : 'No ongoing consultation yet'}
            color="grey"
          />
        </View>
      )}
    </ScrollView>
  );
};

const loadingPropsStyle = {
  color: colorPalette.black,
};

const useStyles = makeStyles({
  spinnerContainer: {
    marginTop: 16,
    width: '100%',
    justifyContent: 'flex-end',
  },
  placeholder: {
    marginTop: 16,
    alignItems: 'center',
  },
});

export default ConsultationOngoingTab;
