import React, {useState, useEffect} from 'react';

import {useTheme, Button, ButtonProps} from 'rne-netzon';
import {IconButtonColor} from './models';

interface RNEButtonProps extends ButtonProps {
  color?: IconButtonColor;
  size?: number;
}

export const RNEIconButton = (props: RNEButtonProps) => {
  const {theme} = useTheme();

  const [backgroundColor, setBackgroundColor] = useState<string>();

  let {size, ...others} = props;

  const updateButtonStyle = () => {
    switch (props.color) {
      case 'primary':
        setBackgroundColor(theme.colors?.primary);
        break;

      case 'secondary':
        setBackgroundColor(theme.colors?.secondary);
        break;

      case 'success':
        setBackgroundColor(theme.colors?.success);
        break;

      case 'warning':
        setBackgroundColor(theme.colors?.warning);
        break;

      case 'error':
        setBackgroundColor(theme.colors?.error);
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    updateButtonStyle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.color]);

  return (
    <Button
      {...others}
      buttonStyle={{
        backgroundColor: backgroundColor,
        height: size,
        width: size,
        borderRadius: size,
      }}
    />
  );
};
