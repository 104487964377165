import {AxiosRequestConfig} from 'axios';
import {useMutation, useQueries, useQuery, useQueryClient} from 'react-query';
import {API, ApiEndpointsEnum} from '../api';

import {convertJsonToParams} from './../utils/query.util';
import {PaginatedData} from './../types/pagination.type';
import {
  AddPatientProfileReqBody,
  AddPatientProfileReqUIBody,
  PatientProfileReqResponse,
  PatientProfileReqBody,
  UploadPatientProfilePhotoReqBody,
  AdminPatientProfile,
  AdminPatientProfileListParams,
  GetAdminPatientProfileReqBody,
} from '../types/patient-profile.types';

const addPatientProfile = async (reqBody: AddPatientProfileReqBody) => {
  return API.post<PatientProfileReqResponse>(
    `${ApiEndpointsEnum.POST_PATIENT_PROFILE}`,
    reqBody,
  );
};

const useAddPatientProfile = (reqBody: AddPatientProfileReqUIBody) => {
  const queryClient = useQueryClient();

  const moldedReqBody: AddPatientProfileReqBody = {
    firstName: reqBody.firstName,
    middleName: reqBody.middleName,
    lastName: reqBody.lastName,
    location: reqBody.location,
    relationship: reqBody.relationship,
    contactNumber: reqBody.contactNumber,
    birthdate: reqBody.birthdate,
    telecom: reqBody.telecom ? [reqBody.telecom] : [],
    allergyIntolerance: reqBody.allergyIntolerance
      ? [reqBody.allergyIntolerance]
      : [],
    familyMemberHistory: reqBody.familyMemberHistory
      ? [reqBody.familyMemberHistory]
      : [],
    procedure: reqBody.procedure ? [reqBody.procedure] : [],
    medicationStatement: reqBody.medicationStatement
      ? [reqBody.medicationStatement]
      : [],
    condition: reqBody.condition ? [reqBody.condition] : [],
    sex: reqBody.sex,
  };

  return useMutation(
    ['add-patient-profile'],
    () => addPatientProfile(moldedReqBody),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['add-patient-profile']);
        queryClient.fetchQuery(['get-patient-profiles']);
      },
    },
  );
};

const editPatientProfile = async (
  reqBody: AddPatientProfileReqBody,
  patientId: string,
) => {
  const url = ApiEndpointsEnum.PUT_PATIENT_PROFILE.replace(
    '{patientId}',
    patientId,
  );
  return API.put<PatientProfileReqResponse>(url, reqBody);
};

const useEditPatientProfile = (
  reqBody: AddPatientProfileReqUIBody,
  patientId: string,
) => {
  const queryClient = useQueryClient();

  const moldedReqBody: AddPatientProfileReqBody = {
    firstName: reqBody.firstName,
    middleName: reqBody.middleName,
    lastName: reqBody.lastName,
    location: reqBody.location,
    relationship: reqBody.relationship,
    contactNumber: reqBody.contactNumber,
    birthdate: reqBody.birthdate,
    telecom: reqBody.telecom ? [reqBody.telecom] : [],
    allergyIntolerance: reqBody.allergyIntolerance
      ? [reqBody.allergyIntolerance]
      : [],
    familyMemberHistory: reqBody.familyMemberHistory
      ? [reqBody.familyMemberHistory]
      : [],
    procedure: reqBody.procedure ? [reqBody.procedure] : [],
    medicationStatement: reqBody.medicationStatement
      ? [reqBody.medicationStatement]
      : [],
    condition: reqBody.condition ? [reqBody.condition] : [],
    sex: reqBody.sex,
  };

  return useMutation(
    ['edit-patient-profile'],
    () => editPatientProfile(moldedReqBody, patientId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['edit-patient-profile']);
        queryClient.fetchQuery(['get-patient-profiles']);
      },
    },
  );
};

const getPatientProfiles = () => {
  return API.get<PatientProfileReqResponse[]>(
    `${ApiEndpointsEnum.GET_PATIENT_PROFILES}`,
  );
};

const useGetPatientProfiles = () => {
  const queryClient = useQueryClient();

  return useQuery(['get-patient-profiles'], getPatientProfiles, {
    onSuccess: () => {
      queryClient.invalidateQueries(['get-patient-profiles'], {
        refetchActive: false,
      });
    },
  });
};

const getPatientProfile = (reqBody: PatientProfileReqBody) => {
  const url = ApiEndpointsEnum.GET_PATIENT_PROFILE.replace(
    '{patientId}',
    reqBody.patientId,
  );
  return API.get<PatientProfileReqResponse>(url);
};

const useGetPatientProfile = (reqBody: PatientProfileReqBody) => {
  const queryClient = useQueryClient();

  return useQuery(
    ['get-patient-profile', {id: reqBody.patientId}],
    () => getPatientProfile(reqBody),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          ['get-patient-profile', {id: reqBody.patientId}],
          {
            refetchActive: false,
          },
        );
      },
    },
  );
};

const uploadPatientProfile = async (
  reqBody: UploadPatientProfilePhotoReqBody,
  patientId: string,
) => {
  const headerAuthorization: any = {
    'Content-Type': 'multipart/form-data',
  };

  const config: AxiosRequestConfig = {
    headers: {
      ...headerAuthorization,
    },
  };

  const url = ApiEndpointsEnum.PUT_PATIENT_PROFILE_PHOTO.replace(
    '{patientId}',
    patientId,
  );

  const formData = new FormData();

  formData.append('image', reqBody.image!);

  return API.put<PatientProfileReqResponse>(url, formData, config);
};

const useUploadPatientProfile = (
  reqBody: UploadPatientProfilePhotoReqBody,
  patientId: string,
) => {
  const queryClient = useQueryClient();

  return useMutation(
    ['upload-patient-profile-photo'],
    () => uploadPatientProfile(reqBody, patientId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['upload-patient-profile-photo']);
        queryClient.fetchQuery(['get-patient-profiles']);
        queryClient.fetchQuery(['get-account']);
      },
    },
  );
};

const getAdminPatientProfiles = (params: AdminPatientProfileListParams) => {
  const queryParams = convertJsonToParams(params as any);

  const url = ApiEndpointsEnum.GET_ADMIN_PATIENT_PROFLES + '?' + queryParams;
  return API.get<PaginatedData<AdminPatientProfile>>(url);
};

const useGetAdminPatientProfiles = (params: AdminPatientProfileListParams) => {
  return useQuery(['admin-patient-profiles', {status: params.status}], () =>
    getAdminPatientProfiles(params),
  );
};

const getAdminPatientProfile = (data: GetAdminPatientProfileReqBody) => {
  const url = ApiEndpointsEnum.GET_ADMIN_PATIENT_PROFLE.replace(
    '{userId}',
    data.userId,
  );
  return API.get<PatientProfileReqResponse[]>(url);
};

const useGetAdminPatientProfile = (data: GetAdminPatientProfileReqBody) => {
  return useQuery(['patient-profile', {id: data.userId}], () =>
    getAdminPatientProfile(data),
  );
};

const deletePatientProfile = async (patientId: string) => {
  const url = ApiEndpointsEnum.DELETE_PATIENT_PROFILE.replace(
    '{patientId}',
    patientId,
  );
  return API.del(url);
};

const useDeletePatientProfile = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ['delete-patient-profile'],
    (patientId: string) => deletePatientProfile(patientId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['delete-patient-profile']);
        queryClient.fetchQuery(['get-patient-profiles']);
      },
    },
  );
};

export {
  useAddPatientProfile,
  useEditPatientProfile,
  useGetPatientProfiles,
  useGetPatientProfile,
  useGetAdminPatientProfile,
  useGetAdminPatientProfiles,
  getPatientProfile,
  useUploadPatientProfile,
  useDeletePatientProfile,
};
