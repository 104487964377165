import React, {memo, useState, useEffect} from 'react';
import {View, TouchableOpacity} from 'react-native';

import {makeStyles} from 'rne-netzon';

import {RNEText} from './../rne/text/RNEText';

interface PaginationItemProps {
  label: string;
  disabled?: boolean;
  active?: boolean;
  onPress?: () => void;
}

const PaginationItem = (props: PaginationItemProps) => {
  const styles = useStyles();

  return (
    <TouchableOpacity
      activeOpacity={0.75}
      disabled={props.disabled}
      onPress={() => props.onPress?.()}>
      <View style={styles.item}>
        <RNEText
          label={props.label}
          variant="button"
          color={props.active ? 'primary' : 'grey'}
        />
      </View>
    </TouchableOpacity>
  );
};

const kBoundaryCount = 5;
const kMaxItemCount = 7;

interface PaginationProps {
  pageNumber: number;
  totalPage: number;
  canGoBack?: boolean;
  canGoNext?: boolean;
  freeze?: boolean;
  hiddenGap?: number;
  goToPage?: (pageNumber: number) => void;
  goBack?: () => void;
  goNext?: () => void;
}

const Pagination = (props: PaginationProps) => {
  const styles = useStyles();

  const [hideStartEllipsis, setHideStartEllipsis] = useState(true);
  const [hideEndEllipsis, setHideEndEllipsis] = useState(true);

  const [siblingStart, setSiblingStart] = useState<number[]>([]);
  const [siblingMid, setSiblingMid] = useState<number[]>([]);
  const [siblingEnd, setSiblingEnd] = useState<number[]>([]);

  const range = (start: number, end: number) => {
    const length = end - start + 1;
    return Array.from({length}, (_, i) => start + i);
  };

  useEffect(() => {
    if (props.totalPage <= kMaxItemCount) {
      setSiblingStart(range(1, props.totalPage));
      setSiblingMid([]);
      setSiblingEnd([]);
      setHideStartEllipsis(true);
      setHideEndEllipsis(true);
    } else {
      if (props.pageNumber < kBoundaryCount) {
        setSiblingStart(range(1, kBoundaryCount));
        setSiblingMid([]);
        setHideStartEllipsis(false);
        setHideEndEllipsis(true);
        setSiblingEnd(range(props.totalPage, props.totalPage));
      } else if (props.pageNumber > props.totalPage - kBoundaryCount + 1) {
        setSiblingStart(range(1, 1));
        setSiblingMid([]);
        setHideStartEllipsis(true);
        setHideEndEllipsis(false);
        setSiblingEnd(
          range(props.totalPage - kBoundaryCount + 1, props.totalPage),
        );
      } else {
        setSiblingStart(range(1, 1));

        const diff = props.totalPage - props.pageNumber + 1;

        if (diff < kBoundaryCount) {
          setSiblingMid([]);
          setHideStartEllipsis(false);
          setHideEndEllipsis(true);
          setSiblingEnd(range(props.pageNumber, props.totalPage));
        } else {
          setSiblingMid(range(props.pageNumber - 1, props.pageNumber + 1));
          setHideStartEllipsis(false);
          setHideEndEllipsis(false);
          setSiblingEnd(range(props.totalPage, props.totalPage));
        }
      }
    }
  }, [props.pageNumber, props.totalPage]);

  return (
    <View style={styles.root}>
      <PaginationItem
        label={'<'}
        disabled={!props.canGoBack}
        onPress={() => !props.freeze && props.goBack?.()}
      />

      {siblingStart.map((item, index) => (
        <PaginationItem
          key={index}
          label={item.toString()}
          active={item === props.pageNumber}
          onPress={() => !props.freeze && props.goToPage?.(item)}
        />
      ))}

      {!hideStartEllipsis && <PaginationItem label={'...'} />}

      {siblingMid.map((item, index) => (
        <PaginationItem
          key={index}
          label={item.toString()}
          active={item === props.pageNumber}
          onPress={() => !props.freeze && props.goToPage?.(item)}
        />
      ))}

      {!hideEndEllipsis && <PaginationItem label={'...'} />}

      {siblingEnd.map((item, index) => (
        <PaginationItem
          key={index}
          label={item.toString()}
          active={item === props.pageNumber}
          onPress={() => !props.freeze && props.goToPage?.(item)}
        />
      ))}

      <PaginationItem
        label={'>'}
        disabled={!props.canGoNext}
        onPress={() => !props.freeze && props.goNext?.()}
      />
    </View>
  );
};
const useStyles = makeStyles({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  item: {
    width: 32,
    height: 24,
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default memo(Pagination);
