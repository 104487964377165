import React from 'react';
import {View, Image} from 'react-native';

import {makeStyles} from 'rne-netzon';
import {colorPalette} from '../../../core/config/color.config';

import {RNEText, RNEButton, SVGIcon} from '../../components';

import {PractitionerProfile} from '../../server/types/practitioner-profile.types';

import {formatAmount} from '../../utils/currency.utils';
import ContentScrollView from '../scroll/ContentScrollView';

interface DoctorConsultationProfileProps {
  practitioner: PractitionerProfile;
  onClose?: () => void;
}

const DoctorConsultationProfile = ({
  practitioner,
  onClose,
}: DoctorConsultationProfileProps) => {
  const styles = useStyles();

  return (
    <View style={styles.root}>
      <View style={styles.headerContainer}>
        <View style={styles.mainDetailContainer}>
          <View style={styles.mainDetailItem}>
            <RNEText
              label={`Dr. ${practitioner?.humanName.given[0]} ${practitioner?.humanName.family}`}
              variant="title"
              weight="600"
              color="blackLight"
              numberOfLines={2}
              ellipsizeMode="tail"
            />
          </View>

          <View style={styles.mainDetailItem}>
            <RNEText
              label={
                practitioner && practitioner.practitionerRole[0]
                  ? practitioner?.organization[0].address?.city
                    ? `${practitioner?.practitionerRole[0].specialty.text} at ${practitioner?.organization[0].address?.city}`
                    : `${practitioner?.practitionerRole[0].specialty.text}`
                  : '--'
              }
              color="blackLight"
            />
          </View>

          {/* <View style={styles.mainDetailItem}>
            <View style={styles.mainDetailItemKey}>
              <RNEText label={'License Number:'} />
            </View>
            <View style={styles.mainDetailItemValue}>
              <RNEText
                label={
                  practitioner
                    ? `${practitioner.qualification.identifier}`
                    : '--'
                }
              />
            </View>
          </View> */}

          <View style={styles.mainDetailItem}>
            <View style={styles.mainDetailItemKey}>
              <RNEText label={'Consultation Fee:'} />
            </View>
            <View style={styles.mainDetailItemValue}>
              <RNEText
                label={
                  practitioner && practitioner.fee?.amount
                    ? `${practitioner.fee.amount.currency} ${formatAmount(
                        practitioner?.fee.amount.value,
                      )}`
                    : '--'
                }
                weight="bold"
              />
            </View>
          </View>
        </View>
        <View style={styles.profilePictureContainer}>
          {practitioner && practitioner.photo ? (
            <Image
              source={{
                uri: practitioner.photo.url,
                width: 96,
                height: 96,
              }}
              style={imageStyle}
              accessibilityLabel={practitioner.photo.title}
            />
          ) : (
            <SVGIcon name="user" size={48} />
          )}
        </View>
      </View>

      <View style={styles.otherContentContainer}>
        <View style={styles.otherContentContainerHeader}>
          <RNEText
            label={'What cases I usually handle:'}
            color="accent"
            weight="600"
          />
        </View>

        <ContentScrollView isWeb>
          <RNEText label={practitioner?.handledCases || '--'} />
        </ContentScrollView>
      </View>

      <View style={styles.actionContainer}>
        <RNEButton
          title="Back"
          color="secondary"
          variant="outlined"
          onPress={onClose}
          style={styles.buttonStyle}
        />
      </View>
    </View>
  );
};

const imageStyle = {
  width: 96,
  height: 96,
  borderRadius: 96,
};

const useStyles = makeStyles(theme => ({
  root: {
    width: 720,
    paddingHorizontal: 48,
    paddingVertical: 32,
  },
  headerContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottomWidth: 1.5,
    borderBottomColor: 'rgba(229, 229, 229, 0.8)',
    paddingBottom: 32,
  },
  mainDetailContainer: {
    flex: 1,
  },
  mainDetailItem: {
    marginBottom: 4,
    flexDirection: 'row',
  },
  mainDetailItemKey: {
    marginRight: 4,
  },
  mainDetailItemValue: {},
  profilePictureContainer: {
    width: 96,
    height: 96,
    borderRadius: 96,
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 2,
    borderColor: colorPalette.light,
    marginLeft: 24,
  },
  otherContentContainer: {
    marginTop: 48,
    maxHeight: 200,
  },
  otherContentContainerHeader: {
    marginBottom: 4,
  },
  actionContainer: {
    marginTop: 48,
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  buttonStyle: {
    width: 180,
  },
}));

export default DoctorConsultationProfile;
