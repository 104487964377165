import React, {useState, useEffect, useCallback} from 'react';
import {
  View,
  Platform,
  ScrollView,
  Image,
  ImageBackground,
  Dimensions,
} from 'react-native';
import {makeStyles, Overlay} from 'rne-netzon';
import {colorPalette} from '../../../core/config/color.config';

import {useNavigation} from '@react-navigation/native';

import {RNEText, SVGIcon, RNEButton, IconName} from '../../components';

import {formatDateString} from '../../utils/date.utils';
import {NavigationKey} from '../../navigations/config';
import ResendVerificationSuccessModal from '../../views/modal/ResendVerificationSuccessModal';
import DeniedResendVerificationModal from '../../views/modal/DeniedResendVerificationModal';
import {useToast} from 'react-native-fast-toast';
import {
  useGetProfessionalProfile,
  useRequestVerification,
} from '../../server/react-query/usePractitioner';
import {PractitionerStatus} from '../../server/types/practitioner-status.types';
import {formatAmount} from '../../utils/currency.utils';

const DoctorViewProfessionalProfileScreen = () => {
  const navigation = useNavigation();
  const webStyles = useWebStyles();
  const mobileStyles = useMobileStyles();

  const {status, data, error, isFetching} = useGetProfessionalProfile();

  const [showDeniedRequestModal, setShowDeniedRequestModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const [statusIcon, setStatusIcon] = useState<IconName>('profile-complete');
  const [iconSize, setIconSize] = useState<number>(
    Platform.OS === 'web' ? 36 : 24,
  );

  const [overrideCardsOverlay, setOverrideCardsOverlay] = useState({
    bottom: -60,
  });
  const [overrideFormContainer, setOverrideFormContainer] = useState({
    marginVertical: 20,
  });

  const goToEditProfessionalProfile = () => {
    setShowDeniedRequestModal(false);
    navigation.navigate(NavigationKey.DOCTOR_EDIT_PROFESSIONAL_PROFILE);
  };

  const goToDashboard = () => {
    setShowSuccessModal(false);
    navigation.navigate(NavigationKey.DOCTOR_SCREEN_LANDING);
  };

  const loadingPropsStyle = {
    color: colorPalette.black,
  };

  const toast = useToast();
  const verificationMutation = useRequestVerification();
  const requestVerification = async () => {
    try {
      if (data?.qualification == null) {
        setShowDeniedRequestModal(true);
      } else {
        await verificationMutation.mutateAsync();
        setShowSuccessModal(true);
      }
    } catch (err) {
      console.log('err', err);
      if (err?.data?.error) {
        toast?.show(err?.data?.error, {type: 'danger'});
      }
    }
  };

  const onTextLayout = useCallback(e => {
    if (e.nativeEvent.lines.length >= 2) {
      setOverrideCardsOverlay({
        bottom: -100,
      });
      setOverrideFormContainer({
        marginVertical: 60,
      });
    }
  }, []);

  useEffect(() => {
    switch (data?.status) {
      case PractitionerStatus.VERIFIED:
        setStatusIcon('profile-complete');
        setIconSize(Platform.OS === 'web' ? 36 : 24);
        break;

      case PractitionerStatus.UNVERIFIED:
        setStatusIcon('close-gray-70');
        setIconSize(24);
        break;

      case PractitionerStatus.DECLINED:
        setStatusIcon('request-denied');
        setIconSize(Platform.OS === 'web' ? 16 : 12);
        break;

      case PractitionerStatus.PENDING:
        setStatusIcon('request-cancelled');
        setIconSize(Platform.OS === 'web' ? 16 : 12);
        break;

      default:
        break;
    }
  }, [data?.status]);

  return (
    <>
      {Platform.OS === 'web' ? (
        <ScrollView>
          <View style={webStyles.mainContainer}>
            <View style={webStyles.topBackground}>
              <View style={webStyles.titleContainer}>
                <RNEText label="Dashboard > " variant="body" color="white" />
                <RNEText
                  label="My Professional Profile"
                  variant="body"
                  color="white"
                  weight="bold"
                />
              </View>
              <View style={webStyles.headerContainer}>
                <View style={webStyles.userIcon}>
                  {data?.photo ? (
                    <Image
                      style={webStyles.imageWrapper}
                      source={{
                        uri: data.photo.url,
                      }}
                    />
                  ) : (
                    <SVGIcon name="user-white" size={68} />
                  )}
                  <View style={webStyles.statusIndicator}>
                    <SVGIcon name={statusIcon} size={iconSize} />
                  </View>
                </View>
                <View style={webStyles.rightHeaderGroup}>
                  <RNEText
                    label="My Professional Profile"
                    variant="header"
                    color="white"
                    weight="bold"
                  />

                  <View style={webStyles.dateActionsGroup}>
                    <View style={webStyles.dateContainer}>
                      <RNEText
                        label={`Date Updated: ${formatDateString(
                          data?.modifiedOn,
                        )}`}
                        variant="body"
                        color="white"
                      />
                    </View>

                    <View style={webStyles.actionWrapper}>
                      <View>
                        <RNEButton
                          style={webStyles.buttonStyle}
                          title="Edit Profile"
                          color="white"
                          variant="outlined"
                          onPress={goToEditProfessionalProfile}
                        />
                      </View>

                      {data?.status !== PractitionerStatus.VERIFIED && (
                        <View style={webStyles.buttonContainer}>
                          <RNEButton
                            title="Request Verification"
                            color="secondary"
                            style={webStyles.buttonStyle}
                            onPress={requestVerification}
                            loading={verificationMutation.isLoading}
                          />
                        </View>
                      )}
                    </View>
                  </View>
                </View>
              </View>
            </View>

            <View style={webStyles.formContainer}>
              <View style={webStyles.formTitleContainer}>
                <RNEText
                  label="Professional Profile"
                  variant="body"
                  color="black"
                  weight="bold"
                />
                <RNEButton
                  height={20}
                  loadingProps={loadingPropsStyle}
                  loading={isFetching}
                />
              </View>

              <View style={webStyles.labelGroupContainer}>
                <View style={webStyles.rowGroupContainer}>
                  <View style={webStyles.infoContainer}>
                    <View style={webStyles.iconContainer}>
                      <SVGIcon name="user" size={28} />
                    </View>
                    <RNEText
                      label={`Dr. ${data?.humanName.given[0]} ${data?.humanName.family}`}
                      variant="body"
                      color="black"
                    />
                  </View>

                  <View style={webStyles.infoContainer}>
                    <View style={webStyles.iconContainer}>
                      <SVGIcon name="map" size={28} />
                    </View>
                    <RNEText
                      label={
                        data?.organization[0] &&
                        data?.organization[0]?.address &&
                        data?.organization[0]?.address.city
                          ? data?.organization[0]?.address?.city
                          : '[City]'
                      }
                      variant="body"
                      color="black"
                    />
                  </View>

                  <View style={webStyles.infoContainer}>
                    <View style={webStyles.iconContainer}>
                      <SVGIcon name="price" size={28} />
                    </View>
                    <RNEText
                      label={
                        data?.fee
                          ? `${data.fee.amount.currency} ${formatAmount(
                              data?.fee.amount.value,
                            )}`
                          : '[No Data]'
                      }
                      variant="title"
                      color="secondary"
                      weight="bold"
                    />
                  </View>
                </View>

                <View style={webStyles.rowGroupContainer}>
                  <View style={webStyles.infoContainer}>
                    <View style={webStyles.iconContainer}>
                      <SVGIcon name="general-practitioner" size={28} />
                    </View>
                    <RNEText
                      label={
                        data?.practitionerRole[0]
                          ? data?.practitionerRole[0].specialty.text
                          : '[Specialization]'
                      }
                      variant="body"
                      color="black"
                    />
                  </View>
                  <View style={webStyles.infoContainer}>
                    <View style={webStyles.iconContainer}>
                      <SVGIcon name="allied-health-professionals" size={28} />
                    </View>
                    <RNEText
                      label={
                        data?.qualification
                          ? data?.qualification.identifier
                          : '[License No.]'
                      }
                      variant="body"
                      color="black"
                    />
                  </View>
                  <View style={webStyles.infoContainer}>
                    <View style={webStyles.iconContainer}>
                      <SVGIcon name="calendar" size={28} />
                    </View>
                    <RNEText
                      label={
                        data?.birthdate
                          ? formatDateString(data?.birthdate).toString()
                          : '[Birthdate]'
                      }
                      variant="body"
                      color="black"
                    />
                  </View>
                </View>
              </View>
              <View style={webStyles.formTitleContainer}>
                <RNEText
                  label="Cases I Usually Handle:"
                  variant="body"
                  color="black"
                  weight="bold"
                />
              </View>
              <View style={webStyles.labelGroupContainer}>
                <View style={webStyles.rowGroupContainer}>
                  <View style={webStyles.infoContainer}>
                    <RNEText
                      label={
                        data?.handledCases ? data?.handledCases : '[No Data]'
                      }
                      variant="body"
                      color="black"
                    />
                  </View>
                </View>
              </View>
              <View style={webStyles.formTitleContainer}>
                <RNEText
                  label="Bank Details"
                  variant="body"
                  color="black"
                  weight="bold"
                />
              </View>
              <View style={webStyles.labelGroupContainer}>
                <View style={webStyles.rowGroupContainer}>
                  <View style={webStyles.infoContainer}>
                    <View style={webStyles.iconContainer}>
                      <SVGIcon name="home" size={28} />
                    </View>
                    <RNEText
                      label={data?.bankName ? data?.bankName : '[Bank Name]'}
                      variant="body"
                      color="black"
                    />
                  </View>

                  <View style={webStyles.infoContainer}>
                    <View style={webStyles.iconContainer}>
                      <SVGIcon name="pay" size={28} />
                    </View>
                    <RNEText
                      label={
                        data?.bankAccount
                          ? data?.bankAccount
                          : '[Account Number]'
                      }
                      variant="body"
                      color="black"
                    />
                  </View>

                  <View style={webStyles.infoContainer} />
                </View>
              </View>
            </View>
          </View>
        </ScrollView>
      ) : (
        <ScrollView>
          <View style={mobileStyles.content}>
            <View style={mobileStyles.header}>
              <View style={mobileStyles.photoContainer}>
                {data?.photo?.url ? (
                  <ImageBackground
                    source={{
                      uri: data?.photo.url,
                    }}
                    style={mobileStyles.photoStyle}
                    imageStyle={mobileStyles.photoContainerStyle}
                  />
                ) : (
                  <View
                    style={[
                      mobileStyles.photoPlaceholder,
                      mobileStyles.photoPlaceholderMobile,
                    ]}>
                    <SVGIcon name="user-white" size={56} />
                  </View>
                )}

                <View style={mobileStyles.statusIndicator}>
                  <SVGIcon name={statusIcon} size={iconSize} />
                </View>
              </View>

              <View>
                <View style={mobileStyles.nameContainer}>
                  <RNEText
                    label={`Dr. ${data?.humanName.given[0]} ${data?.humanName.family}`}
                    variant="header"
                    color="white"
                    weight="bold"
                    align="center"
                    numberOfLines={2}
                    ellipsizeMode="tail"
                    onTextLayout={onTextLayout}
                  />
                </View>
                <View style={mobileStyles.dateContainer}>
                  <RNEText
                    label={
                      data?.practitionerRole[0]
                        ? data?.practitionerRole[0].specialty.text
                        : '[Specialization]'
                    }
                    variant="body"
                    color="white"
                    align="center"
                  />
                </View>
              </View>
              {/* <View style={mobileStyles.ratingContainer}>
                <Rating
                  tintColor={colorPalette.purple}
                  ratingCount={5}
                  imageSize={20}
                />
              </View> */}

              <View style={mobileStyles.actionWrapper}>
                <View style={mobileStyles.buttonContainer}>
                  <RNEButton
                    title="Edit Profile"
                    color="white"
                    variant="outlined"
                    onPress={goToEditProfessionalProfile}
                  />
                </View>

                {data?.status !== PractitionerStatus.VERIFIED && (
                  <View
                    style={[
                      mobileStyles.buttonContainer,
                      mobileStyles.rightContainer,
                    ]}>
                    <RNEButton
                      title="Request Verification"
                      color="secondary"
                      onPress={requestVerification}
                      loading={verificationMutation.isLoading}
                    />
                  </View>
                )}
              </View>

              <View style={[mobileStyles.cardsOverlay, overrideCardsOverlay]}>
                <View style={mobileStyles.cardsContainer}>
                  <View style={mobileStyles.cardContainer}>
                    <View style={mobileStyles.cardIcon}>
                      <SVGIcon name="map" size={28} />
                    </View>
                    <RNEText
                      label={
                        data?.organization[0] &&
                        data?.organization[0]?.address &&
                        data?.organization[0]?.address.city
                          ? data?.organization[0]?.address?.city
                          : '[City]'
                      }
                      variant="button"
                      color="black"
                      align="center"
                      weight="bold"
                    />
                  </View>
                  <View
                    style={[
                      mobileStyles.cardContainer,
                      mobileStyles.rightContainer,
                    ]}>
                    <View style={mobileStyles.cardIcon}>
                      <SVGIcon name="price" size={28} />
                    </View>
                    <RNEText
                      label={
                        data?.fee
                          ? `${data.fee.amount.currency} ${formatAmount(
                              data?.fee.amount.value,
                            )}`
                          : '[No Data]'
                      }
                      variant="button"
                      color="black"
                      align="center"
                      weight="bold"
                    />
                  </View>
                </View>
              </View>
            </View>

            <View style={[mobileStyles.formContainer, overrideFormContainer]}>
              <View style={mobileStyles.formTitleContainer}>
                <View style={mobileStyles.spinnerContainer}>
                  <RNEButton
                    height={32}
                    loadingProps={loadingPropsStyle}
                    loading={isFetching}
                  />
                </View>
              </View>

              <View style={mobileStyles.sectionContainer}>
                <View style={mobileStyles.infoContainer}>
                  <View style={mobileStyles.iconContainer}>
                    <SVGIcon name="general-practitioner" size={28} />
                  </View>
                  <RNEText
                    label={
                      data?.practitionerRole[0]
                        ? data?.practitionerRole[0].specialty.text
                        : '[Specialization]'
                    }
                    variant="body"
                    color="black"
                  />
                </View>

                <View style={mobileStyles.infoContainer}>
                  <View style={mobileStyles.iconContainer}>
                    <SVGIcon name="allied-health-professionals" size={28} />
                  </View>
                  <RNEText
                    label={
                      data?.qualification
                        ? data?.qualification.identifier
                        : '[License No.]'
                    }
                    variant="body"
                    color="black"
                  />
                </View>

                <View style={mobileStyles.infoContainer}>
                  <View style={mobileStyles.iconContainer}>
                    <SVGIcon name="calendar" size={28} />
                  </View>
                  <RNEText
                    label={
                      data?.birthdate
                        ? formatDateString(data?.birthdate).toString()
                        : '[Birthdate]'
                    }
                    variant="body"
                    color="black"
                  />
                </View>
              </View>
              <View style={mobileStyles.formTitleContainer}>
                <RNEText
                  label="Cases I Usually Handle:"
                  variant="body"
                  color="accent"
                  weight="bold"
                />
              </View>

              <View style={mobileStyles.sectionContainer}>
                <View>
                  <RNEText
                    label={
                      data?.handledCases ? data?.handledCases : '[No Data]'
                    }
                    variant="body"
                    color="black"
                  />
                </View>
              </View>

              <View style={mobileStyles.formTitleContainer}>
                <RNEText
                  label="Bank Details"
                  variant="title"
                  color="accent"
                  weight="bold"
                />
              </View>

              <View style={mobileStyles.sectionContainer}>
                <View style={mobileStyles.infoContainer}>
                  <View style={mobileStyles.iconContainer}>
                    <SVGIcon name="home" size={28} />
                  </View>
                  <RNEText
                    label={data?.bankName ? data?.bankName : '[Bank Name]'}
                    variant="body"
                    color="black"
                  />
                </View>

                <View style={mobileStyles.infoContainer}>
                  <View style={mobileStyles.iconContainer}>
                    <SVGIcon name="pay" size={28} />
                  </View>
                  <RNEText
                    label={
                      data?.bankAccount ? data?.bankAccount : '[Account Number]'
                    }
                    variant="body"
                    color="black"
                  />
                </View>
              </View>
            </View>
          </View>
        </ScrollView>
      )}
      <Overlay
        isVisible={showDeniedRequestModal}
        overlayStyle={
          Platform.OS === 'web'
            ? webStyles.overlayContainerWeb
            : mobileStyles.overlayContainerMobile
        }>
        <DeniedResendVerificationModal
          onCancel={() => setShowDeniedRequestModal(false)}
          onConfirm={goToEditProfessionalProfile}
        />
      </Overlay>
      <Overlay
        isVisible={showSuccessModal}
        overlayStyle={
          Platform.OS === 'web'
            ? webStyles.overlayContainerWeb
            : mobileStyles.overlayContainerMobile
        }>
        <ResendVerificationSuccessModal onConfirm={goToDashboard} />
      </Overlay>
    </>
  );
};

export default DoctorViewProfessionalProfileScreen;

const useWebStyles = makeStyles({
  mainContainer: {
    flex: 1,
    height: '100%',
    width: '100%',
    backgroundColor: '#F6F7FB',
  },
  topBackground: {
    backgroundColor: colorPalette.purple,
    borderBottomRightRadius: 50,
    height: 420,
  },
  titleContainer: {
    flexDirection: 'row',
    marginHorizontal: 100,
    paddingVertical: 20,
    borderBottomWidth: 1,
    borderBottomColor: colorPalette.white,
  },
  headerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 100,
    paddingRight: 100,
    paddingVertical: 60,
  },
  userIcon: {
    position: 'relative',
    backgroundColor: 'transparent',
    marginRight: 48,
    borderRadius: 100,
    height: 140,
    width: 140,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: colorPalette.white,
  },
  imageWrapper: {
    width: '100%',
    height: '100%',
    borderRadius: 100,
  },
  statusIndicator: {
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    bottom: 4,
    right: 4,
    backgroundColor: colorPalette.white,
    width: 24,
    height: 24,
    borderRadius: 24,
  },
  rightHeaderGroup: {
    flex: 1,
  },
  dateActionsGroup: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  dateContainer: {
    flex: 1,
    height: 48,
    minWidth: 250,
    justifyContent: 'center',
  },
  formContainer: {
    backgroundColor: colorPalette.white,
    borderRadius: 20,
    marginHorizontal: 100,
    top: -100,
    paddingTop: 64,
    paddingBottom: 40,
    paddingHorizontal: 60,
  },
  formTitleContainer: {
    paddingLeft: 24,
    flexDirection: 'row',
  },
  labelGroupContainer: {
    width: '100%',
    marginVertical: 40,
  },
  rowGroupContainer: {
    flexDirection: 'row',
    marginBottom: 40,
  },
  infoContainer: {
    paddingLeft: 24,
    marginBottom: 4,
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  iconContainer: {
    width: 60,
  },
  actionWrapper: {
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  buttonContainer: {
    marginLeft: 24,
  },
  buttonStyle: {
    width: 180,
  },
  overlayContainerWeb: {
    borderRadius: 16,
    paddingHorizontal: 48,
    paddingVertical: 24,
  },
});

const useMobileStyles = makeStyles({
  content: {
    flex: 1,
    width: '100%',
  },
  header: {
    backgroundColor: colorPalette.purple,
    height: 390,
    borderBottomStartRadius: 230,
    borderBottomEndRadius: 230,
    marginHorizontal: -30,
    position: 'relative',
    alignItems: 'center',
  },
  photoContainer: {
    position: 'relative',
    width: 112,
    height: 112,
    borderRadius: 56,
    marginTop: 32,
    marginBottom: 16,
  },
  photoContainerStyle: {
    borderRadius: 56,
    shadowColor: '#050B5A',
    shadowOffset: {
      width: 5,
      height: 20,
    },
    shadowRadius: 20,
    // elevation: 10,
    zIndex: 10,
  },
  photoStyle: {
    flex: 1,
    justifyContent: 'center',
    resizeMode: 'cover',
  },
  photoPlaceholder: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  photoPlaceholderMobile: {
    borderWidth: 1,
    borderColor: colorPalette.white,
    borderRadius: 56,
  },
  statusIndicator: {
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    bottom: 4,
    right: 4,
    backgroundColor: colorPalette.white,
    width: 24,
    height: 24,
    borderRadius: 24,
  },
  userIcon: {
    borderRadius: 100,
    height: 109,
    width: 109,
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 32,
    marginBottom: 16,
  },
  imageWrapper: {
    width: '100%',
    height: '100%',
    borderRadius: 100,
  },
  dateContainer: {
    paddingTop: 4,
  },
  ratingContainer: {
    marginTop: 20,
  },
  cardsOverlay: {
    position: 'absolute',
    left: 0,
    right: 0,
    paddingHorizontal: 64,
  },
  cardsContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  cardContainer: {
    flex: 1,
    height: '100%',
    shadowColor: '#050B5A',
    shadowOffset: {
      width: 0,
      height: 10,
    },
    shadowOpacity: 0.1,
    shadowRadius: 5,
    borderRadius: 10,
    elevation: 10,
    zIndex: 10,
    backgroundColor: colorPalette.white,
    alignItems: 'center',
    paddingHorizontal: 24,
    paddingVertical: 24,
  },
  rightContainer: {
    marginLeft: 16,
  },
  cardContent: {
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cardIcon: {
    marginBottom: 14,
  },
  formContainer: {
    paddingHorizontal: 52,
  },
  infoContainer: {
    marginBottom: 4,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    flex: 1,
    marginVertical: 20,
    borderBottomWidth: 1,
    borderColor: colorPalette.light,
    paddingBottom: 10,
  },
  iconContainer: {
    width: 60,
  },
  formTitleContainer: {
    marginBottom: 20,
    flexDirection: 'row',
  },
  sectionContainer: {
    marginBottom: 60,
  },
  actionWrapper: {
    marginTop: 24,
    flexDirection: 'row',
  },
  buttonContainer: {
    flex: 1,
    maxWidth: 160,
    alignSelf: 'center',
  },
  spinnerContainer: {
    width: '100%',
    justifyContent: 'flex-end',
  },
  overlayContainerMobile: {
    borderRadius: 20,
    paddingHorizontal: 32,
    paddingVertical: 24,
  },
  nameContainer: {
    width: Dimensions.get('screen').width - 64,
    paddingHorizontal: 32,
  },
});
