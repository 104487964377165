import React, {useState, useEffect, useCallback} from 'react';
import {Dimensions, View, ImageBackground} from 'react-native';
import {ScrollView, TouchableOpacity} from 'react-native-gesture-handler';

import StarRating from 'react-native-simple-star-rating-widget';

import {makeStyles} from 'rne-netzon';
import {colorPalette} from '../../../core/config/color.config';

import {useRoute, useNavigation} from '@react-navigation/native';

import {RNEButton, RNEText, SVGIcon} from '../../components';

import {useGetPractitionerProfile} from './../../server/react-query/usePractitioner';
import {NavigationKey} from '../../navigations/config';
import {formatAmount} from '../../utils/currency.utils';

const PractitionerRequestProfileViewScreen = () => {
  const styles = useStyles();
  const route = useRoute();
  const navigation = useNavigation();

  const [practitionerId, setPractitionerId] = useState('');

  const {data, isLoading, refetch} = useGetPractitionerProfile({
    practitionerId: practitionerId,
  });

  const [overrideMinHeight, setOverrideMinHeight] = useState({
    minHeight: Dimensions.get('screen').height,
  });

  const goBack = () => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    }
  };

  const onTextLayout = useCallback(e => {
    if (e.nativeEvent.lines.length) {
      setOverrideMinHeight({
        minHeight:
          Dimensions.get('screen').height + e.nativeEvent.lines.length * 18,
      });
    }
  }, []);

  useEffect(() => {
    const params = route.params as any;

    if (params && params.practitionerId) {
      setPractitionerId(params.practitionerId);
    }
  }, [route.params]);

  useEffect(() => {
    if (practitionerId) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [practitionerId]);

  return (
    <ScrollView>
      <View style={[styles.root, overrideMinHeight]}>
        <View style={styles.headerContainer}>
          <View style={styles.photoContainer}>
            {data?.photo?.url ? (
              <ImageBackground
                source={{
                  uri: data?.photo.url,
                }}
                style={styles.photoStyle}
                imageStyle={styles.photoContainerStyle}
              />
            ) : (
              <View
                style={[
                  styles.photoPlaceholder,
                  styles.photoPlaceholderMobile,
                ]}>
                <SVGIcon name="user-white" size={56} />
              </View>
            )}
          </View>

          <View style={styles.name}>
            <RNEText
              label={
                data
                  ? `Dr. ${data?.humanName.given[0]} ${data?.humanName.family}`
                  : '--'
              }
              color="white"
              weight="600"
              variant="title"
              align="center"
              numberOfLines={2}
              ellipsizeMode="tail"
            />
          </View>
          <View style={styles.profession}>
            <RNEText
              label={data ? data.practitionerRole[0].specialty.text : '--'}
              color="white"
              weight="300"
              align="center"
            />
          </View>

          <View style={styles.rating}>
            {/* <StarRating
              rating={2}
              onChange={() => {}}
              minRating={0}
              enableHalfStar={false}
              starSize={20}
              color={colorPalette.secondary}
              style={styles.ratingStyle}
              starSpace={2}
              viewOnly={true}
            /> */}
          </View>
        </View>

        <View style={styles.bodyContainer}>
          <View style={styles.cardsContainer}>
            <View style={styles.cardItem}>
              <SVGIcon name="map" size={28} />

              <View style={styles.cardItemText}>
                <RNEText
                  label={
                    data?.organization[0].address?.city
                      ? data?.organization[0].address?.city
                      : '--'
                  }
                  align="center"
                  variant="button"
                />
              </View>
            </View>

            <View style={[styles.cardItem, styles.cardItemSibling]}>
              <SVGIcon name="price" size={28} />

              <View style={styles.cardItemText}>
                <RNEText
                  label={
                    data
                      ? `${data?.fee.amount.currency} ${formatAmount(
                          data?.fee.amount.value,
                        )}`
                      : '--'
                  }
                  align="center"
                  variant="button"
                />
              </View>
            </View>
          </View>

          <View style={styles.caseContainer}>
            <RNEText
              label="What cases I usually handle..."
              color="accent"
              weight="bold"
            />

            <View style={styles.caseContainerValue}>
              <RNEText
                label={data && data.handledCases ? data.handledCases : '--'}
                onTextLayout={onTextLayout}
              />
            </View>
          </View>

          <View style={styles.actionContainer}>
            <RNEButton
              title="Request Appointment"
              color="secondary"
              onPress={() =>
                navigation.navigate(
                  NavigationKey.PATIENT_CONSULTATION_REQUEST,
                  {
                    practitionerId: practitionerId,
                  },
                )
              }
            />
          </View>
        </View>
      </View>
    </ScrollView>
  );
};

const useStyles = makeStyles({
  root: {
    flex: 1,
    paddingTop: 24,
    paddingBottom: 32,
    paddingHorizontal: 32,
    backgroundColor: colorPalette.white,
  },
  headerContainer: {
    position: 'absolute',
    width: Dimensions.get('screen').width + 120,
    height: Dimensions.get('screen').width + 120,
    backgroundColor: colorPalette.primary,
    borderBottomStartRadius: Dimensions.get('screen').width + 120,
    borderBottomEndRadius: Dimensions.get('screen').width + 120,
    top: -100,
    left: -60,
    shadowColor: '#050B5A',
    shadowOffset: {
      width: 0,
      height: 10,
    },
    shadowOpacity: 0.1,
    shadowRadius: 5,
    elevation: 10,
    zIndex: 10,
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 32,
    paddingLeft: 96,
    paddingRight: 96,
  },
  photoContainer: {
    width: 112,
    height: 112,
    borderRadius: 56,
  },
  photoContainerStyle: {
    borderRadius: 56,
    shadowColor: '#050B5A',
    shadowOffset: {
      width: 5,
      height: 20,
    },
    shadowRadius: 20,
    elevation: 10,
    zIndex: 10,
  },
  photoStyle: {
    flex: 1,
    justifyContent: 'center',
    resizeMode: 'cover',
  },
  photoPlaceholder: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  photoPlaceholderMobile: {
    borderWidth: 1,
    borderColor: colorPalette.white,
    borderRadius: 56,
  },
  name: {
    marginTop: 16,
  },
  profession: {
    marginTop: 4,
  },
  rating: {
    marginTop: 24,
  },
  ratingStyle: {
    marginHorizontal: 0,
  },
  bodyContainer: {
    position: 'absolute',
    top: Dimensions.get('screen').width - 30,
    left: 0,
    right: 0,
    elevation: 12,
    zIndex: 12,
    paddingHorizontal: 32,
    shadowColor: 'transparent',
  },
  cardsContainer: {
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  cardItem: {
    flex: 1,
    height: '100%',
    shadowColor: '#050B5A',
    shadowOffset: {
      width: 0,
      height: 10,
    },
    shadowOpacity: 0.1,
    shadowRadius: 5,
    borderRadius: 10,
    elevation: 10,
    zIndex: 10,
    backgroundColor: colorPalette.white,
    alignItems: 'center',
    paddingHorizontal: 24,
    paddingVertical: 24,
  },
  cardItemSibling: {
    marginLeft: 24,
  },
  cardItemText: {
    marginTop: 12,
  },
  caseContainer: {
    marginTop: 48,
  },
  caseContainerValue: {
    marginTop: 16,
  },
  actionContainer: {
    marginTop: 48,
    alignItems: 'center',
  },
});

export default PractitionerRequestProfileViewScreen;
