import React, {memo} from 'react';
import {StyleProp, View, ViewStyle} from 'react-native';

import {makeStyles} from 'rne-netzon';
import {colorPalette} from '../../../core/config/color.config';

import {IconName, RNEText, RNEButton, SVGIcon} from '../../components';

interface PatientMedicalItemViewProps {
  label: string;
  value: string;
  isLoading?: boolean;
  iconName?: IconName;
  style?: StyleProp<ViewStyle>;
}

const PatientMedicalItemView = (props: PatientMedicalItemViewProps) => {
  const styles = useStyles();

  return (
    <View style={[styles.root, props.style ?? {}]}>
      {props.iconName && (
        <View style={styles.iconContainer}>
          <SVGIcon name={props.iconName} size={32} />
        </View>
      )}

      <View style={styles.textContainer}>
        <View style={styles.labelContainer}>
          <RNEText label={props.label} color="blackLight" weight="600" />
          {props.isLoading && (
            <RNEButton
              height={24}
              loading={props.isLoading}
              type="clear"
              loadingProps={loadingStyle}
            />
          )}
        </View>

        <View style={styles.valueContainer}>
          <RNEText label={props.value} color="blackLight" />
        </View>
      </View>
    </View>
  );
};

const loadingStyle = {
  color: colorPalette.primary,
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  iconContainer: {
    width: 50,
  },
  textContainer: {
    flex: 1,
    marginTop: 2,
  },
  labelContainer: {
    height: 24,
    flexDirection: 'row',
    alignItems: 'center',
  },
  valueContainer: {
    marginTop: 4,
  },
}));

export default memo(PatientMedicalItemView);
