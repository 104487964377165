import {HumanName} from './identity.types';
import {Photo} from './photo.types';
import {PatientTableSortField} from './table.types';

/**
 * Add Patient Profile
 */
export interface AddPatientProfileReqBody {
  firstName: string;
  middleName: string;
  lastName: string;
  relationship: string;
  location?: string;
  contactNumber?: string;
  birthdate?: string;
  telecom?: string[];
  allergyIntolerance?: string[];
  familyMemberHistory?: string[];
  procedure?: string[];
  medicationStatement?: string[];
  condition?: string[];
  sex?: string;
}

export interface AddPatientProfileReqUIBody {
  firstName: string;
  middleName: string;
  lastName: string;
  relationship: string;
  location?: string;
  contactNumber?: string;
  birthdate?: string;
  telecom?: string;
  allergyIntolerance?: string;
  familyMemberHistory?: string;
  procedure?: string;
  medicationStatement?: string;
  condition?: string;
  sex?: string;
}

export interface UploadPatientProfilePhotoReqBody {
  image?: any | null;
}

export interface ContactPoint {
  contactPointId: string;
  value: string;
  modifiedOn: string;
}

export interface PatientProfileReqResponse {
  patientId: string;
  userId: string;
  humanName: HumanName;
  location: string;
  contactNumber: string;
  relationship: string;
  telecom: ContactPoint[];
  birthdate: string;
  photo: Photo;
  consultationServiceRequestCount: number;
  sex?: string;
}

export interface PatientProfileReqBody {
  patientId: string;
}

export interface AdminPatientProfile {
  userId: string;
  email: string;
  humanName: HumanName;
  location: string;
  createdOn: Date;
  profileCount: number;
}

export interface AdminPatientProfileListParams {
  searchKey?: string;
  status?: PatientStatus;
  sortBy?: PatientTableSortField;
  ascending?: boolean;
  pageNumber?: number;
  pageSize?: number;
}

export interface GetAdminPatientProfileReqBody {
  userId: string;
}

export enum PatientStatus {
  VERIFIED = 'verified',
  ARCHIVED = 'archived',
}
