import React from 'react';

import {ConsultationRequestResBody} from '../../server/types/consultation.types';
import {ConsultationRequestAcceptanceScreen} from '../../screens';
import {Platform, View} from 'react-native';
import {makeStyles} from 'rne-netzon';

interface ConsultationAcceptance {
  hasConsultationFee: boolean;
  consultationFee: number;
  note?: string;
}

interface ConsultationRequestAcceptanceModalProps {
  serviceRequest?: ConsultationRequestResBody;
  onConfirm: () => void;
}

function ConsultationRequestAcceptanceModal(
  props: ConsultationRequestAcceptanceModalProps,
) {
  const rneStyles = useStyles();

  return (
    <View style={rneStyles.root}>
      <ConsultationRequestAcceptanceScreen
        serviceRequest={props.serviceRequest}
        onConfirm={props.onConfirm}
      />
    </View>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
  },
}));

export default ConsultationRequestAcceptanceModal;
