import React from 'react';
import {Platform, TextStyle} from 'react-native';
import {createStackNavigator} from '@react-navigation/stack';
import {DrawerContentComponentProps} from '@react-navigation/drawer';
import {DrawerActions} from '@react-navigation/native';

import {colorPalette} from '../../../core/config/color.config';

import {NavigationKey} from '../config/navigation.config';
import {NavigationTitle} from '../config/navigationTitle';

import {
  PatientEditProfileScreen,
  PatientLandingScreen,
  PatientSearchResultScreen,
  PractitionerRequestProfileViewScreen,
  PatientConsultationRequestScreen,
} from '../../screens';
import {
  HeaderProfileRight,
  HeaderBackLeft,
  HeaderProfileLeft,
} from '../../views';
import PatientMedicalHistoryStackMobileNavigator from './PatientMedicalHistoryStackNavigator';

const {Navigator, Screen} = createStackNavigator();

const webOptions = {
  headerStyle: {
    backgroundColor: '#2BA8E81A',
    borderBottomColor: 'transparent',
    height: 72,
  },
  headerTintColor: colorPalette.primary,
  headerTitleStyle: {
    fontWeight: 'bold',
    fontFamily: 'Quicksand-Bold',
    fontSize: 16,
    paddingLeft: 40,
  } as TextStyle,
  headerLeftContainerStyle: {
    marginLeft: 24,
  },
};

const mobileGeneralOptions = {
  title: '',
  headerTintColor: colorPalette.primary,
};

const mobileBackHeaderNoTitleOptions = {
  title: '',
  headerStyle: {
    elevation: 0,
    shadowOpacity: 0,
  },
  headerTintColor: colorPalette.blackLight,
  headerTitleStyle: {
    fontFamily: 'Quicksand-Regular',
    fontSize: 14,
    paddingLeft: 28,
  },
  headerLeft: () => <HeaderBackLeft />,
};

const patientMobileNavigatorOptions = {
  headerStyle: {
    elevation: 0,
    shadowOpacity: 0,
  },
  headerTitleStyle: {
    fontWeight: 'bold',
    fontFamily: 'Quicksand-Bold',
    fontSize: 16,
  } as TextStyle,
};

const mobileHeaderWithImageAndMsgOptions = {
  ...patientMobileNavigatorOptions,
  ...mobileGeneralOptions,
  headerStyle: {
    backgroundColor: '#2BA8E81A',
    borderBottomColor: 'transparent',
    elevation: 0,
    shadowOpacity: 0,
  },
  headerTintColor: colorPalette.blackLight,
  headerTitleStyle: {
    paddingLeft: 28,
  } as TextStyle,
  headerLeft: () => <HeaderProfileLeft inProfileScreen={false} />,
};

const PatientLandingStackWebNavigator = () => {
  return (
    <Navigator>
      <Screen
        name={NavigationKey.PATIENT_SCREEN_LANDING}
        component={PatientLandingScreen}
        options={{
          title: 'Dashboard',
          ...webOptions,
        }}
      />
      <Screen
        name={NavigationKey.PATIENT_PROFILE_EDIT}
        component={PatientEditProfileScreen}
        options={{
          headerShown: false,
          title: NavigationTitle.PATIENT_PROFILE_EDIT,
        }}
      />
      <Screen
        name={NavigationKey.PATIENT_SEARCH_RESULT}
        component={PatientSearchResultScreen}
        options={{
          title: NavigationTitle.PATIENT_SEARCH_RESULT,
          ...webOptions,
          headerTitleStyle: {
            ...{
              ...webOptions.headerTitleStyle,
              paddingLeft: 16,
            },
          },
        }}
      />
      <Screen
        name={NavigationKey.PATIENT_CONSULTATION_REQUEST}
        component={PatientConsultationRequestScreen}
        options={{
          title: NavigationTitle.PATIENT_CONSULTATION_REQUEST,
          ...webOptions,
          headerLeft: () => null,
          headerTitle: '',
        }}
      />
    </Navigator>
  );
};

const PatientLandingStackMobileNavigator = (
  props: DrawerContentComponentProps,
) => {
  return (
    <Navigator>
      <Screen
        name={NavigationKey.PATIENT_SCREEN_LANDING}
        component={PatientLandingScreen}
        options={{
          ...mobileHeaderWithImageAndMsgOptions,
          headerRight: () => (
            <HeaderProfileRight
              showMessageIcon={true}
              onClick={() =>
                props.navigation.dispatch(DrawerActions.toggleDrawer())
              }
            />
          ),
        }}
      />
      <Screen
        name={NavigationKey.PATIENT_SEARCH_RESULT}
        component={PatientSearchResultScreen}
        options={{
          ...patientMobileNavigatorOptions,
          headerTitleStyle: {
            paddingLeft: 40,
          },
          headerStyle: {
            backgroundColor: '#2BA8E81A',
            borderBottomColor: 'transparent',
            elevation: 0,
            shadowOpacity: 0,
          },
          headerLeft: () => (
            <HeaderProfileLeft hasBack={true} inProfileScreen={false} />
          ),
          headerRight: () => (
            <HeaderProfileRight
              showMessageIcon={true}
              onClick={() =>
                props.navigation.dispatch(DrawerActions.toggleDrawer())
              }
            />
          ),
          ...mobileGeneralOptions,
        }}
      />
      <Screen
        name={NavigationKey.DOCTOR_PROFESSIONAL_PROFILE_REQUEST}
        component={PractitionerRequestProfileViewScreen}
        options={{
          ...mobileBackHeaderNoTitleOptions,
          headerStyle: {
            backgroundColor: colorPalette.primary,
            borderBottomColor: 'transparent',
            elevation: 0,
            shadowOpacity: 0,
          },
          headerLeft: () => <HeaderBackLeft label=" " iconName="left-white" />,
          ...mobileGeneralOptions,
        }}
      />
      <Screen
        name={NavigationKey.PATIENT_CONSULTATION_REQUEST}
        component={PatientConsultationRequestScreen}
        options={{
          ...mobileHeaderWithImageAndMsgOptions,
          headerRight: () => (
            <HeaderProfileRight
              showMessageIcon={true}
              onClick={() =>
                props.navigation.dispatch(DrawerActions.toggleDrawer())
              }
            />
          ),
        }}
      />
      <Screen
        name={NavigationKey.PATIENT_MEDICAL_HISTORY_STACK}
        component={PatientMedicalHistoryStackMobileNavigator}
        options={{
          headerShown: false,
        }}
      />
    </Navigator>
  );
};

export default Platform.OS === 'web'
  ? PatientLandingStackWebNavigator
  : PatientLandingStackMobileNavigator;
