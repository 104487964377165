import {color} from 'react-native-reanimated';

interface IColorShade {
  light: string;
  lighter: string;
  regular: string;
  dark: string;
  darker: string;
}

interface IColors {
  blueJeans: IColorShade;
  brightYellow: IColorShade;
  midnightBlue: IColorShade;
  skyBlue: IColorShade;
  mediumTurquoise: IColorShade;
  mediumSlateBlue: IColorShade;
  gray: IColorShade;
  white: IColorShade;
  black: IColorShade;
  red: IColorShade;
  purple: IColorShade;
}

export const colors: IColors = {
  blueJeans: {
    light: '#1BA1F9',
    lighter: '#1CA7EC', // no value
    regular: '#1CA7EC',
    dark: '#1CA7EC', // no value
    darker: '#167CA5',
  },
  brightYellow: {
    light: '#FFB000', // no value
    lighter: '#FFB000', // no value
    regular: '#FFB000',
    dark: '#FFB000', // no value
    darker: '#FFB000', // no value
  },
  midnightBlue: {
    light: '#064583',
    lighter: '#7A81F3', // no value
    regular: '#050B5A',
    dark: '#07395E',
    darker: '#342E37',
  },
  skyBlue: {
    light: '#7ED4F3', // no value
    lighter: '#7ED4F3', // no value
    regular: '#7ED4F3',
    dark: '#1FB2DA',
    darker: '#3FADCA',
  },
  mediumTurquoise: {
    light: '#52DEDD', // no value
    lighter: '#52DEDD', // no value
    regular: '#52DEDD',
    dark: '#52DEDD', // no value
    darker: '#52DEDD', // no value
  },
  mediumSlateBlue: {
    light: '#7981F3', // no value
    lighter: '#7981F3', // no value
    regular: '#7981F3',
    dark: '#3268BA',
    darker: '#7981F3', // no value
  },
  gray: {
    light: '#ADBABD', // no value
    lighter: '#E5E5E5',
    regular: '#ADBABD',
    dark: '#ADBABD', // no value
    darker: '#ADBABD', // no value
  },
  white: {
    light: '#FFFFFF', // no value
    lighter: '#FFFFFF', // no value
    regular: '#FFFFFF',
    dark: '#FFFFFF', // no value
    darker: '#FFFFFF', // no value
  },
  black: {
    light: '#342e38',
    lighter: '#000000', // no value
    regular: '#000000',
    dark: '#000000', // no value
    darker: '#000000', // no value
  },
  red: {
    light: '#FFFFFF', // no value
    lighter: '#FFFFFF', // no value
    regular: '#FF6663',
    dark: '#FFFFFF', // no value
    darker: '#FFFFFF', // no value
  },
  purple: {
    light: '#FFFFFF', // no value
    lighter: '#FFFFFF', // no value
    regular: '#7A81F3',
    dark: '#FFFFFF', // no value
    darker: '#FFFFFF', // no value
  },
};

export interface IColorPalette {
  primary: string;
  secondary: string;
  accent: string;
  tint: string;
  success: string;
  warning: string;
  error: string;
  black: string;
  white: string;
  dark: string;
  light: string;
  grey: string;
  blackLight: string;
  purple: string;
}

export type TColorPalette = keyof IColorPalette;

export const colorPalette: IColorPalette = {
  primary: colors.blueJeans.regular,
  secondary: colors.brightYellow.regular,
  accent: colors.midnightBlue.regular,
  tint: colors.midnightBlue.lighter,
  success: colors.mediumTurquoise.regular, // TBD
  warning: colors.brightYellow.regular, // TBD
  error: colors.red.regular, // TBD
  black: colors.black.regular,
  white: colors.white.regular,
  dark: colors.midnightBlue.darker,
  light: colors.gray.lighter,
  grey: colors.gray.regular,
  blackLight: colors.black.light,
  purple: colors.purple.regular,
};
