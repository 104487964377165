import React from 'react';
import {Platform, TextStyle} from 'react-native';

import {createStackNavigator} from '@react-navigation/stack';
import {colorPalette} from '../../../core/config/color.config';

import {NavigationKey} from '../config/navigation.config';
import {NavigationTitle} from '../config/navigationTitle';
import {
  DoctorViewProfessionalProfileScreen,
  DoctorEditProfessionalProfileScreen,
} from '../../screens';
import {HeaderBackLeft, HeaderIconRight} from '../../views';

const {Navigator, Screen} = createStackNavigator();

const mobileGeneralOptions = {
  title: '',
  headerTintColor: colorPalette.primary,
};

const DoctorProfileStackWebNavigator = () => {
  return (
    <Navigator>
      <Screen
        name={NavigationKey.DOCTOR_VIEW_PROFESSIONAL_PROFILE}
        component={DoctorViewProfessionalProfileScreen}
        options={{
          headerShown: false,
          title: NavigationTitle.DOCTOR_VIEW_PROFESSIONAL_PROFILE,
        }}
      />
      <Screen
        name={NavigationKey.DOCTOR_EDIT_PROFESSIONAL_PROFILE}
        component={DoctorEditProfessionalProfileScreen}
        options={{
          headerShown: false,
          title: NavigationTitle.DOCTOR_EDIT_PROFESSIONAL_PROFILE,
        }}
      />
    </Navigator>
  );
};

const doctorMobileNavigatorOptions = {
  headerStyle: {
    elevation: 0,
    shadowOpacity: 0,
  },
  headerTitleStyle: {
    fontWeight: 'bold',
    fontFamily: 'Quicksand-Bold',
    fontSize: 16,
  } as TextStyle,
};

const DoctorProfileStackMobileNavigator = () => {
  return (
    <Navigator>
      <Screen
        name={NavigationKey.DOCTOR_VIEW_PROFESSIONAL_PROFILE}
        component={DoctorViewProfessionalProfileScreen}
        options={{
          ...doctorMobileNavigatorOptions,
          headerStyle: {
            backgroundColor: colorPalette.purple,
            borderBottomColor: 'transparent',
            elevation: 0,
            shadowOpacity: 0,
          },
          headerLeft: () => <HeaderBackLeft label=" " iconName="left-white" />,
          ...mobileGeneralOptions,
        }}
      />
      <Screen
        name={NavigationKey.DOCTOR_EDIT_PROFESSIONAL_PROFILE}
        component={DoctorEditProfessionalProfileScreen}
      />
    </Navigator>
  );
};

export default Platform.OS === 'web'
  ? DoctorProfileStackWebNavigator
  : DoctorProfileStackMobileNavigator;
