import React, {useEffect, useState, useLayoutEffect} from 'react';
import {useNavigation, useRoute} from '@react-navigation/native';
import {View, Platform, ScrollView, Image} from 'react-native';
import {makeStyles, Divider} from 'rne-netzon';

import {RNEButton, RNEIconButton, RNEText, SVGIcon} from '../../components';
import {LogoLight} from './../../images/Image';
import {TouchableWithoutFeedback} from 'react-native-gesture-handler';
import {
  useDownloadPrescription,
  useGetPrescription,
} from '../../server/react-query/useMedicalAttachment';
import {formatDateString} from '../../utils/date.utils';
import {colorPalette} from '../../../core/config/color.config';
import HeaderIconRight from '../navigation/HeaderIconRight';
import {useDownloader} from '../../hooks/downloader/useDownloader';

export interface ViewPrescriptionViewProps {
  onClose: () => void;
  id: string;
  subject?: string;
}

const ViewPrescriptionView = (props: ViewPrescriptionViewProps) => {
  const webStyles = useWebStyles();
  const mobileStyles = useMobileStyles();
  const route = useRoute();
  const navigation = useNavigation();

  const [id, setId] = useState();
  const [navSubject, setNavSubject] = useState();
  const [age, setAge] = useState<number | null>(null);

  const {data, isFetching, refetch} = useGetPrescription(
    {
      groupId: Platform.OS === 'web' ? props.id : id!,
    },
    Platform.OS,
    Platform.OS === 'web' ? props.subject! : navSubject!,
  );

  const {data: webBlob, refetch: download} = useDownloadPrescription(
    {
      groupId: Platform.OS === 'web' ? props.id : id!,
    },
    Platform.OS,
    Platform.OS === 'web' ? props.subject : navSubject,
  );

  const prescriptionConfig = {
    web: {
      fileName: 'Prescription',
      data: webBlob,
      download: download,
    },
    mobile: {
      download: download,
    },
  };

  const {downloadAttachment} = useDownloader(prescriptionConfig);

  const onDownloadClick = () => {
    downloadAttachment();
  };

  useEffect(() => {
    const params = route.params as any;

    if (params && params.id) {
      setId(params.id);
    }

    if (params && params.subject) {
      setNavSubject(params.subject);
    }
  }, [route.params, props]);

  useEffect(() => {
    if (id && navSubject) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, navSubject]);

  useLayoutEffect(() => {
    if (Platform.OS !== 'web') {
      navigation.setOptions({
        headerRight: () => (
          <HeaderIconRight iconName="download-down" onClick={onDownloadClick} />
        ),
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigation]);

  useEffect(() => {
    if (data?.[0].subject.birthdate) {
      const birthdate = new Date(data[0].subject.birthdate);
      const ageDifMs = Date.now() - birthdate.getTime();
      const ageDate = new Date(ageDifMs);
      const calculatedAge = Math.abs(ageDate.getUTCFullYear() - 1970);
      setAge(calculatedAge);
    }
  }, [data]);

  return (
    <>
      {Platform.OS === 'web' ? (
        <View style={webStyles.mainContainer}>
          <View style={webStyles.headerContainer}>
            <Image style={webStyles.logo} source={LogoLight} />
            <View style={webStyles.rightIcons}>
              <TouchableWithoutFeedback>
                <RNEIconButton
                  icon={<SVGIcon name="download-down" size={28} />}
                  type="clear"
                  size={28}
                  onPress={onDownloadClick}
                  containerStyle={{borderRadius: 0}}
                />
              </TouchableWithoutFeedback>
              <TouchableWithoutFeedback>
                <RNEIconButton
                  icon={<SVGIcon name="close-gray-71" size={36} />}
                  type="clear"
                  size={36}
                  onPress={props.onClose}
                />
              </TouchableWithoutFeedback>
            </View>
          </View>
          <ScrollView>
            <View style={webStyles.bodyContainer}>
              {data && (
                <>
                  <View style={webStyles.infoContainer}>
                    <RNEText
                      label={`Patient: ${data[0].subject.humanName.given[0]} ${data[0].subject.humanName.family}`}
                      variant="body"
                      weight="bold"
                      style={textStyle}
                    />
                    <RNEText
                      label={`Address: ${data[0].subject.location ?? '--'}`}
                      variant="body"
                      weight="bold"
                      style={textStyle}
                    />
                    <RNEText
                      label={`Age: ${age ?? '--'}`}
                      variant="body"
                      weight="bold"
                      style={textStyle}
                    />
                    <RNEText
                      label={`Sex: ${
                        data[0].subject.sex
                          ? data[0].subject.sex.charAt(0).toUpperCase() +
                            data[0].subject.sex.slice(1)
                          : '--'
                      }`}
                      variant="body"
                      weight="bold"
                      style={textStyle}
                    />
                  </View>
                </>
              )}

              <View style={webStyles.titleContainer}>
                <RNEText
                  label="Rx"
                  variant="title"
                  weight="bold"
                  color="accent"
                  style={titleStyle}
                />
                <View style={webStyles.spinnerContainer}>
                  <RNEButton
                    height={20}
                    loadingProps={loadingPropsStyle}
                    loading={isFetching}
                  />
                </View>
              </View>
              {data && (
                <>
                  <View style={webStyles.infoContainer}>
                    <RNEText
                      label={`Date Issued: ${formatDateString(
                        data[0].authoredOn,
                      )}`}
                      variant="body"
                      style={textStyle}
                    />
                    <RNEText
                      label={`Prescription ID: #${data[0].groupId
                        .substring(0, 7)
                        .toUpperCase()}`}
                      variant="body"
                      style={textStyle}
                    />
                  </View>
                  {data.map((prescription, index) => (
                    <View key={index}>
                      <View style={webStyles.prescriptionContainer}>
                        {prescription.medicationCodeableConcept.code?.map(
                          (name, i) => (
                            <View key={i}>
                              {name.system === 'generic-name' && (
                                <RNEText
                                  label={`Generic Name: ${name.display}`}
                                  variant="body"
                                  style={textStyle}
                                />
                              )}
                              {name.system === 'brand-name' && (
                                <RNEText
                                  label={`Brand Name: ${name.display}`}
                                  variant="body"
                                  style={textStyle}
                                />
                              )}
                            </View>
                          ),
                        )}
                        <RNEText
                          label={`Dose: ${prescription.dosageInstruction.doseAndRate.doseText}`}
                          variant="body"
                          style={textStyle}
                        />
                        <RNEText
                          label={`Frequency: ${prescription.dosageInstruction.timing.text}`}
                          variant="body"
                          style={textStyle}
                        />
                        <RNEText
                          label={`Quantity: ${prescription.dosageInstruction.doseAndRate.rateText}`}
                          variant="body"
                          style={textStyle}
                        />
                        <br />
                        <RNEText
                          label={`General instruction: ${prescription.note.text}`}
                          variant="body"
                          style={textStyle}
                        />
                      </View>
                      {index < data.length - 1 && <Divider />}
                    </View>
                  ))}
                  <View style={webStyles.doctorContainer}>
                    <RNEText
                      label={`Dr. ${
                        data && data[0] && data[0].performer.humanName.given[0]
                      } ${
                        data && data[0] && data[0].performer.humanName.family
                      }`}
                      weight="bold"
                      style={doctorNameStyle}
                    />
                    <RNEText
                      label={
                        data &&
                        data[0] &&
                        data[0].performer.organization[0].address?.city
                          ? `${data[0].performer.practitionerRole[0].specialty.text}, ${data[0].performer.organization[0].address?.city}`
                          : `${data[0].performer.practitionerRole[0].specialty.text}`
                      }
                      variant="body"
                    />
                    <RNEText
                      label={`License No: ${
                        data &&
                        data[0] &&
                        data[0].performer.qualification.identifier
                      }`}
                      variant="body"
                    />
                  </View>
                </>
              )}
            </View>
          </ScrollView>
        </View>
      ) : (
        <ScrollView>
          <View style={mobileStyles.mainContainer}>
            {data && (
              <>
                <View style={mobileStyles.infoContainer}>
                  <RNEText
                    label={`Patient: ${data[0].subject.humanName.given[0]} ${data[0].subject.humanName.family}`}
                    variant="body"
                    weight="bold"
                    style={textStyle}
                  />
                  <RNEText
                    label={`Address: ${data[0].subject.location ?? '--'}`}
                    variant="body"
                    weight="bold"
                    style={textStyle}
                  />
                  <RNEText
                    label={`Age: ${age ?? '--'}`}
                    variant="body"
                    weight="bold"
                    style={textStyle}
                  />
                  <RNEText
                    label={`Sex: ${
                      data[0].subject.sex
                        ? data[0].subject.sex.charAt(0).toUpperCase() +
                          data[0].subject.sex.slice(1)
                        : '--'
                    }`}
                    variant="body"
                    weight="bold"
                    style={textStyle}
                  />
                </View>
              </>
            )}
            <View style={mobileStyles.titleContainer}>
              <RNEText
                label="Rx"
                variant="title"
                weight="bold"
                color="accent"
                style={titleStyle}
              />
              <View style={mobileStyles.spinnerContainer}>
                <RNEButton
                  height={24}
                  loadingProps={loadingPropsStyle}
                  loading={isFetching}
                />
              </View>
            </View>
            {data && (
              <>
                <View style={mobileStyles.infoContainer}>
                  <RNEText
                    label={`Date Issued: ${formatDateString(
                      data[0].authoredOn,
                    )}`}
                    variant="body"
                  />
                  <RNEText
                    label={`Prescription ID: #${data[0].groupId
                      .substring(0, 7)
                      .toUpperCase()}`}
                    variant="body"
                  />
                </View>
                {data.map((prescription, index) => (
                  <View key={index}>
                    <View style={mobileStyles.prescriptionContainer}>
                      {prescription.medicationCodeableConcept.code?.map(
                        (name, i) => (
                          <View key={i}>
                            {name.system === 'generic-name' && (
                              <RNEText
                                label={`Generic Name: ${name.display}`}
                                variant="body"
                              />
                            )}
                            {name.system === 'brand-name' && (
                              <RNEText
                                label={`Brand Name: ${name.display}`}
                                variant="body"
                              />
                            )}
                          </View>
                        ),
                      )}
                      <RNEText
                        label={`Dose: ${prescription.dosageInstruction.doseAndRate.doseText}`}
                        variant="body"
                      />
                      <RNEText
                        label={`Frequency: ${prescription.dosageInstruction.timing.text}`}
                        variant="body"
                      />
                      <RNEText
                        label={`Quantity: ${prescription.dosageInstruction.doseAndRate.rateText}`}
                        variant="body"
                      />
                      <View style={mobileStyles.spaceContainer} />
                      <RNEText
                        label={`General instruction: ${prescription.note.text}`}
                        variant="body"
                      />
                    </View>
                    {index < data.length - 1 && <Divider />}
                  </View>
                ))}
                <View style={mobileStyles.doctorContainer}>
                  <RNEText
                    label={`Dr. ${
                      data && data[0] && data[0].performer.humanName.given[0]
                    } ${data && data[0] && data[0].performer.humanName.family}`}
                    weight="bold"
                    variant="title"
                    style={doctorNameStyle}
                  />
                  <RNEText
                    label={
                      data &&
                      data[0] &&
                      data[0].performer.organization[0].address?.city
                        ? `${data[0].performer.practitionerRole[0].specialty.text}, ${data[0].performer.organization[0].address?.city}`
                        : `${data[0].performer.practitionerRole[0].specialty.text}`
                    }
                    variant="body"
                  />
                  <RNEText
                    label={`License No: ${
                      data &&
                      data[0] &&
                      data[0].performer.qualification.identifier
                    }`}
                    variant="body"
                  />
                </View>
              </>
            )}
          </View>
        </ScrollView>
      )}
    </>
  );
};

export default ViewPrescriptionView;

const titleStyle = {
  fontSize: 40,
};
const doctorNameStyle = {
  fontSize: 24,
};

const textStyle = {
  lineHeight: 24,
};

const loadingPropsStyle = {
  color: colorPalette.primary,
};

const useWebStyles = makeStyles({
  mainContainer: {
    marginHorizontal: 40,
    marginVertical: 20,
    flex: 1,
    width: '40vw',
    maxWidth: 700,
    minWidth: 440,
    maxHeight: '70vh',
  },
  headerContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottomColor: 'rgba(229, 229, 229, 0.7)',
    borderBottomWidth: 1,
    paddingTop: 12,
    paddingBottom: 28,
  },
  logo: {
    width: 200,
    height: 44,
  },
  rightIcons: {
    flexDirection: 'row',
    alignSelf: 'center',
    justifyContent: 'space-between',
    width: 76,
  },
  closeIcon: {
    marginLeft: 28,
  },
  bodyContainer: {
    marginVertical: 40,
    marginHorizontal: 60,
  },
  infoContainer: {
    marginVertical: 24,
  },
  prescriptionContainer: {
    marginVertical: 28,
  },
  doctorContainer: {
    marginVertical: 24,
  },
  titleContainer: {
    flexDirection: 'row',
  },
  spinnerContainer: {
    alignSelf: 'flex-end',
    marginBottom: 12,
  },
});

const useMobileStyles = makeStyles({
  mainContainer: {
    width: '100%',
    flex: 1,
    paddingHorizontal: 52,
    paddingVertical: 40,
  },
  infoContainer: {
    marginTop: 12,
    marginBottom: 24,
  },
  prescriptionContainer: {
    marginVertical: 28,
  },
  spaceContainer: {
    marginVertical: 12,
  },
  doctorContainer: {
    marginVertical: 24,
  },
  titleContainer: {
    flexDirection: 'row',
  },
  spinnerContainer: {
    marginTop: 24,
    marginLeft: -12,
  },
});
