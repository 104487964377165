import React from 'react';
import {StyleSheet, View} from 'react-native';

import {observer} from 'mobx-react';

import useStores from '../../stores/useStores';

import {RNEText} from '../../components';

import {colorPalette} from '../../../core/config/color.config';

import ConsultationStatus from '../consultation/ConsultationStatus';
import ConsultationPhoto from '../consultation/ConsultationPhoto';

import {
  ConsultationRequestResBody,
  ConsultationStatusEnum,
} from '../../server/types/consultation.types';

import {PopoverItemKey} from '../popover/data/popoverItems.data';
import ConsultationPatientPopover from '../popover/ConsultationPatientPopover';

interface HeaderConsultationDoctorWebProps {
  serviceRequest?: ConsultationRequestResBody;
  onMenuItemClick?: (itemKey: PopoverItemKey) => void;
}

const HeaderConsultationDoctorWeb = (
  props: HeaderConsultationDoctorWebProps,
) => {
  const {authStore} = useStores();

  return (
    <>
      <View style={styles.mainContainer}>
        <View style={styles.profileContainer}>
          <View style={styles.profilePhoto}>
            <ConsultationPhoto
              photo={props.serviceRequest?.performer?.photo}
              size={48}
            />
          </View>

          <View style={styles.nameProfessionContainer}>
            <RNEText
              label={
                props.serviceRequest?.performer
                  ? `Dr. ${props.serviceRequest?.performer?.humanName.given[0]} ${props.serviceRequest?.performer?.humanName.family}`
                  : '--'
              }
              variant="body"
              weight="bold"
              numberOfLines={1}
            />
            <RNEText
              label={
                props.serviceRequest?.performer
                  ? props.serviceRequest?.performer.organization[0].address
                      ?.city
                    ? `${props.serviceRequest?.performer.practitionerRole[0].specialty.text} at ${props.serviceRequest?.performer?.organization[0].address?.city}`
                    : `${props.serviceRequest?.performer.practitionerRole[0].specialty.text}`
                  : '--'
              }
              variant="button"
              weight="300"
              color="blackLight"
              numberOfLines={1}
            />
          </View>
        </View>

        {props.serviceRequest?.status === ConsultationStatusEnum.ONGOING ||
        props.serviceRequest?.status === ConsultationStatusEnum.COMPLETED ? (
          <ConsultationPatientPopover
            status={props.serviceRequest.status}
            onMenuItemClick={props.onMenuItemClick}
          />
        ) : (
          <ConsultationStatus
            consultationStatus={props.serviceRequest?.status}
            procedureStatus={props.serviceRequest?.procedure?.status}
            isActionByPatient={
              authStore.auth?.user.userId ===
                props.serviceRequest?.modifiedBy &&
              authStore.userRole === 'patient'
            }
          />
        )}
      </View>
    </>
  );
};

export default observer(HeaderConsultationDoctorWeb);

const styles = StyleSheet.create({
  mainContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 16,
    borderBottomWidth: 1,
    borderBottomColor: colorPalette.light,
  },
  profileContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  profilePhoto: {
    borderRadius: 50,
    borderWidth: 1,
    borderColor: colorPalette.primary,
    width: 48,
    height: 48,
    alignItems: 'center',
    justifyContent: 'center',
  },
  imageStyle: {
    height: 48,
    width: 48,
    borderRadius: 100,
    borderWidth: 1,
    borderColor: colorPalette.primary,
  },
  nameProfessionContainer: {
    marginLeft: 24,
    flex: 0.9,
  },
});
