import React, {memo} from 'react';
import {Platform, View} from 'react-native';

import {makeStyles} from 'rne-netzon';

import {SVGIcon, RNEButton, RNEText} from '../../components';

interface SwitchPatientConfirmationModalProps {
  onCancel?: () => void;
  onConfirm?: () => void;
}

function SwitchPatientConfirmationModal(
  props: SwitchPatientConfirmationModalProps,
) {
  const rneStyles = useStyles();

  return (
    <View style={rneStyles.root}>
      <RNEText
        label="Are you sure you switch the patient profile?"
        variant="header"
        align="center"
        weight="600"
        color="accent"
      />

      <View style={rneStyles.actionContainer}>
        <View style={rneStyles.buttonContainer}>
          <RNEButton
            title="Cancel"
            color="accent"
            onPress={props.onCancel}
            style={rneStyles.buttonStyle}
            variant="outlined"
          />
        </View>
        <RNEButton
          title="Switch"
          color="accent"
          onPress={props.onConfirm}
          style={rneStyles.buttonStyle}
        />
      </View>
    </View>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 540,
    padding: 32,
    height: 320,
    justifyContent: 'center',
  },
  actionContainer: {
    marginTop: 80,
    justifyContent: 'center',
    flexDirection: 'row',
  },
  buttonContainer: {
    marginRight: 16,
  },
  buttonStyle: {
    width: 120,
  },
}));

export default memo(SwitchPatientConfirmationModal);
