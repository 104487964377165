import React, {useState, useEffect} from 'react';
import {Platform, StyleProp, TextStyle} from 'react-native';

import {useTheme, Button, ButtonProps} from 'rne-netzon';
import {ButtonColor, ButtonVariant} from './models';
import {colorPalette} from '../../../../core/config/color.config';

const defaultHeight = {
  web: 48,
  mobile: 48,
} as const;

const defaultFontSize = {
  web: 14,
  mobile: 12,
} as const;

interface RNEButtonProps extends ButtonProps {
  color?: ButtonColor;
  height?: number;
  variant?: ButtonVariant;
}

export const RNEButton = (props: RNEButtonProps) => {
  const {theme} = useTheme();

  const [backgroundColor, setBackgroundColor] = useState<string>();

  let {
    title = '',
    height = Platform.OS === 'web' ? defaultHeight.web : defaultHeight.mobile,
    variant = 'default',
    ...others
  } = props;

  const textStyle: StyleProp<TextStyle> = {
    fontFamily: 'Quicksand-Bold',
    fontSize:
      Platform.OS === 'web' ? defaultFontSize.web : defaultFontSize.mobile,
    fontWeight: 'bold',
    color: variant === 'default' ? '#FFFFFF' : backgroundColor,
  };

  const updateButtonStyle = () => {
    switch (props.color) {
      case 'primary':
        setBackgroundColor(theme.colors?.primary);
        break;

      case 'secondary':
        setBackgroundColor(theme.colors?.secondary);
        break;

      case 'success':
        setBackgroundColor(theme.colors?.success);
        break;

      case 'warning':
        setBackgroundColor(theme.colors?.warning);
        break;

      case 'error':
        setBackgroundColor(theme.colors?.error);
        break;

      case 'accent':
        setBackgroundColor(colorPalette.accent);
        break;

      case 'white':
        setBackgroundColor(colorPalette.white);
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    updateButtonStyle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.color]);

  // TODO: Add hover styles.
  return (
    <Button
      buttonStyle={Object.assign(
        {
          backgroundColor:
            variant === 'default' ? backgroundColor : 'transparent',
          height: height,
          borderColor: variant === 'outlined' ? backgroundColor : 'transparent',
          borderWidth: variant === 'outlined' ? 2 : 0,
        },
        others.buttonStyle ?? {},
      )}
      {...others}
      titleStyle={[textStyle, props.titleStyle ?? {}]}
      title={title}
    />
  );
};
