import React from 'react';
import {View, Platform, ScrollView} from 'react-native';
import {SafeAreaView} from 'react-native-safe-area-context';
import {makeStyles} from 'rne-netzon';

import {RNEText} from '../../components';

const PatientMessagesScreen = () => {
  const sharedStyles = useSharedStyles();
  const webStyles = useWebStyles();
  const mobileStyles = useMobileStyles();

  return (
    <SafeAreaView style={sharedStyles.root}>
      {Platform.OS === 'web' ? (
        <View style={webStyles.mainContainer}>
          <RNEText
            label="Messages"
            variant="header"
            weight="bold"
            color="accent"
          />
        </View>
      ) : (
        <ScrollView>
          <View style={mobileStyles.mainContainer}>
            <RNEText
              label="Messages"
              variant="header"
              weight="bold"
              color="accent"
            />
          </View>
        </ScrollView>
      )}
    </SafeAreaView>
  );
};

export default PatientMessagesScreen;

const useWebStyles = makeStyles({
  mainContainer: {
    paddingVertical: 140,
    paddingHorizontal: 80,
  },
});

const useMobileStyles = makeStyles({
  mainContainer: {
    padding: 50,
  },
});

const useSharedStyles = makeStyles({
  root: {
    height: '100%',
    flexDirection: 'row',
    width: '100%',
  },
});
