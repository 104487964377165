import React from 'react';
import {Platform} from 'react-native';
import {ToastProvider} from 'react-native-fast-toast';

import {colorPalette} from './../../../core/config/color.config';

interface FastToastProps {
  children: React.ReactNode;
}

function FastToastProvider(props: FastToastProps) {
  return (
    <ToastProvider
      placement="top"
      offset={Platform.OS === 'ios' ? 40 : 0}
      dangerColor={colorPalette.error}
      successColor={colorPalette.success}
      normalColor={colorPalette.primary}
      warningColor={colorPalette.warning}>
      {props.children}
    </ToastProvider>
  );
}

export default FastToastProvider;
