import React, {memo} from 'react';
import {Platform, View} from 'react-native';

import {makeStyles} from 'rne-netzon';

import {SVGIcon, RNEText, RNEIconButton} from '../../components';

interface ConsultationRequestSentModalProps {
  onConfirm?: () => void;
}

function ConsultationRequestSentModal(
  props: ConsultationRequestSentModalProps,
) {
  const rneStyles = useStyles();

  return (
    <View style={rneStyles.root}>
      <RNEText
        label="Consultation Request Sent!"
        variant="header"
        style={titleFontSize}
        align="center"
        weight="600"
        color="primary"
      />
      <View style={rneStyles.subtitleContainer}>
        <RNEText
          label="We have notified the doctor regarding your request. Once the doctor accepts your request, you can then proceed to pay the consultation fee before it starts."
          variant="subheader"
          style={subtitleFontSize}
          align="center"
          color={Platform.OS === 'web' ? 'blackLight' : 'grey'}
        />
      </View>

      <View style={rneStyles.buttonContainer}>
        <RNEIconButton
          icon={<SVGIcon name="check-white" size={48} />}
          color="secondary"
          size={80}
          onPress={props.onConfirm}
          raised={Platform.OS !== 'web'}
        />
      </View>
    </View>
  );
}

const titleFontSize = {
  fontSize: Platform.OS === 'web' ? 32 : 20,
};

const subtitleFontSize = {
  fontSize: Platform.OS === 'web' ? 24 : 16,
};

const useStyles = makeStyles(theme => ({
  root: {
    padding: 28,
    margin: 32,
    height: 300,
    justifyContent: 'center',
    alignSelf: 'center',
    maxWidth: Platform.OS === 'web' ? 500 : '85%',
  },
  subtitleContainer: {
    marginTop: 20,
  },
  buttonContainer: {
    marginTop: 48,
    justifyContent: 'center',
    alignSelf: 'center',
  },
}));

export default memo(ConsultationRequestSentModal);
