import {Platform} from 'react-native';
import {colorPalette} from '../../../../core/config/color.config';

export type InputVariant = 'default' | 'shadowed';

interface InputStyle {
  border: {
    default: string;
    focus: string;
    error: string;
  };
}

export const inputStyles: {[key in InputVariant]: InputStyle} = {
  default: {
    border: {
      default: colorPalette.light,
      focus: colorPalette.primary,
      error: colorPalette.error,
    },
  },
  shadowed: {
    border: {
      default: 'transparent',
      focus: '#ACBABD',
      error: colorPalette.error,
    },
  },
};

export const shadowStyles = {
  shadowColor: Platform.OS === 'web' ? '#070F580D' : colorPalette.grey,
  shadowOffset: {
    width: -5,
    height: 10,
  },
  shadowOpacity: 1,
  shadowRadius: 10,
  elevation: 16,
  backgroundColor: colorPalette.white,
};
