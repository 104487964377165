import {AxiosRequestConfig} from 'axios';
import {useMutation, useQueryClient} from 'react-query';
import {API, ApiEndpointsEnum} from '../api';
import {getJWT} from '../api/api.helper';
import {FirebaseTokenReqBody} from '../types/firebase-token.types';

const saveFirebaseToken = async (reqBody: FirebaseTokenReqBody) => {
  return API.post(`${ApiEndpointsEnum.POST_FIREBASE_TOKEN}`, reqBody);
};

const useSaveFirebaseToken = (reqBody: FirebaseTokenReqBody) => {
  const queryClient = useQueryClient();

  return useMutation(['saveFirebaseToken'], () => saveFirebaseToken(reqBody), {
    onSuccess: () => {
      queryClient.invalidateQueries(['saveFirebaseToken']);
    },
  });
};

export {useSaveFirebaseToken};
