import React, {useState} from 'react';
import {View, TouchableWithoutFeedback} from 'react-native';
import {Image, makeStyles} from 'rne-netzon';
import {SVGIcon} from '..';
import {colorPalette} from '../../../core/config/color.config';
import {ImageAttachmentSelectorProps} from './ImageAttachmentSelector.native';
import {useToast} from 'react-native-fast-toast';
import {compress} from 'image-conversion';

export const ImageAttachmentSelector = (
  props: ImageAttachmentSelectorProps,
) => {
  const webStyles = useWebStyles();
  let inputElement: HTMLElement | null;
  const [fileUri, setFileUri] = useState<string | undefined>('');

  const toast = useToast();

  const triggerInputClick = async () => {
    inputElement?.click();
  };

  const onInputClick = (event: any) => {
    event.target.value = '';
  };

  const fileSizeChecker = (file: File | Blob, uri: string) => {
    const maxSizeInMB = 20;
    const maxSizeInBytes = maxSizeInMB * 1024 * 1024; // Convert MB to bytes

    const fileSize = file instanceof File ? file.size : file.size;

    if (fileSize > maxSizeInBytes) {
      toast?.show(`File size is over ${maxSizeInMB}MB`, {type: 'danger'});
    } else {
      setFileUri(uri);
      props.onSelectPhoto(file);
    }
  };

  const handleFileChange = async (event: any) => {
    const {target} = event;
    const {files} = target;

    if (files && files[0]) {
      const file = files[0];

      console.log(
        'Before compression - File size:',
        (file.size / (1024 * 1024)).toFixed(2),
        'MB',
      );

      // Check if the file size is over 20MB (5 * 1024 * 1024 bytes)
      if (file.size > 20 * 1024 * 1024) {
        try {
          const compressedBlob = await compress(file, {quality: 0.5});

          // Create a new File object using the compressed Blob and retain original file properties
          const compressedFile = new File([compressedBlob], file.name, {
            type: compressedBlob.type,
            lastModified: Date.now(),
          });

          console.log(
            'After compression - File size:',
            (compressedFile.size / (1024 * 1024)).toFixed(2),
            'MB',
          );

          const compressedFileUri = URL.createObjectURL(compressedFile);

          fileSizeChecker(compressedFile, compressedFileUri);
        } catch (error) {
          console.error('Error compressing image:', error);
        }
      } else {
        // If the file size is not over 20MB, directly pass the file to fileSizeChecker
        const fileUri = URL.createObjectURL(file);
        fileSizeChecker(file, fileUri);
      }
    }
  };

  const removeSelectedPhoto = () => {
    setFileUri(undefined);
    // props.providedPhoto = '';
    props.onSelectPhoto(null);
  };

  const inputStyle = {
    display: 'none',
  };

  return (
    <View>
      <TouchableWithoutFeedback onPress={triggerInputClick}>
        <View style={webStyles.cameraIcon} accessibilityRole="button">
          <SVGIcon name="camera-29" size={20} />
          <input
            ref={input => (inputElement = input)}
            type="file"
            style={inputStyle}
            accept="image/jpeg, image/png, .svg, image/heic, image/heic-sequence"
            onChange={handleFileChange}
            onClick={onInputClick}
          />
        </View>
      </TouchableWithoutFeedback>
    </View>
  );
};

const useWebStyles = makeStyles({
  inputStyle: {
    display: 'none',
  },
  cameraIcon: {
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default ImageAttachmentSelector;
