import React, {memo, useEffect, useState} from 'react';
import {View} from 'react-native';

import {Divider} from 'rne-netzon';
import {RNEText, SVGIcon} from '../../components';

import {
  Menu,
  MenuOptions,
  MenuOption,
  MenuTrigger,
} from 'react-native-popup-menu';

import {
  popoverOptionsStyles,
  popoverOptionStyles,
  popoverDividerStyle,
  PopoverItemKey,
  IPopoverItem,
  patientCompletedConsultationPopoverItems,
  patientOngoingConsultationPopoverItems,
} from './data/popoverItems.data';

import {ConsultationStatusEnum} from '../../server/types/consultation.types';

interface ConsultationPatientPopoverProps {
  status: ConsultationStatusEnum;
  onMenuItemClick?: (itemKey: PopoverItemKey) => void;
}

const ConsultationPatientPopover = (props: ConsultationPatientPopoverProps) => {
  const [menuList, setMenuList] = useState<IPopoverItem[]>([]);

  useEffect(() => {
    switch (props.status) {
      case ConsultationStatusEnum.ONGOING:
        setMenuList(patientOngoingConsultationPopoverItems);
        break;

      case ConsultationStatusEnum.COMPLETED:
        setMenuList(patientCompletedConsultationPopoverItems);
        break;

      default:
        setMenuList([]);
        break;
    }
  }, [props.status]);

  const onItemClick = (itemKey: PopoverItemKey) => {
    if (props.onMenuItemClick) {
      props.onMenuItemClick(itemKey);
    }
  };

  return (
    <Menu rendererProps={{preferredPlacement: 'bottom'}}>
      <MenuTrigger>
        <SVGIcon name="option-gray" />
      </MenuTrigger>
      <MenuOptions customStyles={popoverOptionsStyles}>
        {menuList.map((item, index) => (
          <View key={index}>
            {index > 0 && <Divider style={popoverDividerStyle} />}

            <MenuOption
              customStyles={popoverOptionStyles}
              onSelect={() => onItemClick(item.itemKey)}>
              <RNEText label={item.label} variant="button" weight="600" />
            </MenuOption>
          </View>
        ))}
      </MenuOptions>
    </Menu>
  );
};

export default memo(ConsultationPatientPopover);
