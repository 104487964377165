import React, {memo, useEffect, useState} from 'react';
import {View, TouchableOpacity} from 'react-native';

import {makeStyles} from 'rne-netzon';
import {colorPalette} from '../../../core/config/color.config';
import {RNEText} from '../../components';

import useStores from '../../stores/useStores';

import {Photo} from '../../server/types/user.types';
import {
  ConsultationRequestResBody,
  ConsultationStatusEnum,
} from '../../server/types/consultation.types';
import {formatShortDate, timestamp} from '../../utils/date.utils';

import {default as ConsultationPhoto} from './ConsultationPhoto';
import {isToday} from 'date-fns';
import {ProcedureStatusEnum} from '../../server/types/procedure.types';
import {ConsultationStatus} from '..';

interface ConsultationListItemPendingProps {
  item: ConsultationRequestResBody;
  onPress?: () => void;
}

const ConsultationListItemPending = (
  props: ConsultationListItemPendingProps,
) => {
  const styles = useStyle();
  const {authStore} = useStores();

  const [displayName, setDisplayName] = useState('');
  const [displayPhoto, setDisplayPhoto] = useState<Photo>();
  const [displayStatus, setDisplayStatus] = useState(false);

  useEffect(() => {
    switch (authStore.userRole) {
      case 'patient':
        if (props.item.performer) {
          setDisplayName(
            `Dr. ${props.item.performer.humanName.given[0]} ${props.item.performer.humanName.family}`,
          );
          setDisplayPhoto(props.item.performer.photo);
        } else {
          setDisplayName('Deleted Profile');
        }
        break;

      case 'practitioner':
        if (props.item.subject) {
          setDisplayName(
            `"Patient ${props.item.subject.patientId
              .substring(0, 6)
              .toUpperCase()}"`,
          );
          setDisplayPhoto(props.item.subject.photo);
        } else {
          setDisplayName('Deleted Profile');
        }
        break;

      default:
        break;
    }

    if (props.item?.procedure) {
      if (
        props.item?.status === ConsultationStatusEnum.PENDING &&
        props.item?.procedure?.status === ProcedureStatusEnum.TO_PAY &&
        authStore.userRole === 'practitioner'
      ) {
        if (props.item.subject) {
          setDisplayName(
            `${props.item?.subject?.humanName.given[0]} ${props.item?.subject?.humanName.family}`,
          );
          setDisplayPhoto(props.item?.subject?.photo);
        } else {
          setDisplayName('Deleted Profile');
        }
        setDisplayStatus(true);
      }
    }
  }, [authStore.userRole, props]);

  return (
    <TouchableOpacity activeOpacity={0.75} onPress={props.onPress}>
      <View style={styles.root}>
        <View style={styles.photo}>
          <ConsultationPhoto photo={displayPhoto} />
        </View>

        <View style={styles.message}>
          <View>
            <RNEText
              label={displayName}
              color="blackLight"
              weight="600"
              numberOfLines={1}
              ellipsizeMode="tail"
            />
          </View>

          <View>
            <RNEText
              label={props.item.note[0].text}
              color="blackLight"
              variant="button"
              weight="400"
              numberOfLines={2}
              ellipsizeMode="tail"
            />
          </View>
        </View>

        <View style={styles.timestamp}>
          {displayStatus ? (
            <ConsultationStatus
              consultationStatus={props?.item?.status}
              procedureStatus={props?.item?.procedure?.status}
            />
          ) : (
            <RNEText
              label={
                isToday(
                  new Date(props.item.note[props.item.note.length - 1].time),
                )
                  ? timestamp(
                      new Date(
                        props.item.note[props.item.note.length - 1].time,
                      ),
                    )
                  : formatShortDate(
                      new Date(
                        props.item.note[props.item.note.length - 1].time,
                      ),
                    )
              }
              color="grey"
              variant="button"
            />
          )}
        </View>
      </View>
    </TouchableOpacity>
  );
};

const useStyle = makeStyles({
  root: {
    flexDirection: 'row',
    paddingHorizontal: 24,
    paddingVertical: 24,
    borderBottomWidth: 1,
    borderBottomColor: colorPalette.light,
  },
  photo: {},
  message: {
    flex: 1,
    paddingHorizontal: 16,
    paddingTop: 4,
  },
  timestamp: {
    paddingTop: 4,
  },
});

export default memo(ConsultationListItemPending);
