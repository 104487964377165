import {useMutation, useQuery} from 'react-query';
import {useQueriesTyped} from './utils/react-query.types';

import {AxiosRequestConfig} from 'axios';

import {API, ApiEndpointsEnum} from '../api';
import {
  AllergyIntoleranceReqResponse,
  ConditionReqResponse,
  FamilyMemberHistoryReqResponse,
  MedicationStatementReqResponse,
  ProcedureReqResponse,
  MedicalHistoryReqBody,
} from '../types/medical-history.types';
import {ContactPoint} from '../types/patient-profile.types';

const getAllergyIntolerance = (reqBody: MedicalHistoryReqBody) => {
  let config: AxiosRequestConfig = {};

  if (reqBody.patientId) {
    const headerAuthorization: any = {
      'X-Subject': reqBody.patientId,
    };

    config = {
      headers: {
        ...headerAuthorization,
      },
    };
  }

  const url = ApiEndpointsEnum.GET_ALLERGY_INTOLERANCE;
  return API.get<AllergyIntoleranceReqResponse[]>(url, config);
};

const useGetAllergyIntolerance = (reqBody: MedicalHistoryReqBody) => {
  return useQuery(['get-allergy-intolerance', {id: reqBody.patientId}], () =>
    getAllergyIntolerance(reqBody),
  );
};

const getCondition = (reqBody: MedicalHistoryReqBody) => {
  let config: AxiosRequestConfig = {};

  if (reqBody.patientId) {
    const headerAuthorization: any = {
      'X-Subject': reqBody.patientId,
    };

    config = {
      headers: {
        ...headerAuthorization,
      },
    };
  }

  const url = ApiEndpointsEnum.GET_CONDITION;
  return API.get<ConditionReqResponse[]>(url, config);
};

const useGetCondition = (reqBody: MedicalHistoryReqBody) => {
  return useQuery(['get-condition', {id: reqBody.patientId}], () =>
    getCondition(reqBody),
  );
};

const getFamilyMemberHistory = (reqBody: MedicalHistoryReqBody) => {
  let config: AxiosRequestConfig = {};

  if (reqBody.patientId) {
    const headerAuthorization: any = {
      'X-Subject': reqBody.patientId,
    };

    config = {
      headers: {
        ...headerAuthorization,
      },
    };
  }

  const url = ApiEndpointsEnum.GET_FAMILY_MEMBER_HISTORY;
  return API.get<FamilyMemberHistoryReqResponse[]>(url, config);
};

const useGetFamilyMemberHistory = (reqBody: MedicalHistoryReqBody) => {
  return useQuery(['get-family-member-history', {id: reqBody.patientId}], () =>
    getFamilyMemberHistory(reqBody),
  );
};

const getMedicationStatement = (reqBody: MedicalHistoryReqBody) => {
  let config: AxiosRequestConfig = {};

  if (reqBody.patientId) {
    const headerAuthorization: any = {
      'X-Subject': reqBody.patientId,
    };

    config = {
      headers: {
        ...headerAuthorization,
      },
    };
  }

  const url = ApiEndpointsEnum.GET_MEDICATION_STATEMENT;
  return API.get<MedicationStatementReqResponse[]>(url, config);
};

const useGetMedicationStatement = (reqBody: MedicalHistoryReqBody) => {
  return useQuery(['get-medication-statement', {id: reqBody.patientId}], () =>
    getMedicationStatement(reqBody),
  );
};

const getProcedure = (reqBody: MedicalHistoryReqBody) => {
  let config: AxiosRequestConfig = {};

  if (reqBody.patientId) {
    const headerAuthorization: any = {
      'X-Subject': reqBody.patientId,
    };

    config = {
      headers: {
        ...headerAuthorization,
      },
    };
  }

  const url = ApiEndpointsEnum.GET_PROCEDURE + '?code';
  return API.get<ProcedureReqResponse[]>(url, config);
};

const useGetProcedure = (reqBody: MedicalHistoryReqBody) => {
  return useQuery(['get-procedure', {id: reqBody.patientId}], () =>
    getProcedure(reqBody),
  );
};

const getTelecom = (reqBody: MedicalHistoryReqBody) => {
  const url = ApiEndpointsEnum.GET_TELECOM.replace(
    '{patientId}',
    reqBody.patientId,
  );
  return API.get<ContactPoint[]>(url);
};

const useGetTelecom = (reqBody: MedicalHistoryReqBody) => {
  return useQuery(['get-telecom', {id: reqBody.patientId}], () =>
    getTelecom(reqBody),
  );
};

const useGetMedicalData = (reqBody: MedicalHistoryReqBody) => {
  return useQueriesTyped([
    {
      queryKey: ['get-allergy-intolerance', {id: reqBody.patientId}],
      queryFn: () => getAllergyIntolerance(reqBody),
      enabled: !!reqBody.patientId,
    },
    {
      queryKey: ['get-family-member-history', {id: reqBody.patientId}],
      queryFn: () => getFamilyMemberHistory(reqBody),
      enabled: !!reqBody.patientId,
    },
    {
      queryKey: ['get-procedure', {id: reqBody.patientId}],
      queryFn: () => getProcedure(reqBody),
      enabled: !!reqBody.patientId,
    },
    {
      queryKey: ['get-medication-statement', {id: reqBody.patientId}],
      queryFn: () => getMedicationStatement(reqBody),
      enabled: !!reqBody.patientId,
    },
    {
      queryKey: ['get-condition', {id: reqBody.patientId}],
      queryFn: () => getCondition(reqBody),
      enabled: !!reqBody.patientId,
    },
    {
      queryKey: ['get-telecom', {id: reqBody.patientId}],
      queryFn: () => getTelecom(reqBody),
      enabled: !!reqBody.patientId,
    },
  ]);
};

const putAllergyIntolerance = (reqBody: string[], patientId: string) => {
  let config: AxiosRequestConfig = {};

  if (patientId) {
    const headerAuthorization: any = {
      'X-Subject': patientId,
    };

    config = {
      headers: {
        ...headerAuthorization,
      },
    };
  }

  const url = ApiEndpointsEnum.PUT_ALLERGY_INTOLERANCE;
  return API.put<AllergyIntoleranceReqResponse[]>(url, reqBody, config);
};

const usePutAllergyIntolerance = (reqBody: string[], patientId: string) => {
  return useMutation(['put-allergy-intolerance'], () =>
    putAllergyIntolerance(reqBody, patientId),
  );
};

const putCondition = (reqBody: string[], patientId: string) => {
  let config: AxiosRequestConfig = {};

  if (patientId) {
    const headerAuthorization: any = {
      'X-Subject': patientId,
    };

    config = {
      headers: {
        ...headerAuthorization,
      },
    };
  }

  const url = ApiEndpointsEnum.PUT_CONDITION;
  return API.put<ConditionReqResponse[]>(url, reqBody, config);
};

const usePutCondition = (reqBody: string[], patientId: string) => {
  return useMutation(['put-condition'], () => putCondition(reqBody, patientId));
};

const putFamilyMemberHistory = (reqBody: string[], patientId: string) => {
  let config: AxiosRequestConfig = {};

  if (patientId) {
    const headerAuthorization: any = {
      'X-Subject': patientId,
    };

    config = {
      headers: {
        ...headerAuthorization,
      },
    };
  }

  const url = ApiEndpointsEnum.PUT_FAMILY_MEMBER_HISTORY;
  return API.put<FamilyMemberHistoryReqResponse[]>(url, reqBody, config);
};

const usePutFamilyMemberHistory = (reqBody: string[], patientId: string) => {
  return useMutation(['put-family-member-history'], () =>
    putFamilyMemberHistory(reqBody, patientId),
  );
};

const putMedicationStatement = (reqBody: string[], patientId: string) => {
  let config: AxiosRequestConfig = {};

  if (patientId) {
    const headerAuthorization: any = {
      'X-Subject': patientId,
    };

    config = {
      headers: {
        ...headerAuthorization,
      },
    };
  }

  const url = ApiEndpointsEnum.PUT_MEDICATION_STATEMENT;
  return API.put<MedicationStatementReqResponse[]>(url, reqBody, config);
};

const usePutMedicationStatement = (reqBody: string[], patientId: string) => {
  return useMutation(['put-medication-statement'], () =>
    putMedicationStatement(reqBody, patientId),
  );
};

const putProcedure = (reqBody: string[], patientId: string) => {
  let config: AxiosRequestConfig = {};

  if (patientId) {
    const headerAuthorization: any = {
      'X-Subject': patientId,
    };

    config = {
      headers: {
        ...headerAuthorization,
      },
    };
  }

  const url = ApiEndpointsEnum.PUT_PROCEDURE;
  return API.put<ProcedureReqResponse[]>(url, reqBody, config);
};

const usePutProcedure = (reqBody: string[], patientId: string) => {
  return useMutation(['put-procedure'], () => putProcedure(reqBody, patientId));
};

const putTelecom = (reqBody: string[], patientId: string) => {
  const url = ApiEndpointsEnum.PUT_TELECOM.replace('{patientId}', patientId);
  return API.put<ProcedureReqResponse[]>(url, reqBody);
};

const usePutTelecom = (reqBody: string[], patientId: string) => {
  return useMutation(['put-telecom'], () => putTelecom(reqBody, patientId));
};

export {
  useGetAllergyIntolerance,
  useGetCondition,
  useGetFamilyMemberHistory,
  useGetMedicationStatement,
  useGetProcedure,
  useGetTelecom,
  getAllergyIntolerance,
  getCondition,
  getFamilyMemberHistory,
  getMedicationStatement,
  getProcedure,
  useGetMedicalData,
  usePutAllergyIntolerance,
  usePutCondition,
  usePutFamilyMemberHistory,
  usePutMedicationStatement,
  usePutProcedure,
  usePutTelecom,
};
