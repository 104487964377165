import React, {useState, useEffect} from 'react';
import {
  Platform,
  Image,
  View,
  ScrollView,
  TouchableWithoutFeedback,
} from 'react-native';
import {SafeAreaView} from 'react-native-safe-area-context';
import {Overlay, makeStyles} from 'rne-netzon';
import {colorPalette} from '../../../core/config/color.config';
import {useToast} from 'react-native-fast-toast';

import {useNavigation} from '@react-navigation/native';
import {NavigationKey} from '../../navigations/config';

import {useForm, Controller} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';

import {LogoContrast} from './../../images/Image';

import {SVGIcon, RNEButton, RNEText, RNEInput} from '../../components';

import {
  ForgotPasswordReqBody,
  ResetPasswordReqBodyExt,
} from '../../server/types/password.types';

import {
  useForgotPassword,
  useResetPassword,
} from './../../server/react-query/usePassword';

import {ResetPasswordSuccessModal} from '../../views';

import useStores from '../../stores/useStores';

const inputContainerStyle = {
  backgroundColor: colorPalette.white,
};

const inputStyleWhite = {
  color: colorPalette.white,
};

const loginTextLeftPadding = {
  padding: 12,
};

const schema: yup.SchemaOf<ResetPasswordReqBodyExt> = yup.object({
  resetCode: yup.string().trim().required('This field is required.'),
  password: yup
    .string()
    .trim()
    .required('This field is required.')
    .min(6, 'Min of 6 characters.'),
  confirmPassword: yup
    .string()
    .trim()
    .required('This field is required.')
    .min(6, 'Min of 6 characters.')
    .oneOf([yup.ref('password'), null], 'Passwords do not match.'),
});

const ResetPasswordScreen = () => {
  const navigation = useNavigation();
  const webStyles = useWebStyles();
  const sharedStyles = useSharedStyles();
  const mobileStyles = useMobileStyles();
  const {emailStore} = useStores();
  const {
    control,
    handleSubmit,
    getValues,
    reset,
    setValue,
    formState: {errors, isValid},
  } = useForm<ResetPasswordReqBodyExt>({
    mode: 'all',
    defaultValues: {
      resetCode: '',
      password: '',
      confirmPassword: '',
    },
    resolver: yupResolver(schema),
  });

  const toast = useToast();

  const mutation = useResetPassword(getValues());

  const forgotPasswordMutation = useForgotPassword({
    email: emailStore.email,
  });

  const [showDialog, setShowDialog] = useState(false);

  const goToLogin = () => {
    navigation.navigate(NavigationKey.LOGIN_SCREEN);
    emailStore.removeEmail();
    setShowDialog(false);
  };

  const resendCode = async () => {
    try {
      await forgotPasswordMutation.mutateAsync();
      forgotPasswordMutation.reset();
      toast?.show('Code resent!', {type: 'normal'});
    } catch (error) {
      if (error?.data?.error) {
        toast?.show(error?.data?.error, {type: 'danger'});
      }
    }
  };

  const resetPassword = () => {
    handleSubmit(onSubmit)();
  };

  const onSubmit = async () => {
    if (isValid) {
      try {
        const data = await mutation.mutateAsync();

        if (!data) {
          reset();
          mutation.reset();
          setShowDialog(true);
        }
      } catch (error) {
        console.log('error', error);
        if (error?.data?.error) {
          toast?.show(error?.data?.error, {type: 'danger'});
        }
      }
    }
  };

  return (
    <SafeAreaView style={sharedStyles.root}>
      <Overlay
        isVisible={showDialog}
        overlayStyle={sharedStyles.overlayContainer}>
        <ResetPasswordSuccessModal onConfirm={goToLogin} />
      </Overlay>
      {Platform.OS === 'web' ? (
        <View style={webStyles.mainContainer}>
          <TouchableWithoutFeedback onPress={goToLogin}>
            <View accessibilityRole="button">
              <Image style={webStyles.logo} source={LogoContrast} />
            </View>
          </TouchableWithoutFeedback>
          <View style={webStyles.contentWrapper}>
            <RNEText
              label="Reset your password"
              variant="header"
              weight="bold"
              color="white"
              align="center"
            />

            <RNEText
              label="We've sent the reset password code to your email address."
              variant="body"
              color="white"
              align="center"
            />

            <View style={webStyles.inputGroupContainer}>
              <View style={webStyles.inputContainer}>
                <Controller
                  control={control}
                  render={({field: {onChange, onBlur, value}}) => (
                    <RNEInput
                      placeholder="Reset Code"
                      keyboardType="numeric"
                      inputContainerStyle={inputContainerStyle}
                      value={value}
                      onChangeText={text => onChange(text)}
                      errorMessage={errors.resetCode?.message}
                    />
                  )}
                  name="resetCode"
                />
              </View>
              <View style={webStyles.inputContainer}>
                <Controller
                  control={control}
                  render={({field: {onChange, onBlur, value}}) => (
                    <RNEInput
                      placeholder="New Password"
                      secureTextEntry
                      inputContainerStyle={inputContainerStyle}
                      value={value}
                      onChangeText={text => onChange(text)}
                      errorMessage={errors.password?.message}
                    />
                  )}
                  name="password"
                />
              </View>
              <View style={webStyles.inputContainer}>
                <Controller
                  control={control}
                  render={({field: {onChange, onBlur, value}}) => (
                    <RNEInput
                      placeholder="Confirm Password"
                      secureTextEntry
                      inputContainerStyle={inputContainerStyle}
                      value={value}
                      onChangeText={text => onChange(text)}
                      onSubmitEditing={resetPassword}
                      errorMessage={errors.confirmPassword?.message}
                    />
                  )}
                  name="confirmPassword"
                />
              </View>
            </View>

            <View style={webStyles.actionContainer}>
              <RNEButton
                title="Reset Password"
                color="secondary"
                onPress={resetPassword}
                disabled={!isValid}
                loading={mutation.isLoading}
              />
            </View>

            <View style={webStyles.footerActions}>
              <View style={mobileStyles.footerAction}>
                <RNEText
                  label="Resend Code"
                  variant="button"
                  onPress={resendCode}
                  color="white"
                />
              </View>
              <View style={mobileStyles.footerAction}>
                <RNEText
                  label="You can only resend code every after one minute"
                  color="white"
                  variant="button"
                  align="center"
                />
              </View>
            </View>
          </View>
        </View>
      ) : (
        <ScrollView>
          <View style={mobileStyles.container}>
            <TouchableWithoutFeedback onPress={goToLogin}>
              <View style={mobileStyles.backContainer}>
                <SVGIcon name="left-white" />
                <RNEText
                  label="Log In"
                  variant="body"
                  color="white"
                  style={loginTextLeftPadding}
                />
              </View>
            </TouchableWithoutFeedback>
            <View style={mobileStyles.content}>
              <RNEText
                label="Reset your password"
                variant="header"
                weight="bold"
                color="white"
                align="center"
              />
              <View style={mobileStyles.subheader}>
                <RNEText
                  label="We've sent you the reset password code to your email address."
                  variant="subheader"
                  color="grey"
                  align="center"
                />
              </View>

              <View style={mobileStyles.inputGroupContainer}>
                <View style={mobileStyles.inputContainer}>
                  <Controller
                    control={control}
                    render={({field: {onChange, onBlur, value}}) => (
                      <RNEInput
                        placeholder="Reset Code"
                        value={value}
                        onChangeText={text => onChange(text)}
                        errorMessage={errors.resetCode?.message}
                        inputStyle={inputStyleWhite}
                      />
                    )}
                    name="resetCode"
                  />
                </View>
                <View style={mobileStyles.inputContainer}>
                  <Controller
                    control={control}
                    render={({field: {onChange, onBlur, value}}) => (
                      <RNEInput
                        placeholder="New Password"
                        secureTextEntry
                        value={value}
                        onChangeText={text => onChange(text)}
                        errorMessage={errors.password?.message}
                        inputStyle={inputStyleWhite}
                      />
                    )}
                    name="password"
                  />
                </View>
                <View style={mobileStyles.inputContainer}>
                  <Controller
                    control={control}
                    render={({field: {onChange, onBlur, value}}) => (
                      <RNEInput
                        placeholder="Confirm Password"
                        secureTextEntry
                        value={value}
                        onChangeText={text => onChange(text)}
                        errorMessage={errors.confirmPassword?.message}
                        inputStyle={inputStyleWhite}
                      />
                    )}
                    name="confirmPassword"
                  />
                </View>
              </View>

              <View style={mobileStyles.actionContainer}>
                <RNEButton
                  title="Reset Password"
                  onPress={resetPassword}
                  color="secondary"
                  disabled={!isValid}
                  loading={mutation.isLoading}
                />
              </View>

              <View style={mobileStyles.footerActions}>
                <View style={mobileStyles.footerAction}>
                  <RNEText
                    label="Resend Code"
                    variant="button"
                    onPress={resendCode}
                    color="white"
                  />
                </View>
                <View style={mobileStyles.footerAction}>
                  <RNEText
                    label="You can only resend code every after one minute"
                    color="white"
                    variant="body"
                    align="center"
                  />
                </View>
              </View>
            </View>
          </View>
        </ScrollView>
      )}
    </SafeAreaView>
  );
};

export default ResetPasswordScreen;

const useWebStyles = makeStyles({
  mainContainer: {
    width: '100%',
    paddingTop: 32,
    paddingBottom: 32,
    paddingLeft: 96,
    paddingRight: 96,
  },
  logo: {
    width: 294,
    height: 94,
  },
  inputGroupContainer: {
    width: '100%',
    marginTop: 32,
  },
  inputContainer: {
    marginBottom: 24,
    width: '70%',
    alignSelf: 'center',
  },
  input: {
    backgroundColor: 'white',
  },
  actionContainer: {
    marginTop: 32,
    alignSelf: 'center',
    width: '70%',
  },
  contentWrapper: {
    maxWidth: 550,
    alignSelf: 'center',
    width: '100%',
    marginTop: 96,
  },
  footerActions: {
    width: '70%',
    alignSelf: 'center',
    alignItems: 'center',
    marginTop: 28,
  },
  footerAction: {
    marginBottom: 20,
  },
  overlayContainer: {
    borderRadius: 20,
  },
});

const useMobileStyles = makeStyles({
  container: {
    flex: 1,
    height: '100%',
    width: '100%',
    paddingHorizontal: 24,
  },
  backContainer: {
    marginBottom: 96,
    alignItems: 'center',
    flexDirection: 'row',
  },
  content: {
    flex: 1,
    width: '100%',
    paddingLeft: 20,
    paddingRight: 20,
  },
  subheader: {
    marginTop: 20,
  },
  inputGroupContainer: {
    width: '100%',
    marginTop: 52,
  },
  inputContainer: {
    marginBottom: 28,
  },
  actionContainer: {
    marginTop: 16,
    alignSelf: 'center',
    width: 150,
  },
  footerActions: {
    width: '70%',
    alignSelf: 'center',
    alignItems: 'center',
    marginTop: 12,
  },
  footerAction: {
    marginBottom: 20,
  },
  overlayContainer: {
    borderRadius: 20,
  },
});

const useSharedStyles = makeStyles({
  root: {
    height: '100%',
    flexDirection: 'row',
    width: '100%',
    backgroundColor: colorPalette.accent,
  },
  overlayContainer: {
    borderRadius: 20,
  },
});
