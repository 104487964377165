import React from 'react';
import {createStackNavigator} from '@react-navigation/stack';

import {NavigationKey} from '../config/navigation.config';
import {NavigationTitle} from '../config/navigationTitle';
import {OnboardingEntryScreen, OnboardingSliderScreen} from '../../screens';

const {Navigator, Screen} = createStackNavigator();

const OnboardingStackNavigator = () => {
  return (
    <Navigator
      screenOptions={{
        headerShown: false,
      }}>
      <Screen
        options={{title: NavigationTitle.ONBOARDING_SCREEN_ENTRY}}
        name={NavigationKey.ONBOARDING_SCREEN_ENTRY}
        component={OnboardingEntryScreen}
      />
      <Screen
        options={{title: NavigationTitle.ONBOARDING_SCREEN_SLIDER}}
        name={NavigationKey.ONBOARDING_SCREEN_SLIDER}
        component={OnboardingSliderScreen}
      />
    </Navigator>
  );
};

export default OnboardingStackNavigator;
