import React, {useEffect, useLayoutEffect, useState} from 'react';
import {Platform} from 'react-native';
import {createMaterialTopTabNavigator} from '@react-navigation/material-top-tabs';

import {NavigationKey} from '../config/navigation.config';

import {ConsultationMobileListScreen} from '../../screens';

import {ConsultationTabBar, HeaderIconRight} from '../../views';
import {
  getFocusedRouteNameFromRoute,
  useNavigation,
  useRoute,
} from '@react-navigation/native';

const {Navigator, Screen} = createMaterialTopTabNavigator();

const ConsultationTabNavigator = () => {
  const route = useRoute();
  const navigation = useNavigation();

  const [currentTab, setCurrentTab] = useState('ongoing');

  useLayoutEffect(() => {
    if (Platform.OS !== 'web') {
      navigation.setOptions({
        headerRight: () => (
          <HeaderIconRight
            iconName="search-white-80"
            onClick={() => {
              navigation.navigate(NavigationKey.CONSULTATION_SEARCH, {
                currentTab: currentTab,
              });
            }}
          />
        ),
      });
    }
  }, [navigation, currentTab]);

  useEffect(() => {
    const routeName = getFocusedRouteNameFromRoute(route);
    if (routeName) {
      setCurrentTab(routeName as NavigationKey);
    }
  }, [route]);

  return (
    <Navigator tabBar={props => <ConsultationTabBar {...props} />}>
      <Screen
        name={NavigationKey.CONSULTATION_TAB_ONGOING}
        component={() => <ConsultationMobileListScreen tabType="ongoing" />}
        options={{
          title: 'Ongoing',
        }}
      />
      <Screen
        name={NavigationKey.CONSULTATION_TAB_PENDING}
        component={() => <ConsultationMobileListScreen tabType="pending" />}
        options={{
          title: 'Pending',
        }}
      />
      <Screen
        name={NavigationKey.CONSULTATION_TAB_HISTORY}
        component={() => <ConsultationMobileListScreen tabType="history" />}
        options={{
          title: 'History',
        }}
      />
    </Navigator>
  );
};

export default ConsultationTabNavigator;
