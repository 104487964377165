import React, {useState, createContext, useContext} from 'react';
import {
  useCloseRoom,
  useCreateRoom,
  useGenerateToken,
} from '../../server/react-query/useVideoCall';
import {CodedConversationMessages} from '../../server/types/conversation.types';
import useConversation from '../conversation/useConversation';

type IVideoCallContext = {
  connected: boolean;
  isAudioEnabled: boolean;
  receiverHasEntered: boolean;
  endingCall: boolean;
  token: string;
  generateToken: (subjectId: string, roomId: string) => any;
  createRoom: (
    subjectId: string,
    roomId: string,
    fromInvitation?: boolean,
  ) => any;
  closeRoom: (subjectId: string, roomId: string) => any;
  setAudioEnabled: (audioEnabled: boolean) => any;
  setReceiverEntered: (receiverEntered: boolean) => any;
  setConnectivity: (connected: boolean) => any;
  resetValues: () => any;
};

export const VideoCallContext = createContext<IVideoCallContext>({
  connected: false,
  isAudioEnabled: true,
  receiverHasEntered: false,
  endingCall: false,
  token: '',
  generateToken: () => null,
  createRoom: () => null,
  closeRoom: () => null,
  setAudioEnabled: () => null,
  setReceiverEntered: () => null,
  setConnectivity: () => null,
  resetValues: () => null,
});

export const VideoCallProvider = ({children}: any) => {
  const [connected, setConnected] = useState(false);
  const [isAudioEnabled, setIsAudioEnabled] = useState(true);
  const [receiverHasEntered, setReceiverHasEntered] = useState(false);
  const [endingCall, setEndingCall] = useState(false);
  const [token, setToken] = useState('');

  const {initialized, conversation} = useConversation();

  const generateTokenMutation = useGenerateToken();
  const createRoomMutation = useCreateRoom();
  const closeRoomMutation = useCloseRoom();

  const generateToken = async (subjectId: string, roomId: string) => {
    try {
      if (!token) {
        const response = await generateTokenMutation.mutateAsync({
          subjectId: subjectId,
          roomId: roomId,
        });
        setToken(response.token);
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const createRoom = async (
    subjectId: string,
    roomId: string,
    fromInvitation?: boolean,
  ) => {
    try {
      await createRoomMutation.mutateAsync({
        subjectId: subjectId,
        roomId: roomId,
      });

      if (initialized && conversation) {
        conversation.sendMessage(
          fromInvitation
            ? CodedConversationMessages.VIDEO_CALL_ACCEPT
            : CodedConversationMessages.VIDEO_CALL_TRIGGER,
        );
      }

      generateToken(subjectId, roomId);
    } catch (error) {
      console.log('error', error);
    }
  };

  const closeRoom = async (subjectId: string, roomId: string) => {
    setEndingCall(true);
    try {
      await closeRoomMutation.mutateAsync({
        subjectId: subjectId,
        roomId: roomId,
      });
    } catch (error) {
      console.log('error', error);
    }

    if (initialized && conversation) {
      conversation.sendMessage('The video call ended.');
    }
  };

  const setAudioEnabled = (audioEnabled: boolean) => {
    setIsAudioEnabled(audioEnabled);
  };

  const setReceiverEntered = (receiverEntered: boolean) => {
    setReceiverHasEntered(receiverEntered);
  };

  const setConnectivity = (isConnected: boolean) => {
    setConnected(isConnected);
  };

  const resetValues = () => {
    setToken('');
    setReceiverEntered(false);
    setEndingCall(false);
    setConnected(false);
  };

  return (
    <VideoCallContext.Provider
      value={{
        connected,
        isAudioEnabled,
        receiverHasEntered,
        endingCall,
        token,
        generateToken,
        createRoom,
        closeRoom,
        setAudioEnabled,
        setReceiverEntered,
        setConnectivity,
        resetValues,
      }}>
      {children}
    </VideoCallContext.Provider>
  );
};

const useVideoCall = () => {
  const _context = useContext(VideoCallContext);

  if (!_context) {
    throw new Error('You have forgotten to use VideoCallProvider.');
  }
  return _context;
};

export default useVideoCall;
