import React, {memo, useState, useEffect} from 'react';
import {Platform, View} from 'react-native';

import {makeStyles, Overlay} from 'rne-netzon';
import {format} from 'date-fns';

import {RNEText, RNEButton, Pagination, usePagination} from '../../components';
import {colorPalette} from '../../../core/config/color.config';

import {
  AdminPatientProfile,
  PatientStatus,
} from '../../server/types/patient-profile.types';

import {useGetAdminPatientProfiles} from './../../server/react-query/usePatientProfile';
import {PatientTableSortField} from '../../server/types/table.types';

import PatientProfileVerifiedModal from '../modal/PatientProfileVerifiedModal';
import ContentScrollView from '../scroll/ContentScrollView';
import TableHeaderRow from '../tables/TableHeaderRow';
import TableHeaderCell from '../tables/TableHeaderCell';
import TableContentCell from '../tables/TableContentCell';
import TableBodyRow from '../tables/TableBodyRow';
import TableBodyCell from '../tables/TableBodyCell';

interface AdminPatientVerifiedTabProps {
  searchKey?: string;
}

const AdminPatientVerifiedTab = (props: AdminPatientVerifiedTabProps) => {
  const styles = useStyles();
  const {
    pageNumber,
    pageSize,
    totalPage,
    setPageData,
    goBack,
    goNext,
    goToPage,
    canGoBack,
    canGoNext,
  } = usePagination();

  const [showViewDialog, setShowViewDialog] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState<
    AdminPatientProfile | undefined
  >();
  const [sortBy, setSortBy] = useState<PatientTableSortField>(
    PatientTableSortField.CREATED_ON,
  );
  const [ascending, setAscending] = useState<boolean>(true);

  const {data, isLoading, isFetching, refetch} = useGetAdminPatientProfiles({
    searchKey: props.searchKey,
    status: PatientStatus.VERIFIED,
    pageNumber: pageNumber,
    sortBy: sortBy,
    ascending: ascending,
  });

  const viewPatientProfile = (patient: AdminPatientProfile) => {
    setSelectedPatient(patient);
    setShowViewDialog(true);
  };

  const onColumnClick = (column: PatientTableSortField) => {
    if (!isFetching) {
      if (sortBy !== column) {
        setSortBy(column);
        setAscending(true);
      } else {
        setAscending(!ascending);
      }
    }
  };

  useEffect(() => {
    setPageData({
      pageNumber: data?.pageNumber,
      pageSize: data?.pageSize,
      totalPage: data?.totalPages,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber]);

  useEffect(() => {
    if (pageNumber !== 1) {
      goToPage(1);
    } else {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.searchKey]);

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy, ascending]);

  return (
    <View style={styles.root}>
      {selectedPatient && (
        <>
          <Overlay
            isVisible={showViewDialog}
            overlayStyle={styles.overlayContainer}
            onBackdropPress={() => {
              setSelectedPatient(undefined);
              setShowViewDialog(false);
            }}>
            <PatientProfileVerifiedModal
              onClose={() => {
                setSelectedPatient(undefined);
                setShowViewDialog(false);
              }}
              userId={selectedPatient.userId}
            />
          </Overlay>
        </>
      )}

      <View style={styles.scrollView}>
        <ContentScrollView isWeb={Platform.OS === 'web'}>
          <TableHeaderRow>
            <TableHeaderCell>
              <TableContentCell
                label="User Name"
                ascending={ascending}
                active={sortBy === PatientTableSortField.LASTNAME}
                onPress={() => onColumnClick(PatientTableSortField.LASTNAME)}
              />
            </TableHeaderCell>
            <TableHeaderCell>
              <TableContentCell
                label="City Address"
                ascending={ascending}
                active={sortBy === PatientTableSortField.LOCATION}
                onPress={() => onColumnClick(PatientTableSortField.LOCATION)}
              />
            </TableHeaderCell>
            <TableHeaderCell>
              <TableContentCell
                label="Account Created"
                ascending={ascending}
                active={sortBy === PatientTableSortField.CREATED_ON}
                onPress={() => onColumnClick(PatientTableSortField.CREATED_ON)}
              />
            </TableHeaderCell>
            <TableHeaderCell>
              <TableContentCell
                label="Sub Profiles"
                ascending={ascending}
                active={sortBy === PatientTableSortField.PROFILE_COUNT}
                onPress={() =>
                  onColumnClick(PatientTableSortField.PROFILE_COUNT)
                }
              />
            </TableHeaderCell>
            <TableHeaderCell>
              <TableContentCell label="" hideSort={true} />
            </TableHeaderCell>
          </TableHeaderRow>

          {data &&
            data.data.map(profile => (
              <TableBodyRow key={profile.userId}>
                <TableBodyCell>
                  <RNEText
                    label={`${profile.humanName.family}, ${profile.humanName.given[0]}`}
                    variant="button"
                    numberOfLines={2}
                  />
                </TableBodyCell>
                <TableBodyCell>
                  <RNEText label={profile.location || '--'} variant="button" />
                </TableBodyCell>
                <TableBodyCell>
                  <RNEText
                    label={format(new Date(profile.createdOn), 'MM/dd/yyyy')}
                    variant="button"
                  />
                </TableBodyCell>
                <TableBodyCell>
                  <RNEText
                    label={profile.profileCount.toString()}
                    variant="button"
                  />
                </TableBodyCell>
                <TableBodyCell justify="flex-end">
                  <View style={styles.buttonStyle}>
                    <RNEButton
                      title="View Profile"
                      variant="outlined"
                      color="warning"
                      onPress={() => viewPatientProfile(profile)}
                    />
                  </View>
                </TableBodyCell>
              </TableBodyRow>
            ))}

          {isFetching && data && data.data.length > 0 && (
            <View style={styles.fetchingContainer}>
              <RNEButton
                height={24}
                loading={isFetching}
                type="clear"
                loadingProps={loadingStyle}
                buttonStyle={buttonStyle}
              />
            </View>
          )}

          {!isLoading && data && data.data.length === 0 && (
            <View style={styles.placeholderContainer}>
              <RNEText label="No verified account(s)" color="grey" />
            </View>
          )}

          {isLoading && (
            <RNEButton
              height={24}
              loading={isLoading}
              type="clear"
              loadingProps={loadingStyle}
              buttonStyle={buttonStyle}
            />
          )}

          {isFetching && data && data.data.length === 0 && (
            <View style={styles.fetchingContainer}>
              <RNEButton
                height={24}
                loading={isFetching}
                type="clear"
                loadingProps={loadingStyle}
                buttonStyle={buttonStyle}
              />
            </View>
          )}
        </ContentScrollView>

        {data && data.data.length > 0 && (
          <View style={styles.pagination}>
            <Pagination
              pageNumber={pageNumber}
              totalPage={totalPage}
              goToPage={goToPage}
              goBack={goBack}
              goNext={goNext}
              canGoBack={canGoBack}
              canGoNext={canGoNext}
              freeze={isFetching}
            />
          </View>
        )}
      </View>
    </View>
  );
};

const buttonStyle = {
  paddingHorizontal: 0,
  marginTop: 32,
};

const loadingStyle = {
  color: colorPalette.primary,
};

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
  },
  buttonStyle: {
    width: 150,
    marginLeft: 16,
  },
  placeholderContainer: {
    marginTop: 32,
    alignItems: 'center',
  },
  overlayContainer: {
    borderRadius: 20,
    padding: 0,
  },
  scrollView: {
    flex: 1,
    height: '100%',
    maxHeight: Platform.OS === 'web' ? '65vh' : 'auto',
    position: 'relative',
  },
  pagination: {
    marginTop: 16,
    alignItems: 'flex-end',
  },
  fetchingContainer: {
    position: 'absolute',
    alignItems: 'center',
    top: 120,
    left: 0,
    right: 0,
  },
}));

export default memo(AdminPatientVerifiedTab);
