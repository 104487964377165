import React from 'react';
import {Platform} from 'react-native';
import {createStackNavigator} from '@react-navigation/stack';

import {colorPalette} from '../../../core/config/color.config';

import {NavigationKey} from '../config/navigation.config';
import {NavigationTitle} from '../config/navigationTitle';

import {TermsConditionsScreen} from '../../screens';
import {HeaderBackLeft} from '../../views';

const {Navigator, Screen} = createStackNavigator();

const mobileBackHeaderNoTitleOptions = {
  title: '',
  headerStyle: {
    elevation: 0,
    shadowOpacity: 0,
  },
  headerTintColor: colorPalette.blackLight,
  headerTitleStyle: {
    fontFamily: 'Quicksand-Regular',
    fontSize: 14,
    paddingLeft: 28,
  },
  headerLeft: () => <HeaderBackLeft />,
};

const PatientTandCStackWebNavigator = () => {
  return (
    <Navigator>
      <Screen
        name={NavigationKey.PATIENT_TANDC}
        component={TermsConditionsScreen}
        options={{headerShown: false, title: NavigationTitle.TANDC_SCREEN}}
      />
    </Navigator>
  );
};

const PatientTandCStackMobileNavigator = () => {
  return (
    <Navigator>
      <Screen
        name={NavigationKey.PATIENT_TANDC}
        component={TermsConditionsScreen}
        options={{...mobileBackHeaderNoTitleOptions}}
      />
    </Navigator>
  );
};

export default Platform.OS === 'web'
  ? PatientTandCStackWebNavigator
  : PatientTandCStackMobileNavigator;
