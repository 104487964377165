import AsyncStorage from '@react-native-async-storage/async-storage';
import {LoginReqResponse} from '../types/authentication.types';

export const getJWT = async () => {
  const itemData = await AsyncStorage.getItem('AuthStore');

  if (itemData) {
    const authStoreData = JSON.parse(itemData);

    if (authStoreData && authStoreData.auth) {
      const authData = authStoreData.auth as LoginReqResponse;
      return authData.token;
    }
  }

  return null;
};
