import {makeAutoObservable, observable, action, computed} from 'mobx';
import AsyncStorage from '@react-native-async-storage/async-storage';

import {persistStore} from '../utils/mobx.utils';

import {LoginReqResponse} from './../server/types/authentication.types';

export class AuthStore {
  auth: LoginReqResponse | null = null;
  onboardingDone: boolean = false;
  authenticated: boolean = false;

  constructor() {
    makeAutoObservable(this, {
      auth: observable,
      onboardingDone: observable,
      authenticated: observable,
      setAuth: action,
      login: action,
      logout: action,
      loggedIn: computed,
      isOnboardingDone: computed,
      userRole: computed,
    });

    persistStore(
      this,
      ['auth', 'authenticated', 'onboardingDone'],
      'AuthStore',
    );
  }

  setAuth(authData: LoginReqResponse) {
    this.auth = authData;
  }

  login() {
    this.authenticated = true;
  }

  async logout() {
    this.auth = null;
    this.authenticated = false;
    AsyncStorage.removeItem('ConversationStore');
  }

  completeOnboarding() {
    this.onboardingDone = true;
  }

  get loggedIn() {
    return this.authenticated;
  }

  get isOnboardingDone(): boolean {
    return this.onboardingDone;
  }

  get authData() {
    return this.auth;
  }

  get userRole() {
    return this.auth?.user.userRole;
  }
}
