import AlliedHealthProfessionalsWhite from './../../../assets/icons/svg/allied-health-professionals-white.svg';
import AlliedHealthProfessionals from './../../../assets/icons/svg/allied-health-professionals.svg';
import ArrowDown from './../../../assets/icons/svg/arrow-down.svg';
import Attachment from './../../../assets/icons/svg/attachment.svg';
import Calendar from './../../../assets/icons/svg/calendar.svg';
import Call25 from './../../../assets/icons/svg/call-25.svg';
import Call27 from './../../../assets/icons/svg/call-27.svg';
import Call28 from './../../../assets/icons/svg/call-28.svg';
import Camera29 from './../../../assets/icons/svg/camera-29.svg';
import Camera85 from './../../../assets/icons/svg/camera-85.svg';
import CameraView from './../../../assets/icons/svg/camera-view.svg';
import CameraWhite from './../../../assets/icons/svg/camera-white.svg';
import CancelWhite from './../../../assets/icons/svg/cancel-white.svg';
import CheckWhite from './../../../assets/icons/svg/check-white.svg';
import Check from './../../../assets/icons/svg/check.svg';
import CheckboxChecked from './../../../assets/icons/svg/checkbox-checked.svg';
import CheckboxUnchecked from './../../../assets/icons/svg/checkbox-unchecked.svg';
import CloseGray70 from './../../../assets/icons/svg/close-gray-70.svg';
import CloseGray71 from './../../../assets/icons/svg/close-gray-71.svg';
import Close from './../../../assets/icons/svg/close.svg';
import ComplementaryMedicineWhite from './../../../assets/icons/svg/complementary-medicine-white.svg';
import ComplementaryMedicine from './../../../assets/icons/svg/complementary-medicine.svg';
import ConsultationRequest from './../../../assets/icons/svg/consultation-request.svg';
import Dentists from './../../../assets/icons/svg/dentists.svg';
import DownloadDown from './../../../assets/icons/svg/download-down.svg';
import DownloadLeft from './../../../assets/icons/svg/download-left.svg';
import EasyToUse from './../../../assets/icons/svg/easy-to-use.svg';
import EmailWhite from './../../../assets/icons/svg/email-white.svg';
import Email from './../../../assets/icons/svg/email.svg';
import Facebook from './../../../assets/icons/svg/facebook.svg';
import FaqWhite from './../../../assets/icons/svg/faq-white.svg';
import Faq from './../../../assets/icons/svg/faq.svg';
import FirstAidWhite from './../../../assets/icons/svg/first-aid-white.svg';
import Fullscreen from './../../../assets/icons/svg/fullscreen.svg';
import Gender from './../../../assets/icons/svg/gender.svg';
import GeneralPractitioner from './../../../assets/icons/svg/general-practitioner.svg';
import Globe from './../../../assets/icons/svg/globe.svg';
import HCIconAllergies from './../../../assets/icons/svg/hc-icon-allergies.svg';
import HCIconFamilyCondition from './../../../assets/icons/svg/hc-icon-family-condition.svg';
import HCIconHospitalization from './../../../assets/icons/svg/hc-icon-hospitalization.svg';
import HCIconMaintenance from './../../../assets/icons/svg/hc-icon-maintenance.svg';
import HCIconMedicalCondition from './../../../assets/icons/svg/hc-icon-medical-condition.svg';
import HCIconPersonalAndSocial from './../../../assets/icons/svg/hc-icon-personal-and-social.svg';
import HideVideo from './../../../assets/icons/svg/hide-video.svg';
import Hide from './../../../assets/icons/svg/hide.svg';
import HomeWhite from './../../../assets/icons/svg/home-white.svg';
import Home from './../../../assets/icons/svg/home.svg';
import Information from './../../../assets/icons/svg/information.svg';
import Instagram from './../../../assets/icons/svg/instagram.svg';
import LeftBlack from './../../../assets/icons/svg/left-black.svg';
import LeftBlue from './../../../assets/icons/svg/left-blue.svg';
import LeftGray from './../../../assets/icons/svg/left-gray.svg';
import LeftWhite from './../../../assets/icons/svg/left-white.svg';
import Left from './../../../assets/icons/svg/left.svg';
import Lock from './../../../assets/icons/svg/lock.svg';
import Map from './../../../assets/icons/svg/map.svg';
import MedicalSpecialistCross from './../../../assets/icons/svg/medical-specialist-cross.svg';
import MedicalSpecialistStar from './../../../assets/icons/svg/medical-specialist-star.svg';
import MenuDarkBlue from './../../../assets/icons/svg/menu-dark-blue.svg';
import MenuLightBlue from './../../../assets/icons/svg/menu-light-blue.svg';
import MenuWhite57 from './../../../assets/icons/svg/menu-white-57.svg';
import MenuWhite59 from './../../../assets/icons/svg/menu-white-59.svg';
import MenuYellow from './../../../assets/icons/svg/menu-yellow.svg';
import Menu from './../../../assets/icons/svg/menu.svg';
import Message from './../../../assets/icons/svg/message.svg';
import Minus from './../../../assets/icons/svg/minus.svg';
import Mute09 from './../../../assets/icons/svg/mute-09.svg';
import Mute5 from './../../../assets/icons/svg/mute-5.svg';
import NotificationWhite from './../../../assets/icons/svg/notification-white.svg';
import Notification from './../../../assets/icons/svg/notification.svg';
import OptionGray from './../../../assets/icons/svg/option-gray.svg';
import OptionWhite from './../../../assets/icons/svg/option-white.svg';
import Option from './../../../assets/icons/svg/option.svg';
import Pay from './../../../assets/icons/svg/pay.svg';
import Pen from './../../../assets/icons/svg/pen.svg';
import PenWhite from './../../../assets/icons/svg/pen-white.svg';
import Plus from './../../../assets/icons/svg/plus.svg';
import Prescription from './../../../assets/icons/svg/prescription.svg';
import Price from './../../../assets/icons/svg/price.svg';
import ProfileComplete from './../../../assets/icons/svg/profile-complete.svg';
import ProfileIncomplete from './../../../assets/icons/svg/profile-incomplete.svg';
import Receipt from './../../../assets/icons/svg/receipt.svg';
import RequestCancelled from './../../../assets/icons/svg/request-cancelled.svg';
import RequestDenied from './../../../assets/icons/svg/request-denied.svg';
import RightWhite from './../../../assets/icons/svg/right-white.svg';
import RightYellow from './../../../assets/icons/svg/right-yellow.svg';
import Right from './../../../assets/icons/svg/right.svg';
import SearchWhite56 from './../../../assets/icons/svg/search-white-56.svg';
import SearchWhite80 from './../../../assets/icons/svg/search-white-80.svg';
import Search from './../../../assets/icons/svg/search.svg';
import Security from './../../../assets/icons/svg/security.svg';
import Selection from './../../../assets/icons/svg/selection.svg';
import SendWhite from './../../../assets/icons/svg/send-white.svg';
import Send from './../../../assets/icons/svg/send.svg';
import SettingWhite from './../../../assets/icons/svg/setting-white.svg';
import Setting from './../../../assets/icons/svg/setting.svg';
import Share from './../../../assets/icons/svg/share.svg';
import StarGray from './../../../assets/icons/svg/star-gray.svg';
import StarYellow from './../../../assets/icons/svg/star-yellow.svg';
import Star from './../../../assets/icons/svg/star.svg';
import Stethoscope from './../../../assets/icons/svg/stethoscope.svg';
import TempPaymentBlue from './../../../assets/icons/svg/temp-payment-blue.svg';
import TempPaymentWhite from './../../../assets/icons/svg/temp-payment-white.svg';
import Time from './../../../assets/icons/svg/time.svg';
import Trash from './../../../assets/icons/svg/trash.svg';
import TrustedByDoctors from './../../../assets/icons/svg/trusted-by-doctors.svg';
import Unmute04 from './../../../assets/icons/svg/unmute-04.svg';
import Unmute08 from './../../../assets/icons/svg/unmute-08.svg';
import Upload from './../../../assets/icons/svg/upload.svg';
import UserConfirmed from './../../../assets/icons/svg/user-confirmed.svg';
import UserFriendly from './../../../assets/icons/svg/user-friendly.svg';
import UserWhite from './../../../assets/icons/svg/user-white.svg';
import User from './../../../assets/icons/svg/user.svg';
import Video from './../../../assets/icons/svg/video.svg';
import View from './../../../assets/icons/svg/view.svg';
import WaiverWhite from './../../../assets/icons/svg/waiver-white.svg';
import Waiver from './../../../assets/icons/svg/waiver.svg';

const iconListConst = [
  'allied-health-professionals-white',
  'allied-health-professionals',
  'arrow-down',
  'attachment',
  'calendar',
  'call-25',
  'call-27',
  'call-28',
  'camera-29',
  'camera-85',
  'camera-view',
  'camera-white',
  'cancel-white',
  'check-white',
  'check',
  'checkbox-checked',
  'checkbox-unchecked',
  'close-gray-70',
  'close-gray-71',
  'close',
  'complementary-medicine-white',
  'complementary-medicine',
  'consultation-request',
  'dentists',
  'download-down',
  'download-left',
  'easy-to-use',
  'email-white',
  'email',
  'facebook',
  'faq-white',
  'faq',
  'first-aid-white',
  'fullscreen',
  'gender',
  'general-practitioner',
  'globe',
  'hc-icon-allergies',
  'hc-icon-family-condition',
  'hc-icon-hospitalization',
  'hc-icon-maintenance',
  'hc-icon-medical-condition',
  'hc-icon-personal-and-social',
  'hide-video',
  'hide',
  'home-white',
  'home',
  'information',
  'instagram',
  'left-black',
  'left-blue',
  'left-gray',
  'left-white',
  'left',
  'lock',
  'map',
  'medical-specialist-cross',
  'medical-specialist-star',
  'menu-dark-blue',
  'menu-light-blue',
  'menu-white-57',
  'menu-white-59',
  'menu-yellow',
  'menu',
  'message',
  'minus',
  'mute-09',
  'mute-5',
  'notification-white',
  'notification',
  'option-gray',
  'option-white',
  'option',
  'pay',
  'pen',
  'pen-white',
  'plus',
  'prescription',
  'price',
  'profile-complete',
  'profile-incomplete',
  'receipt',
  'request-cancelled',
  'request-denied',
  'right-white',
  'right-yellow',
  'right',
  'search-white-56',
  'search-white-80',
  'search',
  'security',
  'selection',
  'send-white',
  'send',
  'setting-white',
  'setting',
  'share',
  'star-gray',
  'star-yellow',
  'star',
  'stethoscope',
  'temp-payment-blue',
  'temp-payment-white',
  'time',
  'trash',
  'trusted-by-doctors',
  'unmute-04',
  'unmute-08',
  'upload',
  'user-confirmed',
  'user-friendly',
  'user-white',
  'user',
  'video',
  'view',
  'waiver-white',
  'waiver',
] as const;

export type IconName = typeof iconListConst[number];

export const IconList: {
  [key in IconName]: any;
} = {
  'allied-health-professionals-white': AlliedHealthProfessionalsWhite,
  'allied-health-professionals': AlliedHealthProfessionals,
  'arrow-down': ArrowDown,
  attachment: Attachment,
  calendar: Calendar,
  'call-25': Call25,
  'call-27': Call27,
  'call-28': Call28,
  'camera-29': Camera29,
  'camera-85': Camera85,
  'camera-view': CameraView,
  'camera-white': CameraWhite,
  'check-white': CheckWhite,
  'cancel-white': CancelWhite,
  check: Check,
  'checkbox-checked': CheckboxChecked,
  'checkbox-unchecked': CheckboxUnchecked,
  'close-gray-70': CloseGray70,
  'close-gray-71': CloseGray71,
  close: Close,
  'complementary-medicine-white': ComplementaryMedicineWhite,
  'complementary-medicine': ComplementaryMedicine,
  'consultation-request': ConsultationRequest,
  dentists: Dentists,
  'download-down': DownloadDown,
  'download-left': DownloadLeft,
  'easy-to-use': EasyToUse,
  'email-white': EmailWhite,
  email: Email,
  facebook: Facebook,
  'faq-white': FaqWhite,
  faq: Faq,
  'first-aid-white': FirstAidWhite,
  fullscreen: Fullscreen,
  gender: Gender,
  globe: Globe,
  'hc-icon-allergies': HCIconAllergies,
  'hc-icon-family-condition': HCIconFamilyCondition,
  'hc-icon-hospitalization': HCIconHospitalization,
  'hc-icon-maintenance': HCIconMaintenance,
  'hc-icon-medical-condition': HCIconMedicalCondition,
  'hc-icon-personal-and-social': HCIconPersonalAndSocial,
  'general-practitioner': GeneralPractitioner,
  'hide-video': HideVideo,
  hide: Hide,
  'home-white': HomeWhite,
  home: Home,
  instagram: Instagram,
  information: Information,
  'left-black': LeftBlack,
  'left-blue': LeftBlue,
  'left-gray': LeftGray,
  'left-white': LeftWhite,
  left: Left,
  lock: Lock,
  map: Map,
  'medical-specialist-cross': MedicalSpecialistCross,
  'medical-specialist-star': MedicalSpecialistStar,
  'menu-dark-blue': MenuDarkBlue,
  'menu-light-blue': MenuLightBlue,
  'menu-white-57': MenuWhite57,
  'menu-white-59': MenuWhite59,
  'menu-yellow': MenuYellow,
  menu: Menu,
  message: Message,
  minus: Minus,
  'mute-09': Mute09,
  'mute-5': Mute5,
  'notification-white': NotificationWhite,
  notification: Notification,
  'option-gray': OptionGray,
  'option-white': OptionWhite,
  option: Option,
  pay: Pay,
  pen: Pen,
  'pen-white': PenWhite,
  plus: Plus,
  prescription: Prescription,
  price: Price,
  'profile-complete': ProfileComplete,
  'profile-incomplete': ProfileIncomplete,
  receipt: Receipt,
  'request-cancelled': RequestCancelled,
  'request-denied': RequestDenied,
  'right-white': RightWhite,
  'right-yellow': RightYellow,
  right: Right,
  'search-white-56': SearchWhite56,
  'search-white-80': SearchWhite80,
  search: Search,
  security: Security,
  selection: Selection,
  'send-white': SendWhite,
  send: Send,
  'setting-white': SettingWhite,
  setting: Setting,
  share: Share,
  'star-gray': StarGray,
  'star-yellow': StarYellow,
  star: Star,
  stethoscope: Stethoscope,
  'temp-payment-blue': TempPaymentBlue,
  'temp-payment-white': TempPaymentWhite,
  time: Time,
  trash: Trash,
  'trusted-by-doctors': TrustedByDoctors,
  'unmute-04': Unmute04,
  'unmute-08': Unmute08,
  upload: Upload,
  'user-confirmed': UserConfirmed,
  'user-friendly': UserFriendly,
  'user-white': UserWhite,
  user: User,
  video: Video,
  view: View,
  'waiver-white': WaiverWhite,
  waiver: Waiver,
};
