import React, {useEffect, useState} from 'react';
import {StyleSheet, View, Platform} from 'react-native';

import {observer} from 'mobx-react';

import {RNEText, RNEButton} from '../../components';

import {colorPalette} from '../../../core/config/color.config';

import {
  useGenerateTemporaryToken,
  useGetInvoice,
} from '../../server/react-query/usePayment';

import {formatAmount} from '../../utils/currency.utils';
import {useNavigation} from '@react-navigation/native';
import {NavigationKey} from '../../navigations/config';
import {useToast} from 'react-native-fast-toast';
import {PaymentMethodSelectionModal} from '..';
import {Overlay} from 'rne-netzon';
import usePayment from '../../hooks/payment/usePayment';

interface ConsultationProceedToPaymentProps {
  serviceId: string;
  patientId: string;
}

const ConsultationProceedToPayment = (
  props: ConsultationProceedToPaymentProps,
) => {
  const navigation = useNavigation();
  const toast = useToast();
  const [totalAmount, setTotalAmount] = useState(0);
  const [showDialog, setShowDialog] = useState(false);
  const [webRedirect, setWebRedirect] = useState(false);

  const {data} = useGetInvoice({
    serviceId: props.serviceId,
    patientId: props.patientId,
  });

  const {prepareIPay88Values, setSelectedPaymentMethod} = usePayment();

  const mutation = useGenerateTemporaryToken();

  const proceedToPayment = async () => {
    try {
      const response = await mutation.mutateAsync({
        patientId: props.patientId,
        invoiceId: data?.[0]?.invoiceId || '',
      });
      navigation.navigate(NavigationKey.CONSULTATION_PAYMENT_WEB_VIEW, {
        token: response.token,
      });
    } catch (error) {
      console.log('error', error);
      if (error?.data?.error) {
        toast?.show(error?.data?.error, {type: 'danger'});
      }
    }
  };

  const getSelectedPaymentMethod = (code: string) => {
    setSelectedPaymentMethod(code);
  };

  useEffect(() => {
    const amount = data?.[0]?.totalGross?.value || 0;
    setTotalAmount(amount);
  }, [data]);

  return (
    <>
      <Overlay
        isVisible={showDialog}
        onBackdropPress={() => setShowDialog(false)}
        overlayStyle={webStyles.overlayContainer}>
        <PaymentMethodSelectionModal
          onConfirm={() => {
            setShowDialog(false);
            setWebRedirect(true);
            prepareIPay88Values(data?.[0]!, true);
          }}
          onSelectPaymentMethod={getSelectedPaymentMethod}
        />
      </Overlay>
      {Platform.OS === 'web' ? (
        <View style={webStyles.mainContainer}>
          <View style={webStyles.messageContainer}>
            <RNEText
              label="Your doctor has accepted your request."
              weight="bold"
              color="accent"
              variant="button"
              numberOfLines={3}
              ellipsizeMode="tail"
            />
            <RNEText
              label={`Total due is P${formatAmount(
                totalAmount,
              )}. Proceed with payment to start the consultation.`}
              color="blackLight"
              variant="button"
              numberOfLines={3}
              ellipsizeMode="tail"
            />
          </View>

          <View>
            <RNEButton
              title="Proceed to Payment"
              color="primary"
              type="outline"
              variant="outlined"
              style={webStyles.buttonStyle}
              onPress={() => setShowDialog(true)}
              loading={webRedirect}
            />
          </View>
        </View>
      ) : (
        <View style={mobileStyles.mainContainer}>
          <View style={mobileStyles.messageContainer}>
            <RNEText
              label="Your doctor has accepted your request."
              weight="bold"
              color="accent"
              numberOfLines={3}
              ellipsizeMode="tail"
              align="center"
            />
            <RNEText
              label={`Total due is P${formatAmount(
                totalAmount,
              )}. Proceed with payment to start the consultation.`}
              color="grey"
              numberOfLines={3}
              ellipsizeMode="tail"
              align="center"
            />
          </View>

          <View>
            <RNEButton
              title="Proceed to Payment"
              color="accent"
              type="outline"
              variant="outlined"
              style={mobileStyles.buttonStyle}
              onPress={proceedToPayment}
            />
          </View>
        </View>
      )}
    </>
  );
};

export default observer(ConsultationProceedToPayment);

const webStyles = StyleSheet.create({
  mainContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 16,
    borderTopWidth: 1,
    borderTopColor: colorPalette.light,
  },
  messageContainer: {
    flex: 1,
    justifyContent: 'center',
    marginRight: 16,
  },
  buttonStyle: {
    width: 180,
  },
  overlayContainer: {
    borderRadius: 20,
    marginHorizontal: 40,
  },
});

const mobileStyles = StyleSheet.create({
  mainContainer: {
    alignItems: 'center',
    paddingVertical: 32,
    borderTopWidth: 1,
    borderTopColor: colorPalette.light,
  },
  messageContainer: {
    alignItems: 'center',
    marginBottom: 24,
  },
  buttonStyle: {
    width: 180,
  },
});
