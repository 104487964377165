import React, {memo, useEffect, useState} from 'react';
import {View, Text} from 'react-native';

import {makeStyles} from 'rne-netzon';
import {colorPalette} from '../../../core/config/color.config';

import {ConsultationRequestResBody} from '../../server/types/consultation.types';

import useStores from '../../stores/useStores';

import ConsultationRequestMessage from './ConsultationRequestMessage';
import ConsultationMessageAttachment from './ConsultationMessageAttachment';

interface ConsultationDetailCancelledProps {
  serviceRequest: ConsultationRequestResBody;
}

const ConsultationDetailCancelled = (
  props: ConsultationDetailCancelledProps,
) => {
  const {authStore} = useStores();
  const styles = useStyles();

  return (
    <View style={styles.body}>
      <ConsultationRequestMessage
        message={props.serviceRequest.note?.[0]?.text}
        timestamp={new Date(props.serviceRequest.note?.[0]?.time)}
        owner={authStore.userRole === 'patient'}
      />

      {authStore.userRole === 'practitioner' && (
        <ConsultationMessageAttachment
          messageType={
            authStore.userRole === 'practitioner' ? 'incoming' : 'outgoing'
          }
          attribute={{
            Type: 'history',
          }}
          serviceRequest={props.serviceRequest}
        />
      )}
    </View>
  );
};

const useStyles = makeStyles({
  body: {
    paddingVertical: 12,
  },
});

export default memo(ConsultationDetailCancelled);
