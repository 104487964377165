import React, {useEffect, useState} from 'react';
import {View} from 'react-native';

import useStores from '../../stores/useStores';

import {observer} from 'mobx-react';
import _ from 'lodash';

import {RNEButton} from '../../components';
import {makeStyles} from 'rne-netzon';
import {colorPalette} from '../../../core/config/color.config';

import {ConsultationRequestResBody} from '../../server/types/consultation.types';

import ConsultationMessageBubble from './ConsultationMessageBubble';
import ConsultationMessageAttachment from './ConsultationMessageAttachment';
import {useGetOngoingConsultation} from '../../server/react-query/useConsultation';
import {ProcedureStatusEnum} from '../../server/types/procedure.types';
import {ConsultationInformationMessage} from '..';

interface ConsultationMessagesProps {
  conversation: any;
  serviceRequest: ConsultationRequestResBody;
}

const ConsultationMessages = ({
  conversation,
  serviceRequest,
}: ConsultationMessagesProps) => {
  const styles = useStyle();
  const {
    authStore,
    patientProfileStore,
    doctorProfileStore,
    conversationStore,
  } = useStores();
  const [messages, setMessages] = useState<any[]>([]);

  const [isPatient, setIsPatient] = useState(false);
  const [authorId, setAuthorId] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const {refetch: refetchOngoingConsultationsList} = useGetOngoingConsultation({
    status: [ProcedureStatusEnum.ONGOING],
    subject: patientProfileStore.activeProfile?.patientId,
    SortBy: 'noteTime',
    Ascending: false,
  });

  const getMessages = async () => {
    if (conversation) {
      setIsLoading(true);
      try {
        const msgs = await conversation.getMessages(1000);
        console.log(msgs.items);

        setMessages(msgs.items);
      } catch (error) {
        // TODO: Handle error
      }
      setIsLoading(false);
    }
  };

  const onMessageAdded = (msg: any) => {
    setMessages(oldMessages => {
      return [...oldMessages, msg];
    });
    refetchOngoingConsultationsList();
  };

  useEffect(() => {
    if (conversation) {
      conversation.on('messageAdded', onMessageAdded);
      getMessages();
    } else {
      setMessages([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversation]);

  useEffect(() => {
    setIsPatient(authStore.userRole === 'patient');
  }, [authStore.userRole]);

  useEffect(() => {
    let id: string = '';

    if (isPatient) {
      id = patientProfileStore.activeProfile?.patientId || '';
    } else {
      id = doctorProfileStore.doctorProfile?.practitionerId || '';
    }

    setAuthorId(id);
  }, [
    isPatient,
    patientProfileStore.activeProfile,
    doctorProfileStore.doctorProfile,
  ]);

  return (
    <View>
      {!isLoading ? (
        messages.map(message => {
          if (_.isEmpty(message.attributes)) {
            return (
              <ConsultationMessageBubble
                key={message.sid}
                message={message.body}
                timestamp={message.dateUpdated}
                owner={message.author === authorId}
                isPatient={
                  (isPatient && message.author === authorId) ||
                  (!isPatient && message.author !== authorId)
                }
                serviceRequest={serviceRequest}
                latestVideoCallRequestTimestamp={
                  conversationStore.latestVideoCallRequest?.timestamp
                }
              />
            );
          } else {
            return (
              <>
                {message.attributes.Type === 'invoice' ? (
                  <ConsultationInformationMessage
                    timestamp={new Date(message.dateUpdated)}
                  />
                ) : (
                  <ConsultationMessageAttachment
                    messageType={
                      message.author === authorId ? 'outgoing' : 'incoming'
                    }
                    attribute={message.attributes}
                    serviceRequest={serviceRequest}
                  />
                )}
              </>
            );
          }
        })
      ) : (
        <View style={styles.contentPlaceholder}>
          <RNEButton
            height={32}
            loadingProps={loadingPropsStyle}
            loading={true}
          />
        </View>
      )}

      {/* <ConsultationMessageAttachment
        messageType="outgoing"
        attribute={{
          Type: 'diagnosticReport',
        }}
        serviceRequest={serviceRequest}
      />

      <ConsultationMessageAttachment
        messageType="outgoing"
        attribute={{
          Type: 'laboratoryServiceRequest',
        }}
        serviceRequest={serviceRequest}
      />

      <ConsultationMessageAttachment
        messageType="outgoing"
        attribute={{
          Type: 'medicalRequest',
        }}
        serviceRequest={serviceRequest}
      /> */}
    </View>
  );
};

const loadingPropsStyle = {
  color: colorPalette.primary,
};

const useStyle = makeStyles({
  contentPlaceholder: {
    marginTop: 24,
  },
});

export default observer(ConsultationMessages);
