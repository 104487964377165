import {action, computed, makeAutoObservable, observable} from 'mobx';
import {ConsultationRequestResBody} from '../server/types/consultation.types';
import {HumanName} from '../server/types/identity.types';
import {PatientProfileReqResponse} from '../server/types/patient-profile.types';
import {Photo} from '../server/types/photo.types';
import {PractitionerProfile} from '../server/types/practitioner-profile.types';
import {ProcedureConsultationReqResponse} from '../server/types/procedure.types';

import {persistStore} from '../utils/mobx.utils';

export interface IRecentlyViewedAccount {
  basedOnId: string;
  performer?: PractitionerProfile;
  subject?: PatientProfileReqResponse;
}

export class RecentlyViewedAccountsStore {
  recentlyViewedAccounts: IRecentlyViewedAccount[] = [];

  constructor() {
    makeAutoObservable(this, {
      recentlyViewedAccounts: observable,
      resetRecentlyViewedAccount: action,
      recentlyViewedAccts: computed,
    });

    persistStore(
      this,
      ['recentlyViewedAccounts'],
      'RecentlyViewedAccountsStore',
    );
  }

  addRecentlyViewedAccount(
    basedOnId: string,
    data: ConsultationRequestResBody | ProcedureConsultationReqResponse,
  ) {
    const index = this.recentlyViewedAccounts.findIndex(
      account => account.basedOnId === basedOnId,
    );

    if (index < 0) {
      const viewData: IRecentlyViewedAccount = {
        basedOnId: basedOnId,
        performer: data.performer,
        subject: data.subject,
      };
      this.recentlyViewedAccounts.unshift(viewData);
    }
  }

  resetRecentlyViewedAccount() {
    this.recentlyViewedAccounts = [];
  }

  get recentlyViewedAccts() {
    return this.recentlyViewedAccounts.slice(0, 4);
  }
}
