import React, {useState, createContext, useContext} from 'react';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {InvoiceResBody, PaymentReqBody} from '../../server/types/payment.types';
import {RNSearchableDropdownOption} from '../../components';
import {
  BACKEND_URL,
  IPAY88_PAYMENT_ENTRY_URL,
  MERCHANT_CODE,
  RESPONSE_URL,
} from '../../server/api/api.config';
import {formatAmount} from '../../utils/currency.utils';
import {htmlPost} from '../../utils/html.utils';

export const paymentMethods: RNSearchableDropdownOption[] = [
  {
    name: '7-Eleven',
    value: '48',
  },
  {
    name: 'BPI Online Banking',
    value: '58',
  },
  {
    name: 'Credit Card',
    value: '1',
  },
  {
    name: 'DragonPay ',
    value: '18',
  },
  {
    name: 'GCash',
    value: '3',
  },
  {
    name: 'GrabPay',
    value: '38',
  },
];

const schema: yup.SchemaOf<Partial<PaymentReqBody>> = yup.object({
  MerchantCode: yup.string().trim().required('This field is required.'),
  PaymentId: yup.string(),
  RefNo: yup.string(),
  Amount: yup.string().required('This field is required.'),
  Currency: yup.string().required('This field is required.'),
  ProdDesc: yup.string().required('This field is required.'),
  UserName: yup.string().required('This field is required.'),
  UserEmail: yup.string().required('This field is required.'),
  UserContact: yup.string().required('This field is required.'),
  Remark: yup.object(),
  Lang: yup.string(),
  SignatureType: yup.string().required('This field is required.'),
  Signature: yup.string().required('This field is required.'),
  ResponseUrl: yup.string().required('This field is required.'),
  BackendUrl: yup.string().required('This field is required.'),
});

type IPaymentContext = {
  paymentMethod: string;
  prepareIPay88Values: (invoiceData: InvoiceResBody, fromWeb?: boolean) => any;
  postToIpay88: () => any;
  setSelectedPaymentMethod: (payMethod: string) => any;
};

export const PaymentContext = createContext<IPaymentContext>({
  paymentMethod: '1',
  prepareIPay88Values: () => null,
  postToIpay88: () => null,
  setSelectedPaymentMethod: () => null,
});

export const PaymentProvider = ({children}: any) => {
  const [paymentMethod, setPaymentMethod] = useState('1');
  const {setValue, getValues} = useForm<PaymentReqBody>({
    mode: 'all',
    defaultValues: {
      MerchantCode: '',
      PaymentId: '',
      RefNo: '',
      Amount: '',
      Currency: '',
      ProdDesc: '',
      UserName: '',
      UserEmail: '',
      UserContact: '',
      Remark: '',
      Lang: '',
      SignatureType: '',
      Signature: '',
      ResponseUrl: '',
      BackendUrl: '',
    },
    resolver: yupResolver(schema),
  });

  const prepareIPay88Values = (
    invoiceData: InvoiceResBody,
    fromWeb?: boolean,
  ) => {
    const remark = {
      service: {
        id: invoiceData.lineItem[0].chargeItem.serviceId,
        type: 'consultation',
      },
      performer: {
        id: invoiceData.participant.practitionerId,
        name: {
          given: invoiceData.participant.firstName,
          family: invoiceData.participant.lastName,
        },
      },
    };

    setValue('MerchantCode', MERCHANT_CODE);
    setValue('PaymentId', paymentMethod);
    setValue('RefNo', invoiceData?.invoiceId || '');
    setValue('Amount', formatAmount(invoiceData?.totalGross?.value || 0));
    setValue('Currency', invoiceData?.totalGross?.currency || '');
    setValue(
      'ProdDesc',
      `Consultation with Dr. ${invoiceData.participant.lastName}, ${invoiceData.participant.firstName} ${invoiceData.participant.middleName}`,
    );
    setValue(
      'UserName',
      `${invoiceData?.recipient.firstName} ${invoiceData?.recipient.lastName}`,
    );
    setValue('UserEmail', `${invoiceData?.recipient.email}`);
    setValue('UserContact', `${invoiceData?.recipient.contactNumber}`);
    setValue('Remark', JSON.stringify(remark));
    setValue('Lang', 'UTF-8');
    setValue('SignatureType', 'SHA1');
    setValue('Signature', invoiceData?.signature || '');
    setValue('ResponseUrl', RESPONSE_URL);
    setValue('BackendUrl', BACKEND_URL);

    if (fromWeb) {
      postToIpay88();
    }
  };

  const postToIpay88 = () => {
    const url = IPAY88_PAYMENT_ENTRY_URL;
    var values = getValues();
    htmlPost(url, values);
  };

  const setSelectedPaymentMethod = (payMethod: string) => {
    setPaymentMethod(payMethod);
  };

  return (
    <PaymentContext.Provider
      value={{
        paymentMethod,
        prepareIPay88Values,
        postToIpay88,
        setSelectedPaymentMethod,
      }}>
      {children}
    </PaymentContext.Provider>
  );
};

const usePayment = () => {
  const _context = useContext(PaymentContext);

  if (!_context) {
    throw new Error('You have forgotten to use PaymentProvider.');
  }
  return _context;
};

export default usePayment;
