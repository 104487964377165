import {FocalDevice} from './focal-device.types';
import {PractitionerProfile} from './practitioner-profile.types';
import {PatientProfileReqResponse} from './patient-profile.types';
import {ConsultationNote} from './consultation.types';
import {CodeableConcept} from './code.types';

export enum ProcedureStatusEnum {
  ONGOING = 'inProgress',
  COMPLETED = 'completed',
  TO_PAY = 'preparation',
}

export interface ProcedureConsultationReqResponse {
  procedureId: string;
  status: ProcedureStatusEnum;
  subject: PatientProfileReqResponse;
  performer: PractitionerProfile;
  basedOnId: string;
  code: CodeableConcept;
  note: ConsultationNote;
  focalDevice: FocalDevice;
  modifiedOn: Date;
  modifiedBy: string;
}

export interface ProcedureListReqParams {
  searchKey?: string;
  status: ProcedureStatusEnum[];
  subject?: string;
  code?: string;
  SortBy?: string;
  Ascending?: boolean;
}
