import React from 'react';
import {Platform, Animated, StyleSheet, View} from 'react-native';

import {RNEText, SVGIcon} from '../../components';
import {logoStyle, mobileStyles} from './styles';

const OnboardingSlideScreen3 = () => {
  const label = '100% Convenient';

  return (
    <Animated.View style={mobileStyles.sliderContainer}>
      <View style={mobileStyles.sliderContent}>
        <View style={logoStyle}>
          <SVGIcon name="easy-to-use" size={210} />
        </View>
        <View>
          <RNEText
            label={Platform.OS === 'web' ? label.toUpperCase() : label}
            color="accent"
            variant="header"
            weight="bold"
            align="center"
          />
        </View>
        <View style={mobileStyles.description}>
          <RNEText
            label="Get a medical advise, laboratory request, medical prescription, and medical certificate from a licensed physician without having to leave your home."
            color={Platform.OS === 'web' ? 'black' : 'grey'}
            variant="body"
            align="center"
          />
        </View>
      </View>
    </Animated.View>
  );
};

export default OnboardingSlideScreen3;
