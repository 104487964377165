import React from 'react';
import {View, Text} from 'react-native';

import {makeStyles} from 'rne-netzon';

interface TableHeaderCellProps {
  flex?: number;
  width?: number | string;
  children: React.ReactNode;
  justify?: 'flex-start' | 'center' | 'flex-end';
}

const TableHeaderCell = (props: TableHeaderCellProps) => {
  const styles = useStyle();

  const {flex = 1, width, justify = 'flex-start'} = props;

  return (
    <>
      <View
        style={[
          styles.rootContainer,
          props.width ? {width: width} : {flex: flex},
          {
            justifyContent: justify,
          },
        ]}>
        {props.children}
      </View>
    </>
  );
};

const useStyle = makeStyles({
  rootContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    height: '100%',
    paddingHorizontal: 12,
  },
});

export default TableHeaderCell;
