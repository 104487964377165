import React, {memo, useEffect, useState} from 'react';
import {View} from 'react-native';

import {makeStyles} from 'rne-netzon';
import {colorPalette} from '../../../core/config/color.config';

import {RNEText} from '../../components';
import {ConsultationStatusEnum} from '../../server/types/consultation.types';
import {ProcedureStatusEnum} from '../../server/types/procedure.types';

interface ConsultationStatusProps {
  consultationStatus?: ConsultationStatusEnum;
  procedureStatus?: ProcedureStatusEnum;
  isActionByPatient?: boolean;
}

const ConsultationStatus = (props: ConsultationStatusProps) => {
  const styles = useStyle();

  const [statusData, setStatusData] = useState<{
    label: string;
    bgColor: string;
  }>();

  useEffect(() => {
    switch (props.consultationStatus) {
      case ConsultationStatusEnum.COMPLETED:
        setStatusData({
          label: 'Completed',
          bgColor: colorPalette.secondary,
        });
        break;

      case ConsultationStatusEnum.DECLINED:
        setStatusData({
          label: props.isActionByPatient ? 'Cancelled' : 'Declined',
          bgColor: colorPalette.accent,
        });
        break;

      case ConsultationStatusEnum.CANCELLED:
        setStatusData({
          label: props.isActionByPatient ? 'Cancelled' : 'Declined',
          bgColor: colorPalette.accent,
        });
        break;

      case ConsultationStatusEnum.PENDING:
        setStatusData({
          label: 'Pending',
          bgColor: colorPalette.success,
        });
        break;

      default:
        break;
    }

    if (props.procedureStatus) {
      if (
        props.consultationStatus === ConsultationStatusEnum.PENDING &&
        props.procedureStatus === ProcedureStatusEnum.TO_PAY
      ) {
        setStatusData({
          label: 'To Pay',
          bgColor: colorPalette.purple,
        });
      }
    }
  }, [
    props.consultationStatus,
    props.procedureStatus,
    props.isActionByPatient,
  ]);

  return (
    <View
      style={[
        styles.status,
        {
          backgroundColor: statusData?.bgColor,
        },
      ]}>
      <RNEText
        label={statusData?.label ?? ''}
        color="white"
        variant="button"
        weight="600"
        style={fontStyle}
      />
    </View>
  );
};

const fontStyle = {
  fontSize: 12,
};

const useStyle = makeStyles({
  status: {
    width: 90,
    alignItems: 'center',
    borderRadius: 100,
    paddingHorizontal: 12,
    paddingVertical: 6,
    backgroundColor: 'transparent',
    height: 40,
    justifyContent: 'center',
  },
});

export default memo(ConsultationStatus);
