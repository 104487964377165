import React, {useLayoutEffect} from 'react';
import {
  View,
  Image,
  Platform,
  ScrollView,
  TouchableWithoutFeedback,
  TouchableOpacity,
} from 'react-native';

import HTML from 'react-native-render-html';

import {useNavigation} from '@react-navigation/native';
import {NavigationKey} from '../../navigations/config';

import {makeStyles} from 'rne-netzon';
import {colorPalette} from '../../../core/config/color.config';
import {LogoLight} from './../../images/Image';

import {RNEText} from '../../components';
import HeaderBackLeft from '../../views/navigation/HeaderBackLeft';

import {HTMLPrivacyPolicy} from './../../data/policy/privacy-policy';
import {ContentScrollView} from '../../views';

const PrivacyPolicyAuthScreen = () => {
  const webStyles = useWebStyles();
  const mobileStyles = useMobileStyles();

  const navigation = useNavigation();

  const goToLogin = () => {
    navigation.navigate(NavigationKey.LOGIN_SCREEN);
  };

  const onTermsClick = () => {
    navigation.navigate(NavigationKey.TANDC_SCREEN);
  };

  useLayoutEffect(() => {
    navigation.setOptions(
      Platform.OS === 'web'
        ? {
            headerShown: false,
          }
        : {
            headerShown: true,
            title: '',
            headerStyle: {
              elevation: 0,
              shadowOpacity: 0,
            },
            headerTintColor: colorPalette.blackLight,
            headerTitleStyle: {
              fontFamily: 'Quicksand-Regular',
              fontSize: 14,
              paddingLeft: 28,
            },
            headerLeft: () => <HeaderBackLeft />,
          },
    );
  }, [navigation]);

  return (
    <>
      {Platform.OS === 'web' ? (
        <View style={webStyles.root}>
          <View style={webStyles.logoContainer}>
            <TouchableWithoutFeedback onPress={goToLogin}>
              <Image style={webStyles.logo} source={LogoLight} />
            </TouchableWithoutFeedback>
          </View>
          <View style={webStyles.mainContainer}>
            <View style={webStyles.titleContainer}>
              <RNEText
                label="Privacy Policy"
                variant="header"
                weight="bold"
                color="accent"
              />
              <View style={webStyles.privacyContainer}>
                <TouchableOpacity activeOpacity={0.75} onPress={onTermsClick}>
                  <RNEText
                    label="Terms and Conditions "
                    variant="button"
                    weight="bold"
                  />
                </TouchableOpacity>
                <RNEText label="here" variant="button" />
              </View>
            </View>
            <ContentScrollView isWeb={Platform.OS === 'web'}>
              <HTML
                source={{
                  html: HTMLPrivacyPolicy,
                }}
              />
            </ContentScrollView>
          </View>
        </View>
      ) : (
        <ScrollView>
          <View style={mobileStyles.mainContainer}>
            <RNEText
              label="Privacy Policy"
              variant="header"
              weight="bold"
              color="accent"
            />

            <HTML
              source={{
                html: HTMLPrivacyPolicy,
              }}
            />
          </View>
        </ScrollView>
      )}
    </>
  );
};

export default PrivacyPolicyAuthScreen;

const useWebStyles = makeStyles({
  root: {flex: 1, height: '100%', width: '100%', padding: 48},
  logoContainer: {
    width: '100%',
    maxWidth: '1024px',
    alignSelf: 'center',
  },
  logo: {
    width: 200,
    height: 44,
  },
  mainContainer: {
    width: '100%',
    maxWidth: 760,
    alignSelf: 'center',
    flex: 1,
    marginTop: '10vh',
    maxHeight: '60vh',
  },
  titleContainer: {
    marginBottom: 40,
  },
  privacyContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  mainWrapper: {
    flex: 1,
    paddingRight: 48,
  },
});

const useMobileStyles = makeStyles({
  mainContainer: {
    paddingVertical: 20,
    paddingHorizontal: 24,
  },
});
