import React from 'react';
import {Platform} from 'react-native';
import {createDrawerNavigator} from '@react-navigation/drawer';
import {makeStyles} from 'rne-netzon';

import {NavigationKey} from '../config/navigation.config';
import {ConversationProvider} from '../../hooks/conversation/useConversation';

import {PatientSideMenu} from '../../views';
import {PrivacyPolicyScreen} from '../../screens';

import PatientLandingStackNavigator from './PatientLandingStackNavigator';
import PatientProfileStackNavigator from './PatientProfileStackNavigator';
import PatientNotificationStackNavigator from './PatientNotificationStackNavigator';
import PatientConsultationStackNavigator from './PatientConsultationStackNavigator';
import PatientTandCStackNavigator from './PatientTandCStackNavigator';
import PatientHelpStackNavigator from './PatientHelpStackNavigator';
import PatientAccountStackNavigator from './PatientAccountStackNavigator';
import PatientMedicalHistoryStackNavigator from './PatientMedicalHistoryStackNavigator';
import {NavigationTitle} from '../config/navigationTitle';

const {Navigator, Screen} = createDrawerNavigator();

const PatientDrawerNavigator = () => {
  const drawerStyles = useDrawerStyles();
  const drawerPosition = Platform.OS === 'web' ? 'left' : 'right';
  const drawerType = Platform.OS === 'web' ? 'permanent' : 'front';

  return (
    <ConversationProvider>
      <Navigator
        drawerPosition={drawerPosition}
        drawerType={drawerType}
        drawerStyle={
          Platform.OS === 'web'
            ? drawerStyles.webWidth
            : drawerStyles.mobileWidth
        }
        drawerContent={() => <PatientSideMenu />}
        screenOptions={{
          unmountOnBlur: Platform.OS === 'web' ? true : false,
          swipeEnabled: false,
        }}>
        <Screen
          name={NavigationKey.PATIENT_LANDING_STACK}
          component={PatientLandingStackNavigator}
        />
        <Screen
          name={NavigationKey.PATIENT_PROFILE_STACK}
          component={PatientProfileStackNavigator}
        />
        <Screen
          name={NavigationKey.PATIENT_NOTIFICATION_STACK}
          component={PatientNotificationStackNavigator}
        />
        <Screen
          name={NavigationKey.PATIENT_CONSULTATION_STACK}
          component={PatientConsultationStackNavigator}
        />
        <Screen
          name={NavigationKey.PATIENT_TANDC_STACK}
          component={PatientTandCStackNavigator}
        />
        <Screen
          name={NavigationKey.PATIENT_HELP_STACK}
          component={PatientHelpStackNavigator}
        />
        <Screen
          name={NavigationKey.PATIENT_ACCOUNT_STACK}
          component={PatientAccountStackNavigator}
        />
        <Screen
          name={NavigationKey.PATIENT_MEDICAL_HISTORY_STACK}
          component={PatientMedicalHistoryStackNavigator}
        />
        <Screen
          name={NavigationKey.PRIVACY_POLICY}
          component={PrivacyPolicyScreen}
          options={{
            title: NavigationTitle.PRIVACY_POLICY,
          }}
        />
      </Navigator>
    </ConversationProvider>
  );
};

export default PatientDrawerNavigator;

const useDrawerStyles = makeStyles({
  webWidth: {
    width: 'auto',
    borderRightColor: 'rgba(229, 229, 229, 0.4)',
    borderRightWidth: 2,
  },
  mobileWidth: {
    width: '85%',
  },
});
