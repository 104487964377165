import React from 'react';
import {createStackNavigator} from '@react-navigation/stack';

import {NavigationKey} from '../config/navigation.config';
import {AdminLandingScreen} from '../../screens';

import {NavigationTitle} from '../config/navigationTitle';

const {Navigator, Screen} = createStackNavigator();

const AdminLandingStackNavigator = () => {
  return (
    <Navigator initialRouteName={NavigationKey.ADMIN_DASHBOARD}>
      <Screen
        name={NavigationKey.ADMIN_DASHBOARD}
        component={AdminLandingScreen}
        options={{
          headerShown: false,
          title: NavigationTitle.ADMIN_DASHBOARD,
        }}
      />
    </Navigator>
  );
};

export default AdminLandingStackNavigator;
