import React from 'react';
import {View, Platform, ScrollView, TouchableOpacity} from 'react-native';

import HTML from 'react-native-render-html';

import {useNavigation} from '@react-navigation/native';
import {NavigationKey} from '../../navigations/config';

import {makeStyles} from 'rne-netzon';

import {RNEText} from '../../components';

import {HTMLTermsAndConditions} from '../../data/terms/terms';
import {
  BUILD_NUMBER,
  MAJOR_VERSION,
  MINOR_VERSION,
} from '../../server/api/api.config';

const TermsConditionsScreen = () => {
  const sharedStyles = useSharedStyles();
  const webStyles = useWebStyles();
  const mobileStyles = useMobileStyles();

  const navigation = useNavigation();

  const onPrivacyPolicyClick = () => {
    navigation.navigate(NavigationKey.PRIVACY_POLICY);
  };

  return (
    <>
      {Platform.OS === 'web' ? (
        <View style={webStyles.mainContainer}>
          <View style={webStyles.titleContainer}>
            <RNEText
              label="Terms and Conditions"
              variant="header"
              weight="bold"
              color="accent"
            />
          </View>
          <ScrollView style={webStyles.mainWrapper}>
            <HTML
              source={{
                html: HTMLTermsAndConditions,
              }}
            />
          </ScrollView>
          <View style={webStyles.privacyContainer}>
            <TouchableOpacity
              activeOpacity={0.75}
              onPress={onPrivacyPolicyClick}>
              <RNEText
                label="Privacy Policy "
                variant="body"
                weight="bold"
                color="primary"
              />
            </TouchableOpacity>
            <RNEText label="here" variant="body" />
          </View>
          <View>
            <RNEText
              label={`House Call version ${MAJOR_VERSION}.${MINOR_VERSION}.${BUILD_NUMBER}`}
              variant="title"
              weight="bold"
              color="accent"
              align="right"
              // eslint-disable-next-line react-native/no-inline-styles
              style={{fontSize: 16}}
            />
          </View>
        </View>
      ) : (
        <>
          <ScrollView>
            <View style={mobileStyles.mainContainer}>
              <RNEText
                label="Terms and Conditions"
                variant="header"
                weight="bold"
                color="accent"
              />

              <HTML
                source={{
                  html: HTMLTermsAndConditions,
                }}
              />
            </View>
          </ScrollView>

          <View style={mobileStyles.privacyContainer}>
            <TouchableOpacity
              activeOpacity={0.75}
              onPress={onPrivacyPolicyClick}>
              <RNEText
                label="Privacy Policy "
                variant="body"
                weight="bold"
                color="primary"
              />
            </TouchableOpacity>
            <RNEText label="here" variant="body" />
          </View>

          <View style={mobileStyles.versionContainer}>
            <RNEText
              label={`House Call version ${MAJOR_VERSION}.${MINOR_VERSION}.${BUILD_NUMBER}`}
              variant="title"
              weight="bold"
              color="accent"
              align="right"
              // eslint-disable-next-line react-native/no-inline-styles
              style={{fontSize: 16}}
            />
          </View>
        </>
      )}
    </>
  );
};

export default TermsConditionsScreen;

const useWebStyles = makeStyles({
  mainContainer: {
    paddingTop: 140,
    paddingBottom: 32,
    paddingHorizontal: 80,
    width: '100%',
    flex: 1,
  },
  titleContainer: {
    marginBottom: 40,
  },
  mainWrapper: {
    paddingRight: 48,
  },
  privacyContainer: {
    height: 96,
    flexDirection: 'row',
    alignItems: 'center',
  },
});

const useMobileStyles = makeStyles({
  mainContainer: {
    paddingVertical: 20,
    paddingHorizontal: 48,
  },
  privacyContainer: {
    height: 96,
    paddingHorizontal: 48,
    flexDirection: 'row',
    alignItems: 'center',
  },
  versionContainer: {
    marginRight: 20,
    marginBottom: 20,
  },
});

const useSharedStyles = makeStyles({
  root: {
    height: '100%',
    flexDirection: 'row',
    width: '100%',
  },
  paragraphContainer: {
    paddingVertical: 40,
    width: '100%',
  },
  tncParagraph: {
    marginBottom: 24,
  },
});
