import {AxiosRequestConfig} from 'axios';
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query';
import {API, ApiEndpointsEnum} from '../api';
import {
  AccountDeletionStatusResBody,
  EditAccountReqBody,
} from '../types/account.types';
import {User} from '../types/user.types';
import {IBaseErrorResponse} from './../types/error.types';

const getAccount = async () => {
  return API.get<User>(ApiEndpointsEnum.GET_ACCOUNT);
};

const useGetAccount = () => {
  const queryClient = useQueryClient();

  return useQuery<User, IBaseErrorResponse>(
    ['get-account'],
    () => getAccount(),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['get-account'], {
          refetchActive: false,
        });
      },
    },
  );
};

const editAccount = async (reqBody: EditAccountReqBody) => {
  return API.put(`${ApiEndpointsEnum.PUT_ACCOUNT}`, reqBody);
};

const useEditAccount = (reqBody: EditAccountReqBody) => {
  const queryClient = useQueryClient();

  return useMutation(['edit-account'], () => editAccount(reqBody), {
    onSuccess: () => {
      queryClient.invalidateQueries(['edit-account']);
      queryClient.fetchQuery(['get-account']);
    },
  });
};

const deleteAccount = async (password: string) => {
  let config: AxiosRequestConfig = {};

  if (password) {
    const headerAuthorization: any = {
      'X-Password': password,
    };

    config = {
      headers: {
        ...headerAuthorization,
      },
    };
  }

  return API.del(`${ApiEndpointsEnum.DELETE_ACCOUNT}`, config);
};

const useDeleteAccount = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ['delete-account'],
    (password: string) => deleteAccount(password),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['delete-account']);
        queryClient.fetchQuery(['get-deletion-request-status']);
      },
    },
  );
};

const getDeletionRequestStatus = async () => {
  return API.get<AccountDeletionStatusResBody>(
    ApiEndpointsEnum.GET_ACCOUNT_DELETION,
  );
};

const useGetDeletionRequestStatus = () => {
  const queryClient = useQueryClient();

  return useQuery<AccountDeletionStatusResBody>(
    ['get-deletion-request-status'],
    () => getDeletionRequestStatus(),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['get-deletion-request-status'], {
          refetchActive: false,
        });
      },
    },
  );
};

const cancelAccountDeletionRequest = async () => {
  return API.del(`${ApiEndpointsEnum.DELETE_ACCOUNT_DELETION}`);
};

const useCancelAccountDeletionRequest = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ['cancel-account-deletion-request'],
    () => cancelAccountDeletionRequest(),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['cancel-account-deletion-request']);
        queryClient.fetchQuery(['get-deletion-request-status']);
      },
    },
  );
};

export {
  getAccount,
  useGetAccount,
  useEditAccount,
  useDeleteAccount,
  useGetDeletionRequestStatus,
  useCancelAccountDeletionRequest,
};
