import React, {useState, useEffect} from 'react';
import {View, TouchableOpacity} from 'react-native';

import {makeStyles, Divider} from 'rne-netzon';

import {RNEText, SVGIcon} from '../../components';

import {PatientProfileReqResponse} from '../../server/types/patient-profile.types';
import {ConsultationStatusEnum} from '../../server/types/consultation.types';

import PatientDetailView from '../patient/PatientDetailView';
import PatientMedicalView from '../patient/PatientMedicalView';

interface ConsultationPatientMedicalViewProps {
  patient: PatientProfileReqResponse;
  status: ConsultationStatusEnum;
  onClose?: () => void;
}

const ConsultationPatientMedicalView = (
  props: ConsultationPatientMedicalViewProps,
) => {
  const styles = useStyles();

  const [isAnonymous, setIsAnonymous] = useState(false);

  useEffect(() => {
    switch (props.status) {
      case ConsultationStatusEnum.PENDING:
        setIsAnonymous(true);
        break;

      case ConsultationStatusEnum.ONGOING:
        setIsAnonymous(false);
        break;

      case ConsultationStatusEnum.COMPLETED:
        setIsAnonymous(false);
        break;

      case ConsultationStatusEnum.CANCELLED:
        setIsAnonymous(true);
        break;

      case ConsultationStatusEnum.DECLINED:
        setIsAnonymous(true);
        break;

      default:
        break;
    }
  }, [props.status]);

  return (
    <View style={styles.detailRoot}>
      <View style={styles.detailContainer}>
        <View style={styles.headerContainer}>
          <RNEText label="Patient Details" weight="600" />
        </View>

        <View style={styles.detailView}>
          {<PatientDetailView detail={props.patient} anonymous={isAnonymous} />}
        </View>

        {isAnonymous && (
          <View>
            <RNEText label="Note:" weight="600" variant="button" />
            <RNEText
              label="Patient's personal details will not be shown until consultation request has been accepted."
              weight="300"
            />
          </View>
        )}
      </View>
      <View style={styles.dividerContainer}>
        <Divider style={styles.divider} />
      </View>
      <View style={styles.medicalContainer}>
        <RNEText label="Medical History" weight="600" />

        <View style={styles.detailView}>
          {<PatientMedicalView patientId={props.patient?.patientId || ''} />}
        </View>
      </View>

      <View style={styles.closeContainer}>
        <TouchableOpacity activeOpacity={0.7} onPress={props.onClose}>
          <SVGIcon name="close-gray-71" size={32} />
        </TouchableOpacity>
      </View>
    </View>
  );
};

const useStyles = makeStyles(theme => ({
  detailRoot: {
    flex: 1,
    flexDirection: 'row',
    position: 'relative',
  },
  detailContainer: {
    width: 300,
    paddingHorizontal: 48,
    paddingTop: 40,
    paddingBottom: 56,
  },
  detailView: {
    flex: 1,
    marginTop: 32,
  },
  dividerContainer: {
    // paddingVertical: 32,
  },
  divider: {
    width: 1,
    height: '100%',
  },
  verticalDivider: {
    height: 1,
    width: '100%',
  },
  medicalContainer: {
    flex: 1,
    paddingHorizontal: 48,
    paddingTop: 40,
    paddingBottom: 24,
  },
  headerContainer: {
    height: 24,
    flexDirection: 'row',
    alignItems: 'center',
  },
  closeContainer: {
    position: 'absolute',
    right: 32,
    top: 32,
  },
}));

export default ConsultationPatientMedicalView;
