import React, {useState, useEffect} from 'react';
import {
  Animated,
  Platform,
  View,
  Image,
  ScrollView,
  TouchableWithoutFeedback,
  TouchableOpacity,
} from 'react-native';

import {SafeAreaView} from 'react-native-safe-area-context';

import {useQueryClient} from 'react-query';

import {
  useRoute,
  useNavigation,
  getFocusedRouteNameFromRoute,
} from '@react-navigation/native';
import {NavigationKey} from '../../navigations/config';

import {Overlay, makeStyles} from 'rne-netzon';

import {LogoLight} from './../../images/Image';

import useStores from '../../stores/useStores';
import {SVGIcon, RNEText} from '../../components';
import {colorPalette} from '../../../core/config/color.config';

import {useSideMenu} from './../../hooks/menu/useSideMenu';

import LogoutConfirmationModal from '../modal/LogoutConfirmationModal';
import SideMenuItem from './SideMenuItem';
import {menuWidth, adminSideMenuItemGroup} from './data/sideMenuItem.data';

const AdminSideMenu = () => {
  const route = useRoute();
  const navigation = useNavigation();
  const {authStore} = useStores();
  const webStyles = useWebStyles();
  const sharedStyles = useSharedStyles();

  const queryClient = useQueryClient();

  const {widthValue, compressed, toggleMenu} = useSideMenu({
    minValue: menuWidth.compressed,
    maxValue: menuWidth.expanded,
  });

  const [showDialog, setShowDialog] = useState(false);
  const [currentScreen, setCurrentScreen] = useState(
    NavigationKey.ADMIN_LANDING,
  );

  const navigateTo = (
    navigationKey: NavigationKey,
    shouldSetScreen: boolean = true,
  ) => {
    if (shouldSetScreen) {
      setCurrentScreen(navigationKey);
    }
    navigation.navigate(navigationKey);
  };

  const toggleLogoutConfirmation = () => {
    setShowDialog(true);
  };

  const logout = () => {
    queryClient.clear();
    setShowDialog(false);
    authStore.logout();
  };

  useEffect(() => {
    const routeName = getFocusedRouteNameFromRoute(route);
    if (routeName) {
      setCurrentScreen(routeName as NavigationKey);
    }
  }, [route]);

  return (
    <SafeAreaView>
      <Overlay
        isVisible={showDialog}
        overlayStyle={sharedStyles.overlayContainer}>
        <LogoutConfirmationModal
          onConfirm={logout}
          onCancel={() => setShowDialog(false)}
        />
      </Overlay>

      <Animated.View
        style={[
          webStyles.mainContainer,
          {
            width: widthValue,
          },
        ]}>
        <View style={webStyles.topWrapper}>
          {!compressed && (
            <View accessibilityRole="button">
              <Image style={webStyles.logo} source={LogoLight} />
            </View>
          )}
          <TouchableWithoutFeedback onPress={toggleMenu}>
            <View accessibilityRole="button">
              <SVGIcon name="menu" size={44} />
            </View>
          </TouchableWithoutFeedback>
        </View>
        <View style={webStyles.bodyWrapper}>
          <ScrollView>
            {adminSideMenuItemGroup.map((menuGroup, groupIndex) => (
              <View key={groupIndex} style={webStyles.contentWrapper}>
                {menuGroup.map((menuItem, itemIndex) => (
                  <SideMenuItem
                    key={itemIndex}
                    label={menuItem.label}
                    iconName={menuItem.iconName}
                    onPress={() => navigateTo(menuItem.navigationKey)}
                    active={currentScreen === menuItem.navigationKey}
                    hideLabel={compressed}
                  />
                ))}
              </View>
            ))}
          </ScrollView>
        </View>
        <View style={webStyles.profileWrapper}>
          <TouchableOpacity
            activeOpacity={0.7}
            onPress={toggleLogoutConfirmation}>
            <View style={webStyles.profile}>
              <SVGIcon name="download-left" size={24} />
              {!compressed && (
                <View style={webStyles.userInfo}>
                  <RNEText label="Logout" variant="button" color="black" />
                </View>
              )}
            </View>
          </TouchableOpacity>
        </View>
      </Animated.View>
    </SafeAreaView>
  );
};

export default AdminSideMenu;

const userNameStyle = {
  width: Platform.OS === 'web' ? 140 : 100,
};

const useWebStyles = makeStyles({
  mainContainer: {
    height: '100vh',
    padding: 48,
    justifyContent: 'space-between',
    backgroundColor: colorPalette.white,
  },
  topWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    // flex: 0.5,
    height: 72,
    marginBottom: 60,
    backgroundColor: colorPalette.white,
  },
  logo: {
    width: 168,
    height: 48,
  },
  bodyWrapper: {
    minWidth: 200,
    flex: 2,
    justifyContent: 'space-evenly',
  },
  contentWrapper: {
    marginBottom: 40,
  },
  contentItem: {
    flexDirection: 'row',
    marginBottom: 20,
    alignItems: 'center',
  },
  subcontentWrapper: {},
  subcontentItem: {
    flexDirection: 'row',
    marginBottom: 20,
    alignItems: 'center',
  },
  profileWrapper: {
    width: '100%',
    height: 60,
    justifyContent: 'flex-end',
    marginTop: 12,
    backgroundColor: colorPalette.white,
  },
  profile: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  userInfo: {
    // paddingVertical: 8,
    paddingLeft: 20,
  },
});

const useSharedStyles = makeStyles({
  root: {
    height: '100%',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
  },
  overlayContainer: {
    borderRadius: 20,
  },
  iconContainer: {
    width: 64,
  },
  labelContainer: {
    flex: 1,
  },
});
