import React from 'react';
import {QueryClient, QueryClientProvider} from 'react-query';
import {ReactQueryDevtools} from 'react-query/devtools';
import AsyncStorage from '@react-native-async-storage/async-storage';
import useStores from '../../stores/useStores';

interface ReactQueryProvider {
  children?: React.ReactNode;
  enableDevTools?: boolean;
}

const ReactQueryProvider = (props: ReactQueryProvider) => {
  const {enableDevTools = false, children} = props;

  const {authStore, patientProfileStore, doctorProfileStore} = useStores();

  const retry = (failureCount: number, error: any) => {
    const reqError: any = error;
    if (reqError && reqError.status === 401) {
      AsyncStorage.removeItem('ConversationStore');
      patientProfileStore.logout();
      doctorProfileStore.logout();
      authStore.logout();
      return false;
    }
    if (failureCount < 2) {
      return true;
    }
    return false;
  };

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: retry,
      },
      mutations: {
        retry: retry,
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      {children}
      {enableDevTools && <ReactQueryDevtools />}
    </QueryClientProvider>
  );
};

export default ReactQueryProvider;
