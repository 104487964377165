import React, {useEffect, useState} from 'react';
import {TouchableOpacity, View, Image} from 'react-native';
import {makeStyles} from 'rne-netzon';
import {RNEButton, RNEIconButton, RNEText, SVGIcon} from '../../components';
import {
  connect,
  LocalVideoTrackPublication,
  RemoteAudioTrack,
  RemoteParticipant,
  RemoteTrack,
  RemoteVideoTrack,
  Room,
} from 'twilio-video';
import {useCheckRoom} from '../../server/react-query/useVideoCall';
import useStores from '../../stores/useStores';
import {PractitionerProfile} from '../../server/types/practitioner-profile.types';
import {PatientProfileReqResponse} from '../../server/types/patient-profile.types';
import {colorPalette} from '../../../core/config/color.config';
import useVideoCall from '../../hooks/video-call/useVideoCall';
import {useSendVideoCallNotification} from '../../server/react-query/usePushNotification';

interface ConsultationVideoCallScreenProps {
  procedureId: string;
  subjectId: string;
  receiver: PractitionerProfile | PatientProfileReqResponse;
  onConfirm: () => void;
  fromInvitation?: boolean;
}

const ConsultationVideoCallScreen = (
  props: ConsultationVideoCallScreenProps,
) => {
  const styles = useStyles();
  const {authStore} = useStores();
  const {
    connected,
    isAudioEnabled,
    receiverHasEntered,
    endingCall,
    token,
    generateToken,
    createRoom,
    closeRoom,
    setAudioEnabled,
    setReceiverEntered,
    setConnectivity,
    resetValues,
  } = useVideoCall();

  const [activeRoom, setActiveRoom] = useState<Room>();
  const [noLocalVideo, setNoLocalVideo] = useState(false);

  const {data: existingRoom} = useCheckRoom({
    subjectId: props.subjectId,
    roomId: props.procedureId,
  });

  const sendEndCallNotif = useSendVideoCallNotification({
    title: 'END CALL',
    body: 'Call attempt has been ended',
    consultation: props.procedureId,
    notificationUrl: '',
    fromUserId: authStore.authData?.user.userId!,
    toUserId: props.receiver.userId,
  });

  const closeRoomAndRedirect = async (endedByMe: boolean) => {
    if (endedByMe) {
      sendEndCallNotif.mutateAsync();
      await closeRoom(props.subjectId, props.procedureId);
    }
    props.onConfirm();
    resetValues();
  };

  const connectToRoom = async () => {
    try {
      if (!connected) {
        await connect(token, {
          name: props.procedureId,
        }).then(async (room: Room) => {
          if (room) {
            setConnectivity(true);
            setActiveRoom(room);

            room.localParticipant.videoTracks.forEach(
              (track: LocalVideoTrackPublication) =>
                track.track.attach('#localVideo'),
            );

            room.participants.forEach((participant: RemoteParticipant) =>
              manageTracksForRemoteParticipant(participant),
            );

            room.on('participantConnected', onParticipantConnected);
            room.on('participantDisconnected', onParticipantDisconnected);
            room.on('disconnected', onParticipantDisconnected);
          }
        });
      }
    } catch (error: any) {
      console.log('error', error);
      if (error.toString() === 'NotFoundError: Requested device not found') {
        setNoLocalVideo(true);
        setTimeout(() => {
          closeRoomAndRedirect(true);
        }, 3000);
      }
    }
  };

  const onMuteButtonPress = () => {
    if (!activeRoom || !activeRoom.localParticipant) {
      throw new Error('You must be connected to a room to mute tracks.');
    } else {
      if (isAudioEnabled) {
        activeRoom.localParticipant.audioTracks.forEach(publication =>
          publication.track.disable(),
        );
        setAudioEnabled(false);
      } else {
        activeRoom.localParticipant.audioTracks.forEach(publication =>
          publication.track.enable(),
        );
        setAudioEnabled(true);
      }
    }
  };

  const manageTracksForRemoteParticipant = (participant: RemoteParticipant) => {
    attachAttachableTracksForRemoteParticipant(participant);

    participant.on('trackSubscribed', onTrackSubscribed);
    participant.on('trackUnsubscribed', onTrackUnsubscribed);
  };

  const onParticipantConnected = (participant: RemoteParticipant) => {
    manageTracksForRemoteParticipant(participant);
  };

  const onParticipantDisconnected = () => {
    closeRoomAndRedirect(false);
  };

  const onTrackSubscribed = (track: RemoteTrack) => {
    if (!trackExistsAndIsAttachable(track)) {
      return;
    }

    attachTrack(track);
  };

  const onTrackUnsubscribed = (track: RemoteTrack) => {
    if (trackExistsAndIsAttachable(track)) {
      track.detach().forEach(element => element.remove());
    }
  };

  const attachAttachableTracksForRemoteParticipant = (
    participant: RemoteParticipant,
  ) => {
    participant.tracks.forEach(publication => {
      if (!publication.isSubscribed) {
        return;
      }

      if (!trackExistsAndIsAttachable(publication.track)) {
        return;
      }

      attachTrack(publication.track);
    });
  };

  const trackExistsAndIsAttachable = (
    track?: RemoteTrack | null,
  ): track is RemoteAudioTrack | RemoteVideoTrack => {
    return (
      !!track &&
      ((track as RemoteAudioTrack).attach !== undefined ||
        (track as RemoteVideoTrack).attach !== undefined)
    );
  };

  const attachTrack = (track: RemoteAudioTrack | RemoteVideoTrack) => {
    setReceiverEntered(true);
    track.attach('#remoteVideo');
  };

  useEffect(() => {
    setAudioEnabled(true);
    if (existingRoom) {
      generateToken(props.subjectId, props.procedureId);
    } else {
      createRoom(props.subjectId, props.procedureId, props.fromInvitation);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existingRoom]);

  useEffect(() => {
    if (token) {
      connectToRoom();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <View style={styles.mainContainer}>
      <View style={styles.contentContainer}>
        {!endingCall ? (
          <View>
            <View style={styles.participantsContainer}>
              <View style={styles.photosContainer}>
                {authStore.authData?.user.photo ? (
                  <Image
                    source={{uri: authStore.authData?.user.photo.url}}
                    style={styles.imageThumbnailStyle}
                  />
                ) : (
                  <View style={styles.defaultThumbnailPhotoContainer}>
                    <SVGIcon name="user" size={24} />
                  </View>
                )}
                <View style={styles.lowerThumbnailContainer}>
                  {props.receiver?.photo ? (
                    <Image
                      source={{uri: props.receiver.photo.url}}
                      style={styles.imageThumbnailStyle}
                    />
                  ) : (
                    <View style={styles.defaultThumbnailPhotoContainer}>
                      <SVGIcon name="user" size={24} />
                    </View>
                  )}
                </View>
              </View>
              <View style={styles.namesContainer}>
                <RNEText
                  label={`${
                    authStore.userRole === 'practitioner'
                      ? `Dr. ${authStore.authData?.user.firstName}, ${props.receiver?.humanName.given[0]}`
                      : `${authStore.authData?.user.firstName}, Dr. ${props.receiver?.humanName.given[0]}`
                  }`}
                  variant="title"
                  color="white"
                  style={titleFontSize20}
                />
              </View>
            </View>
            <View style={styles.selfVideoContainer}>
              <video
                id="localVideo"
                width={310}
                // eslint-disable-next-line react-native/no-inline-styles
                style={{
                  position: 'absolute',
                  marginRight: 20,
                  marginTop: 20,
                  transform: 'scale(-1, 1)',
                }}
              />
            </View>
            {receiverHasEntered ? (
              <View style={styles.remoteVideoContainer}>
                <video
                  id="remoteVideo"
                  width={310}
                  // eslint-disable-next-line react-native/no-inline-styles
                  style={{
                    width: '100%',
                    height: '100vh',
                    alignSelf: 'center',
                  }}
                />
              </View>
            ) : (
              <>
                {noLocalVideo ? (
                  <View style={styles.noCameraTextContainer}>
                    <RNEText
                      label="Your camera needs to be connected to make a video call. This call will be ended."
                      variant="title"
                      color="white"
                      style={titleFontSize20}
                      align="center"
                    />
                    <RNEButton
                      height={160}
                      loadingProps={loadingPropsStyle}
                      loading={true}
                    />
                  </View>
                ) : (
                  <View style={styles.nameContainer}>
                    {props.receiver?.photo ? (
                      <Image
                        source={{uri: props.receiver.photo.url}}
                        style={styles.imageStyle}
                      />
                    ) : (
                      <View style={styles.defaultPhotoContainer}>
                        <SVGIcon name="user" size={100} />
                      </View>
                    )}
                    <RNEText
                      label={`${
                        authStore.userRole === 'patient' ? 'Dr.' : ''
                      } ${props.receiver?.humanName.given[0]} ${
                        props.receiver?.humanName.family
                      }`}
                      variant="title"
                      color="white"
                      align="center"
                      weight="bold"
                    />
                    <RNEText
                      label="Connecting..."
                      variant="body"
                      color="white"
                      align="center"
                    />
                  </View>
                )}
              </>
            )}
          </View>
        ) : (
          <View style={styles.endingCallContainer}>
            <RNEText
              label="Ending call..."
              variant="title"
              color="white"
              align="center"
              weight="400"
            />
            <RNEButton
              height={160}
              loadingProps={loadingPropsStyle}
              loading={true}
            />
          </View>
        )}
      </View>

      {!endingCall && !noLocalVideo && (
        <View style={styles.buttonsContainer}>
          <TouchableOpacity>
            <RNEIconButton
              icon={
                isAudioEnabled ? (
                  <SVGIcon name="mute-5" size={20} />
                ) : (
                  <SVGIcon name="unmute-04" size={20} />
                )
              }
              color="white"
              size={60}
              raised
              onPress={onMuteButtonPress}
            />
          </TouchableOpacity>
          <TouchableOpacity>
            <RNEIconButton
              icon={<SVGIcon name="call-28" size={20} />}
              color="white"
              size={60}
              raised
              onPress={() => closeRoomAndRedirect(true)}
            />
          </TouchableOpacity>
        </View>
      )}
    </View>
  );
};

export default ConsultationVideoCallScreen;

const loadingPropsStyle = {
  color: colorPalette.white,
  size: 100,
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'center',
};

const titleFontSize20 = {
  fontSize: 20,
};

const useStyles = makeStyles({
  mainContainer: {
    width: '100vw',
    height: '100vh',
    // flex: 1,
    backgroundColor: 'rgba(103, 128, 159, 1)',
  },
  contentContainer: {
    flex: 1,
  },
  buttonsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    paddingBottom: 40,
  },
  participantsContainer: {
    alignItems: 'flex-start',
    flexDirection: 'row',
    marginLeft: 20,
    marginTop: 20,
    position: 'absolute',
  },
  defaultThumbnailPhotoContainer: {
    height: 40,
    width: 40,
    backgroundColor: colorPalette.white,
    borderRadius: 100,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: colorPalette.white,
  },
  photosContainer: {
    flexDirection: 'row',
  },
  lowerThumbnailContainer: {
    top: 8,
    right: 8,
  },
  namesContainer: {
    marginLeft: 8,
    alignSelf: 'center',
  },
  selfVideoContainer: {
    alignItems: 'flex-end',
  },
  nameContainer: {
    top: '30vh',
  },
  imageStyle: {
    height: 160,
    width: 160,
    borderRadius: 100,
    alignSelf: 'center',
  },
  defaultPhotoContainer: {
    height: 160,
    width: 160,
    backgroundColor: colorPalette.white,
    borderRadius: 100,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
  },
  imageThumbnailStyle: {
    height: 40,
    width: 40,
    borderRadius: 100,
    borderWidth: 1,
    borderColor: colorPalette.white,
  },
  endingCallContainer: {
    justifyContent: 'center',
    height: '100%',
  },
  remoteVideoContainer: {
    zIndex: -1,
  },
  noCameraTextContainer: {
    top: '45vh',
  },
});
