import React, {memo} from 'react';
import {View} from 'react-native';

import {makeStyles} from 'rne-netzon';
import {colorPalette} from '../../../core/config/color.config';

import {RNEText, RNEInput, RNEButton, SVGIcon} from '../../components';

interface AdminSearchHeaderProps {
  title: string;
  placeholder?: string;
  searchValue?: string;
  showFilter?: boolean;
  onSearchValueChange?: (val: string) => void;
  onFilterClick?: () => void;
  onExportClick?: () => void;
}

const AdminSearchHeader = (props: AdminSearchHeaderProps) => {
  const styles = useStyle();

  const {title = '', placeholder = 'Search', showFilter = true} = props;

  const onSearchChange = (val: string) => {
    props.onSearchValueChange?.(val);
  };

  return (
    <View style={styles.rootContainer}>
      <View style={styles.titleContainer}>
        <RNEText
          label={title}
          color="accent"
          variant="subheader"
          weight="600"
        />
      </View>

      <View style={styles.rightGroupContainer}>
        <View>
          <RNEInput
            leftIcon={<SVGIcon name="search" size={24} />}
            placeholder={placeholder}
            value={props.searchValue}
            onChangeText={onSearchChange}
          />
        </View>

        {/* <View style={styles.actionContainer}>
          {showFilter && (
            <View style={styles.action}>
              <RNEButton
                title="Filter"
                type="clear"
                icon={<SVGIcon name="menu-light-blue" />}
                titleStyle={actionTitleStyle}
              />
            </View>
          )}

          <View style={styles.action}>
            <RNEButton
              title="Export"
              type="clear"
              icon={<SVGIcon name="download-down" />}
              titleStyle={actionTitleStyle}
            />
          </View>
        </View> */}
      </View>
    </View>
  );
};

const actionTitleStyle = {
  color: colorPalette.black,
  marginLeft: 12,
};

const useStyle = makeStyles({
  rootContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  titleContainer: {
    flexDirection: 'row',
    flex: 1,
    flexWrap: 'wrap',
  },
  rightGroupContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  searchContainer: {
    width: 350,
  },
  actionContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  action: {
    marginLeft: 24,
  },
});

export default memo(AdminSearchHeader);
