import React, {useEffect} from 'react';
import {Platform, View} from 'react-native';
import {makeStyles} from 'rne-netzon';
import {RNEText} from '../../components';
import {REACT_APP_BASE_WEB_URL} from '../../server/api/api.config';

const ConsultationPaymentSuccessScreen = () => {
  const styles = useStyles();

  useEffect(() => {
    if (Platform.OS === 'web') {
      setTimeout(() => {
        const url = `${REACT_APP_BASE_WEB_URL}main/consultation/list?screen=ongoing&tab=0`;
        window.open(url, '_self');
      }, 5000);
    }
  }, []);

  return (
    <View style={styles.mainContainer}>
      <View style={styles.titleContainer}>
        <RNEText
          label="Payment Successful!"
          color="accent"
          weight="600"
          variant="title"
        />
      </View>
      <View style={styles.subtitleContainer}>
        <RNEText
          label="You will be redirected back to the app. It might take a few seconds."
          color="black"
          variant="body"
        />
      </View>
    </View>
  );
};

export default ConsultationPaymentSuccessScreen;

const useStyles = makeStyles({
  mainContainer: {
    padding: 100,
    alignItems: 'center',
  },
  titleContainer: {
    paddingVertical: 40,
  },
  subtitleContainer: {
    paddingVertical: 20,
  },
});
