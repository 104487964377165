import React, {useState, useEffect, useCallback} from 'react';

const kInitialPageNumber = 1;
const kInitialPageSize = 1;
const kInitialTotalPage = 1;
const kInitialCanGoBack = false;
const kInitialCanGoNext = false;

interface PaginationProps {
  pageNumber?: number;
  pageSize?: number;
  totalPage?: number;
}

const initialPaginationPropsData: PaginationProps = {
  pageNumber: kInitialPageNumber,
  pageSize: kInitialPageSize,
  totalPage: kInitialTotalPage,
};

const usePagination = (props: PaginationProps = initialPaginationPropsData) => {
  const [pageNumber, setPageNumber] = useState<number>(kInitialPageNumber);
  const [pageSize, setPageSize] = useState<number>(kInitialPageSize);
  const [totalPage, setTotalPage] = useState<number>(kInitialTotalPage);

  const [canGoBack, setCanGoBack] = useState<boolean>(kInitialCanGoBack);
  const [canGoNext, setCanGoNext] = useState<boolean>(kInitialCanGoNext);

  const goBack = () => {
    if (canGoBack) {
      const newPageNumber = pageNumber - 1;
      setPageNumber(newPageNumber);
      calculateGoBackState(newPageNumber);
      calculateGoNextState(newPageNumber);
    }
  };

  const goNext = () => {
    if (canGoNext) {
      const newPageNumber = pageNumber + 1;
      setPageNumber(newPageNumber);
      calculateGoBackState(newPageNumber);
      calculateGoNextState(newPageNumber);
    }
  };

  const goToPage = (pageNum: number) => {
    if (pageNum > 0 && pageNum <= totalPage && pageNum !== pageNumber) {
      setPageNumber(pageNum);
      calculateGoBackState(pageNum);
      calculateGoNextState(pageNum);
    }
  };

  const setPageData = (pageData: PaginationProps) => {
    if (pageData.pageSize !== undefined) {
      setPageSize(pageData.pageSize || kInitialPageSize);
    }

    if (pageData.totalPage !== undefined) {
      setTotalPage(pageData.totalPage || kInitialTotalPage);
    }

    if (pageData.pageNumber !== undefined) {
      setPageNumber(pageData.pageNumber || kInitialPageNumber);
      calculateGoBackState(pageData.pageNumber);
      calculateGoNextState(pageData.pageNumber);
    }
  };

  const calculateGoBackState = (newPageNumber: number) => {
    setCanGoBack(newPageNumber > 1);
  };

  const calculateGoNextState = useCallback(
    (newPageNumber: number) => {
      setCanGoNext(newPageNumber < totalPage);
    },
    [totalPage],
  );

  useEffect(() => {
    if (props) {
      setPageNumber(props.pageNumber || kInitialPageNumber);
      setPageSize(props.pageSize || kInitialPageSize);
      setTotalPage(props.totalPage || kInitialTotalPage);
      calculateGoBackState(props.pageNumber || kInitialPageNumber);
      calculateGoNextState(props.pageNumber || kInitialPageNumber);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  useEffect(() => {
    calculateGoBackState(pageNumber);
    calculateGoNextState(pageNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalPage, pageNumber]);

  return {
    totalPage,
    pageNumber,
    pageSize,
    canGoBack,
    canGoNext,
    goBack,
    goNext,
    goToPage,
    setPageData,
  };
};

export default usePagination;
