import React from 'react';
import {Platform} from 'react-native';
import {DefaultTheme, NavigationContainer} from '@react-navigation/native';
import {SafeAreaProvider} from 'react-native-safe-area-context';
import RNBootSplash from 'react-native-bootsplash';

import {ReactQueryProvider} from './app/server/react-query';

import FastToastProvider from './app/components/toast/FastToast';
import ThemeRNE from './theme/theme-rne';
import {MenuProvider} from 'react-native-popup-menu';

import RootStackNavigator from './app/navigations';

import {StoreProvider} from './app/stores/useStores';
import {initializeApp} from 'firebase/app';
import {getMessaging, onMessage} from 'firebase/messaging';
import {firebaseConfig} from './app/utils/firebase-config.utils';

const navTheme = DefaultTheme;
navTheme.colors.background = 'white';
navTheme.dark = false;

const App = () => {
  const app = initializeApp(firebaseConfig);
  const messaging = getMessaging(app);
  const ringingNotif = require('./assets/audio/ringing.mp3');

  navigator.serviceWorker.addEventListener('message', function (event) {
    if (event.data === 'Start ringing notification') {
      soundNotification(true);
    } else if (event.data === 'Stop ringing notification') {
      soundNotification(false);
    }
  });

  onMessage(messaging, payload => {
    console.log('Message received. ', payload);

    if (payload.data?.title === 'INCOMING CALL') {
      soundNotification(true);
      alert(`${payload.data?.title} ${payload.data?.body}`); // eslint-disable-line no-alert
    } else {
      soundNotification(false);
    }
  });

  const onNavigationReady = () => {
    if (Platform.OS !== 'web') {
      setTimeout(() => {
        RNBootSplash.hide({fade: true});
      }, 350);
    }
  };

  const soundNotification = (play: boolean) => {
    const audio = document.getElementById(
      'ringingNotification',
    ) as HTMLAudioElement;

    if (play) {
      audio.muted = false;
      audio.play();
    } else {
      audio.muted = true;
      audio.pause();
    }
  };

  return (
    <StoreProvider>
      <ReactQueryProvider>
        <ThemeRNE>
          <MenuProvider>
            <SafeAreaProvider>
              <NavigationContainer
                linking={{
                  enabled: true,
                  prefixes: [],
                }}
                theme={navTheme}
                onReady={onNavigationReady}>
                <audio
                  loop
                  muted={true}
                  preload="auto"
                  id="ringingNotification"
                  src={ringingNotif.default}></audio>
                <FastToastProvider>
                  <RootStackNavigator />
                </FastToastProvider>
              </NavigationContainer>
            </SafeAreaProvider>
          </MenuProvider>
        </ThemeRNE>
      </ReactQueryProvider>
    </StoreProvider>
  );
};

export default App;
