import React, {useState, useEffect} from 'react';
import {View, Platform, ScrollView} from 'react-native';
import {makeStyles} from 'rne-netzon';
import {colorPalette} from '../../../core/config/color.config';
import {RNEText, RNEButton, RNEInput} from '../../components';

import {useForm, Controller, useFieldArray} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';

import {
  MedicationRequestArrayUI,
  MedicationRequestReqBodyUI,
  MedicationRequestStatusEnum,
} from '../../server/types/medical-attachment.types';
import {useToast} from 'react-native-fast-toast';
import {
  useAddPrescription,
  useGetPrescriptions,
  useUpdatePrescription,
} from '../../server/react-query/useMedicalAttachment';
import {useNavigation, useRoute} from '@react-navigation/native';
import {useSendMobilePushNotification} from '../../server/react-query/usePushNotification';
import {REACT_APP_BASE_WEB_URL} from '../../server/api/api.config';
import useStores from '../../stores/useStores';

interface AddPrescriptionViewProps {
  basedOnId: string;
  subject: string;
  userId: string;
}

const schema: yup.SchemaOf<MedicationRequestArrayUI> = yup.object().shape({
  prescriptions: yup
    .array()
    .of(
      yup.object().shape({
        generic: yup.string().trim().required('This field is required.'),
        brand: yup.string().trim().required('This field is required.'),
        dose: yup.string().trim().required('This field is required.'),
        frequency: yup.string().trim().required('This field is required.'),
        quantity: yup.string().trim().required('This field is required.'),
        generalInstruction: yup.string(),
      }),
    )
    .required(),
});

const AddPrescriptionView = (props: AddPrescriptionViewProps) => {
  const {authStore} = useStores();
  const webStyles = useWebStyles();
  const mobileStyles = useMobileStyles();
  const toast = useToast();
  const route = useRoute();
  const navigation = useNavigation();

  const {data, isFetching, refetch} = useGetPrescriptions({
    basedOnId: props.basedOnId,
    status: MedicationRequestStatusEnum.DRAFT,
  });

  const {
    control,
    handleSubmit,
    getValues,
    reset,
    trigger,
    formState: {errors, isValid},
  } = useForm<MedicationRequestArrayUI>({
    mode: 'all',
    defaultValues: {
      prescriptions: [
        {
          generic: '',
          brand: '',
          dose: '',
          frequency: '',
          quantity: '',
          generalInstruction: '',
        },
      ],
    },
    resolver: yupResolver(schema),
  });

  const {fields, remove, prepend, append} = useFieldArray({
    control,
    name: 'prescriptions',
  });

  const [basedOnId, setBasedOnId] = useState('');
  const [groupId, setGroupId] = useState('');
  const [status, setStatus] = useState<MedicationRequestStatusEnum>(
    MedicationRequestStatusEnum.DRAFT,
  );

  const addMutation = useAddPrescription(basedOnId, status, getValues());

  const updateMutation = useUpdatePrescription(
    groupId,
    basedOnId,
    status,
    getValues(),
    basedOnId,
  );

  const [mutation, setMutation] = useState(addMutation);

  const prependInscription = () => {
    prepend({
      generic: '',
      brand: '',
      dose: '',
      frequency: '',
      quantity: '',
      generalInstruction: '',
    });
  };

  const savePrescription = (requestStatus: MedicationRequestStatusEnum) => {
    setStatus(requestStatus);
    trigger('prescriptions');
    handleSubmit(onSubmit)();
  };

  const navProps = {
    procedureId: props.basedOnId,
    subjectId: props.subject,
    receiver: props.subject,
    serviceRequestId: props.basedOnId,
  };

  const triggerPushNotification = useSendMobilePushNotification({
    title: 'New chat message for the patient',
    body: 'Your consultation chat has a new message.',
    consultation: `"${JSON.stringify(navProps)}"`,
    notificationUrl: `${REACT_APP_BASE_WEB_URL}main/consultation/detail?id=${props.basedOnId}&screen=pending&tab=0`,
    fromUserId: authStore.authData?.user.userId!,
    toUserId: props.userId,
  });

  const onSubmit = async () => {
    if (isValid) {
      try {
        await mutation.mutateAsync();
        refetch();

        reset();
        mutation.reset();
        navigation.goBack();
        triggerPushNotification.mutateAsync();
      } catch (error) {
        if (error?.data?.error) {
          toast?.show(error?.data?.error, {type: 'danger'});
        }
      }
    }
  };

  useEffect(() => {
    const params = route.params as any;

    if (params) {
      setBasedOnId(params.basedOnId);
    }
  }, [route.params]);

  useEffect(() => {
    if (data && data.length > 0) {
      const prescriptions: MedicationRequestReqBodyUI[] = [];

      data.map(prescription => {
        const inscription: MedicationRequestReqBodyUI = {
          generic:
            prescription.medicationCodeableConcept.code?.find(
              med => med.system === 'generic-name',
            )?.display || '',
          brand:
            prescription.medicationCodeableConcept.code?.find(
              med => med.system === 'brand-name',
            )?.display || '',
          dose: prescription.dosageInstruction.doseAndRate.doseText,
          frequency: prescription.dosageInstruction.timing.text,
          quantity: prescription.dosageInstruction.doseAndRate.rateText,
          generalInstruction: prescription.note.text,
        };

        prescriptions.push(inscription);
      });

      append(prescriptions);
      setGroupId(data?.[0]?.groupId);
      setMutation(updateMutation);
    } else {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (groupId) {
      remove(0);
    }
  }, [groupId, remove]);

  useEffect(() => {
    if (props.basedOnId) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  return (
    <>
      {Platform.OS === 'web' ? (
        <ScrollView>
          <View>
            <View style={webStyles.formContainer}>
              <View style={webStyles.rightContainer}>
                <RNEText
                  label="Inscription"
                  style={subtitleStyle}
                  variant="title"
                  weight="bold"
                />

                {fields.map((prescription, index) => {
                  return (
                    <View
                      style={webStyles.labelGroupContainer}
                      key={prescription.id}>
                      <View style={webStyles.rowGroupContainer}>
                        <View style={webStyles.inputContainer}>
                          <View style={webStyles.inputLabel}>
                            <RNEText
                              label="Generic"
                              variant="button"
                              color="accent"
                              weight="bold"
                            />
                          </View>
                          <Controller
                            control={control}
                            render={({field: {onChange, onBlur, value}}) => (
                              <RNEInput
                                height={60}
                                placeholder="Enter Generic Name"
                                inputContainerStyle={inputContainerWebStyle}
                                defaultValue={`${prescription.generic}`}
                                onChangeText={text => {
                                  onChange(text);
                                }}
                                errorMessage={
                                  errors.prescriptions?.[index]?.generic
                                    ?.message
                                }
                                variant="shadowed"
                              />
                            )}
                            defaultValue={prescription.generic}
                            name={`prescriptions.${index}.generic`}
                          />
                        </View>

                        <View style={webStyles.inputContainer}>
                          <View style={webStyles.inputLabel}>
                            <RNEText
                              label="Brand"
                              variant="button"
                              color="accent"
                              weight="bold"
                            />
                          </View>
                          <Controller
                            control={control}
                            render={({field: {onChange, onBlur, value}}) => (
                              <RNEInput
                                height={60}
                                placeholder="Enter Brand Name"
                                inputContainerStyle={inputContainerWebStyle}
                                defaultValue={`${prescription.brand}`}
                                onChangeText={text => {
                                  onChange(text);
                                }}
                                errorMessage={
                                  errors.prescriptions?.[index]?.brand?.message
                                }
                                variant="shadowed"
                              />
                            )}
                            defaultValue={prescription.brand}
                            name={`prescriptions.${index}.brand`}
                          />
                        </View>
                      </View>

                      <View style={webStyles.rowGroupContainer}>
                        <View style={webStyles.inputContainer}>
                          <View style={webStyles.inputLabel}>
                            <RNEText
                              label="Dose"
                              variant="button"
                              color="accent"
                              weight="bold"
                            />
                          </View>
                          <Controller
                            control={control}
                            render={({field: {onChange, onBlur, value}}) => (
                              <RNEInput
                                height={60}
                                placeholder="Enter Dosage"
                                inputContainerStyle={inputContainerWebStyle}
                                defaultValue={`${prescription.dose}`}
                                onChangeText={text => {
                                  onChange(text);
                                }}
                                errorMessage={
                                  errors.prescriptions?.[index]?.dose?.message
                                }
                                variant="shadowed"
                              />
                            )}
                            defaultValue={prescription.dose}
                            name={`prescriptions.${index}.dose`}
                          />
                        </View>

                        <View style={webStyles.inputContainer}>
                          <View style={webStyles.inputLabel}>
                            <RNEText
                              label="Frequency"
                              variant="button"
                              color="accent"
                              weight="bold"
                            />
                          </View>
                          <Controller
                            control={control}
                            render={({field: {onChange, onBlur, value}}) => (
                              <RNEInput
                                height={60}
                                placeholder="i.e. to be taken once a week"
                                inputContainerStyle={inputContainerWebStyle}
                                defaultValue={`${prescription.frequency}`}
                                onChangeText={text => {
                                  onChange(text);
                                }}
                                errorMessage={
                                  errors.prescriptions?.[index]?.frequency
                                    ?.message
                                }
                                variant="shadowed"
                              />
                            )}
                            defaultValue={prescription.frequency}
                            name={`prescriptions.${index}.frequency`}
                          />
                        </View>

                        <View style={webStyles.inputContainer}>
                          <View style={webStyles.inputLabel}>
                            <RNEText
                              label="Quantity"
                              variant="button"
                              color="accent"
                              weight="bold"
                            />
                          </View>
                          <Controller
                            control={control}
                            render={({field: {onChange, onBlur, value}}) => (
                              <RNEInput
                                height={60}
                                placeholder="Enter Quantity"
                                inputContainerStyle={inputContainerWebStyle}
                                defaultValue={`${prescription.quantity}`}
                                onChangeText={text => {
                                  onChange(text);
                                }}
                                errorMessage={
                                  errors.prescriptions?.[index]?.quantity
                                    ?.message
                                }
                                variant="shadowed"
                              />
                            )}
                            defaultValue={prescription.quantity}
                            name={`prescriptions.${index}.quantity`}
                          />
                        </View>
                      </View>

                      <View style={webStyles.rowGroupContainer}>
                        <View style={webStyles.inputContainer}>
                          <View style={webStyles.inputLabel}>
                            <RNEText
                              label="General Instruction"
                              variant="button"
                              color="accent"
                              weight="bold"
                            />
                          </View>
                          <Controller
                            control={control}
                            render={({field: {onChange, onBlur, value}}) => (
                              <RNEInput
                                height={80}
                                multiline={true}
                                placeholder="Instruction"
                                inputContainerStyle={textareaContainerWebStyle}
                                defaultValue={`${prescription.generalInstruction}`}
                                onChangeText={text => {
                                  onChange(text);
                                }}
                                errorMessage={
                                  errors.prescriptions?.[index]
                                    ?.generalInstruction?.message
                                }
                                variant="shadowed"
                              />
                            )}
                            defaultValue={prescription.generalInstruction}
                            name={`prescriptions.${index}.generalInstruction`}
                          />
                        </View>
                      </View>

                      <View style={webStyles.actionButtonContainer}>
                        <View style={webStyles.actionButtonWrapper}>
                          {index === fields.length - 1 ? (
                            <RNEButton
                              height={60}
                              title="Add Inscription"
                              color="primary"
                              style={webStyles.actionButtonStyle}
                              onPress={prependInscription}
                            />
                          ) : (
                            <RNEButton
                              height={60}
                              title="Remove"
                              color="primary"
                              style={webStyles.actionButtonStyle}
                              variant="outlined"
                              onPress={() => remove(index)}
                            />
                          )}
                        </View>
                      </View>
                    </View>
                  );
                })}

                <View style={webStyles.buttonsContainer}>
                  <RNEButton
                    title="Cancel"
                    color="accent"
                    style={webStyles.buttonStyle}
                    variant="outlined"
                    height={60}
                    onPress={navigation.goBack}
                  />
                  <View style={webStyles.rightButtonsContainer}>
                    <View style={webStyles.buttonContainer}>
                      <RNEButton
                        title="Save Draft"
                        color="secondary"
                        style={webStyles.buttonStyle}
                        variant="outlined"
                        height={60}
                        loadingProps={loadingStyle}
                        loading={
                          (addMutation.isLoading || updateMutation.isLoading) &&
                          status === MedicationRequestStatusEnum.DRAFT
                        }
                        onPress={() =>
                          savePrescription(MedicationRequestStatusEnum.DRAFT)
                        }
                      />
                    </View>
                    <RNEButton
                      title="Submit"
                      color="secondary"
                      style={webStyles.buttonStyle}
                      height={60}
                      loading={
                        (addMutation.isLoading || updateMutation.isLoading) &&
                        status === MedicationRequestStatusEnum.COMPLETED
                      }
                      onPress={() =>
                        savePrescription(MedicationRequestStatusEnum.COMPLETED)
                      }
                    />
                  </View>
                </View>
              </View>
            </View>
          </View>
        </ScrollView>
      ) : (
        <ScrollView>
          <View style={mobileStyles.mainContainer}>
            <View style={mobileStyles.titleContainer}>
              <RNEText
                label="E-prescription"
                color="accent"
                variant="title"
                weight="bold"
              />
            </View>
            <View style={mobileStyles.bodyContainer}>
              {fields.map((prescription, index) => {
                return (
                  <View
                    style={mobileStyles.formContainer}
                    key={prescription.id}>
                    <View style={mobileStyles.subtitleContainer}>
                      <RNEText
                        label="INSCRIPTION"
                        variant="body"
                        weight="bold"
                      />
                    </View>
                    <View style={mobileStyles.inputContainer}>
                      <Controller
                        control={control}
                        render={({field: {onChange, onBlur, value}}) => (
                          <RNEInput
                            placeholder="Enter Generic Name"
                            defaultValue={`${prescription.generic}`}
                            onChangeText={text => {
                              onChange(text);
                            }}
                            errorMessage={
                              errors.prescriptions?.[index]?.generic?.message
                            }
                          />
                        )}
                        defaultValue={prescription.generic}
                        name={`prescriptions.${index}.generic`}
                      />
                    </View>
                    <View style={mobileStyles.inputContainer}>
                      <Controller
                        control={control}
                        render={({field: {onChange, onBlur, value}}) => (
                          <RNEInput
                            placeholder="Enter Brand Name"
                            defaultValue={`${prescription.brand}`}
                            onChangeText={text => {
                              onChange(text);
                            }}
                            errorMessage={
                              errors.prescriptions?.[index]?.brand?.message
                            }
                          />
                        )}
                        defaultValue={prescription.brand}
                        name={`prescriptions.${index}.brand`}
                      />
                    </View>
                    <View style={mobileStyles.inputContainer}>
                      <Controller
                        control={control}
                        render={({field: {onChange, onBlur, value}}) => (
                          <RNEInput
                            placeholder="Enter Dosage"
                            defaultValue={`${prescription.dose}`}
                            onChangeText={text => {
                              onChange(text);
                            }}
                            errorMessage={
                              errors.prescriptions?.[index]?.dose?.message
                            }
                          />
                        )}
                        defaultValue={prescription.dose}
                        name={`prescriptions.${index}.dose`}
                      />
                    </View>
                    <View style={mobileStyles.inputContainer}>
                      <Controller
                        control={control}
                        render={({field: {onChange, onBlur, value}}) => (
                          <RNEInput
                            placeholder="i.e. to be taken once a week"
                            defaultValue={`${prescription.frequency}`}
                            onChangeText={text => {
                              onChange(text);
                            }}
                            errorMessage={
                              errors.prescriptions?.[index]?.frequency?.message
                            }
                          />
                        )}
                        defaultValue={prescription.frequency}
                        name={`prescriptions.${index}.frequency`}
                      />
                    </View>
                    <View style={mobileStyles.inputContainer}>
                      <Controller
                        control={control}
                        render={({field: {onChange, onBlur, value}}) => (
                          <RNEInput
                            placeholder="Enter Quantity"
                            defaultValue={`${prescription.quantity}`}
                            onChangeText={text => {
                              onChange(text);
                            }}
                            errorMessage={
                              errors.prescriptions?.[index]?.quantity?.message
                            }
                          />
                        )}
                        defaultValue={prescription.quantity}
                        name={`prescriptions.${index}.quantity`}
                      />
                    </View>
                    <View style={mobileStyles.inputContainer}>
                      <Controller
                        control={control}
                        render={({field: {onChange, onBlur, value}}) => (
                          <RNEInput
                            height={80}
                            multiline={true}
                            textAlignVertical="top"
                            inputContainerStyle={textareaContainerMobileStyle}
                            placeholder="Instruction"
                            defaultValue={`${prescription.generalInstruction}`}
                            onChangeText={text => {
                              onChange(text);
                            }}
                            errorMessage={
                              errors.prescriptions?.[index]?.generalInstruction
                                ?.message
                            }
                          />
                        )}
                        defaultValue={prescription.generalInstruction}
                        name={`prescriptions.${index}.generalInstruction`}
                      />
                    </View>

                    <View style={mobileStyles.actionButtonContainer}>
                      {index === fields.length - 1 ? (
                        <RNEButton
                          title="Add Inscription"
                          color="primary"
                          onPress={prependInscription}
                        />
                      ) : (
                        <RNEButton
                          title="Remove"
                          color="primary"
                          variant="outlined"
                          onPress={() => remove(index)}
                        />
                      )}
                    </View>
                  </View>
                );
              })}
            </View>
            <View style={mobileStyles.buttonsContainer}>
              <RNEButton
                title="Cancel"
                color="accent"
                variant="outlined"
                onPress={navigation.goBack}
              />
              <View style={mobileStyles.rightButtonsContainer}>
                <View style={mobileStyles.buttonContainer}>
                  <RNEButton
                    title="Save Draft"
                    color="secondary"
                    variant="outlined"
                    loadingProps={loadingStyle}
                    loading={
                      (addMutation.isLoading || updateMutation.isLoading) &&
                      status === MedicationRequestStatusEnum.DRAFT
                    }
                    onPress={() =>
                      savePrescription(MedicationRequestStatusEnum.DRAFT)
                    }
                  />
                </View>
                <RNEButton
                  title="Submit"
                  color="secondary"
                  loading={
                    (addMutation.isLoading || updateMutation.isLoading) &&
                    status === MedicationRequestStatusEnum.COMPLETED
                  }
                  onPress={() =>
                    savePrescription(MedicationRequestStatusEnum.COMPLETED)
                  }
                />
              </View>
            </View>
          </View>
        </ScrollView>
      )}
    </>
  );
};

export default AddPrescriptionView;

const subtitleStyle = {
  fontSize: 16,
  paddingLeft: 24,
};

const inputContainerWebStyle = {
  borderRadius: 20,
};

const textareaContainerWebStyle = {
  borderRadius: 20,
  padding: 20,
};

const textareaContainerMobileStyle = {
  borderRadius: 4,
  padding: 12,
};

const loadingStyle = {
  color: colorPalette.secondary,
};

const useWebStyles = makeStyles({
  titleContainer: {
    flexDirection: 'row',
    marginHorizontal: 100,
    paddingVertical: 20,
    borderBottomWidth: 1,
    borderBottomColor: colorPalette.grey,
  },
  headerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 100,
    paddingRight: 100,
    paddingVertical: 60,
  },
  formContainer: {
    backgroundColor: colorPalette.white,
    borderRadius: 20,
    paddingHorizontal: 12,
    marginBottom: 80,
    flexDirection: 'row',
  },
  leftContainer: {
    flexShrink: 0,
    borderRightColor: 'rgba(229, 229, 229, 0.7)',
    borderRightWidth: 1,
    paddingVertical: 60,
    paddingRight: 32,
  },
  subtitleContainer: {
    marginBottom: 40,
    paddingLeft: 12,
  },
  infoContainer: {
    marginBottom: 64,
  },
  labelGroupContainer: {
    width: '100%',
    marginTop: 40,
    marginBottom: 20,
    borderBottomColor: 'rgba(229, 229, 229, 0.5)',
    borderBottomWidth: 1.7,
  },
  rowGroupContainer: {
    flexDirection: 'row',
    marginBottom: 60,
  },
  inputContainer: {
    marginRight: 40,
    marginBottom: 4,
    flex: 1,
  },
  inputLabel: {
    paddingLeft: 24,
    marginBottom: 4,
  },
  actionButtonContainer: {
    alignSelf: 'flex-end',
    marginBottom: 60,
  },
  actionButtonWrapper: {
    marginHorizontal: 20,
  },
  rightContainer: {
    flex: 3,
  },
  buttonContainer: {
    marginRight: 20,
  },
  buttonsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 40,
    marginBottom: 20,
    marginRight: 40,
    flexWrap: 'wrap',
  },
  rightButtonsContainer: {
    flexDirection: 'row',
    marginLeft: 20,
    flexWrap: 'wrap',
  },
  buttonStyle: {
    width: 140,
  },
  actionButtonStyle: {
    width: 260,
  },
});

const useMobileStyles = makeStyles({
  mainContainer: {
    width: '100%',
    flex: 1,
    paddingHorizontal: 52,
    paddingBottom: 52,
    paddingTop: 20,
  },
  titleContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  bodyContainer: {
    paddingVertical: 40,
  },
  subtitleContainer: {
    marginBottom: 24,
  },
  formContainer: {
    marginBottom: 20,
    borderBottomColor: 'rgba(229, 229, 229, 0.5)',
    borderBottomWidth: 1.7,
  },
  inputContainer: {
    marginBottom: 16,
  },
  actionButtonContainer: {
    marginTop: 16,
    marginBottom: 40,
    width: 132,
    alignSelf: 'flex-end',
  },
  buttonContainer: {
    marginRight: 8,
  },
  buttonsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  rightButtonsContainer: {
    flexDirection: 'row',
    marginLeft: 20,
    flexWrap: 'wrap',
  },
});
