export enum NavigationTitle {
  ROOT_STACK = 'Root',
  ONBOARDING_STACK = 'Onboarding',
  ONBOARDING_SCREEN_ENTRY = 'Onboarding Entry',
  ONBOARDING_SCREEN_SLIDER = 'Onboarding Slider',
  AUTH_STACK = 'Auth',
  LOGIN_SCREEN = 'Login',
  SIGNUP_SCREEN = 'Sign Up',
  SIGNUP_VERIFY_SCREEN = 'Verify Sign Up',
  FORGOT_PASSWORD_SCREEN = 'Forgot Password',
  RESET_PASSWORD_SCREEN = 'Reset Password',
  MAIN_DRAWER = 'Main',
  PATIENT_STACK = 'Patient',
  PATIENT_SCREEN_LANDING = 'Patient Menu',
  PATIENT_CONSULTATION_REQUEST = 'Patient Consultation Request',
  PATIENT_CONSULTATIONS = 'Patient Consultations',
  DOCTOR_STACK = 'Doctor',
  DOCTOR_SCREEN_LANDING = 'Doctor Menu',
  DOCTOR_VIEW_PROFESSIONAL_PROFILE = 'Doctor Professional Profile',
  DOCTOR_EDIT_PROFESSIONAL_PROFILE = 'Doctor Professional Profile Edit',
  DOCTOR_ACCOUNT = 'Doctor Account',
  DOCTOR_ACCOUNT_EDIT = 'Doctor Account Edit',
  DOCTOR_ADD_MEDICAL_ATTACHMENT = 'Add Medical Attachment',
  TANDC_SCREEN = 'Terms and Conditions',
  NOTIFICATIONS = 'Notifications',
  MESSAGES = 'Messages',
  HELP = 'Help',
  PATIENT_ACCOUNT = 'Patient Account',
  PATIENT_ACCOUNT_EDIT = 'Patient Account Edit',
  PATIENT_PROFILE_ADD = 'Patient Profile Add',
  PATIENT_PROFILE_EDIT = 'Patient Profile Edit',
  PATIENT_SEARCH_RESULT = 'Dashboard - Search a doctor',
  ADMIN_DASHBOARD = 'Dashboard',
  ADMIN_DOCTORS_LIST = 'Doctor Accounts',
  ADMIN_PROFILES_LIST = 'Profile Accounts',
  ADMIN_DELETED_PROFILES_LIST = 'Deleted Profiles',
  CONSULTATION_LIST = 'Consultations',
  CONSULTATION_PAYMENT_ENTRY = 'Payment Entry',
  CONSULTATION_PAYMENT_FAIL = 'Payment Failed',
  CONSULTATION_PAYMENT_SUCCESS = 'Payment Success',
  CONSULTATION_VIDEO_CALL = 'Consultation Video Call',
  PRIVACY_POLICY = 'Privacy Policy',
}
