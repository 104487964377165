import React from 'react';
import {Platform, TextStyle} from 'react-native';
import {createStackNavigator} from '@react-navigation/stack';
import {DrawerContentComponentProps} from '@react-navigation/drawer';
import {DrawerActions} from '@react-navigation/native';

import {colorPalette} from '../../../core/config/color.config';

import {NavigationKey} from '../config/navigation.config';
import {NavigationTitle} from '../config/navigationTitle';

import {
  AccountDeletionConfirmationScreen,
  PatientAddProfileNextScreen,
  PatientAddProfileScreen,
  PatientEditProfileNextScreen,
  PatientEditProfileScreen,
  PatientProfilesScreen,
} from '../../screens';
import {
  HeaderProfileRight,
  HeaderBackLeft,
  HeaderProfileLeft,
} from '../../views';

const {Navigator, Screen} = createStackNavigator();

const webOptions = {
  headerStyle: {
    backgroundColor: '#2BA8E81A',
    borderBottomColor: 'transparent',
    height: 72,
  },
  headerTintColor: colorPalette.primary,
  headerTitleStyle: {
    fontWeight: 'bold',
    fontFamily: 'Quicksand-Bold',
    fontSize: 16,
    paddingLeft: 40,
  } as TextStyle,
  headerLeftContainerStyle: {
    marginLeft: 24,
  },
};

const mobileGeneralOptions = {
  title: '',
  headerTintColor: colorPalette.primary,
};

const mobileBackHeaderWithTitleOptions = {
  headerStyle: {
    borderBottomColor: 'transparent',
  },
  headerTintColor: colorPalette.blackLight,
  headerTitleStyle: {
    fontFamily: 'Quicksand-Bold',
    fontSize: 14,
    fontWeight: 'bold',
    alignSelf: 'center',
    marginStart: -28,
  } as TextStyle,
  headerLeft: () => <HeaderBackLeft />,
};

const patientMobileNavigatorOptions = {
  headerStyle: {
    elevation: 0,
    shadowOpacity: 0,
  },
  headerTitleStyle: {
    fontWeight: 'bold',
    fontFamily: 'Quicksand-Bold',
    fontSize: 16,
  } as TextStyle,
};

const PatientProfileStackWebNavigator = () => {
  return (
    <Navigator>
      <Screen
        name={NavigationKey.PATIENT_PROFILES}
        component={PatientProfilesScreen}
        options={{
          title: 'Dashboard - Profiles',
          ...webOptions,
          headerLeft: () => null,
        }}
      />
      <Screen
        name={NavigationKey.PATIENT_PROFILE_ADD}
        component={PatientAddProfileScreen}
        options={{
          headerShown: false,
          title: NavigationTitle.PATIENT_PROFILE_ADD,
        }}
      />
      <Screen
        name={NavigationKey.PATIENT_PROFILE_EDIT}
        component={PatientEditProfileScreen}
        options={{
          headerShown: false,
          title: NavigationTitle.PATIENT_PROFILE_EDIT,
        }}
      />
    </Navigator>
  );
};

const PatientProfileStackMobileNavigator = (
  props: DrawerContentComponentProps,
) => {
  return (
    <Navigator>
      <Screen
        name={NavigationKey.PATIENT_PROFILES}
        component={PatientProfilesScreen}
        options={{
          ...patientMobileNavigatorOptions,
          headerTitleStyle: {
            paddingLeft: 40,
          },
          headerLeft: () => <HeaderProfileLeft inProfileScreen={true} />,
          headerRight: () => (
            <HeaderProfileRight
              showMessageIcon={false}
              onClick={() =>
                props.navigation.dispatch(DrawerActions.toggleDrawer())
              }
            />
          ),
          ...mobileGeneralOptions,
        }}
      />
      <Screen
        name={NavigationKey.PATIENT_PROFILE_ADD}
        component={PatientAddProfileScreen}
        options={{
          title: 'Add Patient Details',
          ...mobileBackHeaderWithTitleOptions,
        }}
      />
      <Screen
        name={NavigationKey.PATIENT_PROFILE_ADD_NEXT}
        component={PatientAddProfileNextScreen}
        options={{
          title: 'Add Patient Details',
          ...mobileBackHeaderWithTitleOptions,
        }}
      />
      <Screen
        name={NavigationKey.PATIENT_PROFILE_EDIT}
        component={PatientEditProfileScreen}
      />
      <Screen
        name={NavigationKey.PATIENT_PROFILE_EDIT_NEXT}
        component={PatientEditProfileNextScreen}
      />
      <Screen
        name={NavigationKey.PATIENT_PROFILE_DELETE}
        component={AccountDeletionConfirmationScreen}
        options={{
          title: '',
          ...mobileBackHeaderWithTitleOptions,
        }}
      />
    </Navigator>
  );
};

export default Platform.OS === 'web'
  ? PatientProfileStackWebNavigator
  : PatientProfileStackMobileNavigator;
