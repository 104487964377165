export const HTMLPrivacyPolicy = `
<h4><strong>Introduction</strong></h4>
<p><span style="font-weight: 400;">We are committed to protecting the privacy of patient information and to handling your personal information in a responsible manner in accordance with the following provisions and regulations of Philippine Law:&nbsp;</span></p>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">DOH-NPC Joint Memorandum Circular No. 2020-0001 entitled &ldquo;(Guidelines on the Use of Telemedicine in COVID-19 Response dated March 28, 2020</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Philippine Medical Act of 1959</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Philippine eHealth Systems and Services Act</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Republic Act No. 10173 or the Data Privacy Act of 2012</span></li>
</ul>
<p><span style="font-weight: 400;">The Privacy Legislation governs the way in which we may collect, use and disclose personal information about any individual (&ldquo;</span><strong>you</strong><span style="font-weight: 400;">&rdquo;, &ldquo;</span><strong>your</strong><span style="font-weight: 400;">&rdquo;, &ldquo;user&rdquo;).</span></p>
<p><span style="font-weight: 400;">This Privacy Policy sets out the manner in which we comply with our obligations under Privacy Legislation, and explains how you may access that information, how you may seek the correction of any information and how you may make a complaint about a breach of Privacy Legislation.</span></p>
<p><span style="font-weight: 400;">This Privacy Policy is current as of 25</span><span style="font-weight: 400;"> June 2021</span><span style="font-weight: 400;">. From time to time we may make changes to our policy, processes and systems in relation to how we handle your personal information. We will update this Privacy Policy to reflect any changes. Those changes will be available on our Site.</span></p>
<p><span style="font-weight: 400;">Please read the following information carefully to understand Housecall&rsquo;s practices regarding your information and how we will use it.</span></p>
<p><span style="font-weight: 400;">By continuing to use our Site or any other services provided by us on the Site, you acknowledge that you have read, understood, accepted, and will comply with this Privacy Policy, and agree to the use of data as set out in this Policy. If you do not agree with any of the information described in this Policy, you should not access the Site.</span></p>
<h4><strong>Collection</strong></h4>
<p><span style="font-weight: 400;">We collect information that is necessary and relevant to provide you with our telehealth service on the Site in relation to medical care and treatment. This information may include your name, address, date of birth, gender, health information, family history, credit card and direct debit details, and contact details. This information may be stored on our computer medical records system and/or in handwritten medical records. Any failure to provide requested information may result in restriction or limitation of use of our Site and services.</span></p>
<p><span style="font-weight: 400;">We do not collect any special categories of personal data about you. This includes details about your race or ethnicity, religious or philosophical beliefs, sexual orientation, political opinions and trade union membership. Nor do we collect any information about criminal convictions and offences.</span></p>
<p><span style="font-weight: 400;">We collect information in various ways, such as from the Site, in person, by phone, or in writing. This information may be collected by our medical and non-medical staff.</span></p>
<p><span style="font-weight: 400;">Wherever practicable we will only collect information directly from you.</span></p>
<p><span style="font-weight: 400;">However, we may also need to collect your information from other sources such as treating specialists, GPs, radiologists, pathologists, hospitals, other health care providers, Housecall record system, your carers and family members.</span></p>
<p><span style="font-weight: 400;">We may be required by law to retain medical records for certain periods of time depending on your age at the time we provide services on the Site.</span></p>
<h4><strong>Use and Disclosure of personal information</strong></h4>
<p><span style="font-weight: 400;">We will treat your personal information as strictly private and confidential. We will only use or disclose it for purposes directly related to your care and treatment, or in ways that you would reasonably expect that we may use it for your ongoing care and treatment. For example, the disclosure of blood test results to your specialist or requests for X-rays.</span></p>
<p><span style="font-weight: 400;">There are circumstances where we may be permitted or required by law to disclose your personal information to third parties. For example, to government regulatory bodies, tribunals, courts of law, hospitals, the electronic transfer of prescriptions service or to the Housecall record system.</span></p>
<p><span style="font-weight: 400;">Where it is necessary to provide your personal information to third parties, we require our third-party providers to comply with all relevant privacy and data protection laws (including, where necessary, obtaining the appropriate express or implied consent from you at the time your personal information is collected) in order for such information to be lawfully used for telehealth services and related purposes.</span></p>
<h4><strong>Collection of other data</strong></h4>
<p><span style="font-weight: 400;">When you visit our Site, we may collect the following information which is sent to us by your computer, mobile device, phone, or any other device:</span></p>
<ul>
<li><span style="font-weight: 400;"> &nbsp; </span> <span style="font-weight: 400;">your IP address and ISP information;</span></li>
<li><span style="font-weight: 400;"> &nbsp; </span> <span style="font-weight: 400;">your mobile network information;</span></li>
<li><span style="font-weight: 400;"> &nbsp; </span> <span style="font-weight: 400;">browser information;</span></li>
<li><span style="font-weight: 400;"> &nbsp; </span> <span style="font-weight: 400;">geographic information;</span></li>
<li><span style="font-weight: 400;"> &nbsp; </span> <span style="font-weight: 400;">standard web information such as referring/exit pages; and</span></li>
<li><span style="font-weight: 400;"> &nbsp; </span> <span style="font-weight: 400;">device information.</span></li>
</ul>
<p><span style="font-weight: 400;">This information is collected to allow us to provide sound administration and identify users located in jurisdictions where our services are restricted or not permitted according to our policies, as well as to monitor usage, data trend analysis, activity, and to personalize our Site accordingly.</span>&nbsp;</p>
<h4><strong>Data Quality and Corrections</strong></h4>
<p><span style="font-weight: 400;">We will take reasonable steps to ensure that your personal information is accurate, complete, up to date and relevant. For this purpose, our staff may, under our discretion or required by law, ask you to confirm that your personal information are correct when you attend a consultation.</span></p>
<p><span style="font-weight: 400;">We request that you let us know if any of the information we hold about you is incorrect or out of date. If you believe that the information we have about you is not accurate, complete or up to date, we ask that you contact us by phone or email (see contact details below).</span></p>
<h4><strong>Security</strong></h4>
<p><span style="font-weight: 400;">We will take reasonable steps (including the employment of appropriate security systems and procedures) to ensure all data held by us is safe and secure. Such security measures include but are not limited to physical protection and electronic encryption to prevent any unauthorised access, authentication cookies, multilayered Site access protocol.</span></p>
<h4><strong>Access</strong></h4>
<p><span style="font-weight: 400;">You are entitled to request access to your medical records. We request that you put your request by phone or email and we will respond to it within a reasonable time.</span></p>
<p><span style="font-weight: 400;">There may be a fee for the administrative costs of retrieving and providing you with copies of your medical records.</span></p>
<p><span style="font-weight: 400;">We may deny access to your medical records in certain circumstances permitted by law, for example, if disclosure may cause a serious threat to your health or safety. We will always tell you why access is denied and the options you have to respond to our decision.</span></p>
<h4><strong>Destruction</strong></h4>
<p><span style="font-weight: 400;">Deactivation of your account does not automatically cause your personal information to be destroyed. Generally, personal information that we collect will be held for a minimum of 30 days to comply with our record keeping requirements.</span></p>
<p><span style="font-weight: 400;">Where we determine, acting reasonably, that personal information held in respect of an individual is no longer needed for our functions or the services we provide, we will permanently destroy or de-identify that information.</span></p>
<p><span style="font-weight: 400;">If you wish for us to destroy any of your personal information collected by us, we will do so only where we determine that it is not necessary for us to hold such information and doing so will not be in breach of our Terms of Use and other legal requirements.</span></p>
<h4><strong>Overseas Transfer of Data</strong></h4>
<p><span style="font-weight: 400;">We will not transfer your personal information to an overseas recipient unless we have your consent or we are required to do so by law.</span></p>
<h4><strong>Questions or Complaints</strong></h4>
<p><span style="font-weight: 400;">If you have any questions or complaints about our Privacy Policy or the way we handle your personal information, you can contact us by phone or email.</span></p>
<p><span style="font-weight: 400;">Upon receipt of a question or complaint we will consider the details and attempt to resolve it in accordance with our questions or complaints handling procedures.</span></p>
<h4><strong>Contact Us</strong></h4>
<p><span style="font-weight: 400;">Please direct any queries, complaints, and requests for access to medical records to:</span></p>
<p><span style="font-weight: 400;">Kim C. Go&nbsp;</span></p>
<p><span style="font-weight: 400;">Contact Number:&nbsp;+63 977 851 6049</span></p>
<p><span style="font-weight: 400;">Email:&nbsp;kchiewgo@gmail.com</span></p>
<p>&nbsp;</p>
`;
