import React, {memo, useEffect, useState} from 'react';
import {View} from 'react-native';

import {makeStyles} from 'rne-netzon';

import {RNEButton, RNEText, RNSearchableDropdown} from '../../components';
import {paymentMethods} from '../../hooks/payment/usePayment';

interface PaymentMethodSelectionModalProps {
  onConfirm: () => void;
  onSelectPaymentMethod: (code: string) => void;
}

function PaymentMethodSelectionModal(props: PaymentMethodSelectionModalProps) {
  const rneStyles = useStyles();

  const [paymentMethod, setPaymentMethod] = useState('1');

  const onDropdownChange = (val: string | number | null | undefined) => {
    if (val && paymentMethod !== val) {
      setPaymentMethod(val.toString());
    }
  };

  useEffect(() => {
    props.onSelectPaymentMethod(paymentMethod);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentMethod]);

  return (
    <View style={rneStyles.root}>
      <View style={rneStyles.titleContainer}>
        <RNEText
          label="Please select a payment method:"
          color="black"
          weight="600"
          variant="title"
          style={labelStyle}
        />
      </View>
      <View style={rneStyles.dropdownContainer}>
        <RNSearchableDropdown
          value={paymentMethod}
          placeholder="Please select a payment method"
          options={paymentMethods}
          onChange={onDropdownChange}
        />
      </View>
      <View style={rneStyles.buttonContainer}>
        <RNEButton title="Next" onPress={props.onConfirm} color="secondary" />
      </View>
    </View>
  );
}

const labelStyle = {
  fontSize: 16,
};

const useStyles = makeStyles(theme => ({
  root: {
    width: 400,
    padding: 32,
    height: 500,
    justifyContent: 'center',
  },
  buttonStyle: {
    width: 120,
  },
  titleContainer: {
    paddingVertical: 40,
  },
  dropdownContainer: {
    paddingTop: 20,
    height: 48,
    flex: 1,
    justifyContent: 'center',
    zIndex: 2,
  },
  errorMsgContainer: {
    paddingTop: 20,
  },
  buttonContainer: {
    paddingTop: 300,
  },
}));

export default memo(PaymentMethodSelectionModal);
