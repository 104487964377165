import React from 'react';
import {createStackNavigator} from '@react-navigation/stack';

import {colorPalette} from '../../../core/config/color.config';

import {NavigationKey} from '../config/navigation.config';

import {
  HeaderBackLeft,
  MedicalConditionView,
  FamilyConditionView,
  MaintenanceView,
  HospitalizationView,
  PersonalAndSocialView,
  AllergiesView,
} from '../../views';

const {Navigator, Screen} = createStackNavigator();

const mobileBackHeaderNoTitleOptions = {
  title: '',
  headerStyle: {
    elevation: 0,
    shadowOpacity: 0,
  },
  headerTintColor: colorPalette.blackLight,
  headerTitleStyle: {
    fontFamily: 'Quicksand-Regular',
    fontSize: 14,
    paddingLeft: 28,
  },
  headerLeft: () => <HeaderBackLeft />,
};

// const PatientMedicalHistoryStackWebNavigator = () => {
//   return <Navigator />;
// };

const PatientMedicalHistoryStackMobileNavigator = () => {
  return (
    <Navigator>
      <Screen
        name={NavigationKey.CONDITION}
        component={MedicalConditionView}
        options={{...mobileBackHeaderNoTitleOptions}}
      />
      <Screen
        name={NavigationKey.FAMILY_MEMBER_HISTORY}
        component={FamilyConditionView}
        options={{...mobileBackHeaderNoTitleOptions}}
      />
      <Screen
        name={NavigationKey.MEDICATION_STATEMENT}
        component={MaintenanceView}
        options={{...mobileBackHeaderNoTitleOptions}}
      />
      <Screen
        name={NavigationKey.PROCEDURE}
        component={HospitalizationView}
        options={{...mobileBackHeaderNoTitleOptions}}
      />
      <Screen
        name={NavigationKey.PERSONAL_AND_SOCIAL}
        component={PersonalAndSocialView}
        options={{...mobileBackHeaderNoTitleOptions}}
      />
      <Screen
        name={NavigationKey.ALLERGY_INTOLERANCE}
        component={AllergiesView}
        options={{...mobileBackHeaderNoTitleOptions}}
      />
    </Navigator>
  );
};

// export default Platform.OS === 'web'
//   ? PatientMedicalHistoryStackWebNavigator
//   : PatientMedicalHistoryStackMobileNavigator;

export default PatientMedicalHistoryStackMobileNavigator;
