import {useMutation, useQueryClient} from 'react-query';
import {API, ApiEndpointsEnum} from '../api';

import {SignupUserMock} from '../mock/registration.mock';

import {
  SignupReqBody,
  SignupReqResponse,
  SendVerificationReqBody,
  SendVerificationReqResponse,
  ConfirmVerificationReqBody,
  ConfirmVerificationReqResponse,
  AcceptTermsReqBody,
  AcceptTermsReqResponse,
} from '../types/registration.types';

// --- SIGNUP ---
const signupMock = async (reqBody: SignupReqBody) => {
  const data = await new Promise<SignupReqResponse>(resolve =>
    setTimeout(() => resolve(SignupUserMock), 1000),
  );

  return data;
};

const signup = (reqBody: SignupReqBody) => {
  return API.post<SignupReqResponse>(`${ApiEndpointsEnum.SIGNUP}`, reqBody);
};

const useSignup = (reqBody: SignupReqBody) => {
  const queryClient = useQueryClient();

  return useMutation(['signup'], () => signup(reqBody), {
    onSuccess: () => {
      queryClient.invalidateQueries(['signup']);
    },
  });
};

// --- SEND VERFICATION ---
const sendVerificationMock = async () => {
  const data = await new Promise<SendVerificationReqResponse>(resolve =>
    setTimeout(() => resolve({}), 1000),
  );

  return data;
};

const sendVerification = () => {
  return API.post<SignupReqResponse>(
    `${ApiEndpointsEnum.REQUEST_VERIFICATION}`,
  );
};

const useSendVerification = () => {
  const queryClient = useQueryClient();

  return useMutation(['send-verification'], () => sendVerification(), {
    onSuccess: () => {
      queryClient.invalidateQueries(['send-verification']);
    },
  });
};

// --- CONFIRM VERFICATION ---
const confirmVerificationMock = async (reqBody: ConfirmVerificationReqBody) => {
  const data = await new Promise<ConfirmVerificationReqResponse>(resolve =>
    setTimeout(() => resolve({}), 1000),
  );

  return data;
};

const confirmVerification = (reqBody: ConfirmVerificationReqBody) => {
  return API.put<ConfirmVerificationReqResponse>(
    `${ApiEndpointsEnum.CONFIRM_VERIFICATION}`,
    reqBody,
  );
};

const useConfirmVerification = (reqBody: ConfirmVerificationReqBody) => {
  const queryClient = useQueryClient();

  return useMutation(
    ['confirm-verification'],
    () => confirmVerification(reqBody),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['confirm-verification']);
      },
    },
  );
};

// --- TERMS AND CONDITIONS ACCEPTANCE ---
const acceptTermsMock = async (reqBody: AcceptTermsReqBody) => {
  const data = await new Promise<AcceptTermsReqResponse>(resolve =>
    setTimeout(() => resolve({}), 1000),
  );

  return data;
};

const acceptTerms = (reqBody: AcceptTermsReqBody) => {
  return API.put<AcceptTermsReqResponse>(
    `${ApiEndpointsEnum.ACCEPT_TERMS}`,
    reqBody,
  );
};

const useAcceptTerms = (reqBody: AcceptTermsReqBody) => {
  const queryClient = useQueryClient();

  return useMutation(['accept-terms'], () => acceptTerms(reqBody), {
    onSuccess: () => {
      queryClient.invalidateQueries(['accept-terms']);
    },
  });
};

export {useSignup, useSendVerification, useConfirmVerification, useAcceptTerms};
