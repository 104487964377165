import React from 'react';
import {Platform, TextStyle} from 'react-native';

import {createStackNavigator} from '@react-navigation/stack';
import {colorPalette} from '../../../core/config/color.config';

import {NavigationKey} from '../config/navigation.config';
import {NavigationTitle} from '../config/navigationTitle';

import {
  DoctorViewAccountScreen,
  DoctorEditAccountScreen,
  AccountDeletionConfirmationScreen,
} from '../../screens';
import {HeaderBackLeft} from '../../views';

const {Navigator, Screen} = createStackNavigator();

const mobileGeneralOptions = {
  title: '',
  headerTintColor: colorPalette.primary,
};

const DoctorAccountStackWebNavigator = () => {
  return (
    <Navigator>
      <Screen
        name={NavigationKey.DOCTOR_ACCOUNT}
        component={DoctorViewAccountScreen}
        options={{headerShown: false, title: NavigationTitle.DOCTOR_ACCOUNT}}
      />
      <Screen
        name={NavigationKey.DOCTOR_ACCOUNT_EDIT}
        component={DoctorEditAccountScreen}
        options={{
          headerShown: false,
          title: NavigationTitle.DOCTOR_ACCOUNT_EDIT,
        }}
      />
    </Navigator>
  );
};

const doctorMobileNavigatorOptions = {
  headerStyle: {
    elevation: 0,
    shadowOpacity: 0,
  },
  headerTitleStyle: {
    fontWeight: 'bold',
    fontFamily: 'Quicksand-Bold',
    fontSize: 16,
  } as TextStyle,
};

const DoctorAccountStackMobileNavigator = () => {
  return (
    <Navigator>
      <Screen
        name={NavigationKey.DOCTOR_ACCOUNT}
        component={DoctorViewAccountScreen}
        options={{
          ...doctorMobileNavigatorOptions,
          headerStyle: {
            backgroundColor: colorPalette.purple,
            borderBottomColor: 'transparent',
            elevation: 0,
            shadowOpacity: 0,
          },
          headerLeft: () => <HeaderBackLeft label=" " iconName="left-white" />,
          ...mobileGeneralOptions,
        }}
      />
      <Screen
        name={NavigationKey.DOCTOR_ACCOUNT_EDIT}
        component={DoctorEditAccountScreen}
        options={{
          ...doctorMobileNavigatorOptions,
          headerStyle: {
            backgroundColor: colorPalette.purple,
            borderBottomColor: 'transparent',
            elevation: 0,
            shadowOpacity: 0,
          },
          headerLeft: () => <HeaderBackLeft label=" " iconName="left-white" />,
          ...mobileGeneralOptions,
        }}
      />
      <Screen
        name={NavigationKey.PATIENT_PROFILE_DELETE}
        component={AccountDeletionConfirmationScreen}
        options={{
          ...mobileGeneralOptions,
        }}
      />
    </Navigator>
  );
};

export default Platform.OS === 'web'
  ? DoctorAccountStackWebNavigator
  : DoctorAccountStackMobileNavigator;
