import {useNavigation} from '@react-navigation/native';
import React, {memo, useState} from 'react';
import {View} from 'react-native';
import {useToast} from 'react-native-fast-toast';

import {makeStyles} from 'rne-netzon';
import {
  SVGIcon,
  RNEButton,
  RNEText,
  RNEIconButton,
  RNEInput,
} from '../../components';
import {NavigationKey} from '../../navigations/config';
import {useDeleteAccount} from '../../server/react-query/useAccount';
import {useDeletePatientProfile} from '../../server/react-query/usePatientProfile';
import useStores from '../../stores/useStores';

interface AccountDeletionConfirmationProps {
  onCancel: () => void;
  patientId?: string;
  accountOwner?: boolean;
  forDoctor?: boolean;
}

function AccountDeletionConfirmation(props: AccountDeletionConfirmationProps) {
  const rneStyles = useStyles();
  const navigation = useNavigation();
  const toast = useToast();
  const {authStore, patientProfileStore} = useStores();

  const [hasBeenDeleted, setHasBeenDeleted] = useState(false);
  const [password, setPassword] = useState('');

  const deleteAccountMutation = useDeleteAccount();
  const deletePatientProfileMutation = useDeletePatientProfile();

  const deleteAccount = async () => {
    try {
      await deleteAccountMutation.mutateAsync(password);
      setHasBeenDeleted(true);
    } catch (error) {
      console.log('error', error);
      if (error?.data?.error) {
        toast?.show(error?.data?.error, {type: 'danger'});
      }
    }
  };

  const deletePatientProfile = async () => {
    try {
      await deletePatientProfileMutation.mutateAsync(props.patientId || '');
      setHasBeenDeleted(true);

      if (
        patientProfileStore.activeProfileData?.patientId === props.patientId
      ) {
        const accountOwner = authStore.authData?.user;
        if (accountOwner) {
          patientProfileStore.setActivePatient({
            patientId: accountOwner.patientId,
            firstName: accountOwner.firstName,
            lastName: accountOwner.lastName,
            imageUrl: accountOwner.photo?.url,
          });
        }
      }
    } catch (error) {
      console.log('error', error);
      if (error?.data?.error) {
        toast?.show(error?.data?.error, {type: 'danger'});
      }
    }
  };

  const deletionComplete = () => {
    props?.onCancel();
    if (!props.accountOwner) {
      navigation.navigate(NavigationKey.PATIENT_PROFILES);
    }
  };

  return (
    <View style={rneStyles.root}>
      {!hasBeenDeleted && (
        <>
          <RNEText
            label={
              props.accountOwner
                ? 'You are about to permanently delete your account.'
                : 'You are about to permanently delete a patient profile.'
            }
            variant="header"
            align="center"
            weight="600"
            color="accent"
          />

          <View style={rneStyles.subtitleContainer}>
            <RNEText
              label={
                props.accountOwner
                  ? `${
                      !props.forDoctor
                        ? 'You will lose all your profile information, medical history, along with the sub profiles under your account.\n'
                        : ''
                    } 
                  Once you submit your account for deletion, you have 30 days to reactivate your account. After 30 days, you won't be
                  able to retrieve any of the content or information you have added.\n\nTo confirm account deletion, please enter your password.`
                  : `All medical information (patient details, allergies, family conditions, hospitalizations, maintenance medicines,
                      and personal and social information) of the patient profile will be permanently deleted.`
              }
              variant="body"
              align="center"
            />
          </View>

          {props.accountOwner && (
            <View style={rneStyles.inputContainer}>
              <RNEInput
                placeholder="Password"
                secureTextEntry
                value={password}
                onChangeText={text => setPassword(text)}
                onSubmitEditing={
                  props.accountOwner ? deleteAccount : deletePatientProfile
                }
              />
            </View>
          )}

          <View style={rneStyles.actionContainer}>
            <View style={rneStyles.buttonContainer}>
              <RNEButton
                title="Cancel"
                color="secondary"
                onPress={props.onCancel}
                style={rneStyles.buttonStyle}
                variant="outlined"
              />
            </View>
            <RNEButton
              title="Delete"
              color="secondary"
              onPress={
                props.accountOwner ? deleteAccount : deletePatientProfile
              }
              style={rneStyles.buttonStyle}
              disabled={props.accountOwner && !password}
              loading={
                deleteAccountMutation.isLoading ||
                deletePatientProfileMutation.isLoading
              }
            />
          </View>
        </>
      )}

      {hasBeenDeleted && (
        <View style={rneStyles.messageContainer}>
          <RNEText
            label={
              props.accountOwner
                ? 'Account Deletion Request Sent'
                : 'Patient Profile Deleted'
            }
            variant="header"
            align="center"
            weight="600"
            color="accent"
          />

          <View style={rneStyles.iconContainer}>
            <RNEIconButton
              icon={<SVGIcon name="check-white" size={48} />}
              color="secondary"
              size={64}
              onPress={deletionComplete}
            />
          </View>
        </View>
      )}
    </View>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    justifyContent: 'center',
  },
  subtitleContainer: {
    marginTop: 40,
  },
  actionContainer: {
    marginTop: 80,
    justifyContent: 'center',
    flexDirection: 'row',
  },
  buttonContainer: {
    marginRight: 16,
  },
  buttonStyle: {
    width: 160,
  },
  messageContainer: {
    marginVertical: 20,
    alignItems: 'center',
  },
  iconContainer: {
    alignSelf: 'center',
    marginTop: 40,
  },
  inputContainer: {
    marginTop: 32,
  },
}));

export default memo(AccountDeletionConfirmation);
