import React, {useEffect} from 'react';
import {Platform, StyleSheet, View, Image} from 'react-native';
import {SafeAreaView} from 'react-native-safe-area-context';
import {useToast} from 'react-native-fast-toast';

import {useQueryClient} from 'react-query';

import {useForm, Controller} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';

import {useNavigation} from '@react-navigation/native';
import {NavigationKey} from '../../navigations/config';

import {LogoLight} from './../../images/Image';

import useStores from '../../stores/useStores';

import {useLogin} from '../../server/react-query/useAuthentication';
import {LoginReqBody} from '../../server/types/authentication.types';

import OnboardingSliderScreen from '../onboarding/OnboardingSliderScreen';
import {SVGIcon, RNEButton, RNEText, RNEInput} from '../../components';
import {RegistrationStatus} from '../../server/types/registration.types';

const schema: yup.SchemaOf<Partial<LoginReqBody>> = yup.object({
  email: yup
    .string()
    .required('This field is required.')
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      'Enter a valid email address.',
    ),
  password: yup
    .string()
    .trim()
    .required('This field is required.')
    .min(6, 'Min of 6 characters.'),
});

const LoginScreen = () => {
  const navigation = useNavigation();
  const {authStore, patientProfileStore, doctorProfileStore} = useStores();
  const {
    control,
    handleSubmit,
    getValues,
    reset,
    formState: {errors, isValid},
  } = useForm<LoginReqBody>({
    mode: 'all',
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: yupResolver(schema),
  });

  const queryClient = useQueryClient();

  const toast = useToast();

  const mutation = useLogin(getValues());

  const goToSignup = () => {
    reset();
    navigation.navigate(NavigationKey.SIGNUP_SCREEN);
  };

  const submitForm = async () => {
    handleSubmit(login)();
  };

  const requestPermission = () => {
    console.log('Requesting permission...');
    Notification.requestPermission(result => {
      if (result === 'granted') {
        console.log('Notification permission granted.');
      }
    });
  };

  const login = async () => {
    if (isValid) {
      try {
        const data = await mutation.mutateAsync();
        authStore.setAuth(data);

        if (data.user.userRole === 'patient') {
          patientProfileStore.setActivePatient({
            patientId: data.user.patientId,
            firstName: data.user.firstName,
            lastName: data.user.lastName,
            imageUrl: data.user.photo?.url,
          });
        }

        if (data.user.userRole === 'practitioner') {
          doctorProfileStore.setPartialData({
            firstName: data.user.firstName,
            lastName: data.user.lastName,
            status: data.user.practitionerStatus,
          });
        }

        if (data) {
          reset();
          mutation.reset();

          setTimeout(() => {
            validateRegistrationStatus(data.user.registrationStatus);
          }, 250);
        }
      } catch (error: any) {
        console.log('error', error);
        if (error?.data?.error) {
          toast?.show(error?.data?.error, {type: 'danger'});
        }
      }
    }
  };

  const validateRegistrationStatus = (
    registrationStatus: RegistrationStatus,
  ) => {
    switch (registrationStatus) {
      case 'fullyVerified':
        authStore.login();
        break;

      case 'partiallyVerified':
        navigation.navigate(NavigationKey.TANDC_SCREEN);
        break;

      case 'unverified':
        navigation.navigate(NavigationKey.SIGNUP_VERIFY_SCREEN);
        break;

      default:
        break;
    }
  };

  const goToForgotPassword = () => {
    reset();
    navigation.navigate(NavigationKey.FORGOT_PASSWORD_SCREEN);
  };

  const deleteAllCookies = () => {
    var cookies = document.cookie.split(';');

    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i];
      var eqPos = cookie.indexOf('=');
      var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
    }
  };

  useEffect(() => {
    queryClient.clear();
    if (Platform.OS === 'web') {
      deleteAllCookies();
    }
    requestPermission();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SafeAreaView style={sharedStyles.root}>
      <View style={webStyles.mainContainer}>
        <View style={webStyles.loginContainer}>
          <View style={webStyles.loginWrapper}>
            <Image style={webStyles.logo} source={LogoLight} />

            <View style={webStyles.contentWrapper}>
              <RNEText
                label="Welcome back!"
                variant="header"
                weight="bold"
                color="accent"
              />
              <RNEText label="How can we help you today?" variant="subheader" />

              <View style={webStyles.inputGroupContainer}>
                <View>
                  <Controller
                    control={control}
                    render={({field: {onChange, onBlur, value}}) => (
                      <RNEInput
                        placeholder="Email address"
                        leftIcon={<SVGIcon name="email" />}
                        value={value}
                        onChangeText={text => {
                          onChange(text);
                        }}
                        errorMessage={errors.email?.message}
                      />
                    )}
                    name="email"
                  />
                </View>

                <View style={webStyles.inputContainer}>
                  <Controller
                    control={control}
                    render={({field: {onChange, onBlur, value}}) => (
                      <RNEInput
                        placeholder="Password"
                        secureTextEntry
                        leftIcon={<SVGIcon name="lock" />}
                        value={value}
                        onChangeText={text => {
                          onChange(text);
                        }}
                        onSubmitEditing={submitForm}
                        errorMessage={errors.password?.message}
                      />
                    )}
                    name="password"
                  />
                </View>
              </View>

              <View style={webStyles.loginActionContainer}>
                <RNEButton
                  title="Log In"
                  onPress={submitForm}
                  color="secondary"
                  disabled={!isValid}
                  loading={mutation.isLoading}
                />
              </View>

              <View style={webStyles.footerActions}>
                <View style={webStyles.signupContainer}>
                  <View>
                    <RNEText label="Don't have an account?" variant="button" />
                  </View>

                  <View style={webStyles.signupTextContainer}>
                    <RNEText
                      label="Sign up here."
                      color="primary"
                      variant="button"
                      weight="bold"
                      onPress={goToSignup}
                    />
                  </View>
                </View>

                <View>
                  <RNEText
                    label="Forgot Password?"
                    variant="button"
                    weight="bold"
                    onPress={goToForgotPassword}
                  />
                </View>
              </View>
            </View>
          </View>
        </View>
        <View style={webStyles.onboardingContainer}>
          <OnboardingSliderScreen hideActions={true} />
        </View>
      </View>
    </SafeAreaView>
  );
};

export default LoginScreen;

const webStyles = StyleSheet.create({
  mainContainer: {
    flexDirection: 'row',
    width: '100%',
  },
  loginContainer: {
    flex: 2,
    height: '100%',
    paddingVertical: 32,
    paddingHorizontal: 16,
    alignItems: 'center',
  },
  loginWrapper: {
    width: '100%',
    maxWidth: '960px',
  },
  onboardingContainer: {
    flex: 1,
    width: '100%',
    maxWidth: 600,
    minWidth: 350,
    overflow: 'hidden',
  },
  logo: {
    width: 200,
    height: 44,
  },
  inputGroupContainer: {
    width: '100%',
    marginTop: 56,
  },
  inputContainer: {
    marginTop: 24,
  },
  loginActionContainer: {
    marginTop: 32,
    width: '100%',
  },
  contentWrapper: {
    maxWidth: 450,
    alignSelf: 'center',
    width: '100%',
    marginTop: 96,
  },
  signupContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  signupTextContainer: {
    marginLeft: 4,
  },
  footerActions: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 56,
  },
});

const sharedStyles = StyleSheet.create({
  root: {
    height: '100%',
    flexDirection: 'row',
    width: '100%',
  },
});
