import React, {useEffect} from 'react';
import {useIsFocused} from '@react-navigation/native';
import {View, Platform, ScrollView} from 'react-native';

import {makeStyles} from 'rne-netzon';
import {colorPalette} from '../../../core/config/color.config';

import {RNEButton, RNEText} from '../../components';
import {useGetNotifications} from '../../server/react-query/useNotification';
import useStores from '../../stores/useStores';
import {NotificationCard} from '../../views';

const NotificationsScreen = () => {
  const webStyles = useWebStyles();
  const mobileStyles = useMobileStyles();
  const sharedStyles = useSharedStyles();
  const isFocused = useIsFocused();
  const {authStore, patientProfileStore} = useStores();

  const {data: notifications, isLoading, refetch} = useGetNotifications({
    pageNumber: 1,
    pageSize: 1000,
    patientId:
      authStore.userRole === 'patient'
        ? patientProfileStore.activeProfile?.patientId
        : '',
  });

  useEffect(() => {
    if (isFocused && Platform.OS !== 'web') {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFocused]);

  return (
    <>
      {Platform.OS === 'web' ? (
        <View style={webStyles.mainContainer}>
          <View>
            <RNEText
              label="Notifications"
              variant="header"
              weight="bold"
              color="accent"
            />
          </View>
          <ScrollView>
            {isLoading ? (
              <RNEButton
                height={60}
                loadingProps={loadingPropsStyle}
                loading={true}
              />
            ) : (
              <View style={webStyles.cardsContainer}>
                {notifications && notifications.totalCount > 0 ? (
                  notifications.data.map((notification, i) => (
                    <NotificationCard notification={notification} key={i} />
                  ))
                ) : (
                  <View style={sharedStyles.emptyNotifications}>
                    <RNEText
                      label="No notifications at the moment"
                      variant="body"
                      align="center"
                      color="black"
                    />
                  </View>
                )}
              </View>
            )}
          </ScrollView>
        </View>
      ) : (
        <ScrollView>
          <View style={mobileStyles.mainContainer}>
            <View>
              <RNEText
                label="Notifications"
                variant="header"
                weight="bold"
                color="accent"
              />
            </View>
            <View style={mobileStyles.cardsContainer}>
              {notifications && notifications.totalCount > 0 ? (
                notifications.data.map((notification, i) => (
                  <NotificationCard notification={notification} key={i} />
                ))
              ) : (
                <View style={sharedStyles.emptyNotifications}>
                  <RNEText
                    label="No notifications at the moment"
                    variant="body"
                    align="center"
                    color="black"
                  />
                </View>
              )}
            </View>
          </View>
        </ScrollView>
      )}
    </>
  );
};

export default NotificationsScreen;

const loadingPropsStyle = {
  color: colorPalette.primary,
};

const useWebStyles = makeStyles({
  mainContainer: {
    paddingVertical: 140,
    paddingHorizontal: 80,
    width: '100%',
    flex: 1,
  },
  cardsContainer: {
    paddingRight: 100,
    marginLeft: -20,
  },
});

const useMobileStyles = makeStyles({
  mainContainer: {
    padding: 50,
    width: '100%',
  },
  cardsContainer: {
    marginLeft: -32,
  },
});

const useSharedStyles = makeStyles({
  emptyNotifications: {
    marginTop: 20,
  },
});
