export const HTMLTermsAndConditions = `
<p><strong>Housecall Terms and Conditions</strong></p>
<p><span style="font-weight: 400;">Housecall cares for you, prioritizes your safety, and respects your privacy. These terms &amp; conditions are here to protect you and be ensured that we provide the best service that we can for your health.&nbsp;</span></p>
<p><span style="font-weight: 400;">By accepting to avail any of the Telemedicine Services (&ldquo;Services&rdquo;) of Housecall, you agree to abide by these Terms and Conditions, and it is termed as your consent to proceed with telemedicine consultation with the Registered Medical Practitioner under the prevailing law. Further, you expressly agree that you will not use Housecall&rsquo;s services if you do not understand or accept these Terms and Conditions.</span></p>
<p><span style="font-weight: 400;">By downloading or accessing the Website to use the Services, you irrevocably accept all the terms and conditions stipulated in this Agreement and agree to fully abide by them.</span></p>
<p>This Agreement supersedes all previous oral and written terms and conditions (if any) communicated to you. By availing any Service, you signify your agreement and acceptance to this Agreement.</p>
<p><span style="font-weight: 400;">Housecall (referred to as &ldquo;we&rdquo;, &ldquo;us&rdquo;) are the authors and publishers of the website www.Housecall.com.ph (http://www.Housecall.com.ph) and its sub domains, if any, (collectively referred to as &ldquo;Websites&rdquo;) on the world wide web and&nbsp; Mobile applications (referred to as &ldquo;App&rdquo;). All such Apps, together with Websites visited, accessed or used by users are referred to as &ldquo;Services&rdquo;. Housecall provides the Services, either on its own or in partnership with its agents, affiliates, associates, representatives or other third parties (together referred to as &ldquo;Partners&rdquo;).</span></p>

<ol style="list-style-type: upper-roman;">
<li><strong>Introduction and Purpose:</strong>&nbsp;
<ol style="list-style-type: lower-alpha;">
<li style="padding-left: 4px;"><span style="font-weight: 400;">Telemedicine is the use of telephone, cellphone, computer or electronic gadget that will enable you as a patient to communicate with your doctor/s for the purpose of diagnosis, treatment, management, education and follow-up care when a face-to-face consultation is not possible. Telemedicine consultations may involve live two-way chat, audio and video, patient pictures, medical images, patient&rsquo;s medical records and other things that may be pertinent to the consultation.</span></li>
<li style="padding-left: 4px;"><span style="font-weight: 400;">Electronic systems will utilize network and software security protocols to protect patient identity, privacy and confidentiality and to safeguard data and prevent corruption of data against intentional or unintentional corruption.</span></li>
<li style="padding-left: 4px;"><span style="font-weight: 400;">By participating in this teleconsultation, you acknowledge that a physician-patient relationship is formed at your request.<br /><br /></span></li>
</ol>
</li>

<li><strong>Benefits:</strong><strong>&nbsp;</strong>
<ol style="list-style-type: lower-alpha;">
<li style="font-weight: 400;"><span style="font-weight: 400;">Through the use of telemedicine, you will obtain a medical evaluation and impression of your condition. You may receive guidance on monitoring your condition and the next steps to do should your condition change, specific prescription on what to take, instructions on what laboratory and imaging tests to do.<br /><br /></span></li>
</ol>
</li>

<li><strong>Technology Use:</strong><span style="font-weight: 400;"><br /></span>
<ol style="list-style-type: lower-alpha;">
<li style="padding-left: 4px;"><span style="font-weight: 400;">Housecall shall collect your identifiable health information including physical, physiological and mental health condition, and medical records and history as part of Telemedicine Services. The use of your sensitive personal information which includes your health information will be governed by Housecall&rsquo;s Privacy Policy. When you accept terms and conditions at the time of registering or otherwise provide your personal information, you specifically consent to Housecall&rsquo;s Privacy Policy.</span></li>
<li style="padding-left: 4px;"><span style="font-weight: 400;">You agree Housecall can record, save and store all tele-consultations/ instant messaging chat consultations / text messages / audios / videos / transcripts / prescriptions / email and hard copy correspondences with you for record purposes and produce these as evidence if required before or directed by a court of law;</span></li>
<li style="padding-left: 4px;"><span style="font-weight: 400;">Your Physician shall take appropriate decisions based on the information you provide before or at the time of availing the Telemedicine Services and you hereby accept and agree that Registered Medical Practitioner may choose not to proceed with Telemedicine Service at any point in time and suggest you consult in-person. Similarly, you may choose not to proceed with Telemedicine Service at any time after initiating the consultation with the concerned Registered Medical Practitioner;</span></li>
<li style="padding-left: 4px;"><span style="font-weight: 400;">Your Physician shall use his/ her best judgment to prescribe medicines. As such, your physician/s is/are fully accountable to their diagnoses and prescriptions. You agree and acknowledge that the Prescription will be sent to you through an electronic platform and Housecall shall not be held liable for any issues arising out of such prescription;</span></li>
<li style="padding-left: 4px;"><span style="font-weight: 400;">Your Physician at his/ her professional judgment may ask you for certain diagnostic tests. For such diagnostic services you are free to do so at any legitimate laboratory or diagnostic center that your physician may recommend. Housecall is merely a channel for information transmission, and is not liable for the information on diagnostic test results.</span></li>
<li style="padding-left: 4px;"><span style="font-weight: 400;">Physicians in Housecall are independent entities/ professionals in private practice and are not agents of Housecall and/or its parents, subsidiaries or affiliates. Housecall does not promote or suggest any particular physician. Further, Housecall does not make any representations or warranties with respect to physicians or the quality of the healthcare services they may provide;</span></li>
<li style="padding-left: 4px;"><span style="font-weight: 400;">You hereby accept and acknowledge that the Telemedicine service is between the physician and you, and that Housecall has no role to play in it apart from merely being a platform for information transmission between you and your physician. In an event of any wrongdoing, fraud, mishap, negligence, and malpractice done by the physician, then Housecall will not be held liable for any loss or damage if any.<br /><br /></span></li>
</ol>
</li>

<li><strong> Potential Risks:<br /></strong>
<ol style="list-style-type: lower-alpha;">
<li style="padding-left: 4px;"><span style="font-weight: 400;">You shall hereby accept and acknowledge that your interactions with your physician/s during Telemedicine through an Audio, Video and Text medium has limitations in terms of effective transmission of the contents, which will have a direct impact on the treatment to be prescribed by the Registered Medical Practitioner. The underlying limitations contemplated herein above are as follows;</span>
<ol style="list-style-type: decimal;">
<li style="padding-left: 8px;"><span style="font-weight: 400;">In some instances the information transmitted may be of insufficient quality to allow for appropriate medical or health care decision making by the health care practitioner (i.e., poor resolution images, etc.);</span></li>
<li style="padding-left: 8px;"><span style="font-weight: 400;">Delays in response, evaluation or treatment may occur due to failure of the user&rsquo;s own electronic equipment and internet connection;</span></li>
<li style="padding-left: 8px;"><span style="font-weight: 400;">In some instances a lack of access to all of your medical records may result in adverse drug reactions or allergic reactions or other judgment errors;</span></li>
<li style="padding-left: 8px;"><span style="font-weight: 400;">Telemedicine service is purely dependent on availability of internet and network connectivity, hence, delays in responses, data and media transfer may be affected, depending on each user&rsquo;s own internet and network connectivity.<br /><br /></span><span style="font-weight: 400;">Therefore you cannot hold your doctor liable for any data that may be lost, corrupted, destroyed or intercepted or the illegal use of your data arising from a breach in security.</span><br /><br /></li>
</ol>
</li>
<li style="padding-left: 4px;"><span style="font-weight: 400;">Before using Telemedicine, you agree that you are aware and understand that;</span>
<ol style="list-style-type: decimal;">
<li style="padding-left: 8px;"><span style="font-weight: 400;">Telemedicine is not intended to replace in-person consultation with Registered Medical Practitioner.&nbsp;</span></li>
<li style="padding-left: 8px;"><span style="font-weight: 400;">Medical services rendered by Registered Medical Practitioner under Telemedicine Services are solely subject to professional judgment.</span></li>
<li style="padding-left: 8px;"><span style="font-weight: 400;">Use of Housecall&rsquo;s virtual health service is not for emergencies. You will not use Housecall&rsquo;s Telemedicine for medical emergencies except when no other alternative is available. Housecall hereby clarifies that its Telemedicine is not intended to support medical emergency cases.<br /><br /></span></li>
</ol>
</li>
<li style="padding-left: 4px;"><span style="font-weight: 400;">Any advice provided by any Registered Medical Practitioner shall depend solely on the completeness and accuracy of the information provided by you. Housecall shall not be responsible for any damage, whether physical, emotional, psychological or financial, caused to you due to any improper/unintended use of the Services, any coupon issued to you or any of the benefits available there under, which includes but is not limited to the misinterpretation of the advice given by any Registered Medical Practitioner or virtual health service to you;<br /><br /></span></li>
<li style="padding-left: 4px;"><span style="font-weight: 400;">You hereby indemnify, defend, and hold Housecall, Housecall&rsquo;s distributors, agents, service providers, partners, representatives and other Members, and each of the foregoing entities&rsquo; respective officers, directors, owners, employees, agents, representatives, harmless from and against any and all losses, damages, liabilities and costs arising from (i) your use of the Services (ii) your breach of provisions of this Agreement and (iii) any negligent or intentional wrongdoing or omission on your part;<br /><br /></span></li>
<li style="padding-left: 4px;"><span style="font-weight: 400;">Housecall reserves the right to terminate the Telemedicine services at any time without prior notice;<br /><br /></span></li>
<li style="padding-left: 4px;"><span style="font-weight: 400;">In case legal action becomes necessary between the two parties, Housecall will not be liable for all expenses and charges incurred by each party&rsquo;s judicial actions, including attorney&rsquo;s fees.&nbsp;<br /><br /></span></li>
</ol>
</li>

<li><strong> Data Integrity, Privacy and Confidentiality:</strong>&nbsp;
<ol style="list-style-type: lower-alpha;">
<li style="padding-left: 4px;"><span style="font-weight: 400;">You agree to share your personal data to Housecall in order to facilitate your consultation requests and for billing purposes. You agree not to record in video or audio format nor divulge the details of your consultation in compliance with the Data Privacy Act of 2012.</span></li>
<li style="padding-left: 4px;"><span style="font-weight: 400;">By using any of the Services of Housecall, you hereby unconditionally give your consent, subject to the provisions of applicable law, for Housecall or its representatives to contact you by telephone, SMS or any other mode in relation to the Services as well as for promotion of its services and products. It is your responsibility to ensure that the contact details provided by you is correct;</span></li>
<li style="padding-left: 4px;"><span style="font-weight: 400;">As the user of Housecall, you are responsible for maintaining the confidentiality of your account access information and password. You shall be responsible for all usage of your account and password, whether or not authorized by you. You shall immediately notify Housecall of any actual or suspected unauthorized use of your account or password. Although Housecall will not be liable for your losses caused by any unauthorized use of your account, you may be liable for the losses of Housecall or others due to such unauthorized use.</span>&nbsp;</li>
<li style="padding-left: 4px;"><span style="font-weight: 400;">As the user of Housecall, if you provide any information that is untrue, inaccurate, not current or incomplete (or becomes untrue, inaccurate, not current or incomplete), or Housecall has reasonable grounds to suspect that such information is untrue, inaccurate, not current or incomplete, Housecall has the right to discontinue the Services to you at its sole discretion.</span><br /><br /></li>
</ol>
</li>

<li><strong> In case of an urgent concern:<br /></strong>
<ol style="list-style-type: lower-alpha;">
<li style="padding-left: 4px;"><span style="font-weight: 400;">It is your doctor&rsquo;s responsibility to refer you to the nearest hospital in case he deems your concern to be urgent and would warrant immediate action and management by a health facility. My doctor&rsquo;s responsibility ends with the conclusion of the telemedicine consultation.<br /><br /></span></li>
</ol>
</li>

<li><strong>By signing up to Housecall, you hereby declare that<br /></strong>
<ol style="list-style-type: lower-alpha;">
<li style="padding-left: 4px;"><span style="font-weight: 400;">you have read this form and that you fully understand what is stated here.</span></li>
<li style="padding-left: 4px;"><span style="font-weight: 400;">you were given the opportunity to ask questions and your questions were answered.</span></li>
<li style="padding-left: 4px;"><span style="font-weight: 400;">you have discussed these with your doctor and</span></li>
<li style="padding-left: 4px;"><span style="font-weight: 400;">you fully understand the risks and benefits of telemedicine consultation as they were shared in a language that you can understand.</span></li>
</ol>
</li>
</ol>
<p></p>
`;
