import React, {memo} from 'react';
import {Platform, View, TouchableWithoutFeedback} from 'react-native';

import {makeStyles} from 'rne-netzon';
import {colorPalette} from '../../../core/config/color.config';
import {SVGIcon, RNEText, IconName} from '../../components';

interface SideMenuItemProps {
  label: string;
  active: boolean;
  iconName?: IconName;
  hideLabel?: boolean;
  disabled?: boolean;
  onPress?: () => void;
}

const SideMenuItem = (props: SideMenuItemProps) => {
  const styles = useStyles();

  return (
    <TouchableWithoutFeedback
      disabled={props.disabled}
      onPress={() => props.onPress?.()}>
      <View style={styles.contentItem} accessibilityRole="button">
        {props.disabled && (
          <View
            style={[
              styles.contentItemOverlay,
              props.hideLabel && styles.contentItemOverlayCompressed,
            ]}
          />
        )}

        {props.iconName && (
          <View style={[!props.hideLabel && styles.iconContainer]}>
            <SVGIcon name={props.iconName} size={32} />
          </View>
        )}

        {!props.hideLabel && (
          <View style={styles.labelContainer}>
            <RNEText
              label={props.label}
              variant="body"
              color={
                props.active
                  ? Platform.OS === 'web'
                    ? 'black'
                    : 'primary'
                  : 'black'
              }
              weight={props.active ? 'bold' : '400'}
            />
          </View>
        )}
      </View>
    </TouchableWithoutFeedback>
  );
};

const useStyles = makeStyles({
  contentItem: {
    flexDirection: 'row',
    marginBottom: 20,
    alignItems: 'center',
  },
  iconContainer: {
    width: 64,
  },
  labelContainer: {
    flex: 1,
  },
  contentItemOverlay: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
    position: 'absolute',
    backgroundColor: colorPalette.white,
    zIndex: 1,
    opacity: 0.6,
  },
  contentItemOverlayCompressed: {
    width: 50,
  },
});

export default memo(SideMenuItem);
