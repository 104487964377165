import React from 'react';
import {Platform, View} from 'react-native';

import {RNEText, SVGIcon} from '../../components';
import {logoStyle, mobileStyles} from './styles';

const OnboardingSlideScreen1 = () => {
  const label = 'Easy to Use';

  return (
    <View style={mobileStyles.sliderContainer}>
      <View style={mobileStyles.sliderContent}>
        <View style={logoStyle}>
          <SVGIcon name="user-friendly" size={210} />
        </View>
        <View>
          <RNEText
            label={Platform.OS === 'web' ? label.toUpperCase() : label}
            color="accent"
            variant="header"
            weight="bold"
            align="center"
          />
        </View>
        <View style={mobileStyles.description}>
          <RNEText
            label="This app is guaranteed user-friendly"
            color={Platform.OS === 'web' ? 'black' : 'grey'}
            variant="body"
            align="center"
          />
        </View>
      </View>
    </View>
  );
};

export default OnboardingSlideScreen1;
