import {useMutation, useQueryClient} from 'react-query';
import {API, ApiEndpointsEnum} from '../api';

import {
  ForgotPasswordReqBody,
  ResetPasswordReqBody,
} from '../types/password.types';

const forgotPasswordMock = async (reqBody: ForgotPasswordReqBody) => {
  const data = await new Promise(resolve =>
    setTimeout(() => resolve({}), 1000),
  );

  return data;
};

const resetPasswordMock = async (reqBody: ResetPasswordReqBody) => {
  const data = await new Promise(resolve =>
    setTimeout(() => resolve({}), 1000),
  );

  return data;
};

const forgotPassword = async (reqBody: ForgotPasswordReqBody) => {
  return API.post(`${ApiEndpointsEnum.FORGOT_PASSWORD}`, reqBody);
};

const useForgotPassword = (reqBody: ForgotPasswordReqBody) => {
  const queryClient = useQueryClient();

  return useMutation(['forgot-password'], () => forgotPassword(reqBody), {
    onSuccess: () => {
      queryClient.invalidateQueries(['forgot-password']);
    },
  });
};

const resetPassword = async (reqBody: ResetPasswordReqBody) => {
  return API.patch(`${ApiEndpointsEnum.RESET_PASSWORD}`, reqBody);
};

const useResetPassword = (reqBody: ResetPasswordReqBody) => {
  const queryClient = useQueryClient();

  return useMutation(['reset-password'], () => resetPassword(reqBody), {
    onSuccess: () => {
      queryClient.invalidateQueries(['reset-password']);
    },
  });
};

export {useForgotPassword, useResetPassword};
