import React, {useEffect} from 'react';
import {
  Platform,
  Image,
  View,
  ScrollView,
  TouchableWithoutFeedback,
} from 'react-native';
import {SafeAreaView} from 'react-native-safe-area-context';
import {makeStyles} from 'rne-netzon';
import {colorPalette} from '../../../core/config/color.config';
import {useToast} from 'react-native-fast-toast';

import {useNavigation} from '@react-navigation/native';
import {NavigationKey} from '../../navigations/config';

import {useForm, Controller} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';

import {LogoContrast} from './../../images/Image';

import {SVGIcon, RNEButton, RNEText, RNEInput} from '../../components';
import {ForgotPasswordReqBody} from '../../server/types/password.types';
import {useForgotPassword} from './../../server/react-query/usePassword';

import useStores from '../../stores/useStores';

const inputContainerStyle = {
  backgroundColor: colorPalette.white,
};

const inputStyleWhite = {
  color: colorPalette.white,
};

const schema: yup.SchemaOf<ForgotPasswordReqBody> = yup.object({
  email: yup
    .string()
    .required('This field is required.')
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      'Enter a valid email address.',
    ),
});

const ForgotPasswordScreen = () => {
  const navigation = useNavigation();
  const webStyles = useWebStyles();
  const sharedStyles = useSharedStyles();
  const mobileStyles = useMobileStyles();
  const {emailStore} = useStores();
  const {
    control,
    handleSubmit,
    getValues,
    reset,
    setValue,
    formState: {errors, isValid},
  } = useForm<ForgotPasswordReqBody>({
    mode: 'all',
    defaultValues: {
      email: '',
    },
    resolver: yupResolver(schema),
  });

  const toast = useToast();

  const loginTextLeftPadding = {
    padding: 12,
  };

  const mutation = useForgotPassword(getValues());

  const goToLogin = () => {
    reset();
    navigation.navigate(NavigationKey.LOGIN_SCREEN);
  };

  const goToSignUp = () => {
    reset();
    navigation.navigate(NavigationKey.SIGNUP_SCREEN);
  };

  const goToResetPassword = () => {
    handleSubmit(onSubmit)();
  };

  const onSubmit = async (formValue: ForgotPasswordReqBody) => {
    if (isValid) {
      try {
        const data = await mutation.mutateAsync();

        if (!data) {
          reset();
          mutation.reset();
          emailStore.setEmail(formValue.email);
          navigation.navigate(NavigationKey.RESET_PASSWORD_SCREEN);
        }
      } catch (error) {
        if (error?.data?.error) {
          toast?.show(error?.data?.error, {type: 'danger'});
        }
      }
    }
  };

  useEffect(() => {
    emailStore.removeEmail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SafeAreaView style={sharedStyles.root}>
      {Platform.OS === 'web' ? (
        <View style={webStyles.mainContainer}>
          <TouchableWithoutFeedback onPress={goToLogin}>
            <View accessibilityRole="button">
              <Image style={webStyles.logo} source={LogoContrast} />
            </View>
          </TouchableWithoutFeedback>
          <View style={webStyles.contentWrapper}>
            <View>
              <RNEText
                label="Can’t remember your password?"
                variant="header"
                weight="bold"
                color="white"
                align="center"
              />
            </View>
            <View>
              <RNEText
                label="You can check your email address for the reset password code."
                variant="body"
                color="white"
                align="center"
              />
              <RNEText
                label="We’ll give you 5 minutes to verify your account."
                variant="body"
                color="white"
                align="center"
              />
            </View>

            <View style={webStyles.inputContainer}>
              <Controller
                control={control}
                render={({field: {onChange, onBlur, value}}) => (
                  <RNEInput
                    placeholder="Email address"
                    inputContainerStyle={inputContainerStyle}
                    leftIcon={<SVGIcon name="email" />}
                    value={value}
                    onChangeText={text => onChange(text)}
                    onSubmitEditing={goToResetPassword}
                    errorMessage={errors.email?.message}
                  />
                )}
                name="email"
              />
            </View>

            <View style={webStyles.actionContainer}>
              <RNEButton
                title="Reset Password"
                onPress={goToResetPassword}
                color="secondary"
                disabled={!isValid}
                loading={mutation.isLoading}
              />
            </View>

            <View style={webStyles.footerActions}>
              <View>
                <RNEText
                  label="Sign Up"
                  variant="button"
                  weight="bold"
                  onPress={goToSignUp}
                  color="white"
                />
              </View>
              <View>
                <RNEText
                  label="Login"
                  variant="button"
                  weight="bold"
                  onPress={goToLogin}
                  color="white"
                />
              </View>
            </View>
          </View>
        </View>
      ) : (
        <ScrollView>
          <View style={mobileStyles.container}>
            <TouchableWithoutFeedback onPress={goToLogin}>
              <View style={mobileStyles.backContainer}>
                <SVGIcon name="left-white" />
                <RNEText
                  label="Log In"
                  variant="body"
                  color="white"
                  style={loginTextLeftPadding}
                />
              </View>
            </TouchableWithoutFeedback>
            <View style={mobileStyles.content}>
              <RNEText
                label="Can’t remember your password?"
                variant="header"
                weight="bold"
                color="white"
                align="center"
              />
              <View style={mobileStyles.body}>
                <RNEText
                  label="You can check your email address for the reset password code.
                Don’t worry, we’ll give you 5 minutes to verify your account."
                  variant="body"
                  color="grey"
                  align="center"
                />
              </View>

              <View style={mobileStyles.inputContainer}>
                <Controller
                  control={control}
                  render={({field: {onChange, onBlur, value}}) => (
                    <RNEInput
                      placeholder="Email address"
                      leftIcon={<SVGIcon name="email" />}
                      keyboardType="email-address"
                      value={value}
                      onChangeText={text => onChange(text)}
                      errorMessage={errors.email?.message}
                      inputStyle={inputStyleWhite}
                    />
                  )}
                  name="email"
                />
              </View>

              <View style={mobileStyles.actionContainer}>
                <RNEButton
                  title="Reset Password"
                  onPress={goToResetPassword}
                  color="secondary"
                  disabled={!isValid}
                  loading={mutation.isLoading}
                />
              </View>
            </View>
          </View>
        </ScrollView>
      )}
    </SafeAreaView>
  );
};

export default ForgotPasswordScreen;

const useWebStyles = makeStyles({
  mainContainer: {
    width: '100%',
    paddingTop: 32,
    paddingBottom: 32,
    paddingLeft: 96,
    paddingRight: 96,
  },
  logo: {
    width: 200,
    height: 44,
  },
  inputContainer: {
    width: '60%',
    marginTop: 76,
    alignSelf: 'center',
  },
  actionContainer: {
    marginTop: 32,
    alignSelf: 'center',
    width: '60%',
  },
  contentWrapper: {
    maxWidth: 650,
    alignSelf: 'center',
    width: '100%',
    marginTop: 96,
  },
  footerActions: {
    width: '60%',
    flexDirection: 'row',
    alignSelf: 'center',
    justifyContent: 'space-between',
    marginTop: 96,
    paddingLeft: 16,
    paddingRight: 16,
  },
});

const useMobileStyles = makeStyles({
  container: {
    flex: 1,
    height: '100%',
    width: '100%',
    paddingHorizontal: 24,
  },
  backContainer: {
    marginBottom: 96,
    alignItems: 'center',
    flexDirection: 'row',
  },
  content: {
    flex: 1,
    width: '100%',
    paddingLeft: 20,
    paddingRight: 20,
  },
  body: {
    marginTop: 20,
  },
  inputContainer: {
    marginTop: 52,
  },
  actionContainer: {
    marginTop: 32,
    alignSelf: 'center',
    width: 150,
  },
});

const useSharedStyles = makeStyles({
  root: {
    height: '100%',
    flexDirection: 'row',
    width: '100%',
    backgroundColor: colorPalette.accent,
  },
});
