import {Platform, StyleSheet} from 'react-native';

export const webStyles = StyleSheet.create({
  logoContainer: {
    marginBottom: 75,
  },
});

export const mobileStyles = StyleSheet.create({
  sliderContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 48,
  },
  sliderContent: {
    alignItems: 'center',
  },
  description: {
    paddingTop: 20,
    textAlign: 'center',
  },
  logoContainer: {
    marginBottom: 45,
  },
});

export const logoStyle =
  Platform.OS === 'web' ? webStyles.logoContainer : mobileStyles.logoContainer;
