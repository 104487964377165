import React, {useState, useEffect} from 'react';
import {View, TouchableOpacity} from 'react-native';

import {makeStyles, Divider} from 'rne-netzon';
import {colorPalette} from '../../../core/config/color.config';

import {RNEText, RNEButton, SVGIcon} from '../../components';

import {useGetAdminPatientProfile} from './../../server/react-query/usePatientProfile';
import {PatientProfileReqResponse} from '../../server/types/patient-profile.types';

import PatientDetailView from '../patient/PatientDetailView';
import PatientMedicalView from '../patient/PatientMedicalView';

interface PatientProfileVerifiedModalProps {
  userId: string;
  onClose?: () => void;
}

const PatientProfileVerifiedModal = (
  props: PatientProfileVerifiedModalProps,
) => {
  const styles = useStyles();

  const {data, isLoading, isFetching} = useGetAdminPatientProfile({
    userId: props.userId,
  });

  const [currentProfile, setCurrentProfile] = useState<
    PatientProfileReqResponse | undefined
  >(data?.[0]);

  const onChangeProfile = (profile: PatientProfileReqResponse) => {
    setCurrentProfile(profile);
  };

  useEffect(() => {
    if (data) {
      setCurrentProfile(data[0]);
    }
  }, [data]);

  return (
    <View style={styles.root}>
      <View style={styles.masterRoot}>
        <View style={styles.masterHeader}>
          <RNEText label="Sub Profiles" weight="600" />
        </View>

        {data?.map((profile, index) => (
          <View key={profile.patientId}>
            <Divider style={styles.verticalDivider} />

            <TouchableOpacity
              activeOpacity={0.8}
              onPress={() => onChangeProfile(profile)}>
              <View key={profile.patientId} style={styles.patientItem}>
                <RNEText
                  label={`${profile.humanName.given[0]} ${profile.humanName.family}`}
                  color={
                    profile.patientId === currentProfile?.patientId
                      ? 'primary'
                      : 'black'
                  }
                  weight={
                    profile.patientId === currentProfile?.patientId
                      ? '400'
                      : '300'
                  }
                  variant="button"
                />
              </View>
            </TouchableOpacity>

            {index === data.length - 1 && (
              <Divider style={styles.verticalDivider} />
            )}
          </View>
        ))}
      </View>
      <Divider style={styles.divider} />
      <View style={styles.detailRoot}>
        <View style={styles.detailContainer}>
          <View style={styles.headerContainer}>
            <RNEText label="Patient Details" weight="600" />
            {(isLoading || isFetching) && (
              <RNEButton
                height={24}
                loading={isLoading}
                type="clear"
                loadingProps={loadingStyle}
              />
            )}
          </View>

          <View style={styles.detailView}>
            {<PatientDetailView detail={currentProfile} />}
          </View>
        </View>
        <View style={styles.dividerContainer}>
          <Divider style={styles.divider} />
        </View>
        <View style={styles.medicalContainer}>
          <RNEText label="Medical History" weight="600" />

          <View style={styles.detailView}>
            {<PatientMedicalView patientId={currentProfile?.patientId || ''} />}
          </View>
        </View>

        <View style={styles.closeContainer}>
          <TouchableOpacity activeOpacity={0.7} onPress={props.onClose}>
            <SVGIcon name="close-gray-71" size={32} />
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
};

const loadingStyle = {
  color: colorPalette.primary,
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '75vw',
    maxWidth: '75vw',
    height: '70vh',
    maxHeight: '70vh',
    flexDirection: 'row',
  },
  masterRoot: {
    width: 200,
    paddingHorizontal: 24,
    paddingVertical: 32,
  },
  masterHeader: {
    marginBottom: 16,
  },
  patientItem: {
    paddingVertical: 12,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  detailRoot: {
    flex: 1,
    flexDirection: 'row',
    position: 'relative',
  },
  detailContainer: {
    width: 300,
    paddingHorizontal: 48,
    paddingVertical: 32,
  },
  detailView: {
    height: '100%',
    marginTop: 32,
  },
  dividerContainer: {
    paddingVertical: 32,
  },
  divider: {
    width: 1,
    height: '100%',
  },
  verticalDivider: {
    height: 1,
    width: '100%',
  },
  medicalContainer: {
    flex: 1,
    paddingHorizontal: 48,
    paddingVertical: 32,
  },
  headerContainer: {
    height: 24,
    flexDirection: 'row',
    alignItems: 'center',
  },
  closeContainer: {
    position: 'absolute',
    right: 24,
    top: 24,
  },
}));

export default PatientProfileVerifiedModal;
