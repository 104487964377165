import React from 'react';
import {Platform} from 'react-native';
import {createStackNavigator} from '@react-navigation/stack';

import {observer} from 'mobx-react';

import {NavigationKey} from '../config/navigation.config';
import useStores from '../../stores/useStores';

import AuthStackNavigator from './AuthStackNavigator';
import OnboardingStackNavigator from './OnboardingStackNavigator';
import AdminDrawerNavigator from './AdminDrawerNavigator';
import PatientDrawerNavigator from './PatientDrawerNavigator';
import DoctorDrawerNavigator from './DoctorDrawerNavigator';
import PaymentWebViewStackNavigator from './PaymentWebViewStackNavigator';

const {Navigator, Screen} = createStackNavigator();

const RootStackNavigator = () => {
  const {authStore} = useStores();

  const getOnboardingScreen = () => {
    return (
      <Screen
        name={NavigationKey.ONBOARDING_STACK}
        component={OnboardingStackNavigator}
      />
    );
  };

  const getAuthScreen = () => {
    return (
      <>
        <Screen
          name={NavigationKey.AUTH_STACK}
          component={AuthStackNavigator}
        />
        <Screen
          name={NavigationKey.PAYMENT_WEB_VIEW_STACK}
          component={PaymentWebViewStackNavigator}
        />
      </>
    );
  };

  const getMainScreen = () => {
    switch (authStore.auth?.user.userRole) {
      case 'admin':
        return (
          <Screen
            name={NavigationKey.MAIN_DRAWER}
            component={AdminDrawerNavigator}
          />
        );

      case 'patient':
        return (
          <>
            <Screen
              name={NavigationKey.MAIN_DRAWER}
              component={PatientDrawerNavigator}
            />
            <Screen
              name={NavigationKey.PAYMENT_WEB_VIEW_STACK}
              component={PaymentWebViewStackNavigator}
            />
          </>
        );

      case 'practitioner':
        return (
          <Screen
            name={NavigationKey.MAIN_DRAWER}
            component={DoctorDrawerNavigator}
          />
        );

      default:
        return null;
    }
  };

  const getNavigator = (children: React.ReactNode) => {
    return (
      <Navigator
        screenOptions={{
          headerShown: false,
        }}>
        {children}
      </Navigator>
    );
  };

  const getAuthStateScreen = () => {
    return authStore.loggedIn ? getMainScreen() : getOnboardingStateScreen();
  };

  const getOnboardingStateScreen = () => {
    if (Platform.OS === 'web') {
      return getAuthScreen();
    } else {
      return authStore.isOnboardingDone
        ? getAuthScreen()
        : getOnboardingScreen();
    }
  };

  return getNavigator(getAuthStateScreen());
};

export default observer(RootStackNavigator);
