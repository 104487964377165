import {makeAutoObservable, observable, action, computed} from 'mobx';

import {PractitionerProfile} from '../server/types/practitioner-profile.types';
import {PractitionerStatus} from '../server/types/practitioner-status.types';

import {persistStore} from '../utils/mobx.utils';

export interface IDoctorProfilePartialData {
  firstName: string;
  lastName: string;
  status: PractitionerStatus;
}

export class DoctorProfileStore {
  partialData: IDoctorProfilePartialData | null = null;
  doctorProfile: PractitionerProfile | null = null;

  constructor() {
    makeAutoObservable(this, {
      partialData: observable,
      doctorProfile: observable,
      logout: action,
      setPartialData: action,
      setDoctorProfile: action,
      doctorStatus: computed,
      statusData: computed,
    });

    persistStore(this, ['partialData', 'doctorProfile'], 'DoctorProfileStore');
  }

  setPartialData(data: IDoctorProfilePartialData) {
    this.partialData = data;
  }

  setDoctorProfile(profile: PractitionerProfile) {
    this.doctorProfile = profile;
  }

  logout() {
    this.doctorProfile = null;
    this.partialData = null;
  }

  get doctorStatus() {
    if (this.doctorProfile && this.doctorProfile.status) {
      return this.doctorProfile.status;
    } else {
      this.partialData?.status;
    }
  }

  get statusData() {
    return this.partialData;
  }
}
