import React, {useCallback, useLayoutEffect} from 'react';
import {View, Platform, ScrollView, TextStyle} from 'react-native';

import {observer} from 'mobx-react';

import {makeStyles} from 'rne-netzon';
import {colorPalette} from '../../../core/config/color.config';

import useStores from '../../stores/useStores';
import {
  DrawerActions,
  useFocusEffect,
  useNavigation,
} from '@react-navigation/native';
import {NavigationKey} from '../../navigations/config';

import {useGetConsultationDashboard} from '../../server/react-query/useConsultation';

import {RNEButton, RNEText, ShadowedCard} from '../../components';
import {
  DoctorDashboardCard,
  HeaderProfileLeft,
  HeaderProfileRight,
} from '../../views';

const DoctorLandingScreen = () => {
  const webStyles = useWebStyles();
  const mobileStyles = useMobileStyles();
  const {doctorProfileStore} = useStores();

  const navigation = useNavigation();

  const {data, isLoading, refetch} = useGetConsultationDashboard();

  const goToConsultationTab = (tabIndex: number) => {
    if (Platform.OS === 'web') {
      navigation.navigate(NavigationKey.DOCTOR_CONSULTATION_STACK, {
        screen: NavigationKey.CONSULTATION_LIST,
        params: {
          tab: tabIndex,
        },
      });
    } else {
      let screen: NavigationKey;

      switch (tabIndex) {
        case 0:
          screen = NavigationKey.CONSULTATION_TAB_ONGOING;
          break;

        case 1:
          screen = NavigationKey.CONSULTATION_TAB_PENDING;
          break;

        case 2:
          screen = NavigationKey.CONSULTATION_TAB_HISTORY;
          break;

        default:
          screen = NavigationKey.CONSULTATION_TAB_ONGOING;
          break;
      }

      navigation.navigate(NavigationKey.DOCTOR_CONSULTATION_STACK, {
        screen: NavigationKey.CONSULTATION_LIST,
        params: {
          screen: screen,
        },
      });
    }
  };

  useLayoutEffect(() => {
    navigation.setOptions(
      Platform.OS === 'web'
        ? {
            headerShown: true,
            title: 'Dashboard',
            ...{
              headerStyle: {
                backgroundColor: colorPalette.purple,
                borderBottomColor: 'transparent',
                height: 72,
              },
              headerTintColor: colorPalette.white,
              headerTitleStyle: {
                fontWeight: 'bold',
                fontFamily: 'Quicksand-Bold',
                fontSize: 16,
                paddingLeft: 40,
              } as TextStyle,
            },
            headerLeft: () => null,
          }
        : {
            headerShown: true,
            ...{
              headerStyle: {
                elevation: 0,
                shadowOpacity: 0,
              },
              headerTitleStyle: {
                fontWeight: 'bold',
                fontFamily: 'Quicksand-Bold',
                fontSize: 16,
              } as TextStyle,
            },
            headerStyle: {
              backgroundColor: colorPalette.white,
              borderBottomColor: 'transparent',
              elevation: 0,
              shadowOpacity: 0,
            },
            headerTitleStyle: {
              paddingLeft: 28,
            },
            headerLeft: () => (
              <HeaderProfileLeft
                inProfileScreen={true}
                isLogoContrast={false}
              />
            ),
            headerRight: () => (
              <HeaderProfileRight
                showMessageIcon={false}
                isMenuLight={false}
                onClick={() =>
                  navigation.dispatch(DrawerActions.toggleDrawer())
                }
              />
            ),
            ...{
              title: '',
              headerTintColor: colorPalette.primary,
            },
          },
    );
  }, [navigation]);

  useFocusEffect(
    useCallback(() => {
      if (Platform.OS !== 'web') {
        refetch();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []),
  );

  return (
    <>
      {Platform.OS === 'web' ? (
        <ScrollView>
          <View style={webStyles.mainContainer}>
            <View>
              <RNEText
                label={`Hi, Doc ${doctorProfileStore.doctorProfile?.humanName.given[0]}!`}
                variant="header"
                weight="bold"
                color="accent"
                align="center"
              />
              <RNEText
                label="Here are your consultations for today:"
                align="center"
                color="blackLight"
                style={customWebTextFont}
              />
            </View>
            <View style={webStyles.consultationCards}>
              <View style={webStyles.cardContainer}>
                <DoctorDashboardCard
                  isLoading={isLoading}
                  count={data?.pending}
                  label="REQUEST"
                  bgColor={colorPalette.white}
                  labelColor="blackLight"
                  countColor="success"
                  loaderColor={colorPalette.success}
                  onPress={() => goToConsultationTab(1)}
                />
              </View>
              <View style={webStyles.cardContainer}>
                <DoctorDashboardCard
                  isLoading={isLoading}
                  count={data?.ongoing}
                  label="ONGOING"
                  bgColor={colorPalette.success}
                  onPress={() => goToConsultationTab(0)}
                />
              </View>
              <View style={webStyles.cardContainer}>
                <DoctorDashboardCard
                  isLoading={isLoading}
                  count={data?.completed}
                  label="COMPLETED"
                  bgColor={colorPalette.purple}
                  onPress={() => goToConsultationTab(2)}
                />
              </View>
            </View>
          </View>
        </ScrollView>
      ) : (
        <ScrollView>
          <View style={mobileStyles.mainContainer}>
            <View>
              <RNEText
                label={`Hi, Doc ${doctorProfileStore.doctorProfile?.humanName.given[0]}!`}
                variant="header"
                weight="bold"
                color="accent"
              />
              <RNEText
                label="Here are your consultations for today:"
                variant="body"
              />
            </View>
            <View style={mobileStyles.cardsContainer}>
              <View style={mobileStyles.cardWrapper}>
                <ShadowedCard onPress={() => goToConsultationTab(1)}>
                  <View style={mobileStyles.cardContainer}>
                    <View style={mobileStyles.cardValue}>
                      {!isLoading ? (
                        <RNEText
                          label={
                            data?.pending.toString().padStart(2, '0') || ''
                          }
                          variant="header"
                          weight="bold"
                          color="success"
                          style={mobileCardLabelStyle}
                        />
                      ) : (
                        <RNEButton
                          height={24}
                          loading={isLoading}
                          type="clear"
                          loadingProps={{
                            color: colorPalette.success,
                          }}
                          buttonStyle={buttonStyle}
                        />
                      )}
                    </View>

                    <View style={mobileStyles.cardLabel}>
                      <RNEText
                        label="REQUEST"
                        variant="subheader"
                        weight="bold"
                        color="black"
                      />
                    </View>
                  </View>
                </ShadowedCard>
              </View>
              <View style={mobileStyles.cardWrapper}>
                <ShadowedCard
                  backgroundColor={colorPalette.success}
                  onPress={() => goToConsultationTab(0)}>
                  <View style={mobileStyles.cardContainer}>
                    <View style={mobileStyles.cardValue}>
                      {!isLoading ? (
                        <RNEText
                          label={
                            data?.ongoing.toString().padStart(2, '0') || ''
                          }
                          variant="header"
                          weight="bold"
                          color="white"
                          style={mobileCardLabelStyle}
                        />
                      ) : (
                        <RNEButton
                          height={24}
                          loading={isLoading}
                          type="clear"
                          loadingProps={{
                            color: colorPalette.white,
                          }}
                          buttonStyle={buttonStyle}
                        />
                      )}
                    </View>

                    <View style={mobileStyles.cardLabel}>
                      <RNEText
                        label="ONGOING"
                        variant="subheader"
                        weight="bold"
                        color="white"
                      />
                    </View>
                  </View>
                </ShadowedCard>
              </View>
              <View style={mobileStyles.cardWrapper}>
                <ShadowedCard
                  backgroundColor={colorPalette.purple}
                  onPress={() => goToConsultationTab(2)}>
                  <View style={mobileStyles.cardContainer}>
                    <View style={mobileStyles.cardValue}>
                      {!isLoading ? (
                        <RNEText
                          label={
                            data?.completed.toString().padStart(2, '0') || ''
                          }
                          variant="header"
                          weight="bold"
                          color="white"
                          style={mobileCardLabelStyle}
                        />
                      ) : (
                        <RNEButton
                          height={24}
                          loading={isLoading}
                          type="clear"
                          loadingProps={{
                            color: colorPalette.white,
                          }}
                          buttonStyle={buttonStyle}
                        />
                      )}
                    </View>

                    <View style={mobileStyles.cardLabel}>
                      <RNEText
                        label="COMPLETED"
                        variant="subheader"
                        weight="bold"
                        color="white"
                      />
                    </View>
                  </View>
                </ShadowedCard>
              </View>
            </View>
          </View>
        </ScrollView>
      )}
    </>
  );
};

export default observer(DoctorLandingScreen);

const customWebTextFont = {
  fontSize: 20,
  marginTop: 4,
};

const buttonStyle = {
  paddingHorizontal: 0,
};

const useWebStyles = makeStyles({
  mainContainer: {
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 100,
  },
  consultationCards: {
    marginTop: 96,
    width: '100%',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  cardWrapper: {
    width: 355,
    height: 312,
  },
  cardContainer: {
    marginRight: 32,
    marginBottom: 32,
    flexShrink: 1,
    flexGrow: 0,
    minWidth: 200,
    maxWidth: 250,
    width: '100%',
  },
  cardLabel: {
    marginTop: 8,
  },
});

const mobileCardLabelStyle = {
  fontSize: 60,
};

const useMobileStyles = makeStyles({
  mainContainer: {
    paddingVertical: 60,
    paddingHorizontal: 40,
  },
  cardsContainer: {
    marginTop: 32,
  },
  cardWrapper: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  cardContainer: {
    height: 72,
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: -12,
  },
  cardValue: {
    width: 80,
  },
  cardLabel: {
    flex: 1,
    alignItems: 'center',
    marginTop: 12,
    marginLeft: 8,
  },
});
