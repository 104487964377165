import React from 'react';
import {Dimensions, StyleSheet, View, Image} from 'react-native';
import {SafeAreaView} from 'react-native-safe-area-context';
import {useNavigation} from '@react-navigation/native';
import {NavigationKey} from '../../navigations/config';

const AppLogo = require('./../../../assets/logo/house-call-logo.png');

import {RNEText, SVGIcon, RNEIconButton} from '../../components';
import {colorPalette} from '../../../core/config/color.config';

const Dimension = Dimensions.get('screen');

const OnboardingEntryScreen = () => {
  const navigation = useNavigation();

  const onNext = () => {
    navigation.navigate(NavigationKey.ONBOARDING_SCREEN_SLIDER);
  };

  return (
    <>
      <SafeAreaView style={mobileStyles.root}>
        <View style={mobileStyles.onboardingContainer}>
          <View style={mobileStyles.logoContainer}>
            <Image
              resizeMode={'contain'}
              style={mobileStyles.logo}
              source={AppLogo}
            />
          </View>
        </View>
      </SafeAreaView>
      <View style={mobileStyles.bodyContainer}>
        <View>
          <RNEText
            label="How are you today?"
            color="white"
            variant="header"
            weight="bold"
          />
        </View>
        <View style={mobileStyles.description}>
          <RNEText
            label="Get medical consultations anywhere, anytime at the comfort of your fingertips."
            color="white"
            variant="body"
            weight="300"
          />
        </View>
      </View>

      <View style={mobileStyles.actionContainer} />

      <View style={mobileStyles.actionButton}>
        <RNEIconButton
          icon={<SVGIcon name="right-yellow" size={40} />}
          type="clear"
          color="tint"
          onPress={onNext}
          activeOpacity={1}
          size={64}
        />
      </View>
    </>
  );
};

export default OnboardingEntryScreen;

const mobileStyles = StyleSheet.create({
  root: {
    flex: 1,
    paddingVertical: 40,
    paddingHorizontal: 48,
  },
  onboardingContainer: {
    width: '100%',
    height: '100%',
    justifyContent: 'space-between',
  },
  logoContainer: {
    marginTop: 22,
    width: '100%',
  },
  logo: {
    width: '100%',
    height: 200,
  },
  bodyContainer: {
    position: 'absolute',
    width: Dimension.width,
    height: Dimension.width,
    left: -48,
    bottom: 48,
    borderRadius: Dimension.width,
    shadowColor: '#060F58',
    shadowOffset: {
      width: 5,
      height: 20,
    },
    shadowRadius: 10,
    shadowOpacity: 0.2,
    elevation: 5,
    zIndex: 5,
    backgroundColor: colorPalette.primary,
    justifyContent: 'center',
    paddingLeft: 96,
    paddingRight: 56,
  },
  description: {
    paddingTop: 5,
  },
  actionContainer: {
    position: 'absolute',
    width: Dimension.width / 2.25,
    height: Dimension.width / 2.25,
    right: -20,
    bottom: -32,
    borderRadius: Dimension.width / 2.25,
    shadowColor: '#060F58',
    shadowOffset: {
      width: 5,
      height: 20,
    },
    shadowRadius: 10,
    shadowOpacity: 0.2,
    elevation: 4,
    zIndex: 4,
    backgroundColor: colorPalette.accent,
    justifyContent: 'center',
    alignItems: 'center',
  },
  actionButton: {
    position: 'absolute',
    right: (Dimension.width / 2.25 - 64 - 20 * 2) / 2,
    bottom: (Dimension.width / 2.25 - 64 - 32 * 2) / 2,
    elevation: 10,
    zIndex: 10,
  },
});
