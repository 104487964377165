import {makeAutoObservable, observable, action, computed} from 'mobx';
import {PractitionerSpecialization} from '../server/types/practitioner-profile.types';

import {persistStore} from '../utils/mobx.utils';

export class SpecializationStore {
  specializations: PractitionerSpecialization[] = [];

  constructor() {
    makeAutoObservable(this, {
      specializations: observable,
      setSpecializations: action,
    });

    persistStore(this, ['specializations'], 'SpecializationStore');
  }

  setSpecializations(data: PractitionerSpecialization[]) {
    this.specializations = data;
  }
}
