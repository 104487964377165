import React, {memo, useEffect, useState} from 'react';
import {View, Text, TouchableOpacity} from 'react-native';

import {makeStyles} from 'rne-netzon';
import {colorPalette} from '../../../core/config/color.config';
import {RNEText} from '../../components';

import useStores from '../../stores/useStores';

import {Photo} from '../../server/types/user.types';
import {ProcedureConsultationReqResponse} from '../../server/types/procedure.types';
import {formatShortDate, timestamp} from '../../utils/date.utils';

import {default as ConsultationPhoto} from './ConsultationPhoto';
import {isToday} from 'date-fns';
import {CodedConversationMessages} from '../../server/types/conversation.types';

interface ConsultationListItemOngoingProps {
  item: ProcedureConsultationReqResponse;
  onPress?: () => void;
}

const ConsultationListItemOngoing = (
  props: ConsultationListItemOngoingProps,
) => {
  const styles = useStyle();
  const {authStore} = useStores();

  const [displayName, setDisplayName] = useState('');
  const [displayPhoto, setDisplayPhoto] = useState<Photo>();
  const [displayLatestMessage, setDisplayLatestMessage] = useState('');

  useEffect(() => {
    switch (authStore.userRole) {
      case 'patient':
        if (props.item.performer) {
          setDisplayName(
            `Dr. ${props.item.performer.humanName.given[0]} ${props.item.performer.humanName.family}`,
          );
          setDisplayPhoto(props.item.performer.photo);
        } else {
          setDisplayName('Deleted Profile');
        }
        break;

      case 'practitioner':
        if (props.item.subject) {
          setDisplayName(
            `${props.item.subject.humanName.given[0]} ${props.item.subject.humanName.family}`,
          );
          setDisplayPhoto(props.item.subject.photo);
        } else {
          setDisplayName('Deleted Profile');
        }
        break;

      default:
        break;
    }

    switch (props.item.note?.text) {
      case CodedConversationMessages.VIDEO_CALL_TRIGGER:
        setDisplayLatestMessage('Video Call Invitation');
        break;

      case CodedConversationMessages.VIDEO_CALL_ACCEPT:
        setDisplayLatestMessage('Video Call Invitation Acceptance');
        break;

      case undefined:
        setDisplayLatestMessage('--');
        break;

      default:
        setDisplayLatestMessage(props.item.note?.text);
        break;
    }
  }, [authStore.userRole, props]);

  return (
    <TouchableOpacity activeOpacity={0.75} onPress={props.onPress}>
      <View style={styles.root}>
        <View style={styles.photo}>
          <ConsultationPhoto photo={displayPhoto} />
        </View>

        <View style={styles.message}>
          <View>
            <RNEText
              label={displayName}
              color="blackLight"
              weight="600"
              numberOfLines={1}
              ellipsizeMode="tail"
            />
          </View>

          <View>
            <RNEText
              label={displayLatestMessage}
              color="blackLight"
              variant="button"
              weight="400"
              numberOfLines={2}
              ellipsizeMode="tail"
            />
          </View>
        </View>

        <View style={styles.timestamp}>
          <RNEText
            label={
              isToday(new Date(props.item.note.time))
                ? timestamp(new Date(props.item.note.time))
                : formatShortDate(new Date(props.item.note.time))
            }
            color="grey"
            variant="button"
          />
        </View>
      </View>
    </TouchableOpacity>
  );
};

const useStyle = makeStyles({
  root: {
    flexDirection: 'row',
    paddingHorizontal: 24,
    paddingVertical: 24,
    borderBottomWidth: 1,
    borderBottomColor: colorPalette.light,
  },
  photo: {},
  message: {
    flex: 1,
    paddingHorizontal: 16,
    paddingTop: 4,
  },
  timestamp: {
    paddingTop: 4,
  },
});

export default memo(ConsultationListItemOngoing);
