import React from 'react';
import {View} from 'react-native';
import {makeStyles} from 'rne-netzon';

import {PractitionerProfile} from '../../server/types/practitioner-profile.types';

import DoctorConsultationProfile from '../doctor/DoctorConsultationProfile';

interface ConsultationDoctorProfileModalProps {
  practitioner: PractitionerProfile;
  onClose?: () => void;
}

const ConsultationDoctorProfileModal = (
  props: ConsultationDoctorProfileModalProps,
) => {
  const styles = useStyles();

  return (
    <View style={styles.root}>
      <DoctorConsultationProfile
        practitioner={props.practitioner}
        onClose={props.onClose}
      />
    </View>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    paddingVertical: 24,
    paddingHorizontal: 32,
  },
}));

export default ConsultationDoctorProfileModal;
