import React, {useState} from 'react';
import {
  View,
  Platform,
  ScrollView,
  Dimensions,
  TouchableWithoutFeedback,
} from 'react-native';

import {makeStyles, Overlay, Image} from 'rne-netzon';
import {colorPalette} from '../../../core/config/color.config';
import {observer} from 'mobx-react';

import {RNEText, ShadowedCard, SVGIcon, RNEButton} from '../../components';

import {CommonActions, useNavigation} from '@react-navigation/native';
import {NavigationKey} from '../../navigations/config';
import {useGetPatientProfiles} from '../../server/react-query/usePatientProfile';
import useStores from '../../stores/useStores';
import {ISelectedAccount} from '../../stores/patientProfileStore';
import {SwitchPatientConfirmationModal} from '../../views';

const PatientProfilesScreen = () => {
  const navigation = useNavigation();
  const sharedStyles = useSharedStyles();
  const webStyles = useWebStyles();
  const mobileStyles = useMobileStyles();
  const {
    patientProfileStore,
    recentlyViewedAccountsStore,
    conversationStore,
  } = useStores();
  const {status, data, error, isFetching} = useGetPatientProfiles();

  const activeProfile = patientProfileStore.activeProfileData;

  const [showDialog, setShowDialog] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState(activeProfile);

  const goToAddProfile = () => {
    navigation.navigate(NavigationKey.PATIENT_PROFILE_ADD);
  };

  const goToEditProfile = (patientId: string, accountOwner: boolean) => {
    const accountData: ISelectedAccount = {
      patientId: patientId,
      accountOwner: accountOwner,
    };
    patientProfileStore.setSelectedProfile(accountData);
    navigation.navigate(NavigationKey.PATIENT_PROFILE_EDIT);
  };

  const toggleSwitchConfirmation = (
    patientId: string,
    firstName: string,
    lastName: string,
    imageUrl?: string,
  ) => {
    if (activeProfile?.patientId !== patientId) {
      setSelectedProfile({
        patientId: patientId,
        firstName: firstName,
        lastName: lastName,
        imageUrl: imageUrl,
      });
      setShowDialog(true);
    }
  };

  const switchProfile = () => {
    setShowDialog(false);
    patientProfileStore.setActivePatient(selectedProfile!);
    recentlyViewedAccountsStore.resetRecentlyViewedAccount();
    conversationStore.resetAccessToken();
    navigation.dispatch(
      CommonActions.reset({
        index: 0,
        routes: [{name: NavigationKey.PATIENT_SCREEN_LANDING}],
      }),
    );
  };

  return (
    <>
      <Overlay
        isVisible={showDialog}
        overlayStyle={sharedStyles.overlayContainer}>
        <SwitchPatientConfirmationModal
          onConfirm={switchProfile}
          onCancel={() => setShowDialog(false)}
        />
      </Overlay>
      {Platform.OS === 'web' ? (
        <ScrollView>
          <View style={webStyles.mainContainer}>
            <View style={webStyles.spinnerContainer}>
              <RNEButton
                height={20}
                loadingProps={loadingPropsStyle}
                loading={isFetching}
              />
            </View>
            <View>
              <RNEText
                label={`Hi, ${activeProfile?.firstName}!`}
                variant="header"
                weight="bold"
                color="accent"
              />
              <RNEText
                label="Who needs to have a check up today?"
                variant="body"
                color="black"
              />
            </View>
            <View style={webStyles.cardsContainer}>
              {data?.map((profile, index) => {
                return (
                  <View style={webStyles.cardContainer} key={index}>
                    <ShadowedCard
                      width={300}
                      height={220}
                      backgroundColor={
                        activeProfile?.patientId !== profile.patientId
                          ? colorPalette.white
                          : colorPalette.primary
                      }
                      onPress={() =>
                        toggleSwitchConfirmation(
                          profile.patientId,
                          profile.humanName.given[0],
                          profile.humanName.family,
                          profile.photo?.url,
                        )
                      }>
                      <View style={sharedStyles.leftContainer}>
                        {profile.photo ? (
                          <Image
                            source={{uri: profile.photo.url}}
                            style={sharedStyles.imageStyle}
                            placeholderStyle={
                              sharedStyles.imagePlaceholderStyle
                            }
                            PlaceholderContent={
                              <RNEButton
                                height={20}
                                loadingProps={loadingPropsStyle}
                                loading={true}
                              />
                            }
                          />
                        ) : (
                          <SVGIcon
                            name={
                              activeProfile?.patientId !== profile.patientId
                                ? 'user'
                                : 'user-white'
                            }
                            size={68}
                          />
                        )}
                      </View>
                      <View style={sharedStyles.textContainer}>
                        <View style={sharedStyles.nameContainer}>
                          <RNEText
                            label={
                              profile.humanName.given[0] +
                              ' ' +
                              profile.humanName.family
                            }
                            variant="title"
                            weight="bold"
                            color={
                              activeProfile?.patientId !== profile.patientId
                                ? 'black'
                                : 'white'
                            }
                            ellipsizeMode="tail"
                            numberOfLines={1}
                            style={nameStyle}
                          />
                          <RNEText
                            label={
                              profile.relationship
                                ? profile.relationship
                                : 'Me (Account Owner)'
                            }
                            variant="button"
                            color={
                              activeProfile?.patientId !== profile.patientId
                                ? 'black'
                                : 'white'
                            }
                          />
                        </View>
                        <View style={sharedStyles.infoContainer}>
                          <View style={sharedStyles.reqColumn1}>
                            <RNEText
                              label="Request(s)"
                              variant="button"
                              color={
                                activeProfile?.patientId !== profile.patientId
                                  ? 'black'
                                  : 'white'
                              }
                            />
                          </View>
                          <View style={sharedStyles.column2}>
                            <RNEText
                              label=":"
                              variant="button"
                              color={
                                activeProfile?.patientId !== profile.patientId
                                  ? 'black'
                                  : 'white'
                              }
                            />
                          </View>
                          <View>
                            <RNEText
                              label={profile.consultationServiceRequestCount.toString()}
                              variant="button"
                              color={
                                activeProfile?.patientId !== profile.patientId
                                  ? 'black'
                                  : 'white'
                              }
                            />
                          </View>
                        </View>
                        <TouchableWithoutFeedback
                          onPress={() =>
                            goToEditProfile(
                              profile.patientId,
                              profile.relationship ? false : true,
                            )
                          }>
                          <View style={sharedStyles.editContainer}>
                            <View style={sharedStyles.iconContainer}>
                              <SVGIcon
                                name={
                                  activeProfile?.patientId !== profile.patientId
                                    ? 'pen'
                                    : 'pen-white'
                                }
                                size={20}
                              />
                            </View>
                            <RNEText
                              label="Edit Profile"
                              variant="button"
                              weight="bold"
                              color={
                                activeProfile?.patientId !== profile.patientId
                                  ? 'primary'
                                  : 'white'
                              }
                            />
                          </View>
                        </TouchableWithoutFeedback>
                      </View>
                    </ShadowedCard>
                  </View>
                );
              })}
              {data && data.length < 5 && (
                <View style={webStyles.cardContainer}>
                  <ShadowedCard
                    width={300}
                    height={220}
                    onPress={goToAddProfile}>
                    <View style={sharedStyles.leftContainer}>
                      <SVGIcon name="medical-specialist-cross" size={60} />
                    </View>
                    <View style={sharedStyles.textContainer}>
                      <RNEText
                        label="Add New Profile"
                        variant="body"
                        weight="bold"
                        color="primary"
                      />
                    </View>
                  </ShadowedCard>
                </View>
              )}
            </View>
          </View>
        </ScrollView>
      ) : (
        <ScrollView>
          <View style={mobileStyles.mainContainer}>
            <View>
              <RNEText
                label={`Hi, ${activeProfile?.firstName}!`}
                variant="header"
                weight="bold"
                color="accent"
              />
              <RNEText
                label="Who needs to have a check up today?"
                variant="body"
                color="black"
              />
            </View>
            <View style={mobileStyles.spinnerContainer}>
              <RNEButton
                height={24}
                loadingProps={loadingPropsStyle}
                loading={isFetching}
              />
            </View>
            <View style={mobileStyles.cardsContainer}>
              {data?.map((profile, index) => {
                return (
                  <ShadowedCard
                    width="100%"
                    height={200}
                    key={index}
                    backgroundColor={
                      activeProfile?.patientId !== profile.patientId
                        ? colorPalette.white
                        : colorPalette.primary
                    }
                    onPress={() =>
                      toggleSwitchConfirmation(
                        profile.patientId,
                        profile.humanName.given[0],
                        profile.humanName.family,
                        profile.photo?.url,
                      )
                    }>
                    <View style={sharedStyles.cardContainer}>
                      <View style={sharedStyles.leftContainer}>
                        {profile.photo ? (
                          <Image
                            source={{uri: profile.photo.url}}
                            style={sharedStyles.imageStyle}
                            placeholderStyle={
                              sharedStyles.imagePlaceholderStyle
                            }
                            PlaceholderContent={
                              <RNEButton
                                height={40}
                                loadingProps={loadingPropsStyle}
                                loading={true}
                              />
                            }
                          />
                        ) : (
                          <SVGIcon
                            name={
                              activeProfile?.patientId !== profile.patientId
                                ? 'user'
                                : 'user-white'
                            }
                            size={68}
                          />
                        )}
                      </View>
                      <View style={sharedStyles.textContainer}>
                        <View style={sharedStyles.nameContainer}>
                          <RNEText
                            label={
                              profile.humanName.given[0] +
                              ' ' +
                              profile.humanName.family
                            }
                            variant="title"
                            weight="bold"
                            color={
                              activeProfile?.patientId !== profile.patientId
                                ? 'black'
                                : 'white'
                            }
                            ellipsizeMode="tail"
                            numberOfLines={1}
                            style={nameStyle}
                          />
                          <RNEText
                            label={
                              profile.relationship
                                ? profile.relationship
                                : 'Me (Account Owner)'
                            }
                            variant="button"
                            color={
                              activeProfile?.patientId !== profile.patientId
                                ? 'black'
                                : 'white'
                            }
                          />
                        </View>
                        <View style={sharedStyles.infoContainer}>
                          <View style={sharedStyles.reqColumn1}>
                            <RNEText
                              label="Request(s)"
                              variant="button"
                              color={
                                activeProfile?.patientId !== profile.patientId
                                  ? 'black'
                                  : 'white'
                              }
                            />
                          </View>
                          <View style={sharedStyles.column2}>
                            <RNEText
                              label=":"
                              variant="button"
                              color={
                                activeProfile?.patientId !== profile.patientId
                                  ? 'black'
                                  : 'white'
                              }
                            />
                          </View>
                          <View>
                            <RNEText
                              label={profile.consultationServiceRequestCount.toString()}
                              variant="button"
                              color={
                                activeProfile?.patientId !== profile.patientId
                                  ? 'black'
                                  : 'white'
                              }
                            />
                          </View>
                        </View>
                        <TouchableWithoutFeedback
                          onPress={() =>
                            goToEditProfile(
                              profile.patientId,
                              profile.relationship ? false : true,
                            )
                          }>
                          <View style={sharedStyles.editContainer}>
                            <View style={sharedStyles.iconContainer}>
                              <SVGIcon
                                name={
                                  activeProfile?.patientId !== profile.patientId
                                    ? 'pen'
                                    : 'pen-white'
                                }
                                size={20}
                              />
                            </View>
                            <RNEText
                              label="Edit Profile"
                              variant="body"
                              weight="bold"
                              color={
                                activeProfile?.patientId !== profile.patientId
                                  ? 'primary'
                                  : 'white'
                              }
                            />
                          </View>
                        </TouchableWithoutFeedback>
                      </View>
                    </View>
                  </ShadowedCard>
                );
              })}

              {data && data.length < 5 && (
                <ShadowedCard
                  width="100%"
                  height={200}
                  onPress={goToAddProfile}>
                  <View style={sharedStyles.cardContainer}>
                    <View style={sharedStyles.leftContainer}>
                      <SVGIcon name="medical-specialist-cross" size={60} />
                    </View>
                    <View style={sharedStyles.textContainer}>
                      <RNEText
                        label="Add New Profile"
                        variant="title"
                        weight="bold"
                        color="primary"
                      />
                    </View>
                  </View>
                </ShadowedCard>
              )}
            </View>
          </View>
        </ScrollView>
      )}
    </>
  );
};

export default observer(PatientProfilesScreen);

const loadingPropsStyle = {
  color: colorPalette.black,
};

const nameStyle = {
  fontSize: 16,
};

const useWebStyles = makeStyles({
  mainContainer: {
    padding: 100,
  },
  spinnerContainer: {
    alignSelf: 'flex-end',
  },
  cardsContainer: {
    marginVertical: 48,
    marginLeft: -24,
    flexDirection: 'row',
    flexWrap: 'wrap',
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    alignContent: 'stretch',
  },
  cardContainer: {
    flexDirection: 'row',
    minWidth: 380,
    height: 280,
    flex: 1,
    flexBasis: 'auto',
    flexGrow: 0,
    flexShrink: 1,
    flexWrap: 'nowrap',
    marginBottom: 48,
  },
});

const useMobileStyles = makeStyles({
  mainContainer: {
    padding: 50,
  },
  cardsContainer: {
    marginLeft: -32,
  },
  spinnerContainer: {
    marginTop: 20,
    marginRight: -40,
    alignSelf: 'flex-end',
  },
});

const useSharedStyles = makeStyles({
  root: {
    height: '100%',
    flexDirection: 'row',
    width: '100%',
  },
  cardContainer: {
    // maxWidth: '100%',
    flexDirection: 'row',
  },
  leftContainer: {
    justifyContent: 'space-evenly',
  },
  iconContainer: {
    paddingRight: 12,
  },
  textContainer: {
    flex: 1,
    paddingHorizontal: 20,
    justifyContent: 'space-evenly',
  },
  nameContainer: {
    paddingBottom: 12,
    flexShrink: 1,
  },
  infoContainer: {
    flexDirection: 'row',
    width: '100%',
  },
  editContainer: {
    flexDirection: 'row',
    marginTop: 28,
  },
  reqColumn1: {
    flex: 1,
  },
  msgColumn1: {
    flex: 1,
  },
  column2: {
    marginRight: 12,
  },
  overlayContainer: {
    borderRadius: 20,
  },
  imageStyle: {
    height: 152,
    width: 100,
    borderRadius: 20,
  },
  imagePlaceholderStyle: {
    backgroundColor: 'transparent',
  },
});
