import React, {memo, useState} from 'react';
import {View} from 'react-native';

import {makeStyles} from 'rne-netzon';
import {colorPalette} from '../../../core/config/color.config';

import {SVGIcon, RNEButton, RNEText} from '../../components';
import AccountDeletionConfirmation from './AccountDeletionConfirmation';

interface DownloadMedicalRecordsProps {
  onCancel: () => void;
}

function DownloadMedicalRecords(props: DownloadMedicalRecordsProps) {
  const rneStyles = useStyles();

  const [downloadMedRec, setDownloadMedRec] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [proceedDeletion, setProceedDeletion] = useState(false);

  return (
    <View style={rneStyles.root}>
      {!downloadMedRec && !proceedDeletion && !downloading && (
        <>
          <RNEText
            label="Would you like to download your medical records before deleting your profile?"
            variant="header"
            align="center"
            weight="600"
            color="accent"
          />

          <View style={rneStyles.actionContainer}>
            <View style={rneStyles.buttonContainer}>
              <RNEButton
                title="No"
                color="secondary"
                onPress={() => setProceedDeletion(true)}
                style={rneStyles.buttonStyle}
                variant="outlined"
              />
            </View>
            <RNEButton
              title="Yes"
              color="secondary"
              onPress={() => {
                setTimeout(() => {
                  setDownloadMedRec(true);
                }, 3000);
                setDownloading(true);
              }}
              style={rneStyles.buttonStyle}
            />
          </View>
        </>
      )}

      {downloading && !downloadMedRec && (
        <RNEText
          label="Downloading Medical Records and Personal Information"
          variant="header"
          align="center"
          weight="600"
          color="accent"
        />
      )}

      {downloadMedRec && !proceedDeletion && (
        <>
          <RNEText
            label="Medical Records Successfully Downloaded"
            variant="header"
            align="center"
            weight="600"
            color="accent"
          />

          <View style={rneStyles.iconContainer}>
            <SVGIcon name="check-white" size={80} />
          </View>

          <RNEText
            label="Continue deleting your profile?"
            variant="body"
            align="center"
          />

          <View style={rneStyles.actionContainer}>
            <View style={rneStyles.buttonContainer}>
              <RNEButton
                title="Cancel"
                color="secondary"
                onPress={props.onCancel}
                style={rneStyles.buttonStyle}
                variant="outlined"
              />
            </View>
            <RNEButton
              title="Delete"
              color="secondary"
              onPress={() => setProceedDeletion(true)}
              style={rneStyles.buttonStyle}
            />
          </View>
        </>
      )}

      {proceedDeletion && (
        <AccountDeletionConfirmation onCancel={props.onCancel} />
      )}
    </View>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    justifyContent: 'center',
    paddingHorizontal: 20,
  },
  iconContainer: {
    marginVertical: 40,
    alignSelf: 'center',
    backgroundColor: colorPalette.secondary,
    borderRadius: 50,
  },
  actionContainer: {
    marginTop: 40,
    justifyContent: 'center',
    flexDirection: 'row',
  },
  buttonContainer: {
    marginRight: 16,
  },
  buttonStyle: {
    width: 160,
  },
}));

export default memo(DownloadMedicalRecords);
