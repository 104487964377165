import React, {useState, useEffect} from 'react';
import {View, Platform, ScrollView, Dimensions} from 'react-native';
import {TouchableWithoutFeedback} from 'react-native-gesture-handler';

import {observer} from 'mobx-react';
import {useToast} from 'react-native-fast-toast';

import {makeStyles, Overlay} from 'rne-netzon';
import {colorPalette} from '../../../core/config/color.config';

import {useNavigation} from '@react-navigation/native';

import {
  IconName,
  RNEText,
  SVGIcon,
  RNEButton,
  RNSearchableDropdown,
  RNSearchableDropdownOption,
  RNEInput,
} from '../../components';
import useStores from '../../stores/useStores';

import {MedicalHistoryCard, MedicalHistoryModal} from './../../views';
import {MedicalHistoryEnum} from '../../views/medical-history/data/medicalHistory';
import {NavigationKey} from '../../navigations/config';

const medicalHistoryList: {
  label: string;
  icon: IconName;
  key: MedicalHistoryEnum;
  navigation: NavigationKey;
}[] = [
  {
    label: 'Medical Condition',
    icon: 'hc-icon-medical-condition',
    key: MedicalHistoryEnum.CONDITION,
    navigation: NavigationKey.CONDITION,
  },
  {
    label: 'Family Condition',
    icon: 'hc-icon-family-condition',
    key: MedicalHistoryEnum.FAMILY_MEMBER_HISTORY,
    navigation: NavigationKey.FAMILY_MEMBER_HISTORY,
  },
  {
    label: 'Maintenance',
    icon: 'hc-icon-maintenance',
    key: MedicalHistoryEnum.MEDICATION_STATEMENT,
    navigation: NavigationKey.MEDICATION_STATEMENT,
  },
  {
    label: 'Hospitalization',
    icon: 'hc-icon-hospitalization',
    key: MedicalHistoryEnum.PROCEDURE,
    navigation: NavigationKey.PROCEDURE,
  },
  {
    label: 'Personal and Social',
    icon: 'hc-icon-personal-and-social',
    key: MedicalHistoryEnum.PERSONAL_AND_SOCIAL,
    navigation: NavigationKey.PERSONAL_AND_SOCIAL,
  },
  {
    label: 'Allergies',
    icon: 'hc-icon-allergies',
    key: MedicalHistoryEnum.ALLERGY_INTOLERANCE,
    navigation: NavigationKey.ALLERGY_INTOLERANCE,
  },
];

const PatientLandingScreen = () => {
  const navigation = useNavigation();
  const sharedStyles = useSharedStyles();
  const webStyles = useWebStyles();
  const mobileStyles = useMobileStyles();
  const {patientProfileStore, specializationStore} = useStores();
  const toast = useToast();

  const activeProfile = patientProfileStore.activeProfileData;

  const [searchKey, setSearchKey] = useState('');
  const [searchByDoctor, setSearchByDoctor] = useState(false);

  const [dropdownValue, setDropdownValue] = useState<
    number | string | null | undefined
  >();
  const [dropdownOptions, setDropdownOptions] = useState<
    RNSearchableDropdownOption[]
  >([]);

  const [
    selectedMedicalHistory,
    setSelectedMedicalHistory,
  ] = useState<MedicalHistoryEnum>(MedicalHistoryEnum.ALLERGY_INTOLERANCE);

  const [showDialog, setShowDialog] = useState(false);

  const switchSearchBy = () => {
    setSearchByDoctor(!searchByDoctor);
  };

  const onDropdownChange = (val: string | number | null | undefined) => {
    if (dropdownValue !== val) {
      setDropdownValue(val);
      goSearchDoctor(searchKey, val);
    }
  };

  const toggleModal = (medicalHistoryKey: MedicalHistoryEnum) => {
    setShowDialog(true);
    setSelectedMedicalHistory(medicalHistoryKey);
  };

  const goSearchDoctor = (
    inputValue: string,
    dpValue: number | string | null | undefined,
  ) => {
    const inputVal = searchByDoctor ? inputValue : '';
    const dpVal = !searchByDoctor ? dpValue : '';

    navigation.navigate(NavigationKey.PATIENT_SEARCH_RESULT, {
      searchKey: inputVal,
      searchDropValue: dpVal,
      searchType: searchByDoctor ? 'name' : 'specialization',
    });

    setSearchKey('');
    setTimeout(() => {
      setDropdownValue(undefined);
    }, 10);
  };

  const onSuccessHistoryUpdate = () => {
    toast?.show('Successfully updated the medical data');
    setShowDialog(false);
  };

  useEffect(() => {
    setDropdownOptions(
      specializationStore.specializations.map(specialization => ({
        name: specialization.value,
        value: specialization.specializationId,
      })),
    );
  }, [specializationStore.specializations]);

  return (
    <>
      <Overlay
        isVisible={showDialog}
        overlayStyle={sharedStyles.overlayContainer}>
        <MedicalHistoryModal
          medicalHistoryType={selectedMedicalHistory}
          onCancel={() => setShowDialog(false)}
          onSuccess={() => onSuccessHistoryUpdate()}
        />
      </Overlay>
      {Platform.OS === 'web' ? (
        <ScrollView>
          <View style={webStyles.mainContainer}>
            <View>
              <RNEText
                label={`Hi, ${activeProfile?.firstName}!`}
                variant="header"
                weight="bold"
                color="accent"
              />
              <RNEText
                label="How do you feel today?"
                variant="body"
                color="black"
              />
            </View>
            <View style={webStyles.searchContainer}>
              <View style={webStyles.dropdownContainer}>
                {searchByDoctor ? (
                  <View style={webStyles.searchInput}>
                    <RNEInput
                      value={searchKey}
                      onChangeText={setSearchKey}
                      placeholder="Input Doctor's Name here"
                      variant="shadowed"
                      inputContainerStyle={inputContainerStyle}
                      height={48}
                      onSubmitEditing={() =>
                        goSearchDoctor(searchKey, dropdownValue)
                      }
                    />
                  </View>
                ) : (
                  <View style={webStyles.dropdown}>
                    <RNSearchableDropdown
                      value={dropdownValue}
                      placeholder="Input Doctor's Specialization here"
                      options={dropdownOptions}
                      onChange={onDropdownChange}
                    />
                  </View>
                )}

                <View style={webStyles.buttonContainer}>
                  <RNEButton
                    title="Find a Doctor"
                    color="secondary"
                    style={webStyles.buttonStyle}
                    onPress={() => goSearchDoctor(searchKey, dropdownValue)}
                  />
                </View>
              </View>

              <View style={webStyles.textButton}>
                <TouchableWithoutFeedback
                  onPress={switchSearchBy}
                  accessibilityRole="button">
                  <RNEText
                    label={
                      searchByDoctor
                        ? "Input Doctor's Specialization instead"
                        : 'Find a Doctor by Name Instead'
                    }
                    variant="button"
                    weight="bold"
                    color="secondary"
                  />
                </TouchableWithoutFeedback>
              </View>
            </View>
            <View style={[webStyles.cardsContainer]}>
              {medicalHistoryList.map((item, index) => (
                <View key={index} style={webStyles.cardContainer}>
                  <MedicalHistoryCard
                    width={328}
                    height={120}
                    label={item.label}
                    icon={<SVGIcon name={item.icon} size={80} />}
                    onPress={() => toggleModal(item.key)}
                  />
                </View>
              ))}
            </View>
          </View>
        </ScrollView>
      ) : (
        <ScrollView keyboardShouldPersistTaps="handled">
          <View style={mobileStyles.mainContainer}>
            <View style={mobileStyles.greetingContainer}>
              <RNEText
                label={`Hi, ${activeProfile?.firstName}!`}
                variant="header"
                weight="bold"
                color="accent"
              />
              <RNEText
                label="How do you feel today?"
                variant="body"
                color="black"
              />
            </View>
            <>
              {searchByDoctor ? (
                <View style={mobileStyles.searchInput}>
                  <RNEInput
                    value={searchKey}
                    onChangeText={setSearchKey}
                    placeholder="Input Doctor's Name here"
                    inputContainerStyle={inputContainerStyle}
                    onSubmitEditing={() =>
                      goSearchDoctor(searchKey, dropdownValue)
                    }
                    returnKeyType="search"
                  />
                </View>
              ) : (
                <View style={mobileStyles.dropdownContainer}>
                  <RNSearchableDropdown
                    placeholder="Input Doctor's Specialization here"
                    value={dropdownValue}
                    options={dropdownOptions}
                    title={'Select specialization'}
                    onChange={onDropdownChange}
                  />
                </View>
              )}

              <TouchableWithoutFeedback onPress={switchSearchBy}>
                <View
                  accessibilityRole="button"
                  style={mobileStyles.textButton}>
                  <RNEText
                    label={
                      searchByDoctor
                        ? "Input Doctor's Specialization instead"
                        : 'Find a Doctor by Name Instead'
                    }
                    variant="body"
                    weight="bold"
                    color="secondary"
                  />
                </View>
              </TouchableWithoutFeedback>
            </>

            <View style={mobileStyles.cardsContainer}>
              {medicalHistoryList.map((item, index) => (
                <MedicalHistoryCard
                  key={index}
                  height={100}
                  label={item.label}
                  icon={<SVGIcon name={item.icon} size={48} />}
                  onPress={() =>
                    navigation.navigate(
                      NavigationKey.PATIENT_MEDICAL_HISTORY_STACK,
                      {screen: item.navigation},
                    )
                  }
                />
              ))}
            </View>
          </View>
        </ScrollView>
      )}
    </>
  );
};

export default observer(PatientLandingScreen);

const inputContainerStyle = {
  height: 48,
  borderRadius: 30,
  shadowOffset: {
    width: -5,
    height: 10,
  },
  shadowOpacity: 0.5,
  shadowRadius: 20,
  shadowColor: colorPalette.grey,
  backgroundColor: colorPalette.white,
  zIndex: 50,
  elevation: 50,
};

const useWebStyles = makeStyles({
  mainContainer: {
    padding: 100,
  },
  searchContainer: {
    marginTop: 32,
    zIndex: 20,
  },
  dropdownContainer: {
    flexDirection: 'row',
    zIndex: 20,
  },
  dropdown: {
    height: 48,
    flex: 1,
    justifyContent: 'center',
  },
  searchInput: {
    height: 48,
    flex: 1,
    justifyContent: 'center',
  },
  buttonContainer: {
    marginLeft: 24,
  },
  buttonStyle: {
    width: 168,
  },
  textButton: {
    marginTop: 24,
    zIndex: 0,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  cardsContainer: {
    marginLeft: -20,
    marginTop: 12,
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  cardContainer: {
    marginRight: 48,
    marginBottom: 0,
    flexShrink: 1,
    flexGrow: 0,
    minWidth: 250,
    maxWidth: 332,
    width: '100%',
  },
  titleContainer: {
    flexShrink: 1,
  },
});

const useMobileStyles = makeStyles({
  mainContainer: {
    paddingVertical: 60,
    paddingHorizontal: 40,
  },
  greetingContainer: {},
  searchInput: {
    marginTop: 24,
    marginBottom: 16,
    height: 48,
  },
  dropdownContainer: {
    marginTop: 24,
    marginBottom: 16,
  },
  textButton: {
    alignItems: 'flex-end',
    height: 60,
    zIndex: 0,
    elevation: 0,
  },
  cardsContainer: {
    zIndex: 10,
    elevation: 10,
    marginTop: 0,
    marginBottom: 12,
    marginLeft: -20,
    marginRight: 20,
  },
  iconContainer: {
    paddingRight: 28,
  },
});

const useSharedStyles = makeStyles({
  root: {
    height: '100%',
    flexDirection: 'row',
    width: '100%',
  },
  iconContainer: {
    paddingRight: 28,
  },
  overlayContainer: {
    borderRadius: 20,
  },
});
