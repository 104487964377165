import React from 'react';
import {View, TouchableWithoutFeedback, Platform} from 'react-native';
import {makeStyles} from 'rne-netzon';
import {colorPalette} from '../../../core/config/color.config';

interface ShadowedCardProps {
  height?: number | string;
  width?: number | string;
  flexDirection?: string;
  backgroundColor?: string;
  onPress?: () => void;
  children: React.ReactNode;
}

export const ShadowedCard = (props: ShadowedCardProps) => {
  const baseStyles = useBaseStyles();
  const webStyles = useWebStyles();
  const mobileStyles = useMobileStyles();
  const styles = {
    width: props?.width ? props.width : '100%',
    height: props?.height ? props.height : '100%',
    backgroundColor: props?.backgroundColor
      ? props.backgroundColor
      : colorPalette.white,
  };

  return (
    <TouchableWithoutFeedback onPress={props.onPress}>
      {Platform.OS === 'web' ? (
        <View
          accessibilityRole="button"
          style={[webStyles.boxContainer, baseStyles.boxContainer, styles]}>
          {props.children}
        </View>
      ) : (
        <View
          accessibilityRole="button"
          style={[mobileStyles.boxContainer, baseStyles.boxContainer, styles]}>
          {props.children}
        </View>
      )}
    </TouchableWithoutFeedback>
  );
};

const useBaseStyles = makeStyles({
  boxContainer: {
    flex: 1,
    margin: 20,
    padding: 32,
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 20,
    shadowOffset: {
      width: -5,
      height: 10,
    },
    shadowOpacity: 1,
    shadowRadius: 10,
  },
});

const useWebStyles = makeStyles({
  boxContainer: {
    shadowColor: '#070F580D',
  },
});

const useMobileStyles = makeStyles({
  boxContainer: {
    shadowColor: colorPalette.grey,
    elevation: 16,
    backgroundColor: colorPalette.white,
  },
});

export default ShadowedCard;
