import React from 'react';
import {Platform, Animated, View} from 'react-native';

import {RNEText, SVGIcon} from '../../components';
import {logoStyle, mobileStyles} from './styles';

const OnboardingSlideScreen2 = () => {
  const label = 'Trusted by Doctors';

  return (
    <Animated.View style={mobileStyles.sliderContainer}>
      <View style={mobileStyles.sliderContent}>
        <View style={logoStyle}>
          <SVGIcon name="trusted-by-doctors" size={210} />
        </View>
        <View>
          <RNEText
            label={Platform.OS === 'web' ? label.toUpperCase() : label}
            color="secondary"
            variant="header"
            weight="bold"
            align="center"
          />
        </View>
        <View style={mobileStyles.description}>
          <RNEText
            label="We are supported by a vast network of medical practitioners aiming to deliver easy and accessible health care to everyone in need."
            color={Platform.OS === 'web' ? 'black' : 'grey'}
            variant="body"
            align="center"
          />
        </View>
      </View>
    </Animated.View>
  );
};

export default OnboardingSlideScreen2;
