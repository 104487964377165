import React, {useEffect, useState} from 'react';
import {View} from 'react-native';
import {makeStyles} from 'rne-netzon';
import {colorPalette} from '../../../core/config/color.config';
import {RNEButton, RNEText} from '../../components';

import {useGetInvoiceViaTempToken} from '../../server/react-query/usePayment';
import {useRoute} from '@react-navigation/native';
import usePayment from '../../hooks/payment/usePayment';
import {PaymentMethodSelectionModal} from '../../views';

const ConsultationPaymentEntryScreen = () => {
  const styles = useStyles();
  const route = useRoute();

  const [token, setToken] = useState('');
  const [hasSelectedPayMethod, setHasSelectedPayMethod] = useState(false);

  const {
    paymentMethod,
    prepareIPay88Values,
    postToIpay88,
    setSelectedPaymentMethod,
  } = usePayment();

  const {data: invoiceFromTempToken} = useGetInvoiceViaTempToken(token);

  const getSelectedPaymentMethod = (code: string) => {
    setSelectedPaymentMethod(code);
  };

  const proceedToIPay88 = () => {
    setHasSelectedPayMethod(true);
    postToIpay88();
  };

  useEffect(() => {
    const params: any = route.params;

    if (params && params.token) {
      setToken(params.token);
    }
  }, [route.params]);

  useEffect(() => {
    if (invoiceFromTempToken && invoiceFromTempToken.length > 0) {
      prepareIPay88Values(invoiceFromTempToken?.[0]!);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceFromTempToken, paymentMethod]);

  return (
    <View style={styles.mainContainer}>
      {hasSelectedPayMethod ? (
        <>
          <View style={styles.titleContainer}>
            <RNEText
              label="Processing"
              color="black"
              weight="600"
              variant="title"
            />
          </View>
          <View style={styles.primarySpinnerContainer}>
            <RNEButton
              height={160}
              loadingProps={primaryLoadingPropsStyle}
              loading={true}
            />
          </View>
          <View style={styles.subtitleContainer}>
            <RNEText
              label="You will be redirected to a secure website. It might take a few seconds."
              color="black"
              variant="body"
            />
          </View>
          <View style={styles.subtitleContainer}>
            <RNEText
              label="Please do not refresh the page or click the Back or Close button of your browser."
              color="black"
              variant="body"
            />
          </View>
        </>
      ) : (
        <>
          {invoiceFromTempToken && invoiceFromTempToken.length > 0 ? (
            <PaymentMethodSelectionModal
              onConfirm={proceedToIPay88}
              onSelectPaymentMethod={getSelectedPaymentMethod}
            />
          ) : (
            <View style={styles.secondarySpinnerContainer}>
              <RNEText
                label="Please wait..."
                color="black"
                weight="600"
                variant="title"
              />
              <RNEButton
                height={160}
                loadingProps={secondaryLoadingPropsStyle}
                loading={true}
              />
            </View>
          )}
        </>
      )}
    </View>
  );
};

export default ConsultationPaymentEntryScreen;

const primaryLoadingPropsStyle = {
  color: colorPalette.primary,
  size: 100,
};

const secondaryLoadingPropsStyle = {
  color: colorPalette.secondary,
  size: 100,
};

const useStyles = makeStyles({
  mainContainer: {
    padding: 100,
    alignItems: 'center',
  },
  titleContainer: {
    paddingVertical: 40,
  },
  primarySpinnerContainer: {
    alignSelf: 'center',
  },
  secondarySpinnerContainer: {
    alignSelf: 'center',
    top: '60%',
  },
  subtitleContainer: {
    paddingVertical: 20,
  },
  dropdownContainer: {
    paddingTop: 20,
    height: 48,
    flex: 1,
    justifyContent: 'center',
  },
  buttonContainer: {
    paddingTop: 300,
  },
});
