import {makeAutoObservable, observable} from 'mobx';

import {persistStore} from '../utils/mobx.utils';

export class EmailStore {
  email: string = '';

  constructor() {
    makeAutoObservable(this, {
      email: observable,
    });

    persistStore(this, ['email'], 'EmailStore');
  }

  setEmail(email: string) {
    this.email = email;
  }

  removeEmail() {
    this.email = '';
  }
}
