import React, {memo, useEffect, useState} from 'react';
import {Platform, View} from 'react-native';

import {makeStyles} from 'rne-netzon';
import {format} from 'date-fns';

import {RNEText, RNEButton, Pagination, usePagination} from '../../components';
import {colorPalette} from '../../../core/config/color.config';

import {PractitionerStatus} from '../../server/types/practitioner-status.types';
import {useGetPractitionerProfiles} from './../../server/react-query/usePractitioner';
import {PractionerTableSortField} from '../../server/types/table.types';

import ContentScrollView from '../scroll/ContentScrollView';
import TableHeaderRow from '../tables/TableHeaderRow';
import TableHeaderCell from '../tables/TableHeaderCell';
import TableContentCell from '../tables/TableContentCell';
import TableBodyRow from '../tables/TableBodyRow';
import TableBodyCell from '../tables/TableBodyCell';

interface AdminDoctorDeclinedTabProps {
  searchKey?: string;
}

const AdminDoctorDeclinedTab = (props: AdminDoctorDeclinedTabProps) => {
  const styles = useStyles();
  const {
    pageNumber,
    pageSize,
    totalPage,
    setPageData,
    goBack,
    goNext,
    goToPage,
    canGoBack,
    canGoNext,
  } = usePagination();

  const [sortBy, setSortBy] = useState<PractionerTableSortField>(
    PractionerTableSortField.CREATED_ON,
  );
  const [ascending, setAscending] = useState<boolean>(true);

  const {data, isLoading, isFetching, refetch} = useGetPractitionerProfiles(
    {
      searchKey: props.searchKey,
      status: PractitionerStatus.DECLINED,
      pageNumber: pageNumber,
      sortBy: sortBy,
      ascending: ascending,
    },
    {},
  );

  const onColumnClick = (column: PractionerTableSortField) => {
    if (!isFetching) {
      if (sortBy !== column) {
        setSortBy(column);
        setAscending(true);
      } else {
        setAscending(!ascending);
      }
    }
  };

  useEffect(() => {
    setPageData({
      pageNumber: data?.pageNumber,
      pageSize: data?.pageSize,
      totalPage: data?.totalPages,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber]);

  useEffect(() => {
    if (pageNumber !== 1) {
      goToPage(1);
    } else {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.searchKey]);

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy, ascending]);

  return (
    <View style={styles.root}>
      <View style={styles.scrollView}>
        <ContentScrollView isWeb={Platform.OS === 'web'}>
          <TableHeaderRow>
            <TableHeaderCell>
              <TableContentCell
                label="Doctor Name"
                ascending={ascending}
                active={sortBy === PractionerTableSortField.LASTNAME}
                onPress={() => onColumnClick(PractionerTableSortField.LASTNAME)}
              />
            </TableHeaderCell>
            <TableHeaderCell>
              <TableContentCell
                label="Specialization"
                ascending={ascending}
                active={sortBy === PractionerTableSortField.PROFESSION}
                onPress={() =>
                  onColumnClick(PractionerTableSortField.PROFESSION)
                }
              />
            </TableHeaderCell>
            <TableHeaderCell>
              <TableContentCell
                label="License No."
                ascending={ascending}
                active={sortBy === PractionerTableSortField.QUALIFICATION}
                onPress={() =>
                  onColumnClick(PractionerTableSortField.QUALIFICATION)
                }
              />
            </TableHeaderCell>
            <TableHeaderCell>
              <TableContentCell
                label="Birthdate"
                ascending={ascending}
                active={sortBy === PractionerTableSortField.BIRTHDATE}
                onPress={() =>
                  onColumnClick(PractionerTableSortField.BIRTHDATE)
                }
              />
            </TableHeaderCell>
            <TableHeaderCell>
              <TableContentCell
                label="Date Declined"
                ascending={ascending}
                active={sortBy === PractionerTableSortField.DECLINED_ON}
                onPress={() =>
                  onColumnClick(PractionerTableSortField.DECLINED_ON)
                }
              />
            </TableHeaderCell>
          </TableHeaderRow>

          {data &&
            data.data.map(profile => (
              <TableBodyRow key={profile.practitionerId}>
                <TableBodyCell>
                  <RNEText
                    label={`${profile.humanName.family}, ${profile.humanName.given[0]}`}
                    variant="button"
                    numberOfLines={2}
                  />
                </TableBodyCell>
                <TableBodyCell>
                  <RNEText
                    label={profile.practitionerRole?.[0]?.specialty.text}
                    variant="button"
                  />
                </TableBodyCell>
                <TableBodyCell>
                  <RNEText
                    label={profile.qualification.identifier}
                    variant="button"
                  />
                </TableBodyCell>
                <TableBodyCell>
                  <RNEText
                    label={
                      profile.birthdate
                        ? format(new Date(profile.birthdate), 'MM/dd/yyyy')
                        : '--'
                    }
                    variant="button"
                  />
                </TableBodyCell>
                <TableBodyCell>
                  <RNEText
                    label={format(new Date(profile.declinedOn), 'MM/dd/yyyy')}
                    variant="button"
                  />
                </TableBodyCell>
              </TableBodyRow>
            ))}

          {isFetching && data && data.data.length > 0 && (
            <View style={styles.fetchingContainer}>
              <RNEButton
                height={24}
                loading={isFetching}
                type="clear"
                loadingProps={loadingStyle}
                buttonStyle={buttonStyle}
              />
            </View>
          )}

          {!isLoading && data && data.data.length === 0 && (
            <View style={styles.placeholderContainer}>
              <RNEText label="No declined account(s)" color="grey" />
            </View>
          )}

          {isLoading && (
            <RNEButton
              height={24}
              loading={isLoading}
              type="clear"
              loadingProps={loadingStyle}
              buttonStyle={buttonStyle}
            />
          )}

          {isFetching && data && data.data.length === 0 && (
            <View style={styles.fetchingContainer}>
              <RNEButton
                height={24}
                loading={isFetching}
                type="clear"
                loadingProps={loadingStyle}
                buttonStyle={buttonStyle}
              />
            </View>
          )}
        </ContentScrollView>
      </View>

      {data && data.data.length > 0 && (
        <View style={styles.pagination}>
          <Pagination
            pageNumber={pageNumber}
            totalPage={totalPage}
            goToPage={goToPage}
            goBack={goBack}
            goNext={goNext}
            canGoBack={canGoBack}
            canGoNext={canGoNext}
            freeze={isFetching}
          />
        </View>
      )}
    </View>
  );
};

const buttonStyle = {
  paddingHorizontal: 0,
  marginTop: 32,
};

const loadingStyle = {
  color: colorPalette.primary,
};

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
  },
  buttonStyle: {
    width: 100,
    marginLeft: 16,
  },
  placeholderContainer: {
    marginTop: 32,
    alignItems: 'center',
  },
  scrollView: {
    flex: 1,
    height: '100%',
    maxHeight: Platform.OS === 'web' ? '60vh' : 'auto',
    position: 'relative',
  },
  pagination: {
    marginTop: 16,
    alignItems: 'flex-end',
  },
  fetchingContainer: {
    position: 'absolute',
    alignItems: 'center',
    top: 120,
    left: 0,
    right: 0,
  },
}));

export default memo(AdminDoctorDeclinedTab);
