import {colorPalette} from '../../../../core/config/color.config';

export enum PopoverItemKey {
  DOCTOR_PROFILE,
  MEDICAL_HISTORY,
  MEDICAL_ATTACHMENTS,
  END_CONSULTATION,
  LABORATORY_REQUEST,
  MEDICAL_CERTIFICATE,
  PRESCRIPTION,
  OTHER,
}

export interface IPopoverItem {
  label: string;
  itemKey: PopoverItemKey;
}

export const patientOngoingConsultationPopoverItems: IPopoverItem[] = [
  {
    label: "Doctor's Profile",
    itemKey: PopoverItemKey.DOCTOR_PROFILE,
  },
  {
    label: 'Medical Attachments',
    itemKey: PopoverItemKey.MEDICAL_ATTACHMENTS,
  },
];

export const doctorOngoingConsultationPopoverItems: IPopoverItem[] = [
  {
    label: 'Medical History',
    itemKey: PopoverItemKey.MEDICAL_HISTORY,
  },
  {
    label: 'Medical Attachments',
    itemKey: PopoverItemKey.MEDICAL_ATTACHMENTS,
  },
  {
    label: 'End Consultation',
    itemKey: PopoverItemKey.END_CONSULTATION,
  },
];

export const patientCompletedConsultationPopoverItems: IPopoverItem[] = [
  {
    label: "Doctor's Profile",
    itemKey: PopoverItemKey.DOCTOR_PROFILE,
  },
  {
    label: 'Medical Attachments',
    itemKey: PopoverItemKey.MEDICAL_ATTACHMENTS,
  },
];

export const doctorCompletedConsultationPopoverItems: IPopoverItem[] = [
  {
    label: 'Medical History',
    itemKey: PopoverItemKey.MEDICAL_HISTORY,
  },
  {
    label: 'Medical Attachments',
    itemKey: PopoverItemKey.MEDICAL_ATTACHMENTS,
  },
];

export const medicalAttachmentPopoverItems: IPopoverItem[] = [
  {
    label: 'Laboratory Request',
    itemKey: PopoverItemKey.LABORATORY_REQUEST,
  },
  {
    label: 'Medical Certificate',
    itemKey: PopoverItemKey.MEDICAL_CERTIFICATE,
  },
  {
    label: 'Prescription',
    itemKey: PopoverItemKey.PRESCRIPTION,
  },
  // {
  //   label: 'Other',
  //   itemKey: PopoverItemKey.OTHER,
  // },
];

export const popoverOptionsStyles = {
  optionsContainer: {
    marginTop: 32,
    backgroundColor: colorPalette.white,
    paddingHorizontal: 24,
    paddingVertical: 16,
    borderRadius: 16,
  },
  optionsWrapper: {},
  optionWrapper: {},
  optionTouchable: {
    activeOpacity: 70,
  },
};

export const popoverOptionStyles = {
  optionTouchable: {
    underlayColor: 'transparent',
    activeOpacity: 0.6,
  },
  optionWrapper: {
    paddingVertical: 8,
  },
};

export const popoverDividerStyle = {
  color: colorPalette.light,
  marginVertical: 8,
};
