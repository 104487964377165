import React from 'react';

export interface ViewImageAttachmentView {
  subject: string;
  mediaId: string;
  onClose: () => void;
}

const ConsultationImageAttachmentViewScreen = (
  props: ViewImageAttachmentView,
) => {
  return <></>;
};

export default ConsultationImageAttachmentViewScreen;
