import React, {memo} from 'react';
import {View} from 'react-native';
import {useQueryClient} from 'react-query';

import {makeStyles} from 'rne-netzon';
import {colorPalette} from '../../../core/config/color.config';

import {RNEText, RNEButton} from '../../components';
import {
  PractitionerStatus,
  VerifyPractitionerActionType,
} from '../../server/types/practitioner-status.types';

import {useVerifyPractitionerProfile} from './../../server/react-query/usePractitioner';

interface PractitionerProfileDeclineModalProps {
  label?: string;
  onClose?: () => void;
  practitionerId: string;
}

function PractitionerProfileDeclineModal(
  props: PractitionerProfileDeclineModalProps,
) {
  const rneStyles = useStyles();

  const queryClient = useQueryClient();
  const mutation = useVerifyPractitionerProfile();

  const verifyPractitionerProfile = async () => {
    try {
      await mutation.mutateAsync({
        practitionerId: props.practitionerId,
        actionType: VerifyPractitionerActionType.DECLINE,
      });

      queryClient.invalidateQueries([
        'practitioner-profiles',
        {status: PractitionerStatus.PENDING},
      ]);
      queryClient.invalidateQueries([
        'practitioner-profiles',
        {status: PractitionerStatus.DECLINED},
      ]);
      queryClient.refetchQueries([
        'practitioner-profiles',
        {status: PractitionerStatus.PENDING},
      ]);
      queryClient.refetchQueries([
        'practitioner-profiles',
        {status: PractitionerStatus.DECLINED},
      ]);

      props.onClose?.();
    } catch (error) {
      console.log('error', error);
    }
  };

  return (
    <View style={rneStyles.root}>
      <RNEText
        label="Are you sure?"
        variant="subheader"
        align="center"
        weight="600"
        color="accent"
      />

      <View>
        <RNEText
          label={`You're about to deny the account of ${props.label}.`}
          variant="body"
          align="center"
        />
      </View>

      <View style={rneStyles.actionContainer}>
        {!mutation.isLoading ? (
          <>
            <RNEButton
              title="Cancel"
              color="accent"
              variant="outlined"
              onPress={props.onClose}
              style={rneStyles.buttonStyle}
            />
            <RNEButton
              title="Deny Account"
              color="accent"
              onPress={verifyPractitionerProfile}
              style={[rneStyles.buttonStyle, rneStyles.buttonNext]}
            />
          </>
        ) : (
          <RNEButton
            height={24}
            loading={true}
            type="clear"
            loadingProps={loadingStyle}
            buttonStyle={buttonStyle}
          />
        )}
      </View>
    </View>
  );
}

const buttonStyle = {
  paddingHorizontal: 0,
  marginTop: 0,
};

const loadingStyle = {
  color: colorPalette.primary,
};

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 460,
    paddingHorizontal: 40,
    paddingVertical: 32,
  },
  actionContainer: {
    marginTop: 36,
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  buttonStyle: {
    width: 150,
  },
  buttonNext: {
    marginLeft: 16,
  },
}));

export default memo(PractitionerProfileDeclineModal);
