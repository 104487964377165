import React, {useState} from 'react';
import {StyleSheet, View, Platform} from 'react-native';

import {observer} from 'mobx-react';

import {RNEText, RNEButton} from '../../components';

import {colorPalette} from '../../../core/config/color.config';

import {useCancelConsultationDetail} from '../../server/react-query/useConsultation';
import {ConsultationStatusEnum} from '../../server/types/consultation.types';
import {Overlay} from 'rne-netzon/dist/overlay/Overlay';
import ConsultationCancelConfirmationModal from '../modal/ConsultationCancelConfirmationModal';
import {useNavigation} from '@react-navigation/native';
import {NavigationKey} from '../../navigations/config';
import {useToast} from 'react-native-fast-toast';

interface ConsultationCancelProps {
  serviceRequestId: string;
}

const ConsultationCancel = (props: ConsultationCancelProps) => {
  const navigation = useNavigation();
  const toast = useToast();

  const [showDialog, setShowDialog] = useState(false);

  const cancelMutation = useCancelConsultationDetail({
    serviceRequestId: props.serviceRequestId,
    status: ConsultationStatusEnum.CANCELLED,
  });

  const cancelConsultation = async () => {
    try {
      setShowDialog(false);
      await cancelMutation.mutateAsync();

      if (Platform.OS === 'web') {
        navigation.navigate(NavigationKey.PATIENT_CONSULTATION_STACK, {
          screen: NavigationKey.CONSULTATION_LIST,
          params: {
            tab: 2,
            id: props.serviceRequestId,
          },
        });
      }
    } catch (error) {
      console.log('error', error);
      toast?.show(error?.data?.error, {type: 'danger'});
    }
  };

  return (
    <>
      <Overlay
        isVisible={showDialog}
        overlayStyle={sharedStyles.overlayContainer}>
        <ConsultationCancelConfirmationModal
          onConfirm={cancelConsultation}
          onCancel={() => setShowDialog(false)}
        />
      </Overlay>
      {Platform.OS === 'web' ? (
        <View style={webStyles.mainContainer}>
          <View style={webStyles.messageContainer}>
            <RNEText
              label="Please wait. Your Doctor will respond to you as soon as possible."
              weight="bold"
              color="accent"
              variant="button"
              numberOfLines={3}
              ellipsizeMode="tail"
            />
            <RNEText
              label="Your name is hidden from view until the doctor accepts your consultation."
              color="blackLight"
              variant="button"
              numberOfLines={3}
              ellipsizeMode="tail"
            />
          </View>

          <View>
            <RNEButton
              title="Cancel Request"
              color="primary"
              type="outline"
              variant="outlined"
              style={webStyles.buttonStyle}
              onPress={() => setShowDialog(true)}
              loading={cancelMutation.isLoading}
            />
          </View>
        </View>
      ) : (
        <View style={mobileStyles.mainContainer}>
          <View style={mobileStyles.messageContainer}>
            <RNEText
              label="Still need the consultation?"
              weight="bold"
              color="accent"
              numberOfLines={3}
              ellipsizeMode="tail"
              align="center"
            />
            <RNEText
              label="You can choose to cancel this request."
              color="grey"
              numberOfLines={3}
              ellipsizeMode="tail"
              align="center"
            />
          </View>

          <View>
            <RNEButton
              title="Cancel Request"
              color="primary"
              type="outline"
              variant="outlined"
              style={mobileStyles.buttonStyle}
              onPress={() => setShowDialog(true)}
              loading={cancelMutation.isLoading}
            />
          </View>
        </View>
      )}
    </>
  );
};

export default observer(ConsultationCancel);

const webStyles = StyleSheet.create({
  mainContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 16,
    borderTopWidth: 1,
    borderTopColor: colorPalette.light,
  },
  messageContainer: {
    flex: 1,
    justifyContent: 'center',
    marginRight: 16,
  },
  buttonStyle: {
    width: 180,
  },
});

const mobileStyles = StyleSheet.create({
  mainContainer: {
    alignItems: 'center',
    paddingVertical: 32,
    borderTopWidth: 1,
    borderTopColor: colorPalette.light,
  },
  messageContainer: {
    alignItems: 'center',
    marginBottom: 24,
  },
  buttonStyle: {
    width: 180,
  },
});

const sharedStyles = StyleSheet.create({
  overlayContainer: {
    borderRadius: 20,
    marginHorizontal: 40,
  },
});
