import React from 'react';
import {Platform} from 'react-native';

import {createStackNavigator} from '@react-navigation/stack';

import {NavigationKey} from '../config/navigation.config';

import {DoctorLandingEntryScreen} from '../../screens';

const {Navigator, Screen} = createStackNavigator();

const DoctorLandingStackWebNavigator = () => {
  return (
    <Navigator>
      <Screen
        name={NavigationKey.DOCTOR_SCREEN_LANDING}
        component={DoctorLandingEntryScreen}
        options={{
          headerShown: false,
        }}
      />
    </Navigator>
  );
};

const DoctorLandingStackMobileNavigator = () => {
  return (
    <Navigator>
      <Screen
        name={NavigationKey.DOCTOR_SCREEN_LANDING}
        component={DoctorLandingEntryScreen}
        options={{
          headerShown: false,
        }}
      />
    </Navigator>
  );
};

export default Platform.OS === 'web'
  ? DoctorLandingStackWebNavigator
  : DoctorLandingStackMobileNavigator;
