import React, {useCallback, useEffect} from 'react';
import {Platform, View} from 'react-native';
import {observer} from 'mobx-react';

import useStores from '../../stores/useStores';

import {useGetProfessionalProfile} from '../../server/react-query/usePractitioner';
import {PractitionerStatus} from '../../server/types/practitioner-status.types';

import DoctorLandingScreen from './DoctorLandingScreen';
import DoctorVerificationLandingScreen from './DoctorVerificationLandingScreen';
import DoctorDefaultLandingScreen from './DoctorDefaultLandingScreen';

import {makeStyles} from 'rne-netzon';
import {RNEButton} from '../../components';
import {colorPalette} from '../../../core/config/color.config';
import {useFocusEffect} from '@react-navigation/native';

const DoctorLandingEntryScreen = () => {
  const {doctorProfileStore} = useStores();
  const {data, refetch} = useGetProfessionalProfile();

  const styles = useStyles();

  useEffect(() => {
    if (data) {
      doctorProfileStore.setDoctorProfile(data);
    }
  }, [data, doctorProfileStore]);

  useFocusEffect(
    useCallback(() => {
      if (Platform.OS !== 'web') {
        refetch();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []),
  );

  return (
    <>
      {doctorProfileStore.doctorStatus === PractitionerStatus.VERIFIED && (
        <DoctorLandingScreen />
      )}

      {doctorProfileStore.doctorStatus === PractitionerStatus.PENDING && (
        <DoctorVerificationLandingScreen />
      )}

      {(doctorProfileStore.doctorStatus === PractitionerStatus.UNVERIFIED ||
        doctorProfileStore.doctorStatus === PractitionerStatus.DECLINED) && (
        <DoctorDefaultLandingScreen />
      )}

      {(doctorProfileStore.doctorStatus === undefined ||
        doctorProfileStore.doctorStatus === null) && (
        <View style={styles.spinnerContainer}>
          <RNEButton
            height={32}
            loadingProps={loadingPropsStyle}
            loading={true}
          />
        </View>
      )}
    </>
  );
};

const loadingPropsStyle = {
  color: colorPalette.black,
};

const useStyles = makeStyles({
  spinnerContainer: {
    width: '100%',
    marginTop: 32,
    justifyContent: 'flex-end',
  },
});

export default observer(DoctorLandingEntryScreen);
