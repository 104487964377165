import React, {useState, useEffect} from 'react';
import {
  Platform,
  StyleSheet,
  View,
  Image,
  ScrollView,
  TouchableWithoutFeedback,
} from 'react-native';

import {SafeAreaView} from 'react-native-safe-area-context';
import {CheckBox, Icon, useTheme} from 'rne-netzon';
import {useToast} from 'react-native-fast-toast';

import {useForm, Controller} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';

import {useNavigation} from '@react-navigation/native';
import {NavigationKey} from '../../navigations/config';

import useStores from '../../stores/useStores';

import {useSignup} from './../../server/react-query/useRegistration';
import {SignupReqBody} from '../../server/types/registration.types';
import {UserRole} from '../../server/types/user.types';

import {colorPalette} from '../../../core/config/color.config';
import {LogoLight} from './../../images/Image';

import {
  SVGIcon,
  RNEButton,
  RNEIconButton,
  RNEText,
  RNEInput,
} from '../../components';

const schema: yup.SchemaOf<Partial<SignupReqBody>> = yup.object({
  firstName: yup.string().trim().required('This field is required.'),
  middleName: yup.string().trim().required('This field is required.'),
  lastName: yup.string().trim().required('This field is required.'),
  email: yup
    .string()
    .required('This field is required.')
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      'Enter a valid email address.',
    ),
  password: yup
    .string()
    .trim()
    .required('This field is required.')
    .min(6, 'Min of 6 characters.'),
  userRole: yup.mixed<UserRole>(),
});

const SignupScreen = () => {
  const {theme} = useTheme();
  const {authStore} = useStores();
  const navigation = useNavigation();
  const {
    control,
    handleSubmit,
    getValues,
    reset,
    setValue,
    formState: {errors, isValid},
  } = useForm<SignupReqBody>({
    mode: 'all',
    defaultValues: {
      email: '',
      firstName: '',
      lastName: '',
      middleName: '',
      password: '',
      userRole: 'patient',
    },
    resolver: yupResolver(schema),
  });

  const toast = useToast();

  const [asDoctor, setAsDoctor] = useState(false);

  const mutation = useSignup(getValues());

  const goToLogin = () => {
    reset();
    navigation.navigate(NavigationKey.LOGIN_SCREEN);
  };

  const goToVerification = () => {
    handleSubmit(onSubmit)();
  };

  const onCheckboxClicked = () => {
    setAsDoctor(!asDoctor);
  };

  const onSubmit = async () => {
    if (isValid) {
      try {
        const data = await mutation.mutateAsync();
        authStore.setAuth(data);

        if (data) {
          reset();
          mutation.reset();
          navigation.navigate(NavigationKey.SIGNUP_VERIFY_SCREEN);
        }
      } catch (error) {
        console.log('error', error);
        if (error?.data?.error) {
          toast?.show(error?.data?.error, {type: 'danger'});
        }
      }
    }
  };

  useEffect(() => {
    if (asDoctor) {
      setValue('userRole', 'practitioner');
    } else {
      setValue('userRole', 'patient');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asDoctor]);

  return (
    <SafeAreaView style={sharedStyles.root}>
      {Platform.OS === 'web' ? (
        <View style={webStyles.loginContainer}>
          <View style={webStyles.loginWrapper}>
            <TouchableWithoutFeedback
              onPress={goToLogin}
              accessibilityRole="button">
              <Image style={webStyles.logo} source={LogoLight} />
            </TouchableWithoutFeedback>

            <View style={webStyles.contentWrapper}>
              <RNEText
                label="Let's set up your profile!"
                variant="header"
                weight="bold"
                color="accent"
                align="center"
              />

              <View style={webStyles.inputGroupContainer}>
                <View style={webStyles.nameInputGroupContainer}>
                  <View style={webStyles.inputFirstNameContainer}>
                    <Controller
                      control={control}
                      render={({field: {onChange, onBlur, value}}) => (
                        <RNEInput
                          placeholder="First Name"
                          value={value}
                          onChangeText={text => {
                            onChange(text);
                          }}
                          errorMessage={errors.firstName?.message}
                        />
                      )}
                      name="firstName"
                    />
                  </View>

                  <View style={webStyles.inputNameContainer}>
                    <Controller
                      control={control}
                      render={({field: {onChange, onBlur, value}}) => (
                        <RNEInput
                          placeholder="Middle Name"
                          value={value}
                          onChangeText={text => onChange(text)}
                          errorMessage={errors.middleName?.message}
                        />
                      )}
                      name="middleName"
                    />
                  </View>

                  <View style={webStyles.inputNameContainer}>
                    <Controller
                      control={control}
                      render={({field: {onChange, onBlur, value}}) => (
                        <RNEInput
                          placeholder="Last Name"
                          value={value}
                          onChangeText={text => onChange(text)}
                          errorMessage={errors.lastName?.message}
                        />
                      )}
                      name="lastName"
                    />
                  </View>
                </View>

                <View style={webStyles.inputContainer}>
                  <Controller
                    control={control}
                    render={({field: {onChange, onBlur, value}}) => (
                      <RNEInput
                        placeholder="Email address"
                        leftIcon={<SVGIcon name="email" />}
                        value={value}
                        onChangeText={text => onChange(text)}
                        errorMessage={errors.email?.message}
                      />
                    )}
                    name="email"
                  />
                </View>

                <View style={webStyles.inputContainer}>
                  <Controller
                    control={control}
                    render={({field: {onChange, onBlur, value}}) => (
                      <RNEInput
                        placeholder="Password"
                        secureTextEntry
                        leftIcon={<SVGIcon name="lock" />}
                        value={value}
                        onChangeText={text => onChange(text)}
                        onSubmitEditing={goToVerification}
                        errorMessage={errors.password?.message}
                      />
                    )}
                    name="password"
                  />
                </View>

                <View style={mobileStyles.checkBoxContainer}>
                  <CheckBox
                    title="Sign up as a Doctor"
                    checkedIcon={
                      <Icon
                        type="material-community"
                        name="checkbox-marked-circle"
                        color={colorPalette.primary}
                      />
                    }
                    uncheckedIcon={
                      <Icon
                        type="material-community"
                        name="checkbox-blank-circle-outline"
                        color={colorPalette.primary}
                      />
                    }
                    checked={asDoctor}
                    onPress={onCheckboxClicked}
                  />
                </View>
              </View>

              <View style={webStyles.loginActionContainer}>
                <RNEButton
                  title="Sign Up"
                  onPress={goToVerification}
                  color="secondary"
                  disabled={!isValid}
                  loading={mutation.isLoading}
                />
              </View>

              <View style={webStyles.footerActions}>
                <View style={mobileStyles.signupContainer}>
                  <RNEText label="Already have an account?" variant="button" />

                  <View style={mobileStyles.signupTextContainer}>
                    <RNEText
                      label="Login here."
                      color="primary"
                      variant="button"
                      weight="bold"
                      onPress={goToLogin}
                    />
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
      ) : (
        <ScrollView>
          <View style={mobileStyles.loginContainer}>
            <View style={mobileStyles.content}>
              <View style={mobileStyles.header}>
                <View
                  style={{
                    ...mobileStyles.headerMain,
                    backgroundColor: theme.colors?.primary,
                  }}
                />

                <View style={mobileStyles.headerTextContainer}>
                  <RNEText
                    label="Let's set up your profile!"
                    color="accent"
                    variant="header"
                    weight="bold"
                  />
                </View>
              </View>

              <View style={mobileStyles.inputGroupContainer}>
                <View>
                  <Controller
                    control={control}
                    render={({field: {onChange, onBlur, value}}) => (
                      <RNEInput
                        placeholder="First Name"
                        value={value}
                        onChangeText={text => onChange(text)}
                        errorMessage={errors.firstName?.message}
                      />
                    )}
                    name="firstName"
                  />

                  <View style={mobileStyles.inputContainer}>
                    <Controller
                      control={control}
                      render={({field: {onChange, onBlur, value}}) => (
                        <RNEInput
                          placeholder="Middle Name"
                          value={value}
                          onChangeText={text => onChange(text)}
                          errorMessage={errors.middleName?.message}
                        />
                      )}
                      name="middleName"
                    />
                  </View>

                  <View style={mobileStyles.inputContainer}>
                    <Controller
                      control={control}
                      render={({field: {onChange, onBlur, value}}) => (
                        <RNEInput
                          placeholder="Last Name"
                          value={value}
                          onChangeText={text => onChange(text)}
                          errorMessage={errors.lastName?.message}
                        />
                      )}
                      name="lastName"
                    />
                  </View>

                  <View style={mobileStyles.inputContainer}>
                    <Controller
                      control={control}
                      render={({field: {onChange, onBlur, value}}) => (
                        <RNEInput
                          placeholder="Email address"
                          leftIcon={<SVGIcon name="email" />}
                          keyboardType="email-address"
                          value={value}
                          onChangeText={text => onChange(text)}
                          errorMessage={errors.email?.message}
                        />
                      )}
                      name="email"
                    />
                  </View>
                </View>

                <View style={mobileStyles.inputContainer}>
                  <Controller
                    control={control}
                    render={({field: {onChange, onBlur, value}}) => (
                      <RNEInput
                        placeholder="Password"
                        secureTextEntry
                        leftIcon={<SVGIcon name="lock" />}
                        value={value}
                        onChangeText={text => onChange(text)}
                        errorMessage={errors.password?.message}
                      />
                    )}
                    name="password"
                  />
                </View>

                <View style={mobileStyles.checkBoxContainer}>
                  <CheckBox
                    title="Sign up as a Doctor"
                    checkedIcon={
                      <Icon
                        type="material-community"
                        name="checkbox-marked-circle"
                        color={colorPalette.primary}
                      />
                    }
                    uncheckedIcon={
                      <Icon
                        type="material-community"
                        name="checkbox-blank-circle-outline"
                        color={colorPalette.primary}
                      />
                    }
                    checked={asDoctor}
                    onPress={onCheckboxClicked}
                  />
                </View>
              </View>

              <View style={mobileStyles.loginActionContainer}>
                <RNEText
                  label="Sign Up"
                  variant="header"
                  weight="bold"
                  color="secondary"
                />

                <RNEIconButton
                  icon={<SVGIcon name="right-white" size={48} />}
                  color="secondary"
                  size={64}
                  onPress={goToVerification}
                  raised
                  disabled={!isValid}
                  loading={mutation.isLoading}
                />
              </View>
            </View>

            <View style={sharedStyles.footerActions}>
              <View style={mobileStyles.signupContainer}>
                <RNEText label="Already have an account?" variant="body" />

                <View style={mobileStyles.signupTextContainer}>
                  <RNEText
                    label="Login here."
                    color="primary"
                    variant="body"
                    weight="bold"
                    onPress={goToLogin}
                  />
                </View>
              </View>
            </View>
          </View>
        </ScrollView>
      )}
    </SafeAreaView>
  );
};

export default SignupScreen;

const webStyles = StyleSheet.create({
  mainContainer: {
    flexDirection: 'row',
    width: '100%',
  },
  loginContainer: {
    flex: 2,
    height: '100%',
    paddingVertical: 32,
    paddingHorizontal: 16,
    alignItems: 'center',
  },
  loginWrapper: {
    width: '100%',
    maxWidth: '1024px',
  },
  onboardingContainer: {
    flex: 1,
    width: '100%',
    maxWidth: 600,
    minWidth: 350,
    overflow: 'hidden',
  },
  logo: {
    width: 200,
    height: 44,
  },
  inputGroupContainer: {
    width: '100%',
    marginTop: 56,
  },
  nameInputGroupContainer: {
    flexDirection: 'row',
  },
  inputContainer: {
    marginTop: 16,
    flex: 1,
  },
  inputFirstNameContainer: {
    flex: 1,
  },
  inputNameContainer: {
    marginLeft: 24,
    flex: 1,
  },
  loginActionContainer: {
    marginTop: 32,
    maxWidth: 450,
    width: '100%',
    alignSelf: 'center',
  },
  contentWrapper: {
    maxWidth: 760,
    alignSelf: 'center',
    width: '100%',
    marginTop: '15vh',
  },
  signupContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  signupTextContainer: {
    marginLeft: 4,
  },
  footerActions: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingHorizontal: 48,
    marginTop: 24,
  },
});

const mobileStyles = StyleSheet.create({
  loginContainer: {
    flex: 1,
    height: '100%',
    paddingBottom: 48,
    alignItems: 'center',
    width: '100%',
  },
  logoContainer: {
    width: '100%',
    alignItems: 'center',
  },
  content: {
    flex: 1,
    width: '100%',
  },
  header: {
    // position: 'relative',
  },
  headerMain: {
    position: 'absolute',
    width: 360,
    height: 360,
    right: -280,
    top: -128,
    borderRadius: 360,
    shadowColor: '#050B5A',
    shadowOffset: {
      width: 5,
      height: 20,
    },
    shadowRadius: 20,
    elevation: 5,
  },
  headerTextContainer: {
    marginTop: 52,
    marginLeft: 48,
    width: '50%',
  },
  inputGroupContainer: {
    width: '100%',
    marginTop: 72,
    paddingHorizontal: 32,
  },
  loginActionContainer: {
    marginTop: 32,
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 48,
  },
  signupContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  signupTextContainer: {
    marginLeft: 4,
  },
  inputContainer: {
    marginTop: 24,
  },
  checkBoxContainer: {
    marginLeft: -20,
  },
});

const sharedStyles = StyleSheet.create({
  root: {
    height: '100%',
    flexDirection: 'row',
    width: '100%',
  },
  footerActions: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 48,
    marginTop: 80,
  },
});
