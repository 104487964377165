import React, {useState, useEffect} from 'react';
import {useNavigation, useRoute} from '@react-navigation/native';
import {View, Platform, ScrollView} from 'react-native';
import {useToast} from 'react-native-fast-toast';
import {makeStyles} from 'rne-netzon';
import {colorPalette} from '../../../core/config/color.config';

import {useForm, Controller} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';

import {RNEText, RNEButton, RNEInput} from '../../components';
import {
  useAddMedicalCertificate,
  useGetMedicalCertificates,
  useUpdateMedicalCertificate,
} from '../../server/react-query/useMedicalAttachment';
import {
  DiagnosticReportStatusEnum,
  MedicalAttachmentText,
} from '../../server/types/medical-attachment.types';
import {useSendMobilePushNotification} from '../../server/react-query/usePushNotification';
import {REACT_APP_BASE_WEB_URL} from '../../server/api/api.config';
import useStores from '../../stores/useStores';

interface AddMedicalCertificateViewProps {
  basedOnId: string;
  subject: string;
  userId: string;
}

const schema: yup.SchemaOf<MedicalAttachmentText> = yup.object({
  note: yup.string().trim().required('This field is required.'),
});

const AddMedicalCertificateView = (props: AddMedicalCertificateViewProps) => {
  const {authStore} = useStores();
  const webStyles = useWebStyles();
  const mobileStyles = useMobileStyles();
  const navigation = useNavigation();
  const route = useRoute();
  const toast = useToast();

  const {data, isFetching, refetch} = useGetMedicalCertificates({
    basedOnId: props.basedOnId,
    status: DiagnosticReportStatusEnum.PARTIAL,
  });

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    reset,
    formState: {errors, isValid},
  } = useForm<MedicalAttachmentText>({
    mode: 'all',
    defaultValues: {note: ''},
    resolver: yupResolver(schema),
  });

  const [basedOnId, setBasedOnId] = useState('');
  const [diagnosticReportId, setDiagnosticReportId] = useState('');
  const [status, setStatus] = useState<DiagnosticReportStatusEnum>(
    DiagnosticReportStatusEnum.PARTIAL,
  );

  const addMutation = useAddMedicalCertificate(
    basedOnId,
    status,
    getValues().note,
  );

  const updateMutation = useUpdateMedicalCertificate(
    diagnosticReportId,
    {
      conclusion: getValues().note,
      status: status,
    },
    basedOnId,
  );

  const [mutation, setMutation] = useState(addMutation);

  const addMedicalCertificate = (reportStatus: DiagnosticReportStatusEnum) => {
    setStatus(reportStatus);
    handleSubmit(onSubmit)();
  };

  const navProps = {
    procedureId: props.basedOnId,
    subjectId: props.subject,
    receiver: props.subject,
    serviceRequestId: props.basedOnId,
  };

  const triggerPushNotification = useSendMobilePushNotification({
    title: 'New chat message for the patient',
    body: 'Your consultation chat has a new message.',
    consultation: `"${JSON.stringify(navProps)}"`,
    notificationUrl: `${REACT_APP_BASE_WEB_URL}main/consultation/detail?id=${props.basedOnId}&screen=pending&tab=0`,
    fromUserId: authStore.authData?.user.userId!,
    toUserId: props.userId,
  });

  const onSubmit = async () => {
    if (isValid) {
      try {
        await mutation.mutateAsync();
        refetch();

        reset();
        mutation.reset();
        navigation.goBack();
        triggerPushNotification.mutateAsync();
      } catch (error) {
        if (error?.data?.error) {
          toast?.show(error?.data?.error, {type: 'danger'});
        }
      }
    }
  };

  useEffect(() => {
    const params = route.params as any;

    if (params) {
      setBasedOnId(params.basedOnId);
    }
  }, [route.params]);

  useEffect(() => {
    if (data && data.length > 0) {
      setValue('note', data?.[0]?.conclusion, {shouldValidate: true});
      setDiagnosticReportId(data?.[0]?.diagnosticReportId);
      setMutation(updateMutation);
    } else {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (props.basedOnId) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  return (
    <>
      {Platform.OS === 'web' ? (
        <ScrollView>
          <View>
            <View style={webStyles.formContainer}>
              <View style={webStyles.rightContainer}>
                <RNEText
                  label="Certificate Details"
                  style={subtitleStyle}
                  variant="title"
                  weight="bold"
                />
                <View style={webStyles.textareaContainer}>
                  <Controller
                    control={control}
                    render={({field: {onChange, onBlur, value}}) => (
                      <RNEInput
                        placeholder="Certificate Details"
                        variant="shadowed"
                        multiline={true}
                        height={320}
                        inputContainerStyle={inputContainerWebStyle}
                        inputStyle={textareaWebStyle}
                        value={value || ''}
                        onChangeText={text => {
                          onChange(text);
                        }}
                        errorMessage={errors.note?.message}
                      />
                    )}
                    name="note"
                  />
                </View>
                <View style={webStyles.buttonsContainer}>
                  <RNEButton
                    title="Cancel"
                    color="accent"
                    style={webStyles.buttonStyle}
                    variant="outlined"
                    height={60}
                    onPress={navigation.goBack}
                  />
                  <View style={webStyles.rightButtonsContainer}>
                    <View style={webStyles.buttonContainer}>
                      <RNEButton
                        title="Save Draft"
                        color="secondary"
                        style={webStyles.buttonStyle}
                        variant="outlined"
                        height={60}
                        loadingProps={loadingStyle}
                        loading={
                          (addMutation.isLoading || updateMutation.isLoading) &&
                          status === DiagnosticReportStatusEnum.PARTIAL
                        }
                        onPress={() =>
                          addMedicalCertificate(
                            DiagnosticReportStatusEnum.PARTIAL,
                          )
                        }
                      />
                    </View>
                    <RNEButton
                      title="Submit"
                      color="secondary"
                      style={webStyles.buttonStyle}
                      height={60}
                      loading={
                        (addMutation.isLoading || updateMutation.isLoading) &&
                        status === DiagnosticReportStatusEnum.FINAL
                      }
                      onPress={() =>
                        addMedicalCertificate(DiagnosticReportStatusEnum.FINAL)
                      }
                    />
                  </View>
                </View>
              </View>
            </View>
          </View>
        </ScrollView>
      ) : (
        <ScrollView>
          <View style={mobileStyles.mainContainer}>
            <View style={mobileStyles.titleContainer}>
              <RNEText
                label="Medical Certificate"
                color="accent"
                variant="title"
                weight="bold"
              />
            </View>
            <View style={mobileStyles.bodyContainer}>
              <Controller
                control={control}
                render={({field: {onChange, onBlur, value}}) => (
                  <RNEInput
                    height={300}
                    placeholder="Certificate Details"
                    multiline={true}
                    textAlignVertical="top"
                    inputContainerStyle={inputContainerMobileStyle}
                    inputStyle={inputStyle}
                    value={value || ''}
                    onChangeText={text => {
                      onChange(text);
                    }}
                    errorMessage={errors.note?.message}
                  />
                )}
                name="note"
              />
            </View>
            <View style={mobileStyles.buttonsContainer}>
              <RNEButton
                title="Cancel"
                color="accent"
                variant="outlined"
                onPress={navigation.goBack}
              />
              <View style={mobileStyles.rightButtonsContainer}>
                <View style={mobileStyles.buttonContainer}>
                  <RNEButton
                    title="Save Draft"
                    color="secondary"
                    variant="outlined"
                    loadingProps={loadingStyle}
                    loading={
                      (addMutation.isLoading || updateMutation.isLoading) &&
                      status === DiagnosticReportStatusEnum.PARTIAL
                    }
                    onPress={() =>
                      addMedicalCertificate(DiagnosticReportStatusEnum.PARTIAL)
                    }
                  />
                </View>
                <RNEButton
                  title="Submit"
                  color="secondary"
                  loading={
                    (addMutation.isLoading || updateMutation.isLoading) &&
                    status === DiagnosticReportStatusEnum.FINAL
                  }
                  onPress={() =>
                    addMedicalCertificate(DiagnosticReportStatusEnum.FINAL)
                  }
                />
              </View>
            </View>
          </View>
        </ScrollView>
      )}
    </>
  );
};

export default AddMedicalCertificateView;

const subtitleStyle = {
  fontSize: 16,
  paddingLeft: 20,
};

const inputContainerWebStyle = {
  borderRadius: 20,
};

const textareaWebStyle = {
  padding: 20,
  lineHeight: 24,
};

const inputContainerMobileStyle = {
  borderRadius: 12,
  paddingTop: 8,
  paddingBottom: 16,
};

const inputStyle = {
  lineHeight: 24,
};

const loadingStyle = {
  color: colorPalette.secondary,
};

const useWebStyles = makeStyles({
  titleContainer: {
    flexDirection: 'row',
    marginHorizontal: 100,
    paddingVertical: 20,
    borderBottomWidth: 1,
    borderBottomColor: colorPalette.grey,
  },
  headerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 100,
    paddingRight: 100,
    paddingVertical: 60,
  },
  formContainer: {
    backgroundColor: colorPalette.white,
    borderRadius: 20,
    paddingHorizontal: 12,
    marginBottom: 80,
    flexDirection: 'row',
  },
  leftContainer: {
    flexShrink: 0,
    borderRightColor: 'rgba(229, 229, 229, 0.7)',
    borderRightWidth: 1,
    paddingVertical: 60,
    paddingRight: 32,
  },
  subtitleContainer: {
    marginBottom: 40,
    paddingLeft: 12,
  },
  infoContainer: {
    marginBottom: 44,
  },
  inputLabel: {
    paddingLeft: 24,
    marginBottom: 4,
  },
  rightContainer: {
    flex: 3,
  },
  textareaContainer: {
    marginTop: 52,
  },
  buttonContainer: {
    marginRight: 20,
  },
  buttonsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 32,
    marginLeft: 20,
    flexWrap: 'wrap',
  },
  rightButtonsContainer: {
    flexDirection: 'row',
    marginLeft: 20,
    flexWrap: 'wrap',
  },
  buttonStyle: {
    width: 140,
  },
});

const useMobileStyles = makeStyles({
  mainContainer: {
    width: '100%',
    flex: 1,
    paddingHorizontal: 52,
    paddingBottom: 52,
    paddingTop: 20,
  },
  titleContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  iconContainer: {
    paddingBottom: 12,
  },
  bodyContainer: {
    paddingVertical: 40,
  },
  buttonContainer: {
    marginRight: 12,
  },
  buttonsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  rightButtonsContainer: {
    flexDirection: 'row',
    marginLeft: 20,
    flexWrap: 'wrap',
  },
});
