import React, {useState, useEffect} from 'react';
import {Platform} from 'react-native';
import {Input, InputProps} from 'rne-netzon';
import {colorPalette} from '../../../../core/config/color.config';
import {InputVariant, inputStyles, shadowStyles} from './models';

const defaultHeight = {
  web: 48,
  mobile: 48,
} as const;

interface RNEInputProps extends InputProps {
  height?: number;
  variant?: InputVariant;
}

export const RNEInput = (props: RNEInputProps) => {
  const [errorStyle, setErorStyle] = useState({});
  const [borderColor, setBorderColor] = useState(colorPalette.light);

  const {
    height = Platform.OS === 'web' ? defaultHeight.web : defaultHeight.mobile,
    style,
    inputContainerStyle,
    variant = 'default',
    ...others
  } = props;

  const onFocus = () => {
    if (props.errorMessage) {
      setBorderColor(inputStyles[variant].border.error);
    } else {
      setBorderColor(inputStyles[variant].border.focus);
    }
  };

  const onBlur = () => {
    if (props.errorMessage) {
      setBorderColor(inputStyles[variant].border.error);
    } else {
      setBorderColor(inputStyles[variant].border.default);
    }
  };

  useEffect(() => {
    setErorStyle(
      props.errorMessage
        ? {}
        : {
            display: 'none',
          },
    );

    if (props.errorMessage) {
      setBorderColor(inputStyles[variant].border.error);
    } else {
      setBorderColor(inputStyles[variant].border.default);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.errorMessage]);

  return (
    <Input
      errorStyle={errorStyle}
      {...others}
      onFocus={onFocus}
      onBlur={onBlur}
      style={Object.assign(
        style ?? {},
        {
          height: height,
        },
        Platform.OS === 'web'
          ? {
              outline: 'none',
            }
          : {},
      )}
      inputContainerStyle={Object.assign(
        inputContainerStyle ?? {},
        {
          borderColor: borderColor,
        },
        Object.assign(variant === 'shadowed' ? shadowStyles : {}),
      )}
    />
  );
};
