import React, {useCallback, useEffect, useLayoutEffect, useState} from 'react';
import {Platform, TouchableOpacity, View} from 'react-native';
import {ScrollView} from 'react-native-gesture-handler';
import {makeStyles} from 'rne-netzon';
import {colorPalette} from '../../../core/config/color.config';

import {RNEInput, RNEText, SVGIcon} from '../../components';

import {useNavigation, useRoute} from '@react-navigation/native';

import {
  ConsultationHistoryTab,
  ConsultationOngoingTab,
  ConsultationPendingTab,
  ConsultationRecentlyViewedAccount,
} from '../../views';

import {useDebounce} from '../../hooks/debounce/useDebounce';
import useStores from '../../stores/useStores';
import {NavigationKey} from '../../navigations/config';

const ConsultationSearchResultsScreen = () => {
  const styles = useStyles();
  const navigation = useNavigation();
  const route = useRoute();

  const {recentlyViewedAccountsStore} = useStores();

  const [searchValue, setSearchValue] = useState('');
  const [routeParams, setRouteParams] = useState({});
  const [currentTab, setCurrentTab] = useState('ongoing');

  const debouncedSearchValue = useDebounce(searchValue, 350);

  const goBack = () => {
    setSearchValue('');
    navigation.goBack();
  };

  const onItemPressed = (id: string) => {
    const params = {
      ...routeParams,
      id: id,
    };
    navigation.navigate(NavigationKey.CONSULTATION_DETAIL, params);
  };

  const displayTab = useCallback(() => {
    switch (currentTab) {
      case 'ongoing':
        return <ConsultationOngoingTab searchKey={debouncedSearchValue} />;

      case 'pending':
        return <ConsultationPendingTab searchKey={debouncedSearchValue} />;

      case 'history':
        return <ConsultationHistoryTab searchKey={debouncedSearchValue} />;
    }
  }, [currentTab, debouncedSearchValue]);

  useEffect(() => {
    const params: any = route.params;

    if (params) {
      setRouteParams(params);

      if (params.currentTab) {
        setCurrentTab(params.currentTab);
      }
    }
  }, [route]);

  useLayoutEffect(() => {
    if (Platform.OS !== 'web') {
      navigation.setOptions({
        animationEnabled: false,
        header: () => (
          <View style={styles.headerContainer}>
            <RNEInput
              value={searchValue}
              onChangeText={setSearchValue}
              placeholder="Search"
              placeholderTextColor={colorPalette.grey}
              inputContainerStyle={inputContainerStyle}
              containerStyle={containerStyle}
              autoFocus={true}
              returnKeyType="search"
              leftIcon={
                <TouchableOpacity onPress={goBack}>
                  <SVGIcon name="left-black" size={20} />
                </TouchableOpacity>
              }
            />
          </View>
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigation, searchValue]);

  return (
    <ScrollView>
      <View style={styles.mainContainer}>
        {!searchValue ? (
          <View style={styles.recentContainer}>
            {recentlyViewedAccountsStore.recentlyViewedAccts.length > 0 && (
              <>
                <RNEText label="Recent" weight="bold" />
                {recentlyViewedAccountsStore.recentlyViewedAccts.map(
                  (account, index) => (
                    <ConsultationRecentlyViewedAccount
                      key={index}
                      item={account}
                      onPress={() => onItemPressed(account.basedOnId)}
                    />
                  ),
                )}
              </>
            )}
          </View>
        ) : (
          <>{displayTab()}</>
        )}
      </View>
    </ScrollView>
  );
};

const inputContainerStyle = {
  borderWidth: 0,
  borderBottomWidth: 0,
};

const containerStyle = {
  width: '80%',
};

const useStyles = makeStyles({
  headerContainer: {
    marginHorizontal: 20,
    marginTop: Platform.OS === 'ios' ? '10%' : 0,
    borderBottomColor: 'rgba(229, 229, 229, 0.7)',
    borderBottomWidth: 0.8,
    height: 60,
    justifyContent: 'center',
  },
  mainContainer: {
    marginHorizontal: 20,
  },
  recentContainer: {
    marginTop: 32,
  },
});

export default ConsultationSearchResultsScreen;
