import React, {useState} from 'react';
import {StyleSheet, View, Platform} from 'react-native';

import {observer} from 'mobx-react';

import {RNEText, RNEButton} from '../../components';
import {Overlay} from 'rne-netzon';

import {colorPalette} from '../../../core/config/color.config';

import {useAcceptConsultationDetail} from '../../server/react-query/useConsultation';

import ConsultationDeclineModal from '../modal/ConsultationDeclineModal';
import {useNavigation} from '@react-navigation/native';
import {NavigationKey} from '../../navigations/config';
import {useToast} from 'react-native-fast-toast';
import {ConsultationRequestAcceptanceModal} from '..';
import {ConsultationRequestResBody} from '../../server/types/consultation.types';

interface ConsultationAcceptDeclineProps {
  serviceRequest: ConsultationRequestResBody;
}

const ConsultationAcceptDecline = (props: ConsultationAcceptDeclineProps) => {
  const navigation = useNavigation();
  const toast = useToast();

  const [showDenyConsultationModal, setShowDenyConsultationModal] = useState(
    false,
  );
  const [
    showAcceptConsultationDialog,
    setShowAcceptConsultationDialog,
  ] = useState(false);

  const acceptMutation = useAcceptConsultationDetail({
    basedOn: props.serviceRequest.serviceRequestId,
  });

  const onDenyConsultation = async () => {
    setShowDenyConsultationModal(true);
  };

  const onDenyConsultationComplete = async () => {
    setShowDenyConsultationModal(false);
  };

  const onAcceptConsultation = async () => {
    if (Platform.OS === 'web') {
      setShowAcceptConsultationDialog(true);
    } else {
      navigation.navigate(NavigationKey.CONSULTATION_REQUEST_ACCEPTANCE, {
        onConfirm: navigation.goBack,
        serviceRequest: props.serviceRequest,
      });
    }
  };

  const onAcceptConsultationComplete = async () => {
    setShowAcceptConsultationDialog(false);
  };

  return (
    <>
      <Overlay
        isVisible={showAcceptConsultationDialog}
        onBackdropPress={() => setShowAcceptConsultationDialog(false)}
        overlayStyle={
          Platform.OS === 'web'
            ? webStyles.overlayContainerWeb
            : mobileStyles.overlayContainerMobile
        }>
        <ConsultationRequestAcceptanceModal
          onConfirm={onAcceptConsultationComplete}
          serviceRequest={props.serviceRequest}
        />
      </Overlay>
      {Platform.OS === 'web' ? (
        <View style={webStyles.mainContainer}>
          <View style={webStyles.messageContainer}>
            <RNEText
              label="The patient's name is hidden from view until you accept this consultation. Once you accept this request, the patient will proceed with payment before the consultation starts."
              color="grey"
              variant="button"
              numberOfLines={3}
              ellipsizeMode="tail"
            />
          </View>

          <View style={webStyles.actionContainer}>
            <RNEButton
              title="Decline"
              color="secondary"
              type="outline"
              variant="outlined"
              style={webStyles.buttonStyle}
              onPress={onDenyConsultation}
            />

            <View style={webStyles.buttonSibling}>
              <RNEButton
                title="Accept"
                color="secondary"
                style={webStyles.buttonStyle}
                onPress={onAcceptConsultation}
                loading={acceptMutation.isLoading}
              />
            </View>
          </View>
        </View>
      ) : (
        <View style={mobileStyles.mainContainer}>
          <View style={mobileStyles.messageContainer}>
            <RNEText
              label="The patient's name is hidden from view until you accept this consultation. Once you accept this request, the patient will proceed with payment before the consultation starts."
              color="grey"
              ellipsizeMode="tail"
              align="center"
            />
          </View>

          <View style={mobileStyles.actionContainer}>
            <RNEButton
              title="Decline"
              color="secondary"
              type="outline"
              variant="outlined"
              style={mobileStyles.buttonStyle}
              onPress={onDenyConsultation}
            />

            <View style={mobileStyles.buttonSibling}>
              <RNEButton
                title="Accept"
                color="secondary"
                style={webStyles.buttonStyle}
                onPress={onAcceptConsultation}
                loading={acceptMutation.isLoading}
              />
            </View>
          </View>
        </View>
      )}

      <Overlay
        isVisible={showDenyConsultationModal}
        overlayStyle={
          Platform.OS === 'web'
            ? webStyles.overlayContainerWeb
            : mobileStyles.overlayContainerMobile
        }>
        <ConsultationDeclineModal
          serviceRequestId={props.serviceRequest.serviceRequestId}
          onCancel={() => setShowDenyConsultationModal(false)}
          onConfirm={onDenyConsultationComplete}
        />
      </Overlay>
    </>
  );
};

export default observer(ConsultationAcceptDecline);

const webStyles = StyleSheet.create({
  mainContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 16,
    borderTopWidth: 1,
    borderTopColor: colorPalette.light,
  },
  messageContainer: {
    flex: 1,
    justifyContent: 'center',
    marginRight: 16,
  },
  actionContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  buttonStyle: {
    width: 120,
  },
  buttonSibling: {
    marginLeft: 16,
  },
  overlayContainerWeb: {
    borderRadius: 16,
    paddingVertical: 24,
    maxHeight: '95vh',
  },
});

const mobileStyles = StyleSheet.create({
  mainContainer: {
    alignItems: 'center',
    paddingVertical: 32,
    borderTopWidth: 1,
    borderTopColor: colorPalette.light,
  },
  messageContainer: {
    alignItems: 'center',
    marginBottom: 24,
  },
  actionContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  buttonStyle: {
    width: 120,
  },
  buttonSibling: {
    marginLeft: 16,
  },
  overlayContainerMobile: {
    borderRadius: 20,
    paddingHorizontal: 32,
    paddingVertical: 24,
  },
});
