import {AxiosRequestConfig} from 'axios';
import {Platform} from 'react-native';
import {API} from '../server/api';

/**
 * sends a request to the specified url from a form. this will change the window location.
 * @param {string} path the path to send the post request to
 * @param {object} params the parameters to add to the url
 * @param {string} [method=post] the method to use on the form
 */
export const htmlPost = (
  path: string,
  params: any,
  method: string = 'post',
) => {
  // The rest of this code assumes you are not using a library.
  // It can be made less verbose if you use one.
  if (Platform.OS === 'web') {
    const form = document.createElement('form');
    form.method = method;
    form.action = path;

    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        const hiddenField = document.createElement('input');
        hiddenField.type = 'hidden';
        hiddenField.name = key;
        hiddenField.value = params[key];

        form.appendChild(hiddenField);
      }
    }

    document.body.appendChild(form);
    form.submit();
  } else {
    const config: AxiosRequestConfig = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    const data = new URLSearchParams();
    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        data.append(key, params[key]);
      }
    }

    API.post(path, data, config);
  }
};
