import React, {memo, useState, useEffect} from 'react';
import {View} from 'react-native';

import {format} from 'date-fns';

import {makeStyles} from 'rne-netzon';

import {PatientProfileReqResponse} from '../../server/types/patient-profile.types';
import PatientDetailItemView from './PatientDetailItemView';
import {computeAge} from '../../utils/date.utils';

interface PatientDetailViewProps {
  detail?: PatientProfileReqResponse;
  anonymous?: boolean;
}

const PatientDetailView = (props: PatientDetailViewProps) => {
  const styles = useStyles();
  const [age, setAge] = useState<number | undefined>(0);

  useEffect(() => {
    if (props.detail?.birthdate) {
      setAge(computeAge(props.detail?.birthdate));
    }
  }, [props.detail]);

  return (
    <View style={styles.root}>
      <PatientDetailItemView
        style={styles.detailItem}
        label={
          !props.anonymous
            ? props.detail && props.detail.humanName
              ? `${props.detail.humanName.given[0]} ${props.detail.humanName.family}`
              : '--'
            : '[Data Hidden]'
        }
        iconName="user"
      />
      <PatientDetailItemView
        style={styles.detailItem}
        label={props.detail?.location || '--'}
        iconName="map"
      />
      <PatientDetailItemView
        style={styles.detailItem}
        label={
          props.detail && props.detail.birthdate
            ? `${age} ${age && age > 1 ? 'years' : 'year'} old \n${format(
                new Date(props.detail.birthdate),
                'MMMM dd, yyyy',
              )}`
            : '--'
        }
        iconName="calendar"
      />
      {/* <PatientDetailItemView
        style={styles.detailItem}
        label={'--'}
        iconName="gender"
      /> */}
      <PatientDetailItemView
        style={styles.detailItem}
        label={
          !props.anonymous
            ? props.detail?.contactNumber || '--'
            : '[Data Hidden]'
        }
        iconName="call-27"
      />
    </View>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    flexDirection: 'column',
  },
  detailItem: {
    marginBottom: 24,
  },
}));

export default memo(PatientDetailView);
