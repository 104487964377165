import React from 'react';
import {View, TouchableOpacity} from 'react-native';
import {MaterialTopTabBarProps} from '@react-navigation/material-top-tabs';
import Animated from 'react-native-reanimated';
import {makeStyles} from 'rne-netzon';
import {colorPalette} from '../../../core/config/color.config';
import {RNEText} from '../../components';

const ConsultationTabBar = ({
  state,
  descriptors,
  navigation,
  position,
}: MaterialTopTabBarProps) => {
  const styles = useStyle();

  return (
    <View style={styles.tabRoot}>
      {state.routes.map((route, index) => {
        const {options} = descriptors[route.key];
        const label =
          options.tabBarLabel !== undefined
            ? options.tabBarLabel
            : options.title !== undefined
            ? options.title
            : route.name;

        const isFocused = state.index === index;

        const onPress = () => {
          const event = navigation.emit({
            type: 'tabPress',
            target: route.key,
            canPreventDefault: true,
          });

          if (!isFocused && !event.defaultPrevented) {
            navigation.navigate(route.name);
          }
        };

        const onLongPress = () => {
          navigation.emit({
            type: 'tabLongPress',
            target: route.key,
          });
        };

        const inputRange = state.routes.map((_, i) => i);
        const outputRange = inputRange.map(i => (i === index ? 1 : 0.7));
        const opacity = Animated.interpolateNode(position, {
          inputRange,
          outputRange,
        });

        return (
          <View key={index} style={[styles.tabContainer]}>
            <TouchableOpacity
              accessibilityRole="button"
              accessibilityState={isFocused ? {selected: true} : {}}
              accessibilityLabel={options.tabBarAccessibilityLabel}
              testID={options.tabBarTestID}
              onPress={onPress}
              onLongPress={onLongPress}
              style={styles.tabContent}
              activeOpacity={0.75}>
              <View
                style={[
                  styles.tabContentWrapper,
                  {
                    backgroundColor: isFocused
                      ? colorPalette.white
                      : colorPalette.primary,
                  },
                ]}>
                <RNEText
                  label={label.toString()}
                  color={isFocused ? 'primary' : 'white'}
                  variant="button"
                  weight={isFocused ? '600' : '400'}
                />
              </View>
            </TouchableOpacity>

            <View style={[styles.tabIndicator, isFocused && styles.triangle]} />
          </View>
        );
      })}
    </View>
  );
};

const useStyle = makeStyles({
  tabRoot: {
    flexDirection: 'row',
    paddingHorizontal: 24,
    paddingTop: 12,
    backgroundColor: colorPalette.primary,
    height: 68,
  },
  tabContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 4,
  },
  tabContent: {
    flex: 1,
    // width: '100%',
    // justifyContent: 'center',
  },
  tabContentWrapper: {
    paddingVertical: 8,
    paddingHorizontal: 16,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 36,
  },
  tabIndicator: {
    height: 16,
  },
  triangle: {
    width: 0,
    height: 0,
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderLeftWidth: 12,
    borderRightWidth: 12,
    borderBottomWidth: 12,
    borderLeftColor: 'transparent',
    borderRightColor: 'transparent',
    borderBottomColor: colorPalette.white,
    // opacity: 1,
  },
  triangleDown: {
    transform: [{rotate: '180deg'}],
  },
});

export default ConsultationTabBar;
