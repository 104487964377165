import React, {createContext, useContext} from 'react';
import {observer, useLocalObservable} from 'mobx-react';
import {AuthStore} from './authStore';
import {EmailStore} from './emailStore';
import {PatientProfileStore} from './patientProfileStore';
import {DoctorProfileStore} from './doctorProfileStore';
import {SpecializationStore} from './specializationStore';
import {HospitalStore} from './hospitalStore';
import {ConversationStore} from './conversationStore';
import {RecentlyViewedAccountsStore} from './recentlyViewedAccountsStore';

export type TStore = {
  authStore: AuthStore;
  emailStore: EmailStore;
  patientProfileStore: PatientProfileStore;
  doctorProfileStore: DoctorProfileStore;
  specializationStore: SpecializationStore;
  hospitalStore: HospitalStore;
  conversationStore: ConversationStore;
  recentlyViewedAccountsStore: RecentlyViewedAccountsStore;
};

export const storeContext = createContext<TStore | null>(null);

export const StoreProvider = observer(({children}: any) => {
  const authStore = useLocalObservable(() => new AuthStore());
  const emailStore = useLocalObservable(() => new EmailStore());
  const patientProfileStore = useLocalObservable(
    () => new PatientProfileStore(),
  );
  const doctorProfileStore = useLocalObservable(() => new DoctorProfileStore());
  const specializationStore = useLocalObservable(
    () => new SpecializationStore(),
  );
  const hospitalStore = useLocalObservable(() => new HospitalStore());
  const conversationStore = useLocalObservable(() => new ConversationStore());
  const recentlyViewedAccountsStore = useLocalObservable(
    () => new RecentlyViewedAccountsStore(),
  );

  const stores = {
    authStore,
    emailStore,
    patientProfileStore,
    doctorProfileStore,
    specializationStore,
    hospitalStore,
    conversationStore,
    recentlyViewedAccountsStore,
  };

  return (
    <storeContext.Provider value={stores}>{children}</storeContext.Provider>
  );
});

const useStores = () => {
  const _store = useContext(storeContext);

  if (!_store) {
    throw new Error('You have forgotten to use StoreProvider.');
  }
  return _store;
};

export default useStores;
