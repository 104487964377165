import React, {useState} from 'react';
import {
  Platform,
  View,
  Image,
  TouchableWithoutFeedback,
  TouchableOpacity,
} from 'react-native';
import {SafeAreaView} from 'react-native-safe-area-context';

import HTML from 'react-native-render-html';

import {Icon, makeStyles} from 'rne-netzon';
import {useToast} from 'react-native-fast-toast';

import {useNavigation} from '@react-navigation/native';
import {NavigationKey} from '../../navigations/config';

import useStores from '../../stores/useStores';
import {useAcceptTerms} from '../../server/react-query/useRegistration';

import {colorPalette} from '../../../core/config/color.config';
import {LogoLight} from '../../images/Image';

import {HTMLTermsAndConditions} from '../../data/terms/terms';

import {
  SVGIcon,
  RNEButton,
  RNEIconButton,
  RNEText,
  RNECheckBox,
} from '../../components';

import {ContentScrollView} from '../../views';

const TermsConditionsAuthScreen = () => {
  const rneStyles = useStyles();
  const {authStore} = useStores();
  const navigation = useNavigation();
  const toast = useToast();

  const [termsAccepted, setTermsAccepted] = useState(false);

  const mutation = useAcceptTerms({});

  const onCheckboxClicked = () => {
    setTermsAccepted(!termsAccepted);
  };

  const acceptTermsAndConditions = async () => {
    try {
      await mutation.mutateAsync();
      authStore.login();
    } catch (error) {
      console.log('error', error);
      if (error?.data?.error) {
        toast?.show(error?.data?.error, {type: 'danger'});
      }
    }
  };

  const goToLogin = () => {
    navigation.navigate(NavigationKey.LOGIN_SCREEN);
  };

  const onPrivacyPolicyClick = () => {
    navigation.navigate(NavigationKey.PRIVACY_POLICY);
  };

  return (
    <SafeAreaView style={rneStyles.root}>
      {Platform.OS === 'web' ? (
        <View style={rneStyles.logoContainer}>
          <TouchableWithoutFeedback onPress={goToLogin}>
            <Image style={rneStyles.logo} source={LogoLight} />
          </TouchableWithoutFeedback>
        </View>
      ) : (
        <></>
      )}

      <View style={rneStyles.contentContainer}>
        <View style={rneStyles.headerContainer}>
          <RNEText
            label="Before we proceed..."
            variant="header"
            weight="bold"
            color="accent"
          />
          <RNEText
            label="Terms and Conditions"
            variant="subheader"
            weight="bold"
            style={subtitleStyle}
          />
        </View>

        <View style={rneStyles.bodyContainer}>
          <ContentScrollView isWeb={Platform.OS === 'web'}>
            <HTML
              source={{
                html: HTMLTermsAndConditions,
              }}
            />
          </ContentScrollView>
        </View>

        <View style={rneStyles.footerContainer}>
          <View style={rneStyles.leftFooter}>
            <View style={rneStyles.footerCheckbox}>
              <RNECheckBox
                title="I agree to the Terms and Conditions"
                checkedIcon={
                  <Icon
                    type="material-community"
                    name="checkbox-marked-circle"
                    color={colorPalette.primary}
                  />
                }
                uncheckedIcon={
                  <Icon
                    type="material-community"
                    name="checkbox-blank-circle-outline"
                    color={colorPalette.primary}
                  />
                }
                checked={termsAccepted}
                onPress={onCheckboxClicked}
              />
            </View>

            <View style={rneStyles.privacyContainer}>
              <TouchableOpacity
                activeOpacity={0.75}
                onPress={onPrivacyPolicyClick}>
                <RNEText
                  label="Privacy Policy "
                  variant="button"
                  weight="bold"
                />
              </TouchableOpacity>
              <RNEText label="here" variant="button" />
            </View>
          </View>

          {Platform.OS === 'web' ? (
            <View style={rneStyles.btnAccept}>
              <RNEButton
                title="Let's Go!"
                color="secondary"
                onPress={acceptTermsAndConditions}
                disabled={!termsAccepted}
                loading={mutation.isLoading}
              />
            </View>
          ) : (
            <RNEIconButton
              icon={<SVGIcon name="right-white" size={48} />}
              color="secondary"
              size={64}
              onPress={acceptTermsAndConditions}
              raised
              disabled={!termsAccepted}
              loading={mutation.isLoading}
            />
          )}
        </View>
      </View>
    </SafeAreaView>
  );
};

const subtitleStyle = {
  fontSize: 20,
};

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    height: '100%',
    width: '100%',
    padding: Platform.OS === 'web' ? 48 : 24,
  },
  backButtonContainer: {
    alignSelf: 'flex-start',
  },
  logoContainer: {
    width: '100%',
    maxWidth: '1024px',
    alignSelf: 'center',
  },
  logo: {
    width: 200,
    height: 44,
  },
  contentContainer: {
    width: '100%',
    maxWidth: 760,
    alignSelf: 'center',
    flex: 1,
    height: '100%',
    marginTop: Platform.OS === 'web' ? '10vh' : 16,
  },
  headerContainer: {},
  bodyContainer: {
    flex: 1,
    marginVertical: 24,
    maxHeight: Platform.OS === 'web' ? '50vh' : 'auto',
  },
  tncParagraph: {
    marginBottom: 24,
  },
  footerContainer: {
    height: 72,
    flexDirection: 'row',
    alignItems: 'center',
  },
  leftFooter: {
    flex: 1,
  },
  footerCheckbox: {
    flex: 1,
  },
  privacyContainer: {
    marginLeft: 56,
    flexDirection: 'row',
    alignItems: 'center',
  },
  btnAccept: {
    width: 150,
  },
}));

export default TermsConditionsAuthScreen;
