import React, {useState, createRef, useEffect} from 'react';
import {
  Platform,
  View,
  Image,
  ScrollView,
  TextInput,
  TouchableWithoutFeedback,
} from 'react-native';
import {SafeAreaView} from 'react-native-safe-area-context';

import {makeStyles, Overlay, useTheme} from 'rne-netzon';
import {useToast} from 'react-native-fast-toast';

import {useNavigation} from '@react-navigation/native';
import {NavigationKey} from '../../navigations/config';

import {
  useSendVerification,
  useConfirmVerification,
} from './../../server/react-query/useRegistration';

import {LogoContrast} from './../../images/Image';

import {SVGIcon, RNEButton, RNEText, RNEBoxInput} from '../../components';

import {RegistrationSuccessModal} from '../../views';

const backButtonPaddingHorizontal = 4;
const backButtonTextPaddingHorizontal = 12;

const arrRefLength = 5;
const defaultCodeValues = ['', '', '', '', ''];

const SignupVerificationScreen = () => {
  const navigation = useNavigation();
  const rneStyles = useStyles();
  const {theme} = useTheme();

  const toast = useToast();

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [code, setCode] = useState('');
  const [codeValues, setCodeValues] = useState<string[]>(defaultCodeValues);
  const [canSubmit, setCanSubmit] = useState(false);
  const [elRefs, setElRefs] = React.useState<React.RefObject<TextInput>[]>([]);

  const mutation = useConfirmVerification({
    code: code,
  });

  const sendMutation = useSendVerification();

  const onChangeText = (index: number, text: string) => {
    setCodeValues(oldValues => {
      return oldValues.map((value, i) => {
        return i === index ? text.trim() : value;
      });
    });

    if (text && text.trim()) {
      if (index < arrRefLength - 1) {
        elRefs[index + 1].current?.focus();
      }
    }
  };

  const confirmVerification = async () => {
    try {
      await mutation.mutateAsync();
      setShowSuccessModal(true);
    } catch (error) {
      console.log('error', error);
      if (error?.data?.error) {
        toast?.show(error?.data?.error, {type: 'danger'});
      }
    }
  };

  const sendVerification = async () => {
    try {
      await sendMutation.mutateAsync();
      toast?.show('Successfully sent verification code.', {type: 'success'});
    } catch (error) {
      console.log('error', error);
      if (error?.data?.error) {
        toast?.show(error?.data?.error, {type: 'danger'});
      }
    }
  };

  const goToLogin = () => {
    navigation.navigate(NavigationKey.LOGIN_SCREEN);
  };

  const goToSignup = () => {
    navigation.navigate(NavigationKey.SIGNUP_SCREEN);
  };

  const goToTermsAndConditions = () => {
    setShowSuccessModal(false);
    setCodeValues(defaultCodeValues);
    navigation.navigate(NavigationKey.TANDC_SCREEN);
  };

  useEffect(() => {
    setElRefs(oldElRefs =>
      Array(arrRefLength)
        .fill(null)
        .map((_, i) => oldElRefs[i] || createRef()),
    );
  }, []);

  useEffect(() => {
    setCode(codeValues.join(''));
  }, [codeValues]);

  useEffect(() => {
    setCanSubmit(code.length === 5);
  }, [code]);

  return (
    <SafeAreaView style={rneStyles.root}>
      <ScrollView>
        {Platform.OS === 'web' ? (
          <View style={rneStyles.logoContainer}>
            <TouchableWithoutFeedback
              onPress={goToLogin}
              accessibilityRole="button">
              <Image style={rneStyles.logo} source={LogoContrast} />
            </TouchableWithoutFeedback>
          </View>
        ) : (
          <View style={rneStyles.backButtonContainer}>
            <RNEButton
              title="Sign Up"
              type="clear"
              icon={<SVGIcon name="left-white" size={16} />}
              onPress={goToSignup}
              titleStyle={{
                color: theme.colors?.white,
                paddingLeft: backButtonTextPaddingHorizontal,
              }}
              buttonStyle={{
                paddingHorizontal: backButtonPaddingHorizontal,
              }}
            />
          </View>
        )}

        <View style={rneStyles.contentContainer}>
          <View>
            <View style={rneStyles.headerContainer}>
              <RNEText
                label="Verify Account"
                color="white"
                variant="header"
                weight="bold"
                align="center"
              />
              <RNEText
                label="A 5-digit verification code has been sent to your email. This code is valid only within the next 5 minutes."
                color="white"
                variant="body"
                align="center"
              />
            </View>

            <View>
              <View>
                <View style={rneStyles.inputGroupContainer}>
                  <View>
                    <RNEBoxInput
                      ref={elRefs[0]}
                      maxLength={1}
                      value={codeValues[0]}
                      onChangeText={text => onChangeText(0, text)}
                    />
                  </View>
                  <View style={rneStyles.inputBoxContainer}>
                    <RNEBoxInput
                      ref={elRefs[1]}
                      maxLength={1}
                      value={codeValues[1]}
                      onChangeText={text => onChangeText(1, text)}
                    />
                  </View>
                  <View style={rneStyles.inputBoxContainer}>
                    <RNEBoxInput
                      ref={elRefs[2]}
                      maxLength={1}
                      value={codeValues[2]}
                      onChangeText={text => onChangeText(2, text)}
                    />
                  </View>
                  <View style={rneStyles.inputBoxContainer}>
                    <RNEBoxInput
                      ref={elRefs[3]}
                      maxLength={1}
                      value={codeValues[3]}
                      onChangeText={text => onChangeText(3, text)}
                    />
                  </View>
                  <View style={rneStyles.inputBoxContainer}>
                    <RNEBoxInput
                      ref={elRefs[4]}
                      maxLength={1}
                      value={codeValues[4]}
                      onChangeText={text => onChangeText(4, text)}
                      onSubmitEditing={confirmVerification}
                    />
                  </View>
                </View>

                <View style={rneStyles.actionContainer}>
                  <View style={rneStyles.verifyBtnContainer}>
                    <RNEButton
                      title="Verify Account"
                      color="secondary"
                      onPress={confirmVerification}
                      disabled={!canSubmit}
                      loading={mutation.isLoading}
                    />
                  </View>

                  <View style={rneStyles.resendBtnContainer}>
                    <RNEButton
                      title="Resend Code"
                      type="clear"
                      onPress={sendVerification}
                      titleStyle={{
                        color: theme.colors?.white,
                      }}
                    />
                  </View>

                  <View style={rneStyles.resendTextContainer}>
                    <RNEText
                      label="You can only resend code every after one minute"
                      color="white"
                      align="center"
                    />
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
      </ScrollView>

      <Overlay
        isVisible={showSuccessModal}
        overlayStyle={
          Platform.OS === 'web'
            ? rneStyles.overlayContainerWeb
            : rneStyles.overlayContainerMobile
        }>
        <RegistrationSuccessModal onConfirm={goToTermsAndConditions} />
      </Overlay>
    </SafeAreaView>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    height: '100%',
    width: '100%',
    backgroundColor: theme.colors?.primary,
    padding: Platform.OS === 'web' ? 48 : 24,
  },
  backButtonContainer: {
    alignSelf: 'flex-start',
  },
  logoContainer: {
    width: '100%',
    maxWidth: '1024px',
    alignSelf: 'center',
  },
  logo: {
    width: 200,
    height: 44,
  },
  contentContainer: {
    width: '100%',
    maxWidth: 450,
    alignSelf: 'center',
    flex: 1,
    height: '100%',
    justifyContent: 'center',
    marginTop: Platform.OS === 'web' ? '15vh' : 96,
  },
  headerContainer: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  inputGroupContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 56,
  },
  inputBoxContainer: {
    marginLeft: Platform.OS === 'web' ? 16 : 8,
  },
  actionContainer: {
    marginTop: 24,
    alignItems: 'center',
  },
  verifyBtnContainer: {
    width: Platform.OS === 'web' ? '60%' : 150,
  },
  resendBtnContainer: {
    width: Platform.OS === 'web' ? '60%' : 150,
    marginTop: 16,
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
  },
  resendTextContainer: {
    width: Platform.OS === 'web' ? '60%' : 200,
    marginTop: 16,
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
  },
  overlayContainerWeb: {
    borderRadius: 8,
    paddingHorizontal: 48,
    paddingVertical: 24,
  },
  overlayContainerMobile: {
    borderRadius: 20,
    paddingHorizontal: 48,
    paddingVertical: 52,
  },
}));

export default SignupVerificationScreen;
