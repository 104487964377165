import {PlatformOSType, PermissionsAndroid} from 'react-native';
import {Dirs, FileSystem} from 'react-native-file-access';
import {AxiosRequestConfig} from 'axios';
import {API} from '../api';
import {getJWT} from './api.helper';

type fileExtension = 'pdf';

interface downloadFileProps {
  endpoint: string;
  method?: 'GET' | 'POST';
  headers?: any;
  fileExtension?: fileExtension;
  fileName?: string;
  platform: PlatformOSType;
}

export const downloadFile = async ({
  endpoint,
  method = 'GET',
  headers = {},
  fileExtension = 'pdf',
  fileName = 'download',
  platform,
}: downloadFileProps): Promise<any> => {
  if (platform === 'web') {
    return null;
  } else {
    const jwt = await getJWT();

    if (jwt) {
      headers.Authorization = 'Bearer ' + jwt;
    }

    let config: AxiosRequestConfig = {};

    config = {
      headers,
      responseType: 'blob',
    };

    const name = `${fileName}-${new Date().getTime()}.${fileExtension}`;
    const path = `${Dirs.DocumentDir}/${name}`;

    // code used below is based from this blog
    // https://dev.to/adecrown/writing-a-file-and-making-it-visible-to-users-in-react-native-37j7
    // other reference: https://github.com/RonRadtke/react-native-blob-util
    const saveFileToFolder = async (base64Data: string) => {
      try {
        const permissionWriteExternalStorage = async () => {
          const granted = await PermissionsAndroid.request(
            PermissionsAndroid.PERMISSIONS.WRITE_EXTERNAL_STORAGE,
          );
          return granted === PermissionsAndroid.RESULTS.GRANTED;
        };

        if (platform === 'android') {
          const permissionGranted = await permissionWriteExternalStorage();
          if (permissionGranted) {
            await FileSystem.writeFile(path, base64Data, 'base64');

            if (!FileSystem.exists(path)) {
              return;
            } // check to see if our path was created

            await FileSystem.cpExternal(path, name, 'downloads'); // copies our file to the downloads folder/directory
            // file should now be visible in the downloads folder
            console.log('android: file download complete', path);
          }
        }

        if (platform === 'ios') {
          await FileSystem.writeFile(path, base64Data, 'base64');
          console.log('ios: file download complete', path);
        }

        return;
      } catch (error) {
        console.error(error);
      }
    };

    // use axios GET for getting the blob data
    // then convert blob to base64 as this is the input required for `saveFileToFolder()`
    // DISCLAIMER: converting from blob to base64 may have issues but this works for now
    // should research proper handling of downloading attachment for native apps
    return API.get(endpoint, config).then(async (r: any) => {
      const reader = new FileReader();
      reader.readAsDataURL(r);
      reader.onloadend = async () => {
        const base64data = reader.result;
        if (base64data && typeof base64data === 'string') {
          const base64 = base64data.substr(base64data.indexOf(',') + 1);
          await saveFileToFolder(base64);
        }
      };
    });
  }
};
