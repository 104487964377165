export enum ApiEndpointsEnum {
  LOGIN = '/authentication',
  LOGOUT = '/authentication',
  SIGNUP = '/user',
  REQUEST_VERIFICATION = '/user/verification/send',
  CONFIRM_VERIFICATION = '/user/verification/confirm',
  ACCEPT_TERMS = '/user/terms',
  FORGOT_PASSWORD = '/user/forgot-password',
  RESET_PASSWORD = '/user/reset-password',
  GET_PATIENT_PROFILES = '/patient/profiles',
  GET_PATIENT_PROFILE = '/patient/{patientId}',
  POST_PATIENT_PROFILE = '/patient/gateway',
  PUT_PATIENT_PROFILE = '/patient/gateway/{patientId}',
  PUT_PATIENT_PROFILE_PHOTO = '/patient/{patientId}/photo',
  GET_ADMIN_PATIENT_PROFLE = '/patient/profiles/{userId}',
  DELETE_PATIENT_PROFILE = '/patient/{patientId}',
  GET_ADMIN_PATIENT_PROFLES = '/patient',
  GET_ALLERGY_INTOLERANCE = '/allergyintolerance',
  PUT_ALLERGY_INTOLERANCE = '/allergyintolerance',
  GET_CONDITION = '/condition',
  PUT_CONDITION = '/condition',
  GET_FAMILY_MEMBER_HISTORY = '/familymemberhistory',
  PUT_FAMILY_MEMBER_HISTORY = '/familymemberhistory',
  GET_MEDICATION_STATEMENT = '/medicationstatement',
  PUT_MEDICATION_STATEMENT = '/medicationstatement',
  GET_PROCEDURE = '/procedure',
  PUT_PROCEDURE = '/procedure',
  GET_TELECOM = '/patient/{patientId}/telecom',
  PUT_TELECOM = '/patient/{patientId}/telecom',
  GET_ACCOUNT = '/account',
  DELETE_ACCOUNT = '/account',
  GET_PROFESSIONAL_PROFILE = '/practitioner/me',
  EDIT_PROFESSIONAL_PROFILE = '/practitioner',
  REQUEST_PRACTITIONER_VERIFICATION = '/practitioner/request-verification',
  PUT_ACCOUNT = '/account',
  GET_ADMIN_DASHBOARD = '/report/dashboard',
  GET_PRACTITIONER_PROFILE = '/practitioner/gateway/{practitionerId}',
  GET_PRACTITIONER_PROFILES = '/practitioner/profiles',
  VERIFY_PRACTITIONER_PROFILE = '/practitioner/verify/{practitionerId}',
  GET_PRACTITIONER_SPECIALIZATIONS = '/specialization',
  GET_PRACTITIONER_HOSPITALS = '/hospital',
  PUT_PRACTITIONER_PROFILE_PHOTO = '/practitioner/me/photo',
  POST_CONSULTATION_REQUEST = '/servicerequest',
  GET_CONSULTATION_LIST_PENDING_HISTORY = '/servicerequest',
  GET_CONSULTATION_LIST_ONGOING = '/procedure',
  GET_CONSULTATION_DETAIL = '/servicerequest/{serviceRequestId}',
  PATCH_CONSULTATION = '/servicerequest/{serviceRequestId}/status',
  ACCEPT_CONSULTATION = '/procedure',
  END_CONSULTATION = '/procedure/{procedureId}/status',
  POST_MEDICAL_CERTIFICATE = '/diagnosticreport',
  POST_PRESCRIPTION = '/medicationrequest',
  GET_MEDICAL_CERTIFICATE = '/diagnosticreport/{diagnosticReportId}',
  GET_PRESCRIPTION = '/medicationrequest/group',
  GET_LABORATORY_REQUEST = '/servicerequest/{serviceRequestId}',
  GET_MEDICAL_CERTIFICATES = '/diagnosticreport',
  GET_PRESCRIPTIONS = '/medicationrequest',
  GET_LABORATORY_REQUESTS = '/servicerequest',
  PUT_MEDICAL_CERTIFICATE = '/diagnosticreport/{diagnosticReportId}',
  PUT_PRESCRIPTION = '/medicationrequest',
  PUT_LABORATORY_REQUEST = '/servicerequest/{serviceRequestId}',
  GET_PROCEDURE_DETAIL = '/procedure/{procedureId}',
  CONVERSATION_ACCESS_TOKEN = '/conversation/accesstoken',
  GET_ALL_MEDICAL_ATTACHMENTS = '/medicalattachment/gateway',
  GET_CONSULTATION_DASHBOARD = '/report/dashboard/practitioner',
  POST_INVOICE = '/invoice',
  GET_INVOICE = '/invoice',
  POST_PAYMENT_NOTICE = '/paymentinvoice',
  POST_TEMPORARY_TOKEN = '/invoice/{invoiceId}/token',
  POST_VIDEO_CALL_TOKEN = '/video/room/{roomId}/accesstoken',
  PUT_VIDEO_CALL_ROOM = '/video/room/{roomId}',
  GET_VIDEO_CALL_ROOM = '/video/room/{roomId}',
  PATCH_VIDEO_CALL_ROOM = '/video/room/{roomId}',
  ATTACH_MEDIA_TO_CONVERSATION = '/media',
  GET_ATTACHMENT_MEDIA = '/media/{mediaId}',
  GET_ATTACHMENT_MEDIA_URL = '/media/{mediaId}/attachment/url',
  GET_NOTIFICATIONS = '/notification',
  POST_PUSH_NOTIFICATION = '/firebasecloud/messaging/notification',
  GET_ACCOUNT_DELETION = '/accountdeletion',
  DELETE_ACCOUNT_DELETION = '/accountdeletion',
  POST_FIREBASE_TOKEN = '/firebasetoken',
  POST_VIDEO_CALL_NOTIFICATION = '/video/room/callnotification',
}
