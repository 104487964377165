import React from 'react';
import {StyleSheet, View, TouchableWithoutFeedback} from 'react-native';

import {IconName, SVGIcon} from '../../components';

type HeaderRightIconName = Extract<
  IconName,
  'trash' | 'download-down' | 'search-white-80'
>;

interface HeaderIconRightProps {
  onClick?: () => void;
  iconName?: HeaderRightIconName;
}

const HeaderIconRight = (props: HeaderIconRightProps) => {
  return (
    <View style={mobileStyles.iconsContainer}>
      <TouchableWithoutFeedback onPress={props.onClick}>
        <View style={mobileStyles.iconContainer}>
          {props.iconName ? (
            <SVGIcon name={props.iconName} size={20} />
          ) : (
            <SVGIcon name="trash" size={20} />
          )}
        </View>
      </TouchableWithoutFeedback>
    </View>
  );
};

export default HeaderIconRight;

const mobileStyles = StyleSheet.create({
  iconsContainer: {
    flexDirection: 'row',
    paddingHorizontal: 12,
  },
  iconContainer: {
    paddingHorizontal: 8,
  },
});
