import {TAttachmentType} from './medical-attachment.types';

export interface ConversationAccessTokenReqData {
  subject?: string;
}

export interface UploadMediaReqBody {
  content: any | null;
  subject: string;
  conversationId: string;
  type: TAttachmentType;
}

export interface GetMediaReqBody {
  mediaId: string;
  subject?: string;
}

export interface AttachmentResponse {
  attachmentId: string;
  contentType: string;
  url: string;
  title: string;
}

export interface GetMediaResBody {
  mediaId: string;
  basedOn: string;
  content: AttachmentResponse;
  operator: string;
}

export interface GetMediaUrlResBody {
  url: string;
}

export enum CodedConversationMessages {
  VIDEO_CALL_ACCEPT = 'HC_VIDEO_CALL_ACCEPT',
  VIDEO_CALL_TRIGGER = 'HC_VIDEO_CALL_TRIGGER',
  CONSULTATION_APPROVED = 'HC_CONSULTATION_APPROVED',
  CONSULTATION_PAID = 'HC_CONSULTATION_PAID',
}
