import {IconName} from '../../../components';

import {NavigationKey} from '../../../navigations/config';

export const menuWidth = {
  expanded: 320,
  compressed: 120,
};

interface ISideMenuItem {
  iconName: IconName;
  label: string;
  navigationKey: NavigationKey;
}

export const adminSideMenuItemGroup: ISideMenuItem[][] = [
  [
    {
      iconName: 'home',
      label: 'Dashboard',
      navigationKey: NavigationKey.ADMIN_LANDING,
    },
    {
      iconName: 'medical-specialist-cross',
      label: 'Doctor Accounts',
      navigationKey: NavigationKey.ADMIN_DOCTORS,
    },
    {
      iconName: 'user',
      label: 'Patient Accounts',
      navigationKey: NavigationKey.ADMIN_PATIENTS_LST,
    },
  ],
  [
    {
      iconName: 'lock',
      label: 'Archived',
      navigationKey: NavigationKey.ADMIN_DELETED_PROFILES,
    },
  ],
];

export const doctorSideMenuItemGroup: ISideMenuItem[][] = [
  [
    {
      iconName: 'home',
      label: 'Dashboard',
      navigationKey: NavigationKey.DOCTOR_LANDING_STACK,
    },
    {
      iconName: 'medical-specialist-cross',
      label: 'Professional Profile',
      navigationKey: NavigationKey.DOCTOR_PROFILE_STACK,
    },
    {
      iconName: 'notification',
      label: 'Notifications',
      navigationKey: NavigationKey.DOCTOR_NOTIFICATION_STACK,
    },
    {
      iconName: 'email',
      label: 'Consultations',
      navigationKey: NavigationKey.DOCTOR_CONSULTATION_STACK,
    },
  ],
  [
    {
      iconName: 'information',
      label: 'Terms & Conditions',
      navigationKey: NavigationKey.DOCTOR_TANDC_STACK,
    },
    {
      iconName: 'faq',
      label: 'Help',
      navigationKey: NavigationKey.DOCTOR_HELP_STACK,
    },
    {
      iconName: 'download-left',
      label: 'Logout',
      navigationKey: NavigationKey.LOGOUT,
    },
  ],
];

export const patientSideMenuItemGroup: ISideMenuItem[][] = [
  [
    {
      iconName: 'home',
      label: 'Dashboard',
      navigationKey: NavigationKey.PATIENT_LANDING_STACK,
    },
    {
      iconName: 'medical-specialist-cross',
      label: 'Profiles',
      navigationKey: NavigationKey.PATIENT_PROFILE_STACK,
    },
    {
      iconName: 'notification',
      label: 'Notifications',
      navigationKey: NavigationKey.PATIENT_NOTIFICATION_STACK,
    },
    {
      iconName: 'email',
      label: 'Consultations',
      navigationKey: NavigationKey.PATIENT_CONSULTATION_STACK,
    },
  ],
  [
    {
      iconName: 'information',
      label: 'Terms & Conditions',
      navigationKey: NavigationKey.PATIENT_TANDC_STACK,
    },
    {
      iconName: 'faq',
      label: 'Help',
      navigationKey: NavigationKey.PATIENT_HELP_STACK,
    },
    {
      iconName: 'download-left',
      label: 'Logout',
      navigationKey: NavigationKey.LOGOUT,
    },
  ],
];
