import React, {memo} from 'react';
import {View} from 'react-native';

import {Divider} from 'rne-netzon';
import {RNEText} from '../../components';

import {
  Menu,
  MenuOptions,
  MenuOption,
  MenuTrigger,
} from 'react-native-popup-menu';

import {
  popoverOptionStyles,
  popoverDividerStyle,
  PopoverItemKey,
  medicalAttachmentPopoverItems,
} from './data/popoverItems.data';
import {colorPalette} from '../../../core/config/color.config';

interface MedicalAttachmentPopoverProps {
  onMenuItemClick?: (itemKey: PopoverItemKey) => void;
  children: React.ReactNode;
}

const MedicalAttachmentPopover = (props: MedicalAttachmentPopoverProps) => {
  const onItemClick = (itemKey: PopoverItemKey) => {
    if (props.onMenuItemClick) {
      props.onMenuItemClick(itemKey);
    }
  };

  return (
    <Menu rendererProps={{preferredPlacement: 'top'}}>
      <MenuTrigger>{props.children}</MenuTrigger>
      <MenuOptions customStyles={overridedPopoverOptionsStyles}>
        {medicalAttachmentPopoverItems.map((item, index) => (
          <View key={index}>
            {index > 0 && <Divider style={popoverDividerStyle} />}

            <MenuOption
              customStyles={popoverOptionStyles}
              onSelect={() => onItemClick(item.itemKey)}>
              <RNEText label={item.label} variant="button" weight="600" />
            </MenuOption>
          </View>
        ))}
      </MenuOptions>
    </Menu>
  );
};

const overridedPopoverOptionsStyles = {
  optionsContainer: {
    marginTop: -44,
    backgroundColor: colorPalette.white,
    paddingHorizontal: 24,
    paddingVertical: 16,
    borderRadius: 16,
    width: 220,
  },
  optionsWrapper: {},
  optionWrapper: {},
  optionTouchable: {
    activeOpacity: 70,
  },
};

export default memo(MedicalAttachmentPopover);
