import React, {useEffect, useState} from 'react';
import {View, Platform, ScrollView, Image} from 'react-native';
import {makeStyles, Overlay} from 'rne-netzon';
import {colorPalette} from '../../../core/config/color.config';

import {useNavigation} from '@react-navigation/native';
import {NavigationKey} from '../../navigations/config';

import {RNEText, SVGIcon, RNEButton, IconName} from '../../components';

import {
  useCancelAccountDeletionRequest,
  useGetAccount,
  useGetDeletionRequestStatus,
} from '../../server/react-query/useAccount';
import {PractitionerStatus} from '../../server/types/practitioner-status.types';

import {countdownInDays, formatDateString} from '../../utils/date.utils';
import {AccountDeletionModal} from '../../views';

const DoctorViewAccountScreen = () => {
  const navigation = useNavigation();
  const webStyles = useWebStyles();
  const mobileStyles = useMobileStyles();
  const sharedStyles = useSharedStyles();
  const {status, data, isFetching} = useGetAccount();
  const {data: accountDeletionRequest, isError} = useGetDeletionRequestStatus();

  const cancelDeletionRequestMutation = useCancelAccountDeletionRequest();

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const [statusIcon, setStatusIcon] = useState<IconName>('profile-complete');
  const [iconSize, setIconSize] = useState<number>(
    Platform.OS === 'web' ? 36 : 24,
  );

  const cancelDeletionRequest = async () => {
    try {
      await cancelDeletionRequestMutation.mutateAsync();
    } catch (err) {
      console.log(err);
    }
  };

  const goToEditProfile = () => {
    navigation.navigate(NavigationKey.DOCTOR_ACCOUNT_EDIT);
  };

  useEffect(() => {
    switch (data?.practitionerStatus) {
      case PractitionerStatus.VERIFIED:
        setStatusIcon('profile-complete');
        setIconSize(Platform.OS === 'web' ? 36 : 24);
        break;

      case PractitionerStatus.UNVERIFIED:
        setStatusIcon('close-gray-70');
        setIconSize(24);
        break;

      case PractitionerStatus.DECLINED:
        setStatusIcon('request-denied');
        setIconSize(Platform.OS === 'web' ? 16 : 12);
        break;

      case PractitionerStatus.PENDING:
        setStatusIcon('request-cancelled');
        setIconSize(Platform.OS === 'web' ? 16 : 12);
        break;

      default:
        break;
    }
  }, [data?.practitionerStatus]);

  return (
    <>
      <Overlay
        isVisible={showDeleteDialog}
        overlayStyle={
          Platform.OS === 'web'
            ? webStyles.overlayContainerWeb
            : mobileStyles.overlayContainerMobile
        }>
        <AccountDeletionModal
          onCancel={() => setShowDeleteDialog(false)}
          accountOwner={true}
          forDoctor={true}
        />
      </Overlay>
      {Platform.OS === 'web' ? (
        <ScrollView>
          <View style={webStyles.mainContainer}>
            <View style={webStyles.topBackground}>
              <View style={webStyles.titleContainer}>
                <RNEText label="Dashboard > " variant="body" color="white" />
                <RNEText
                  label="My Account Details"
                  variant="body"
                  color="white"
                  weight="bold"
                />
              </View>
              <View style={webStyles.headerContainer}>
                <View style={webStyles.userIcon}>
                  {data?.photo ? (
                    <Image
                      style={webStyles.imageWrapper}
                      source={{
                        uri: data.photo.url,
                      }}
                    />
                  ) : (
                    <SVGIcon name="user-white" size={68} />
                  )}
                  <View style={webStyles.statusIndicator}>
                    <SVGIcon name={statusIcon} size={iconSize} />
                  </View>
                </View>
                <View style={webStyles.rightHeaderGroup}>
                  <RNEText
                    label="My Account Details"
                    variant="header"
                    color="white"
                    weight="bold"
                  />

                  <View style={webStyles.dateActionsGroup}>
                    <View style={webStyles.dateContainer}>
                      {data!.modifiedOn && (
                        <RNEText
                          label={`Date Updated: ${formatDateString(
                            data!.modifiedOn,
                          )}`}
                          variant="body"
                          color="white"
                        />
                      )}
                    </View>

                    <View style={webStyles.actionWrapper}>
                      <View>
                        <RNEButton
                          title="Edit Details"
                          color="secondary"
                          style={webStyles.buttonStyle}
                          onPress={goToEditProfile}
                        />
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>

            <View style={webStyles.formContainer}>
              <View style={webStyles.formTitleContainer}>
                <RNEText
                  label="Account Details"
                  variant="body"
                  color="black"
                  weight="bold"
                />
                <RNEButton
                  height={20}
                  loadingProps={loadingPropsStyle}
                  loading={isFetching}
                />
              </View>

              <View style={webStyles.labelGroupContainer}>
                <View style={webStyles.rowGroupContainer}>
                  <View style={webStyles.infoContainer}>
                    <View style={webStyles.iconContainer}>
                      <SVGIcon name="email" size={28} />
                    </View>
                    <RNEText
                      label={data!.email}
                      variant="body"
                      color="black"
                      numberOfLines={1}
                    />
                  </View>

                  <View style={webStyles.infoContainer}>
                    <View style={webStyles.iconContainer}>
                      <SVGIcon name="map" size={28} />
                    </View>
                    <RNEText
                      label={data!.location ? data!.location : 'N/A'}
                      variant="body"
                      color="black"
                      numberOfLines={1}
                    />
                  </View>

                  <View style={webStyles.infoContainer}>
                    <View style={webStyles.iconContainer}>
                      <SVGIcon name="call-27" size={28} />
                    </View>
                    <RNEText
                      label={data!.contactNumber ? data!.contactNumber : 'N/A'}
                      variant="body"
                      color="black"
                      numberOfLines={1}
                    />
                  </View>
                </View>

                <View style={webStyles.rowGroupContainer}>
                  <View style={webStyles.infoContainer}>
                    <View style={webStyles.iconContainer}>
                      <SVGIcon name="lock" size={28} />
                    </View>
                    <RNEText label="* * * * * *" variant="body" color="black" />
                  </View>

                  <View style={webStyles.infoContainer} />
                  <View style={webStyles.infoContainer} />
                </View>
              </View>

              <View style={webStyles.actionContainer}>
                {!isError && accountDeletionRequest ? (
                  <>
                    <RNEButton
                      title="Cancel Deletion"
                      color="warning"
                      style={webStyles.buttonStyle}
                      onPress={cancelDeletionRequest}
                      loading={cancelDeletionRequestMutation.isLoading}
                    />
                    <View style={sharedStyles.subtitleStyle}>
                      <RNEText
                        label={`Your account will be permanently deleted in ${countdownInDays(
                          accountDeletionRequest.deletionDate,
                          30,
                        )} days.`}
                        variant="button"
                        color="error"
                      />
                    </View>
                  </>
                ) : (
                  <RNEButton
                    title="Delete Profile"
                    color="error"
                    style={webStyles.buttonStyle}
                    onPress={() => setShowDeleteDialog(true)}
                  />
                )}
              </View>
            </View>
          </View>
        </ScrollView>
      ) : (
        <ScrollView>
          <View style={mobileStyles.content}>
            <View style={mobileStyles.header}>
              <View style={mobileStyles.userIcon}>
                {data?.photo ? (
                  <Image
                    style={mobileStyles.imageWrapper}
                    source={{
                      uri: data.photo.url,
                    }}
                  />
                ) : (
                  <SVGIcon name="user-white" size={68} />
                )}
                <View style={mobileStyles.statusIndicator}>
                  <SVGIcon name={statusIcon} size={iconSize} />
                </View>
              </View>

              <RNEText
                label="My Account Details"
                variant="header"
                color="white"
                weight="bold"
                align="center"
              />
              <View style={mobileStyles.dateContainer}>
                {data!.modifiedOn && (
                  <RNEText
                    label={`Date Updated: ${formatDateString(
                      data!.modifiedOn,
                    )}`}
                    variant="body"
                    color="white"
                    align="center"
                  />
                )}
              </View>
            </View>

            <View style={mobileStyles.formContainer}>
              <View style={mobileStyles.formTitleContainer}>
                <RNEText
                  label="Account Details"
                  variant="title"
                  color="accent"
                  weight="bold"
                />
                <View style={mobileStyles.spinnerContainer}>
                  <RNEButton
                    height={32}
                    loadingProps={loadingPropsStyle}
                    loading={isFetching}
                  />
                </View>
              </View>

              <View style={mobileStyles.sectionContainer}>
                <View style={mobileStyles.infoContainer}>
                  <View style={mobileStyles.iconContainer}>
                    <SVGIcon name="email" size={28} />
                  </View>
                  <RNEText label={data!.email} variant="body" color="black" />
                </View>

                <View style={mobileStyles.infoContainer}>
                  <View style={mobileStyles.iconContainer}>
                    <SVGIcon name="lock" size={28} />
                  </View>
                  <RNEText label="* * * * * *" variant="body" color="black" />
                </View>

                <View style={mobileStyles.infoContainer}>
                  <View style={mobileStyles.iconContainer}>
                    <SVGIcon name="map" size={28} />
                  </View>
                  <RNEText
                    label={data!.location ? data!.location : 'N/A'}
                    variant="body"
                    color="black"
                  />
                </View>

                <View style={mobileStyles.infoContainer}>
                  <View style={mobileStyles.iconContainer}>
                    <SVGIcon name="call-27" size={28} />
                  </View>
                  <RNEText
                    label={data!.contactNumber ? data!.contactNumber : 'N/A'}
                    variant="body"
                    color="black"
                  />
                </View>
              </View>

              <View style={mobileStyles.buttonsContainer}>
                <RNEButton
                  title="Edit Details"
                  color="primary"
                  variant="outlined"
                  onPress={goToEditProfile}
                />
                {!isError && accountDeletionRequest ? (
                  <RNEButton
                    title="Cancel Deletion"
                    color="warning"
                    onPress={cancelDeletionRequest}
                    loading={cancelDeletionRequestMutation.isLoading}
                  />
                ) : (
                  <RNEButton
                    title="Delete Profile"
                    color="error"
                    onPress={() => setShowDeleteDialog(true)}
                  />
                )}
              </View>
              {!isError && accountDeletionRequest && (
                <View style={sharedStyles.subtitleStyle}>
                  <RNEText
                    label={`Your account will be permanently deleted in ${countdownInDays(
                      accountDeletionRequest.deletionDate,
                      30,
                    )} days.`}
                    variant="button"
                    color="error"
                    align="center"
                  />
                </View>
              )}
            </View>
          </View>
        </ScrollView>
      )}
    </>
  );
};

export default DoctorViewAccountScreen;

const loadingPropsStyle = {
  color: colorPalette.black,
};

const useWebStyles = makeStyles({
  mainContainer: {
    flex: 1,
    height: '100%',
    width: '100%',
    backgroundColor: '#F6F7FB',
  },
  topBackground: {
    backgroundColor: colorPalette.purple,
    borderBottomRightRadius: 50,
    height: 420,
  },
  titleContainer: {
    flexDirection: 'row',
    marginHorizontal: 100,
    paddingVertical: 20,
    borderBottomWidth: 1,
    borderBottomColor: colorPalette.white,
  },
  headerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 100,
    paddingRight: 100,
    paddingVertical: 60,
  },
  userIcon: {
    position: 'relative',
    backgroundColor: 'transparent',
    marginRight: 48,
    borderRadius: 100,
    height: 140,
    width: 140,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: colorPalette.white,
  },
  imageWrapper: {
    width: '100%',
    height: '100%',
    borderRadius: 100,
  },
  checkIcon: {
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 160,
    left: 240,
  },
  statusIndicator: {
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    bottom: 4,
    right: 4,
    backgroundColor: colorPalette.white,
    width: 24,
    height: 24,
    borderRadius: 24,
  },
  rightHeaderGroup: {
    flex: 1,
  },
  dateActionsGroup: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  dateContainer: {
    flex: 1,
    height: 48,
    minWidth: 250,
    justifyContent: 'center',
  },
  formContainer: {
    backgroundColor: colorPalette.white,
    borderRadius: 20,
    marginHorizontal: 100,
    top: -100,
    paddingTop: 64,
    paddingBottom: 40,
    paddingHorizontal: 60,
  },
  formTitleContainer: {
    paddingLeft: 24,
    flexDirection: 'row',
  },
  labelGroupContainer: {
    width: '100%',
    marginVertical: 40,
  },
  rowGroupContainer: {
    flexDirection: 'row',
    marginBottom: 40,
  },
  infoContainer: {
    paddingLeft: 24,
    marginBottom: 4,
    flexDirection: 'row',
    alignItems: 'center',
    flex: 0.3,
  },
  iconContainer: {
    width: 60,
  },
  actionWrapper: {
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  buttonContainer: {
    marginRight: 20,
    flex: 2,
    alignItems: 'flex-end',
    alignSelf: 'flex-end',
    marginBottom: 16,
  },
  buttonStyle: {
    width: 180,
  },
  actionContainer: {
    alignItems: 'flex-end',
  },
  overlayContainerWeb: {
    borderRadius: 16,
  },
});

const useMobileStyles = makeStyles({
  content: {
    flex: 1,
    width: '100%',
  },
  header: {
    backgroundColor: colorPalette.purple,
    height: 320,
    borderBottomStartRadius: 230,
    borderBottomEndRadius: 230,
    marginHorizontal: -30,
    position: 'relative',
  },
  userIcon: {
    height: 140,
    width: 140,
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 32,
    marginBottom: 16,
    borderRadius: 100,
    borderWidth: 1,
    borderColor: colorPalette.white,
  },
  statusIndicator: {
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    bottom: 8,
    right: 8,
    backgroundColor: colorPalette.white,
    width: 24,
    height: 24,
    borderRadius: 24,
  },
  imageWrapper: {
    width: '100%',
    height: '100%',
    borderRadius: 100,
  },
  checkIcon: {
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 136,
    left: 248,
  },
  dateContainer: {
    paddingTop: 4,
  },
  formContainer: {
    marginVertical: 60,
    paddingHorizontal: 52,
  },
  infoContainer: {
    marginBottom: 4,
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    marginVertical: 20,
    paddingRight: 40,
  },
  iconContainer: {
    width: 60,
  },
  formTitleContainer: {
    marginBottom: 20,
    flexDirection: 'row',
  },
  sectionContainer: {
    marginBottom: 60,
  },
  buttonsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
  },
  spinnerContainer: {
    width: '100%',
    justifyContent: 'flex-end',
  },
  overlayContainerMobile: {
    borderRadius: 20,
    marginHorizontal: '10%',
  },
});

const useSharedStyles = makeStyles({
  subtitleStyle: {
    marginTop: 8,
  },
});
