import React, {memo, useEffect, useState} from 'react';
import {Platform, View} from 'react-native';
import {TouchableOpacity} from 'react-native-gesture-handler';

import {makeStyles, Overlay} from 'rne-netzon';
import {colorPalette} from '../../../core/config/color.config';

import {useNavigation} from '@react-navigation/native';
import {NavigationKey} from '../../navigations/config';

import {ConsultationRequestResBody} from '../../server/types/consultation.types';
import {AttachmentAttribute} from '../../server/types/medical-attachment.types';

import {RNEIconButton, RNEText, SVGIcon} from '../../components';
import ConsultationPatientMedicalModal from '../modal/ConsultationPatientMedicalModal';
import ViewLaboratoryRequestView from '../medical-attachments/ViewLaboratoryRequestView';
import ViewPrescriptionView from '../medical-attachments/ViewPrescriptionView';
import ViewMedicalCertificateView from '../medical-attachments/ViewMedicalCertificateView';
import ViewImageAttachmentView from '../medical-attachments/ViewImageAttachmentView';
import {
  useDownloadLaboratoryRequest,
  useDownloadMedicalCertificate,
  useDownloadPrescription,
} from '../../server/react-query/useMedicalAttachment';
import {useDownloader} from '../../hooks/downloader/useDownloader';
import {ConsultationSoundClipAttachment} from '..';

interface ConsultationMessageAttachmentProps {
  messageType: 'outgoing' | 'incoming';
  serviceRequest: ConsultationRequestResBody;
  attribute?: AttachmentAttribute;
}

const ConsultationMessageAttachment = (
  props: ConsultationMessageAttachmentProps,
) => {
  const styles = useStyles();
  const navigation = useNavigation();

  const [label, setLabel] = useState('');
  const [downloadable, setDownloadable] = useState(false);
  const [triggerAudioPlayer, setTriggerAudioPlayer] = useState(false);

  const [showAudioPlayerModal, setShowAudioPlayerModal] = useState(false);
  const [showHistoryDialog, setShowHistoryDialog] = useState(false);
  const [
    showMedicalAttachmentDialog,
    setShowMedicalAttachmentDialog,
  ] = useState(false);
  const [
    medicalAttachmentComponent,
    setMedicalAttachmentComponent,
  ] = useState<React.ReactNode>();
  const [
    medicalAttachmentNavKey,
    setMedicalAttachmentNavKey,
  ] = useState<NavigationKey>();

  const {
    data: webLabReqBlob,
    refetch: downloadLabReq,
  } = useDownloadLaboratoryRequest(props.attribute?.Id || '', Platform.OS);

  const {
    data: webMedCertBlob,
    refetch: downloadMedCert,
  } = useDownloadMedicalCertificate(
    props.attribute?.Id || '',
    Platform.OS,
    props.serviceRequest.subject && props.serviceRequest.subject.patientId,
  );

  const {
    data: webPrescriptionBlob,
    refetch: downloadPrescription,
  } = useDownloadPrescription(
    {
      groupId: props.attribute?.Id || '',
    },
    Platform.OS,
    props.serviceRequest.subject && props.serviceRequest.subject.patientId,
  );

  const [
    downloadMedicalAttachmentConfig,
    setDownloadMedicalAttachmentConfig,
  ] = useState({
    web: {
      fileName: 'Laboratory Request',
      data: webLabReqBlob,
      download: downloadLabReq,
    },
    mobile: {
      download: downloadLabReq,
    },
  });

  const {downloadAttachment} = useDownloader(downloadMedicalAttachmentConfig);

  const onViewClick = () => {
    switch (props.attribute?.Type) {
      case 'history':
        handleViewHistory();
        break;

      case 'laboratoryServiceRequest':
        handleViewMedicalAttachment();
        break;

      case 'medicalRequest':
        handleViewMedicalAttachment();
        break;

      case 'diagnosticReport':
        handleViewMedicalAttachment();
        break;

      case 'image':
        handleViewMedicalAttachment();
        break;

      default:
        break;
    }
  };

  const handleViewMedicalAttachment = () => {
    if (Platform.OS === 'web') {
      setShowMedicalAttachmentDialog(true);
    } else {
      if (medicalAttachmentNavKey) {
        navigation.navigate(medicalAttachmentNavKey, {
          id: props.attribute?.Id || '',
          subject:
            props.serviceRequest.subject &&
            props.serviceRequest.subject.patientId,
        });
      }
    }
  };

  const onDownloadClick = () => {
    switch (props.attribute?.Type) {
      case 'history':
        handleViewHistory();
        break;

      case 'laboratoryServiceRequest':
        downloadAttachment();
        break;

      case 'medicalRequest':
        downloadAttachment();
        break;

      case 'diagnosticReport':
        downloadAttachment();
        break;

      default:
        break;
    }
  };

  const onMedicalAttachmentClose = () => {
    setShowMedicalAttachmentDialog(false);
  };

  const handleViewHistory = () => {
    if (Platform.OS === 'web') {
      setShowHistoryDialog(true);
    } else {
      navigation.navigate(NavigationKey.CONSULTATION_PATIENT_MEDICAL_HISTORY, {
        patient: props.serviceRequest.subject,
        status: props.serviceRequest.status,
      });
    }
  };

  const showPlayer = () => {
    if (Platform.OS === 'web') {
      setTriggerAudioPlayer(!triggerAudioPlayer);
    } else {
      setShowAudioPlayerModal(true);
    }
  };

  useEffect(() => {
    if (props.attribute?.Type) {
      console.log(props.attribute?.Type);
      if (props.attribute?.Type === 'media') {
        console.log(props);
      }
    }

    switch (props.attribute?.Type) {
      case 'history':
        setLabel('Medical History');
        setDownloadable(false);
        break;

      case 'laboratoryServiceRequest':
        setLabel('Laboratory Request');
        setDownloadable(true);
        setMedicalAttachmentComponent(
          <ViewLaboratoryRequestView
            id={props.attribute?.Id || ''}
            onClose={onMedicalAttachmentClose}
          />,
        );
        setMedicalAttachmentNavKey(NavigationKey.VIEW_LABORATORY_REQUEST);
        setDownloadMedicalAttachmentConfig({
          web: {
            fileName: 'Laboratory Request',
            data: webLabReqBlob,
            download: downloadLabReq,
          },
          mobile: {
            download: downloadLabReq,
          },
        });
        break;

      case 'medicalRequest':
        setLabel('Prescription');
        setDownloadable(true);
        setMedicalAttachmentComponent(
          <ViewPrescriptionView
            id={props.attribute?.Id || ''}
            subject={
              props.serviceRequest.subject &&
              props.serviceRequest.subject.patientId
            }
            onClose={onMedicalAttachmentClose}
          />,
        );
        setMedicalAttachmentNavKey(NavigationKey.VIEW_PRESCRIPTION);
        setDownloadMedicalAttachmentConfig({
          web: {
            fileName: 'Prescription',
            data: webPrescriptionBlob,
            download: downloadPrescription,
          },
          mobile: {
            download: downloadPrescription,
          },
        });
        break;

      case 'diagnosticReport':
        setLabel('Medical Certificate');
        setDownloadable(true);
        setMedicalAttachmentComponent(
          <ViewMedicalCertificateView
            id={props.attribute?.Id || ''}
            subject={
              props.serviceRequest.subject &&
              props.serviceRequest.subject.patientId
            }
            onClose={onMedicalAttachmentClose}
          />,
        );
        setMedicalAttachmentNavKey(NavigationKey.VIEW_MEDICAL_CERTIFICATE);
        setDownloadMedicalAttachmentConfig({
          web: {
            fileName: 'Medical Certificate',
            data: webMedCertBlob,
            download: downloadMedCert,
          },
          mobile: {
            download: downloadMedCert,
          },
        });
        break;

      case 'media':
        setLabel('Sound Clip');
        setDownloadable(false);
        break;

      case 'soundClip':
        setLabel('Sound Clip');
        setDownloadable(false);
        break;

      case 'image':
        setLabel('Image');
        setDownloadable(false);
        if (Platform.OS === 'web') {
          setMedicalAttachmentComponent(
            <ViewImageAttachmentView
              subject={
                props.serviceRequest.subject &&
                props.serviceRequest.subject.patientId
              }
              mediaId={props.attribute?.Id || ''}
              onClose={onMedicalAttachmentClose}
            />,
          );
        } else {
          setMedicalAttachmentNavKey(NavigationKey.VIEW_IMAGE_ATTACHMENT);
        }
        break;

      default:
        break;
    }
  }, [
    props.attribute,
    props.serviceRequest.subject,
    downloadMedCert,
    downloadPrescription,
    downloadLabReq,
    webMedCertBlob,
    webPrescriptionBlob,
    webLabReqBlob,
    props,
  ]);

  return (
    <>
      <View>
        <View
          style={[
            styles.messageContainer,
            // eslint-disable-next-line react-native/no-inline-styles
            {
              alignItems:
                props.messageType === 'outgoing' ? 'flex-end' : 'flex-start',
            },
          ]}>
          <View
            style={[
              styles.messageWrapper,
              // eslint-disable-next-line react-native/no-inline-styles
              {
                borderTopRightRadius: props.messageType === 'outgoing' ? 0 : 12,
                borderTopLeftRadius: props.messageType === 'outgoing' ? 12 : 0,
                borderColor:
                  props.attribute?.Type === 'history'
                    ? colorPalette.purple
                    : colorPalette.success,
              },
            ]}>
            <View style={styles.labelContainer}>
              <RNEText
                label={label}
                color={
                  props.attribute?.Type === 'history' ? 'purple' : 'success'
                }
                variant="button"
                weight="bold"
              />
            </View>

            <View style={styles.actionsContainer}>
              <View style={[styles.actionContainer]}>
                {props.attribute?.Type === 'media' ||
                props.attribute?.Type === 'soundClip' ? (
                  <>
                    {triggerAudioPlayer ? (
                      <>
                        {Platform.OS === 'web' && (
                          <ConsultationSoundClipAttachment
                            subject={
                              props.serviceRequest.subject &&
                              props.serviceRequest.subject.patientId
                            }
                            mediaId={props.attribute.Id || ''}
                          />
                        )}
                      </>
                    ) : (
                      <View>
                        {Platform.OS === 'web' ? (
                          <TouchableOpacity activeOpacity={0.75}>
                            <RNEIconButton
                              icon={<SVGIcon name="mute-09" size={24} />}
                              type="clear"
                              size={24}
                              containerStyle={containerStyle}
                              onPress={showPlayer}
                            />
                            <View accessibilityRole="button"></View>
                          </TouchableOpacity>
                        ) : (
                          <TouchableOpacity
                            activeOpacity={0.75}
                            onPress={showPlayer}>
                            <SVGIcon name="mute-09" size={20} />
                          </TouchableOpacity>
                        )}
                      </View>
                    )}
                  </>
                ) : (
                  <View>
                    {Platform.OS === 'web' ? (
                      <TouchableOpacity activeOpacity={0.75}>
                        <RNEIconButton
                          icon={<SVGIcon name="view" size={24} />}
                          type="clear"
                          size={24}
                          containerStyle={containerStyle}
                          onPress={onViewClick}
                        />
                        <View accessibilityRole="button"></View>
                      </TouchableOpacity>
                    ) : (
                      <TouchableOpacity
                        activeOpacity={0.75}
                        onPress={onViewClick}>
                        <SVGIcon name="view" size={20} />
                      </TouchableOpacity>
                    )}
                  </View>
                )}
              </View>

              {downloadable && (
                <View
                  style={[
                    styles.actionContainer,
                    styles.actionContainerSibling,
                  ]}>
                  {Platform.OS === 'web' ? (
                    <TouchableOpacity activeOpacity={0.75}>
                      <RNEIconButton
                        icon={<SVGIcon name="download-down" size={24} />}
                        type="clear"
                        size={32}
                        containerStyle={containerStyle}
                        onPress={onDownloadClick}
                      />
                    </TouchableOpacity>
                  ) : (
                    <TouchableOpacity
                      activeOpacity={0.75}
                      onPress={onDownloadClick}>
                      <SVGIcon name="download-down" size={20} />
                    </TouchableOpacity>
                  )}
                </View>
              )}
            </View>
          </View>
        </View>
      </View>

      <Overlay
        isVisible={showHistoryDialog && !!props.serviceRequest}
        overlayStyle={styles.overlayContainer}
        onBackdropPress={() => setShowHistoryDialog(false)}>
        <ConsultationPatientMedicalModal
          patient={props.serviceRequest.subject}
          status={props.serviceRequest.status}
          onClose={() => setShowHistoryDialog(false)}
        />
      </Overlay>

      <Overlay
        isVisible={showMedicalAttachmentDialog && !!props.serviceRequest}
        overlayStyle={styles.overlayContainer}
        onBackdropPress={() => setShowMedicalAttachmentDialog(false)}>
        {medicalAttachmentComponent}
      </Overlay>

      <Overlay
        isVisible={showAudioPlayerModal}
        overlayStyle={styles.overlayAudioPlayerContainer}
        onBackdropPress={() => setShowAudioPlayerModal(false)}>
        <ConsultationSoundClipAttachment
          subject={
            props.serviceRequest.subject &&
            props.serviceRequest.subject.patientId
          }
          mediaId={props.attribute?.Id || ''}
        />
      </Overlay>
    </>
  );
};

const containerStyle = {
  width: 44,
  margin: -10,
};

const useStyles = makeStyles({
  timestamp: {
    alignItems: 'center',
    marginTop: 24,
  },
  messageContainer: {
    marginTop: 16,
  },
  messageWrapper: {
    width: '80%',
    paddingHorizontal: 24,
    paddingVertical: 16,
    borderRadius: 12,
    flexDirection: 'row',
    borderWidth: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: 1,
  },
  labelContainer: {
    flex: 0.6,
  },
  actionsContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flex: 0.4,
  },
  actionContainer: {},
  actionContainerSibling: {
    marginLeft: 16,
  },
  overlayContainer: {
    borderRadius: 20,
  },
  overlayAudioPlayerContainer: {
    width: '90%',
  },
});

export default memo(ConsultationMessageAttachment);
