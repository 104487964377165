import {useMutation, useQuery, useQueryClient} from 'react-query';
import {API, ApiEndpointsEnum} from '../api';

import {convertJsonToParams} from './../utils/query.util';
import {PaginatedData} from './../types/pagination.type';
import {
  PractitionerProfile,
  PractitionerSpecialization,
  PractitionerProfileListParams,
  VerifyPractitionerProfileReqBody,
  GetPractitionerProfileReqBody,
  UploadPractitionerProfilePhotoReqBody,
  RequestVerificationReqResponse,
  EditPractitionerProfileReqUIBody,
  PractitionerHospital,
  EditPractitionerProfileReqBody,
} from './../types/practitioner-profile.types';
import {AxiosRequestConfig} from 'axios';
import {IBaseErrorResponse} from '../types/error.types';

const getPractitionerProfiles = (params: PractitionerProfileListParams) => {
  const queryParams = convertJsonToParams(params as any);

  const url = ApiEndpointsEnum.GET_PRACTITIONER_PROFILES + '?' + queryParams;
  return API.get<PaginatedData<PractitionerProfile>>(url);
};

const useGetPractitionerProfiles = (
  params: PractitionerProfileListParams,
  {enabled = true, cacheTime = 300000},
) => {
  return useQuery(
    ['practitioner-profiles', {status: params.status}],
    () => getPractitionerProfiles(params),
    {
      enabled: enabled,
      cacheTime: cacheTime,
    },
  );
};

const verifyPractitionerProfile = (data: VerifyPractitionerProfileReqBody) => {
  const url = ApiEndpointsEnum.VERIFY_PRACTITIONER_PROFILE.replace(
    '{practitionerId}',
    data.practitionerId,
  );

  const reqBody = {
    actionType: data.actionType,
  };

  return API.put(url, reqBody);
};

const useVerifyPractitionerProfile = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ['verify-practitioner-profile'],
    (data: VerifyPractitionerProfileReqBody) => verifyPractitionerProfile(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['verify-practitioner-profile']);
      },
    },
  );
};

const getPractitionerProfile = (data: GetPractitionerProfileReqBody) => {
  const url = ApiEndpointsEnum.GET_PRACTITIONER_PROFILE.replace(
    '{practitionerId}',
    data.practitionerId,
  );
  return API.get<PractitionerProfile>(url);
};

const useGetPractitionerProfile = (data: GetPractitionerProfileReqBody) => {
  return useQuery(
    ['practitioner-profile', {id: data.practitionerId}],
    () => getPractitionerProfile(data),
    {
      enabled: !!data.practitionerId,
    },
  );
};

const getPractitionerSpecializations = () => {
  const url = ApiEndpointsEnum.GET_PRACTITIONER_SPECIALIZATIONS;
  return API.get<PractitionerSpecialization[]>(url);
};

const useGetPractitionerSpecializations = () => {
  return useQuery(
    ['practitioner-profiles'],
    () => getPractitionerSpecializations(),
    {
      staleTime: Infinity,
    },
  );
};

const getPractitionerHospitals = () => {
  const url = ApiEndpointsEnum.GET_PRACTITIONER_HOSPITALS;
  return API.get<PractitionerHospital[]>(url);
};

const useGetPractitionerHospitals = () => {
  return useQuery(
    ['practitioner-hospitals'],
    () => getPractitionerHospitals(),
    {
      staleTime: Infinity,
    },
  );
};

const getProfessionalProfile = async () => {
  return API.get<PractitionerProfile>(
    ApiEndpointsEnum.GET_PROFESSIONAL_PROFILE,
  );
};

const useGetProfessionalProfile = () => {
  const queryClient = useQueryClient();

  return useQuery<PractitionerProfile, IBaseErrorResponse>(
    ['get-professional-profile'],
    () => getProfessionalProfile(),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['get-professional-profile'], {
          refetchActive: false,
        });
      },
      onError: err => {
        console.log(err);
      },
    },
  );
};

const editProfessionalProfile = async (
  reqBody: EditPractitionerProfileReqBody,
) => {
  return API.put<PractitionerProfile>(
    ApiEndpointsEnum.EDIT_PROFESSIONAL_PROFILE,
    reqBody,
  );
};

const useEditDoctorProfile = (reqBody: EditPractitionerProfileReqUIBody) => {
  const queryClient = useQueryClient();

  const moldedReqBody: EditPractitionerProfileReqBody = {
    firstName: reqBody.firstName,
    middleName: reqBody.middleName,
    lastName: reqBody.lastName,
    specialty: reqBody.specialty ? [reqBody.specialty] : [],
    hospital: null,
    city: reqBody.city,
    licenseNumber: reqBody.licenseNumber,
    birthdate: reqBody.birthdate,
    consultationFee: reqBody.consultationFee,
    handledCases: reqBody.handledCases,
    bankName: reqBody.bankName,
    bankAccount: reqBody.bankAccount,
  };

  return useMutation(
    ['edit-professional-profile'],
    () => editProfessionalProfile(moldedReqBody),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['edit-professional-profile']);
        queryClient.fetchQuery(['get-professional-profile']);
        queryClient.fetchQuery(['get-account']);
      },
      onError: err => {
        console.log(err);
      },
    },
  );
};

const requestVerification = () => {
  return API.put<RequestVerificationReqResponse>(
    `${ApiEndpointsEnum.REQUEST_PRACTITIONER_VERIFICATION}`,
  );
};

const useRequestVerification = () => {
  const queryClient = useQueryClient();

  return useMutation(['request-verification'], () => requestVerification(), {
    onSuccess: () => {
      queryClient.invalidateQueries(['request-verification']);
      queryClient.fetchQuery(['get-professional-profile']);
    },
  });
};

const uploadPractitionerProfile = async (
  reqBody: UploadPractitionerProfilePhotoReqBody,
) => {
  const headerAuthorization: any = {
    'Content-Type': 'multipart/form-data',
  };

  const config: AxiosRequestConfig = {
    headers: {
      ...headerAuthorization,
    },
  };

  const formData = new FormData();

  formData.append('image', reqBody.image!);

  return API.put<PractitionerProfile>(
    ApiEndpointsEnum.PUT_PRACTITIONER_PROFILE_PHOTO,
    formData,
    config,
  );
};

const useUploadPractitionerProfile = (
  reqBody: UploadPractitionerProfilePhotoReqBody,
) => {
  const queryClient = useQueryClient();

  return useMutation(
    ['upload-practitioner-profile-photo'],
    () => uploadPractitionerProfile(reqBody),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['upload-practitioner-profile-photo']);
        queryClient.fetchQuery(['get-professional-profile']);
        queryClient.fetchQuery(['get-account']);
      },
    },
  );
};

export {
  useGetPractitionerProfile,
  useGetPractitionerProfiles,
  useVerifyPractitionerProfile,
  useGetPractitionerSpecializations,
  useGetProfessionalProfile,
  useEditDoctorProfile,
  useUploadPractitionerProfile,
  useRequestVerification,
  useGetPractitionerHospitals,
};
