import React, {memo} from 'react';
import {View, Text} from 'react-native';

import './ExpandableTextarea.css';

import TextareaAutosize from 'react-textarea-autosize';

interface ExpandableTextareaProps {
  maxRows?: number;
  minRows?: number;
  placeholder?: string;
  value?: string | ReadonlyArray<string> | number;
  onChangeText: (value: string) => void;
}

const ExpandableTextarea = ({
  maxRows = 1,
  minRows = 1,
  placeholder = '',
  value,
  onChangeText,
}: ExpandableTextareaProps) => {
  return (
    <View>
      <TextareaAutosize
        value={value}
        placeholder={placeholder}
        maxRows={maxRows}
        minRows={minRows}
        className="expandable-textarea"
        onChange={ev => {
          onChangeText(ev.target.value);
        }}
      />
    </View>
  );
};

export default memo(ExpandableTextarea);
