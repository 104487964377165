import React, {memo} from 'react';
import {Dimensions, Platform, View} from 'react-native';

import {makeStyles} from 'rne-netzon';

import {SVGIcon, RNEButton, RNEText} from '../../components';

interface DeniedResendVerificationModalProps {
  onCancel?: () => void;
  onConfirm?: () => void;
}

function DeniedResendVerificationModal(
  props: DeniedResendVerificationModalProps,
) {
  const rneStyles = useStyles();

  return (
    <View style={rneStyles.root}>
      <RNEText
        label="Account verification request denied!"
        variant="header"
        align="center"
        weight="600"
        color="accent"
      />
      <View style={rneStyles.description}>
        <RNEText
          label="Please update your professional details first."
          variant="body"
          align="center"
        />
      </View>

      <View style={rneStyles.actionContainer}>
        <View style={rneStyles.buttonContainer}>
          <RNEButton
            title="Cancel"
            color="secondary"
            onPress={props.onCancel}
            variant="outlined"
            style={rneStyles.buttonStyle}
          />
        </View>

        <View style={[rneStyles.buttonContainer, rneStyles.buttonSibling]}>
          <RNEButton
            title="Edit Profile"
            color="secondary"
            onPress={props.onConfirm}
            style={rneStyles.buttonStyle}
          />
        </View>
      </View>
    </View>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: Platform.OS === 'web' ? 480 : '85%',
    paddingVertical: Platform.OS === 'web' ? 32 : 16,
    paddingHorizontal: Platform.OS === 'web' ? 48 : 16,
  },
  description: {
    marginTop: 20,
  },
  actionContainer: {
    marginTop: 48,
    justifyContent: 'center',
    flexDirection: 'row',
  },
  buttonContainer: {
    flex: 1,
  },
  buttonStyle: {
    width: Platform.OS === 'web' ? 160 : '100%',
  },
  buttonSibling: {
    marginLeft: 16,
  },
}));

export default memo(DeniedResendVerificationModal);
