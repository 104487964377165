import {useNavigation} from '@react-navigation/core';
import React, {memo, useState} from 'react';
import {Platform, View} from 'react-native';

import {makeStyles} from 'rne-netzon';
import {AccountDeletionConfirmation} from '..';

import {RNEButton, RNEText} from '../../components';
import {NavigationKey} from '../../navigations/config';
import DownloadMedicalRecords from './DownloadMedicalRecords';

interface AccountDeletionRequestProps {
  onCancel: () => void;
  onDoctorDelete?: () => void;
  patientId?: string;
  forDoctor?: boolean;
  accountOwner?: boolean;
}

function AccountDeletionRequest(props: AccountDeletionRequestProps) {
  const rneStyles = useStyles();
  const navigation = useNavigation();

  // const [showDownloadMedRecs, setShowDownloadMedRecs] = useState(false);
  const [proceedDeletion, setProceedDeletion] = useState(false);

  const nextScreen = () => {
    if (Platform.OS === 'web') {
      setProceedDeletion(true);
    } else {
      props.onCancel();
      navigation.navigate(NavigationKey.PATIENT_PROFILE_DELETE, {
        patientId: props.patientId,
        accountOwner: props.accountOwner,
        forDoctor: props.forDoctor,
      });
    }
  };

  return (
    <View style={rneStyles.root}>
      {!proceedDeletion ? (
        <>
          <RNEText
            label={
              props.accountOwner || props.forDoctor
                ? 'Are you sure you want to Delete your Account?'
                : 'Are you sure you want to delete this patient profile?'
            }
            variant="header"
            align="center"
            weight="600"
            color="accent"
          />

          <View style={rneStyles.subtitleContainer}>
            {!props.forDoctor ? (
              <RNEText
                label={
                  props.accountOwner
                    ? 'Deleting your account also deletes the other sub profiles you have under your account.'
                    : 'Deleting this patient profile means that this profile can no longer be accessed nor retrieved by the patient, the practitioners, or any House Call Administrators.'
                }
                variant="body"
                align="center"
              />
            ) : (
              <RNEText
                label={`You will lose all your profile information when you delete your account.\n\nOnce you submit your account for deletion, you have 30 days to
                reactivate your account. After 30 days, you won't be able to retrieve any of the content or information you've added.`}
                variant="body"
                align="center"
              />
            )}
          </View>

          <View style={rneStyles.actionContainer}>
            <View style={rneStyles.buttonContainer}>
              <RNEButton
                title="Cancel"
                color="secondary"
                onPress={props.onCancel}
                style={rneStyles.buttonStyle}
                variant="outlined"
              />
            </View>
            <RNEButton
              title="Delete Anyway"
              color="secondary"
              onPress={nextScreen}
              style={rneStyles.buttonStyle}
            />
          </View>
        </>
      ) : (
        <AccountDeletionConfirmation
          onCancel={props.onCancel}
          accountOwner={props.accountOwner}
          patientId={props.patientId}
          forDoctor={props.forDoctor}
        />
      )}
      {/* {showDownloadMedRecs && (
        <DownloadMedicalRecords onCancel={props.onCancel} />
      )} */}
    </View>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    justifyContent: 'center',
  },
  subtitleContainer: {
    marginTop: 40,
  },
  actionContainer: {
    marginTop: 60,
    justifyContent: 'center',
    flexDirection: 'row',
  },
  buttonContainer: {
    marginRight: 16,
  },
  buttonStyle: {
    width: 160,
  },
}));

export default memo(AccountDeletionRequest);
