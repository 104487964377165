import React, {useState} from 'react';
import {View, ScrollView} from 'react-native';
import {makeStyles} from 'rne-netzon';

import {colorPalette} from '../../../core/config/color.config';

import {useNavigation} from '@react-navigation/native';
import {NavigationKey} from '../../navigations/config';

import {
  RNEText,
  SVGIcon,
  RNEInput,
  RNEButton,
  ImageSelector,
} from '../../components';

import {useForm, Controller} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  AddPatientProfileReqUIBody,
  UploadPatientProfilePhotoReqBody,
} from '../../server/types/patient-profile.types';
import {
  useAddPatientProfile,
  useUploadPatientProfile,
} from '../../server/react-query/usePatientProfile';
import {useToast} from 'react-native-fast-toast';

import useStores from '../../stores/useStores';
import {parseDateString} from '../../utils/date.utils';

const schema: yup.SchemaOf<AddPatientProfileReqUIBody> = yup.object({
  firstName: yup.string().trim().required('This field is required.'),
  middleName: yup.string().trim().required('This field is required.'),
  lastName: yup.string().trim().required('This field is required.'),
  relationship: yup.string().trim().required('This field is required.'),
  location: yup.string(),
  contactNumber: yup
    .string()
    .trim()
    .matches(/^[\d +-]+$/, {
      message: 'Invalid contact number format',
      excludeEmptyString: true,
    }),
  birthdate: yup
    .string()
    .trim()
    .matches(/^\d{4}-\d{2}-\d{2}$/, {
      message: 'Invalid date format (yyyy-mm-dd)',
      excludeEmptyString: true,
    })
    .test('test-invalid-date', 'Invalid date value', parseDateString),
  telecom: yup.string(),
  allergyIntolerance: yup.string(),
  familyMemberHistory: yup.string(),
  procedure: yup.string(),
  medicationStatement: yup.string(),
  condition: yup.string(),
});
const PatientAddProfileScreen = () => {
  const navigation = useNavigation();
  const webStyles = useWebStyles();
  const {patientProfileStore} = useStores();
  const {
    control,
    handleSubmit,
    getValues,
    reset,
    setValue,
    formState: {errors, isValid},
  } = useForm<AddPatientProfileReqUIBody>({
    mode: 'all',
    defaultValues: {
      firstName: '',
      middleName: '',
      lastName: '',
      relationship: '',
    },
    resolver: yupResolver(schema),
  });

  const [photo, setPhoto] = useState<UploadPatientProfilePhotoReqBody>({
    image: null,
  });

  const [patientId, setPatientId] = useState('');

  const toast = useToast();

  const mutation = useAddPatientProfile(getValues());
  const uploadMutation = useUploadPatientProfile(photo, patientId);

  const addPatientProfile = () => {
    handleSubmit(onSubmit)();
  };

  const onSubmit = async () => {
    if (isValid) {
      try {
        const data = await mutation.mutateAsync();

        if (photo.image) {
          setPatientId(data.patientId);
          await uploadMutation.mutateAsync();
          uploadMutation.reset();
        }

        reset();
        mutation.reset();
        goToProfiles();
      } catch (error) {
        if (error?.data?.error) {
          toast?.show(error?.data?.error, {type: 'danger'});
        }
      }
    }
  };

  const goToProfiles = () => {
    reset();
    navigation.navigate(NavigationKey.PATIENT_PROFILES);
  };

  const getFile = (file: any | null) => {
    setPhoto({
      image: file,
    });
  };

  return (
    <ScrollView>
      <View style={webStyles.mainContainer}>
        <View style={webStyles.topBackground}>
          <View style={webStyles.titleContainer}>
            <RNEText label="Home > " variant="body" color="white" />
            <RNEText
              label="Add New Profile"
              variant="body"
              color="white"
              weight="bold"
            />
          </View>
          <View style={webStyles.headerContainer}>
            <ImageSelector onSelectPhoto={getFile} />
            <RNEText
              label="Add a New profile"
              variant="header"
              color="white"
              weight="bold"
            />
          </View>
        </View>
        <View style={webStyles.formContainer}>
          <View style={webStyles.formTitleContainer}>
            <RNEText
              label="Profile Details"
              variant="body"
              color="black"
              weight="bold"
            />
          </View>

          <View style={webStyles.inputGroupContainer}>
            <View style={webStyles.nameInputGroupContainer}>
              <View style={webStyles.inputFirstNameContainer}>
                <View style={webStyles.inputLabel}>
                  <RNEText
                    label="First Name"
                    variant="button"
                    color="accent"
                    weight="bold"
                  />
                </View>
                <Controller
                  control={control}
                  render={({field: {onChange, onBlur, value}}) => (
                    <RNEInput
                      placeholder="First Name"
                      value={value || ''}
                      onChangeText={text => {
                        onChange(text);
                      }}
                      errorMessage={errors.firstName?.message}
                      variant="shadowed"
                    />
                  )}
                  name="firstName"
                />
              </View>

              <View style={webStyles.inputNameContainer}>
                <View style={webStyles.inputLabel}>
                  <RNEText
                    label="Middle Name"
                    variant="button"
                    color="accent"
                    weight="bold"
                  />
                </View>
                <Controller
                  control={control}
                  render={({field: {onChange, onBlur, value}}) => (
                    <RNEInput
                      placeholder="Middle Name"
                      value={value || ''}
                      onChangeText={text => onChange(text)}
                      errorMessage={errors.middleName?.message}
                      variant="shadowed"
                    />
                  )}
                  name="middleName"
                />
              </View>

              <View style={webStyles.inputNameContainer}>
                <View style={webStyles.inputLabel}>
                  <RNEText
                    label="Last Name"
                    variant="button"
                    color="accent"
                    weight="bold"
                  />
                </View>
                <Controller
                  control={control}
                  render={({field: {onChange, onBlur, value}}) => (
                    <RNEInput
                      placeholder="Last Name"
                      value={value || ''}
                      onChangeText={text => onChange(text)}
                      errorMessage={errors.lastName?.message}
                      variant="shadowed"
                    />
                  )}
                  name="lastName"
                />
              </View>
            </View>

            <View style={webStyles.nameInputGroupContainer}>
              <View style={webStyles.inputFirstNameContainer}>
                <View style={webStyles.inputLabel}>
                  <RNEText
                    label="Birthdate"
                    variant="button"
                    color="accent"
                    weight="bold"
                  />
                </View>
                <Controller
                  control={control}
                  render={({field: {onChange, onBlur, value}}) => (
                    <RNEInput
                      placeholder="Birthdate"
                      leftIcon={<SVGIcon name="calendar" />}
                      value={value || ''}
                      onChangeText={text => {
                        onChange(text);
                      }}
                      errorMessage={errors.birthdate?.message}
                      variant="shadowed"
                    />
                  )}
                  name="birthdate"
                />
              </View>

              <View style={webStyles.inputNameContainer}>
                <View style={webStyles.inputLabel}>
                  <RNEText
                    label="City Address"
                    variant="button"
                    color="accent"
                    weight="bold"
                  />
                </View>
                <Controller
                  control={control}
                  render={({field: {onChange, onBlur, value}}) => (
                    <RNEInput
                      placeholder="City Address"
                      leftIcon={<SVGIcon name="map" />}
                      value={value || ''}
                      onChangeText={text => onChange(text)}
                      errorMessage={errors.location?.message}
                      variant="shadowed"
                    />
                  )}
                  name="location"
                />
              </View>

              <View style={webStyles.inputNameContainer}>
                <View style={webStyles.inputLabel}>
                  <RNEText
                    label="Contact Number"
                    variant="button"
                    color="accent"
                    weight="bold"
                  />
                </View>
                <Controller
                  control={control}
                  render={({field: {onChange, onBlur, value}}) => (
                    <RNEInput
                      placeholder="Contact Number"
                      leftIcon={<SVGIcon name="call-27" />}
                      value={value || ''}
                      onChangeText={text => onChange(text)}
                      errorMessage={errors.contactNumber?.message}
                      variant="shadowed"
                    />
                  )}
                  name="contactNumber"
                />
              </View>
            </View>

            <View style={webStyles.nameInputGroupContainer}>
              <View style={webStyles.inputFirstNameContainer}>
                <View style={webStyles.inputLabel}>
                  <RNEText
                    label="Relationship"
                    variant="button"
                    color="accent"
                    weight="bold"
                  />
                </View>
                <Controller
                  control={control}
                  render={({field: {onChange, onBlur, value}}) => (
                    <RNEInput
                      placeholder="Relationship"
                      leftIcon={<SVGIcon name="user-confirmed" />}
                      value={value || ''}
                      onChangeText={text => {
                        onChange(text);
                      }}
                      errorMessage={errors.relationship?.message}
                      variant="shadowed"
                    />
                  )}
                  name="relationship"
                />
              </View>

              <View style={webStyles.inputNameContainer} />

              <View style={webStyles.inputNameContainer} />
            </View>
          </View>

          <View>
            <View style={webStyles.historyTitleContainer}>
              <RNEText
                label="Medical History"
                variant="body"
                color="black"
                weight="bold"
              />
            </View>

            <View style={webStyles.sectionContainer}>
              <View style={webStyles.sectionTitleContainer}>
                <SVGIcon name="general-practitioner" size={24} />
                <View style={webStyles.sectionTitleText}>
                  <RNEText
                    label="Allergies"
                    variant="body"
                    color="black"
                    weight="bold"
                  />
                </View>
              </View>
              <View style={webStyles.cardContainer}>
                <Controller
                  control={control}
                  render={({field: {onChange, onBlur, value}}) => (
                    <RNEInput
                      placeholder="Allergies"
                      value={value || ''}
                      onChangeText={text => onChange(text)}
                      errorMessage={errors.allergyIntolerance?.message}
                      variant="shadowed"
                      multiline={true}
                      height={48}
                      inputStyle={inputStyle}
                      inputContainerStyle={inputContainerWebStyle}
                    />
                  )}
                  name="allergyIntolerance"
                />
              </View>
            </View>

            <View style={webStyles.sectionContainer}>
              <View style={webStyles.sectionTitleContainer}>
                <SVGIcon name="security" size={24} />
                <View style={webStyles.sectionTitleText}>
                  <RNEText
                    label="Family Condition"
                    variant="body"
                    color="black"
                    weight="bold"
                  />
                </View>
              </View>
              <View style={webStyles.cardContainer}>
                <Controller
                  control={control}
                  render={({field: {onChange, onBlur, value}}) => (
                    <RNEInput
                      placeholder="Family Condition"
                      value={value || ''}
                      onChangeText={text => onChange(text)}
                      errorMessage={errors.familyMemberHistory?.message}
                      variant="shadowed"
                      multiline={true}
                      height={48}
                      inputStyle={inputStyle}
                      inputContainerStyle={inputContainerWebStyle}
                    />
                  )}
                  name="familyMemberHistory"
                />
              </View>
            </View>

            <View style={webStyles.sectionContainer}>
              <View style={webStyles.sectionTitleContainer}>
                <SVGIcon name="medical-specialist-cross" size={24} />
                <View style={webStyles.sectionTitleText}>
                  <RNEText
                    label="Hospitalization"
                    variant="body"
                    color="black"
                    weight="bold"
                  />
                </View>
              </View>
              <View style={webStyles.cardContainer}>
                <Controller
                  control={control}
                  render={({field: {onChange, onBlur, value}}) => (
                    <RNEInput
                      placeholder="Hospitalization"
                      value={value || ''}
                      onChangeText={text => onChange(text)}
                      errorMessage={errors.procedure?.message}
                      variant="shadowed"
                      multiline={true}
                      height={48}
                      inputStyle={inputStyle}
                      inputContainerStyle={inputContainerWebStyle}
                    />
                  )}
                  name="procedure"
                />
              </View>
            </View>

            <View style={webStyles.sectionContainer}>
              <View style={webStyles.sectionTitleContainer}>
                <SVGIcon name="allied-health-professionals" size={24} />
                <View style={webStyles.sectionTitleText}>
                  <RNEText
                    label="Maintenance Medicine"
                    variant="body"
                    color="black"
                    weight="bold"
                  />
                </View>
              </View>
              <View style={webStyles.cardContainer}>
                <Controller
                  control={control}
                  render={({field: {onChange, onBlur, value}}) => (
                    <RNEInput
                      placeholder="Maintenance Medicine"
                      value={value || ''}
                      onChangeText={text => onChange(text)}
                      errorMessage={errors.medicationStatement?.message}
                      variant="shadowed"
                      multiline={true}
                      height={48}
                      inputStyle={inputStyle}
                      inputContainerStyle={inputContainerWebStyle}
                    />
                  )}
                  name="medicationStatement"
                />
              </View>
            </View>

            <View style={webStyles.sectionContainer}>
              <View style={webStyles.sectionTitleContainer}>
                <SVGIcon name="general-practitioner" size={24} />
                <View style={webStyles.sectionTitleText}>
                  <RNEText
                    label="Medical Conditions"
                    variant="body"
                    color="black"
                    weight="bold"
                  />
                </View>
              </View>
              <View style={webStyles.cardContainer}>
                <Controller
                  control={control}
                  render={({field: {onChange, onBlur, value}}) => (
                    <RNEInput
                      placeholder="Medical Conditions"
                      value={value || ''}
                      onChangeText={text => onChange(text)}
                      errorMessage={errors.condition?.message}
                      variant="shadowed"
                      multiline={true}
                      height={48}
                      inputStyle={inputStyle}
                      inputContainerStyle={inputContainerWebStyle}
                    />
                  )}
                  name="condition"
                />
              </View>
            </View>

            <View style={webStyles.sectionContainer}>
              <View style={webStyles.sectionTitleContainer}>
                <SVGIcon name="security" size={24} />
                <View style={webStyles.sectionTitleText}>
                  <RNEText
                    label="Personal and Social"
                    variant="body"
                    color="black"
                    weight="bold"
                  />
                </View>
              </View>
              <View style={webStyles.cardContainer}>
                <Controller
                  control={control}
                  render={({field: {onChange, onBlur, value}}) => (
                    <RNEInput
                      placeholder="Personal and Social"
                      value={value || ''}
                      onChangeText={text => onChange(text)}
                      errorMessage={errors.telecom?.message}
                      variant="shadowed"
                      multiline={true}
                      height={48}
                      inputStyle={inputStyle}
                      inputContainerStyle={inputContainerWebStyle}
                    />
                  )}
                  name="telecom"
                />
              </View>
            </View>
          </View>

          <View style={webStyles.buttonsContainer}>
            <View style={webStyles.buttonContainer}>
              <RNEButton
                title="Cancel"
                color="accent"
                style={webStyles.buttonStyle}
                onPress={goToProfiles}
                variant="outlined"
              />
            </View>
            <RNEButton
              title="Add New Profile"
              color="secondary"
              style={webStyles.buttonStyle}
              onPress={addPatientProfile}
              loading={mutation.isLoading || uploadMutation.isLoading}
            />
          </View>
        </View>
      </View>
    </ScrollView>
  );
};

export default PatientAddProfileScreen;

const inputContainerWebStyle = {
  padding: 20,
};

const inputStyle = {
  lineHeight: 24,
};

const useWebStyles = makeStyles({
  mainContainer: {
    flex: 1,
    height: '100%',
    width: '100%',
    backgroundColor: '#F6F7FB',
  },
  topBackground: {
    backgroundColor: colorPalette.primary,
    borderBottomRightRadius: 50,
    height: 420,
  },
  titleContainer: {
    flexDirection: 'row',
    marginHorizontal: 100,
    paddingVertical: 20,
    borderBottomWidth: 1,
    borderBottomColor: colorPalette.white,
  },
  headerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 200,
    paddingVertical: 60,
  },
  userIcon: {
    backgroundColor: colorPalette.white,
    marginRight: 48,
    borderRadius: 100,
    height: 140,
    width: 140,
    justifyContent: 'center',
    alignItems: 'center',
  },
  cameraIcon: {
    backgroundColor: colorPalette.white,
    borderRadius: 50,
    height: 52,
    width: 52,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 148,
    left: 300,
  },
  formContainer: {
    backgroundColor: colorPalette.white,
    borderRadius: 20,
    marginHorizontal: 100,
    top: -100,
    paddingVertical: 40,
    paddingHorizontal: 60,
  },
  formTitleContainer: {
    paddingLeft: 24,
  },
  inputGroupContainer: {
    width: '100%',
    marginVertical: 40,
  },
  nameInputGroupContainer: {
    flexDirection: 'row',
    marginBottom: 40,
  },
  inputContainer: {
    marginTop: 16,
    flex: 1,
  },
  inputFirstNameContainer: {
    flex: 1,
  },
  inputNameContainer: {
    marginLeft: 24,
    flex: 1,
  },
  inputLabel: {
    paddingLeft: 24,
    marginBottom: 4,
  },
  historyTitleContainer: {
    paddingLeft: 24,
    paddingBottom: 24,
  },
  sectionContainer: {
    marginLeft: 24,
    marginVertical: 20,
  },
  sectionTitleContainer: {
    flexDirection: 'row',
  },
  sectionTitleText: {
    justifyContent: 'center',
    marginLeft: 32,
  },
  cardContainer: {
    marginLeft: -20,
    marginTop: 28,
    width: '100%',
  },
  cardText: {
    width: '100%',
  },
  buttonsContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  buttonContainer: {
    marginRight: 20,
  },
  buttonStyle: {
    width: 180,
  },
});
