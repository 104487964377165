import React, {memo, useEffect, useState} from 'react';
import {View} from 'react-native';

import useStores from '../../stores/useStores';

import {
  ConsultationRequestResBody,
  ConsultationStatusEnum,
} from '../../server/types/consultation.types';

import ConsultationDetailPending from './ConsultationDetailPending';
import ConsultationDetailOngoing from './ConsultationDetailOngoing';
import ConsultationDetailCompleted from './ConsultationDetailCompleted';
import ConsultationDetailCancelled from './ConsultationDetailCancelled';
import ConsultationDetailDeclined from './ConsultationDetailDeclined';

interface ConsultationDetailEntryProps {
  serviceRequest: ConsultationRequestResBody;
}

const ConsultationDetailEntry = (props: ConsultationDetailEntryProps) => {
  const {authStore} = useStores();

  const [statusByPatient, setStatusByPatient] = useState(false);

  useEffect(() => {
    switch (authStore.userRole) {
      case 'patient':
        setStatusByPatient(
          authStore.auth?.user.userId === props.serviceRequest?.modifiedBy,
        );
        break;

      case 'practitioner':
        setStatusByPatient(
          !(authStore.auth?.user.userId === props.serviceRequest?.modifiedBy),
        );
        break;

      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authStore.userRole, props.serviceRequest]);

  return (
    <View>
      {props.serviceRequest.status === ConsultationStatusEnum.PENDING && (
        <ConsultationDetailPending serviceRequest={props.serviceRequest} />
      )}

      {props.serviceRequest.status === ConsultationStatusEnum.ONGOING && (
        <ConsultationDetailOngoing serviceRequest={props.serviceRequest} />
      )}

      {props.serviceRequest.status === ConsultationStatusEnum.COMPLETED && (
        <ConsultationDetailCompleted serviceRequest={props.serviceRequest} />
      )}

      {statusByPatient &&
        props.serviceRequest.status === ConsultationStatusEnum.CANCELLED && (
          <ConsultationDetailCancelled serviceRequest={props.serviceRequest} />
        )}

      {!statusByPatient &&
        props.serviceRequest.status === ConsultationStatusEnum.CANCELLED && (
          <ConsultationDetailDeclined serviceRequest={props.serviceRequest} />
        )}
    </View>
  );
};

export default memo(ConsultationDetailEntry);
