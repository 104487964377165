export interface NotificationsResBody {
  notificationId: string;
  patientId?: string;
  practitionerId?: string;
  type: NotificationType;
  content: any;
  createdOn: string;
}

export interface NotificationsReqParams {
  pageNumber?: number;
  pageSize?: number;
  patientId?: string;
}

export enum NotificationType {
  CONSULTATION_REQUEST = 'consultationRequest',
  CONSULTATION_CANCELLED = 'consultationCancelled',
  CONSULTATION_ACCEPTED = 'consultationAccepted',
  CONSULTATION_DECLINED = 'consultationDeclined',
  CONSULTATION_PAID = 'consultationPaid',
  CONSULTATION_COMPLETED = 'consultationCompleted',
  PRACTITIONER_PROFILE_PENDING = 'pendingPractitionerProfile',
  PRACTITIONER_PROFILE_DECLINED = 'practitionerProfileDeclined',
  PRACTITIONER_PROFILE_VERIFIED = 'practitionerProfileVerified',
  ATTACHED_DIAGNOSTIC_REPORT = 'diagnosticReportAttached',
  ATTACHED_MEDICATION_REQUEST = 'medicationRequestAttached',
  ATTACHED_LABORATORY_REQUEST = 'laboratoryRequestAttached',
  MESSAGE_ADDED = 'messageAdded',
}
