import React, {useEffect, useState, useLayoutEffect, useRef} from 'react';
import {View, Platform, KeyboardAvoidingView} from 'react-native';

import {useNavigation, useRoute} from '@react-navigation/native';
import {NavigationKey} from '../../navigations/config';

import {RNEButton, RNEText} from '../../components';
import {colorPalette} from '../../../core/config/color.config';
import {makeStyles, Overlay} from 'rne-netzon';

import useStores from '../../stores/useStores';

import {
  ConsultationAcceptDecline,
  ConsultationCancel,
  ConsultationChat,
  ConsultationDetailEntry,
  ConsultationDetailPlaceholder,
  ConsultationDoctorPopover,
  ConsultationDoctorProfileModal,
  ConsultationEndModal,
  ConsultationPatientMedicalModal,
  ConsultationPatientPopover,
  ConsultationStatus,
  ConsultationHistoryFooterNote,
  ContentScrollView,
  HeaderConsultationDoctor,
  HeaderConsultationDoctorWeb,
  HeaderConsultationPatient,
  HeaderConsultationPatientWeb,
  MedicalAttachmentsListView,
  ConsultationProceedToPayment,
} from '../../views';

import {useGetConsultationDetail} from '../../server/react-query/useConsultation';
import {ConsultationStatusEnum} from '../../server/types/consultation.types';

import useConversation from '../../hooks/conversation/useConversation';

import {PopoverItemKey} from '../../views/popover/data/popoverItems.data';
import MedicalAttachmentsModal from '../../views/modal/MedicalAttachmentsModal';
import {MedicalAttachmentEnum} from '../../views/medical-attachments/data/medicalAttachment';
import {computeAge} from '../../utils/date.utils';
import {useGetAllMedicalAttachments} from '../../server/react-query/useMedicalAttachment';
import EmptyMedicalAttachmentsModal from '../../views/modal/EmptyMedicalAttachmentsModal';
import {ProcedureStatusEnum} from '../../server/types/procedure.types';
import useVideoCall from '../../hooks/video-call/useVideoCall';
import {REACT_APP_BASE_WEB_URL} from '../../server/api/api.config';

const ConsultationDetailScreen = () => {
  const route = useRoute();
  const navigation = useNavigation();
  const {authStore, patientProfileStore, doctorProfileStore} = useStores();
  const {closeRoom, resetValues} = useVideoCall();

  const styles = useStyle();
  const rneStyles = useStyles();

  const {initialized, conversation, resetConversation} = useConversation();

  const [showIncorrectProfileDialog, setShowIncorrectProfileDialog] = useState(
    false,
  );

  const [consultationId, setConsultationId] = useState();
  const [showEndConsultationDialog, setShowEndConsultationDialog] = useState(
    false,
  );
  const [
    showMedicalAttachmentsDialog,
    setShowMedicalAttachmentsDialog,
  ] = useState(false);
  const [
    showEmptyMedicalAttachmentsDialog,
    setShowEmptyMedicalAttachmentsDialog,
  ] = useState(false);
  const [
    showMedicalAttachmentsScreen,
    setShowMedicalAttachmentsScreen,
  ] = useState(false);
  const [
    showViewDoctorProfileDialog,
    setShowViewDoctorProfileDialog,
  ] = useState(false);
  const [showHistoryDialog, setShowHistoryDialog] = useState(false);
  const [statusByPatient, setStatusByPatient] = useState(false);

  const {data: serviceRequest, isLoading} = useGetConsultationDetail({
    serviceRequestId: consultationId || '',
  });

  const {data: medicalAttachments, refetch} = useGetAllMedicalAttachments({
    serviceRequestId: consultationId,
  });

  const navProps = {
    procedureId: serviceRequest?.procedure?.procedureId,
    subjectId: serviceRequest?.subject.patientId,
    receiver:
      authStore.authData?.user.userRole === 'patient'
        ? serviceRequest?.procedure?.subject
        : serviceRequest?.procedure?.performer,
    serviceRequestId: serviceRequest?.serviceRequestId,
  };

  const notificationProps = {
    title:
      authStore.authData?.user.userRole === 'patient'
        ? 'New chat message for the doctor'
        : 'New chat message for the patient',
    body: 'Your consultation chat has a new message.',
    consultation: `"${JSON.stringify(navProps)}"`,
    notificationUrl: `${REACT_APP_BASE_WEB_URL}main/consultation/detail?id=${consultationId}&screen=pending&tab=0`,
    fromUserId: authStore.authData?.user.userId!,
    toUserId:
      authStore.authData?.user.userRole === 'patient'
        ? serviceRequest?.performer.userId!
        : serviceRequest?.requester!,
  };

  const onMenuItemClick = async (itemKey: PopoverItemKey) => {
    let defaultAttachValues;
    if (
      serviceRequest &&
      serviceRequest.subject &&
      (serviceRequest.status === ConsultationStatusEnum.COMPLETED ||
        serviceRequest.status === ConsultationStatusEnum.ONGOING)
    ) {
      defaultAttachValues = {
        basedOnId: serviceRequest?.serviceRequestId,
        subject: serviceRequest?.subject.patientId,
        patientName: `${serviceRequest?.subject.humanName.given[0]} ${serviceRequest?.subject.humanName.family}`,
        age:
          serviceRequest?.subject.birthdate &&
          computeAge(serviceRequest?.subject.birthdate),
        userId: serviceRequest.subject.userId,
      };
    }

    switch (itemKey) {
      case PopoverItemKey.END_CONSULTATION:
        handleEndConsultation();
        break;

      case PopoverItemKey.MEDICAL_ATTACHMENTS:
        const res = await refetch();
        if (res.data && res.data?.length > 0) {
          if (Platform.OS === 'web') {
            setShowMedicalAttachmentsDialog(true);
          } else {
            setShowMedicalAttachmentsScreen(true);
          }
        } else {
          setShowEmptyMedicalAttachmentsDialog(true);
        }
        break;

      case PopoverItemKey.DOCTOR_PROFILE:
        handleViewDoctorProfile();
        break;

      case PopoverItemKey.MEDICAL_HISTORY:
        handleViewHistory();
        break;

      case PopoverItemKey.LABORATORY_REQUEST:
        navigation.navigate(NavigationKey.DOCTOR_ADD_MEDICAL_ATTACHMENT, {
          view: MedicalAttachmentEnum.LABORATORY_REQUEST,
          ...defaultAttachValues,
        });
        break;

      case PopoverItemKey.MEDICAL_CERTIFICATE:
        navigation.navigate(NavigationKey.DOCTOR_ADD_MEDICAL_ATTACHMENT, {
          view: MedicalAttachmentEnum.MEDICAL_CERTIFICATE,
          ...defaultAttachValues,
        });
        break;

      case PopoverItemKey.PRESCRIPTION:
        navigation.navigate(NavigationKey.DOCTOR_ADD_MEDICAL_ATTACHMENT, {
          view: MedicalAttachmentEnum.PRESCRIPTION,
          ...defaultAttachValues,
        });
        break;

      default:
        break;
    }
  };

  const handleEndConsultation = () => {
    setShowEndConsultationDialog(true);
  };

  const endConsultationCancel = () => {
    setShowEndConsultationDialog(false);
  };

  const endConsultationComplete = () => {
    setShowEndConsultationDialog(false);
    navigation.navigate(NavigationKey.DOCTOR_CONSULTATION_STACK, {
      screen: NavigationKey.CONSULTATION_LIST,
      params: {
        tab: 2,
        id: serviceRequest?.serviceRequestId,
      },
    });
  };

  const handleViewDoctorProfile = () => {
    if (Platform.OS === 'web') {
      setShowViewDoctorProfileDialog(true);
    } else {
      setShowMedicalAttachmentsScreen(false);
      if (serviceRequest) {
        navigation.navigate(NavigationKey.CONSULTATION_DOCTOR_PROFILE, {
          practitioner: serviceRequest?.performer,
        });
      }
    }
  };

  const handleViewHistory = () => {
    if (Platform.OS === 'web') {
      setShowHistoryDialog(true);
    } else {
      if (serviceRequest) {
        navigation.navigate(
          NavigationKey.CONSULTATION_PATIENT_MEDICAL_HISTORY,
          {
            patient: serviceRequest.subject,
            status: serviceRequest.status,
          },
        );
      }
    }
  };

  const sendMessage = (message: string) => {
    setShowMedicalAttachmentsScreen(false);
    if (initialized && conversation) {
      conversation.sendMessage(message);
    }
  };

  const navigateBackToTab = (consultationStatus?: ConsultationStatusEnum) => {
    let screen: NavigationKey;

    switch (consultationStatus) {
      case ConsultationStatusEnum.PENDING:
        screen = NavigationKey.CONSULTATION_TAB_PENDING;
        break;

      case ConsultationStatusEnum.ONGOING:
        screen = NavigationKey.CONSULTATION_TAB_ONGOING;
        break;

      case ConsultationStatusEnum.CANCELLED:
      case ConsultationStatusEnum.COMPLETED:
      case ConsultationStatusEnum.DECLINED:
        screen = NavigationKey.CONSULTATION_TAB_HISTORY;
        break;

      default:
        screen = NavigationKey.CONSULTATION_TAB_ONGOING;
        break;
    }

    if (screen) {
      navigation.navigate(NavigationKey.DOCTOR_CONSULTATION_STACK, {
        screen: NavigationKey.CONSULTATION_LIST,
        params: {
          screen: screen,
        },
      });
    }
  };

  const endCall = async (subjectId: string, roomId: string) => {
    await closeRoom(subjectId, roomId);
    resetValues();
  };

  useEffect(() => {
    const params: any = route.params;
    const patientId = patientProfileStore.activeProfile?.patientId;
    const doctor = doctorProfileStore.doctorStatus;

    if (
      params.subjectId !== undefined &&
      params.subjectId !== patientId &&
      !doctor
    ) {
      setShowIncorrectProfileDialog(true);
    }

    if (params) {
      setConsultationId(params.id);

      if (params.declining) {
        endCall(params.subjectId, params.roomId);
      }

      if (params.id !== consultationId) {
        resetConversation();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route.params]);

  useEffect(() => {
    switch (authStore.userRole) {
      case 'patient':
        setStatusByPatient(
          authStore.auth?.user.userId === serviceRequest?.modifiedBy,
        );
        break;

      case 'practitioner':
        setStatusByPatient(
          !(authStore.auth?.user.userId === serviceRequest?.modifiedBy),
        );
        break;

      default:
        break;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authStore.userRole, serviceRequest]);

  useEffect(() => {
    const stack =
      authStore.userRole === 'patient'
        ? NavigationKey.PATIENT_CONSULTATION_STACK
        : NavigationKey.DOCTOR_CONSULTATION_STACK;
    const screen = NavigationKey.CONSULTATION_DETAIL;
    const id = serviceRequest?.serviceRequestId;
    let params = {};

    if (id) {
      switch (serviceRequest?.status) {
        case ConsultationStatusEnum.ONGOING:
          params = {
            tab: 0,
            screen: NavigationKey.CONSULTATION_TAB_PENDING,
            id: id,
          };
          break;

        case ConsultationStatusEnum.PENDING:
          params = {
            tab: 1,
            screen: NavigationKey.CONSULTATION_TAB_ONGOING,
            id: id,
          };
          break;

        case ConsultationStatusEnum.COMPLETED:
        case ConsultationStatusEnum.DECLINED:
        case ConsultationStatusEnum.CANCELLED:
          params = {
            tab: 2,
            screen: NavigationKey.CONSULTATION_TAB_HISTORY,
            id: id,
          };
          break;
      }

      navigation.navigate(stack, {
        screen: screen,
        params: params,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceRequest?.status]);

  useLayoutEffect(() => {
    if (Platform.OS !== 'web') {
      navigation.setOptions({
        title: '',
        headerLeft: () => {
          if (authStore.userRole === 'patient') {
            return (
              <HeaderConsultationDoctor
                serviceRequest={serviceRequest}
                onBackClickFromList={() => {
                  showMedicalAttachmentsScreen
                    ? setShowMedicalAttachmentsScreen(false)
                    : navigateBackToTab(serviceRequest?.status);
                }}
              />
            );
          } else if (authStore.userRole === 'practitioner') {
            return (
              <HeaderConsultationPatient
                serviceRequest={serviceRequest}
                onBackClickFromList={() => {
                  showMedicalAttachmentsScreen
                    ? setShowMedicalAttachmentsScreen(false)
                    : navigateBackToTab(serviceRequest?.status);
                }}
              />
            );
          } else {
            null;
          }
        },
        headerRight: () => (
          <View style={styles.headerRight}>
            {!(
              serviceRequest?.status === ConsultationStatusEnum.ONGOING ||
              serviceRequest?.status === ConsultationStatusEnum.COMPLETED
            ) && (
              <ConsultationStatus
                consultationStatus={serviceRequest?.status}
                procedureStatus={serviceRequest?.procedure?.status}
                isActionByPatient={statusByPatient}
              />
            )}

            {(serviceRequest?.status === ConsultationStatusEnum.ONGOING ||
              serviceRequest?.status === ConsultationStatusEnum.COMPLETED) &&
              authStore.userRole === 'patient' &&
              !!serviceRequest && (
                <ConsultationPatientPopover
                  status={serviceRequest.status}
                  onMenuItemClick={onMenuItemClick}
                />
              )}

            {(serviceRequest?.status === ConsultationStatusEnum.ONGOING ||
              serviceRequest?.status === ConsultationStatusEnum.COMPLETED) &&
              authStore.userRole === 'practitioner' && (
                <ConsultationDoctorPopover
                  status={serviceRequest.status}
                  onMenuItemClick={onMenuItemClick}
                />
              )}
          </View>
        ),
        headerStyle: {
          borderBottomColor: 'transparent',
          elevation: 0,
          shadowOpacity: 0,
        },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    navigation,
    serviceRequest,
    authStore.userRole,
    statusByPatient,
    showMedicalAttachmentsScreen,
  ]);

  return (
    <>
      <View style={styles.root}>
        <Overlay
          onBackdropPress={() => null}
          isVisible={showIncorrectProfileDialog}
          overlayStyle={rneStyles.overlayContainer}>
          <View style={rneStyles.root}>
            <RNEText
              label="Incorrect Profile"
              variant="title"
              align="center"
              weight="600"
              color="accent"
            />
            <View style={rneStyles.subtitleContainer}>
              <RNEText
                label="You may switch profile to view notification."
                variant="body"
                align="center"
              />
            </View>
            <View style={rneStyles.actionContainer}>
              <RNEButton
                title="Switch Profiles"
                color="accent"
                onPress={() => {
                  setShowIncorrectProfileDialog(false);
                  navigation.navigate(NavigationKey.PATIENT_PROFILE_STACK);
                }}
                style={rneStyles.buttonStyle}
              />
            </View>
          </View>
        </Overlay>
        <Overlay
          onBackdropPress={() => setShowMedicalAttachmentsDialog(false)}
          isVisible={showMedicalAttachmentsDialog}
          overlayStyle={styles.overlayContainer}>
          <MedicalAttachmentsModal
            basedOnId={serviceRequest?.serviceRequestId}
            subject={
              serviceRequest?.subject && serviceRequest?.subject.patientId
            }
            serviceRequestId={serviceRequest?.serviceRequestId}
            attachments={medicalAttachments}
          />
        </Overlay>
        <Overlay
          isVisible={showEmptyMedicalAttachmentsDialog}
          overlayStyle={styles.overlayContainer}>
          <EmptyMedicalAttachmentsModal
            onConfirm={() => setShowEmptyMedicalAttachmentsDialog(false)}
          />
        </Overlay>
        {consultationId ? (
          <View style={styles.content}>
            {Platform.OS === 'web' && (
              <>
                <View
                  style={
                    authStore.userRole === 'patient'
                      ? styles.boxHeaderPatient
                      : styles.boxHeaderDoctor
                  }
                />
                <View style={styles.contentLimit}>
                  {authStore.userRole === 'patient' && (
                    <HeaderConsultationDoctorWeb
                      serviceRequest={serviceRequest}
                      onMenuItemClick={onMenuItemClick}
                    />
                  )}

                  {authStore.userRole === 'practitioner' && (
                    <HeaderConsultationPatientWeb
                      serviceRequest={serviceRequest}
                      onMenuItemClick={onMenuItemClick}
                    />
                  )}
                </View>
              </>
            )}

            {showMedicalAttachmentsScreen && Platform.OS !== 'web' && (
              <View style={styles.listContainer}>
                <MedicalAttachmentsListView
                  serviceRequestId={serviceRequest?.serviceRequestId}
                  subject={
                    serviceRequest?.subject && serviceRequest?.subject.patientId
                  }
                  attachments={medicalAttachments}
                />
              </View>
            )}

            {!showMedicalAttachmentsScreen && (
              <View style={styles.messages}>
                <ContentScrollView
                  isWeb={Platform.OS === 'web'}
                  scrollToEnd={true}>
                  <View style={styles.messagesWrapper}>
                    <View style={[styles.contentLimit]}>
                      {serviceRequest && !isLoading ? (
                        <ConsultationDetailEntry
                          serviceRequest={serviceRequest}
                        />
                      ) : (
                        <View style={styles.contentPlaceholder}>
                          <RNEButton
                            height={32}
                            loadingProps={loadingPropsStyle}
                            loading={true}
                          />
                          <RNEText
                            label="Getting your messages..."
                            color="grey"
                            align="center"
                          />
                        </View>
                      )}
                    </View>
                  </View>
                </ContentScrollView>
              </View>
            )}

            {serviceRequest && (
              <View style={styles.contentLimit}>
                {serviceRequest?.status === ConsultationStatusEnum.PENDING &&
                  !serviceRequest.procedure &&
                  authStore.userRole === 'patient' && (
                    <ConsultationCancel
                      serviceRequestId={serviceRequest?.serviceRequestId}
                    />
                  )}

                {serviceRequest?.status === ConsultationStatusEnum.PENDING &&
                  serviceRequest?.procedure?.status ===
                    ProcedureStatusEnum.TO_PAY &&
                  authStore.userRole === 'patient' && (
                    <ConsultationProceedToPayment
                      serviceId={serviceRequest?.procedure.procedureId}
                      patientId={serviceRequest?.subject.patientId}
                    />
                  )}

                {serviceRequest?.status === ConsultationStatusEnum.PENDING &&
                  !serviceRequest.procedure &&
                  authStore.userRole === 'practitioner' &&
                  serviceRequest?.subject && (
                    <ConsultationAcceptDecline
                      serviceRequest={serviceRequest}
                    />
                  )}

                {(serviceRequest?.status === ConsultationStatusEnum.COMPLETED ||
                  serviceRequest?.status === ConsultationStatusEnum.CANCELLED ||
                  !serviceRequest?.subject) && (
                  <ConsultationHistoryFooterNote
                    serviceRequest={serviceRequest}
                  />
                )}
              </View>
            )}

            {serviceRequest &&
              serviceRequest.subject &&
              serviceRequest?.status === ConsultationStatusEnum.ONGOING && (
                <KeyboardAvoidingView
                  style={Platform.OS === 'web' && fullWidthWeb}
                  behavior={Platform.OS === 'ios' ? 'padding' : undefined}
                  keyboardVerticalOffset={80}>
                  <View
                    style={[
                      styles.contentLimit,
                      Platform.OS !== 'web' && styles.contentLimitChat,
                    ]}>
                    <ConsultationChat
                      notificationProps={notificationProps}
                      consultation={serviceRequest}
                      onSend={sendMessage}
                      userRole={authStore.userRole}
                      onMenuItemClick={onMenuItemClick}
                    />
                  </View>
                </KeyboardAvoidingView>
              )}
          </View>
        ) : (
          <View style={styles.detailPlaceholder}>
            <ConsultationDetailPlaceholder />
          </View>
        )}
      </View>

      <Overlay
        isVisible={showEndConsultationDialog && !!serviceRequest}
        overlayStyle={styles.overlayContainer}
        onBackdropPress={() => setShowEndConsultationDialog(false)}>
        {!!serviceRequest && (
          <ConsultationEndModal
            serviceRequest={serviceRequest}
            onCancel={endConsultationCancel}
            onComplete={endConsultationComplete}
          />
        )}
      </Overlay>

      <Overlay
        isVisible={showViewDoctorProfileDialog && !!serviceRequest}
        overlayStyle={styles.overlayContainer}
        onBackdropPress={() => setShowViewDoctorProfileDialog(false)}>
        {!!serviceRequest && (
          <ConsultationDoctorProfileModal
            practitioner={serviceRequest.performer}
            onClose={() => setShowViewDoctorProfileDialog(false)}
          />
        )}
      </Overlay>

      <Overlay
        isVisible={showHistoryDialog && !!serviceRequest}
        overlayStyle={[
          styles.overlayContainer,
          // eslint-disable-next-line react-native/no-inline-styles
          {
            padding: 0,
          },
        ]}
        onBackdropPress={() => setShowHistoryDialog(false)}>
        {!!serviceRequest && (
          <ConsultationPatientMedicalModal
            patient={serviceRequest.subject}
            status={serviceRequest.status}
            onClose={() => setShowHistoryDialog(false)}
          />
        )}
      </Overlay>
    </>
  );
};

const loadingPropsStyle = {
  color: colorPalette.primary,
};

const fullWidthWeb = {
  width: '100%',
};

const useStyle = makeStyles({
  root: {
    flex: 1,
  },
  detailPlaceholder: {
    flex: 1,
    paddingBottom: 60,
  },
  content: {
    flexDirection: 'column',
    flex: 1,
    width: '100%',
    alignItems: 'center',
  },
  messages: {
    flex: 1,
    width: '100%',
  },
  messagesWrapper: {
    alignItems: 'center',
  },
  headerRight: {
    flexDirection: 'row',
    paddingHorizontal: 12,
  },
  contentLimit: {
    width: '100%',
    paddingHorizontal: 60,
  },
  contentLimitChat: {
    paddingHorizontal: 0,
  },
  overlayContainer: {
    borderRadius: 20,
    marginHorizontal: 40,
  },
  listContainer: {
    maxWidth: 860,
    width: '100%',
    flex: 1,
  },
  contentPlaceholder: {
    marginTop: 24,
  },
  boxHeaderDoctor: {
    height: 68,
    backgroundColor: colorPalette.success,
    width: '100%',
  },
  boxHeaderPatient: {
    height: 68,
    backgroundColor: colorPalette.primary,
    width: '100%',
  },
});

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: Platform.OS === 'web' ? 420 : '80%',
    padding: Platform.OS === 'web' ? 32 : 16,
  },
  subtitleContainer: {
    marginTop: 20,
  },
  actionContainer: {
    marginTop: 36,
    alignItems: 'center',
  },
  buttonStyle: {
    width: 200,
  },
  overlayContainer: {
    borderRadius: 20,
    marginHorizontal: 40,
  },
}));

export default ConsultationDetailScreen;
