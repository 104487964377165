import React, {memo} from 'react';
import {View, Text} from 'react-native';

import {makeStyles} from 'rne-netzon';
import {RNEText, SVGIcon} from '../../components';

const ConsultationDetailPlaceholder = () => {
  const styles = useStyle();

  return (
    <View style={styles.root}>
      <SVGIcon name="selection" size={96} />

      <View>
        <RNEText
          label="Select a conversation from the list"
          variant="subheader"
          weight="600"
          color="blackLight"
          style={subtitleFontSize20}
        />
      </View>
    </View>
  );
};

const subtitleFontSize20 = {
  fontSize: 20,
};

const useStyle = makeStyles({
  root: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default memo(ConsultationDetailPlaceholder);
