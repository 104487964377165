import {CodingCodes} from './../data/Codes.data';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {AxiosRequestConfig} from 'axios';
import {API, ApiEndpointsEnum} from '../api';
import {
  ConsultationRequestReqBody,
  ConsultationRequestResBody,
  ConsultationListReqParams,
  ConsultationDetailReqBody,
  CancelConsultationReqBody,
  DenyConsultationReqBody,
  AcceptConsultationReqBody,
  EndConsultationReqBody,
  ConsultationStatusEnum,
  ProcedureDetailReqBody,
  ConsultationDashboard,
} from '../types/consultation.types';
import {
  ProcedureConsultationReqResponse,
  ProcedureListReqParams,
} from '../types/procedure.types';

import {convertJsonToParams} from '../utils/query.util';

const addConsultationRequest = async (reqBody: ConsultationRequestReqBody) => {
  let config: AxiosRequestConfig = {};

  if (reqBody.subject) {
    const headerAuthorization: any = {
      'X-Subject': reqBody.subject,
    };

    config = {
      headers: {
        ...headerAuthorization,
      },
    };
  }

  reqBody.code = {
    code: [
      {
        code: CodingCodes.serviceRequest,
      },
    ],
  };

  return API.post<ConsultationRequestResBody>(
    `${ApiEndpointsEnum.POST_CONSULTATION_REQUEST}`,
    reqBody,
    config,
  );
};

const useAddConsultationRequest = (reqBody: ConsultationRequestReqBody) => {
  const queryClient = useQueryClient();

  return useMutation(
    ['add-consultation-request'],
    () => addConsultationRequest(reqBody),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['add-consultation-request']);
      },
    },
  );
};

const getConsultationListPendingHistory = async (
  reqParams: ConsultationListReqParams,
) => {
  let config: AxiosRequestConfig = {};

  if (reqParams.subject) {
    const headerAuthorization: any = {
      'X-Subject': reqParams.subject,
    };

    config = {
      headers: {
        ...headerAuthorization,
      },
    };
  }

  const queryParams = convertJsonToParams(reqParams as any);
  const url =
    ApiEndpointsEnum.GET_CONSULTATION_LIST_PENDING_HISTORY + '?' + queryParams;

  return API.get<ConsultationRequestResBody[]>(url, config);
};

const getConsultationListOngoing = async (
  reqParams: ProcedureListReqParams,
) => {
  let config: AxiosRequestConfig = {};

  if (reqParams.subject) {
    const headerAuthorization: any = {
      'X-Subject': reqParams.subject,
    };

    config = {
      headers: {
        ...headerAuthorization,
      },
    };
  }

  const queryParams = convertJsonToParams(reqParams as any);
  const url =
    ApiEndpointsEnum.GET_CONSULTATION_LIST_ONGOING + '?' + queryParams;

  return API.get<ProcedureConsultationReqResponse[]>(url, config);
};

const useGetOngoingConsultation = (reqParams: ProcedureListReqParams) => {
  reqParams.code = CodingCodes.serviceRequest;

  return useQuery(['ongoing-consultations', {id: reqParams.searchKey}], () =>
    getConsultationListOngoing(reqParams),
  );
};

const useGetPendingConsultation = (reqParams: ConsultationListReqParams) => {
  return useQuery('pending-consultations', () =>
    getConsultationListPendingHistory(reqParams),
  );
};

const useGetHistoryConsultation = (reqParams: ConsultationListReqParams) => {
  reqParams.code = CodingCodes.serviceRequest;

  return useQuery(['history-consultations', {id: reqParams.searchKey}], () =>
    getConsultationListPendingHistory(reqParams),
  );
};

const getConsultationDetail = async (reqBody: ConsultationDetailReqBody) => {
  const url = ApiEndpointsEnum.GET_CONSULTATION_DETAIL.replace(
    '{serviceRequestId}',
    reqBody.serviceRequestId,
  );

  return API.get<ConsultationRequestResBody>(url);
};

const useGetConsultationDetail = (reqBody: ConsultationDetailReqBody) => {
  return useQuery(
    ['consultation-detail', {id: reqBody.serviceRequestId}],
    () => getConsultationDetail(reqBody),
    {
      enabled: !!reqBody.serviceRequestId,
    },
  );
};

const cancelConsultation = async (reqBody: CancelConsultationReqBody) => {
  const url = ApiEndpointsEnum.PATCH_CONSULTATION.replace(
    '{serviceRequestId}',
    reqBody.serviceRequestId,
  );

  return API.patch<ConsultationRequestResBody>(url, reqBody);
};

const useCancelConsultationDetail = (reqBody: CancelConsultationReqBody) => {
  const queryClient = useQueryClient();

  return useMutation(
    ['cancel-consultation', {id: reqBody.serviceRequestId}],
    () => cancelConsultation(reqBody),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['pending-consultations']);
        queryClient.invalidateQueries(['history-consultations']);
        queryClient.refetchQueries([
          'consultation-detail',
          {id: reqBody.serviceRequestId},
        ]);
      },
    },
  );
};

const denyConsultation = async (reqBody: DenyConsultationReqBody) => {
  const url = ApiEndpointsEnum.PATCH_CONSULTATION.replace(
    '{serviceRequestId}',
    reqBody.serviceRequestId,
  );

  return API.patch<ConsultationRequestResBody>(url, reqBody);
};

const useDenyConsultationDetail = (reqBody: DenyConsultationReqBody) => {
  const queryClient = useQueryClient();

  return useMutation(
    ['deny-consultation', {id: reqBody.serviceRequestId}],
    () => denyConsultation(reqBody),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['pending-consultations']);
        queryClient.invalidateQueries(['history-consultations']);
        queryClient.refetchQueries([
          'consultation-detail',
          {id: reqBody.serviceRequestId},
        ]);
      },
    },
  );
};

const acceptConsultation = async (reqBody: AcceptConsultationReqBody) => {
  const url = ApiEndpointsEnum.ACCEPT_CONSULTATION;
  return API.post<ProcedureConsultationReqResponse>(url, reqBody);
};

const useAcceptConsultationDetail = (reqBody: AcceptConsultationReqBody) => {
  const queryClient = useQueryClient();

  return useMutation(
    ['accept-consultation', {id: reqBody.basedOn}],
    () => acceptConsultation(reqBody),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['pending-consultations']);
        queryClient.invalidateQueries(['ongoing-consultations']);
        queryClient.refetchQueries([
          'consultation-detail',
          {id: reqBody.basedOn},
        ]);
      },
    },
  );
};

const endConsultation = async (reqBody: EndConsultationReqBody) => {
  const body = {
    status: ConsultationStatusEnum.COMPLETED,
  };

  const url = ApiEndpointsEnum.END_CONSULTATION.replace(
    '{procedureId}',
    reqBody.procedureId,
  );
  return API.patch<ProcedureConsultationReqResponse>(url, body);
};

const useEndConsultationDetail = (reqBody: EndConsultationReqBody) => {
  const queryClient = useQueryClient();

  return useMutation(
    ['end-consultation', {id: reqBody.procedureId}],
    () => endConsultation(reqBody),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['ongoing-consultations']);
        queryClient.invalidateQueries(['history-consultations']);
        queryClient.refetchQueries([
          'consultation-detail',
          {id: reqBody.serviceRequestId},
        ]);
      },
    },
  );
};

const getProcedureDetail = async (reqBody: ProcedureDetailReqBody) => {
  const url = ApiEndpointsEnum.GET_PROCEDURE_DETAIL.replace(
    '{procedureId}',
    reqBody.procedureId || '',
  );

  return API.get<ProcedureConsultationReqResponse>(url);
};

const useGetProcedureDetail = (reqBody: ProcedureDetailReqBody) => {
  return useQuery(
    ['procedure-detail', {id: reqBody.procedureId}],
    () => getProcedureDetail(reqBody),
    {
      enabled: !!reqBody.procedureId,
    },
  );
};

const getConsultationDashboard = async () => {
  const url = ApiEndpointsEnum.GET_CONSULTATION_DASHBOARD;

  return API.get<ConsultationDashboard>(url);
};

const useGetConsultationDashboard = () => {
  return useQuery(['consultation-dashboard'], () => getConsultationDashboard());
};

export {
  useAddConsultationRequest,
  useGetOngoingConsultation,
  useGetPendingConsultation,
  useGetHistoryConsultation,
  useGetConsultationDetail,
  useCancelConsultationDetail,
  useDenyConsultationDetail,
  useAcceptConsultationDetail,
  useEndConsultationDetail,
  useGetProcedureDetail,
  useGetConsultationDashboard,
};
