import React, {memo} from 'react';
import {Platform, View} from 'react-native';

import {makeStyles} from 'rne-netzon';

import {SVGIcon, RNEText, RNEIconButton} from '../../components';

interface EmptyMedicalAttachmentsModalProps {
  onConfirm?: () => void;
}

function EmptyMedicalAttachmentsModal(
  props: EmptyMedicalAttachmentsModalProps,
) {
  const rneStyles = useStyles();

  return (
    <View style={rneStyles.root}>
      <RNEText
        label="No Medical Attachment"
        variant="header"
        style={titleFontSize24}
        align="center"
        weight="600"
        color="accent"
      />

      <View style={rneStyles.buttonContainer}>
        <RNEIconButton
          icon={<SVGIcon name="check-white" size={48} />}
          color="secondary"
          size={80}
          onPress={props.onConfirm}
          raised={Platform.OS !== 'web'}
        />
      </View>
    </View>
  );
}

const titleFontSize24 = {
  fontSize: Platform.OS === 'web' ? 30 : 24,
};

const useStyles = makeStyles(theme => ({
  root: {
    padding: 28,
    margin: 32,
    height: 220,
    justifyContent: 'center',
    alignSelf: 'center',
  },
  buttonContainer: {
    marginTop: 48,
    justifyContent: 'center',
    alignSelf: 'center',
  },
}));

export default memo(EmptyMedicalAttachmentsModal);
