import React, {useEffect, useState} from 'react';
import {View, ScrollView, Image} from 'react-native';
import {makeStyles} from 'rne-netzon';
import {colorPalette} from '../../../core/config/color.config';

import {useNavigation} from '@react-navigation/native';
import {NavigationKey} from '../../navigations/config';

import {
  RNEText,
  SVGIcon,
  RNEButton,
  RNEInput,
  IconName,
} from '../../components';
import {useToast} from 'react-native-fast-toast';

import {useForm, Controller} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';

import useStores from '../../stores/useStores';
import {EditAccountReqBodyExt} from '../../server/types/account.types';
import {useEditAccount} from '../../server/react-query/useAccount';
import {PractitionerStatus} from '../../server/types/practitioner-status.types';

import {formatDateString} from '../../utils/date.utils';

const schema: yup.SchemaOf<EditAccountReqBodyExt> = yup.object().shape(
  {
    location: yup.string(),
    contactNumber: yup
      .string()
      .trim()
      .matches(/^[\d +-]+$/, {
        message: 'Invalid contact number format',
        excludeEmptyString: true,
      }),
    oldPassword: yup
      .string()
      .trim()
      .when('newPassword', {
        is: (newPassword: string) => newPassword && newPassword.length > 0,
        then: yup
          .string()
          .required('Field is required')
          .min(6, 'Min of 6 characters.'),
      }),
    newPassword: yup
      .string()
      .trim()
      .when('oldPassword', {
        is: (oldPassword: string) => oldPassword && oldPassword.length > 0,
        then: yup
          .string()
          .required('Field is required')
          .min(6, 'Min of 6 characters.'),
      }),
    confirmNewPassword: yup
      .string()
      .trim()
      .when('newPassword', {
        is: (newPassword: string) => newPassword && newPassword.length > 0,
        then: yup
          .string()
          .required('This field is required.')
          .min(6, 'Min of 6 characters.')
          .oneOf([yup.ref('newPassword'), null], 'Passwords do not match.'),
      }),
  },
  [['oldPassword', 'newPassword']],
);

const DoctorEditAccountScreen = () => {
  const navigation = useNavigation();
  const webStyles = useWebStyles();
  const {authStore} = useStores();
  const {
    control,
    handleSubmit,
    getValues,
    reset,
    setValue,
    formState: {errors, isValid},
  } = useForm<EditAccountReqBodyExt>({
    mode: 'all',
    defaultValues: {
      location: '',
      contactNumber: '',
    },
    resolver: yupResolver(schema),
  });

  const user = authStore.authData?.user!;

  const toast = useToast();
  const [statusIcon, setStatusIcon] = useState<IconName>('profile-complete');
  const [iconSize, setIconSize] = useState<number>(36);

  const mutation = useEditAccount(getValues());

  const editDoctorAccount = () => {
    handleSubmit(onSubmit)();
  };

  const onSubmit = async () => {
    if (isValid) {
      try {
        await mutation.mutateAsync();

        reset();
        mutation.reset();
        goToViewProfile();
      } catch (error) {
        if (error?.data?.error) {
          toast?.show(error?.data?.error, {type: 'danger'});
        }
      }
    }
  };

  const goToViewProfile = () => {
    navigation.navigate(NavigationKey.DOCTOR_ACCOUNT);
  };

  useEffect(() => {
    reset({
      location: user.location,
      contactNumber: user.contactNumber,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    switch (user?.practitionerStatus) {
      case PractitionerStatus.VERIFIED:
        setStatusIcon('profile-complete');
        setIconSize(36);
        break;

      case PractitionerStatus.UNVERIFIED:
        setStatusIcon('close-gray-70');
        setIconSize(24);
        break;

      case PractitionerStatus.DECLINED:
        setStatusIcon('request-denied');
        setIconSize(16);
        break;

      case PractitionerStatus.PENDING:
        setStatusIcon('request-cancelled');
        setIconSize(16);
        break;

      default:
        break;
    }
  }, [user?.practitionerStatus]);

  return (
    <ScrollView>
      <View style={webStyles.mainContainer}>
        <View style={webStyles.topBackground}>
          <View style={webStyles.titleContainer}>
            <RNEText label="Dashboard > " variant="body" color="white" />
            <RNEText
              label="My Account Details"
              variant="body"
              color="white"
              weight="bold"
            />
          </View>
          <View style={webStyles.headerContainer}>
            <View style={webStyles.userIcon}>
              {user?.photo ? (
                <Image
                  style={webStyles.imageWrapper}
                  source={{
                    uri: user.photo.url,
                  }}
                />
              ) : (
                <SVGIcon name="user-white" size={68} />
              )}
              <View style={webStyles.statusIndicator}>
                <SVGIcon name={statusIcon} size={iconSize} />
              </View>
            </View>
            {/* <View style={webStyles.cameraIcon}>
                  <SVGIcon name="camera-29" size={20} />
                </View> */}
            <View>
              <RNEText
                label="Edit Account Details"
                variant="header"
                color="white"
                weight="bold"
              />
              <View style={webStyles.dateContainer}>
                <RNEText
                  label={`Date Updated: ${formatDateString(user.modifiedOn)}`}
                  variant="body"
                  color="white"
                />
              </View>
            </View>
          </View>
        </View>

        <View style={webStyles.formContainer}>
          <View style={webStyles.formTitleContainer}>
            <RNEText
              label="Edit Account Details"
              variant="body"
              color="black"
              weight="bold"
            />
          </View>

          <View style={webStyles.labelGroupContainer}>
            <View style={webStyles.rowGroupContainer}>
              <View style={webStyles.infoContainer}>
                <View style={webStyles.inputLabel}>
                  <RNEText
                    label="Email Address"
                    variant="button"
                    color="accent"
                    weight="bold"
                  />
                </View>
                <RNEInput
                  placeholder="Email Address"
                  leftIcon={<SVGIcon name="email" />}
                  value={user.email}
                  variant="shadowed"
                  disabled={true}
                />
              </View>

              <View style={webStyles.infoContainer}>
                <View style={webStyles.inputLabel}>
                  <RNEText
                    label="City Address"
                    variant="button"
                    color="accent"
                    weight="bold"
                  />
                </View>
                <Controller
                  control={control}
                  render={({field: {onChange, onBlur, value}}) => (
                    <RNEInput
                      placeholder="City Address"
                      leftIcon={<SVGIcon name="map" />}
                      value={value || ''}
                      onChangeText={text => {
                        onChange(text);
                      }}
                      errorMessage={errors.location?.message}
                      variant="shadowed"
                    />
                  )}
                  name="location"
                />
              </View>

              <View style={webStyles.infoContainer}>
                <View style={webStyles.inputLabel}>
                  <RNEText
                    label="Phone Number"
                    variant="button"
                    color="accent"
                    weight="bold"
                  />
                </View>
                <Controller
                  control={control}
                  render={({field: {onChange, onBlur, value}}) => (
                    <RNEInput
                      placeholder="Phone Number"
                      leftIcon={<SVGIcon name="call-27" />}
                      value={value || ''}
                      onChangeText={text => {
                        onChange(text);
                      }}
                      errorMessage={errors.contactNumber?.message}
                      variant="shadowed"
                    />
                  )}
                  name="contactNumber"
                />
              </View>
            </View>

            <View style={webStyles.rowGroupContainer}>
              <View style={webStyles.infoContainer}>
                <View style={webStyles.inputLabel}>
                  <RNEText
                    label="Current Password"
                    variant="button"
                    color="accent"
                    weight="bold"
                  />
                </View>
                <Controller
                  control={control}
                  render={({field: {onChange, onBlur, value}}) => (
                    <RNEInput
                      placeholder="* * * * * *"
                      secureTextEntry
                      leftIcon={<SVGIcon name="lock" />}
                      value={value || ''}
                      onChangeText={text => {
                        onChange(text);
                      }}
                      errorMessage={errors.oldPassword?.message}
                      variant="shadowed"
                    />
                  )}
                  name="oldPassword"
                />
              </View>

              <View style={webStyles.infoContainer}>
                <View style={webStyles.inputLabel}>
                  <RNEText
                    label="New Password"
                    variant="button"
                    color="accent"
                    weight="bold"
                  />
                </View>
                <Controller
                  control={control}
                  render={({field: {onChange, onBlur, value}}) => (
                    <RNEInput
                      placeholder="* * * * * *"
                      secureTextEntry
                      leftIcon={<SVGIcon name="lock" />}
                      value={value || ''}
                      onChangeText={text => {
                        onChange(text);
                      }}
                      errorMessage={errors.newPassword?.message}
                      variant="shadowed"
                    />
                  )}
                  name="newPassword"
                />
              </View>

              <View style={webStyles.infoContainer}>
                <View style={webStyles.inputLabel}>
                  <RNEText
                    label="Confirm New Password"
                    variant="button"
                    color="accent"
                    weight="bold"
                  />
                </View>
                <Controller
                  control={control}
                  render={({field: {onChange, onBlur, value}}) => (
                    <RNEInput
                      placeholder="* * * * * *"
                      secureTextEntry
                      leftIcon={<SVGIcon name="lock" />}
                      value={value || ''}
                      onChangeText={text => {
                        onChange(text);
                      }}
                      errorMessage={errors.confirmNewPassword?.message}
                      variant="shadowed"
                    />
                  )}
                  name="confirmNewPassword"
                />
              </View>
            </View>
          </View>

          <View style={webStyles.buttonsContainer}>
            <View style={webStyles.buttonContainer}>
              <RNEButton
                title="Cancel"
                color="secondary"
                style={webStyles.buttonStyle}
                variant="outlined"
                onPress={goToViewProfile}
              />
            </View>
            <RNEButton
              title="Save"
              color="secondary"
              style={webStyles.buttonStyle}
              onPress={editDoctorAccount}
              loading={mutation.isLoading}
              disabled={!isValid}
            />
          </View>
        </View>
      </View>
    </ScrollView>
  );
};

export default DoctorEditAccountScreen;

const useWebStyles = makeStyles({
  mainContainer: {
    flex: 1,
    height: '100%',
    width: '100%',
    backgroundColor: '#F6F7FB',
  },
  topBackground: {
    backgroundColor: colorPalette.purple,
    borderBottomRightRadius: 50,
    height: 420,
  },
  titleContainer: {
    flexDirection: 'row',
    marginHorizontal: 100,
    paddingVertical: 20,
    borderBottomWidth: 1,
    borderBottomColor: colorPalette.white,
  },
  headerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 100,
    paddingRight: 100,
    paddingVertical: 60,
  },
  userIcon: {
    // backgroundColor: colorPalette.white,
    marginRight: 48,
    borderRadius: 100,
    height: 140,
    width: 140,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: colorPalette.white,
  },
  statusIndicator: {
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    bottom: 4,
    right: 4,
    backgroundColor: colorPalette.white,
    width: 24,
    height: 24,
    borderRadius: 24,
  },
  imageWrapper: {
    width: '100%',
    height: '100%',
    borderRadius: 100,
  },
  cameraIcon: {
    backgroundColor: colorPalette.white,
    borderRadius: 50,
    height: 36,
    width: 36,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 160,
    left: 240,
  },
  dateContainer: {
    // paddingTop: 12,
    height: 48,
    justifyContent: 'center',
  },
  formContainer: {
    backgroundColor: colorPalette.white,
    borderRadius: 20,
    marginHorizontal: 100,
    top: -100,
    paddingVertical: 40,
    paddingHorizontal: 60,
  },
  formTitleContainer: {
    paddingLeft: 24,
  },
  labelGroupContainer: {
    width: '100%',
    marginVertical: 40,
  },
  rowGroupContainer: {
    flexDirection: 'row',
    marginBottom: 60,
  },
  infoContainer: {
    paddingLeft: 24,
    marginBottom: 4,
    flex: 1,
  },
  inputLabel: {
    paddingLeft: 24,
    marginBottom: 4,
  },
  buttonContainer: {
    marginRight: 20,
  },
  buttonsContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  buttonStyle: {
    width: 180,
  },
});
