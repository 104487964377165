import React from 'react';
import {View, TouchableWithoutFeedback} from 'react-native';
import {SVGIcon} from '..';
import {useToast} from 'react-native-fast-toast';
import {hugeFileSizeChecker} from '../../utils/file-size-checker.utils';

export interface SoundClipSelectorProps {
  onSelectSoundClip: (file: any | null) => void;
}

export const SoundClipSelector = (props: SoundClipSelectorProps) => {
  let inputElement: HTMLElement | null;

  const toast = useToast();

  const triggerInputClick = async () => {
    inputElement?.click();
  };

  const handleFileChange = (event: any) => {
    const {target} = event;
    const {files} = target;

    if (files && files[0]) {
      const reader = new FileReader();

      reader.onload = (readerEvent: any) => {
        if (
          files[0].type === 'audio/mpeg' ||
          files[0].type === 'audio/wav' ||
          files[0].type === 'audio/m4a'
        ) {
          const fileSize = files[0].size;
          if (fileSize) {
            if (hugeFileSizeChecker(fileSize)) {
              toast?.show('File size is over 5MB', {type: 'danger'});
            } else {
              props.onSelectSoundClip(files[0]);
            }
          }
        } else {
          toast?.show('File type is not supported', {type: 'danger'});
        }
      };

      reader.readAsDataURL(files[0]);
    }
  };

  return (
    <View>
      <TouchableWithoutFeedback onPress={triggerInputClick}>
        <View>
          <SVGIcon name="mute-5" size={20} />
          <input
            ref={input => (inputElement = input)}
            type="file"
            style={inputStyle}
            accept="audio/mpeg, audio/wav, audio/m4a"
            onChange={handleFileChange}
            onClick={event => {
              event.currentTarget.value = '';
            }}
          />
        </View>
      </TouchableWithoutFeedback>
    </View>
  );
};

export default SoundClipSelector;

const inputStyle = {
  display: 'none',
};
