import React from 'react';
import {createStackNavigator} from '@react-navigation/stack';

import {NavigationKey} from '../config/navigation.config';
import {AdminDeletedProfilesScreen} from '../../screens';

import {NavigationTitle} from '../config/navigationTitle';

const {Navigator, Screen} = createStackNavigator();

const AdminDeletedProfilesStackNavigator = () => {
  return (
    <Navigator initialRouteName={NavigationKey.ADMIN_DELETED_PROFILES_LIST}>
      <Screen
        name={NavigationKey.ADMIN_DELETED_PROFILES_LIST}
        component={AdminDeletedProfilesScreen}
        options={{
          headerShown: false,
          title: NavigationTitle.ADMIN_DELETED_PROFILES_LIST,
        }}
      />
    </Navigator>
  );
};

export default AdminDeletedProfilesStackNavigator;
