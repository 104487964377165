import React, {useState, useEffect} from 'react';
import {useRoute} from '@react-navigation/native';
import {View, Platform, ScrollView} from 'react-native';
import {makeStyles} from 'rne-netzon';
import {colorPalette} from '../../../core/config/color.config';

import {RNEText, SVGIcon, RNEInput} from '../../components';
import {MedicalAttachmentEnum} from '../../views/medical-attachments/data/medicalAttachment';
import AddMedicalCertificateView from '../../views/medical-attachments/AddMedicalCertificateView';
import AddPrescriptionView from '../../views/medical-attachments/AddPrescriptionView';
import AddLaboratoryRequestView from '../../views/medical-attachments/AddLaboratoryRequestView';

const AddMedicalAttachmentScreen = () => {
  const webStyles = useWebStyles();
  const route = useRoute();

  const [userId, setUserId] = useState('');
  const [basedOnId, setBasedOnId] = useState('');
  const [subject, setSubject] = useState('');
  const [age, setAge] = useState('');
  const [name, setName] = useState('');
  const [component, setComponent] = useState(
    <AddMedicalCertificateView basedOnId={basedOnId} userId={userId} />,
  );
  const [title, setTitle] = useState<string>('Medical Certificate');
  const [infoContainerMargin, setInfoContainerMargin] = useState<number>(40);

  useEffect(() => {
    const params = route.params as any;

    if (params) {
      setUserId(params.userId);
      setBasedOnId(params.basedOnId);
      setSubject(params.subject);
      setAge(params.age);
      setName(params.patientName);
    }

    switch (params.view) {
      case MedicalAttachmentEnum.MEDICAL_CERTIFICATE:
        setInfoContainerMargin(40);
        setTitle('Medical Certificate');
        setComponent(
          <AddMedicalCertificateView
            basedOnId={basedOnId}
            subject={subject}
            userId={userId}
          />,
        );
        break;
      case MedicalAttachmentEnum.PRESCRIPTION:
        setInfoContainerMargin(64);
        setTitle('E-prescription');
        setComponent(
          <AddPrescriptionView
            basedOnId={basedOnId}
            subject={subject}
            userId={userId}
          />,
        );
        break;
      case MedicalAttachmentEnum.LABORATORY_REQUEST:
        setInfoContainerMargin(40);
        setTitle('Laboratory Request');
        setComponent(
          <AddLaboratoryRequestView
            basedOnId={basedOnId}
            subject={subject}
            userId={userId}
          />,
        );
        break;
    }
  }, [route.params, basedOnId, subject, userId]);

  const infoContainerDynamicStyle = {
    marginBottom: infoContainerMargin,
  };

  return (
    <>
      {Platform.OS === 'web' ? (
        <ScrollView>
          <View style={webStyles.mainContainer}>
            <View style={webStyles.titleContainer}>
              <RNEText
                label="Home > Ongoing Consultation > "
                variant="body"
                color="grey"
              />
              <RNEText
                label={title}
                variant="body"
                color="grey"
                weight="bold"
              />
            </View>
            <View style={webStyles.headerContainer}>
              <View>
                <RNEText
                  label={title}
                  variant="header"
                  color="accent"
                  weight="bold"
                />
              </View>
            </View>

            <View style={webStyles.formContainer}>
              <View style={webStyles.leftContainer}>
                <View style={webStyles.subtitleContainer}>
                  <RNEText
                    label="Patient Details"
                    style={subtitleStyle}
                    variant="title"
                    weight="bold"
                  />
                </View>
                <View style={infoContainerDynamicStyle}>
                  <View style={webStyles.inputLabel}>
                    <RNEText
                      label="Patient Name"
                      variant="button"
                      color="accent"
                      weight="bold"
                    />
                  </View>
                  <RNEInput
                    placeholder="Patient Name"
                    variant="shadowed"
                    value={name}
                    disabled={true}
                    height={60}
                    inputContainerStyle={inputContainerWebStyle}
                  />
                </View>
                <View style={infoContainerDynamicStyle}>
                  <View style={webStyles.inputLabel}>
                    <RNEText
                      label="Age"
                      variant="button"
                      color="accent"
                      weight="bold"
                    />
                  </View>
                  <RNEInput
                    placeholder="Age"
                    variant="shadowed"
                    value={age}
                    disabled={true}
                    height={60}
                    inputContainerStyle={inputContainerWebStyle}
                  />
                </View>
                {/* <View style={infoContainerDynamicStyle}>
                  <View style={webStyles.inputLabel}>
                    <RNEText
                      label="Gender"
                      variant="button"
                      color="accent"
                      weight="bold"
                    />
                  </View>
                  <RNEInput
                    placeholder="Gender"
                    variant="shadowed"
                    disabled={true}
                    rightIcon={<SVGIcon name="arrow-down" />}
                    height={60}
                    inputContainerStyle={inputContainerWebStyle}
                  />
                </View> */}
              </View>
              <View style={webStyles.rightContainer}>{component}</View>
            </View>
          </View>
        </ScrollView>
      ) : (
        <ScrollView>{component}</ScrollView>
      )}
    </>
  );
};

export default AddMedicalAttachmentScreen;

const subtitleStyle = {
  fontSize: 16,
};

const inputContainerWebStyle = {
  borderRadius: 20,
};

const useWebStyles = makeStyles({
  mainContainer: {
    width: '100%',
    height: '100%',
    backgroundColor: '#F6F7FB',
  },
  titleContainer: {
    flexDirection: 'row',
    marginHorizontal: 100,
    paddingVertical: 20,
    borderBottomWidth: 1,
    borderBottomColor: colorPalette.grey,
  },
  headerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 100,
    paddingRight: 100,
    paddingVertical: 60,
  },
  formContainer: {
    backgroundColor: colorPalette.white,
    borderRadius: 20,
    marginHorizontal: 100,
    paddingHorizontal: 40,
    marginBottom: 80,
    flexDirection: 'row',
  },
  leftContainer: {
    flexShrink: 0,
    borderRightColor: 'rgba(229, 229, 229, 0.7)',
    borderRightWidth: 1,
    paddingVertical: 60,
    paddingRight: 32,
  },
  subtitleContainer: {
    marginBottom: 40,
    paddingLeft: 12,
  },
  inputLabel: {
    paddingLeft: 24,
    marginBottom: 4,
  },
  rightContainer: {
    flex: 3,
    paddingTop: 60,
    paddingLeft: 40,
  },
  textareaContainer: {
    marginTop: 52,
  },
  buttonContainer: {
    marginRight: 20,
  },
  buttonsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 32,
    marginBottom: 20,
    flexWrap: 'wrap',
  },
  rightButtonsContainer: {
    flexDirection: 'row',
    marginLeft: 20,
    flexWrap: 'wrap',
  },
  buttonStyle: {
    width: 140,
  },
});

const useMobileStyles = makeStyles({
  mainContainer: {
    width: '100%',
    flex: 1,
    padding: 52,
  },
  titleContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  iconContainer: {
    paddingBottom: 12,
  },
  bodyContainer: {
    paddingVertical: 40,
  },
  buttonContainer: {
    marginRight: 12,
  },
  buttonsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  rightButtonsContainer: {
    flexDirection: 'row',
    marginLeft: 20,
    flexWrap: 'wrap',
  },
});
