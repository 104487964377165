import React from 'react';

import {
  Platform,
  StyleSheet,
  View,
  Button,
  TouchableWithoutFeedback,
} from 'react-native';
import {SVGIcon, RNEIconButton} from '../../components';

const Indicator = (props: {isActive: boolean; onPress: () => void}) => {
  const backgroundColor = {
    backgroundColor: props.isActive ? '#2BA8E8' : '#D4EEFB',
  };

  return (
    <TouchableWithoutFeedback onPress={props.onPress}>
      <View style={[styles.indicator, backgroundColor]} />
    </TouchableWithoutFeedback>
  );
};

const Indicators = (props: {
  size: number;
  activeIndex: number;
  onIndicatorPress: (index: number) => void;
}) => {
  const children = Array.from({length: props.size}, (_, i) => (
    <Indicator
      key={i}
      isActive={i === props.activeIndex}
      onPress={() => props.onIndicatorPress(i)}
    />
  ));

  return <View style={styles.indicators}>{children}</View>;
};

interface ComponentProps {
  size: number;
  activeIndex: number;
  hideActions?: boolean;
  goBack: () => void;
  goNext: () => void;
  onComplete: () => void;
  onIndicatorPress: (index: number) => void;
}

const OnboardingActionPanel = (props: ComponentProps) => {
  return (
    <View style={styles.root}>
      {!props.hideActions && props.activeIndex !== props.size - 3 && (
        <View>
          <RNEIconButton
            icon={<SVGIcon name="left-blue" size={20} />}
            type="clear"
            size={48}
            onPress={props.goBack}
          />
        </View>
      )}

      <Indicators
        size={props.size}
        activeIndex={props.activeIndex}
        onIndicatorPress={props.onIndicatorPress}
      />

      {!props.hideActions && (
        <View>
          <RNEIconButton
            icon={<SVGIcon name="right" size={20} />}
            type="clear"
            size={48}
            onPress={props.goNext}
          />
        </View>
      )}

      {/* {!props.hideActions && props.activeIndex === props.size - 1 && (
        <Button onPress={props.onComplete} title="Complete" />
      )} */}
    </View>
  );
};

export default OnboardingActionPanel;

const styles = StyleSheet.create({
  root: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 30,
    height: 64,
  },
  indicators: {
    flex: 1,
    flexDirection: 'row',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  indicator: {
    width: 8,
    height: 8,
    borderRadius: 8,
    marginHorizontal: 8,
  },
});
