import React, {useState} from 'react';
import {View, Text, Platform, TextStyle, StyleProp} from 'react-native';

import {useForm, Controller, useWatch} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';

import {makeStyles, Divider} from 'rne-netzon';
import {colorPalette} from '../../../core/config/color.config';

import {RNEButton, RNEInput, RNEText} from '../../components';

import {useEndConsultationDetail} from '../../server/react-query/useConsultation';
import {ConsultationRequestResBody} from '../../server/types/consultation.types';

interface ConsultationFee {
  consultationFee: number;
}

const schema: yup.SchemaOf<ConsultationFee> = yup.object({
  consultationFee: yup
    .number()
    .required('This field is required.')
    .typeError('Amount must be a number')
    .min(0, 'Positive amount'),
});

interface ConsultationEndProps {
  serviceRequest: ConsultationRequestResBody;
  onCancel?: () => void;
  onComplete?: () => void;
}

const ConsultationEnd = (props: ConsultationEndProps) => {
  const styles = useStyles();

  const {
    control,
    handleSubmit,
    getValues,
    reset,
    setValue,
    formState: {errors, isValid},
  } = useForm<ConsultationFee>({
    mode: 'all',
    defaultValues: {
      consultationFee: props.serviceRequest.fee?.value || 0,
    },
    resolver: yupResolver(schema),
  });

  const formWatcher = useWatch({
    defaultValue: {
      consultationFee: props.serviceRequest.fee?.value || 0,
    },
    control: control,
  });

  const endMutation = useEndConsultationDetail({
    serviceRequestId: props.serviceRequest.serviceRequestId,
    procedureId: props.serviceRequest.procedure?.procedureId || '',
  });

  const onEndConsultation = () => {
    handleSubmit(onSubmit)();
  };

  const onSubmit = async () => {
    if (isValid) {
      try {
        await endMutation.mutateAsync();
        props.onComplete?.();
      } catch (error) {
        console.log('error', error);
      }
    }
  };

  return (
    <>
      <View style={styles.root}>
        <View style={styles.title}>
          <RNEText
            label="Ending Consultation"
            color="accent"
            variant="title"
            weight="600"
            align="center"
            style={fontStyle}
          />
        </View>

        <View style={styles.itemsContainer}>
          <RNEText
            label="Are you sure you want to end this consultation?"
            color="accent"
            variant="body"
            align="center"
          />
        </View>

        <View style={styles.actionsContainer}>
          <View style={styles.buttonContainer}>
            <RNEButton
              title="Cancel"
              color="secondary"
              type="outline"
              variant="outlined"
              onPress={props.onCancel}
            />
          </View>
          <View style={[styles.buttonContainer, styles.buttonSibling]}>
            <RNEButton
              title="Yes"
              color="secondary"
              onPress={onEndConsultation}
              loading={endMutation.isLoading}
            />
          </View>
        </View>
      </View>
    </>
  );
};

const fontStyle = {
  fontSize: 24,
};

const useStyles = makeStyles({
  root: {
    width: '100%',
    paddingVertical: 16,
    paddingHorizontal: Platform.OS === 'web' ? 0 : 32,
  },
  title: {
    marginTop: 16,
    marginLeft: Platform.OS === 'web' ? 0 : 16,
    alignItems: Platform.OS === 'web' ? 'center' : 'flex-start',
  },
  itemsContainer: {
    marginTop: 16,
  },
  itemContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 32,
    paddingLeft: 16,
    paddingRight: 32,
  },
  actionsContainer: {
    marginTop: 60,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonContainer: {
    width: Platform.OS === 'web' ? 150 : 120,
  },
  buttonSibling: {
    marginLeft: Platform.OS === 'web' ? 24 : 16,
  },
});

export default ConsultationEnd;
