import React, {memo} from 'react';
import {View, TouchableOpacity, StyleProp, TextStyle} from 'react-native';

import {makeStyles} from 'rne-netzon';

import {RNEText, RNEButton} from './../../components';

import {colorPalette} from '../../../core/config/color.config';
import {TextColor} from '../../components/rne/text/models';

interface DoctorDashboardCardProps {
  bgColor?: string;
  count?: number;
  label?: string;
  onPress?: () => void;
  isLoading?: boolean;
  labelColor?: TextColor;
  countColor?: TextColor;
  loaderColor?: string;
}

const DoctorDashboardCard = (props: DoctorDashboardCardProps) => {
  const styles = useStyle();

  const {
    bgColor = colorPalette.primary,
    count = 0,
    label = '',
    isLoading = false,
    labelColor = 'white',
    countColor = 'white',
    loaderColor = 'white',
  } = props;

  return (
    <TouchableOpacity activeOpacity={0.75} onPress={() => props.onPress?.()}>
      <View style={[styles.mainContainer, {backgroundColor: bgColor}]}>
        <View style={styles.countContainer}>
          {!isLoading ? (
            <RNEText
              label={count.toString().padStart(2, '0')}
              variant="header"
              weight="600"
              color={countColor}
              style={labelStyle}
            />
          ) : (
            <RNEButton
              height={24}
              loading={isLoading}
              type="clear"
              loadingProps={{
                color: loaderColor,
              }}
              buttonStyle={buttonStyle}
            />
          )}
        </View>
        <View style={[styles.labelContainer]}>
          <RNEText
            label={label}
            variant="button"
            color={labelColor}
            weight="600"
          />
        </View>
      </View>
    </TouchableOpacity>
  );
};

const labelStyle = {
  fontSize: 60,
  fontFamily: 'Lato-Bold',
};

const buttonStyle = {
  paddingHorizontal: 0,
};

const useStyle = makeStyles({
  mainContainer: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 48,
    paddingHorizontal: 32,
    borderRadius: 12,
    shadowOffset: {
      width: -5,
      height: 10,
    },
    shadowOpacity: 1,
    shadowRadius: 10,
    shadowColor: '#070F580D',
  },
  countStyle: {
    paddingRight: 12,
  },
  countContainer: {
    // height: 48,
    minWidth: 24,
    flexDirection: 'row',
    alignItems: 'center',
  },
  labelContainer: {
    flexDirection: 'row',
    flex: 1,
    flexWrap: 'wrap',
    marginTop: 8,
  },
});

export default memo(DoctorDashboardCard);
