import React, {memo, useEffect, useState} from 'react';
import {View, TouchableOpacity} from 'react-native';

import {makeStyles} from 'rne-netzon';
import {colorPalette} from '../../../core/config/color.config';
import {RNEText} from '../../components';

import useStores from '../../stores/useStores';

import {Photo} from '../../server/types/user.types';

import {default as ConsultationPhoto} from './ConsultationPhoto';
import {IRecentlyViewedAccount} from '../../stores/recentlyViewedAccountsStore';

interface ConsultationRecentlyViewedAccountProps {
  item: IRecentlyViewedAccount;
  onPress?: () => void;
}

const ConsultationRecentlyViewedAccount = (
  props: ConsultationRecentlyViewedAccountProps,
) => {
  const styles = useStyle();
  const {authStore} = useStores();

  const [displayName, setDisplayName] = useState('');
  const [displayPhoto, setDisplayPhoto] = useState<Photo>();

  useEffect(() => {
    switch (authStore.userRole) {
      case 'patient':
        if (props.item.performer) {
          setDisplayName(
            `Dr. ${props.item.performer?.humanName.given[0]} ${props.item.performer?.humanName.family}`,
          );
          setDisplayPhoto(props.item.performer?.photo);
        } else {
          setDisplayName('Deleted Profile');
        }
        break;

      case 'practitioner':
        if (props.item.subject) {
          setDisplayName(
            props.item.subject?.humanName
              ? `${props.item.subject?.humanName.given[0]} ${props.item.subject?.humanName.family}`
              : `"Patient ${props.item.subject.patientId
                  .substring(0, 6)
                  .toUpperCase()}"`,
          );
          setDisplayPhoto(props.item.subject?.photo);
        } else {
          setDisplayName('Deleted Profile');
        }
        break;

      default:
        break;
    }
  }, [authStore.userRole, props]);

  return (
    <TouchableOpacity activeOpacity={0.75} onPress={props.onPress}>
      <View style={styles.root}>
        <View style={styles.photo}>
          <ConsultationPhoto photo={displayPhoto} />
        </View>

        {authStore.userRole === 'patient' ? (
          <View style={styles.twoRowsContainer}>
            <View>
              <RNEText
                label={displayName}
                color="blackLight"
                weight="600"
                numberOfLines={1}
                ellipsizeMode="tail"
              />
            </View>

            <View>
              <RNEText
                label={
                  props.item.performer?.practitionerRole[0]?.specialty?.text ||
                  ''
                }
                color="blackLight"
                variant="button"
                weight="400"
                numberOfLines={2}
                ellipsizeMode="tail"
              />
            </View>
          </View>
        ) : (
          <View style={styles.oneRowContainer}>
            <RNEText
              label={displayName}
              color="blackLight"
              weight="600"
              numberOfLines={1}
              ellipsizeMode="tail"
            />
          </View>
        )}
      </View>
    </TouchableOpacity>
  );
};

const useStyle = makeStyles({
  root: {
    flexDirection: 'row',
    paddingHorizontal: 24,
    paddingVertical: 24,
    borderBottomWidth: 1,
    borderBottomColor: colorPalette.light,
  },
  photo: {},
  twoRowsContainer: {
    flex: 1,
    paddingHorizontal: 16,
    paddingTop: 4,
  },
  oneRowContainer: {
    paddingHorizontal: 16,
    alignSelf: 'center',
    marginBottom: 8,
  },
});

export default memo(ConsultationRecentlyViewedAccount);
