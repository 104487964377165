import React, {memo} from 'react';
import {Platform, View, ImageBackground} from 'react-native';
import {TouchableOpacity} from 'react-native-gesture-handler';

import {makeStyles, Rating} from 'rne-netzon';
import {colorPalette} from '../../../core/config/color.config';
import {RNEText, SVGIcon} from '../../components';

import {PractitionerProfile} from './../../server/types/practitioner-profile.types';

interface DoctorSearchItemProps {
  practitioner: PractitionerProfile;
  onViewProfile?: () => void;
}

const DoctorSearchItem = (props: DoctorSearchItemProps) => {
  const styles = useStyle();

  return (
    <View
      style={[
        styles.mainContainer,
        Platform.OS !== 'web' && styles.mainContainerMobile,
      ]}>
      <View style={styles.photoContainer}>
        {props.practitioner.photo?.url ? (
          <ImageBackground
            source={{
              uri: props.practitioner.photo.url,
            }}
            style={styles.photoStyle}
            imageStyle={styles.photoContainerStyle}
          />
        ) : (
          <View
            style={[
              styles.photoPlaceholder,
              Platform.OS !== 'web' && styles.photoPlaceholderMobile,
            ]}>
            <SVGIcon name="user" size={Platform.OS === 'web' ? 80 : 56} />
          </View>
        )}
      </View>

      <View style={styles.detailContainer}>
        <View style={styles.detailItem}>
          <RNEText
            label={`Dr. ${props.practitioner.humanName.given[0]} ${props.practitioner.humanName.family}`}
            color="accent"
            weight="600"
            ellipsizeMode="tail"
            numberOfLines={1}
          />
        </View>
        <View style={styles.detailItem}>
          <RNEText
            label={`${props.practitioner.practitionerRole[0].specialty.text}`}
            variant="button"
            ellipsizeMode="tail"
            numberOfLines={1}
          />
        </View>
        <View style={styles.detailItem}>
          <RNEText
            label={
              props.practitioner.organization[0].address?.city
                ? `${props.practitioner.organization[0].address?.city}`
                : '--'
            }
            variant="button"
            ellipsizeMode="tail"
            numberOfLines={1}
          />
        </View>
        <View style={[styles.detailItem, styles.ratingItem]}>
          {/* <Rating
            readonly
            ratingCount={5}
            imageSize={12}
            fractions={0}
            startingValue={5}
            jumpValue={1}
            style={styles.ratingStyle}
          /> */}
        </View>

        <View style={[styles.detailItem, styles.detailAction]}>
          <TouchableOpacity onPress={props.onViewProfile} activeOpacity={0.75}>
            <RNEText
              label="View Profile"
              color="secondary"
              variant="button"
              weight="600"
            />
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
};

const useStyle = makeStyles({
  mainContainer: {
    height: '100%',
    flexDirection: 'row',
    alignItems: Platform.OS === 'web' ? 'center' : 'center',
    padding: Platform.OS === 'web' ? 16 : 24,
    borderRadius: 12,
    shadowOffset: {
      width: -5,
      height: 10,
    },
    shadowOpacity: 1,
    shadowRadius: 10,
    shadowColor: '#070F580D',
  },
  mainContainerMobile: {
    shadowColor: colorPalette.grey,
    elevation: 16,
    backgroundColor: colorPalette.white,
  },
  photoContainer: {
    width: Platform.OS === 'web' ? 100 : 96,
    height: Platform.OS === 'web' ? '100%' : 96,
    borderRadius: Platform.OS === 'web' ? 16 : 48,
  },
  photoContainerStyle: {
    borderRadius: Platform.OS === 'web' ? 16 : 48,
  },
  photoStyle: {
    flex: 1,
    justifyContent: 'center',
    resizeMode: 'cover',
  },
  photoPlaceholder: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  photoPlaceholderMobile: {
    borderWidth: 1,
    borderColor: colorPalette.primary,
    borderRadius: 48,
  },
  detailContainer: {
    flex: 1,
    marginLeft: 16,
  },
  detailItem: {
    marginBottom: 4,
  },
  detailAction: {
    marginTop: 12,
  },
  ratingItem: {
    marginTop: 4,
  },
  ratingStyle: {
    alignSelf: 'flex-start',
  },
});

export default memo(DoctorSearchItem);
