import {AxiosRequestConfig} from 'axios';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {API, ApiEndpointsEnum} from '../api';

import {
  CreateInvoiceReqBody,
  CreatePaymentNoticeReqBody,
  GetInvoiceReqBody,
  GetTemporaryTokenReqBody,
  InvoiceResBody,
  TemporaryTokenResBody,
} from '../types/payment.types';
import {convertJsonToParams} from '../utils/query.util';

const createInvoice = async (reqBody: CreateInvoiceReqBody) => {
  return API.post<InvoiceResBody>(`${ApiEndpointsEnum.POST_INVOICE}`, reqBody);
};

const useCreateInvoice = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ['create-invoice'],
    (reqBody: CreateInvoiceReqBody) => createInvoice(reqBody),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['create-invoice']);
      },
    },
  );
};

const getInvoice = (reqBody: GetInvoiceReqBody) => {
  let config: AxiosRequestConfig = {};

  if (reqBody.patientId) {
    const headerAuthorization: any = {
      'X-Subject': reqBody.patientId,
    };

    config = {
      headers: {
        ...headerAuthorization,
      },
    };
  }

  const queryParams = convertJsonToParams(reqBody as any);
  const url = ApiEndpointsEnum.GET_INVOICE + '?' + queryParams;
  return API.get<InvoiceResBody[]>(url, config);
};

const useGetInvoice = (reqBody: GetInvoiceReqBody) => {
  return useQuery(['get-invoice', {id: reqBody.serviceId}], () =>
    getInvoice(reqBody),
  );
};

const createPaymentNotice = async (reqBody: CreatePaymentNoticeReqBody) => {
  let config: AxiosRequestConfig = {};

  if (reqBody.patientId) {
    const headerAuthorization: any = {
      'X-Subject': reqBody.patientId,
    };

    config = {
      headers: {
        ...headerAuthorization,
      },
    };
  }

  const queryParams = convertJsonToParams(reqBody as any);
  const url = ApiEndpointsEnum.POST_PAYMENT_NOTICE + '?' + queryParams;
  return API.post<InvoiceResBody>(url, config);
};

const useCreatePaymentNotice = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ['create-payment-notice'],
    (reqBody: CreatePaymentNoticeReqBody) => createPaymentNotice(reqBody),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['create-payment-notice']);
      },
    },
  );
};

const generateTemporaryToken = async (reqBody: GetTemporaryTokenReqBody) => {
  let config: AxiosRequestConfig = {};

  if (reqBody.patientId) {
    const headerAuthorization: any = {
      'X-Subject': reqBody.patientId,
    };

    config = {
      headers: {
        ...headerAuthorization,
      },
    };
  }

  const url = ApiEndpointsEnum.POST_TEMPORARY_TOKEN.replace(
    '{invoiceId}',
    reqBody.invoiceId,
  );

  return API.post<TemporaryTokenResBody>(url, null, config);
};

const useGenerateTemporaryToken = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ['create-temporary-token'],
    (reqBody: GetTemporaryTokenReqBody) => generateTemporaryToken(reqBody),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['create-temporary-token']);
      },
    },
  );
};

const getInvoiceViaTempToken = (temporaryToken: string) => {
  let config: AxiosRequestConfig = {};

  if (temporaryToken) {
    const headerAuthorization: any = {
      'X-Invoice-Authorization': temporaryToken,
    };

    config = {
      headers: {
        ...headerAuthorization,
      },
    };
  }

  const url = ApiEndpointsEnum.GET_INVOICE;
  return API.get<InvoiceResBody[]>(url, config);
};

const useGetInvoiceViaTempToken = (temporaryToken: string) => {
  return useQuery(['get-invoice-via-temp-token', {id: temporaryToken}], () =>
    getInvoiceViaTempToken(temporaryToken),
  );
};

export {
  useCreateInvoice,
  useGetInvoice,
  useCreatePaymentNotice,
  useGenerateTemporaryToken,
  useGetInvoiceViaTempToken,
};
