import React from 'react';
import {
  StyleSheet,
  Image,
  View,
  TouchableOpacity,
  Dimensions,
} from 'react-native';

import {observer} from 'mobx-react';
import {useNavigation} from '@react-navigation/native';

import {SVGIcon, RNEText} from '../../components';

import {LogoContrast, LogoLight} from './../../images/Image';

import useStores from '../../stores/useStores';

import {colorPalette} from '../../../core/config/color.config';

interface HeaderProfileLeftProps {
  hasBack?: boolean;
  inProfileScreen?: boolean;
  isLogoContrast?: boolean;
}

const HeaderProfileLeft = (props: HeaderProfileLeftProps) => {
  const {patientProfileStore} = useStores();
  const navigation = useNavigation();

  const activeProfile = patientProfileStore.activeProfileData;

  const onBackClick = () => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    }
  };

  return (
    <View accessibilityRole="button" style={mobileStyles.mainContainer}>
      {props.hasBack && (
        <TouchableOpacity activeOpacity={0.75} onPress={onBackClick}>
          <View style={mobileStyles.backContainer}>
            <SVGIcon name="left-gray" size={20} />
          </View>
        </TouchableOpacity>
      )}

      {props.inProfileScreen ? (
        <View>
          <Image
            source={props.isLogoContrast ? LogoContrast : LogoLight}
            style={mobileStyles.logo}
          />
        </View>
      ) : (
        <View style={mobileStyles.profileContainer}>
          <View style={mobileStyles.profilePhoto}>
            {activeProfile?.imageUrl ? (
              <Image
                source={{uri: activeProfile.imageUrl}}
                style={mobileStyles.imageStyle}
              />
            ) : (
              <SVGIcon name="user" size={20} />
            )}
          </View>
          <RNEText
            label={`${activeProfile?.firstName} ${activeProfile?.lastName}`}
            variant="button"
            color="primary"
            weight="bold"
            numberOfLines={1}
            // eslint-disable-next-line react-native/no-inline-styles
            style={{
              paddingHorizontal: 12,
              width:
                Dimensions.get('screen').width - (props.hasBack ? 180 : 150),
            }}
          />
        </View>
      )}
    </View>
  );
};

export default observer(HeaderProfileLeft);

const mobileStyles = StyleSheet.create({
  mainContainer: {
    paddingHorizontal: 20,
    flexDirection: 'row',
    alignItems: 'center',
  },
  backContainer: {
    marginRight: 16,
  },
  logo: {
    width: 120,
    height: 40,
    position: 'relative',
  },
  profileContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  profilePhoto: {
    borderRadius: 50,
    borderWidth: 1,
    borderColor: colorPalette.primary,
    width: 36,
    height: 36,
    alignItems: 'center',
    justifyContent: 'center',
  },
  imageStyle: {
    height: 36,
    width: 36,
    borderRadius: 100,
    borderWidth: 1,
    borderColor: colorPalette.primary,
  },
});
