import React, {forwardRef, useEffect} from 'react';
import {Platform, StyleProp, TextInput, TextStyle} from 'react-native';
import {Input, InputProps, makeStyles, useTheme} from 'rne-netzon';

const RNEBoxInputSize = 48;
const RNEBoxInputRadius = 12;
const RNEBoxInputPadding = 4;

const RNEBoxStyle: StyleProp<TextStyle> = {
  paddingLeft: 0,
  paddingRight: 0,
};

const RNEBoxInputStyle: StyleProp<TextStyle> = {
  textAlign: 'center',
  ...(Platform.OS === 'web'
    ? {
        outline: 'none',
      }
    : {}),
};

interface RNEBoxInputProps extends InputProps {
  size?: number;
  backgroundColor?: string;
}

export const RNEBoxInput = forwardRef<TextInput, RNEBoxInputProps>(
  (props: RNEBoxInputProps, ref) => {
    const {theme} = useTheme();

    const {
      size = RNEBoxInputSize,
      backgroundColor = theme.colors?.white,
      ...others
    } = props;

    return (
      <Input
        ref={ref}
        {...others}
        containerStyle={{
          width: size,
          height: size,
        }}
        inputContainerStyle={{
          width: size,
          height: size,
          backgroundColor: backgroundColor,
          borderRadius: RNEBoxInputRadius,
          paddingHorizontal: RNEBoxInputPadding,
        }}
        inputStyle={RNEBoxInputStyle}
        style={RNEBoxStyle}
      />
    );
  },
);
