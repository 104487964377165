export enum PractionerTableSortField {
  CREATED_ON = 'createdOn',
  LASTNAME = 'lastName',
  PROFESSION = 'profession',
  QUALIFICATION = 'qualification',
  BIRTHDATE = 'birthdate',
  DECLINED_ON = 'declinedOn',
  EMAIL = 'email',
}

export enum PatientTableSortField {
  CREATED_ON = 'createdOn',
  LASTNAME = 'lastName',
  LOCATION = 'location',
  PROFILE_COUNT = 'profileCount',
  EMAIL = 'email',
}
