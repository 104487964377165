import React from 'react';
import {StyleSheet, View, TouchableWithoutFeedback} from 'react-native';

import {SVGIcon, RNEText} from '../../components';

import {useNavigation} from '@react-navigation/native';
import {IconName} from '../../components/icon/iconSource';
import {colorPalette} from '../../../core/config/color.config';
import {NavigationKey} from '../../navigations/config';

const textPadding = {
  paddingLeft: 12,
  paddingTop: 2,
};

type HeaderLeftBackIconName = Extract<
  IconName,
  'left' | 'left-black' | 'left-blue' | 'left-white' | 'left-gray'
>;

interface HeaderBackLeftProps {
  label?: string;
  iconName?: HeaderLeftBackIconName;
  removeLabel?: boolean;
  navigationKey?: NavigationKey;
}

/**
 * TODO: labels should be a prop and is optional (e.g. back icon only without label)
 */
const HeaderBackLeft = (props: HeaderBackLeftProps) => {
  // for now label will have a default value of `Back`
  // we'll use `left-black` icon as a default for now;
  const {label = 'Back', iconName = 'left-black', removeLabel = false} = props;

  const navigation = useNavigation();

  const goBack = () => {
    if (props.navigationKey) {
      navigation.navigate(props.navigationKey);
    } else {
      navigation.goBack();
    }
  };

  return (
    <TouchableWithoutFeedback onPress={goBack}>
      <View accessibilityRole="button" style={mobileStyles.mainContainer}>
        <SVGIcon name={iconName} size={20} />
        {label && !removeLabel && (
          <RNEText label={label} variant="button" style={textPadding} />
        )}
      </View>
    </TouchableWithoutFeedback>
  );
};

export default HeaderBackLeft;

const mobileStyles = StyleSheet.create({
  mainContainer: {
    flexDirection: 'row',
    paddingHorizontal: 20,
  },
});
