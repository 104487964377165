import React, {memo, useEffect} from 'react';
import {View} from 'react-native';

import {makeStyles} from 'rne-netzon';

import {ConsultationRequestResBody} from '../../server/types/consultation.types';
import useConversation from '../../hooks/conversation/useConversation';

import useStores from '../../stores/useStores';

import ConsultationRequestMessage from './ConsultationRequestMessage';
import ConsultationMessageAttachment from './ConsultationMessageAttachment';
import ConsultationMessages from './ConsultationMessages';
import {useGetInvoice} from '../../server/react-query/usePayment';
import {ConsultationInformationMessage} from '..';
import {formatAmount} from '../../utils/currency.utils';

interface ConsultationDetailOngoingProps {
  serviceRequest: ConsultationRequestResBody;
}

const ConsultationDetailOngoing = (props: ConsultationDetailOngoingProps) => {
  const {authStore} = useStores();
  const styles = useStyles();
  const {initialized, getConversation, conversation} = useConversation();

  const {data} = useGetInvoice({
    serviceId: props.serviceRequest.procedure?.procedureId || '',
    patientId: props.serviceRequest.subject?.patientId,
  });

  useEffect(() => {
    if (initialized) {
      const procedureId = props.serviceRequest.procedure?.procedureId;

      if (procedureId) {
        getConversation(procedureId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialized, props.serviceRequest.procedure?.procedureId]);

  return (
    <View style={styles.body}>
      <ConsultationRequestMessage
        message={props.serviceRequest.note?.[0]?.text}
        timestamp={new Date(props.serviceRequest.note?.[0]?.time)}
        owner={authStore.userRole === 'patient'}
      />

      {authStore.userRole === 'practitioner' && (
        <ConsultationMessageAttachment
          messageType={
            authStore.userRole === 'practitioner' ? 'incoming' : 'outgoing'
          }
          attribute={{
            Type: 'history',
          }}
          serviceRequest={props.serviceRequest}
        />
      )}

      {data && (
        <ConsultationInformationMessage
          timestamp={
            data && data[0] && data[0].date
              ? new Date(data[0].date)
              : new Date()
          }
          fee={formatAmount(data.length ? data[0].totalGross.value : 0)}
        />
      )}

      {conversation && (
        <ConsultationMessages
          conversation={conversation}
          serviceRequest={props.serviceRequest}
        />
      )}
    </View>
  );
};

const useStyles = makeStyles({
  body: {
    paddingVertical: 12,
  },
});

export default memo(ConsultationDetailOngoing);
