import React from 'react';
import {StyleSheet, View, TouchableWithoutFeedback} from 'react-native';

import {SVGIcon} from '../../components';
import {useNavigation} from '@react-navigation/native';
import {NavigationKey} from '../../navigations/config';

interface HeaderProfileRightProps {
  showMessageIcon?: boolean;
  isMenuLight?: boolean;
  onClick?: () => void;
}

const HeaderProfileRight = (props: HeaderProfileRightProps) => {
  const navigation = useNavigation();

  return (
    <View style={mobileStyles.iconsContainer}>
      {props.showMessageIcon && (
        <TouchableWithoutFeedback
          onPress={() =>
            navigation.navigate(NavigationKey.PATIENT_CONSULTATION_STACK, {
              screen: NavigationKey.CONSULTATION_LIST,
              params: {
                screen: NavigationKey.CONSULTATION_TAB_ONGOING,
              },
            })
          }>
          <View style={mobileStyles.iconContainer}>
            <SVGIcon name="email" size={20} />
          </View>
        </TouchableWithoutFeedback>
      )}
      <TouchableWithoutFeedback onPress={props.onClick}>
        <View style={mobileStyles.iconContainer}>
          <SVGIcon
            name={props.isMenuLight ? 'menu-white-59' : 'menu'}
            size={20}
          />
        </View>
      </TouchableWithoutFeedback>
    </View>
  );
};

export default HeaderProfileRight;

const mobileStyles = StyleSheet.create({
  iconsContainer: {
    flexDirection: 'row',
    paddingHorizontal: 12,
  },
  iconContainer: {
    paddingHorizontal: 8,
  },
});
