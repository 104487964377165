import React, {memo, useMemo} from 'react';
import {useNavigation} from '@react-navigation/core';
import {View, Platform} from 'react-native';

import {makeStyles} from 'rne-netzon';
import {NavigationKey} from '../../navigations/config';
import {
  NotificationsResBody,
  NotificationType,
} from '../../server/types/notification.types';

import {
  RNEIconButton,
  RNEText,
  ShadowedCard,
  SVGIcon,
} from './../../components';

interface NotificationItem {
  title: string;
  subtitle: string;
  stack: NavigationKey;
  screen: NavigationKey;
  consultationRelated: boolean;
  tab?: number;
}

interface NotificationCardProps {
  notification: NotificationsResBody;
}

const NotificationCard = (props: NotificationCardProps) => {
  const styles = useStyles();
  const navigation = useNavigation();

  const {notification} = props;

  const redirect = () => {
    const {stack, screen, consultationRelated, tab} = notificationItem;

    if (stack && screen && consultationRelated) {
      navigation.navigate(stack, {
        screen: screen,
        params: {
          tab: tab,
          screen: tab && getEquivalentScreen(tab),
          id: notification.content.ServiceRequestId,
        },
      });
    } else if (stack && screen) {
      navigation.navigate(stack, {
        screen: screen,
      });
    }
  };

  const getEquivalentScreen = (tabNum: number) => {
    let screenName = '';
    switch (tabNum) {
      case 0:
        screenName = NavigationKey.CONSULTATION_TAB_PENDING;
        break;

      case 1:
        screenName = NavigationKey.CONSULTATION_TAB_ONGOING;
        break;

      case 2:
        screenName = NavigationKey.CONSULTATION_TAB_HISTORY;
        break;
    }
    return screenName;
  };

  const notificationItem: NotificationItem = useMemo(() => {
    switch (notification.type) {
      case NotificationType.CONSULTATION_REQUEST:
        return {
          title: 'Consultation Request',
          subtitle: 'You have received a consultation request.',
          stack: NavigationKey.DOCTOR_CONSULTATION_STACK,
          screen: NavigationKey.CONSULTATION_DETAIL,
          consultationRelated: true,
          tab: 1,
        };

      case NotificationType.CONSULTATION_CANCELLED:
        return {
          title: 'Consultation Cancelled',
          subtitle: 'A consultation has been cancelled by a patient.',
          stack: NavigationKey.DOCTOR_CONSULTATION_STACK,
          screen: NavigationKey.CONSULTATION_DETAIL,
          consultationRelated: true,
          tab: 2,
        };

      case NotificationType.CONSULTATION_ACCEPTED:
        return {
          title: 'Consultation Accepted',
          subtitle:
            'Your consultation request has been accepted by the doctor. You can now pay for the consultation.',
          stack: NavigationKey.PATIENT_CONSULTATION_STACK,
          screen: NavigationKey.CONSULTATION_DETAIL,
          consultationRelated: true,
          tab: 0,
        };

      case NotificationType.CONSULTATION_DECLINED:
        return {
          title: 'Consultation Declined',
          subtitle:
            'Your consultation request has been declined by the doctor.',
          stack: NavigationKey.PATIENT_CONSULTATION_STACK,
          screen: NavigationKey.CONSULTATION_DETAIL,
          consultationRelated: true,
          tab: 2,
        };

      case NotificationType.CONSULTATION_PAID:
        return {
          title: 'Consultation Paid',
          subtitle:
            'A consultation has been paid by a patient. You can start the consultation.',
          stack: NavigationKey.DOCTOR_CONSULTATION_STACK,
          screen: NavigationKey.CONSULTATION_DETAIL,
          consultationRelated: true,
          tab: 0,
        };

      case NotificationType.CONSULTATION_COMPLETED:
        return {
          title: 'Consultation Completed',
          subtitle:
            'Your consultation has been set as completed by the doctor.',
          stack: NavigationKey.PATIENT_CONSULTATION_STACK,
          screen: NavigationKey.CONSULTATION_DETAIL,
          consultationRelated: true,
          tab: 2,
        };

      case NotificationType.PRACTITIONER_PROFILE_PENDING:
        return {
          title: 'Profile Pending Review',
          subtitle:
            'You have sent a request to verify your profile. Verification process normally takes 24 to 48 hours.',
          stack: NavigationKey.DOCTOR_PROFILE_STACK,
          screen: NavigationKey.DOCTOR_VIEW_PROFESSIONAL_PROFILE,
          consultationRelated: false,
        };

      case NotificationType.PRACTITIONER_PROFILE_DECLINED:
        return {
          title: 'Profile Declined',
          subtitle:
            'Your verification request has been declined. Please ensure you have provided the complete and correct details.',
          stack: NavigationKey.DOCTOR_PROFILE_STACK,
          screen: NavigationKey.DOCTOR_VIEW_PROFESSIONAL_PROFILE,
          consultationRelated: false,
        };

      case NotificationType.PRACTITIONER_PROFILE_VERIFIED:
        return {
          title: 'Profile Verified',
          subtitle:
            'Your verification request has been accepted. Patients will now be able to see your profile.',
          stack: NavigationKey.DOCTOR_PROFILE_STACK,
          screen: NavigationKey.DOCTOR_VIEW_PROFESSIONAL_PROFILE,
          consultationRelated: false,
        };

      case NotificationType.ATTACHED_DIAGNOSTIC_REPORT:
        return {
          title: 'Diagnostic Report Attached',
          subtitle:
            'The doctor attached a diagnostic report in your consultation chat.',
          stack: NavigationKey.PATIENT_CONSULTATION_STACK,
          screen: NavigationKey.CONSULTATION_DETAIL,
          consultationRelated: true,
          tab: 0,
        };

      case NotificationType.ATTACHED_MEDICATION_REQUEST:
        return {
          title: 'Medication Request Attached',
          subtitle:
            'The doctor attached a medication request in your consultation chat.',
          stack: NavigationKey.PATIENT_CONSULTATION_STACK,
          screen: NavigationKey.CONSULTATION_DETAIL,
          consultationRelated: true,
          tab: 0,
        };

      case NotificationType.ATTACHED_LABORATORY_REQUEST:
        return {
          title: 'Laboratory Request Attached',
          subtitle:
            'The doctor attached a laboratory request in your consultation chat.',
          stack: NavigationKey.PATIENT_CONSULTATION_STACK,
          screen: NavigationKey.CONSULTATION_DETAIL,
          consultationRelated: true,
          tab: 0,
        };

      case NotificationType.MESSAGE_ADDED:
        return {
          title: 'New Message',
          subtitle: 'Your consultation chat has a new message.',
          stack: NavigationKey.DOCTOR_CONSULTATION_STACK,
          screen: NavigationKey.CONSULTATION_DETAIL,
          consultationRelated: true,
          tab: 0,
        };
    }
  }, [notification]);

  return (
    <>
      {Platform.OS === 'web' ? (
        <ShadowedCard height={120} onPress={redirect}>
          <View style={styles.textContainer}>
            <RNEText
              label={notificationItem?.title || ''}
              variant="title"
              weight="bold"
              color="primary"
              style={titleFontSizeWeb}
            />
            <View style={styles.subtitleContainer}>
              <RNEText
                label={notificationItem?.subtitle || ''}
                variant="body"
              />
            </View>
          </View>
          <RNEIconButton
            icon={<SVGIcon name="check-white" size={32} />}
            color="secondary"
            size={48}
          />
        </ShadowedCard>
      ) : (
        <ShadowedCard height={120} onPress={redirect}>
          <View style={styles.textContainer}>
            <RNEText
              label={notificationItem?.title || ''}
              variant="title"
              weight="bold"
              style={titleFontSizeMobile}
            />
          </View>
          <RNEIconButton
            icon={<SVGIcon name="check-white" size={32} />}
            color="secondary"
            size={48}
            raised={true}
          />
        </ShadowedCard>
      )}
    </>
  );
};

export default memo(NotificationCard);

const titleFontSizeWeb = {
  fontSize: 20,
};

const titleFontSizeMobile = {
  fontSize: 16,
};

const useStyles = makeStyles({
  textContainer: {
    width: Platform.OS === 'web' ? '95%' : '85%',
  },
  subtitleContainer: {
    marginTop: 4,
  },
});
