/* eslint-disable no-catch-shadow */
/* eslint-disable no-shadow */
import {useNavigation} from '@react-navigation/native';
import React, {memo} from 'react';
import {useEffect} from 'react';
import {useState} from 'react';
import {Platform, ScrollView, View} from 'react-native';
import {useToast} from 'react-native-fast-toast';

import {makeStyles} from 'rne-netzon';
import {colorPalette} from '../../../core/config/color.config';
import {RNEText, SVGIcon, RNEInput, RNEButton} from '../../components';
import {
  useGetAllergyIntolerance,
  usePutAllergyIntolerance,
} from '../../server/react-query/useMedicalHistory';
import useStores from '../../stores/useStores';
import {formatDateString} from '../../utils/date.utils';

interface AllergiesViewProps {
  onCancel: () => void;
  onSuccess: () => void;
}

const AllergiesView = (props: AllergiesViewProps) => {
  const sharedStyles = useSharedStyles();
  const webStyles = useWebStyles();
  const {patientProfileStore} = useStores();
  const mobileStyles = useMobileStyles();
  const navigation = useNavigation();
  const toast = useToast();
  const patientId = patientProfileStore.activeProfileData?.patientId!;
  const {status, data, error, isFetching} = useGetAllergyIntolerance({
    patientId: patientId,
  });

  const [value, setValue] = useState(
    data?.[0]?.note.text ? data?.[0]?.note.text : '',
  );

  const mutation = usePutAllergyIntolerance([value], patientId);

  const onSave = async () => {
    try {
      await mutation.mutateAsync();

      mutation.reset();
      if (Platform.OS === 'web') {
        props.onSuccess();
      } else {
        toast?.show('Successfully updated the medical data');
        navigation.goBack();
      }
    } catch (error) {
      if (error?.data?.error) {
        toast?.show(error?.data?.error, {type: 'danger'});
      }
    }
  };

  useEffect(() => {
    setValue(data?.[0]?.note.text ? data?.[0]?.note.text : '');
  }, [data]);

  return (
    <View style={sharedStyles.root}>
      {Platform.OS === 'web' ? (
        <ScrollView>
          <View style={webStyles.mainContainer}>
            <View style={webStyles.topContainer}>
              <View style={webStyles.textContainer}>
                <View style={webStyles.titleContainer}>
                  <RNEText
                    label="Allergies"
                    color="accent"
                    variant="title"
                    weight="bold"
                  />
                  <View style={webStyles.spinnerContainer}>
                    <RNEButton
                      height={20}
                      loadingProps={loadingPropsStyle}
                      loading={isFetching}
                    />
                  </View>
                </View>
                <RNEText
                  label="What food, medications, or others are you allergic to? (Example: shrimp, ibuprofen, etc.)"
                  variant="body"
                  style={subtitleStyle}
                />
              </View>
              <View style={webStyles.iconContainer}>
                <SVGIcon name="hc-icon-allergies" size={60} />
              </View>
            </View>
            <View style={webStyles.bodyContainer}>
              <RNEInput
                height={200}
                variant="shadowed"
                value={value}
                onChangeText={text => setValue(text)}
                multiline={true}
                inputStyle={inputStyle}
                inputContainerStyle={inputContainerWebStyle}
              />
            </View>
            <View style={webStyles.footerContainer}>
              <View style={webStyles.dateContainer}>
                <RNEText
                  label={
                    data?.[0]?.modifiedOn
                      ? `Date Updated: ${formatDateString(
                          data?.[0]?.modifiedOn,
                        )}`
                      : ''
                  }
                  variant="button"
                />
              </View>
              <View style={webStyles.buttonsContainer}>
                <View style={webStyles.buttonContainer}>
                  <RNEButton
                    title="Cancel"
                    color="secondary"
                    onPress={props.onCancel}
                    style={webStyles.buttonStyle}
                    variant="outlined"
                  />
                </View>
                <RNEButton
                  title="Save"
                  color="secondary"
                  onPress={onSave}
                  style={webStyles.buttonStyle}
                  loading={mutation.isLoading}
                />
              </View>
            </View>
          </View>
        </ScrollView>
      ) : (
        <ScrollView>
          <View style={mobileStyles.mainContainer}>
            <View>
              <View style={mobileStyles.iconContainer}>
                <SVGIcon name="hc-icon-allergies" size={60} />
              </View>
              <View style={mobileStyles.titleContainer}>
                <RNEText
                  label="Allergies"
                  color="accent"
                  variant="title"
                  weight="bold"
                />
                <RNEButton
                  height={24}
                  loadingProps={loadingPropsStyle}
                  loading={isFetching}
                />
              </View>
              <RNEText
                label="What food, medications, or others are you allergic to? (Example: shrimp, ibuprofen, etc.)"
                variant="body"
              />
            </View>
            <View style={mobileStyles.bodyContainer}>
              <RNEInput
                height={240}
                value={value}
                onChangeText={text => setValue(text)}
                multiline={true}
                textAlignVertical="top"
                inputStyle={inputStyle}
                inputContainerStyle={inputContainerMobileStyle}
              />
            </View>
            <View style={mobileStyles.footerContainer}>
              <View style={mobileStyles.buttonsContainer}>
                <View style={mobileStyles.buttonContainer}>
                  <RNEButton
                    title="Cancel"
                    color="secondary"
                    onPress={() => navigation.goBack()}
                    variant="outlined"
                  />
                </View>
                <View
                  style={[
                    mobileStyles.buttonContainer,
                    mobileStyles.buttonSibling,
                  ]}>
                  <RNEButton
                    title="Save"
                    color="secondary"
                    onPress={onSave}
                    loading={mutation.isLoading}
                  />
                </View>
              </View>
              <View style={mobileStyles.dateContainer}>
                <RNEText
                  label={
                    data?.[0]?.modifiedOn
                      ? `Date Updated: ${formatDateString(
                          data?.[0]?.modifiedOn,
                        )}`
                      : ''
                  }
                  variant="button"
                />
              </View>
            </View>
          </View>
        </ScrollView>
      )}
    </View>
  );
};

const subtitleStyle = {
  width: '80%',
};

const loadingPropsStyle = {
  color: colorPalette.primary,
};

const inputContainerWebStyle = {
  padding: 20,
};

const inputContainerMobileStyle = {
  borderRadius: 12,
  paddingTop: 8,
  paddingBottom: 16,
};

const inputStyle = {
  lineHeight: 24,
};

const useWebStyles = makeStyles({
  mainContainer: {
    width: '100%',
    flex: 1,
  },
  topContainer: {
    flexDirection: 'row',
  },
  titleContainer: {
    flexDirection: 'row',
  },
  spinnerContainer: {
    alignSelf: 'flex-end',
    marginBottom: 8,
  },
  iconContainer: {
    justifyContent: 'flex-end',
    paddingRight: 20,
  },
  bodyContainer: {
    paddingVertical: 60,
    marginLeft: 8,
  },
  footerContainer: {
    marginTop: 20,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  dateContainer: {
    justifyContent: 'center',
  },
  buttonsContainer: {
    flexDirection: 'row',
  },
  buttonContainer: {
    marginRight: 16,
  },
  buttonStyle: {
    width: 120,
  },
  textContainer: {
    flex: 1,
  },
});

const useMobileStyles = makeStyles({
  mainContainer: {
    width: '100%',
    flex: 1,
    padding: 52,
  },
  titleContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  iconContainer: {
    paddingBottom: 12,
  },
  bodyContainer: {
    paddingVertical: 40,
  },
  footerContainer: {
    marginTop: 20,
  },
  buttonsContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  buttonContainer: {
    width: 100,
  },
  buttonSibling: {
    marginLeft: 16,
  },
  dateContainer: {
    marginTop: 60,
  },
});

const useSharedStyles = makeStyles({
  root: {
    width: '100%',
    flexDirection: 'column',
  },
});
export default memo(AllergiesView);
