import React from 'react';
import {Platform} from 'react-native';
import {createDrawerNavigator} from '@react-navigation/drawer';
import {makeStyles} from 'rne-netzon';

import {NavigationKey} from '../config/navigation.config';

import AdminLandingStackNavigator from './AdminLandingStackNavigator';
import AdminDoctorsStackNavigator from './AdminDoctorsStackNavigator';
import AdminPatientsStackNavigator from './AdminPatientsStackNavigator';
import AdminDeletedProfilesStackNavigator from './AdminDeletedProfilesStackNavigator';

import {AdminSideMenu} from '../../views';

const {Navigator, Screen} = createDrawerNavigator();

const AdminDrawerNavigator = () => {
  const drawerStyles = useDrawerStyles();
  const drawerPosition = Platform.OS === 'web' ? 'left' : 'right';
  const drawerType = Platform.OS === 'web' ? 'permanent' : 'front';

  return (
    <Navigator
      initialRouteName={NavigationKey.ADMIN_LANDING}
      drawerPosition={drawerPosition}
      drawerType={drawerType}
      drawerStyle={
        Platform.OS === 'web' ? drawerStyles.webWidth : drawerStyles.mobileWidth
      }
      drawerContent={() => <AdminSideMenu />}>
      <Screen
        name={NavigationKey.ADMIN_LANDING}
        component={AdminLandingStackNavigator}
      />
      <Screen
        name={NavigationKey.ADMIN_DOCTORS}
        component={AdminDoctorsStackNavigator}
      />
      <Screen
        name={NavigationKey.ADMIN_PATIENTS_LST}
        component={AdminPatientsStackNavigator}
      />
      <Screen
        name={NavigationKey.ADMIN_DELETED_PROFILES}
        component={AdminDeletedProfilesStackNavigator}
      />
    </Navigator>
  );
};

export default AdminDrawerNavigator;

const useDrawerStyles = makeStyles({
  webWidth: {
    width: 'auto',
    borderRightColor: 'rgba(229, 229, 229, 0.4)',
    borderRightWidth: 2,
  },
  mobileWidth: {
    width: '85%',
  },
});
