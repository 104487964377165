import React, {memo, forwardRef, useRef, useEffect} from 'react';
import {Platform, ScrollView, View} from 'react-native';

import styled from 'styled-components/native';

const StyledScrollView = styled.View`
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
`;

interface ContentScrollViewProps {
  isWeb?: boolean;
  children?: React.ReactNode;
  scrollToEnd?: boolean;
}

const ContentScrollView = (props: ContentScrollViewProps, ref = null) => {
  const {isWeb = false, children} = props;
  const scrollViewRef = useRef<ScrollView>(null);
  const viewRef = useRef<View>(null);

  useEffect(() => {
    const currentRef: any = viewRef.current;

    if (Platform.OS === 'web' && props.scrollToEnd) {
      currentRef?.addEventListener('DOMNodeInserted', (event: any) => {
        const {currentTarget: target} = event;

        // Added an id for the web-player since it also triggers the DOMNodeInserted
        // event. So, if there is no id, should proceed with the autoscroll.
        if (!event.target.id) {
          if (target.scrollHeight === 0) {
            setTimeout(() => {
              target.scroll({
                top: target.scrollHeight,
                behavior: 'smooth',
              });
            }, 1000);
          } else {
            setTimeout(() => {
              target.scroll({
                top: target.scrollHeight,
                behavior: 'smooth',
              });
            }, 10);
          }
        }
      });
    }

    return () => {
      currentRef?.removeEventListener('DOMNodeInserted', () => {});
    };
  }, [props.scrollToEnd]);

  return (
    <>
      {isWeb ? (
        <StyledScrollView ref={viewRef}>{children}</StyledScrollView>
      ) : (
        <ScrollView
          ref={scrollViewRef}
          onContentSizeChange={() => {
            if (props.scrollToEnd) {
              scrollViewRef.current?.scrollToEnd({animated: true});
            }
          }}>
          {children}
        </ScrollView>
      )}
    </>
  );
};

export default memo(ContentScrollView);
