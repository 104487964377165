import React, {memo} from 'react';
import {StyleProp, View, ViewStyle} from 'react-native';

import {makeStyles} from 'rne-netzon';

import {IconName, RNEText, SVGIcon} from '../../components';

interface PatientDetailItemViewProps {
  label: string;
  iconName?: IconName;
  style?: StyleProp<ViewStyle>;
}

const PatientDetailItemView = (props: PatientDetailItemViewProps) => {
  const styles = useStyles();

  return (
    <View style={[styles.root, props.style ?? {}]}>
      {props.iconName && (
        <View style={styles.iconContainer}>
          <SVGIcon name={props.iconName} size={24} />
        </View>
      )}

      <View style={styles.labelContainer}>
        <RNEText label={props.label} color="blackLight" weight="300" />
      </View>
    </View>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
  },
  iconContainer: {
    width: 50,
  },
  labelContainer: {
    flex: 1,
  },
}));

export default memo(PatientDetailItemView);
