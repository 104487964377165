import React from 'react';
import {Platform, TextStyle} from 'react-native';
import {createStackNavigator} from '@react-navigation/stack';

import {colorPalette} from '../../../core/config/color.config';

import {NavigationKey} from '../config/navigation.config';
import {NavigationTitle} from '../config/navigationTitle';

import ConsultationTabNavigator from './ConsultationTabNavigator';

import {
  ConsultationWebListScreen,
  ConsultationDetailScreen,
  ConsultationDoctorProfileScreen,
  PatientConsultationRequestScreen,
  ConsultationSearchResultsScreen,
  PatientSearchResultScreen,
  ConsultationPaymentWebViewScreen,
  ConsultationVideoCallScreen,
  ConsultationImageAttachmentViewScreen,
} from '../../screens';
import {
  HeaderBackLeft,
  MedicalAttachmentsListView,
  ViewMedicalCertificateView,
  ViewPrescriptionView,
  ViewLaboratoryRequestView,
  HeaderProfileRight,
  HeaderProfileLeft,
} from '../../views';
import HeaderIconRight from '../../views/navigation/HeaderIconRight';
import {DrawerContentComponentProps} from '@react-navigation/drawer';
import {DrawerActions} from '@react-navigation/native';
import {ConversationProvider} from '../../hooks/conversation/useConversation';
import {VideoCallProvider} from '../../hooks/video-call/useVideoCall';
import {PaymentProvider} from '../../hooks/payment/usePayment';

const {Navigator, Screen} = createStackNavigator();

const webOptions = {
  headerStyle: {
    backgroundColor: '#2BA8E81A',
    borderBottomColor: 'transparent',
    height: 72,
  },
  headerTintColor: colorPalette.primary,
  headerTitleStyle: {
    fontWeight: 'bold',
    fontFamily: 'Quicksand-Bold',
    fontSize: 16,
    paddingLeft: 40,
  } as TextStyle,
  headerLeftContainerStyle: {
    marginLeft: 24,
  },
};

const patientMobileNavigatorOptions = {
  headerStyle: {
    elevation: 0,
    shadowOpacity: 0,
  },
  headerTitleStyle: {
    fontWeight: 'bold',
    fontFamily: 'Quicksand-Bold',
    fontSize: 16,
  } as TextStyle,
};

const mobileGeneralOptions = {
  title: '',
  headerTintColor: colorPalette.primary,
};

const mobileHeaderWithImageAndMsgOptions = {
  ...patientMobileNavigatorOptions,
  ...mobileGeneralOptions,
  headerStyle: {
    backgroundColor: '#2BA8E81A',
    borderBottomColor: 'transparent',
    elevation: 0,
    shadowOpacity: 0,
  },
  headerTintColor: colorPalette.blackLight,
  headerTitleStyle: {
    paddingLeft: 28,
  } as TextStyle,
  headerLeft: () => <HeaderProfileLeft inProfileScreen={false} />,
};

const mobileBackHeaderNoTitleOptions = {
  title: '',
  headerStyle: {
    elevation: 0,
    shadowOpacity: 0,
  },
  headerTintColor: colorPalette.blackLight,
  headerTitleStyle: {
    fontFamily: 'Quicksand-Regular',
    fontSize: 14,
    paddingLeft: 28,
  },
  headerLeft: () => <HeaderBackLeft />,
};

const PatientConsultationStackWebNavigator = () => {
  return (
    <ConversationProvider>
      <VideoCallProvider>
        <PaymentProvider>
          <Navigator>
            <Screen
              name={NavigationKey.CONSULTATION_LIST}
              component={ConsultationWebListScreen}
              options={{
                headerShown: false,
                title: NavigationTitle.CONSULTATION_LIST,
              }}
            />

            <Screen
              name={NavigationKey.CONSULTATION_DETAIL}
              component={ConsultationWebListScreen}
              options={{
                headerShown: false,
                title: NavigationTitle.CONSULTATION_LIST,
              }}
            />

            <Screen
              name={NavigationKey.PATIENT_CONSULTATION_REQUEST}
              component={PatientConsultationRequestScreen}
              options={{
                title: NavigationTitle.PATIENT_CONSULTATION_REQUEST,
                ...webOptions,
                headerLeft: () => null,
                headerTitle: '',
              }}
            />
            <Screen
              name={NavigationKey.PATIENT_SEARCH_RESULT}
              component={PatientSearchResultScreen}
              options={{
                title: NavigationTitle.PATIENT_SEARCH_RESULT,
                ...webOptions,
                headerTitleStyle: {
                  ...{
                    ...webOptions.headerTitleStyle,
                    paddingLeft: 16,
                  },
                },
              }}
            />
            <Screen
              name={NavigationKey.CONSULTATION_VIDEO_CALL}
              component={ConsultationVideoCallScreen}
              options={{
                headerShown: false,
                title: NavigationTitle.CONSULTATION_VIDEO_CALL,
              }}
            />
          </Navigator>
        </PaymentProvider>
      </VideoCallProvider>
    </ConversationProvider>
  );
};

const PatientConsultationStackMobileNavigator = (
  props: DrawerContentComponentProps,
) => {
  return (
    <ConversationProvider>
      <VideoCallProvider>
        <Navigator>
          <Screen
            name={NavigationKey.CONSULTATION_LIST}
            component={ConsultationTabNavigator}
            options={{
              title: 'Consultations',
              headerStyle: {
                backgroundColor: colorPalette.primary,
                borderBottomColor: 'transparent',
                elevation: 0,
                zIndex: 0,
                shadowOpacity: 0,
              },
              headerTintColor: colorPalette.blackLight,
              headerTitleStyle: {
                fontFamily: 'Quicksand-Bold',
                fontSize: 14,
                fontWeight: 'bold',
                alignSelf: 'center',
                marginStart: -28,
                color: colorPalette.white,
              } as TextStyle,
              headerLeft: () => (
                <HeaderBackLeft
                  removeLabel
                  iconName="left-white"
                  navigationKey={NavigationKey.PATIENT_LANDING_STACK}
                />
              ),
              headerRight: () => <HeaderIconRight iconName="search-white-80" />,
            }}
          />
          <Screen
            name={NavigationKey.CONSULTATION_DETAIL}
            component={ConsultationDetailScreen}
            options={{...mobileBackHeaderNoTitleOptions, gestureEnabled: false}}
          />
          <Screen
            name={NavigationKey.MEDICAL_ATTACHMENTS_LIST}
            component={MedicalAttachmentsListView}
            options={{...mobileBackHeaderNoTitleOptions}}
          />
          <Screen
            name={NavigationKey.VIEW_MEDICAL_CERTIFICATE}
            component={ViewMedicalCertificateView}
            options={{
              ...mobileBackHeaderNoTitleOptions,
              headerLeft: () => <HeaderBackLeft iconName="left-black" />,
              headerRight: () => <HeaderIconRight iconName="download-down" />,
            }}
          />
          <Screen
            name={NavigationKey.VIEW_PRESCRIPTION}
            component={ViewPrescriptionView}
            options={{
              ...mobileBackHeaderNoTitleOptions,
              headerLeft: () => <HeaderBackLeft iconName="left-black" />,
              headerRight: () => <HeaderIconRight iconName="download-down" />,
            }}
          />
          <Screen
            name={NavigationKey.VIEW_LABORATORY_REQUEST}
            component={ViewLaboratoryRequestView}
            options={{
              ...mobileBackHeaderNoTitleOptions,
              headerLeft: () => <HeaderBackLeft iconName="left-black" />,
              headerRight: () => <HeaderIconRight iconName="download-down" />,
            }}
          />
          <Screen
            name={NavigationKey.VIEW_IMAGE_ATTACHMENT}
            component={ConsultationImageAttachmentViewScreen}
            options={{
              ...mobileBackHeaderNoTitleOptions,
              headerLeft: () => <HeaderBackLeft iconName="left-black" />,
              headerRight: () => <HeaderIconRight iconName="download-down" />,
            }}
          />
          <Screen
            name={NavigationKey.CONSULTATION_DOCTOR_PROFILE}
            component={ConsultationDoctorProfileScreen}
          />
          <Screen
            name={NavigationKey.PATIENT_CONSULTATION_REQUEST}
            component={PatientConsultationRequestScreen}
            options={{
              ...mobileHeaderWithImageAndMsgOptions,
              headerRight: () => (
                <HeaderProfileRight
                  showMessageIcon={true}
                  onClick={() =>
                    props.navigation.dispatch(DrawerActions.toggleDrawer())
                  }
                />
              ),
            }}
          />
          <Screen
            name={NavigationKey.PATIENT_SEARCH_RESULT}
            component={PatientSearchResultScreen}
            options={{
              ...patientMobileNavigatorOptions,
              headerTitleStyle: {
                paddingLeft: 40,
              },
              headerStyle: {
                backgroundColor: '#2BA8E81A',
                borderBottomColor: 'transparent',
                elevation: 0,
                shadowOpacity: 0,
              },
              headerLeft: () => (
                <HeaderProfileLeft hasBack={true} inProfileScreen={false} />
              ),
              headerRight: () => (
                <HeaderProfileRight
                  showMessageIcon={true}
                  onClick={() =>
                    props.navigation.dispatch(DrawerActions.toggleDrawer())
                  }
                />
              ),
              ...mobileGeneralOptions,
            }}
          />
          <Screen
            name={NavigationKey.CONSULTATION_PAYMENT_WEB_VIEW}
            component={ConsultationPaymentWebViewScreen}
            options={{headerShown: false, gestureEnabled: false}}
          />
          <Screen
            name={NavigationKey.CONSULTATION_VIDEO_CALL}
            component={ConsultationVideoCallScreen}
            options={{headerShown: false}}
          />
          <Screen
            name={NavigationKey.CONSULTATION_SEARCH}
            component={ConsultationSearchResultsScreen}
            options={{
              ...mobileBackHeaderNoTitleOptions,
            }}
          />
        </Navigator>
      </VideoCallProvider>
    </ConversationProvider>
  );
};

export default Platform.OS === 'web'
  ? PatientConsultationStackWebNavigator
  : PatientConsultationStackMobileNavigator;
