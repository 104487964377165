export enum PractitionerStatus {
  UNVERIFIED = 'unverified',
  PENDING = 'pending',
  DECLINED = 'declined',
  VERIFIED = 'verified',
  ARCHIVED = 'archived',
}

export enum VerifyPractitionerActionType {
  APPROVE = 'approve',
  DECLINE = 'decline',
}
