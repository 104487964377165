import React, {useState} from 'react';
import {View} from 'react-native';

import {makeStyles, Tab} from 'rne-netzon';

import {useDebounce} from '../../hooks/debounce/useDebounce';

import {AdminPatientVerifiedTab, AdminSearchHeader} from '../../views';

const AdminPatientsScreen = () => {
  const styles = useStyle();

  const [searchValue, setSearchValue] = useState('');

  const debouncedSearchValue = useDebounce(searchValue, 350);

  const onFilterClick = () => {};
  const onExportClick = () => {};

  return (
    <View style={styles.rootContainer}>
      <AdminSearchHeader
        title="Patient Accounts"
        searchValue={searchValue}
        onSearchValueChange={setSearchValue}
        onFilterClick={onFilterClick}
        onExportClick={onExportClick}
      />

      <View style={styles.tabsContentContainer}>
        <AdminPatientVerifiedTab searchKey={debouncedSearchValue} />
      </View>
    </View>
  );
};

const useStyle = makeStyles({
  rootContainer: {
    height: '100%',
    paddingVertical: 120,
    paddingHorizontal: 72,
  },
  tabsContentContainer: {
    marginTop: 32,
    height: '100%',
  },
});

export default AdminPatientsScreen;
