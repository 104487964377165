import React, {memo, useState} from 'react';
import {View, Platform} from 'react-native';

import {makeStyles, Overlay} from 'rne-netzon';

import {useNavigation} from '@react-navigation/native';
import {NavigationKey} from '../../navigations/config';

import {PractitionerProfile} from './../../server/types/practitioner-profile.types';

import DoctorSearchItem from './DoctorSearchItem';
import PractitionerRequestProfileViewModal from '../modal/PractitionerRequestProfileViewModal';

interface DoctorSearchListProps {
  practitioners: PractitionerProfile[];
}

const DoctorSearchList = (props: DoctorSearchListProps) => {
  const styles = useStyle();
  const navigation = useNavigation();

  const [showViewDialog, setShowViewDialog] = useState(false);
  const [selectedPractitioner, setSelectedPractitioner] = useState<
    PractitionerProfile | undefined
  >();

  const viewPractitionerProfile = (practitioner: PractitionerProfile) => {
    if (Platform.OS === 'web') {
      setSelectedPractitioner(practitioner);
      setShowViewDialog(true);
    } else {
      navigation.navigate(NavigationKey.DOCTOR_PROFESSIONAL_PROFILE_REQUEST, {
        practitionerId: practitioner.practitionerId,
      });
    }
  };

  const onRequestAppointment = (practitioner: PractitionerProfile) => {
    if (practitioner) {
      navigation.navigate(NavigationKey.PATIENT_CONSULTATION_REQUEST, {
        practitionerId: practitioner.practitionerId,
      });
      setShowViewDialog(false);
    }
  };

  return (
    <View style={styles.cardsContainer}>
      {selectedPractitioner && (
        <>
          <Overlay
            isVisible={showViewDialog}
            overlayStyle={styles.overlayContainer}
            onBackdropPress={() => {
              setSelectedPractitioner(undefined);
              setShowViewDialog(false);
            }}>
            <PractitionerRequestProfileViewModal
              onClose={() => {
                setSelectedPractitioner(undefined);
                setShowViewDialog(false);
              }}
              onRequest={() => onRequestAppointment(selectedPractitioner)}
              practitionerId={selectedPractitioner.practitionerId}
            />
          </Overlay>
        </>
      )}
      {props.practitioners.map(practitioner => (
        <View
          key={practitioner.practitionerId}
          style={[
            styles.cardContainer,
            Platform.OS !== 'web' && styles.cardContainerMobile,
          ]}>
          <DoctorSearchItem
            practitioner={practitioner}
            onViewProfile={() => viewPractitionerProfile(practitioner)}
          />
        </View>
      ))}
    </View>
  );
};

const useStyle = makeStyles({
  rootContainer: {
    paddingVertical: 120,
    paddingHorizontal: 72,
  },
  cardsContainer: {
    marginTop: 12,
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  cardContainer: {
    marginRight: 24,
    marginBottom: 24,
    flexShrink: 1,
    flexGrow: 0,
    minWidth: 200,
    maxWidth: 300,
    width: '100%',
    height: 180,
  },
  cardContainerMobile: {
    minWidth: '100%',
    maxWidth: '100%',
    height: 160,
  },
  overlayContainer: {
    borderRadius: 20,
  },
});

export default memo(DoctorSearchList);
