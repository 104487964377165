import React, {useCallback, useEffect, useState} from 'react';
import {TouchableOpacity, View} from 'react-native';

import {useNavigation, useRoute} from '@react-navigation/native';
import {NavigationKey} from '../../navigations/config';

import {makeStyles, Tab} from 'rne-netzon';
import {colorPalette} from '../../../core/config/color.config';

import {RNEInput, RNEText, SVGIcon} from '../../components';
import {
  ConsultationHistoryTab,
  ConsultationOngoingTab,
  ConsultationPendingTab,
} from '../../views';
import ConsultationDetailScreen from './ConsultationDetailScreen';

import {useGetConsultationDetail} from '../../server/react-query/useConsultation';
import {TouchableWithoutFeedback} from 'react-native-gesture-handler';
import {useDebounce} from '../../hooks/debounce/useDebounce';
import useStores from '../../stores/useStores';
import useVideoCall from '../../hooks/video-call/useVideoCall';

const ConsultationWebListScreen = () => {
  const styles = useStyle();
  const route = useRoute();
  const navigation = useNavigation();
  const {authStore} = useStores();
  const {closeRoom, resetValues} = useVideoCall();

  const [tabIndex, setTabIndex] = useState(0);
  const [consultationId, setConsultationId] = useState();
  const [routeParams, setRouteParams] = useState({});
  const [searching, setSearching] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const debouncedSearchValue = useDebounce(searchValue, 350);

  const {data, refetch} = useGetConsultationDetail({
    serviceRequestId: consultationId || '',
  });

  const changeTab = (index: number) => {
    const params = {
      ...routeParams,
      tab: index,
      id: null,
    };

    const screen = consultationId
      ? NavigationKey.CONSULTATION_DETAIL
      : NavigationKey.CONSULTATION_LIST;

    navigation.navigate(screen, params);
  };

  const displayTab = useCallback(() => {
    switch (tabIndex) {
      case 0:
        return <ConsultationOngoingTab searchKey={debouncedSearchValue} />;

      case 1:
        return <ConsultationPendingTab searchKey={debouncedSearchValue} />;

      case 2:
        return <ConsultationHistoryTab searchKey={debouncedSearchValue} />;
    }
  }, [tabIndex, debouncedSearchValue]);

  const endCall = async (subjectId: string, roomId: string) => {
    await closeRoom(subjectId, roomId);
    resetValues();
  };

  useEffect(() => {
    const params: any = route.params;

    if (params) {
      if (params.id) {
        setConsultationId(params.id);
      }

      if (params.declining) {
        endCall(params.subjectId, params.roomId);
      }

      if (params.tab !== undefined && params.tab !== null) {
        setTabIndex(+params.tab);
      }

      setRouteParams(params);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route.params]);

  useEffect(() => {
    if (consultationId) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consultationId]);

  return (
    <View style={styles.rootContainer}>
      <View style={styles.tabsContainer}>
        <View
          style={
            authStore.userRole === 'patient'
              ? styles.tabsHeaderContainerPatient
              : styles.tabsHeaderContainerDoctor
          }>
          <View
            style={
              !searching ? styles.headerContainer : styles.searchContainer
            }>
            {!searching ? (
              <>
                <RNEText
                  label="Consultations"
                  color="white"
                  weight="600"
                  variant="title"
                  style={fontTitleSize}
                />
                <TouchableWithoutFeedback onPress={() => setSearching(true)}>
                  <View accessibilityRole="button">
                    <SVGIcon name="search-white-80" size={28} />
                  </View>
                </TouchableWithoutFeedback>
              </>
            ) : (
              <>
                <>
                  <RNEInput
                    value={searchValue}
                    onChangeText={setSearchValue}
                    placeholder="Search"
                    inputStyle={inputStyle}
                    placeholderTextColor={colorPalette.white}
                    inputContainerStyle={inputContainerStyle}
                    containerStyle={containerStyle}
                    style={textStyle}
                    autoFocus={true}
                    height={60}
                  />
                  <TouchableOpacity
                    style={styles.closeIconContainer}
                    onPress={() => {
                      setSearching(false);
                      setSearchValue('');
                    }}>
                    <SVGIcon name="cancel-white" size={60} />
                  </TouchableOpacity>
                </>
              </>
            )}
          </View>
        </View>
        <Tab
          value={tabIndex}
          onChange={changeTab}
          indicatorStyle={styles.indicatorStyle}
          style={styles.tabContainer}>
          <Tab.Item
            title="Ongoing"
            containerStyle={
              authStore.userRole === 'patient'
                ? styles.containerStylePatient
                : styles.containerStyleDoctor
            }
            titleStyle={[
              styles.titleStyle,
              tabIndex === 0 &&
                (authStore.userRole === 'patient'
                  ? styles.selectedTitleStylePatient
                  : styles.selectedTitleStyleDoctor),
            ]}
          />
          <Tab.Item
            title="Pending"
            containerStyle={
              authStore.userRole === 'patient'
                ? styles.containerStylePatient
                : styles.containerStyleDoctor
            }
            titleStyle={[
              styles.titleStyle,
              tabIndex === 1 &&
                (authStore.userRole === 'patient'
                  ? styles.selectedTitleStylePatient
                  : styles.selectedTitleStyleDoctor),
            ]}
          />
          <Tab.Item
            title="History"
            containerStyle={
              authStore.userRole === 'patient'
                ? styles.containerStylePatient
                : styles.containerStyleDoctor
            }
            titleStyle={[
              styles.titleStyle,
              tabIndex === 2 &&
                (authStore.userRole === 'patient'
                  ? styles.selectedTitleStylePatient
                  : styles.selectedTitleStyleDoctor),
            ]}
          />
        </Tab>

        <View style={styles.tabsContentContainer}>{displayTab()}</View>
      </View>
      <View style={styles.detailContainer}>
        <ConsultationDetailScreen />
      </View>
    </View>
  );
};

const fontTitleSize = {
  fontSize: 20,
};

const inputStyle = {
  color: colorPalette.white,
};

const inputContainerStyle = {
  borderWidth: 0,
  borderBottomWidth: 0,
};

const containerStyle = {
  width: '80%',
};

const textStyle = {
  fontSize: 20,
};

const useStyle = makeStyles({
  rootContainer: {
    height: '100%',
    flexDirection: 'row',
  },
  tabsContainer: {
    height: '100%',
    width: 400,
    borderRightWidth: 1,
    borderRightColor: colorPalette.light,
  },
  headerContainer: {
    marginVertical: 16,
    paddingHorizontal: 12,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  tabsHeaderContainerDoctor: {
    paddingTop: 8,
    paddingHorizontal: 16,
    backgroundColor: colorPalette.success,
  },
  tabsHeaderContainerPatient: {
    paddingTop: 8,
    paddingHorizontal: 16,
    backgroundColor: colorPalette.primary,
  },
  tabsContentContainer: {
    marginTop: 0,
    flex: 1,
    paddingVertical: 8,
  },
  tabContainer: {
    backgroundColor: colorPalette.primary,
  },
  indicatorStyle: {
    marginLeft: 48,
    width: 0,
    height: 0,
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderLeftWidth: 12,
    borderRightWidth: 12,
    borderBottomWidth: 12,
    borderLeftColor: 'transparent',
    borderRightColor: 'transparent',
    borderBottomColor: colorPalette.white,
  },
  containerStyleDoctor: {
    borderBottomColor: 'transparent',
    backgroundColor: colorPalette.success,
    paddingBottom: 16,
  },
  containerStylePatient: {
    borderBottomColor: 'transparent',
    backgroundColor: colorPalette.primary,
    paddingBottom: 16,
  },
  titleStyle: {
    textTransform: 'none',
    color: colorPalette.white,
    fontSize: 14,
    fontFamily: 'Quicksand-Bold',
    fontWeight: '400',
  },
  selectedTitleStyle: {
    borderRadius: 24,
    color: colorPalette.primary,
    backgroundColor: colorPalette.white,
  },
  selectedTitleStyleDoctor: {
    borderRadius: 24,
    color: colorPalette.success,
    backgroundColor: colorPalette.white,
  },
  selectedTitleStylePatient: {
    borderRadius: 24,
    color: colorPalette.primary,
    backgroundColor: colorPalette.white,
  },
  detailContainer: {
    height: '100%',
    flex: 1,
  },
  searchContainer: {
    flexDirection: 'row',
    paddingBottom: 16,
  },
  closeIconContainer: {
    bottom: 4,
  },
});

export default ConsultationWebListScreen;
