import {makeAutoObservable, observable, action} from 'mobx';

import {persistStore} from '../utils/mobx.utils';
import {ConsultationMessageBubbleProps} from '../views/consultation/ConsultationMessageVideoCallBubble';

export class ConversationStore {
  latestVideoCallRequest: ConsultationMessageBubbleProps | null = null;
  accessToken: string | null = null;

  constructor() {
    makeAutoObservable(this, {
      accessToken: observable,
      setAccessToken: action,
      resetAccessToken: action,
      latestVideoCallRequest: observable,
      setLatestVideoCallRequest: action,
      resetLatestVideoCallRequest: action,
    });

    persistStore(
      this,
      ['accessToken', 'latestVideoCallRequest'],
      'ConversationStore',
    );
  }

  setAccessToken(token: string) {
    this.accessToken = token;
  }

  resetAccessToken() {
    this.accessToken = null;
  }

  setLatestVideoCallRequest(
    latestVideoCallRequest: ConsultationMessageBubbleProps,
  ) {
    this.latestVideoCallRequest = latestVideoCallRequest;
  }

  resetLatestVideoCallRequest() {
    this.latestVideoCallRequest = null;
  }
}
