import React, {useState} from 'react';
import {View, TouchableWithoutFeedback} from 'react-native';
import {Image, makeStyles} from 'rne-netzon';
import {SVGIcon} from '..';
import {colorPalette} from '../../../core/config/color.config';
import {ImageSelectorProps} from './ImageSelector.native';
import {useToast} from 'react-native-fast-toast';

export const ImageSelector = (props: ImageSelectorProps) => {
  const webStyles = useWebStyles();
  let inputElement: HTMLElement | null;

  const [fileUri, setFileUri] = useState<string | undefined>('');

  const toast = useToast();

  const triggerInputClick = async () => {
    inputElement?.click();
  };

  const fileSizeChecker = (fileSize: number, uri: string, image: Blob) => {
    if (fileSize / 1000000 > 5) {
      toast?.show('File size is over 5MB', {type: 'danger'});
    } else if (fileSize && fileSize / 1000000 <= 5) {
      setFileUri(uri);

      props.onSelectPhoto(image);
    }
  };

  const handleFileChange = (event: any) => {
    const {target} = event;
    const {files} = target;

    if (files && files[0]) {
      const reader = new FileReader();

      reader.onload = (readerEvent: any) => {
        const fileSize = files[0].size;
        fileSizeChecker(fileSize, URL.createObjectURL(files[0]), files[0]);
      };

      reader.readAsDataURL(files[0]);
    }
  };

  const removeSelectedPhoto = () => {
    setFileUri(undefined);
    // props.providedPhoto = '';
    props.onSelectPhoto(null);
  };

  const inputStyle = {
    display: 'none',
  };

  return (
    <View>
      {/* {(fileUri || props.providedPhoto) && (
        <TouchableWithoutFeedback onPress={removeSelectedPhoto}>
          <View style={webStyles.closeIcon} accessibilityRole="button">
            <SVGIcon name="close" size={20} />
          </View>
        </TouchableWithoutFeedback>
      )} */}
      <View style={webStyles.userIcon}>
        {fileUri || props.providedPhoto ? (
          fileUri ? (
            <Image source={{uri: fileUri}} style={webStyles.imageStyle} />
          ) : (
            <Image
              source={{uri: props.providedPhoto}}
              style={webStyles.imageStyle}
            />
          )
        ) : (
          <SVGIcon name="user" size={68} />
        )}
      </View>
      <TouchableWithoutFeedback onPress={triggerInputClick}>
        <View style={webStyles.cameraIcon} accessibilityRole="button">
          <SVGIcon name="camera-29" size={20} />
          <input
            ref={input => (inputElement = input)}
            type="file"
            style={inputStyle}
            accept="image/jpeg, image/png, .svg, image/heic, image/heic-sequence"
            onChange={handleFileChange}
          />
        </View>
      </TouchableWithoutFeedback>
    </View>
  );
};

const useWebStyles = makeStyles({
  inputStyle: {
    display: 'none',
  },
  closeIcon: {
    backgroundColor: colorPalette.white,
    borderRadius: 50,
    height: 28,
    width: 28,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    left: 100,
    zIndex: 10,
  },
  userIcon: {
    backgroundColor: colorPalette.white,
    marginRight: 48,
    borderRadius: 100,
    height: 140,
    width: 140,
    justifyContent: 'center',
    alignItems: 'center',
  },
  cameraIcon: {
    backgroundColor: colorPalette.white,
    borderRadius: 50,
    height: 48,
    width: 48,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 92,
    left: 100,
  },
  imageStyle: {
    height: 140,
    width: 140,
    borderRadius: 100,
  },
});

export default ImageSelector;
