import {
  parse,
  isDate,
  isValid,
  format,
  intervalToDuration,
  add,
  differenceInDays,
} from 'date-fns';

const parseDateString = (originalValue: any) => {
  if (!originalValue) {
    return true;
  }

  const parsedDate = parse(originalValue as string, 'yyyy-MM-dd', new Date());
  const isValidDate = isValid(parsedDate);
  return isValidDate;
};

const formatDateString = (originalValue: any) => {
  if (!originalValue) {
    return true;
  }

  const formattedDate = format(new Date(originalValue), 'MMMM dd, yyyy');
  return formattedDate;
};

const formatToMessageTime = (originalValue: any) => {
  if (!originalValue) {
    return '';
  }

  const formattedDate = format(
    new Date(originalValue),
    "MMM dd, yyyy 'at' h:mmaa",
  );
  return formattedDate;
};

const computeAge = (birthdate: any) => {
  if (!birthdate) {
    return birthdate;
  }

  const age = intervalToDuration({
    start: new Date(),
    end: new Date(birthdate),
  }).years;

  return age;
};

const timestamp = (date: Date) => {
  const formattedDate = format(date, 'h:mm aa');
  return formattedDate;
};

const formatShortDate = (date: Date) => {
  const formattedDate = format(date, 'MMMM dd');
  return formattedDate;
};

const countdownInDays = (date: Date, max: number) => {
  if (!date) {
    return date;
  }

  const endDate = add(new Date(date), {days: max});

  const daysLeft = differenceInDays(new Date(endDate), new Date(date));

  return daysLeft;
};

export {
  parseDateString,
  formatDateString,
  formatToMessageTime,
  timestamp,
  computeAge,
  formatShortDate,
  countdownInDays,
};
