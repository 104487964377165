import {
  FCM_API_KEY,
  FCM_APP_ID,
  FCM_AUTH_DOMAIN,
  FCM_MEASUREMENT_ID,
  FCM_MESSAGING_SENDER_ID,
  FCM_PROJECT_ID,
  FCM_STORAGE_BUCKET,
} from '../server/api/api.config';

export const firebaseConfig = {
  apiKey: FCM_API_KEY,
  authDomain: FCM_AUTH_DOMAIN,
  projectId: FCM_PROJECT_ID,
  storageBucket: FCM_STORAGE_BUCKET,
  messagingSenderId: FCM_MESSAGING_SENDER_ID,
  appId: FCM_APP_ID,
  measurementId: FCM_MEASUREMENT_ID,
};
