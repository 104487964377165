import React from 'react';
import {Platform} from 'react-native';
import {FullTheme, ThemeProvider} from 'rne-netzon';

import {colorPalette} from '../core/config/color.config';

const theme: Partial<FullTheme> = {
  colors: {
    primary: colorPalette.primary,
    secondary: colorPalette.secondary,
    success: colorPalette.success,
    warning: colorPalette.warning,
    error: colorPalette.error,
    greyOutline: colorPalette.light,
    white: colorPalette.white,
    divider: colorPalette.light,
    black: colorPalette.black,
    searchBg: colorPalette.light,
    grey0: colorPalette.grey,
    grey1: colorPalette.blackLight,
  },
  Input: {
    containerStyle: {
      paddingHorizontal: 0,
    },
    inputContainerStyle: {
      borderColor: colorPalette.light,
      borderWidth: 1,
      borderRadius: Platform.OS === 'web' ? 12 : 30,
      paddingHorizontal: Platform.OS === 'web' ? 16 : 16,
    },
    style: {
      fontSize: Platform.OS === 'web' ? 16 : 14,
      paddingLeft: Platform.OS === 'web' ? 8 : 8,
      fontFamily: 'Lato-Regular',
      width: '100%',
    },
    placeholderTextColor: colorPalette.grey,
  },
  Button: {
    buttonStyle: {
      borderRadius: 100,
      paddingHorizontal: 24,
    },
    containerStyle: {
      borderRadius: 100,
    },
    activeOpacity: 0.8,
  },
  CheckBox: {
    textStyle: {
      fontSize: Platform.OS === 'web' ? 14 : 12,
      fontFamily: 'Quicksand-Regular',
      fontWeight: '400',
    },
    containerStyle: {
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      justifyContent: 'center',
    },
    activeOpacity: 0.8,
  },
  Overlay: {
    overlayStyle: {
      borderRadius: 20,
    },
  },
  Tab: {
    indicatorStyle: {
      backgroundColor: colorPalette.primary,
    },
    style: {
      borderBottomWidth: 1,
      borderBottomColor: colorPalette.primary,
    },
  },
  TabItem: {
    titleStyle: {
      textTransform: 'none',
      color: colorPalette.blackLight,
      fontSize: Platform.OS === 'web' ? 14 : 12,
      fontFamily: 'Quicksand-Bold',
      fontWeight: '400',
    },
    containerStyle: {
      backgroundColor: 'transparent',
      borderBottomWidth: 2,
      borderBottomColor: colorPalette.light,
    },
  },
};

interface ThemeProps {
  children: React.ReactNode;
}

const ThemeRNE = (props: ThemeProps) => {
  return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
};

export default ThemeRNE;
