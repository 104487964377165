import React, {useState} from 'react';
import {View} from 'react-native';

import {makeStyles, Tab} from 'rne-netzon';

import {useDebounce} from '../../hooks/debounce/useDebounce';

import {
  AdminArchivedDoctorProfilesTab,
  AdminArchivedPatientProfilesTab,
  AdminSearchHeader,
} from '../../views';

const AdminDeletedProfilesScreen = () => {
  const styles = useStyle();

  const [searchValue, setSearchValue] = useState('');
  const [tabIndex, setTabIndex] = useState(0);

  const debouncedSearchValue = useDebounce(searchValue, 350);

  const onExportClick = () => {};

  return (
    <View style={styles.rootContainer}>
      <AdminSearchHeader
        title="Deleted Profiles"
        searchValue={searchValue}
        showFilter={false}
        onSearchValueChange={setSearchValue}
        onExportClick={onExportClick}
      />

      <View style={styles.tabsHeaderContainer}>
        <Tab value={tabIndex} onChange={setTabIndex}>
          <Tab.Item title="Doctors" />
          <Tab.Item title="Patients" />
        </Tab>
      </View>
      <View style={styles.tabsContentContainer}>
        {tabIndex === 0 && (
          <AdminArchivedDoctorProfilesTab searchKey={debouncedSearchValue} />
        )}
        {tabIndex === 1 && (
          <AdminArchivedPatientProfilesTab searchKey={debouncedSearchValue} />
        )}
      </View>
    </View>
  );
};

const useStyle = makeStyles({
  rootContainer: {
    height: '100%',
    paddingVertical: 120,
    paddingHorizontal: 72,
  },
  tabsHeaderContainer: {
    marginTop: 32,
  },
  tabsContentContainer: {
    marginTop: 0,
    height: '100%',
  },
});

export default AdminDeletedProfilesScreen;
