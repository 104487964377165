import React, {memo} from 'react';
import {View} from 'react-native';

import {makeStyles} from 'rne-netzon';

import {RNEText} from '../../components';

import {formatToMessageTime} from '../../utils/date.utils';

interface ConsultationInformationMessageProps {
  timestamp: Date;
  fee?: string;
}

const ConsultationInformationMessage = (
  props: ConsultationInformationMessageProps,
) => {
  const styles = useStyles();

  return (
    <View style={styles.mainContainer}>
      <View style={styles.timestamp}>
        <RNEText
          label={formatToMessageTime(props.timestamp)}
          color="grey"
          variant="button"
          weight="600"
        />
      </View>
      <View
        style={
          props.fee ? styles.messageContainer : styles.lastMessageContainer
        }>
        <RNEText
          label={
            props.fee
              ? `Consultation request approved for ${props.fee} Php`
              : 'Payment for consultation confirmed.'
          }
          color="grey"
          variant="button"
          align="center"
        />
      </View>
    </View>
  );
};

const useStyles = makeStyles({
  mainContainer: {
    marginTop: 20,
  },
  timestamp: {
    alignItems: 'center',
    marginTop: 24,
  },
  messageContainer: {
    marginTop: 8,
  },
  lastMessageContainer: {
    marginTop: 8,
    marginBottom: 40,
  },
});

export default memo(ConsultationInformationMessage);
