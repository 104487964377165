import React from 'react';
import {Platform} from 'react-native';

import {createStackNavigator} from '@react-navigation/stack';
import {colorPalette} from '../../../core/config/color.config';

import {NavigationKey} from '../config/navigation.config';
import {NavigationTitle} from '../config/navigationTitle';
import {HelpScreen} from '../../screens';
import {HeaderBackLeft} from '../../views';

const {Navigator, Screen} = createStackNavigator();

const mobileBackHeaderNoTitleOptions = {
  title: '',
  headerStyle: {
    elevation: 0,
    shadowOpacity: 0,
  },
  headerTintColor: colorPalette.blackLight,
  headerTitleStyle: {
    fontFamily: 'Quicksand-Regular',
    fontSize: 14,
    paddingLeft: 28,
  },
  headerLeft: () => <HeaderBackLeft />,
};

const DoctorHelpStackWebNavigator = () => {
  return (
    <Navigator>
      <Screen
        name={NavigationKey.PATIENT_HELP}
        component={HelpScreen}
        options={{headerShown: false, title: NavigationTitle.HELP}}
      />
    </Navigator>
  );
};

const DoctorHelpStackMobileNavigator = () => {
  return (
    <Navigator>
      <Screen
        name={NavigationKey.PATIENT_HELP}
        component={HelpScreen}
        options={{...mobileBackHeaderNoTitleOptions}}
      />
    </Navigator>
  );
};

export default Platform.OS === 'web'
  ? DoctorHelpStackWebNavigator
  : DoctorHelpStackMobileNavigator;
