export enum NavigationKey {
  ROOT_STACK = 'root',
  ONBOARDING_STACK = 'onboarding',
  ONBOARDING_SCREEN_ENTRY = 'onboarding-entry',
  ONBOARDING_SCREEN_SLIDER = 'onboarding-slider',
  AUTH_STACK = 'auth',
  LOGIN_SCREEN = 'login',
  SIGNUP_SCREEN = 'signup',
  SIGNUP_VERIFY_SCREEN = 'signup-verify',
  FORGOT_PASSWORD_SCREEN = 'forgot-password',
  RESET_PASSWORD_SCREEN = 'reset-password',
  MAIN_DRAWER = 'main',
  PATIENT_STACK = 'patient',
  PATIENT_LANDING_STACK = 'landing',
  PATIENT_SCREEN_LANDING = 'dashboard',
  PATIENT_SEARCH_RESULT = 'search-result',
  PATIENT_PROFILE_STACK = 'profile',
  PATIENT_PROFILES = 'list',
  PATIENT_PROFILE_ADD = 'add',
  PATIENT_PROFILE_ADD_NEXT = 'add-next',
  PATIENT_PROFILE_EDIT = 'edit',
  PATIENT_PROFILE_EDIT_NEXT = 'edit-next',
  PATIENT_PROFILE_DELETE = 'delete',
  PATIENT_MESSAGES = 'patient-messages',
  PATIENT_NOTIFICATION_STACK = 'notification',
  PATIENT_NOTIFICATIONS = 'list',
  PATIENT_TANDC_STACK = 'terms',
  PATIENT_TANDC = 'view',
  PATIENT_HELP_STACK = 'help',
  PATIENT_HELP = 'patient_help',
  PATIENT_ACCOUNT_STACK = 'account',
  PATIENT_ACCOUNT = 'view',
  PATIENT_ACCOUNT_EDIT = 'edit',
  PATIENT_CONSULTATION_STACK = 'consultation',
  PATIENT_CONSULTATION_REQUEST = 'consultation-request',
  PATIENT_MEDICAL_HISTORY_STACK = 'medical-history',
  ALLERGY_INTOLERANCE = 'allergy-intolerance',
  CONDITION = 'condition',
  FAMILY_MEMBER_HISTORY = 'family-member-history',
  MEDICATION_STATEMENT = 'medication-statement',
  PROCEDURE = 'procedure',
  PERSONAL_AND_SOCIAL = 'personal-and-social',
  DOCTOR_STACK = 'doctor',
  DOCTOR_LANDING_STACK = 'landing',
  DOCTOR_SCREEN_LANDING = 'dashboard',
  DOCTOR_PROFILE_STACK = 'profile',
  DOCTOR_VIEW_PROFESSIONAL_PROFILE = 'view',
  DOCTOR_EDIT_PROFESSIONAL_PROFILE = 'edit',
  DOCTOR_PROFESSIONAL_PROFILE = 'doctor-profile',
  DOCTOR_PROFESSIONAL_PROFILE_REQUEST = 'doctor-profile-request',
  DOCTOR_NOTIFICATION_STACK = 'notification',
  DOCTOR_CONSULTATION_STACK = 'consultation',
  DOCTOR_TANDC_STACK = 'terms',
  DOCTOR_HELP_STACK = 'help',
  DOCTOR_ACCOUNT_STACK = 'account',
  DOCTOR_ACCOUNT = 'view',
  DOCTOR_ACCOUNT_EDIT = 'edit',
  DOCTOR_ADD_MEDICAL_ATTACHMENT = 'add-medical-attachment',
  ADMIN_LANDING = 'landing',
  ADMIN_DASHBOARD = 'dashboard',
  ADMIN_DOCTORS = 'doctor',
  ADMIN_DOCTORS_LIST = 'list',
  ADMIN_PATIENTS_LST = 'profile',
  ADMIN_PROFILES_LIST = 'list',
  ADMIN_DELETED_PROFILES = 'deleted-profile',
  ADMIN_DELETED_PROFILES_LIST = 'list',
  CONSULTATION_TAB_LIST = 'consultation-tabs',
  CONSULTATION_TAB_ONGOING = 'ongoing',
  CONSULTATION_TAB_PENDING = 'pending',
  CONSULTATION_TAB_HISTORY = 'history',
  CONSULTATION_DETAIL = 'detail',
  CONSULTATION_LIST = 'list',
  CONSULTATION_END = 'consultation-end',
  CONSULTATION_SEARCH = 'consultation-search',
  CONSULTATION_PAYMENT = 'consultation-payment',
  CONSULTATION_PAYMENT_WEB_VIEW = 'payment-web-view',
  CONSULTATION_VIDEO_CALL = 'consultation-video-call',
  PAYMENT_WEB_VIEW_STACK = 'webview',
  CONSULTATION_PAYMENT_ENTRY = 'payment-entry',
  CONSULTATION_PAYMENT_FAIL = 'payment-fail',
  CONSULTATION_PAYMENT_SUCCESS = 'payment-success',
  MEDICAL_ATTACHMENTS_LIST = 'medical-attachments',
  VIEW_MEDICAL_CERTIFICATE = 'view-medical-certificate',
  VIEW_PRESCRIPTION = 'view-prescription',
  VIEW_LABORATORY_REQUEST = 'view-laboratory-request',
  VIEW_IMAGE_ATTACHMENT = 'view-image-attachment',
  CONSULTATION_DOCTOR_PROFILE = 'consultation-doctor-profile',
  CONSULTATION_PATIENT_MEDICAL_HISTORY = 'consultation-patient-medical-history',
  LOGOUT = 'logout',
  TANDC_SCREEN = 'terms-conditions',
  PRIVACY_POLICY = 'privacy-policy',
  CONSULTATION_REQUEST_ACCEPTANCE = 'consultation-request-acceptance',
}

export const NavigationLinkingConfig = {
  screens: {
    HomeStack: {
      initialRouteName: 'Profile',
      screens: {
        Home: 'home',
        Profile: 'user',
      },
    },
    Settings: 'settings',
  },
};
