import React from 'react';
import {View, Text} from 'react-native';

import {makeStyles} from 'rne-netzon';

import {RNEText, ShadowedCard} from './../../components';

interface MedicalHistoryCardProps {
  label: string;
  width?: number | string;
  height?: number | string;
  icon?: React.ReactNode;
  onPress?: () => void;
}

const MedicalHistoryCard = (props: MedicalHistoryCardProps) => {
  const styles = useStyle();

  return (
    <ShadowedCard
      width={props.width}
      height={props.height}
      onPress={props.onPress}>
      {props.icon && <View style={styles.iconContainer}>{props.icon}</View>}
      <View style={styles.titleContainer}>
        <RNEText
          label={props.label}
          variant="title"
          weight="bold"
          color="primary"
          align="left"
          style={textStyle}
        />
      </View>
    </ShadowedCard>
  );
};

const textStyle = {
  fontSize: 16,
};

const useStyle = makeStyles({
  iconContainer: {
    paddingRight: 28,
  },
  titleContainer: {
    flexDirection: 'row',
    flex: 1,
    flexWrap: 'wrap',
  },
});

export default MedicalHistoryCard;
