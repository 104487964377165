import React, {memo} from 'react';
import {View} from 'react-native';

import {makeStyles} from 'rne-netzon';
import {ConsultationMessageVideoCallBubble} from '..';
import {colorPalette} from '../../../core/config/color.config';

import {RNEText} from '../../components';
import {ConsultationRequestResBody} from '../../server/types/consultation.types';
import {CodedConversationMessages} from '../../server/types/conversation.types';

import {formatToMessageTime} from '../../utils/date.utils';

interface ConsultationMessageBubbleProps {
  message: string;
  timestamp: Date;
  owner?: boolean;
  isPatient?: boolean;
  serviceRequest?: ConsultationRequestResBody;
  latestVideoCallRequestTimestamp?: Date;
}

const ConsultationMessageBubble = (props: ConsultationMessageBubbleProps) => {
  const styles = useStyles();

  return (
    <View>
      <View style={styles.timestamp}>
        <RNEText
          label={formatToMessageTime(props.timestamp)}
          color="grey"
          variant="button"
          weight="600"
        />
      </View>
      <View
        style={[
          styles.messageContainer,
          // eslint-disable-next-line react-native/no-inline-styles
          {
            alignItems: props.owner ? 'flex-end' : 'flex-start',
          },
        ]}>
        <View
          style={[
            styles.messageWrapper,
            // eslint-disable-next-line react-native/no-inline-styles
            {
              borderTopRightRadius: props.owner ? 0 : 12,
              borderTopLeftRadius: props.owner ? 12 : 0,
              backgroundColor: props.isPatient
                ? colorPalette.purple
                : colorPalette.success,
            },
          ]}>
          {props.message === CodedConversationMessages.VIDEO_CALL_TRIGGER ||
          props.message === CodedConversationMessages.VIDEO_CALL_ACCEPT ? (
            <ConsultationMessageVideoCallBubble
              message={props.message}
              timestamp={props.timestamp}
              owner={props.owner}
              isPatient={props.isPatient}
              serviceRequest={props.serviceRequest}
              latestVideoCallRequestTimestamp={
                props.latestVideoCallRequestTimestamp
              }
            />
          ) : (
            <RNEText label={props.message} color="white" variant="button" />
          )}
        </View>
      </View>
    </View>
  );
};

const useStyles = makeStyles({
  timestamp: {
    alignItems: 'center',
    marginTop: 24,
  },
  messageContainer: {
    marginTop: 16,
  },
  messageWrapper: {
    width: '80%',
    padding: 24,
    borderRadius: 12,
  },
  buttonStyle: {
    padding: 8,
  },
  buttonTextStyle: {
    color: colorPalette.blackLight,
  },
});

export default memo(ConsultationMessageBubble);
