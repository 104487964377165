import React, {memo, useEffect, useState} from 'react';
import {View} from 'react-native';

import {makeStyles, Overlay} from 'rne-netzon';

import {RNEButton, RNEText} from '../../components';
import {ConsultationVideoCallScreen} from '../../screens';
import {ConsultationRequestResBody} from '../../server/types/consultation.types';
import useStores from '../../stores/useStores';
import {CodedConversationMessages} from '../../server/types/conversation.types';

export interface ConsultationMessageBubbleProps {
  message: string;
  timestamp: Date;
  owner?: boolean;
  isPatient?: boolean;
  serviceRequest?: ConsultationRequestResBody;
  latestVideoCallRequestTimestamp?: Date;
}

const ConsultationMessageVideoCallBubble = (
  props: ConsultationMessageBubbleProps,
) => {
  const styles = useStyles();
  const {authStore, conversationStore} = useStores();

  const [showVideoCallDialog, setShowVideoCallDialog] = useState(false);
  const [displayMessage, setDisplayMessage] = useState('');

  const acceptCall = () => {
    setShowVideoCallDialog(true);
    const audio = document.getElementById(
      'ringingNotification',
    ) as HTMLAudioElement;
    audio.muted = true;
    audio.pause();
  };

  useEffect(() => {
    if (
      !props.owner &&
      props.message === CodedConversationMessages.VIDEO_CALL_TRIGGER
    ) {
      conversationStore.setLatestVideoCallRequest(props);
    }

    switch (props.message) {
      case CodedConversationMessages.VIDEO_CALL_TRIGGER:
        setDisplayMessage(
          props.owner
            ? `You have invited the ${
                props.isPatient ? 'doctor' : 'patient'
              } to a video call.`
            : `The ${
                props.isPatient ? 'patient' : 'doctor'
              } has invited you to a video call.`,
        );
        break;

      case CodedConversationMessages.VIDEO_CALL_ACCEPT:
        setDisplayMessage(
          props.owner
            ? `You have accepted the invitation for a video call with the ${
                props.isPatient ? 'doctor' : 'patient'
              }.`
            : `The ${
                props.isPatient ? 'patient' : 'doctor'
              } has accepted your invitation for a video call.`,
        );
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  return (
    <View>
      <Overlay isVisible={showVideoCallDialog}>
        <ConsultationVideoCallScreen
          procedureId={props.serviceRequest?.procedure?.procedureId || ''}
          subjectId={
            authStore.userRole === 'patient'
              ? props.serviceRequest?.subject.patientId!
              : ''
          }
          receiver={
            authStore.userRole === 'patient'
              ? props.serviceRequest?.performer!
              : props.serviceRequest?.subject!
          }
          fromInvitation={true}
          onConfirm={() => setShowVideoCallDialog(false)}
        />
      </Overlay>
      <RNEText label={displayMessage} color="white" variant="button" />
      {!props.owner &&
        props.message === CodedConversationMessages.VIDEO_CALL_TRIGGER && (
          <RNEButton
            title="Accept"
            color="accent"
            style={styles.buttonStyle}
            onPress={acceptCall}
            disabled={props.timestamp !== props.latestVideoCallRequestTimestamp}
          />
        )}
    </View>
  );
};

const useStyles = makeStyles({
  buttonStyle: {
    padding: 8,
  },
});

export default memo(ConsultationMessageVideoCallBubble);
