import React from 'react';
import {StyleSheet, View, TouchableOpacity} from 'react-native';

import {observer} from 'mobx-react';
import {useNavigation} from '@react-navigation/native';

import {SVGIcon, RNEText} from '../../components';

import {colorPalette} from '../../../core/config/color.config';

import {ConsultationRequestResBody} from '../../server/types/consultation.types';

import ConsultationPhoto from '../consultation/ConsultationPhoto';

import {PopoverItemKey} from '../popover/data/popoverItems.data';

interface HeaderConsultationDoctorProps {
  serviceRequest?: ConsultationRequestResBody;
  onMenuItemClick?: (itemKey: PopoverItemKey) => void;
  onBackClickFromList?: () => void;
}

const HeaderConsultationDoctor = (props: HeaderConsultationDoctorProps) => {
  const navigation = useNavigation();

  const onBackClick = () => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    }
  };

  return (
    <View accessibilityRole="button" style={styles.mainContainer}>
      <TouchableOpacity
        activeOpacity={0.75}
        onPress={
          props.onBackClickFromList ? props.onBackClickFromList : onBackClick
        }>
        <View style={styles.backContainer}>
          <SVGIcon name="left-gray" size={20} />
        </View>
      </TouchableOpacity>

      <View style={styles.profileContainer}>
        <View style={styles.profilePhoto}>
          <ConsultationPhoto
            photo={props.serviceRequest?.performer?.photo}
            size={36}
          />
        </View>

        <View style={styles.nameProfessionContainer}>
          <RNEText
            label={
              props.serviceRequest?.performer
                ? `Dr. ${props.serviceRequest?.performer?.humanName.given[0]} ${props.serviceRequest?.performer?.humanName.family}`
                : '--'
            }
            variant="button"
            weight="bold"
            numberOfLines={1}
          />
          <RNEText
            label={
              props.serviceRequest?.performer
                ? `${
                    props.serviceRequest?.performer?.practitionerRole?.[0]
                      .specialty.text || '--'
                  }`
                : '--'
            }
            variant="button"
            weight="300"
            color="blackLight"
            numberOfLines={1}
          />
        </View>
      </View>
    </View>
  );
};

export default observer(HeaderConsultationDoctor);

const styles = StyleSheet.create({
  mainContainer: {
    paddingHorizontal: 20,
    flexDirection: 'row',
    alignItems: 'center',
  },
  backContainer: {
    marginRight: 16,
  },
  profileContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  profilePhoto: {
    borderRadius: 50,
    borderWidth: 1,
    borderColor: colorPalette.primary,
    width: 36,
    height: 36,
    alignItems: 'center',
    justifyContent: 'center',
  },
  imageStyle: {
    height: 36,
    width: 36,
    borderRadius: 100,
    borderWidth: 1,
    borderColor: colorPalette.primary,
  },
  nameProfessionContainer: {
    marginLeft: 16,
    width: '65%',
  },
});
