import React, {useEffect, useState, createContext, useContext} from 'react';

import {Client as ConversationsClient} from '@twilio/conversations';

import useStores from './../../stores/useStores';
import {useRequestConversationAccessToken} from './../../server/react-query/useConversation';
import {observer} from 'mobx-react';

type IConversationContext = {
  conversation: any;
  initialized: boolean;
  getConversation: (uniqueName: string) => any;
  resetConversation: () => void;
};

export const ConversationContext = createContext<IConversationContext>({
  conversation: null,
  initialized: false,
  getConversation: () => null,
  resetConversation: () => null,
});

export const ConversationProvider = observer(({children}: any) => {
  const {
    conversationStore,
    patientProfileStore,
    authStore,
    doctorProfileStore,
  } = useStores();
  const [conversation, setConversation] = useState<any>();
  const [
    conversationsClient,
    setConversationsClient,
  ] = useState<ConversationsClient>();
  const [initialized, setInitialized] = useState(false);
  const [savedUniqueName, setSavedUniqueName] = useState('');

  const conversationMutation = useRequestConversationAccessToken();

  const getConversation = async (uniqueName: string) => {
    if (conversationsClient) {
      try {
        const conversationData = await conversationsClient.getConversationByUniqueName(
          uniqueName,
        );

        if (conversationData) {
          setConversation(conversationData);
        }
      } catch (error) {
        // When the patient profile is switched, the user.identity from the conversationsClient
        // is not the same with the active patient so it goes here. So, a new convo client is
        // created to cater the new active patient profile.
        // here -> create new convo client -> useEffect for savedUniqueName -> back to get the convo
        await initConversationAPI();
        setSavedUniqueName(uniqueName);
      }
    }
  };

  const resetConversation = () => {
    setConversation(undefined);
  };

  const getAccessToken = async () => {
    const subject =
      authStore.userRole === 'patient'
        ? patientProfileStore.activeProfile?.patientId
        : doctorProfileStore.doctorProfile?.practitionerId;

    const token = await conversationMutation.mutateAsync({
      subject: subject,
    });

    if (token) {
      conversationStore.setAccessToken(token);
    }
  };

  const initConversationAPI = async () => {
    if (
      !conversationsClient ||
      conversationsClient.user.identity !==
        patientProfileStore.activeProfile?.patientId
    ) {
      try {
        const client = await ConversationsClient.create(
          conversationStore.accessToken || '',
        );
        setConversationsClient(client);
        setInitialized(true);
      } catch (error) {
        getAccessToken();
      }
    } else {
      const client = await conversationsClient.updateToken(
        conversationStore.accessToken || '',
      );
      setConversationsClient(client);
    }
  };

  useEffect(() => {
    if (conversationStore.accessToken) {
      initConversationAPI();
    } else {
      getAccessToken();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversationStore.accessToken]);

  useEffect(() => {
    if (conversationsClient) {
      conversationsClient.on('connectionStateChanged', state => {
        if (state === 'connecting') {
          console.log('connecting');
        }
        if (state === 'connected') {
          console.log('connected');
        }
        if (state === 'disconnecting') {
          getAccessToken();
        }
        if (state === 'disconnected') {
          getAccessToken();
        }
        if (state === 'denied') {
          getAccessToken();
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversationsClient]);

  useEffect(() => {
    if (patientProfileStore.activeProfile?.patientId) {
      getAccessToken();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientProfileStore.activeProfile?.patientId]);

  useEffect(() => {
    if (savedUniqueName) {
      getConversation(savedUniqueName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedUniqueName]);

  return (
    <ConversationContext.Provider
      value={{
        conversation,
        initialized,
        getConversation,
        resetConversation,
      }}>
      {children}
    </ConversationContext.Provider>
  );
});

interface useConversationProps {
  procedureId: string;
}

const useConversation = () => {
  const _context = useContext(ConversationContext);

  if (!_context) {
    throw new Error('You have forgotten to use ConversationProvider.');
  }
  return _context;
};

export default useConversation;
