import React, {memo} from 'react';
import {View, TouchableOpacity} from 'react-native';

import {makeStyles} from 'rne-netzon';

import {RNEText, RNEButton} from './../../components';

import {colorPalette} from '../../../core/config/color.config';

interface AdminDashboardCardProps {
  bgColor?: string;
  count?: number;
  label?: string;
  onPress?: () => void;
  isLoading?: boolean;
}

const AdminDashboardCard = (props: AdminDashboardCardProps) => {
  const styles = useStyle();

  const {
    bgColor = colorPalette.primary,
    count = 0,
    label = '',
    isLoading = false,
  } = props;

  return (
    <TouchableOpacity activeOpacity={0.75} onPress={() => props.onPress?.()}>
      <View style={[styles.mainContainer, {backgroundColor: bgColor}]}>
        <View style={styles.countContainer}>
          {!isLoading ? (
            <RNEText
              label={count.toString()}
              variant="header"
              weight="600"
              color="white"
              style={labelStyle}
            />
          ) : (
            <RNEButton
              height={24}
              loading={isLoading}
              type="clear"
              loadingProps={loadingStyle}
              buttonStyle={buttonStyle}
            />
          )}
        </View>
        <View style={styles.labelContainer}>
          <RNEText label={label} variant="button" color="white" />
        </View>
      </View>
    </TouchableOpacity>
  );
};

const labelStyle = {
  fontFamily: 'Lato-Bold',
};

const buttonStyle = {
  paddingHorizontal: 0,
};

const loadingStyle = {
  color: colorPalette.white,
};

const useStyle = makeStyles({
  mainContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 16,
    paddingHorizontal: 32,
    borderRadius: 12,
    shadowOffset: {
      width: -5,
      height: 10,
    },
    shadowOpacity: 1,
    shadowRadius: 10,
    shadowColor: '#070F580D',
  },
  countStyle: {
    paddingRight: 12,
  },
  countContainer: {
    height: 48,
    minWidth: 24,
    flexDirection: 'row',
    alignItems: 'center',
  },
  labelContainer: {
    flexDirection: 'row',
    flex: 1,
    flexWrap: 'wrap',
    marginLeft: 16,
  },
});

export default memo(AdminDashboardCard);
