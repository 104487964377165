import {IconName} from '../../components';
import {NavigationKey} from '../../navigations/config';
import {MedicalAttachmentEnum} from '../../views/medical-attachments/data/medicalAttachment';
import {Coding} from './code.types';
import {ConsultationNote, ConsultationStatusEnum} from './consultation.types';
import {PatientProfileReqResponse} from './patient-profile.types';
import {PractitionerProfile} from './practitioner-profile.types';

export enum MedicationRequestStatusEnum {
  DRAFT = 'draft',
  COMPLETED = 'completed ',
}

export enum DiagnosticReportStatusEnum {
  PARTIAL = 'partial',
  FINAL = 'final',
}

export interface DoseAndRate {
  doseText: string;
  rateText: string;
}

export interface Timing {
  text: string;
}

export interface DosageInstruction {
  doseAndRate: DoseAndRate;
  timing: Timing;
}

export interface MedicationCodeableConcept {
  text?: string;
  code?: Coding[];
}

export interface MedicationRequestReqBody {
  medicationRequests: MedicationRequest[];
}

export interface MedicationRequest {
  basedOnId: string;
  status: MedicationRequestStatusEnum;
  dosageInstruction: DosageInstruction;
  medicationCodeableConcept: MedicationCodeableConcept;
  note: string;
}

export interface MedicationRequestReqBodyUI {
  generic: string;
  brand: string;
  dose: string;
  frequency: string;
  quantity: string;
  generalInstruction: string;
}

export interface MedicationRequestArrayUI {
  prescriptions: MedicationRequestReqBodyUI[];
}

export interface MedicationRequestResBody {
  medicationRequestId: string;
  basedOnId: string;
  groupId: string;
  subject: PatientProfileReqResponse;
  performer: PractitionerProfile;
  status: MedicationRequestStatusEnum;
  authoredOn: string;
  medicationCodeableConcept: MedicationCodeableConcept;
  dosageInstruction: DosageInstruction;
  note: ConsultationNote;
}

export interface DiagnosticReportReqBody {
  basedOnId: string;
  conclusion: string;
  status: DiagnosticReportStatusEnum;
}

export interface DiagnosticReportResBody {
  diagnosticReportId: string;
  basedOnId: string;
  status: DiagnosticReportStatusEnum;
  subject: PatientProfileReqResponse;
  performer: PractitionerProfile;
  conclusion: string;
  issued: string;
}

export interface LaboratoryRequestReqBody {
  code: MedicationCodeableConcept;
  basedOnId: string;
  note: string;
  status: ConsultationStatusEnum;
  performer: string;
}

export interface MedicalAttachmentText {
  note: string;
}

export interface GetPrescriptionParam {
  groupId: string;
}

export interface GetAllMedicalAttachmentsParam {
  serviceRequestId?: string;
}

export interface MedicalAttachmentsResBody {
  type: MedicalAttachmentEnum;
  id: string;
  createdOn: string;
}

export interface GetMedicalAttachmentParams {
  basedOnId: string;
  status: MedicationRequestStatusEnum | DiagnosticReportStatusEnum;
}

export interface GetLaboratoryRequestsParams {
  searchKey?: string;
  status?: ConsultationStatusEnum;
  code?: string;
}

export interface UpdateLaboratoryRequestReqBody {
  note: string;
  status: ConsultationStatusEnum;
}

export interface UpdateMedicalCertificateReqBody {
  conclusion: string;
  status: DiagnosticReportStatusEnum;
}

export interface UpdatePrescriptionReqBody {
  groupId: string;
  medicationRequests: MedicationRequest[];
}

export type TAttachmentType =
  | 'history'
  | 'media'
  | 'medicalRequest'
  | 'laboratoryServiceRequest'
  | 'diagnosticReport'
  | 'soundClip'
  | 'image';

export interface AttachmentAttribute {
  CreatedOn?: Date;
  Id?: string;
  Type: TAttachmentType;
}
