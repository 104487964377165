import React, {memo} from 'react';
import {Platform, View} from 'react-native';

import {makeStyles} from 'rne-netzon';

import {SVGIcon, RNEIconButton, RNEText} from '../../components';

interface ResetPasswordSuccessModalProps {
  onConfirm?: () => void;
}

function ResetPasswordSuccessModal(props: ResetPasswordSuccessModalProps) {
  const rneStyles = useStyles();

  return (
    <View style={rneStyles.root}>
      <RNEText
        label="You've successfully reset your password."
        variant="title"
        align="center"
        weight="600"
        color="accent"
      />

      <View style={rneStyles.actionContainer}>
        <RNEIconButton
          icon={<SVGIcon name="check-white" size={48} />}
          color="secondary"
          size={64}
          onPress={props.onConfirm}
          raised={Platform.OS !== 'web'}
        />
      </View>
    </View>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: Platform.OS === 'web' ? 420 : '80%',
    padding: Platform.OS === 'web' ? 32 : 16,
  },
  actionContainer: {
    marginTop: 36,
    alignItems: 'center',
  },
}));

export default memo(ResetPasswordSuccessModal);
