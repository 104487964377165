import React, {useEffect, useState} from 'react';
import {View} from 'react-native';
import {SvgProps} from 'react-native-svg';

import {IconName, IconList} from './iconSource';

interface IconProps {
  name: IconName;
  size?: number;
  width?: number;
  height?: number;
}

const SVGIcon = (props: IconProps) => {
  const {name = 'lock', size = 24, width = size, height = size} = props;

  const [Icon, setIcon] = useState<React.FC<SvgProps>>();

  const updateIcon = () => {
    setIcon(IconList[name]);
  };

  useEffect(() => {
    updateIcon();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  if (!Icon) {
    return null;
  }

  return (
    <View
      style={{
        width: width,
        height: height,
      }}>
      {Icon}
    </View>
  );
};

export default React.memo(SVGIcon);
