import React, {useState, useEffect} from 'react';
import {Platform} from 'react-native';

import {useTheme, Text, TextProps} from 'rne-netzon';
import {colorPalette} from '../../../../core/config/color.config';

import {
  TextColor,
  TextVariants,
  TextWeight,
  TypeScale,
  TextAlign,
  getTypeScale,
} from './models';

interface RNETextProps extends TextProps {
  color?: TextColor;
  label: string;
  variant?: TextVariants;
  weight?: TextWeight;
  align?: TextAlign;
}

export const RNEText = (props: RNETextProps) => {
  const {theme} = useTheme();

  const {
    label,
    style,
    align = 'left',
    variant = 'body',
    weight = '400',
    ...others
  } = props;

  const [color, setColor] = useState<string | undefined>(theme.colors?.grey1);
  const [typeScale, setTypeScale] = useState<TypeScale | undefined>();

  const updateStyle = () => {
    switch (props.color) {
      case 'primary':
        setColor(theme.colors?.primary);
        break;

      case 'secondary':
        setColor(theme.colors?.secondary);
        break;

      case 'success':
        setColor(theme.colors?.success);
        break;

      case 'warning':
        setColor(theme.colors?.warning);
        break;

      case 'error':
        setColor(theme.colors?.error);
        break;

      case 'white':
        setColor(theme.colors?.white);
        break;

      case 'black':
        setColor(theme.colors?.black);
        break;

      case 'grey':
        setColor(theme.colors?.grey0);
        break;

      case 'accent':
        setColor(colorPalette.accent);
        break;

      case 'purple':
        setColor(colorPalette.purple);
        break;

      case 'tint':
        setColor(colorPalette.tint);
        break;

      default:
        break;
    }
  };

  const updateTypeScale = () => {
    const scale = getTypeScale(weight, variant, Platform.OS === 'web');
    setTypeScale(scale);
  };

  useEffect(() => {
    updateStyle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.color]);

  useEffect(() => {
    updateTypeScale();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  return (
    <Text
      {...others}
      style={Object.assign(
        {
          color: color,
          fontSize: typeScale?.size,
          fontFamily: typeScale?.family,
          fontWeight: typeScale?.weight,
          textAlign: align,
        },
        style ?? {},
      )}>
      {label}
    </Text>
  );
};
