import React, {useEffect} from 'react';
import {Alert, PermissionsAndroid, Platform} from 'react-native';
import {QueryObserverResult, RefetchOptions} from 'react-query';

interface useDownloaderProps<T, S> {
  web: {
    fileName: string;
    data: T;
    download: (
      options?: RefetchOptions,
    ) => Promise<QueryObserverResult<T, unknown>>;
  };
  mobile: {
    data?: S;
    download: (options?: RefetchOptions | undefined) => Promise<S>;
  };
}

export const useDownloader = <T, S>({
  web,
  mobile,
}: useDownloaderProps<T, S>) => {
  const downloadAttachment = async () => {
    if (Platform.OS !== 'web') {
      if (Platform.OS === 'android') {
        try {
          const granted = await PermissionsAndroid.request(
            PermissionsAndroid.PERMISSIONS.WRITE_EXTERNAL_STORAGE,
          );
          if (granted === PermissionsAndroid.RESULTS.GRANTED) {
            mobile.download();
          } else {
            Alert.alert(
              'Permission Denied!',
              'You need to give storage permission to download the file',
            );
          }
        } catch (err) {
          console.warn(err);
        }
      } else {
        mobile.download();
      }
    } else {
      web.download();
    }
  };

  useEffect(() => {
    try {
      if (Platform.OS === 'web' && web.data) {
        const url = window.URL.createObjectURL(web.data);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = web.fileName + '.pdf';
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      }
    } catch (error) {
      console.error(error);
    }
  }, [web.data, web.fileName]);

  return {
    downloadAttachment,
  };
};
