import {useMutation, useQueryClient} from 'react-query';
import {API, ApiEndpointsEnum} from '../api';
import {PushNotificationReqBody} from '../types/push-notification.types';

const sendVideoCallNotification = (reqBody: PushNotificationReqBody) => {
  return API.post(`${ApiEndpointsEnum.POST_VIDEO_CALL_NOTIFICATION}`, reqBody);
};

const useSendVideoCallNotification = (reqBody: PushNotificationReqBody) => {
  const queryClient = useQueryClient();

  return useMutation(
    ['sendVideoCallNotification'],
    () => sendVideoCallNotification(reqBody),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['sendVideoCallNotification']);
      },
    },
  );
};

const sendPushNotification = (body: PushNotificationReqBody) => {
  return API.post(`${ApiEndpointsEnum.POST_PUSH_NOTIFICATION}`, body);
};

const useSendMobilePushNotification = (body: PushNotificationReqBody) => {
  const query = useQueryClient();

  return useMutation(
    ['sendPushNotification'],
    () => sendPushNotification(body),
    {
      onSuccess: () => {
        query.invalidateQueries(['sendPushNotification']);
      },
    },
  );
};

export {useSendVideoCallNotification, useSendMobilePushNotification};
