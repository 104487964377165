import React from 'react';

import {
  ConsultationOngoingTab,
  ConsultationPendingTab,
  ConsultationHistoryTab,
} from '../../views';

interface ConsultationMobileListScreenProps {
  tabType: 'ongoing' | 'pending' | 'history';
}

const ConsultationMobileListScreen = (
  props: ConsultationMobileListScreenProps,
) => {
  return (
    <>
      {props.tabType === 'ongoing' && <ConsultationOngoingTab />}
      {props.tabType === 'pending' && <ConsultationPendingTab />}
      {props.tabType === 'history' && <ConsultationHistoryTab />}
    </>
  );
};

export default ConsultationMobileListScreen;
