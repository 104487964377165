import React, {useLayoutEffect, useEffect, useState} from 'react';
import {Platform} from 'react-native';

import {useNavigation, useRoute} from '@react-navigation/native';

import {ConsultationPatientMedicalView, HeaderBackLeft} from './../../views';

import {PatientProfileReqResponse} from '../../server/types/patient-profile.types';
import {ConsultationStatusEnum} from '../../server/types/consultation.types';

const ConsultationPatientMedicalScreen = () => {
  const route = useRoute();
  const navigation = useNavigation();

  const [patient, setPatient] = useState<PatientProfileReqResponse>();
  const [status, setStatus] = useState<ConsultationStatusEnum>(
    ConsultationStatusEnum.PENDING,
  );

  const onCancel = () => {
    goBack();
  };

  const goBack = () => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    }
  };

  useLayoutEffect(() => {
    if (Platform.OS !== 'web') {
      navigation.setOptions({
        title: '',
        headerLeft: () => <HeaderBackLeft label="Medical History" />,
        headerStyle: {
          borderBottomColor: 'transparent',
          elevation: 0,
          shadowOpacity: 0,
        },
      });
    }
  }, [navigation]);

  useEffect(() => {
    const params: any = route.params;

    if (params && (params.patient || params.status)) {
      if (params.patient) {
        setPatient(params.patient);
      }
      if (params.status) {
        setStatus(params.status);
      }
    } else {
      goBack();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route.params]);

  return (
    <>
      {!!patient && (
        <ConsultationPatientMedicalView
          patient={patient}
          status={status}
          onClose={onCancel}
        />
      )}
    </>
  );
};

export default ConsultationPatientMedicalScreen;
