import {useMutation, useQueryClient} from 'react-query';
import {API, ApiEndpointsEnum} from '../api';

import {LoginUserMock} from '../mock/login.mock';

import {LoginReqBody, LoginReqResponse} from '../types/authentication.types';

// --- LOGIN ---
const signupMock = async (reqBody: LoginReqBody) => {
  const data = await new Promise<LoginReqResponse>(resolve =>
    setTimeout(() => resolve(LoginUserMock), 1000),
  );

  return data;
};
const login = (reqBody: LoginReqBody) => {
  return API.post<LoginReqResponse>(`${ApiEndpointsEnum.LOGIN}`, reqBody);
};

const useLogin = (reqBody: LoginReqBody) => {
  const queryClient = useQueryClient();

  return useMutation(['login'], () => login(reqBody), {
    onSuccess: () => {
      queryClient.invalidateQueries(['login']);
    },
  });
};

export {useLogin};
