import React, {useState, useEffect} from 'react';
import {
  Animated,
  Platform,
  View,
  Image,
  ScrollView,
  TouchableWithoutFeedback,
} from 'react-native';
import {observer} from 'mobx-react';
import {useQueryClient} from 'react-query';

import {SafeAreaView} from 'react-native-safe-area-context';
import {Overlay, makeStyles} from 'rne-netzon';
import {SVGIcon, RNEText} from '../../components';

import {
  useRoute,
  useNavigation,
  getFocusedRouteNameFromRoute,
} from '@react-navigation/native';
import {NavigationKey} from '../../navigations/config';

import {LogoLight} from '../../images/Image';

import useStores from '../../stores/useStores';
import LogoutConfirmationModal from '../modal/LogoutConfirmationModal';
import SideMenuItem from './SideMenuItem';
import {menuWidth, doctorSideMenuItemGroup} from './data/sideMenuItem.data';

import {useGetAccount} from '../../server/react-query/useAccount';
import {
  useGetPractitionerHospitals,
  useGetPractitionerSpecializations,
} from '../../server/react-query/usePractitioner';
import {LoginReqResponse} from '../../server/types/authentication.types';
import {colorPalette} from '../../../core/config/color.config';
import {User} from '../../server/types/user.types';

import {useSideMenu} from './../../hooks/menu/useSideMenu';
import {useSaveFirebaseToken} from '../../server/react-query/useFirebaseToken';
import {deleteToken, getMessaging, getToken} from 'firebase/messaging';
import {FCM_WEB_VAPID_KEY} from '../../server/api/api.config';

const DoctorSideMenu = () => {
  const {
    authStore,
    patientProfileStore,
    doctorProfileStore,
    specializationStore,
    hospitalStore,
    recentlyViewedAccountsStore,
    conversationStore,
  } = useStores();
  const route = useRoute();
  const navigation = useNavigation();
  const sharedStyles = useSharedStyles();
  const webStyles = useWebStyles();

  const messaging = getMessaging();

  const [deviceId, setDeviceId] = useState('');
  const [token, setToken] = useState('');

  const queryClient = useQueryClient();

  const {widthValue, compressed, toggleMenu} = useSideMenu({
    minValue: menuWidth.compressed,
    maxValue: menuWidth.expanded,
  });

  const fbTokenmutation = useSaveFirebaseToken({
    deviceId,
    token,
  });

  const [accountOwner, setAccountOwner] = useState(authStore.auth?.user);
  const [
    showVerificationSuccessModal,
    setShowVerificationSuccessModal,
  ] = useState(false);

  // check changes of the use account info
  const {data} = useGetAccount();
  const specializationQuery = useGetPractitionerSpecializations();
  const hospitalQuery = useGetPractitionerHospitals();

  const getFirebaseToken = async () => {
    const swRegistration = await navigator.serviceWorker.register(
      '../../../firebase-messaging-sw.js',
    );
    getToken(messaging, {
      serviceWorkerRegistration: swRegistration,
      vapidKey: FCM_WEB_VAPID_KEY,
    })
      .then(async (token: string) => {
        if (token) {
          console.log('Firebase Token', token);
          setToken(token);
          await fbTokenmutation.mutateAsync();
        } else {
          console.log(
            'No registration token available. Request permission to generate one.',
          );
        }
      })
      .catch((err: any) => {
        console.log('An error occurred while retrieving token. ', err);
      });
  };

  useEffect(() => {
    if (authStore.authData && data) {
      const aguid = require('aguid');
      setDeviceId(aguid(navigator.userAgent));
      if (
        authStore.userRole === data.userRole &&
        authStore.auth?.user.practitionerId === data.practitionerId
      ) {
        const authData: LoginReqResponse = {...authStore.authData};
        authData.user = data;
        authStore.setAuth(authData);
        setAccountOwner(authStore.auth?.user);
        checkIfVerified(data);
      }

      getFirebaseToken();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (specializationQuery.data) {
      specializationStore.setSpecializations(specializationQuery.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [specializationQuery.data]);

  useEffect(() => {
    if (hospitalQuery.data) {
      hospitalStore.setHospitals(hospitalQuery.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hospitalQuery.data]);

  const [showDialog, setShowDialog] = useState(false);
  const [currentScreen, setCurrentScreen] = useState(
    NavigationKey.DOCTOR_LANDING_STACK,
  );

  const checkIfVerified = (useData: User) => {
    if (
      doctorProfileStore.partialData?.status === 'pending' &&
      useData.practitionerStatus === 'verified'
    ) {
      setShowVerificationSuccessModal(true);
    }
  };

  const navigateTo = (
    navigationKey: NavigationKey,
    shouldSetScreen: boolean = true,
  ) => {
    if (shouldSetScreen) {
      setCurrentScreen(navigationKey);
    }

    if (navigationKey === NavigationKey.LOGOUT) {
      if (Platform.OS === 'web') {
        toggleLogoutConfirmation();
      } else {
        logout();
      }
    } else {
      if (navigationKey === NavigationKey.DOCTOR_CONSULTATION_STACK) {
        navigation.navigate(NavigationKey.DOCTOR_CONSULTATION_STACK, {
          screen: NavigationKey.CONSULTATION_LIST,
          params: {
            screen: NavigationKey.CONSULTATION_TAB_ONGOING,
            tab: 0,
          },
        });
      } else {
        navigation.navigate(navigationKey);
      }
    }
  };

  const goToDashboard = () => {
    setShowVerificationSuccessModal(false);
    setCurrentScreen(NavigationKey.DOCTOR_SCREEN_LANDING);
    navigation.navigate(NavigationKey.DOCTOR_SCREEN_LANDING);
  };

  const toggleLogoutConfirmation = () => {
    setShowDialog(true);
  };

  const deleteFCMToken = () => {
    deleteToken(messaging);
  };

  const logout = () => {
    queryClient.clear();
    setShowDialog(false);
    deleteFCMToken();
    conversationStore.resetAccessToken();
    authStore.logout();
    doctorProfileStore.logout();
    patientProfileStore.logout();
    recentlyViewedAccountsStore.resetRecentlyViewedAccount();
  };

  useEffect(() => {
    const routeName = getFocusedRouteNameFromRoute(route);
    if (routeName) {
      setCurrentScreen(routeName as NavigationKey);
    }
  }, [route]);

  const userNameStyle = {
    width: 140,
  };

  return (
    <SafeAreaView style={sharedStyles.root}>
      <Overlay
        isVisible={showDialog}
        overlayStyle={sharedStyles.overlayContainer}>
        <LogoutConfirmationModal
          onConfirm={logout}
          onCancel={() => setShowDialog(false)}
        />
      </Overlay>
      {/* <Overlay
        isVisible={showVerificationSuccessModal}
        overlayStyle={sharedStyles.overlayContainer}>
        <DoctorVerificationSuccessModal onConfirm={goToDashboard} />
      </Overlay> */}
      <Animated.View
        style={[
          webStyles.mainContainer,
          {
            width: widthValue,
          },
        ]}>
        <View style={webStyles.topWrapper}>
          {!compressed && (
            <View accessibilityRole="button">
              <Image style={webStyles.logo} source={LogoLight} />
            </View>
          )}
          <TouchableWithoutFeedback onPress={toggleMenu}>
            <View accessibilityRole="button">
              <SVGIcon name="menu" size={44} />
            </View>
          </TouchableWithoutFeedback>
        </View>
        <View style={webStyles.bodyWrapper}>
          <ScrollView>
            {doctorSideMenuItemGroup.map((menuGroup, groupIndex) => (
              <View key={groupIndex} style={webStyles.contentWrapper}>
                {menuGroup.map((menuItem, itemIndex) => (
                  <SideMenuItem
                    key={itemIndex}
                    label={menuItem.label}
                    iconName={menuItem.iconName}
                    onPress={() =>
                      navigateTo(
                        menuItem.navigationKey,
                        menuItem.navigationKey !== NavigationKey.LOGOUT,
                      )
                    }
                    active={currentScreen === menuItem.navigationKey}
                    disabled={
                      (doctorProfileStore.doctorStatus !== 'verified' &&
                        menuItem.navigationKey ===
                          NavigationKey.PATIENT_NOTIFICATIONS) ||
                      (doctorProfileStore.doctorStatus !== 'verified' &&
                        menuItem.navigationKey ===
                          NavigationKey.CONSULTATION_LIST)
                    }
                    hideLabel={compressed}
                  />
                ))}
              </View>
            ))}
          </ScrollView>
        </View>
        <TouchableWithoutFeedback
          onPress={() => navigateTo(NavigationKey.DOCTOR_ACCOUNT_STACK)}>
          <View style={webStyles.profileWrapper} accessibilityRole="button">
            <View style={webStyles.profile}>
              {accountOwner?.photo ? (
                <Image
                  source={{uri: accountOwner.photo.url}}
                  style={
                    !compressed
                      ? webStyles.fullImageStyle
                      : webStyles.compressedImageStyle
                  }
                />
              ) : (
                <SVGIcon name="user" size={compressed ? 42 : 56} />
              )}

              {!compressed && (
                <View style={webStyles.userInfo}>
                  <RNEText
                    label={`${accountOwner?.firstName} ${accountOwner?.lastName}`}
                    variant="body"
                    color="black"
                    weight="bold"
                    numberOfLines={1}
                    style={userNameStyle}
                  />
                  <RNEText
                    label={
                      accountOwner?.location ? accountOwner?.location : 'N/A'
                    }
                    variant="button"
                    color="black"
                    numberOfLines={1}
                    style={userNameStyle}
                  />
                </View>
              )}
            </View>
          </View>
        </TouchableWithoutFeedback>
      </Animated.View>
    </SafeAreaView>
  );
};

export default observer(DoctorSideMenu);

const useWebStyles = makeStyles({
  mainContainer: {
    height: '100vh',
    padding: 48,
    justifyContent: 'space-between',
    backgroundColor: colorPalette.white,
  },
  topWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    // flex: 0.5,
    height: 72,
    marginBottom: 60,
    backgroundColor: colorPalette.white,
  },
  logo: {
    width: 168,
    height: 48,
  },
  bodyWrapper: {
    minWidth: 200,
    flex: 2,
    justifyContent: 'space-evenly',
  },
  contentWrapper: {
    marginBottom: 40,
  },
  contentItem: {
    flexDirection: 'row',
    marginBottom: 20,
    alignItems: 'center',
    position: 'relative',
  },
  contentItemOverlay: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
    position: 'absolute',
    backgroundColor: colorPalette.white,
    zIndex: 1,
    opacity: 0.6,
  },
  subcontentWrapper: {},
  subcontentItem: {
    flexDirection: 'row',
    marginBottom: 20,
    alignItems: 'center',
  },
  profileWrapper: {
    width: '100%',
    height: 60,
    justifyContent: 'flex-end',
    marginTop: 12,
    backgroundColor: colorPalette.white,
  },
  profile: {
    flexDirection: 'row',
  },
  userInfo: {
    paddingVertical: 8,
    paddingLeft: 20,
  },
  fullImageStyle: {
    height: 60,
    width: 60,
    borderRadius: 100,
  },
  compressedImageStyle: {
    height: 48,
    width: 48,
    borderRadius: 100,
  },
});

const useSharedStyles = makeStyles({
  root: {
    height: '100%',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
  },
  overlayContainer: {
    borderRadius: 20,
  },
  iconContainer: {
    width: 64,
  },
  labelContainer: {
    flex: 1,
  },
});
