import React, {memo} from 'react';
import {useNavigation} from '@react-navigation/native';
import {Platform, ScrollView, View} from 'react-native';
import {TouchableWithoutFeedback} from 'react-native-gesture-handler';

import {makeStyles, Divider} from 'rne-netzon';
import {RNEText, SVGIcon} from '../../components';
import {MedicalAttachmentEnum} from './data/medicalAttachment';
import {formatDateString} from '../../utils/date.utils';
import {NavigationKey} from '../../navigations/config';
import {MedicalAttachmentsResBody} from '../../server/types/medical-attachment.types';

export interface MedicalAttachmentsListViewProps {
  serviceRequestId?: string;
  subject?: string;
  attachments?: MedicalAttachmentsResBody[];
  onSelectAttachment?: (
    medicalAttachmentType: MedicalAttachmentEnum,
    id: string,
  ) => void;
}

const MedicalAttachmentsListView = (props: MedicalAttachmentsListViewProps) => {
  const sharedStyles = useSharedStyles();
  const webStyles = useWebStyles();
  const mobileStyles = useMobileStyles();
  const navigation = useNavigation();

  const assignValue = (
    medicalAttachmentType: MedicalAttachmentEnum,
    toReturn: string,
  ): string => {
    let label = '';
    let navigationKey = '';

    switch (medicalAttachmentType) {
      case MedicalAttachmentEnum.LABORATORY_REQUEST:
        label = 'Laboratory Request';
        navigationKey = NavigationKey.VIEW_LABORATORY_REQUEST;
        break;

      case MedicalAttachmentEnum.MEDICAL_CERTIFICATE:
        label = 'Medical Certificate';
        navigationKey = NavigationKey.VIEW_MEDICAL_CERTIFICATE;
        break;

      case MedicalAttachmentEnum.PRESCRIPTION:
        navigationKey = NavigationKey.VIEW_PRESCRIPTION;
        label = 'Prescription';
        break;
    }

    if (toReturn === 'label') {
      return label;
    } else {
      return navigationKey;
    }
  };

  return (
    <View style={sharedStyles.root}>
      {Platform.OS === 'web' ? (
        <View style={webStyles.mainContainer}>
          <View style={webStyles.titleContainer}>
            <RNEText
              label="Medical Attachments"
              variant="header"
              align="center"
              weight="bold"
              color="primary"
              style={titleStyle}
            />
          </View>
          <Divider />
          <ScrollView>
            {props.attachments &&
              props.attachments.map((attachment, index) => (
                <View key={index}>
                  <TouchableWithoutFeedback
                    onPress={() => {
                      props.onSelectAttachment &&
                        props.onSelectAttachment(
                          attachment.type,
                          attachment.id,
                        );
                    }}>
                    <View
                      style={webStyles.rowContainer}
                      accessibilityRole="button">
                      <View style={webStyles.iconContainer}>
                        <SVGIcon
                          name={
                            attachment.type ===
                              MedicalAttachmentEnum.MEDICAL_CERTIFICATE ||
                            attachment.type ===
                              MedicalAttachmentEnum.LABORATORY_REQUEST
                              ? 'message'
                              : 'waiver'
                          }
                          size={40}
                        />
                      </View>
                      <View style={webStyles.textContainer}>
                        <RNEText
                          label={assignValue(attachment.type, 'label')}
                          variant="body"
                        />
                      </View>
                      <View style={webStyles.dateContainer}>
                        <RNEText
                          label={`${formatDateString(attachment.createdOn)}`}
                          variant="body"
                          color="grey"
                        />
                      </View>
                    </View>
                  </TouchableWithoutFeedback>
                  {index < props.attachments!.length - 1 && <Divider />}
                </View>
              ))}
          </ScrollView>
        </View>
      ) : (
        <ScrollView>
          <View style={mobileStyles.mainContainer}>
            <Divider />
            {props.attachments &&
              props.attachments.map((attachment, index) => (
                <View key={index}>
                  <TouchableWithoutFeedback
                    onPress={() => {
                      navigation.navigate(
                        assignValue(attachment.type, 'navigation'),
                        {
                          id: attachment.id,
                          subject: props.subject,
                        },
                      );
                    }}>
                    <View
                      style={mobileStyles.rowContainer}
                      accessibilityRole="button">
                      <View style={mobileStyles.iconContainer}>
                        <SVGIcon
                          name={
                            attachment.type ===
                              MedicalAttachmentEnum.MEDICAL_CERTIFICATE ||
                            attachment.type ===
                              MedicalAttachmentEnum.LABORATORY_REQUEST
                              ? 'message'
                              : 'waiver'
                          }
                          size={28}
                        />
                      </View>
                      <View style={mobileStyles.textContainer}>
                        <RNEText
                          label={assignValue(attachment.type, 'label')}
                          variant="body"
                        />
                      </View>
                    </View>
                  </TouchableWithoutFeedback>
                  <Divider />
                </View>
              ))}
          </View>
        </ScrollView>
      )}
    </View>
  );
};

const titleStyle = {
  fontSize: 24,
};

const useWebStyles = makeStyles({
  mainContainer: {
    width: 600,
    maxWidth: 640,
    height: 400,
    maxHeight: 500,
  },
  titleContainer: {
    justifyContent: 'center',
    padding: 32,
    height: '35%',
  },
  rowContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    flex: 1,
    paddingHorizontal: 40,
    paddingVertical: 20,
  },
  iconContainer: {
    width: 60,
  },
  textContainer: {
    flex: 1,
  },
  dateContainer: {
    flex: 0.5,
    alignItems: 'flex-end',
  },
});

const useMobileStyles = makeStyles({
  mainContainer: {
    width: '100%',
    flex: 1,
    paddingHorizontal: 24,
  },
  rowContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    flex: 1,
    paddingHorizontal: 40,
    paddingVertical: 20,
  },
  iconContainer: {
    width: 60,
  },
  textContainer: {
    flex: 1,
  },
});

const useSharedStyles = makeStyles({
  root: {
    width: '100%',
    flexDirection: 'column',
  },
});
export default memo(MedicalAttachmentsListView);
