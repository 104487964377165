import React from 'react';
import {View, Text} from 'react-native';

import {makeStyles} from 'rne-netzon';

import {NavigationKey} from '../../navigations/config';
import {useNavigation} from '@react-navigation/native';

import {colorPalette} from '../../../core/config/color.config';
import {RNEText} from '../../components';

import {useAdminDashboardReport} from '../../server/react-query/useAdminDashboard';

import {AdminDashboardCard} from '../../views';

const AdminLandingScreen = () => {
  const styles = useStyle();
  const navigation = useNavigation();

  const {data, isLoading} = useAdminDashboardReport();

  const goToPatientList = () => {
    navigation.navigate(NavigationKey.ADMIN_PATIENTS_LST);
  };

  const goToDoctorList = (tabIndex: number) => {
    navigation.navigate(NavigationKey.ADMIN_DOCTORS, {
      screen: NavigationKey.ADMIN_DOCTORS_LIST,
      params: {
        tab: tabIndex,
      },
    });
  };

  return (
    <View style={styles.rootContainer}>
      <View style={styles.cardGroupContainer}>
        <RNEText label="Doctor" color="accent" variant="title" weight="600" />
        <View style={styles.cardsContainer}>
          <View style={styles.cardContainer}>
            <AdminDashboardCard
              isLoading={isLoading}
              count={data?.practitionerCount.pending}
              label="New Account Verification Request"
              bgColor={colorPalette.success}
              onPress={() => goToDoctorList(0)}
            />
          </View>

          <View style={styles.cardContainer}>
            <AdminDashboardCard
              isLoading={isLoading}
              count={data?.practitionerCount.verified}
              label="Verified Doctor Account"
              bgColor={colorPalette.tint}
              onPress={() => goToDoctorList(1)}
            />
          </View>
        </View>
      </View>

      <View style={styles.cardGroupContainer}>
        <RNEText label="User" color="accent" variant="title" weight="600" />
        <View style={styles.cardsContainer}>
          <View style={styles.cardContainer}>
            <AdminDashboardCard
              isLoading={isLoading}
              count={data?.verifiedUsersCount}
              label="Verified User Account"
              bgColor={colorPalette.primary}
              onPress={() => goToPatientList()}
            />
          </View>
        </View>
      </View>
    </View>
  );
};

const useStyle = makeStyles({
  rootContainer: {
    paddingVertical: 120,
    paddingHorizontal: 72,
  },
  cardGroupContainer: {
    marginBottom: 24,
  },
  cardsContainer: {
    marginTop: 12,
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  cardContainer: {
    marginRight: 24,
    marginBottom: 24,
    flexShrink: 1,
    flexGrow: 0,
    minWidth: 200,
    maxWidth: 250,
    width: '100%',
  },
});

export default AdminLandingScreen;
