import {useNavigation, useRoute} from '@react-navigation/native';
import React, {memo, useEffect, useState} from 'react';
import {KeyboardAvoidingView, Platform, View} from 'react-native';
import {useToast} from 'react-native-fast-toast';

import {makeStyles, Overlay} from 'rne-netzon';
import {
  SVGIcon,
  RNEButton,
  RNEText,
  RNEIconButton,
  RNEInput,
} from '../../components';
import {NavigationKey} from '../../navigations/config';
import {useDeleteAccount} from '../../server/react-query/useAccount';
import {useDeletePatientProfile} from '../../server/react-query/usePatientProfile';

function AccountDeletionConfirmation() {
  const rneStyles = useStyles();
  const navigation = useNavigation();
  const route = useRoute();
  const toast = useToast();

  const [password, setPassword] = useState('');
  const [patientId, setPatientId] = useState('');
  const [accountOwner, setAccountOwner] = useState(false);
  const [hasBeenDeleted, setHasBeenDeleted] = useState(false);
  const [forDoctor, setForDoctor] = useState(false);

  const deleteAccountMutation = useDeleteAccount();
  const deletePatientProfileMutation = useDeletePatientProfile();

  const deleteAccount = async () => {
    try {
      await deleteAccountMutation.mutateAsync(password);
      setHasBeenDeleted(true);
    } catch (error) {
      console.log('error', error);
      if (error?.data?.error) {
        toast?.show(error?.data?.error, {type: 'danger'});
      }
    }
  };

  const deletePatientProfile = async () => {
    try {
      await deletePatientProfileMutation.mutateAsync(patientId || '');
      setHasBeenDeleted(true);
    } catch (error) {
      console.log('error', error);
      if (error?.data?.error) {
        toast?.show(error?.data?.error, {type: 'danger'});
      }
    }
  };

  const goBack = () => {
    if (accountOwner) {
      navigation.goBack();
    } else {
      navigation.navigate(NavigationKey.PATIENT_PROFILES);
    }
  };

  useEffect(() => {
    const params: any = route.params;
    if (params) {
      if (params.patientId) {
        setPatientId(params.patientId);
      }
      if (params.accountOwner) {
        setAccountOwner(params.accountOwner);
      }
      if (params.accountOwner) {
        setForDoctor(params.forDoctor);
      }
    }
  }, [route.params]);

  return (
    <View style={rneStyles.root}>
      <>
        <RNEText
          label={
            accountOwner
              ? 'You are about to permanently delete your account.'
              : 'You are about to permanently delete a patient profile.'
          }
          variant="header"
          align="center"
          weight="600"
          color="accent"
        />

        <View style={rneStyles.subtitleContainer}>
          <RNEText
            label={
              accountOwner
                ? `${
                    !forDoctor
                      ? 'You will lose all your profile information, medical history, along with the sub profiles under your account.\n'
                      : ''
                  } 
                  Once you submit your account for deletion, you have 30 days to reactivate your account. After 30 days, you won't be
                  able to retrieve any of the content or information you have added.\n\nTo confirm account deletion, please enter your password.`
                : `All medical information (patient details, allergies, family conditions, hospitalizations, maintenance medicines,
                      and personal and social information) of the patient profile will be permanently deleted.`
            }
            variant="body"
            align="center"
          />
        </View>

        {accountOwner && (
          <KeyboardAvoidingView
            behavior={Platform.OS === 'ios' ? 'padding' : undefined}
            keyboardVerticalOffset={80}>
            <View style={rneStyles.inputContainer}>
              <RNEInput
                placeholder="Password"
                secureTextEntry
                value={password}
                onChangeText={text => setPassword(text)}
                onSubmitEditing={
                  accountOwner ? deleteAccount : deletePatientProfile
                }
              />
            </View>
          </KeyboardAvoidingView>
        )}

        <View style={rneStyles.actionContainer}>
          <View style={rneStyles.buttonContainer}>
            <RNEButton
              title="Cancel"
              color="secondary"
              onPress={navigation.goBack}
              style={rneStyles.buttonStyle}
              variant="outlined"
            />
          </View>
          <RNEButton
            title="Delete"
            color="secondary"
            onPress={accountOwner ? deleteAccount : deletePatientProfile}
            style={rneStyles.buttonStyle}
            disabled={accountOwner && !password}
            loading={
              deleteAccountMutation.isLoading ||
              deletePatientProfileMutation.isLoading
            }
          />
        </View>
      </>

      <Overlay
        isVisible={hasBeenDeleted}
        overlayStyle={rneStyles.overlayContainerMobile}>
        <View style={rneStyles.messageContainer}>
          <RNEText
            label={
              accountOwner
                ? 'Account Deletion Request Sent'
                : 'Patient Profile Deleted'
            }
            variant="header"
            align="center"
            weight="600"
            color="accent"
          />

          <View style={rneStyles.iconContainer}>
            <RNEIconButton
              icon={<SVGIcon name="check-white" size={48} />}
              color="secondary"
              size={64}
              onPress={goBack}
            />
          </View>
        </View>
      </Overlay>
    </View>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    justifyContent: 'center',
    alignSelf: 'center',
    padding: 40,
  },
  subtitleContainer: {
    marginTop: 40,
  },
  actionContainer: {
    marginTop: 80,
    justifyContent: 'center',
    flexDirection: 'row',
  },
  buttonContainer: {
    marginRight: 16,
  },
  buttonStyle: {
    width: 160,
  },
  messageContainer: {
    marginVertical: 40,
    marginHorizontal: 20,
    alignItems: 'center',
  },
  iconContainer: {
    alignSelf: 'center',
    marginTop: 40,
  },
  inputContainer: {
    marginTop: 32,
  },
  overlayContainerMobile: {
    borderRadius: 20,
    marginHorizontal: '10%',
  },
}));

export default memo(AccountDeletionConfirmation);
