import React, {memo} from 'react';
import {View} from 'react-native';

import ReactAudioPlayer from 'react-audio-player';
import {useGetMediaUrl} from '../../server/react-query/useConversation';

interface ConsultationSoundClipAttachmentProps {
  mediaId: string;
  subject?: string;
}

const ConsultationSoundClipAttachment = (
  props: ConsultationSoundClipAttachmentProps,
) => {
  const {data} = useGetMediaUrl({
    subject: props.subject,
    mediaId: props.mediaId,
  });

  return (
    <View nativeID="web-player">
      <ReactAudioPlayer src={data?.url} controls />
    </View>
  );
};

export default memo(ConsultationSoundClipAttachment);
