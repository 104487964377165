import {makeAutoObservable, observable, action, computed} from 'mobx';
import {UploadPatientProfilePhotoReqBody} from '../server/types/patient-profile.types';

import {persistStore} from '../utils/mobx.utils';

export interface IPatientProfilePartialData {
  firstName: string;
  middleName: string;
  lastName: string;
  birthdate?: string;
  location?: string;
  contactNumber?: string;
  relationship: string;
  sex?: string;
}

export interface ISelectedAccount {
  patientId: string;
  accountOwner: boolean;
}

export interface IActiveProfile {
  patientId: string;
  firstName: string;
  lastName: string;
  imageUrl?: string;
  sex: string;
}

export class PatientProfileStore {
  partialData: IPatientProfilePartialData | null = null;
  accountData: ISelectedAccount | null = null;
  activeProfile: IActiveProfile | null = null;
  photo: UploadPatientProfilePhotoReqBody | null = null;

  constructor() {
    makeAutoObservable(this, {
      partialData: observable,
      accountData: observable,
      activeProfile: observable,
      photo: observable,
      setPartialData: action,
      setSelectedProfile: action,
      setActivePatient: action,
      removePartialData: action,
      resetSelectedProfile: action,
      logout: action,
      draftData: computed,
      editingProfileData: computed,
      activeProfileData: computed,
    });

    persistStore(
      this,
      ['partialData', 'accountData', 'activeProfile', 'photo'],
      'PatientProfileStore',
    );
  }

  setPartialData(data: IPatientProfilePartialData) {
    this.partialData = data;
  }

  removePartialData() {
    this.partialData = null;
  }

  setSelectedProfile(accountData: ISelectedAccount) {
    this.accountData = accountData;
  }

  resetSelectedProfile() {
    this.accountData = null;
  }

  setActivePatient(activeProfile: IActiveProfile) {
    this.activeProfile = activeProfile;
  }

  setPhoto(image: UploadPatientProfilePhotoReqBody) {
    this.photo = image;
  }

  resetPhoto() {
    this.photo = null;
  }

  logout() {
    this.activeProfile = null;
    this.accountData = null;
    this.partialData = null;
  }

  get draftData() {
    return this.partialData;
  }

  get editingProfileData() {
    return this.accountData;
  }

  get activeProfileData() {
    return this.activeProfile;
  }
}
