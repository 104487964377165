import axios, {AxiosRequestConfig} from 'axios';

import {apiConfig} from './api.config';
import {getJWT} from './api.helper';

const axiosInstance = axios.create(apiConfig);

axiosInstance.interceptors.request.use(async config => {
  const jwt = await getJWT();

  const headerAuthorization: any = {
    'Content-Type': 'application/json',
  };

  if (jwt) {
    headerAuthorization.Authorization = 'Bearer ' + jwt;
  }

  return {
    ...config,
    headers: {
      ...headerAuthorization,
      ...config.headers,
    },
  };
});

axiosInstance.interceptors.response.use(
  params => {
    // Add something if needed

    return {
      ...params,
    };
  },
  error => {
    return Promise.reject(error.response);
  },
);

export async function get<T>(
  path: string,
  config?: AxiosRequestConfig | undefined,
): Promise<T> {
  if (config && !config.data) {
    config.data = null;
  } else {
    config = {};
    config.data = null;
  }

  const {data} = await axiosInstance.get<T>(path, config);
  return data;
}

export async function post<T>(
  path: string,
  reqData?: any,
  config?: AxiosRequestConfig | undefined,
): Promise<T> {
  const {data} = await axiosInstance.post<T>(path, reqData, config);
  return data;
}

export async function patch<T>(
  path: string,
  reqData?: any,
  config?: AxiosRequestConfig | undefined,
): Promise<T> {
  const {data} = await axiosInstance.patch<T>(path, reqData, config);
  return data;
}

export async function put<T>(
  path: string,
  reqData?: any,
  config?: AxiosRequestConfig | undefined,
): Promise<T> {
  const {data} = await axiosInstance.put<T>(path, reqData, config);
  return data;
}

export async function del<T>(
  path: string,
  config?: AxiosRequestConfig | undefined,
): Promise<T> {
  const {data} = await axiosInstance.delete<T>(path, config);
  return data;
}

export async function download<T>(
  path: string,
  config?: AxiosRequestConfig | undefined,
  fileName?: string,
) {
  if (config && !config.data) {
    config.data = null;
  } else {
    config = {};
    config.data = null;
  }

  const {data} = await axiosInstance.get<T>(path, config);
  const url = window.URL.createObjectURL(data);
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.setAttribute('style', 'display: none');
  a.href = url;
  a.download = fileName + '.pdf';
  a.click();
  window.URL.revokeObjectURL(url);
  a.remove();
}

export const API = {
  get,
  post,
  patch,
  put,
  del,
  download,
};
