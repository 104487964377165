import React, {useEffect, useState} from 'react';
import {View, TouchableWithoutFeedback} from 'react-native';
import {makeStyles, Overlay} from 'rne-netzon';
import {
  SVGIcon,
  RNEIconButton,
  RNEText,
  ExpandableTextarea,
  SoundClipSelector,
  ImageAttachmentSelector,
} from '../../components';
import {colorPalette} from '../../../core/config/color.config';
import {MedicalAttachmentPopover} from '../../views';
import {PopoverItemKey} from '../../views/popover/data/popoverItems.data';
import {ConsultationRequestResBody} from '../../server/types/consultation.types';
import {ConsultationVideoCallScreen} from '../../screens';
import {useUploadMedia} from '../../server/react-query/useConversation';
import {
  useSendMobilePushNotification,
  useSendVideoCallNotification,
} from '../../server/react-query/usePushNotification';
import {REACT_APP_BASE_WEB_URL} from '../../server/api/api.config';

interface INotificationProp {
  title: string;
  body: string;
  consultation: string;
  notificationUrl: string;
  fromUserId: string;
  toUserId: string;
}
interface ConsultationChatProps {
  consultation: ConsultationRequestResBody;
  onSend?: (message: string) => void;
  userRole?: string;
  onMenuItemClick?: (itemKey: PopoverItemKey) => void;
  notificationProps: INotificationProp;
}

export const ConsultationChat = (props: ConsultationChatProps) => {
  const baseStyles = useBaseStyles();
  const webStyles = useWebStyles();

  const [containerHeight, setContainerHeight] = useState(100);
  const [inputMessage, setInputMessage] = useState('');
  const [showVideoCallDialog, setShowVideoCallDialog] = useState(false);
  const [soundClip, setSoundClip] = useState<any>(null);
  const [photo, setPhoto] = useState<any>({
    image: null,
  });
  const [caller, setCaller] = useState('');

  const navProps = {
    procedureId: props.consultation.procedure?.procedureId,
    subjectId: props.consultation?.subject.patientId,
    receiver:
      props.userRole === 'patient'
        ? props.consultation.subject
        : props.consultation.performer,
    serviceRequestId: props.consultation.serviceRequestId,
  };

  const uploadMediaMutation = useUploadMedia();
  const sendPushNotifMutation = useSendVideoCallNotification({
    title: 'INCOMING CALL',
    body: `${caller.length > 0 ? caller : `Someone`} is calling you.`,
    consultation: `"${JSON.stringify(navProps)}"`,
    notificationUrl: `${REACT_APP_BASE_WEB_URL}main/consultation/detail?id=${props.consultation.serviceRequestId}&screen=pending&tab=0`,
    fromUserId:
      props.userRole === 'patient'
        ? props.consultation.subject.userId
        : props.consultation.performer.userId,
    toUserId:
      props.userRole === 'patient'
        ? props.consultation.performer.userId
        : props.consultation.subject.userId,
  });

  const triggerPushNotification = useSendMobilePushNotification(
    props.notificationProps,
  );

  const send = async () => {
    if (soundClip || photo?.image) {
      try {
        await uploadMediaMutation.mutateAsync({
          content: soundClip ? soundClip : photo?.image,
          subject: props.consultation.subject.patientId,
          conversationId: props.consultation.serviceRequestId,
          type: soundClip ? 'soundClip' : 'image',
        });
      } catch (error: any) {
        console.log('error', error);
      }
      setSoundClip(null);
      setPhoto(null);
    } else {
      setInputMessage('');
      if (props.onSend) {
        props.onSend(inputMessage);
      }
    }
    triggerPushNotification.mutateAsync();
  };

  const navigateToVideoCall = () => {
    sendPushNotifMutation.mutateAsync();
    const audio = document.getElementById(
      'ringingNotification',
    ) as HTMLAudioElement;
    audio.muted = true;
    audio.pause();
    setShowVideoCallDialog(true);
  };

  const getFile = (file: any | null) => {
    setPhoto(null);
    setSoundClip(file);
  };

  const getImageFile = (file: any | null) => {
    setSoundClip(null);
    setPhoto({
      image: file,
    });
  };

  useEffect(() => {
    setCaller(
      props.userRole === 'patient'
        ? `${props.consultation.subject.humanName.given[0]} ${props.consultation.subject.humanName.family}`
        : `${props.consultation.performer.humanName.given[0]} ${props.consultation.performer.humanName.family}`,
    );
  }, [props]);

  const mainContainerHeight = {
    minHeight: containerHeight,
  };

  return (
    <TouchableWithoutFeedback>
      <View
        style={[
          webStyles.boxContainer,
          baseStyles.boxContainer,
          mainContainerHeight,
        ]}>
        <Overlay isVisible={showVideoCallDialog}>
          <ConsultationVideoCallScreen
            procedureId={props.consultation.procedure?.procedureId || ''}
            subjectId={
              props.userRole === 'patient'
                ? props.consultation.subject.patientId
                : ''
            }
            receiver={
              props.userRole === 'patient'
                ? props.consultation.performer
                : props.consultation.subject
            }
            fromInvitation={false}
            onConfirm={() => setShowVideoCallDialog(false)}
          />
        </Overlay>
        <View style={webStyles.iconsContainer}>
          {/* <View style={webStyles.iconContainer} accessibilityRole="button">
              <SVGIcon name="camera-29" size={24} />
            </View> */}
          <View style={webStyles.iconContainer} accessibilityRole="button">
            <ImageAttachmentSelector
              onSelectPhoto={getImageFile}
              attachment={true}
            />
          </View>
          <TouchableWithoutFeedback onPress={navigateToVideoCall}>
            <View style={webStyles.iconContainer} accessibilityRole="button">
              <SVGIcon name="video" size={24} />
            </View>
          </TouchableWithoutFeedback>
          <View style={webStyles.iconContainer} accessibilityRole="button">
            <SoundClipSelector onSelectSoundClip={getFile} />
          </View>
          {props.userRole === 'practitioner' && (
            <View style={webStyles.iconContainer} accessibilityRole="button">
              <MedicalAttachmentPopover onMenuItemClick={props.onMenuItemClick}>
                <SVGIcon name="attachment" size={24} />
              </MedicalAttachmentPopover>
            </View>
          )}
        </View>
        <View style={webStyles.inputContainer}>
          {soundClip || photo?.image ? (
            <View style={webStyles.mediaInformationContainer}>
              <View style={webStyles.mediaLabelContainer}>
                <RNEText
                  label="Media attached:&nbsp;"
                  variant="body"
                  numberOfLines={1}
                />
              </View>
              <RNEText
                label={soundClip ? soundClip.name : photo.image.name}
                variant="body"
                weight="bold"
                numberOfLines={1}
              />
              <TouchableWithoutFeedback
                onPress={() => {
                  setSoundClip(null);
                  setPhoto(null);
                }}>
                <View
                  style={webStyles.removeMediaContainer}
                  accessibilityRole="button">
                  <SVGIcon name="close" size={20} />
                </View>
              </TouchableWithoutFeedback>
            </View>
          ) : (
            <ExpandableTextarea
              value={inputMessage}
              placeholder="Message here..."
              maxRows={5}
              onChangeText={text => setInputMessage(text)}
            />
          )}
        </View>
        <View style={webStyles.buttonContainer}>
          <RNEIconButton
            icon={<SVGIcon name="send-white" size={24} />}
            color="secondary"
            size={48}
            onPress={send}
            loading={uploadMediaMutation.isLoading}
          />
        </View>
      </View>
    </TouchableWithoutFeedback>
  );
};

const useBaseStyles = makeStyles({
  boxContainer: {
    // flex: 1,
    width: '100%',
    flexDirection: 'row',
    borderTopColor: colorPalette.light,
    borderTopWidth: 1,
  },
});

const useWebStyles = makeStyles({
  boxContainer: {
    // paddingHorizontal: 60,
    // backgroundColor: colorPalette.grey,
  },
  iconsContainer: {
    flexShrink: 0,
    flexDirection: 'row',
  },
  iconContainer: {
    marginRight: 28,
    justifyContent: 'center',
  },
  inputContainer: {
    flex: 11,
    justifyContent: 'center',
    marginRight: 24,
    paddingVertical: 12,
  },
  buttonContainer: {
    flexShrink: 0,
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  mediaInformationContainer: {
    flexDirection: 'row',
  },
  removeMediaContainer: {
    marginLeft: 8,
  },
  mediaLabelContainer: {
    flexShrink: 0,
  },
});

export default ConsultationChat;
