import React, {useState, useEffect} from 'react';
import {StyleSheet, View, Platform} from 'react-native';

import {observer} from 'mobx-react';

import {RNEButton, RNEText} from '../../components';
import {colorPalette} from '../../../core/config/color.config';
import {formatDateString} from '../../utils/date.utils';
import {
  ConsultationRequestResBody,
  ConsultationStatusEnum,
} from '../../server/types/consultation.types';
import useStores from '../../stores/useStores';
import {useNavigation} from '@react-navigation/native';
import {NavigationKey} from '../../navigations/config';

interface ConsultationHistoryFooterNoteProps {
  serviceRequest: ConsultationRequestResBody;
}

const ConsultationHistoryFooterNote = (
  props: ConsultationHistoryFooterNoteProps,
) => {
  const {authStore} = useStores();
  const navigation = useNavigation();

  const [footerText, setFooterText] = useState(
    `The consultation has been completed last ${formatDateString(
      new Date(props.serviceRequest.modifiedOn),
    )}`,
  );

  const sendNewConsultationReq = () => {
    navigation.navigate(NavigationKey.PATIENT_CONSULTATION_REQUEST, {
      practitionerId: props.serviceRequest.performer.practitionerId,
    });
  };

  const findADoctor = () => {
    navigation.navigate(NavigationKey.PATIENT_SEARCH_RESULT, {
      searchKey: '',
      searchType: 'name',
    });
  };

  useEffect(() => {
    if (props.serviceRequest.subject) {
      if (props.serviceRequest.status === ConsultationStatusEnum.COMPLETED) {
        setFooterText(
          `The consultation has been completed last ${formatDateString(
            new Date(props.serviceRequest.modifiedOn),
          )}`,
        );
      } else {
        if (authStore.auth?.user.userId === props.serviceRequest.modifiedBy) {
          setFooterText(
            authStore.auth.user.userRole === 'practitioner'
              ? 'The consultation request has been referred. The patient will not be charged for this appointment.'
              : 'You had cancelled this consultation request.',
          );
        } else {
          setFooterText(
            authStore.auth?.user.userRole === 'practitioner'
              ? 'The patient has cancelled this consultation request.'
              : 'The consultation request has been referred. You will not be charged for this appointment.',
          );
        }
      }
    } else {
      setFooterText('This patient profile has been deleted.');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.serviceRequest.status, props.serviceRequest.modifiedOn]);

  return (
    <>
      {Platform.OS === 'web' ? (
        <View style={webStyles.mainContainer}>
          <View style={webStyles.messageContainer}>
            <RNEText
              label={footerText}
              color="grey"
              variant="button"
              numberOfLines={3}
              ellipsizeMode="tail"
              align="center"
            />
            {props.serviceRequest.status === ConsultationStatusEnum.COMPLETED &&
              authStore.userRole === 'patient' && (
                <View style={sharedStyles.buttonContainer}>
                  <RNEButton
                    title="Send Consultation Request"
                    color="secondary"
                    style={sharedStyles.buttonStyle}
                    onPress={sendNewConsultationReq}
                  />
                </View>
              )}
            {props.serviceRequest.status === ConsultationStatusEnum.DECLINED &&
              authStore.userRole === 'patient' && (
                <View style={sharedStyles.buttonContainer}>
                  <RNEButton
                    title="Find a doctor"
                    color="secondary"
                    style={sharedStyles.buttonStyle}
                    onPress={findADoctor}
                  />
                </View>
              )}
          </View>
        </View>
      ) : (
        <View style={mobileStyles.mainContainer}>
          <View style={mobileStyles.messageContainer}>
            <RNEText
              label={footerText}
              color="grey"
              numberOfLines={3}
              ellipsizeMode="tail"
              align="center"
            />
            {props.serviceRequest.status === ConsultationStatusEnum.COMPLETED &&
              authStore.userRole === 'patient' && (
                <View style={sharedStyles.buttonContainer}>
                  <RNEButton
                    title="Send Consultation Request"
                    color="secondary"
                    onPress={sendNewConsultationReq}
                  />
                </View>
              )}
            {props.serviceRequest.status === ConsultationStatusEnum.DECLINED &&
              authStore.userRole === 'patient' && (
                <View style={sharedStyles.buttonContainer}>
                  <RNEButton
                    title="Find a doctor"
                    color="secondary"
                    style={sharedStyles.buttonStyle}
                    onPress={findADoctor}
                  />
                </View>
              )}
          </View>
        </View>
      )}
    </>
  );
};

export default observer(ConsultationHistoryFooterNote);

const webStyles = StyleSheet.create({
  mainContainer: {
    flexDirection: 'row',
    paddingVertical: 40,
    alignSelf: 'center',
  },
  messageContainer: {
    flex: 1,
    maxWidth: 860,
    width: '100%',
  },
});

const mobileStyles = StyleSheet.create({
  mainContainer: {
    alignItems: 'center',
    paddingVertical: 32,
    borderTopWidth: 1,
    borderTopColor: colorPalette.light,
  },
  messageContainer: {
    alignItems: 'center',
  },
});

const sharedStyles = StyleSheet.create({
  buttonContainer: {
    marginTop: 20,
    alignSelf: 'center',
  },
  buttonStyle: {
    width: 236,
  },
});
