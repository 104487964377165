import {useNavigation} from '@react-navigation/core';
import React, {memo, useState} from 'react';
import {Platform, View, KeyboardAvoidingView} from 'react-native';

import {makeStyles} from 'rne-netzon';

import {SVGIcon, RNEButton, RNEText, RNEInput} from '../../components';
import {NavigationKey} from '../../navigations/config';

import {useDenyConsultationDetail} from '../../server/react-query/useConsultation';
import {ConsultationStatusEnum} from '../../server/types/consultation.types';

interface ConsultationDeclineModalProps {
  serviceRequestId: string;
  onCancel?: () => void;
  onConfirm?: () => void;
}

function ConsultationDeclineModal(props: ConsultationDeclineModalProps) {
  const navigation = useNavigation();
  const styles = useStyles();

  const [declinedMessage, setDeclinedMessage] = useState('');

  const denyMutation = useDenyConsultationDetail({
    serviceRequestId: props.serviceRequestId,
    status: ConsultationStatusEnum.DECLINED,
    note: declinedMessage,
  });

  const denyConsultation = async () => {
    try {
      await denyMutation.mutateAsync();

      if (Platform.OS === 'web') {
        navigation.navigate(NavigationKey.DOCTOR_CONSULTATION_STACK, {
          screen: NavigationKey.CONSULTATION_LIST,
          params: {
            tab: 2,
            id: props.serviceRequestId,
          },
        });
      } else {
        navigation.navigate(NavigationKey.CONSULTATION_DETAIL, {
          params: {
            id: props.serviceRequestId,
          },
        });
      }

      props.onConfirm?.();
    } catch (error) {
      console.log('error', error);
    }
  };

  return (
    <KeyboardAvoidingView
      style={styles.root}
      behavior={Platform.OS === 'ios' ? 'padding' : undefined}
      keyboardVerticalOffset={400}>
      <RNEText
        label="Consultation Request Declined"
        variant="title"
        align={Platform.OS === 'web' ? 'center' : 'left'}
        weight="600"
        color="primary"
        style={Platform.OS === 'web' ? subtitleFontSize20 : subtitleFontSize16}
      />
      <View style={styles.description}>
        <RNEText
          label="The patient needs to understand why you are declining this request and the steps that he / she needs to take next."
          variant="body"
          align={Platform.OS === 'web' ? 'center' : 'left'}
        />
      </View>
      <View style={styles.description}>
        <RNEText
          label="Let's help him/ her out. Please send him/her a message."
          variant="body"
          align={Platform.OS === 'web' ? 'center' : 'left'}
        />
      </View>

      <View style={styles.bodyContainer}>
        <RNEInput
          height={150}
          variant={Platform.OS === 'web' ? 'shadowed' : undefined}
          value={declinedMessage}
          onChangeText={setDeclinedMessage}
          multiline={true}
          inputStyle={inputStyle}
          inputContainerStyle={inputContainerWebStyle}
          placeholder="Type your message here."
        />
      </View>

      <View style={styles.actionContainer}>
        <View style={styles.buttonContainer}>
          <RNEButton
            title="Cancel"
            color="secondary"
            onPress={props.onCancel}
            variant="outlined"
            style={styles.buttonStyle}
          />
        </View>

        <View style={[styles.buttonContainer, styles.buttonSibling]}>
          <RNEButton
            title="Send"
            color="secondary"
            onPress={denyConsultation}
            disabled={!declinedMessage}
            style={styles.buttonStyle}
            loading={denyMutation.isLoading}
          />
        </View>
      </View>
    </KeyboardAvoidingView>
  );
}

const subtitleFontSize20 = {
  fontSize: 20,
};

const subtitleFontSize16 = {
  fontSize: 16,
};

const inputContainerWebStyle = {
  padding: 20,
};

const inputStyle = {
  lineHeight: 24,
};

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: Platform.OS === 'web' ? 640 : '85%',
    paddingVertical: Platform.OS === 'web' ? 32 : 16,
    paddingHorizontal: Platform.OS === 'web' ? 48 : 16,
    alignItems: Platform.OS === 'web' ? 'center' : 'flex-start',
  },
  description: {
    marginTop: 12,
  },
  bodyContainer: {
    width: '100%',
    marginTop: 32,
  },
  actionContainer: {
    marginTop: Platform.OS === 'web' ? 48 : 32,
    justifyContent: 'center',
    flexDirection: 'row',
  },
  buttonContainer: {
    flex: 1,
  },
  buttonStyle: {
    width: Platform.OS === 'web' ? 160 : '100%',
  },
  buttonSibling: {
    marginLeft: 16,
  },
}));

export default memo(ConsultationDeclineModal);
