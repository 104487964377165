import React from 'react';
import {View, Platform, TextStyle, StyleProp} from 'react-native';

import {useForm, Controller} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';

import {makeStyles} from 'rne-netzon';

import {RNEButton, RNEInput, RNEText} from '../../components';

import {useAcceptConsultationDetail} from '../../server/react-query/useConsultation';
import {ConsultationRequestResBody} from '../../server/types/consultation.types';
import {ScrollView} from 'react-native-gesture-handler';
import {useCreateInvoice} from '../../server/react-query/usePayment';
import {useNavigation} from '@react-navigation/core';
import {NavigationKey} from '../../navigations/config';
import {useToast} from 'react-native-fast-toast';
import {formatAmount} from '../../utils/currency.utils';
import {useSendMobilePushNotification} from '../../server/react-query/usePushNotification';
import useStores from '../../stores/useStores';
import {REACT_APP_BASE_WEB_URL} from '../../server/api/api.config';

interface ConsultationAcceptance {
  hasConsultationFee: boolean;
  consultationFee: number;
  note?: string;
}

interface ConsultationRequestAcceptanceScreenProps {
  serviceRequest?: ConsultationRequestResBody;
  onConfirm: () => void;
}

const ConsultationRequestAcceptanceScreen = (
  props: ConsultationRequestAcceptanceScreenProps,
) => {
  const navigation = useNavigation();
  const toast = useToast();
  const {authStore} = useStores();

  const webStyles = useWebStyles();

  const schema: yup.SchemaOf<ConsultationAcceptance> = yup.object({
    hasConsultationFee: yup.boolean().required(),
    consultationFee: yup
      .number()
      .required()
      .when('hasConsultationFee', {
        is: true,
        then: yup
          .number()
          .required('This field is required.')
          .typeError('Amount must be a number')
          .min(0, 'Positive amount')
          .max(
            props.serviceRequest?.performer?.fee?.amount?.value!,
            'Exceeds the consultation fee you set on your professional profile.',
          ),
        otherwise: yup
          .number()
          .required('This field is required.')
          .typeError('Amount must be a number')
          .min(0, 'Positive amount'),
      }),
    note: yup.string(),
  });

  const {
    control,
    handleSubmit,
    getValues,
    reset,
    formState: {errors, isValid},
  } = useForm<ConsultationAcceptance>({
    mode: 'all',
    defaultValues: {
      hasConsultationFee: props.serviceRequest?.performer?.fee?.amount?.value
        ? true
        : false,
      consultationFee: props.serviceRequest?.performer?.fee?.amount?.value || 0,
      note: '',
    },
    resolver: yupResolver(schema),
  });

  const acceptMutation = useAcceptConsultationDetail({
    basedOn: props.serviceRequest?.serviceRequestId || '',
  });

  const createInvoiceMutation = useCreateInvoice();
  const navProps = {
    procedureId: props.serviceRequest?.procedure?.procedureId,
    subjectId: props.serviceRequest?.subject.patientId!,
    receiver: props.serviceRequest?.performer,
    serviceRequestId: props.serviceRequest?.serviceRequestId,
  };

  const triggerPushNotification = useSendMobilePushNotification({
    title: 'Approved consultation request for the patient',
    body:
      'Your consultation request has been approved. You can now chat with your doctor.',
    consultation: `"${JSON.stringify(navProps)}"`,
    notificationUrl: `${REACT_APP_BASE_WEB_URL}main/consultation/detail?id=${props.serviceRequest?.serviceRequestId}&screen=pending&tab=0`,
    fromUserId: authStore.authData?.user.userId!,
    toUserId: props.serviceRequest?.requester!,
  });

  const onAcceptConsultation = () => {
    handleSubmit(onSubmit)();
  };

  const onSubmit = async () => {
    if (isValid) {
      try {
        const procedure = await acceptMutation.mutateAsync();
        await createInvoiceMutation.mutateAsync({
          subjectId: props.serviceRequest?.subject.patientId || '',
          lineItem: [
            {
              chargeItem: {
                serviceId: procedure.procedureId,
              },
              priceComponent: {
                amount: {
                  value: +getValues().consultationFee,
                  currency: 'PHP',
                },
              },
            },
          ],
          note: getValues().note || '',
        });
        triggerPushNotification.mutateAsync();
        acceptMutation.reset();
        createInvoiceMutation.reset();
        reset();

        if (Platform.OS === 'web') {
          navigation.navigate(NavigationKey.DOCTOR_CONSULTATION_STACK, {
            screen: NavigationKey.CONSULTATION_LIST,
            params: {
              tab: 1,
              id: props.serviceRequest?.serviceRequestId,
            },
          });
        } else {
          navigation.navigate(NavigationKey.CONSULTATION_DETAIL, {
            params: {
              id: props.serviceRequest?.serviceRequestId,
            },
          });
        }
        props.onConfirm();
      } catch (error) {
        console.log('error', error);
        if (error?.data?.error) {
          toast?.show(error?.data?.error, {type: 'danger'});
        }
      }
    }
  };

  return (
    <ScrollView>
      <View style={webStyles.root}>
        <View style={webStyles.title}>
          <RNEText
            label="You have accepted this consultation request."
            variant="title"
            style={titleFontSize}
            align="center"
            weight="600"
            color="primary"
          />
        </View>

        <View style={webStyles.itemsContainer}>
          <View style={webStyles.itemContainer}>
            <View style={webStyles.itemInputLabel}>
              <RNEText
                label="CONSULTATION FEE"
                color="blackLight"
                variant="body"
                align="left"
              />
              {props.serviceRequest?.performer?.fee?.amount?.value && (
                <RNEText
                  label={`Fee should not exceed ${formatAmount(
                    props.serviceRequest?.performer?.fee?.amount?.value,
                  )}`}
                  color="blackLight"
                  variant="button"
                  weight="300"
                  align="left"
                  style={italicStyle}
                />
              )}
            </View>
            <View style={webStyles.itemValue}>
              <Controller
                control={control}
                render={({field: {onChange, onBlur, value}}) => (
                  <RNEInput
                    placeholder="1,500"
                    value={value.toString()}
                    onChangeText={text => {
                      onChange(text);
                    }}
                    errorMessage={errors.consultationFee?.message}
                    variant="shadowed"
                    leftIcon={<RNEText label="Php" color="grey" />}
                  />
                )}
                name="consultationFee"
              />
            </View>
          </View>

          <View style={webStyles.itemContainer}>
            <View style={webStyles.itemInputLabel}>
              <RNEText
                label="Give some love to your patient by giving them a discounted service fee."
                color="blackLight"
                variant="body"
              />
            </View>
            <View style={webStyles.itemInputLabel}>
              <RNEText
                label="A Housecall Fee of 200php will be added to the total amount of the consultation fee"
                color="blackLight"
                variant="body"
              />
            </View>
            <View style={webStyles.itemValue}>
              <Controller
                control={control}
                render={({field: {onChange, onBlur, value}}) => (
                  <RNEInput
                    multiline={true}
                    height={200}
                    placeholder="Add a note to your patient (optional)"
                    inputContainerStyle={inputContainerWebStyle}
                    value={value?.toString()}
                    onChangeText={text => {
                      onChange(text);
                    }}
                    errorMessage={errors.note?.message}
                    variant="shadowed"
                  />
                )}
                name="note"
              />
            </View>
          </View>
        </View>

        <View style={webStyles.buttonContainer}>
          <RNEButton
            title="Proceed to Consultation"
            color="secondary"
            height={60}
            loading={
              acceptMutation.isLoading || createInvoiceMutation.isLoading
            }
            onPress={onAcceptConsultation}
          />
        </View>
      </View>
    </ScrollView>
  );
};

const titleFontSize = {
  fontSize: Platform.OS === 'web' ? 28 : 16,
};

const italicStyle: StyleProp<TextStyle> = {
  fontStyle: 'italic',
};

const inputContainerWebStyle = {
  padding: 20,
  borderRadius: 20,
};

const useWebStyles = makeStyles({
  root: {
    width: 700,
    padding: 40,
  },
  title: {
    marginHorizontal: 80,
  },
  itemsContainer: {
    marginTop: 16,
  },
  itemContainer: {
    marginBottom: 32,
  },
  itemInputLabel: {
    marginVertical: 16,
  },
  itemValue: {
    flex: 1,
  },
  buttonContainer: {
    width: 220,
    alignSelf: 'center',
  },
});

export default ConsultationRequestAcceptanceScreen;
