import React, {memo, useState, useEffect} from 'react';
import {Platform, View} from 'react-native';

import {makeStyles, Overlay} from 'rne-netzon';
import {format} from 'date-fns';

import {RNEText, RNEButton, Pagination, usePagination} from '../../components';
import {colorPalette} from '../../../core/config/color.config';

import {PractitionerStatus} from '../../server/types/practitioner-status.types';
import {PractitionerProfile} from '../../server/types/practitioner-profile.types';

import {useGetPractitionerProfiles} from '../../server/react-query/usePractitioner';
import {PractionerTableSortField} from '../../server/types/table.types';

import PractitionerProfileViewModal from '../modal/PractitionerProfileViewModal';
import ContentScrollView from '../scroll/ContentScrollView';
import TableHeaderRow from '../tables/TableHeaderRow';
import TableHeaderCell from '../tables/TableHeaderCell';
import TableContentCell from '../tables/TableContentCell';
import TableBodyRow from '../tables/TableBodyRow';
import TableBodyCell from '../tables/TableBodyCell';

interface AdminArchivedDoctorProfilesTabProps {
  searchKey?: string;
}

const AdminArchivedDoctorProfilesTab = (
  props: AdminArchivedDoctorProfilesTabProps,
) => {
  const styles = useStyles();
  const {
    pageNumber,
    pageSize,
    totalPage,
    setPageData,
    goBack,
    goNext,
    goToPage,
    canGoBack,
    canGoNext,
  } = usePagination();

  const [showViewDialog, setShowViewDialog] = useState(false);
  const [selectedPractitioner, setSelectedPractitioner] = useState<
    PractitionerProfile | undefined
  >();
  const [sortBy, setSortBy] = useState<PractionerTableSortField>(
    PractionerTableSortField.CREATED_ON,
  );
  const [ascending, setAscending] = useState<boolean>(true);

  const {data, isLoading, isFetching, refetch} = useGetPractitionerProfiles(
    {
      status: PractitionerStatus.ARCHIVED,
      pageNumber: pageNumber,
      sortBy: sortBy,
      ascending: ascending,
    },
    {},
  );

  const viewPractitionerProfile = (practitioner: PractitionerProfile) => {
    setSelectedPractitioner(practitioner);
    setShowViewDialog(true);
  };

  const onColumnClick = (column: PractionerTableSortField) => {
    if (!isFetching) {
      if (sortBy !== column) {
        setSortBy(column);
        setAscending(true);
      } else {
        setAscending(!ascending);
      }
    }
  };

  useEffect(() => {
    setPageData({
      pageNumber: data?.pageNumber,
      pageSize: data?.pageSize,
      totalPage: data?.totalPages,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber]);

  useEffect(() => {
    if (pageNumber !== 1) {
      goToPage(1);
    } else {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.searchKey]);

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy, ascending]);

  return (
    <View style={styles.root}>
      {selectedPractitioner && (
        <>
          <Overlay
            isVisible={showViewDialog}
            overlayStyle={styles.overlayContainer}
            onBackdropPress={() => {
              setSelectedPractitioner(undefined);
              setShowViewDialog(false);
            }}>
            <PractitionerProfileViewModal
              onClose={() => {
                setSelectedPractitioner(undefined);
                setShowViewDialog(false);
              }}
              practitionerId={selectedPractitioner.practitionerId}
            />
          </Overlay>
        </>
      )}

      <View style={styles.scrollView}>
        <ContentScrollView isWeb={Platform.OS === 'web'}>
          <TableHeaderRow>
            <TableHeaderCell>
              <TableContentCell
                label="Username"
                ascending={ascending}
                active={sortBy === PractionerTableSortField.LASTNAME}
                onPress={() => onColumnClick(PractionerTableSortField.LASTNAME)}
              />
            </TableHeaderCell>
            <TableHeaderCell>
              <TableContentCell
                label="Email"
                ascending={ascending}
                active={sortBy === PractionerTableSortField.EMAIL}
                onPress={() => onColumnClick(PractionerTableSortField.EMAIL)}
              />
            </TableHeaderCell>
            <TableHeaderCell width={150}>
              <TableContentCell
                label="Account Created"
                ascending={ascending}
                active={sortBy === PractionerTableSortField.CREATED_ON}
                onPress={() =>
                  onColumnClick(PractionerTableSortField.CREATED_ON)
                }
              />
            </TableHeaderCell>
            <TableHeaderCell width={150}>
              <TableContentCell label="" hideSort={true} />
            </TableHeaderCell>
          </TableHeaderRow>

          {data &&
            data.data.map(profile => (
              <TableBodyRow key={profile.practitionerId}>
                <TableBodyCell>
                  <RNEText
                    label={`${profile.humanName.family}, ${profile.humanName.given[0]}`}
                    variant="button"
                  />
                </TableBodyCell>
                <TableBodyCell>
                  <RNEText label={profile.email} variant="button" />
                </TableBodyCell>
                <TableBodyCell width={150}>
                  <RNEText
                    label={format(new Date(profile.createdOn), 'MM/dd/yyyy')}
                    variant="button"
                  />
                </TableBodyCell>
                <TableBodyCell width={150} justify="flex-end">
                  <View style={styles.buttonStyle}>
                    <RNEButton
                      title="View Profile"
                      variant="outlined"
                      color="warning"
                      onPress={() => viewPractitionerProfile(profile)}
                    />
                  </View>
                </TableBodyCell>
              </TableBodyRow>
            ))}

          {isFetching && data && data.data.length > 0 && (
            <View style={styles.fetchingContainer}>
              <RNEButton
                height={24}
                loading={isFetching}
                type="clear"
                loadingProps={loadingStyle}
                buttonStyle={buttonStyle}
              />
            </View>
          )}

          {!isLoading && data && data.data.length === 0 && (
            <View style={styles.placeholderContainer}>
              <RNEText label="No deleted doctor profile(s)" color="grey" />
            </View>
          )}

          {isLoading && (
            <RNEButton
              height={24}
              loading={isLoading}
              type="clear"
              loadingProps={loadingStyle}
              buttonStyle={buttonStyle}
            />
          )}

          {isFetching && data && data.data.length === 0 && (
            <View style={styles.fetchingContainer}>
              <RNEButton
                height={24}
                loading={isFetching}
                type="clear"
                loadingProps={loadingStyle}
                buttonStyle={buttonStyle}
              />
            </View>
          )}
        </ContentScrollView>
      </View>

      {data && data.data.length > 0 && (
        <View style={styles.pagination}>
          <Pagination
            pageNumber={pageNumber}
            totalPage={totalPage}
            goToPage={goToPage}
            goBack={goBack}
            goNext={goNext}
            canGoBack={canGoBack}
            canGoNext={canGoNext}
            freeze={isFetching}
          />
        </View>
      )}
    </View>
  );
};

const buttonStyle = {
  paddingHorizontal: 0,
  marginTop: 32,
};

const loadingStyle = {
  color: colorPalette.primary,
};

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
  },
  buttonStyle: {
    width: 140,
    marginLeft: 16,
  },
  placeholderContainer: {
    marginTop: 32,
    alignItems: 'center',
  },
  overlayContainer: {
    borderRadius: 20,
  },
  scrollView: {
    flex: 1,
    height: '100%',
    maxHeight: Platform.OS === 'web' ? '60vh' : 'auto',
    position: 'relative',
  },
  pagination: {
    marginTop: 16,
    alignItems: 'flex-end',
  },
  fetchingContainer: {
    position: 'absolute',
    alignItems: 'center',
    top: 120,
    left: 0,
    right: 0,
  },
  triangle: {
    marginLeft: 12,
    width: 0,
    height: 0,
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderLeftWidth: 5,
    borderRightWidth: 5,
    borderBottomWidth: 10,
    borderLeftColor: 'transparent',
    borderRightColor: 'transparent',
    borderBottomColor: '#ACBABD',
  },
  triangleDown: {
    transform: [{rotate: '180deg'}],
  },
}));

export default memo(AdminArchivedDoctorProfilesTab);
