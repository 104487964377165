import React, {memo} from 'react';
import {View, Text, StyleProp, TextStyle} from 'react-native';

import {makeStyles} from 'rne-netzon';
import {colorPalette} from '../../../core/config/color.config';

import {RNEText} from '../../components';

import {formatToMessageTime} from '../../utils/date.utils';

interface ConsultationRequestMessageProps {
  message: string;
  timestamp: Date;
  owner?: boolean;
}

const ConsultationRequestMessage = (props: ConsultationRequestMessageProps) => {
  const styles = useStyles();

  return (
    <View>
      <View style={styles.timestamp}>
        <RNEText
          label={formatToMessageTime(props.timestamp)}
          color="grey"
          variant="button"
          weight="600"
        />
      </View>
      <View
        style={[
          styles.messageContainer,
          // eslint-disable-next-line react-native/no-inline-styles
          {
            alignItems: props.owner ? 'flex-end' : 'flex-start',
          },
        ]}>
        <View
          style={[
            styles.messageWrapper,
            // eslint-disable-next-line react-native/no-inline-styles
            {
              borderTopRightRadius: props.owner ? 0 : 12,
              borderTopLeftRadius: props.owner ? 12 : 0,
            },
          ]}>
          <RNEText
            label="Consultation Request"
            color="white"
            variant="button"
            weight="600"
          />
          <RNEText label={props.message} color="white" variant="button" />
        </View>
      </View>
    </View>
  );
};

const useStyles = makeStyles({
  timestamp: {
    alignItems: 'center',
    marginTop: 24,
  },
  messageContainer: {
    marginTop: 16,
  },
  messageWrapper: {
    width: '80%',
    backgroundColor: colorPalette.purple,
    padding: 24,
    borderRadius: 12,
  },
});

export default memo(ConsultationRequestMessage);
