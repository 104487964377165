import {useMutation, useQuery, useQueryClient} from 'react-query';
import {AxiosRequestConfig} from 'axios';
import {API, ApiEndpointsEnum} from '../api';

import {
  ConversationAccessTokenReqData,
  GetMediaReqBody,
  GetMediaResBody,
  GetMediaUrlResBody,
  UploadMediaReqBody,
} from '../types/conversation.types';

const requestConversationAccessToken = async (
  reqData: ConversationAccessTokenReqData,
) => {
  let config: AxiosRequestConfig = {};

  if (reqData.subject) {
    const headerAuthorization: any = {
      'X-Subject': reqData.subject,
    };

    config = {
      headers: {
        ...headerAuthorization,
      },
    };
  }

  const url = ApiEndpointsEnum.CONVERSATION_ACCESS_TOKEN;

  return API.post<string>(url, {}, config);
};

const useRequestConversationAccessToken = () => {
  return useMutation(
    'conversation-token',
    (reqData: ConversationAccessTokenReqData) =>
      requestConversationAccessToken(reqData),
  );
};

const uploadMedia = async (reqBody: UploadMediaReqBody) => {
  const headerAuthorization: any = {
    'Content-Type': 'multipart/form-data',
    'X-Subject': reqBody.subject,
  };

  const config: AxiosRequestConfig = {
    headers: {
      ...headerAuthorization,
    },
  };

  const formData = new FormData();

  formData.append('content', reqBody.content!);
  formData.append('basedOn', reqBody.conversationId!);
  formData.append('type', reqBody.type);

  return API.post(
    ApiEndpointsEnum.ATTACH_MEDIA_TO_CONVERSATION,
    formData,
    config,
  );
};

const useUploadMedia = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ['upload-media'],
    (reqBody: UploadMediaReqBody) => uploadMedia(reqBody),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['upload-media']);
      },
    },
  );
};

const getMedia = async (reqBody: GetMediaReqBody) => {
  let config: AxiosRequestConfig = {};

  if (reqBody.subject) {
    const headerAuthorization: any = {
      'X-Subject': reqBody.subject,
    };

    config = {
      headers: {
        ...headerAuthorization,
      },
    };
  }

  const url = ApiEndpointsEnum.GET_ATTACHMENT_MEDIA.replace(
    '{mediaId}',
    reqBody.mediaId,
  );

  return API.get<GetMediaResBody>(url, config);
};

const useGetMedia = (reqBody: GetMediaReqBody) => {
  return useQuery(['get-media', {id: reqBody.mediaId}], () =>
    getMedia(reqBody),
  );
};

const getMediaUrl = async (reqBody: GetMediaReqBody) => {
  let config: AxiosRequestConfig = {};

  if (reqBody.subject) {
    const headerAuthorization: any = {
      'X-Subject': reqBody.subject,
    };

    config = {
      headers: {
        ...headerAuthorization,
      },
    };
  }

  const url = ApiEndpointsEnum.GET_ATTACHMENT_MEDIA_URL.replace(
    '{mediaId}',
    reqBody.mediaId,
  );

  return API.get<GetMediaUrlResBody>(url, config);
};

const useGetMediaUrl = (reqBody: GetMediaReqBody) => {
  return useQuery(
    ['get-media-url', {id: reqBody.mediaId}],
    () => getMediaUrl(reqBody),
    {
      enabled: !!reqBody.mediaId,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  );
};

export {
  useRequestConversationAccessToken,
  useUploadMedia,
  useGetMedia,
  useGetMediaUrl,
};
