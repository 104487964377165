import React, {useState, useEffect, useRef} from 'react';
import {Animated} from 'react-native';

interface SideMenuProps {
  minValue: number;
  maxValue: number;
  duration?: number;
}

export const useSideMenu = ({
  minValue,
  maxValue,
  duration = 300,
}: SideMenuProps) => {
  const widthValue = useRef(new Animated.Value(maxValue)).current;

  const [isCompressed, setIsCompressed] = useState(false);
  const [compressed, setCompressed] = useState(false);

  const toggleMenu = () => {
    setIsCompressed(!isCompressed);
  };

  useEffect(() => {
    Animated.timing(widthValue, {
      toValue: isCompressed ? minValue : maxValue,
      duration: 300,
      useNativeDriver: true,
    }).start();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widthValue, isCompressed]);

  useEffect(() => {
    widthValue.addListener(data => {
      if (data.value < maxValue) {
        setCompressed(true);
      } else if (data.value === maxValue) {
        setCompressed(false);
      }
    });

    return () => {
      widthValue.removeAllListeners();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    widthValue,
    compressed,
    toggleMenu,
  };
};
