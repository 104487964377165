import {useQuery} from 'react-query';
import {AxiosRequestConfig} from 'axios';
import {API, ApiEndpointsEnum} from '../api';
import {
  NotificationsReqParams,
  NotificationsResBody,
} from '../types/notification.types';
import {convertJsonToParams} from '../utils/query.util';
import {PaginatedData} from '../types/pagination.type';

const getNotifications = (params: NotificationsReqParams) => {
  let config: AxiosRequestConfig = {};
  const queryParams = convertJsonToParams(params as any);

  if (params.patientId) {
    const headerAuthorization: any = {
      'X-Subject': params.patientId,
    };

    config = {
      headers: {
        ...headerAuthorization,
      },
    };
  }

  const url = ApiEndpointsEnum.GET_NOTIFICATIONS + '?' + queryParams;
  return API.get<PaginatedData<NotificationsResBody>>(url, config);
};

const useGetNotifications = (params: NotificationsReqParams) => {
  return useQuery(['get-notifications', {id: params.patientId}], () =>
    getNotifications(params),
  );
};

export {useGetNotifications};
